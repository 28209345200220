import React, { useEffect } from 'react';

import { destroyApi } from '@eGroupTeam/redux-modules/immutable/apis';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import CircularProgress from '@eGroupTeam/material/CircularProgress';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import Center from '@eGroupTeam/material-layout/Center';
import { Typography } from '@eGroupTeam/material';
import { UrlParams } from 'types';
import { fetchGetActivity, getActivity } from './redux';

const ActivityRoot = ({ route }) => {
  const dispatch = useDispatch();
  const activity = useSelector(getActivity);
  const { activityId } = useParams<UrlParams>();

  useEffect(() => {
    dispatch(
      fetchGetActivity({
        activityId,
      })
    );
    return () => {
      dispatch(destroyApi(['landingPages', 'activity']));
    };
  }, [activityId, dispatch]);

  if (!activity) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (activity.isEmpty()) {
    return (
      <Center>
        <Typography variant="h4">查無此活動</Typography>
      </Center>
    );
  }

  return renderRoutes(route.routes, { activity });
};

export default ActivityRoot;
