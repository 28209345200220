import React, { FC } from 'react';

import apis from 'redux/apisOrg';
import { fromJS, Map, List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import displayValues from '@eGroupTeam/utils/displayValues';
import {
  makeStyles,
  Dialog,
  Fade,
  ListItem,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Grid,
  Paper,
} from '@eGroupTeam/material';
import { getSelectedOrg } from 'redux/modules/common';

import Button from '@eGroupTeam/material/Button';
import ReactSelect, { OptionType } from '@eGroupTeam/material-module/ReactSelect';

import CloseIcon from '@material-ui/icons/Close';
import ScrollableTypography from 'components/ScrollableTypography';
import ListItemBreakText from 'components/ListItemBreakText';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import { EntityList, UserApply } from 'types';
import useOrgActivity from 'utils/useOrgActivity';
import {
  setSelectedUserApply,
  fetchPostActivityUserApplyDays,
  getSelectedUserApply,
  getActivityUserApplyDayIsPosting,
} from './redux';
import TagContainer from './TagContainer';
import Tag from './Tag';

export const DIALOG = 'checkInByUserDataDialog';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
  },
  tagContainer: {
    margin: theme.spacing(1),
  },
}));

const Option = (props) => {
  const { userName, userPhone, userEmail } = props.data.user;
  return (
    <ListItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      button
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <ListItemBreakText
        primary={userName}
        secondary={
          <>
            <Typography component="span" color="textPrimary">
              {userPhone}
            </Typography>{' '}
            {userEmail}
          </>
        }
      />
    </ListItem>
  );
};

export interface CheckInByUserDataDialogProps {
  activityId: string;
}

const CheckInByUserDataDialog: FC<
  CheckInByUserDataDialogProps & WithReduxDialogProps
> = ({ activityId, isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedUserApply = useSelector(getSelectedUserApply);
  const isPosting = useSelector(getActivityUserApplyDayIsPosting);
  const selectedOrg = useSelector(getSelectedOrg);
  const { excute: getUserApplies, data, isLoading } = useAxiosApiWrapper<
    EntityList<UserApply> | ''
  >(apis.fetchGetUserApplies);
  const { data: activity } = useOrgActivity({
    organizationId: selectedOrg.get('organizationId'),
    activityId,
  });

  const handleInputChange = (value) => {
    if (value === '') return;
    getUserApplies({
      activityId,
      organizationId: selectedOrg.get('organizationId'),
      query: value,
      startIndex: 0,
      size: 10,
    });
  };

  const handleChange = (value) => {
    dispatch(setSelectedUserApply(fromJS(value) || Map()));
  };

  const handleClick = () => {
    dispatch(
      fetchPostActivityUserApplyDays({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        userApplyId: selectedUserApply.get('userApplyId'),
      })
    );
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <AppBar elevation={1} position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <ScrollableTypography variant="h6" className={classes.title}>
            {activity?.activityName} - 一般驗證
          </ScrollableTypography>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Box maxWidth={400} padding={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                使用電話、姓名、email 搜尋使用者
              </Typography>
              <Paper elevation={4}>
                <Box py={1} px={1.5}>
                  <ReactSelect
                    MuiTextFieldProps={{
                      fullWidth: true,
                      InputProps: {
                        disableUnderline: true,
                      },
                    }}
                    value={selectedUserApply.toJS()}
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    options={
                      typeof data !== 'string'
                        ? data?.source.map((el) => ({
                            ...el,
                            value: el.userApplyId,
                            label: el.user.userName,
                          })) as OptionType[]
                        : []
                    }
                    isLoading={isLoading}
                    placeholder="人員搜尋"
                    filterOption={null}
                    isClearable
                    components={{
                      Option,
                    }}
                  />
                </Box>
              </Paper>
            </Grid>
            <Fade in={!selectedUserApply.isEmpty()}>
              <Grid item xs={12}>
                <Paper elevation={4}>
                  {selectedUserApply.has('userApplyTagList') && (
                    <TagContainer className={classes.tagContainer}>
                      {selectedUserApply.get('userApplyTagList').map((el) => (
                        <Tag key={el.get('userApplyTagId')}>
                          {el.getIn(['tag', 'tagName'])}
                        </Tag>
                      ))}
                    </TagContainer>
                  )}
                  <Box pt={1.5} pb={2} px={1.5}>
                    {displayValues(
                      [
                        {
                          key: '姓名',
                          value: selectedUserApply.getIn(['user', 'userName']),
                        },
                        {
                          key: '電話',
                          value: selectedUserApply.getIn(['user', 'userPhone']),
                        },
                        {
                          key: '電子郵件',
                          value: selectedUserApply.getIn(['user', 'userEmail']),
                        },
                        {
                          key: '場次',
                          value: selectedUserApply.get('activityClassName'),
                        },
                        {
                          value: selectedUserApply
                            .get('responseList', List())
                            .toJS(),
                          render: () =>
                            selectedUserApply.get('responseList').map((el) => (
                              <Typography
                                key={el.get('responseId')}
                                variant="body1"
                                gutterBottom
                              >
                                {el.get('questionName')}：{el.get('optionName')}
                              </Typography>
                            )),
                        },
                      ],
                      ({ key, value }) => (
                        <Typography key={key} variant="body1" gutterBottom>
                          {key}：{value}
                        </Typography>
                      )
                    )}
                    <Button
                      loading={isPosting}
                      onClick={handleClick}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      確認
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Fade>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<unknown, CheckInByUserDataDialogProps>(
  CheckInByUserDataDialog
);
