import React from 'react';
import PropTypes from 'prop-types';

// TODO: need fixed.
// @ts-ignore
import { submit } from 'redux-form/immutable';
import { getSelectedOrg } from 'redux/modules/common';
import { useDispatch, useSelector } from 'react-redux';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import Button from '@eGroupTeam/material/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@eGroupTeam/material';
import { fetchPatchSurvey, getIsPatching } from './redux';
import { getSurvey } from '../redux';

import SurveyEditForm, { FORM } from './SurveyEditForm';

export const DIALOG = 'surveyEditDialog';

const SurveyEditDialog = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const isPatching = useSelector(getIsPatching);
  const survey = useSelector(getSurvey);
  const selectedOrg = useSelector(getSelectedOrg);

  const handleSubmit = (values) => {
    dispatch(
      fetchPatchSurvey({
        organizationId: selectedOrg.get('organizationId'),
        ...values.toJS(),
      })
    );
  };

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>問卷設定</DialogTitle>
      <DialogContent>
        {survey && (
          <SurveyEditForm initialValues={survey} onSubmit={handleSubmit} />
        )}
      </DialogContent>
      <DialogActions>
        <Button loading={isPatching} onClick={() => dispatch(submit(FORM))}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SurveyEditDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(SurveyEditDialog);
