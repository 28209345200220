import React, { FC, useCallback, useState } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { makeStyles, Box, Tooltip } from '@eGroupTeam/material';
import { getSelectedOrg } from 'redux/modules/common';

import Button from '@eGroupTeam/material/Button';
import Dialog from '@eGroupTeam/material/Dialog';
import Toolbar from '@eGroupTeam/material/Toolbar';
import IconButton from '@eGroupTeam/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import ScrollableTypography from 'components/ScrollableTypography';
import QrCodeRecognizer from 'components/QrCodeRecognizer';
import TransparentAppBar from 'components/TransparentAppBar';

import { FacingMode } from '@eGroupTeam/material-lab/MediaStreamClipper';
import useOrgActivity from 'utils/useOrgActivity';
import {
  fetchPostActivityRecognizeQrcode,
  emptyRecognizedResult,
  emptyUnknownRecognizedResult,
  getRecognizedResult,
  getUnknownRecognizedResult,
} from './redux';
import RecognizedResult from './RecognizedResult';

export const DIALOG = 'checkInByQrCodeDialog';

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    borderColor: '#ffff',
    marginTop: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  text: {
    color: '#fff',
  },
}));

export interface CheckInByQrCodeDialogProps {
  activityId: string;
}

const CheckInByQrCodeDialog: FC<
  CheckInByQrCodeDialogProps & WithReduxDialogProps
> = ({ activityId, isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const recognizedResult = useSelector(getRecognizedResult);
  const unknownRecognizedResult = useSelector(getUnknownRecognizedResult);
  const selectedOrg = useSelector(getSelectedOrg);
  const [facingMode, setFacingMode] = useState<FacingMode>('user');
  const { data: activity } = useOrgActivity({
    organizationId: selectedOrg.get('organizationId'),
    activityId,
  });

  const handleGetIntervalShot = useCallback(
    (blob) => {
      const formData = new FormData();
      formData.append(
        'file',
        new File([blob], 'screenshot.jpeg', {
          lastModified: new Date().getTime(),
        })
      );
      dispatch(
        fetchPostActivityRecognizeQrcode({
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          formData,
        })
      );
    },
    [dispatch, selectedOrg, activityId]
  );

  const handleBackScanner = () => {
    dispatch(emptyRecognizedResult());
    dispatch(emptyUnknownRecognizedResult());
  };

  const renderContent = () => {
    if (recognizedResult) {
      const userApply = recognizedResult.get('userApply');
      return (
        <RecognizedResult userApply={userApply}>
          <Button
            className={classes.button}
            variant="outlined"
            fullWidth
            onClick={handleBackScanner}
          >
            繼續掃描 QRCode
          </Button>
        </RecognizedResult>
      );
    }
    return (
      <QrCodeRecognizer
        facingMode={facingMode}
        handleGetIntervalShot={handleGetIntervalShot}
        errorMsg={unknownRecognizedResult}
      />
    );
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <TransparentAppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            className={classes.text}
          >
            <CloseIcon />
          </IconButton>
          <ScrollableTypography
            variant="h6"
            className={clsx(classes.text, classes.title)}
          >
            {activity?.activityName} - QRCode 驗證
          </ScrollableTypography>
          <Box flexGrow={1} />
          <Tooltip title={`使用${facingMode === 'user' ? '後' : '前'}鏡頭`}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() =>
                setFacingMode((val) =>
                  val === 'user' ? 'environment' : 'user'
                )
              }
            >
              <FlipCameraIosIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </TransparentAppBar>
      {renderContent()}
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<unknown, CheckInByQrCodeDialogProps>(
  CheckInByQrCodeDialog
);
