import axios from 'axios';
import config from './apiConfig';

const invoiceFetcher = axios.create({
  baseURL: '/invoice-member-api',
  ...config,
});

export default {
  /**
   * invoice carrier small to big platform
   */
  fetchPostCarrierMemberVan: () => invoiceFetcher.post('/carrier/member-van'),
  /**
   * invoice carrier big to small platform
   */
  fetchPostCarrierConsumer: () => invoiceFetcher.post('/carrier/consumer'),
};
