import { fetcher } from 'redux/apisLP';
import { ActivityClass, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  activityId?: string;
};
const useActivityClasses = makeGetHook<EntityList<ActivityClass>, PathParams>(
  '/activities/{{activityId}}/activity-class',
  fetcher
);

export default useActivityClasses;
