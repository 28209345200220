import React from 'react';

import { format } from '@eGroupTeam/utils/dateUtils';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, ListItem, Paper, List as MuiList } from '@eGroupTeam/material';
import Skeleton from '@material-ui/lab/Skeleton';
import ListItemBreakText from 'components/ListItemBreakText';
import { Activity } from 'types';
import { setSelectedActivityClassId } from '../Activity/redux';

export interface ActivityListProps {
  activities?: Activity[];
}

const ActivityList = ({ activities }) => {
  const dispatch = useDispatch();
  if (!activities) {
    return (
      <Paper>
        {Array.from(Array(20).keys()).map((val) => (
          <Box key={val} px={2} py={1}>
            <Skeleton width="10%" />
            <Skeleton width="30%" />
          </Box>
        ))}
      </Paper>
    );
  }

  return (
    <Paper>
      <MuiList disablePadding dense>
        {activities.map((el) => (
          <ListItem
            component={Link}
            to={`/me/activities/${el.activityId}/dashboard`}
            button
            onClick={() => dispatch(setSelectedActivityClassId(undefined))}
            key={el.activityId}
          >
            <ListItemBreakText
              primary={el.activityName}
              secondary={`${format(
                el.activityStartDateString,
                'yyyy-MM-dd'
              )} ~ ${format(el.activityEndDateString, 'yyyy-MM-dd')}`}
            />
          </ListItem>
        ))}
      </MuiList>
    </Paper>
  );
};

export default ActivityList;
