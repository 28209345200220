import React, { FC } from 'react';

import Typography from '@eGroupTeam/material/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { ActivityClass } from 'types';
import ActivityGrid, { ActivityGridProps } from './ActivityGrid';

export interface ActivityClassDescriptionProps extends ActivityGridProps {
  activityClass: ActivityClass;
}

const ActivityClassDescription: FC<ActivityClassDescriptionProps> = (props) => {
  const { activityClass, ...other } = props;

  return (
    <ActivityGrid {...other}>
      <>
        <InfoIcon color="action" />
        <Typography display="inline" variant="h6">
          場次敘述
        </Typography>
      </>
      <Typography variant="body1">
        {activityClass.activityClassDescription}
      </Typography>
    </ActivityGrid>
  );
};

export default ActivityClassDescription;
