import React, { useState } from 'react';

import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import isLength from 'validator/lib/isLength';
import { useParams, useLocation, useHistory } from 'react-router';
import {
  makeStyles,
  Container,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@eGroupTeam/material';

import { useSelector, useDispatch } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';
import { Question, Option, UrlParams } from 'types';

import Main from '@eGroupTeam/material-layout/Main';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import BackAppbar from '@eGroupTeam/material-module/BackAppbar';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import SettingsIcon from '@material-ui/icons/Settings';
import Linkcon from '@material-ui/icons/Link';
import ScrollableTypography from 'components/ScrollableTypography';
import SurveyComponent from 'components/Survey';
import SurveyHeader from 'components/SurveyHeader';
import SurveyToolbar from 'components/SurveyToolbar';
import QuestionTextField from 'components/QuestionTextField';
import QuestionComponent from 'components/Question';
import QuestionEditable from 'components/QuestionEditable';
import {
  fetchPatchSurvey,
  fetchGetSurveyQuestions,
  fetchPostSurveyQuestion,
  fetchPatchSurveyQuestion,
  fetchDeleteSurveyQuestion,
  fetchPatchSurveyQuestionsOrder,
  fetchPatchSurveyQuestionOption,
  fetchPostSurveyQuestionOption,
  fetchDeleteSurveyQuestionOption,
  fetchPatchSurveyQuestionOptionsOrder,
  getSurveyQuestions,
} from './redux';
import SurveyEditDialog, { DIALOG as SETTING_DIALOG } from './SurveyEditDialog';
import SurveyShareDialog, { DIALOG as SHARE_DIALOG } from './SurveyShareDialog';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    '& .MuiInput-root': theme.typography.h5,
  },
  toolbar: {
    position: 'absolute',
    right: 0,
    transition: 'all 0.4s',
  },
  grow: {
    flexGrow: 1,
  },
}));

const MAX_NAME = 100;

const SurveyEditor = ({ survey, backPath = '/me/surveys' }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { surveyId } = useParams<UrlParams>();
  const [selectedQuestionId, setSelectedQuestionId] = React.useState<string>();
  const [controledQuestions, setControledQuestions] = React.useState<
    Question[]
  >();
  const [surveyName, setSurveyName] = useState<string>(
    survey.get('surveyName')
  );
  const [surveyDesc, setSurveyDesc] = useState<string>(
    survey.get('surveyDescription')
  );
  const questions = useSelector(getSurveyQuestions);
  const selectedOrg = useSelector(getSelectedOrg);
  const isNameError = !isLength(surveyName || '', {
    min: 0,
    max: MAX_NAME,
  });

  React.useEffect(() => {
    dispatch(
      fetchGetSurveyQuestions({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
      })
    );
  }, [dispatch, selectedOrg, surveyId]);

  React.useEffect(() => {
    if (questions) {
      setControledQuestions(questions.toJS());
    }
  }, [questions]);

  React.useEffect(() => {
    setSurveyName(survey.get('surveyName'));
  }, [survey]);

  React.useEffect(() => {
    setSurveyDesc(survey.get('surveyDescription'));
  }, [survey]);

  const handleSurveyNameChange = (e) => {
    setSurveyName(e.target.value);
    dispatch(
      fetchPatchSurvey({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        surveyName: e.target.value,
      })
    );
  };

  const handleSurveyDescriptionChange = (e) => {
    setSurveyDesc(e.target.value);
    dispatch(
      fetchPatchSurvey({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        surveyDescription: e.target.value,
      })
    );
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (!controledQuestions) return;
    const sourceQuestion = controledQuestions[source.index];

    if (!sourceQuestion) return

    const payload = [...controledQuestions];
    payload.splice(source.index, 1);
    payload.splice(destination.index, 0, sourceQuestion);
    setControledQuestions(payload);
    dispatch(
      fetchPatchSurveyQuestionsOrder({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        payload: payload.map((question) => ({
          questionId: question.questionId,
        })),
      })
    );
  };

  const handleNameChange = (event, question: Question) => {
    dispatch(
      fetchPatchSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        questionName: event.target.value,
      })
    );
  };

  const handleTypeChange = (event, question) => {
    const nextQuestionType = event.target.value;
    let nextOptionList;
    let questionRatingEndValue;
    let questionRatingStartValue;

    if (
      (nextQuestionType === 'choicemulti' ||
        nextQuestionType === 'choiceone' ||
        nextQuestionType === 'select') &&
      (!question.optionList || question.optionList.length === 0)
    ) {
      nextOptionList = [
        {
          optionName: '選項 1',
          optionNo: 1,
        },
      ];
    }
    if (nextQuestionType === 'rating') {
      questionRatingStartValue = 1;
      questionRatingEndValue = 5;
    }
    dispatch(
      fetchPatchSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        questionType: nextQuestionType,
        optionList: nextOptionList,
        questionRatingEndValue,
        questionRatingStartValue,
      })
    );
  };

  const handleDescChange = (event, question: Question) => {
    dispatch(
      fetchPatchSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        questionDescription: event.target.value,
      })
    );
  };

  const handleIsRequiredChange = (event, question: Question) => {
    dispatch(
      fetchPatchSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        isRequired: Number(event.target.checked),
      })
    );
  };

  const handleDelete = (event, question: Question) => {
    dispatch(
      fetchDeleteSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
      })
    );
  };

  const handleCreateQuestion = () => {
    if (controledQuestions) {
      dispatch(
        fetchPostSurveyQuestion({
          organizationId: selectedOrg.get('organizationId'),
          surveyId,
          questionType: 'text',
          questionNo: controledQuestions.length + 1,
        })
      );
    }
  };

  const handleOptionChange = (event, question: Question, option: Option) => {
    dispatch(
      fetchPatchSurveyQuestionOption({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        optionId: option.optionId,
        optionName: event.target.value,
      })
    );
  };

  const handleOptionDelete = (e, question: Question, deletedOption: Option) => {
    dispatch(
      fetchDeleteSurveyQuestionOption({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        optionId: deletedOption.optionId,
      })
    );
  };

  const handleOptionDragEnd = (
    result,
    question: Question,
    options: Option[]
  ) => {
    dispatch(
      fetchPatchSurveyQuestionOptionsOrder({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        payload: options.map((option) => ({
          optionId: option.optionId,
        })),
      })
    );
  };

  const handleOptionCreate = (e, question: Question, index: number) => {
    dispatch(
      fetchPostSurveyQuestionOption({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        optionName: `選項 ${index}`,
        optionNo: index,
      })
    );
  };

  const handleOptionCreateOther = (e, question: Question) => {
    dispatch(
      fetchPostSurveyQuestionOption({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        optionName: '其他...',
        isOther: 1,
        hasInput: 1,
      })
    );
  };

  const handleRatingStartChange = (e, question: Question) => {
    dispatch(
      fetchPatchSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        questionRatingStartValue: e.target.value,
      })
    );
  };
  const handleRatingEndChange = (e, question: Question) => {
    dispatch(
      fetchPatchSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        questionRatingEndValue: e.target.value,
      })
    );
  };
  const handleRatingStartInputChange = (e, question: Question) => {
    dispatch(
      fetchPatchSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        questionRatingStartName: e.target.value,
      })
    );
  };
  const handleRatingEndInputChange = (e, question: Question) => {
    dispatch(
      fetchPatchSurveyQuestion({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
        questionId: question.questionId,
        questionRatingEndName: e.target.value,
      })
    );
  };

  if (!controledQuestions) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <BackAppbar
        push={history.push}
        go={history.go}
        pointerTrigger={location}
        position="fixed"
        elevation={0}
        backPath={backPath}
      >
        <ScrollableTypography variant="h6">
          {survey.get('surveyName')}
        </ScrollableTypography>
        <div className={classes.grow} />
        <Tooltip title="取得問卷連結">
          <IconButton
            color="inherit"
            onClick={() => dispatch(openDialog(SHARE_DIALOG))}
          >
            <Linkcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="問卷設定">
          <IconButton
            color="inherit"
            onClick={() => dispatch(openDialog(SETTING_DIALOG))}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </BackAppbar>
      <SurveyEditDialog />
      <SurveyShareDialog />
      <Main>
        <Container maxWidth="md">
          <SurveyComponent>
            <SurveyHeader>
              <QuestionTextField
                classes={{
                  root: classes.title,
                }}
                placeholder="表單標題"
                value={surveyName}
                onChange={handleSurveyNameChange}
                fullWidth
                error={isNameError}
                helperText={
                  isNameError ? `標題請勿超過 ${MAX_NAME} 個字元` : undefined
                }
              />
              <QuestionTextField
                onChange={handleSurveyDescriptionChange}
                placeholder="問卷說明"
                value={surveyDesc}
                fullWidth
                multiline
              />
            </SurveyHeader>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={survey.get('surveyId')}>
                {(dropProvided) => (
                  <div {...dropProvided.droppableProps}>
                    <div ref={dropProvided.innerRef}>
                      {controledQuestions.map((question, index) => (
                        <Draggable
                          draggableId={question.questionId}
                          key={question.questionId}
                          index={index}
                        >
                          {(dragProvided) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                            >
                              {question.questionId === selectedQuestionId ? (
                                <QuestionEditable
                                  DragHandleProps={dragProvided.dragHandleProps}
                                  question={question}
                                  onNameChange={handleNameChange}
                                  onTypeChange={handleTypeChange}
                                  onDescChange={handleDescChange}
                                  onRequiredChange={handleIsRequiredChange}
                                  onDelete={handleDelete}
                                  onOptionChange={handleOptionChange}
                                  onOptionDelete={handleOptionDelete}
                                  onOptionDragEnd={handleOptionDragEnd}
                                  onOptionCreate={handleOptionCreate}
                                  onOptionCreateOther={handleOptionCreateOther}
                                  onRatingStartChange={handleRatingStartChange}
                                  onRatingEndChange={handleRatingEndChange}
                                  onRatingStartInputChange={
                                    handleRatingStartInputChange
                                  }
                                  onRatingEndInputChange={
                                    handleRatingEndInputChange
                                  }
                                />
                              ) : (
                                <QuestionComponent
                                  DragHandleProps={dragProvided.dragHandleProps}
                                  onClick={() =>
                                    setSelectedQuestionId(question.questionId)
                                  }
                                  question={question}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {dropProvided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <SurveyToolbar
              className={classes.toolbar}
              onCreateQuestionClick={handleCreateQuestion}
              tools={['createQuestion']}
            />
          </SurveyComponent>
        </Container>
      </Main>
    </>
  );
};

export default SurveyEditor;
