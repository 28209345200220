import React, { FC } from 'react';

import clsx from 'clsx';
import { makeStyles, Theme } from '@eGroupTeam/material';

import DragIcon from 'components/DragIcon';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    cursor: (props: QuestionDragHandleProps) =>
      !props.disabled ? 'move' : undefined,
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(3),
  },
  dragIcon: {
    margin: 'auto',
    transform: 'rotate(90deg)',
  },
}));

export interface QuestionDragHandleProps {
  className?: string;
  disabled?: boolean;
  DragHandleProps?: DraggableProvidedDragHandleProps;
}

const QuestionDragHandle: FC<QuestionDragHandleProps> = (props) => {
  const classes = useStyles(props);
  const { className, disabled, DragHandleProps, ...other } = props;
  if (disabled) {
    return (
      <div
        className={clsx(className, classes.root)}
        {...DragHandleProps}
        {...other}
      />
    );
  }

  return (
    <div
      className={clsx(className, classes.root)}
      {...DragHandleProps}
      {...other}
    >
      <DragIcon color="action" className={classes.dragIcon} />
    </div>
  );
};

export default QuestionDragHandle;
