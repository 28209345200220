import { fetcher } from 'redux/apisOrg';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';
import { OrganizationMemberRole, EntityList } from 'types';

export type PathParams = {
  organizationId?: string;
  loginId?: string;
};
const useOrgMemberRoles = makeGetHook<
  EntityList<OrganizationMemberRole>,
  PathParams
>('/organizations/{{organizationId}}/member/{{loginId}}/roles', fetcher);
export default useOrgMemberRoles;
