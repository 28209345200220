import React, { FC } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@eGroupTeam/material';
import placeholder from './placeholder.png';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingTop: '100%',
      backgroundColor: 'rgba(255,255,255,0.5)',
      borderRadius: theme.spacing(8),
      boxShadow: '11px 11px 12px 0 rgba(26, 19, 17, 0.75)',
    },
    img: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'block',
      height: '90%',
      margin: 'auto',
    },
  });

export interface FacePlaceholderProps {
  /**
   * Set true to show face image.
   */
  showFaceImg?: boolean;
}

const FacePlaceholder: FC<FacePlaceholderProps & WithStyles<typeof styles>> = ({
  classes,
  showFaceImg,
}) => (
  <div className={classes.root}>
    {showFaceImg && (
      <img className={classes.img} src={placeholder} alt="face placeholder" />
    )}
  </div>
);

export default withStyles(styles)(FacePlaceholder);
