import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import s3Url from 'utils/s3Url';

import RatioImage from '@eGroupTeam/material/RatioImage';
import {
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
} from '@eGroupTeam/material';
import CheckIcon from '@material-ui/icons/Check';
import ImageUploadArea from '../ImageUploadArea';

const defaultImages = [
  {
    src: 'resources/image/activity/cover/default/1.jpg',
  },
  {
    src: 'resources/image/activity/cover/default/2.jpg',
  },
  {
    src: 'resources/image/activity/cover/default/3.jpg',
  },
  {
    src: 'resources/image/activity/cover/default/4.jpg',
  },
  {
    src: 'resources/image/activity/cover/default/5.jpg',
  },
  {
    src: 'resources/image/activity/cover/default/6.jpg',
  },
];

const useStyles = makeStyles(() => ({
  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  icon: {
    opacity: 0,
    fontSize: 40,
  },
  imgGrid: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  imgRoot: {
    position: 'relative',
    width: '100%',
  },
  imgContainer: {
    '&::after': {
      content: '""',
      transition: 'opacity .6s ease',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,0.4)',
      opacity: 0,
    },

    '&:hover': {
      cursor: 'pointer',
      color: '#fff',

      '&::after': {
        opacity: 1,
      },
    },
  },
  selected: {
    color: '#fff',
    '&::after': {
      opacity: 1,
    },
    '& $icon': {
      opacity: 1,
    },
  },
}));

const ActivityCoverUploadImage = ({
  onDrop: onDropProp,
  onCoverClick,
  isUploading,
  uploadedCoverPath,
  selectedCoverPath,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="lg" fullWidth onClose={handleClose}>
        <DialogTitle>選擇活動封面照</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ImageUploadArea
                onDrop={onDropProp}
                uploadedImagePath={uploadedCoverPath}
                selectedImagePath={selectedCoverPath}
                onCoverClick={onCoverClick}
                uploading={isUploading}
                hover
                ratio="840:620"
              >
                上傳活動封面
                <br />
                840:620
              </ImageUploadArea>
            </Grid>
            {defaultImages.map((el) => (
              <Grid item xs={6} key={el.src} className={classes.imgGrid}>
                <div
                  className={classes.imgRoot}
                  onClick={() => onCoverClick(el)}
                  onKeyDown={() => onCoverClick(el)}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    className={clsx(
                      classes.imgContainer,
                      el.src === selectedCoverPath && classes.selected
                    )}
                  >
                    <RatioImage
                      src={s3Url(el.src)}
                      fit="cover"
                      ratio="840:620"
                      alt="default cover image"
                    />
                    <CheckIcon
                      color="inherit"
                      className={clsx(classes.center, classes.icon)}
                    />
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>完成</Button>
        </DialogActions>
      </Dialog>
      <div
        className={classes.imgRoot}
        onClick={handleOpen}
        onKeyDown={handleOpen}
        role="button"
        tabIndex={0}
      >
        <div className={clsx(classes.imgContainer, classes.selected)}>
          <RatioImage
            src={s3Url(selectedCoverPath)}
            fit="cover"
            ratio="840:620"
            alt="default cover image"
          />
          <Typography variant="h6" className={classes.center} align="center">
            選擇活動封面
          </Typography>
        </div>
      </div>
    </>
  );
};

ActivityCoverUploadImage.propTypes = {
  onDrop: PropTypes.func,
  onCoverClick: PropTypes.func,
  isUploading: PropTypes.bool,
  uploadedCoverPath: PropTypes.string,
  selectedCoverPath: PropTypes.string,
};

export default ActivityCoverUploadImage;
