import React, { FC } from 'react';
import { makeStyles, AppBar, Toolbar, AppBarProps } from '@eGroupTeam/material';
import Logo from 'components/Logo';

const useStyles = makeStyles(() => ({
  root: {
    background: 'transparent',
    borderBottom: 'solid 1px #dbdde3'
  },
  logo: {
    margin: 'auto',
  },
}));

const ActivityAppBar: FC<AppBarProps> = (props) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} elevation={0} {...props}>
      <Toolbar>
        <Logo className={classes.logo} variant="rounded" size="small" elevation={1}/>
      </Toolbar>
    </AppBar>
  );
};

export default ActivityAppBar;
