import React, { ComponentType, FC, useEffect } from 'react';

import filterRoutes from 'utils/filterRoutes';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { openSnackbar } from '@eGroupTeam/redux-modules/immutable/snackbars';
import { renderRoutes } from 'react-router-config';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import routesPrivate from 'routes-private';
import routesPublic from 'routes-public';
import { COOKIE_SNACKBAR } from 'App';
import { setSelectedOrg, getSelectedOrg } from 'redux/modules/common';

import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import CircularProgress from '@eGroupTeam/material/CircularProgress';
import { Member } from 'types';
import GoToNewSiteDialog, { DIALOG } from './GoToNewSiteDialog';
import {
  fetchGetOrganizations,
  fetchGetMemberInfo,
  fetchGetMemberModules,
} from './actions';
import {
  getOrganizations,
  getMemberInfo,
  getMemberModuleValues,
} from './selectors';

const RouterRoot: FC<ReactCookieProps & RouteComponentProps> = ({
  allCookies,
  location,
}) => {
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);
  const memberInfo: Member = useSelector(getMemberInfo);
  const memberModuleValues = useSelector(getMemberModuleValues);
  const selectedOrg = useSelector(getSelectedOrg);
  const hasLogin = allCookies?.u_info && allCookies?.lid && allCookies?.tid;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (allCookies && !allCookies.confirmCookiePolicy) {
      dispatch(openSnackbar(COOKIE_SNACKBAR));
    }
  }, [allCookies, dispatch]);

  useEffect(() => {
    if (hasLogin) {
      dispatch(fetchGetMemberInfo());
      dispatch(fetchGetOrganizations());
    }
  }, [dispatch, hasLogin]);

  useEffect(() => {
    if (selectedOrg && !selectedOrg.isEmpty()) {
      dispatch(
        fetchGetMemberModules({
          organizationId: selectedOrg.get('organizationId'),
        })
      );
    }
  }, [dispatch, selectedOrg]);

  useEffect(() => {
    if (organizations && organizations.size > 0 && selectedOrg.isEmpty()) {
      const selectedOrgId = window.localStorage.getItem('selectedOrgId');
      if (selectedOrgId) {
        const selectedOrg = organizations.find(
          (el) => el.get('organizationId') === selectedOrgId
        );
        if (selectedOrg) {
          dispatch(setSelectedOrg(selectedOrg));
        } else {
          dispatch(setSelectedOrg(organizations.first()));
        }
      } else {
        dispatch(setSelectedOrg(organizations.first()));
      }
    }
  }, [dispatch, organizations, selectedOrg]);

  useEffect(() => {
    if (!allCookies) return;
    if (
      !allCookies.askedGotoNewSite &&
      window.location.hostname === process.env.REACT_APP_CURRENT_WEBSITE_URL &&
      memberInfo?.isTestList
    ) {
      dispatch(openDialog(DIALOG));
    }
  }, [allCookies, dispatch, memberInfo]);

  if (!hasLogin) {
    return renderRoutes(routesPublic);
  }

  if (!organizations || !memberInfo) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (organizations.size > 0 && selectedOrg.isEmpty()) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (!selectedOrg.isEmpty() && !memberModuleValues) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (memberModuleValues) {
    return (
      <>
        <GoToNewSiteDialog />
        {renderRoutes(filterRoutes(routesPrivate, memberModuleValues), {
          organizations,
        })}
      </>
    );
  }

  return (
    <>
      <GoToNewSiteDialog />
      {renderRoutes(routesPrivate, { organizations })}
    </>
  );
};

export default compose<ComponentType>(withRouter, withCookies)(RouterRoot);
