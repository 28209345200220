import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { getEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import { fromJS, Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schema from 'redux/schema';

/** Actions */
export const {
  fetchGetUsers,
  fetchGetUsersRequest,
  fetchGetUsersSuccess,
  fetchGetUsersFailure,

  fetchGetUser,
  fetchGetUserRequest,
  fetchGetUserSuccess,
  fetchGetUserFailure,

  fetchPostUser,
  fetchPostUserRequest,
  fetchPostUserSuccess,
  fetchPostUserFailure,

  fetchPatchUser,
  fetchPatchUserRequest,
  fetchPatchUserSuccess,
  fetchPatchUserFailure,

  fetchPostUsersByExcel,
  fetchPostUsersByExcelRequest,
  fetchPostUsersByExcelSuccess,
  fetchPostUsersByExcelFailure,

  fetchGetUserActivities,
  fetchGetUserActivitiesRequest,
  fetchGetUserActivitiesSuccess,
  fetchGetUserActivitiesFailure,

  fetchPostUserActivitiesByExcel,
  fetchPostUserActivitiesByExcelRequest,
  fetchPostUserActivitiesByExcelSuccess,
  fetchPostUserActivitiesByExcelFailure,

  fetchDeleteUser,
  fetchDeleteUserRequest,
  fetchDeleteUserSuccess,
  fetchDeleteUserFailure,
} = actionCreators.mainPages.users;

/** Types */
export const FETCH_GET_USERS = fetchGetUsers().type;
export const FETCH_GET_USERS_REQUEST = fetchGetUsersRequest().type;
export const FETCH_GET_USERS_SUCCESS = fetchGetUsersSuccess().type;
export const FETCH_GET_USERS_FAILURE = fetchGetUsersFailure().type;

export const FETCH_GET_USER = fetchGetUser().type;
export const FETCH_GET_USER_REQUEST = fetchGetUserRequest().type;
export const FETCH_GET_USER_SUCCESS = fetchGetUserSuccess().type;
export const FETCH_GET_USER_FAILURE = fetchGetUserFailure().type;

export const FETCH_POST_USER = fetchPostUser().type;
export const FETCH_POST_USER_REQUEST = fetchPostUserRequest().type;
export const FETCH_POST_USER_SUCCESS = fetchPostUserSuccess().type;
export const FETCH_POST_USER_FAILURE = fetchPostUserFailure().type;

export const FETCH_PATCH_USER = fetchPatchUser().type;
export const FETCH_PATCH_USER_REQUEST = fetchPatchUserRequest().type;
export const FETCH_PATCH_USER_SUCCESS = fetchPatchUserSuccess().type;
export const FETCH_PATCH_USER_FAILURE = fetchPatchUserFailure().type;

export const FETCH_POST_USERS_BY_EXCEL = fetchPostUsersByExcel().type;
export const FETCH_POST_USERS_BY_EXCEL_REQUEST = fetchPostUsersByExcelRequest()
  .type;
export const FETCH_POST_USERS_BY_EXCEL_SUCCESS = fetchPostUsersByExcelSuccess()
  .type;
export const FETCH_POST_USERS_BY_EXCEL_FAILURE = fetchPostUsersByExcelFailure()
  .type;

export const FETCH_GET_USER_ACTIVITIES = fetchGetUserActivities().type;
export const FETCH_GET_USER_ACTIVITIES_REQUEST = fetchGetUserActivitiesRequest()
  .type;
export const FETCH_GET_USER_ACTIVITIES_SUCCESS = fetchGetUserActivitiesSuccess()
  .type;
export const FETCH_GET_USER_ACTIVITIES_FAILURE = fetchGetUserActivitiesFailure()
  .type;

export const FETCH_POST_USER_ACTIVITIES_BY_EXCEL = fetchPostUserActivitiesByExcel()
  .type;
export const FETCH_POST_USER_ACTIVITIES_BY_EXCEL_REQUEST = fetchPostUserActivitiesByExcelRequest()
  .type;
export const FETCH_POST_USER_ACTIVITIES_BY_EXCEL_SUCCESS = fetchPostUserActivitiesByExcelSuccess()
  .type;
export const FETCH_POST_USER_ACTIVITIES_BY_EXCEL_FAILURE = fetchPostUserActivitiesByExcelFailure()
  .type;

export const FETCH_DELETE_USER = fetchDeleteUser().type;
export const FETCH_DELETE_USER_REQUEST = fetchDeleteUserRequest().type;
export const FETCH_DELETE_USER_SUCCESS = fetchDeleteUserSuccess().type;
export const FETCH_DELETE_USER_FAILURE = fetchDeleteUserFailure().type;

/** Selectors */

/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'users'], Map());

const getFetchGetUsers = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetUsers', Map())
);

const getFetchGetUser = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetUser', Map())
);

const getFetchPostUser = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostUser', Map())
);

const getFetchPatchUser = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPatchUser', Map())
);

const getFetchPostUsersByExcel = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostUsersByExcel', Map())
);

const getFetchGetUserActivities = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetUserActivities', Map())
);

const getFetchPostUserActivitiesByExcel = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostUserActivitiesByExcel', Map())
);

export const getUsers = createSelector(
  getEntities,
  getFetchGetUsers,
  (entities, fetchGet) =>
    fromJS(
      denormalize(
        fetchGet.getIn(['response', 'result']),
        schema.users,
        entities
      )
    )
);

export const getUsersTotal = createSelector(getFetchGetUsers, (fetchGet) =>
  fetchGet.getIn(['response', 'total'])
);

export const getIsLoading = createSelector(getFetchGetUsers, (fetchGet) =>
  fetchGet.get('isLoading')
);

export const getUser = createSelector(
  getEntities,
  getFetchGetUser,
  (entities, fetchGet) =>
    fromJS(denormalize(fetchGet.get('response'), schema.user, entities))
);

export const getIsPosting = createSelector(getFetchPostUser, (fetchPost) =>
  fetchPost.get('isLoading')
);

export const getIsPatching = createSelector(getFetchPatchUser, (fetchPatch) =>
  fetchPatch.get('isLoading')
);

export const getUsersIsPosting = createSelector(
  getFetchPostUsersByExcel,
  (fetchPost) => fetchPost.get('isLoading')
);

export const getPostUsersByExcelResult = createSelector(
  getFetchPostUsersByExcel,
  (fetchPost) => fetchPost.get('response')
);

export const getUserActivitiesIsLoading = createSelector(
  getFetchGetUserActivities,
  (fetchGet) => fetchGet.get('isLoading')
);

export const getUserActivitiesIsPosting = createSelector(
  getFetchPostUserActivitiesByExcel,
  (fetchPost) => fetchPost.get('isLoading')
);
