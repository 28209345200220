import React from 'react';

import { withStyles } from '@eGroupTeam/material';
import clsx from 'clsx';
import { green } from '@eGroupTeam/material/colors';

const styles = (theme) => ({
  root: {
    color: '#fff',
    padding: theme.spacing(0.5, 1),
    fontSize: '0.875rem',
    border: '1px solid #fff',
    borderRadius: 12,
    background: green[500],
    margin: theme.spacing(0.5),
    boxShadow: theme.shadows[1],
  },
});

const Tag = ({ className, classes, ...other }) => (
  <div className={clsx(className, classes.root)} {...other} />
);

export default withStyles(styles)(Tag);
