import React, { FC } from 'react';

import apis from 'redux/apisOrg';
import { format } from '@eGroupTeam/utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { getSelectedOrg } from 'redux/modules/common';
import { Activity, Survey } from 'types';

import ButtonMenu from '@eGroupTeam/material-module/ButtonMenu';
import Main from '@eGroupTeam/material-layout/Main';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import {
  Tooltip,
  Container,
  Box,
  Paper,
  Button,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@eGroupTeam/material';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FixedFab from 'components/FixedFab';
import useActivitySurveys from 'utils/useActivitySurveys';
import { useHistory } from 'react-router';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import Center from '@eGroupTeam/material-layout/Center';
import ActivitySurveyAddDialog, { DIALOG } from './ActivitySurveyAddDialog';

enum FilterType {
  All = 'all',
  Active = 'active',
  Expired = 'expired',
}

export interface ActivitySurveysProps {
  activity: Activity;
}

const ActivitySurveys: FC<ActivitySurveysProps> = ({ activity }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const { activityId } = activity;
  const {
    handleChangePage,
    handleRowsPerPageChange,
    handleSearchChange,
    payload,
    setPayload,
    page,
    rowsPerPage,
  } = useDataTable(
    'orgMembers',
    {
      filterType: FilterType.All,
    },
    {
      fromKey: 'startIndex',
      sizeKey: 'size',
    }
  );
  const { data, response, isValidating } = useActivitySurveys(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    {
      ...payload,
      activityId,
    }
  );
  const { excute: createSurvey, isLoading: isCreating } = useAxiosApiWrapper(
    apis.fetchPostSurvey
  );

  const handleChnageFilterType = (filterType: FilterType) => () => {
    setPayload((payload) => ({
      ...payload,
      size: 10,
      startIndex: 0,
      filterType,
    }));
  };

  const handleSubmit = (values) => {
    createSurvey({
      organizationId: selectedOrg.get('organizationId'),
      activity: {
        activityId: activity.activityId,
      },
      ...values.toJS(),
    }).then((response) => {
      history.push(
        `/me/activities/${response.data.activity.activityId}/surveys/${response.data.surveyId}/editor`
      );
      dispatch(closeDialog(DIALOG));
    });
  };

  const handleAdd = () => {
    dispatch(openDialog(DIALOG));
  };

  const getFilterTypeText = (filterType) => {
    if (filterType === FilterType.Active) {
      return '進行中問卷';
    }
    if (filterType === FilterType.Expired) {
      return '已結束問卷';
    }
    return '全部問卷';
  };

  const renderDataRow = (rowData: unknown) => {
    const survey = rowData as Survey;
    return (
      <TableRow key={survey.surveyId}>
        <TableCell>{survey.surveyName}</TableCell>
        <TableCell>
          {survey.surveyStartDateString &&
            format(survey.surveyStartDateString, 'PP pp')}
        </TableCell>
        <TableCell>
          {survey.surveyEndDateString &&
            format(survey.surveyEndDateString, 'PP pp')}
        </TableCell>
        <TableCell align="right">
          <Button
            startIcon={<EditIcon />}
            onClick={() => {
              history.push(
                `/me/activities/${activityId}/surveys/${survey.surveyId}/editor`
              );
            }}
          >
            編輯
          </Button>
          <Button
            startIcon={<AssessmentIcon />}
            onClick={() => {
              history.push(
                `/me/activities/${activityId}/surveys/${survey.surveyId}/analysis`
              );
            }}
          >
            報表
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  if (activity.activityHasSurvey === 0) {
    return (
      <Center height={500}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            您尚未啟用此服務，請前往活動設定啟用此功能
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/me/activities/${activityId}/settings`);
            }}
          >
            前往活動設定
          </Button>
        </div>
      </Center>
    );
  }

  return (
    <>
      <ActivitySurveyAddDialog onSubmit={handleSubmit} loading={isCreating} />
      <Tooltip title="新增問卷">
        <FixedFab onClick={handleAdd} color="primary">
          <AddIcon />
        </FixedFab>
      </Tooltip>
      <Main>
        <Container>
          <Box mb={1} display="flex">
            <ButtonMenu
              id="activity-filter"
              button={
                <Button variant="outlined" endIcon={<ArrowDropDownIcon />}>
                  問卷篩選：{getFilterTypeText(payload.filterType)}
                </Button>
              }
            >
              <MenuItem
                button
                onClick={handleChnageFilterType(FilterType.All)}
                selected={payload.filterType === FilterType.All}
              >
                全部問卷
              </MenuItem>
              <MenuItem
                button
                onClick={handleChnageFilterType(FilterType.Active)}
                selected={payload.filterType === FilterType.Active}
              >
                進行中問卷
              </MenuItem>
              <MenuItem
                button
                onClick={handleChnageFilterType(FilterType.Expired)}
                selected={payload.filterType === FilterType.Expired}
              >
                已結束問卷
              </MenuItem>
            </ButtonMenu>
          </Box>
          <Paper>
            <DataTable
              title="問卷列表"
              data={data?.source ?? []}
              SearchBarProps={{
                placeholder: '搜尋問卷',
                onChange: handleSearchChange,
                defaultValue: payload.query,
              }}
              columns={['問卷名稱', '開始時間', '結束時間', '']}
              serverSide
              loading={isValidating}
              isEmpty={response?.status === 204}
              renderDataRow={renderDataRow}
              MuiTablePaginationProps={{
                count: data?.total ?? 0,
                labelRowsPerPage: '每頁幾筆',
                page,
                rowsPerPage,
                onPageChange: handleChangePage,
                onRowsPerPageChange: handleRowsPerPageChange,
              }}
              style={{
                tableLayout: 'fixed',
              }}
            />
          </Paper>
        </Container>
      </Main>
    </>
  );
};

export default ActivitySurveys;
