import { fetcher } from 'redux/apisOrg';
import { UserFace, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
  userId?: string;
};
const useUserFaces = makeGetHook<EntityList<UserFace> | '', PathParams>(
  '/organizations/{{organizationId}}/users/{{userId}}/user-faces',
  fetcher
);
export default useUserFaces;
