import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchGetUserApplyTotalCounts,
  fetchGetUserApplyTotalCountsRequest,
  fetchGetUserApplyTotalCountsSuccess,
  fetchGetUserApplyTotalCountsFailure,

  fetchGetActivityApplyFieldResult,
  fetchGetActivityApplyFieldResultRequest,
  fetchGetActivityApplyFieldResultSuccess,
  fetchGetActivityApplyFieldResultFailure,

  fetchPostActivityUserApplyDays,
  fetchPostActivityUserApplyDaysRequest,
  fetchPostActivityUserApplyDaysSuccess,
  fetchPostActivityUserApplyDaysFailure,

  fetchPostActivityRecognizeQrcode,
  fetchPostActivityRecognizeQrcodeRequest,
  fetchPostActivityRecognizeQrcodeSuccess,
  fetchPostActivityRecognizeQrcodeFailure,

  fetchPostActivityRecognizeFace,
  fetchPostActivityRecognizeFaceRequest,
  fetchPostActivityRecognizeFaceSuccess,
  fetchPostActivityRecognizeFaceFailure,

  fetchGetUserApplyCountInfo,

  setSelectedUserApply,
  setResult,
  setRecognizedResult,
  setUnknownRecognizedResult,
  emptyRecognizedResult,
  emptyUnknownRecognizedResult,
} = actionCreators.mainPages.activity.activityCheckIn;

/** Types */

export const FETCH_GET_USER_APPLY_TOTAL_COUNTS = fetchGetUserApplyTotalCounts()
  .type;
export const FETCH_GET_USER_APPLY_TOTAL_COUNTS_REQUEST = fetchGetUserApplyTotalCountsRequest()
  .type;
export const FETCH_GET_USER_APPLY_TOTAL_COUNTS_SUCCESS = fetchGetUserApplyTotalCountsSuccess()
  .type;
export const FETCH_GET_USER_APPLY_TOTAL_COUNTS_FAILURE = fetchGetUserApplyTotalCountsFailure()
  .type;

export const FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT = fetchGetActivityApplyFieldResult()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_REQUEST = fetchGetActivityApplyFieldResultRequest()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_SUCCESS = fetchGetActivityApplyFieldResultSuccess()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_FAILURE = fetchGetActivityApplyFieldResultFailure()
  .type;

export const FETCH_POST_ACTIVITY_USER_APPLY_DAYS = fetchPostActivityUserApplyDays()
  .type;
export const FETCH_POST_ACTIVITY_USER_APPLY_DAYS_REQUEST = fetchPostActivityUserApplyDaysRequest()
  .type;
export const FETCH_POST_ACTIVITY_USER_APPLY_DAYS_SUCCESS = fetchPostActivityUserApplyDaysSuccess()
  .type;
export const FETCH_POST_ACTIVITY_USER_APPLY_DAYS_FAILURE = fetchPostActivityUserApplyDaysFailure()
  .type;

export const FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE = fetchPostActivityRecognizeQrcode()
  .type;
export const FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE_REQUEST = fetchPostActivityRecognizeQrcodeRequest()
  .type;
export const FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE_SUCCESS = fetchPostActivityRecognizeQrcodeSuccess()
  .type;
export const FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE_FAILURE = fetchPostActivityRecognizeQrcodeFailure()
  .type;

export const FETCH_POST_ACTIVITY_RECOGNIZE_FACE = fetchPostActivityRecognizeFace()
  .type;
export const FETCH_POST_ACTIVITY_RECOGNIZE_FACE_REQUEST = fetchPostActivityRecognizeFaceRequest()
  .type;
export const FETCH_POST_ACTIVITY_RECOGNIZE_FACE_SUCCESS = fetchPostActivityRecognizeFaceSuccess()
  .type;
export const FETCH_POST_ACTIVITY_RECOGNIZE_FACE_FAILURE = fetchPostActivityRecognizeFaceFailure()
  .type;

export const FETCH_GET_USER_APPLY_COUNT_INFO = fetchGetUserApplyCountInfo()
  .type;

export const SET_SELECTED_USER_APPLY = setSelectedUserApply().type;
export const SET_RESULT = setResult().type;
export const SET_RECOGNIZED_RESULT = setRecognizedResult().type;
export const SET_UNKNOWN_RECOGNIZED_RESULT = setUnknownRecognizedResult().type;
export const EMPTY_RECOGNIZED_RESULT = emptyRecognizedResult().type;
export const EMPTY_UNKNOWN_RECOGNIZED_RESULT = emptyUnknownRecognizedResult()
  .type;

/** Selectors */

/**
 * Ui States
 */
const getUiStates = (state) => state.getIn(['mainPages', 'ActivityCheckIn']);

export const getRecognizedResult = createSelector(getUiStates, (uiStates) =>
  uiStates.get('recognizedResult')
);

export const getUnknownRecognizedResult = createSelector(
  getUiStates,
  (uiStates) => uiStates.get('unknownRecognizedResult')
);

export const getSelectedUserApply = createSelector(getUiStates, (uiStates) =>
  uiStates.get('selectedUserApply')
);

export const getUserApplyTotal = createSelector(getUiStates, (uiStates) =>
  uiStates.getIn(['userApplyCountInfo', 'total'])
);

export const getUserApplyCounts = createSelector(getUiStates, (uiStates) =>
  uiStates.getIn(['userApplyCountInfo', 'count'])
);

/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'activity', 'activityCheckIn'], Map());

const getFetchGetActivityApplyFieldResult = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetActivityApplyFieldResult', Map())
);

const getFetchPostActivityUserApplyDays = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostActivityUserApplyDays', Map())
);

export const getActivityApplyFieldResult = createSelector(
  getFetchGetActivityApplyFieldResult,
  (fetchResult) =>
    fetchResult.get('response') ? fetchResult.get('response').toJS() : undefined
);

export const getActivityUserApplyDayIsPosting = createSelector(
  getFetchPostActivityUserApplyDays,
  (fetchResult) => fetchResult.get('isLoading')
);
