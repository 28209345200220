import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { format } from '@eGroupTeam/utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { getSelectedOrg } from 'redux/modules/common';
import { Survey } from 'types';

import ButtonMenu from '@eGroupTeam/material-module/ButtonMenu';
import Main from '@eGroupTeam/material-layout/Main';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import {
  Tooltip,
  Container,
  Box,
  Paper,
  CircularProgress,
  Button,
  MenuItem,
  TableCell,
  TableRow,
} from '@eGroupTeam/material';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EditIcon from '@material-ui/icons/Edit';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FixedFab from 'components/FixedFab';
import { fetchGetSurveys, getSurveys, getSurveysTotal } from './redux';
import SurveyAddDialog, { DIALOG } from './SurveyAddDialog';

enum FilterType {
  All = 'all',
  Active = 'active',
  Expired = 'expired',
}

const Surveys = ({ history }) => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const surveys = useSelector(getSurveys);
  const surveysTotal = useSelector(getSurveysTotal);

  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    setPayload,
    page,
    rowsPerPage,
  } = useDataTable('Surveys', {
    startIndex: 0,
    size: 10,
    filterType: FilterType.All,
  }, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });

  useEffect(() => {
    const { filterType, ...other } = payload
    if (filterType === FilterType.All) {
      dispatch(
        fetchGetSurveys({
          organizationId: selectedOrg.get('organizationId'),
          ...other,
        })
      );
    } else {
      dispatch(
        fetchGetSurveys({
          organizationId: selectedOrg.get('organizationId'),
          surveyDeadlineStatus: filterType,
          ...other,
        })
      );
    }
  },
  [dispatch, payload, selectedOrg])

  const handleChnageFilterType = (filterType: FilterType) => () => {
    setPayload((payload) => ({
      ...payload,
      size: 10,
      startIndex: 0,
      filterType,
    }));
  };

  const handleAdd = () => {
    dispatch(openDialog(DIALOG));
  };

  const getFilterTypeText = (filterType) => {
    if (filterType === FilterType.Active) {
      return '進行中問卷';
    }
    if (filterType === FilterType.Expired) {
      return '已結束問卷';
    }
    return '全部問卷';
  };

  const renderColumns = (rowData) => (
    <TableRow>
      {rowData.map((el) => (
        <TableCell key={el}>{el}</TableCell>
      ))}
    </TableRow>
  );

  const renderDataRow = (rowData: unknown) => {
    const survey = rowData as Survey;
    return (
      <TableRow key={survey.surveyId}>
        <TableCell>{survey.surveyName}</TableCell>
        <TableCell>
          {survey.surveyStartDateString &&
            format(survey.surveyStartDateString, 'PP pp')}
        </TableCell>
        <TableCell>
          {survey.surveyEndDateString &&
            format(survey.surveyEndDateString, 'PP pp')}
        </TableCell>
        <TableCell align="right">
          <Button
            startIcon={<EditIcon />}
            onClick={() => {
              history.push(`/me/surveys/${survey.surveyId}/editor`);
            }}
          >
            編輯
          </Button>
          <Button
            startIcon={<AssessmentIcon />}
            onClick={() => {
              history.push(`/me/surveys/${survey.surveyId}/analysis`);
            }}
          >
            報表
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  if (!surveys) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <SurveyAddDialog />
      <Tooltip title="新增問卷">
        <FixedFab onClick={handleAdd} color="primary">
          <AddIcon />
        </FixedFab>
      </Tooltip>
      <Main>
        <Container>
          <Box mb={1} display="flex">
            <ButtonMenu
              id="activity-filter"
              button={
                <Button variant="outlined" endIcon={<ArrowDropDownIcon />}>
                  問卷篩選：{getFilterTypeText(payload.filterType)}
                </Button>
              }
            >
              <MenuItem
                button
                onClick={handleChnageFilterType(FilterType.All)}
                selected={payload.filterType === FilterType.All}
              >
                全部問卷
              </MenuItem>
              <MenuItem
                button
                onClick={handleChnageFilterType(FilterType.Active)}
                selected={payload.filterType === FilterType.Active}
              >
                進行中問卷
              </MenuItem>
              <MenuItem
                button
                onClick={handleChnageFilterType(FilterType.Expired)}
                selected={payload.filterType === FilterType.Expired}
              >
                已結束問卷
              </MenuItem>
            </ButtonMenu>
          </Box>
          <Paper>
            <DataTable
              title="問卷列表"
              data={surveys.toJS()}
              SearchBarProps={{
                placeholder: '搜尋問卷',
                onChange: handleSearchChange,
                defaultValue: payload.query,
              }}
              columns={['問卷名稱', '開始時間', '結束時間', '']}
              serverSide
              isEmpty={surveysTotal === 0}
              renderColumns={renderColumns}
              renderDataRow={renderDataRow}
              MuiTablePaginationProps={{
                count: surveysTotal,
                labelRowsPerPage: '每頁幾筆',
                page,
                rowsPerPage,
                onPageChange: handleChangePage,
                onRowsPerPageChange: handleRowsPerPageChange,
              }}
              style={{
                tableLayout: 'fixed',
              }}
            />
          </Paper>
        </Container>
      </Main>
    </>
  );
};

Surveys.propTypes = {
  // react-router-dom props
  history: PropTypes.object.isRequired,
};

export default Surveys;