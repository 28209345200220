import { fetcher } from 'redux/apisLP';
import { UserApply } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  activityId?: string;
  emailTokenId?: string;
};
const useUserApplyByEmailToken = makeGetHook<UserApply, PathParams>(
  '/activities/{{activityId}}/user-applies/{{emailTokenId}}',
  fetcher
);
export default useUserApplyByEmailToken;
