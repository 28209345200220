import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { format } from '@eGroupTeam/utils/dateUtils';
import s3Url from 'utils/s3Url';
import { ImuActivityFile, UrlParams } from 'types';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';
import { useParams } from 'react-router';
import useClearInputFileValue from 'utils/useClearInputFileValue';
import encodeFilesNameToBase64 from 'utils/encodeFilesNameToBase64';
import apisOrg from 'redux/apisOrg';

import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import {
  Container,
  CircularProgress,
  Typography,
  LinearProgress,
  Box,
  Tooltip,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
} from '@eGroupTeam/material';
import { Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import IntroduceCard from 'components/IntroduceCard';
import IntroduceCardHeader from 'components/IntroduceCardHeader';
import IntroduceCardContent from 'components/IntroduceCardContent';
import IntroduceCardActions from 'components/IntroduceCardActions';
import FixedFab from 'components/FixedFab';
import ListItemBreakText from 'components/ListItemBreakText';
import {
  withReduxDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  fetchGetActivityFiles,
  fetchDeleteActivityFile,
  getActivityFiles,
} from './redux';

const CONFIRM_DELETE_DIALOG = 'confirmDeleteActivityFileDialog';

const ConfirmDeleteDialog = withReduxDialog(CONFIRM_DELETE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);

const ActivityFiles = () => {
  const dispatch = useDispatch();
  const { activityId } = useParams<UrlParams>();
  const selectedOrg = useSelector(getSelectedOrg);
  const activityFiles = useSelector(getActivityFiles);
  const inputEl = useRef<HTMLInputElement>(null);
  const clearInputFileValue = useClearInputFileValue(inputEl);
  const [completed, setCompleted] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<ImuActivityFile>();

  useEffect(() => {
    dispatch(
      fetchGetActivityFiles({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
      })
    );
  }, [activityId, dispatch, selectedOrg]);

  const actionsBeforeUpdate = () => {
    setIsUploading(true);
    setCompleted(0);
  };

  const actionsAfterUpdate = () => {
    clearInputFileValue();
    setIsUploading(false);
  };

  const handleAdd = (e: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    const { files } = e.target;
    if (!files) return
    const newFiles = encodeFilesNameToBase64(files);
    for (let i = 0; i < newFiles.length; i += 1) {
      const newFile = newFiles[i];
      if (newFile) {
        formData.append('file', newFile);
      }
    }
    actionsBeforeUpdate();
    apisOrg
      .fetchPostActivityFile(
        {
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          formData,
        },
        {
          // disable timeout
          timeout: 0,
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setCompleted(percentCompleted);
          },
        }
      )
      .then(() => {
        actionsAfterUpdate();
        dispatch(
          fetchGetActivityFiles({
            organizationId: selectedOrg.get('organizationId'),
            activityId,
          })
        );
      })
      .catch(() => {
        actionsAfterUpdate();
      });
  };

  const handleDelete = (file) => () => {
    setSelectedFile(file);
    dispatch(openDialog(CONFIRM_DELETE_DIALOG));
  };

  const handleConfirmDelete = () => {
    if (selectedFile) {
      dispatch(
        fetchDeleteActivityFile({
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          activityFileId: selectedFile.get('activityFileId'),
        })
      );
    }
  };

  const renderProgressBar = () => {
    if (!isUploading) return undefined;
    return (
      <Grid item xs={12}>
        <Paper>
          <LinearProgress variant="determinate" value={completed} />
          <Box p={2}>
            <Typography variant="body1">上傳中請稍候...</Typography>
          </Box>
        </Paper>
      </Grid>
    );
  };

  if (!activityFiles) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (activityFiles.size === 0) {
    return (
      <Container maxWidth="sm">
        {isUploading && (
          <>
            <LinearProgress variant="determinate" value={completed} />
            <Box p={2}>
              <Typography variant="body1">上傳中請稍候...</Typography>
            </Box>
          </>
        )}
        <IntroduceCard>
          <IntroduceCardHeader title="上傳活動檔案" />
          <IntroduceCardContent>
            <Typography variant="body1">
              您可以上傳活動相關的檔案例如PDF、Word、圖檔等等。
            </Typography>
          </IntroduceCardContent>
          <IntroduceCardActions>
            <Button
              variant="outlined"
              color="primary"
              component="label"
              htmlFor="file"
            >
              上傳檔案
            </Button>
            <input
              id="file"
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={handleAdd}
              ref={inputEl}
            />
          </IntroduceCardActions>
        </IntroduceCard>
      </Container>
    );
  }

  return (
    <Container>
      <ConfirmDeleteDialog
        primary="刪除檔案"
        message={
          <span>
            確定要刪除&quot;
            <strong>
              {selectedFile && selectedFile.get('activityFileName') as string}
            </strong>
            &quot;嗎？
          </span>
        }
        onConfirm={handleConfirmDelete}
      />
      <input
        id="file"
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={handleAdd}
        ref={inputEl}
      />
      <Tooltip title="上傳檔案" placement="top">
        <FixedFab
          color="secondary"
          // TODO: need fixed ts ignore.
          // @ts-ignore
          component="label"
          htmlFor="file"
        >
          <CloudUploadIcon />
        </FixedFab>
      </Tooltip>
      <Grid container spacing={1}>
        {renderProgressBar()}
        <Grid item xs={12}>
          <Paper>
            <List>
              {activityFiles.map((el: ImuActivityFile) => (
                <ListItem
                  key={el.get('activityFileId') as string}
                  button
                  component="a"
                  download={el.get('activityFileName')}
                  href={s3Url(el.get('activityFilePath') as string)}
                  title={el.get('activityFileName') as string}
                  target="_blank"
                >
                  <ListItemBreakText
                    primary={el.get('activityFileName') as string}
                    secondary={format(
                      el.get('activityFileCreateDateString') as string,
                      'PP pp'
                    )}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleDelete(el)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActivityFiles;
