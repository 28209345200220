import React, { useEffect } from 'react';

import getErrorFieldNames from 'utils/getErrorFieldNames';
import { useSelector } from 'react-redux';

import SchemaFields, { Schema } from 'components/SchemaFields';
import SurveyField from 'components/SurveyField';
import { getSchema } from './redux';

const SurveyForm = ({ handleSubmit, submitCount, errors }) => {
  const schema = useSelector(getSchema);

  useEffect(() => {
    if (submitCount === 0) return;
    const errorFieldNames = getErrorFieldNames(errors);
    // Using breakable for loop
    for (let i = 0; i < errorFieldNames.length; i += 1) {
      const fieldAnchorName = `position-${errorFieldNames[i]}`;
      const elements = document.querySelectorAll(`[name="${fieldAnchorName}"]`);
      // Checking if the marker exists in DOM
      if (elements.length && elements[0]) {
        const y = elements[0].getBoundingClientRect().top + window.scrollY - 80;
        window.scroll({
          top: y,
          behavior: 'smooth',
        });
        break;
      }
    }
  }, [errors, submitCount]);

  return (
    <form onSubmit={handleSubmit}>
      <SchemaFields schema={schema as Schema} component={SurveyField} />
    </form>
  );
};

export default SurveyForm;
