import React, { FC, useState } from 'react';

import academicIcon from "static/images/academicIcon.png";
import playIcon from "static/images/playIcon.png";
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { useDispatch } from 'react-redux';
import getVideoPlayList, { Video } from 'utils/getVideoPlayList';
import {
  makeStyles,
  useTheme,
  Drawer,
  List,
  IconButton,
  Typography,
  useMediaQuery,
  Box,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@eGroupTeam/material';
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import VideoPlayerDialog, { DIALOG } from './VideoPlayerDialog';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.layout.tutorialMenuWidth,
    "& .MuiDrawer-paper": {
      width: theme.layout.tutorialMenuWidth,
      borderTopLeftRadius: "30px",
      borderBottomLeftRadius: "30px",
      [theme.breakpoints.down("xs")]: {
        width: "300px",
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
      },
    },
  },
  topLevelMenu: {
    background: "#05C7F2",
    padding: theme.spacing(2),
    color: "white",
    borderTopLeftRadius: "26px",
    "& svg": {
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      borderTopLeftRadius: "18px",
      "& h6": {
        fontSize: "14px",
        lineHeight: "1.2",
      },
    },
  },
  topLevelIcon: {
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    background: "white",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
      [theme.breakpoints.down("xs")]: {
        "& img": {
          width: "70%",
        },
      },
    },
  },
  collpaseMenu: {
    marginTop: "1rem !important",
    boxShadow: "none",
    borderRadius: "0",
    "& .MuiAccordionSummary-root": {
      background: "#05C7F2",
      color: "white",
      "& svg": {
        color: "white",
      },
      "& .MuiAccordionSummary-content": {
        alignItems: "center",
        margin: "1rem 0 !important",
        [theme.breakpoints.down("xs")]: {
          "& h6": {
            fontSize: "14px",
            lineHeight: "1.2",
          },
        },
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "0",
      "& ul": {
        width: "100%",
      },
    },
  },
  smW35: {
    [theme.breakpoints.down("xs")]: {
      width: "35px !important",
    },
  },
  menuItem: {
    paddingRight: theme.spacing(3),
    minHeight: "70px",
    borderBottom: "1px solid rgba(112,112,112, 0.1)",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
    },
    "& .MuiListItemIcon-root": {
      paddingLeft: "4px",
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        "& img": {
          width: "45px",
        },
      },
    },
    "& .MuiListItemText-root .MuiTypography-root p": {
      overflowX: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        lineHeight: "1.2",
        overflow: "hidden",
      },
    },
    "&:hover": {
      background: "#EFFCFF",
    },
  },
}));

const videos = getVideoPlayList();

interface Props {
  open: boolean
  onCloseClick?: () => void
}

const ActivityTutorialDrawer: FC<Props> = ({ open, onCloseClick }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const classes = useStyles();
  const [selectedVideo, setSelectedVideo] = useState<Partial<Video>>({});

  const handleClick = (video: Video) => () => {
    dispatch(openDialog(DIALOG));
    setSelectedVideo(video);
  };

  return (
    <>
      <VideoPlayerDialog
        iframeSrc={selectedVideo.iframeSrc}
        iframeTitle={selectedVideo.iframeTitle}
      />
      <Drawer
        className={classes.drawer}
        variant={matches ? undefined : 'persistent'}
        onClose={onCloseClick}
        anchor="right"
        open={open}
      >
        <Box p={1} pr={0}>
          <Box
            className={classes.topLevelMenu}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Box className={classes.topLevelIcon} mr={2}>
                <img src={academicIcon} alt="Top" />
              </Box>
              <Typography variant="h6" fontWeight={500}>
                使用教學影片
              </Typography>
            </Box>
            <IconButton onClick={onCloseClick}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <List>
            {videos.map((el) => (
              <Tooltip key={el.iframeSrc} title={`${el.title}-${el.subtitle}`}>
                <ListItem button className={classes.menuItem} onClick={handleClick(el)}>
                  <ListItemIcon>
                    <img src={playIcon} alt="Icon" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body1">{el.title}-{el.subtitle}</Typography>
                    {el.subtitle && (
                      <Typography variant="body1">{el.subtitle}</Typography>
                    )}
                  </ListItemText>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default ActivityTutorialDrawer;
