import { fetcher } from 'redux/apisMember';
import { UserApply, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

const useMemberApplyActivities = makeGetHook<EntityList<UserApply>>(
  '/activities',
  fetcher
);

export default useMemberApplyActivities;
