import actionCreators from 'redux/actionCreators';
import { List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { getEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import * as schema from 'redux/schema';
import { format } from '@eGroupTeam/utils/dateUtils';

/** Actions */
export const {
  fetchGetActivityEdm,
  fetchGetActivityEdmRequest,
  fetchGetActivityEdmSuccess,
  fetchGetActivityEdmFailure,

  fetchGetActivityEdmUsers,
  fetchGetActivityEdmUsersRequest,
  fetchGetActivityEdmUsersSuccess,
  fetchGetActivityEdmUsersFailure,

  fetchPostNotificationEmail,
  fetchPostNotificationEmailRequest,
  fetchPostNotificationEmailSuccess,
  fetchPostNotificationEmailFailure,

  fetchPostTestNotificationEmail,
  fetchPostTestNotificationEmailRequest,
  fetchPostTestNotificationEmailSuccess,
  fetchPostTestNotificationEmailFailure,

  fetchGetOrganizationEdmTemplates,
  fetchGetOrganizationEdmTemplatesRequest,
  fetchGetOrganizationEdmTemplatesSuccess,
  fetchGetOrganizationEdmTemplatesFailure,

  fetchPostOrganizationEdmTemplate,
  fetchPostOrganizationEdmTemplateRequest,
  fetchPostOrganizationEdmTemplateSuccess,
  fetchPostOrganizationEdmTemplateFailure,

  fetchPostPreviewEdm,
  fetchPostPreviewEdmRequest,
  fetchPostPreviewEdmSuccess,
  fetchPostPreviewEdmFailure,

  setActiveStep,
  handleNext,
  handlePrev,
} = actionCreators.mainPages.activity.activityNotification;

/** Types */
export const FETCH_GET_ACTIVITY_EDM = fetchGetActivityEdm().type;
export const FETCH_GET_ACTIVITY_EDM_REQUEST = fetchGetActivityEdmRequest().type;
export const FETCH_GET_ACTIVITY_EDM_SUCCESS = fetchGetActivityEdmSuccess().type;
export const FETCH_GET_ACTIVITY_EDM_FAILURE = fetchGetActivityEdmFailure().type;

export const FETCH_GET_ACTIVITY_EDM_USERS = fetchGetActivityEdmUsers().type;
export const FETCH_GET_ACTIVITY_EDM_USERS_REQUEST = fetchGetActivityEdmUsersRequest()
  .type;
export const FETCH_GET_ACTIVITY_EDM_USERS_SUCCESS = fetchGetActivityEdmUsersSuccess()
  .type;
export const FETCH_GET_ACTIVITY_EDM_USERS_FAILURE = fetchGetActivityEdmUsersFailure()
  .type;

export const FETCH_POST_NOTIFICATION_EMAIL = fetchPostNotificationEmail().type;
export const FETCH_POST_NOTIFICATION_EMAIL_REQUEST = fetchPostNotificationEmailRequest()
  .type;
export const FETCH_POST_NOTIFICATION_EMAIL_SUCCESS = fetchPostNotificationEmailSuccess()
  .type;
export const FETCH_POST_NOTIFICATION_EMAIL_FAILURE = fetchPostNotificationEmailFailure()
  .type;

export const FETCH_POST_TEST_NOTIFICATION_EMAIL = fetchPostTestNotificationEmail()
  .type;
export const FETCH_POST_TEST_NOTIFICATION_EMAIL_REQUEST = fetchPostTestNotificationEmailRequest()
  .type;
export const FETCH_POST_TEST_NOTIFICATION_EMAIL_SUCCESS = fetchPostTestNotificationEmailSuccess()
  .type;
export const FETCH_POST_TEST_NOTIFICATION_EMAIL_FAILURE = fetchPostTestNotificationEmailFailure()
  .type;

export const FETCH_GET_ORGANIZATION_EDM_TEMPLATES = fetchGetOrganizationEdmTemplates()
  .type;
export const FETCH_GET_ORGANIZATION_EDM_TEMPLATES_REQUEST = fetchGetOrganizationEdmTemplatesRequest()
  .type;
export const FETCH_GET_ORGANIZATION_EDM_TEMPLATES_SUCCESS = fetchGetOrganizationEdmTemplatesSuccess()
  .type;
export const FETCH_GET_ORGANIZATION_EDM_TEMPLATES_FAILURE = fetchGetOrganizationEdmTemplatesFailure()
  .type;

export const FETCH_POST_ORGANIZATION_EDM_TEMPLATE = fetchPostOrganizationEdmTemplate()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_TEMPLATE_REQUEST = fetchPostOrganizationEdmTemplateRequest()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_TEMPLATE_SUCCESS = fetchPostOrganizationEdmTemplateSuccess()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_TEMPLATE_FAILURE = fetchPostOrganizationEdmTemplateFailure()
  .type;

export const FETCH_POST_PREVIEW_EDM = fetchPostPreviewEdm().type;
export const FETCH_POST_PREVIEW_EDM_REQUEST = fetchPostPreviewEdmRequest().type;
export const FETCH_POST_PREVIEW_EDM_SUCCESS = fetchPostPreviewEdmSuccess().type;
export const FETCH_POST_PREVIEW_EDM_FAILURE = fetchPostPreviewEdmFailure().type;

export const SET_ACTIVE_STEP = setActiveStep().type;
export const HANDLE_NEXT = handleNext().type;
export const HANDLE_PREV = handlePrev().type;
/** Selectors */
/**
 * Ui states
 */
const getUiStates = (state) =>
  state.getIn(['mainPages', 'ActivityNotification']);

export const getActiveStep = createSelector(getUiStates, (uiStates) =>
  uiStates.get('activeStep')
);

export const getActiveStepMax = createSelector(getUiStates, (uiStates) =>
  uiStates.get('activeStepMax')
);

/**
 * Api states
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'activity', 'activityNotification'], Map());

const getFetchGetActivityEdm = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetActivityEdm', Map())
);

const getFetchGetActivityEdmUsers = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetActivityEdmUsers', Map())
);

const getFetchPostNotificationEmail = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostNotificationEmail', Map())
);

const getFetchPostTestNotificationEmail = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostTestNotificationEmail', Map())
);

const getFetchGetOrganizationEdmTemplates = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetOrganizationEdmTemplates', Map())
);

const getFetchPostOrganizationEdmTemplate = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostOrganizationEdmTemplate', Map())
);

const getFetchPostPreviewEdm = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostPreviewEdm', Map())
);

export const getEmailIsSending = createSelector(
  getFetchPostNotificationEmail,
  (fetchResult) => fetchResult.get('isLoading')
);

export const getTestEmailIsSending = createSelector(
  getFetchPostTestNotificationEmail,
  (fetchResult) => fetchResult.get('isLoading')
);

export const getActivityEdm = createSelector(
  getFetchGetActivityEdm,
  getEntities,
  (fetchResult, entities) => {
    if (fetchResult.hasIn(['response', 'result'])) {
      return denormalize(
        fetchResult.getIn(['response', 'result']),
        schema.activityEdms,
        entities
      ).map((list) =>
        list.updateIn(
          ['notificationEmail', 'email', 'emailSendDateString'],
          (el) => format(el, 'yyyy-MM-dd HH:mm')
        )
      );
    }
    return undefined;
  }
);

export const getActivityEdmTotal = createSelector(
  getFetchGetActivityEdm,
  (fetchResult) => fetchResult.getIn(['response', 'total'])
);

export const getActivityEdmUsers = createSelector(
  getFetchGetActivityEdmUsers,
  getEntities,
  (fetchResult) => {
    if (fetchResult.has('response')) {
      return fetchResult
        .get('response')
        .map((el) =>
          el.set('label', el.get('userName')).set('value', el.get('userId'))
        );
    }
    return List();
  }
);

export const getOrganizationEdmTemplates = createSelector(
  getEntities,
  getFetchGetOrganizationEdmTemplates,
  (entities, fetchGet) =>
    denormalize(
      fetchGet.getIn(['response', 'result']),
      schema.organizationEdmTemplates,
      entities
    )
);

export const getOrganizationEdmTemplatesTotal = createSelector(
  getFetchGetOrganizationEdmTemplates,
  (fetchGet) => fetchGet.getIn(['response', 'total'])
);

export const getIsLoading = createSelector(
  getFetchGetOrganizationEdmTemplates,
  (fetchGet) => fetchGet.get('isLoading')
);

export const getIsPosting = createSelector(
  getFetchPostOrganizationEdmTemplate,
  (fetchPost) => fetchPost.get('isLoading')
);

export const getPreviewEdm = createSelector(
  getFetchPostPreviewEdm,
  (fetchGet) => fetchGet.get('response')
);

export const getIsPreviewPosting = createSelector(
  getFetchPostPreviewEdm,
  (fetchPost) => fetchPost.get('isLoading')
);
