import { createTheme } from '@eGroupTeam/material';
import {
  egPalette,
  egShadows,
  egShape,
  palette,
  typography,
  fontFamily,
} from '@eGroupTeam/material/stylesheet';

export const globalStyles = {
  '@global': {
    tspan: {
      fontFamily,
    },
    '.ck-editor__editable_inline': {
      minHeight: 200,
    },
    '.recharts-tooltip-wrapper': {
      '& .recharts-default-tooltip': {
        fontFamily,
        // '& .recharts-tooltip-label': {},
        // '& .recharts-tooltip-item-list': {
        //    '& .recharts-tooltip-item': {
        //     '& .recharts-tooltip-item-name': {},
        //     '& .recharts-tooltip-item-separator': {} ,
        //     '& .recharts-tooltip-item-value': {},
        //     '& .recharts-tooltip-item-unit': {}
        //   }
        // }
      },
    },
  },
};

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    layout: {
      sideMenuWidth: number;
      tutorialMenuWidth: number;
    };
    paletteVariant: {
      blue: {
        congressBlue: string;
        dodgerBlue: string;
        frenchPass: string;
      };
      gray: {
        main: string;
        concrete: string;
        mercury: string;
      };
      yellow: {
        yellowOrange: string;
      };
    };
    egPalette: typeof egPalette;
    egShadows: typeof egShadows;
    egShape: typeof egShape;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    layout?: {
      sideMenuWidth: number;
      tutorialMenuWidth: number;
    };
    paletteVariant: {
      blue: {
        congressBlue: string;
        dodgerBlue: string;
        frenchPass: string;
      };
      gray: {
        main: string;
        concrete: string;
        mercury: string;
      };
      yellow: {
        yellowOrange: string;
      };
    };
    egPalette: typeof egPalette;
    egShadows: typeof egShadows;
    egShape: typeof egShape;
  }
}

const theme = createTheme({
  layout: {
    sideMenuWidth: 240,
    tutorialMenuWidth: 300,
  },
  paletteVariant: {
    blue: {
      congressBlue: '#034C8A',
      dodgerBlue: '#2196F3',
      frenchPass: '#B4EDFC',
    },
    gray: {
      main: '#808080',
      concrete: '#F2F2F2',
      mercury: '#E6E6E6',
    },
    yellow: {
      yellowOrange: '#FBB03B',
    },
  },
  palette: {
    ...palette,
    background: {
      default: '#F5F6FA',
    },
  },
  typography,
  egPalette,
  egShadows,
  egShape,
  overrides: {
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
  },
});

// for develop
// eslint-disable-next-line no-console
if (process.env.NODE_ENV !== 'production') console.log(theme);

export default theme;
