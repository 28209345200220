import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  UPDATE_STEP,
  ADD_STEP,
  RESET_STEPS,
  SET_ACTIVE_STEP,
  HANDLE_NEXT,
  HANDLE_PREV,
} from './redux';

const defaultSteps = [
  {
    label: '填寫基本資料',
    fieldNames: ['userName', 'userPhone', 'userEmail'],
  },
];

const reducer = handleActions(
  {
    [UPDATE_STEP]: (state, action) =>
      state.update('steps', (steps) =>
        steps.update(action.payload.get('index'), (step) =>
          step.mergeDeep(action.payload.delete('index'))
        )
      ),
    [ADD_STEP]: (state, action) =>
      state.update('steps', (steps) => steps.push(action.payload)),
    [RESET_STEPS]: (state) => state.set('steps', fromJS(defaultSteps)),
    [SET_ACTIVE_STEP]: (state, action) =>
      state.set('activeStep', action.payload),
    [HANDLE_NEXT]: (state) =>
      state.update('activeStep', (el) => {
        const nextStep = el + 1;
        if (nextStep <= state.get('steps').size) {
          return nextStep;
        }
        return el;
      }),
    [HANDLE_PREV]: (state) =>
      state.update('activeStep', (el) => {
        const prevStep = el - 1;
        if (prevStep >= 0) {
          return prevStep;
        }
        return el;
      }),
  },
  fromJS({
    steps: defaultSteps,
    activeStep: 0,
  })
);

export default reducer;
