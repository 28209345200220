import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  reduxForm,
  change,
  formValueSelector,
  Field,
  Form,
} from 'redux-form/immutable';
import { orgValidate } from 'utils/validates';
import apis from 'redux/apis';

import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import ImageUploadArea from 'components/ImageUploadArea';
import { fetchPostOrganization } from './redux';

export const FORM = 'newOrganizationForm';

const selector = formValueSelector(FORM);

const NewOrganizationForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const organizationLogoPath = useSelector((state) =>
    selector(state, 'organizationLogoPath')
  );
  const [isUploading, setIsUploading] = React.useState(false);

  const handleCustomSubmit = (values) => {
    dispatch(fetchPostOrganization(values.toJS()));
  };

  const handleCoverDrop = React.useCallback(
    (acceptedFiles) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      setIsUploading(true);
      apis.member
        .fetchPostOrganizationLogo({
          formData,
        })
        .then((response) => {
          dispatch(
            change(
              FORM,
              'organizationLogoPath',
              response.data.organizationLogoPath
            )
          );
          setIsUploading(false);
        })
        .catch(() => {
          setIsUploading(false);
        });
    },
    [dispatch]
  );

  return (
    <Form onSubmit={handleSubmit(handleCustomSubmit)}>
      <Field
        name="organizationName"
        label="名稱"
        component={TextLoadingField}
        fullWidth
        required
        margin="normal"
      />
      <Field
        name="organizationContactPerson"
        label="聯絡人"
        component={TextLoadingField}
        fullWidth
        required
        margin="normal"
      />
      <Field
        name="organizationContactEmail"
        label="Email"
        component={TextLoadingField}
        fullWidth
        required
        margin="normal"
      />
      <Field
        name="organizationContactPhone"
        label="聯絡電話"
        component={TextLoadingField}
        fullWidth
        required
        margin="normal"
      />
      <Field
        name="organizationContactAddress"
        label="聯絡地址"
        component={TextLoadingField}
        fullWidth
        required
        margin="normal"
      />
      <Field component="input" name="organizationLogoPath" type="hidden" />
      <ImageUploadArea
        onDrop={handleCoverDrop}
        uploadedImagePath={organizationLogoPath}
        uploading={isUploading}
        ratio="500:100"
      >
        上傳單位 Logo
        <br />
        500*100
      </ImageUploadArea>
    </Form>
  );
};

export default reduxForm({
  form: FORM,
  validate: orgValidate,
})(NewOrganizationForm);
