import React from 'react';

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Link,
} from '@eGroupTeam/material';
import iosHint from 'static/images/ios-hint.png';

import useIsSupportMediaDevices from '@eGroupTeam/hooks/useIsSupportMediaDevices';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';

export const ALERT_OS_NOT_SUPPORT_DIALOG = 'alertOsNotSupportDialog';

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(2, 0),
  },
  img: {
    width: '100%',
    marginTop: theme.spacing(),
  },
}));

const AlertOsNotSupportDialog = ({ isOpen }) => {
  const classes = useStyles();
  const [, info] = useIsSupportMediaDevices();
  let title = '不支援此瀏覽器';
  let message = '請使用預設瀏覽器開啟網頁';
  let imgSrc = '';

  if (info.iosVersionNotSupprot) {
    title = '不支援此 iOS 版本';
    message = 'iOS 版本需要 11 以上才可以使用。';
  } else if (info.iosBrowserNotSupport) {
    title = '不支援此瀏覽器';
    message = '請長按下方連結使用 safari 開啟網頁。';
    imgSrc = iosHint;
  } else if (info.androidBrowserNotSupport) {
    title = '不支援此瀏覽器';
    message = '請使用 chrome 開啟網頁。';
  }

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <Link
          variant="h6"
          color="secondary"
          className={classes.link}
          href={window.location.href}
        >
          請長按此連結
        </Link>
        {imgSrc && <img src={imgSrc} className={classes.img} alt="hint" />}
      </DialogContent>
    </Dialog>
  );
};

export default withReduxDialog(ALERT_OS_NOT_SUPPORT_DIALOG)(
  AlertOsNotSupportDialog
);
