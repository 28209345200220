import { useCallback, useMemo } from 'react';

import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { DIALOG as SHARE_DIALOG } from 'components/ActivityShareDialog';
import apis from 'redux/apis';
import { Activity } from 'types';

/**
 * Handle activity share button click
 * How to use react hook with ts.
 * https://levelup.gitconnected.com/usetypescript-a-complete-guide-to-react-hooks-and-typescript-db1858d1fb9c
 */
const useHandleActivityShare = (activity?: Activity): [() => void, string] => {
  const dispatch = useDispatch();
  const url = useMemo(() => {
    if (activity) {
      return `${window.location.origin}/activities/${
        activity.activityId
      }?${queryString.stringify({
        openExternalBrowser: 1,
      })}`;
    }
    return '';
  }, [activity]);

  const handleShareClick = useCallback(() => {
    if (!activity) return;
    if (navigator.share) {
      try {
        navigator.share({
          title: activity.activityName,
          text: activity.activityDescription,
          url,
        });
      } catch (error) {
        apis.tools.createLog({
          function: 'useHandleActivityShare',
          browserDescription: window.navigator.userAgent,
          jsonData: {
            action: 'navigator.share',
            data: activity,
            error: error as Error,
          },
          level: 'ERROR',
        });
        dispatch(openDialog(SHARE_DIALOG));
      }
    } else {
      dispatch(openDialog(SHARE_DIALOG));
    }
  }, [activity, dispatch, url]);

  return [handleShareClick, url];
};

export default useHandleActivityShare;
