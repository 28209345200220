import React, { FC, HTMLAttributes } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundImage: "linear-gradient(to bottom, #2176a8, #1d6995, #195c83, #154f71, #12435f)",
    height: "250px",
    padding: "7rem",
    color: "white",
    "& h2": {
      fontSize: "2rem",
      lineHeight: "1.2",
    },
  },
});

const Header: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { className, ...other } = props;
  const classes = useStyles();
  return <div className={clsx(className, classes.root)} {...other} />;
};

export default Header;
