import unique from '@eGroupTeam/utils/unique';

/**
 * Modules mapping routes.
 */
const moduleRouteMapping = {
  common: [
    '/',
    '/fb-login',
    '/g-login',
    '/train',
    '/activities/:activityId',
    '/activities/:activityId/user-applies/:userApplyId/online-checkin',
    '/activities/:activityId/user-applies/:emailTokenId',
    '/activities/:activityId/select-apply-class',
    '/activities/:activityId/apply',
    '/activities/:activityId/apply-train-qrcode',
    '/activities/:activityId/apply-done',
    '/pricing',
    '/reference',
    '/activity-404',
    '/surveys/:surveyId',
    '/surveys/:surveyId/done',
    '/surveys/:surveyId/check',
    '/email-fill-survey',
    '/phone-fill-survey',
    '/privacy',
    '/terms',
    '/not-found',
    '/org/invitation',
    '*',
    '/me',
    '/me/*',
    '/me/new-org',
    '/me/carrier',
    '/me/pay-complete',
    '/me/pay-failed',
    '/me/checkout',
    '/me/profile',
    '/me/profile/activities',
    '/me/profile',
    '/me/profile/*',
  ],
  orgInfo: ['/me/org', '/me/org/info'],
  orgMembers: ['/me/org', '/me/org/members'],
  orgRoles: ['/me/org', '/me/org/roles'],
  hrmUsers: ['/me/hrm', '/me/hrm/users'],
  hrmImportUsers: ['/me/hrm', '/me/hrm/import-users'],
  hrmImportActivities: ['/me/hrm', '/me/hrm/import-activities'],
  activityDashboard: [
    '/me/activities',
    '/me/activities/:activityId',
    '/me/activities/:activityId/quick-guide',
    '/me/activities/:activityId/dashboard',
  ],
  activityCheckIn: [
    '/me/activities',
    '/me/activities/:activityId',
    '/me/activities/:activityId/quick-guide',
    '/me/activities/:activityId/check-in',
  ],
  activityPeople: [
    '/me/activities',
    '/me/activities/:activityId',
    '/me/activities/:activityId/quick-guide',
    '/me/activities/:activityId/people',
  ],
  activityEdit: [
    '/me/new-activity',
    '/me/activities',
    '/me/activities/:activityId',
    '/me/activities/:activityId/quick-guide',
    '/me/activities/:activityId/edit',
  ],
  activityNotification: [
    '/me/activities',
    '/me/activities/:activityId',
    '/me/activities/:activityId/quick-guide',
    '/me/activities/:activityId/notification',
  ],
  activityFields: [
    '/me/activities',
    '/me/activities/:activityId',
    '/me/activities/:activityId/quick-guide',
    '/me/activities/:activityId/fields',
  ],
  activityFiles: [
    '/me/activities',
    '/me/activities/:activityId',
    '/me/activities/:activityId/quick-guide',
    '/me/activities/:activityId/files',
  ],
  activityPermissions: [
    '/me/activities',
    '/me/activities/:activityId',
    '/me/activities/:activityId/quick-guide',
    '/me/activities/:activityId/permissions',
  ],
  activitySurveys: [
    '/me/activities',
    '/me/activities/:activityId/surveys',
    '/me/activities/:activityId/surveys/:surveyId',
    '/me/activities/:activityId/surveys/:surveyId/editor',
    '/me/activities/:activityId/surveys/:surveyId/analysis',
  ],
  activityCertificates: [
    '/me/activities',
    '/me/activities/:activityId/certificates',
  ],
  activitySettings: ['/me/activities', '/me/activities/:activityId/settings'],
  notificationTemplates: ['/me/notification/templates'],
  surveys: [
    '/me/surveys',
    '/me/surveys/:surveyId',
    '/me/surveys/:surveyId/editor',
    '/me/surveys/:surveyId/analysis',
  ],
  qrpass: [
    '/me/qrpass',
    '/me/qrpass/records',
    '/me/qrpass/records/:qrpassRecordId',
    '/me/qrpass/tags',
    '/me/qrpass/products',
    '/me/qrpass/orders',
    '/me/qrpass/orders/:paymentOrderId',
    '/me/qrpass/settings',
    '/me/qrpass/org',
    '/me/qrpass/org/info',
    '/me/qrpass/org/members',
  ],
  orders: [
    '/me/micepoint',
    '/me/micepoint/shop',
    '/me/micepoint/orders',
    '/me/micepoint/orders/:paymentOrderId',
    '/me/micepoint/transactions',
    '/me/micepoint/transactions/:paymentOrderId',
    '/me/activities/:activityId/transactions',
    '/me/activities/:activityId/transactions/:paymentOrderId',
  ],
};

/**
 * iteration routes and filter all nest path by availableRoutes
 */
function iterationAndFilterRoutes(routes, availableRoutes: string[]) {
  return routes
    .filter((route) => {
      if (route.path) {
        return availableRoutes.includes(route.path);
      }
      return true;
    })
    .map((route) => {
      if (route.routes) {
        return {
          ...route,
          routes: iterationAndFilterRoutes(route.routes, availableRoutes),
        };
      }
      return route;
    });
}

export default function filterRoutes(routes, availableModules: string[]) {
  if (availableModules) {
    /**
     * Get all availableRoutes by availableModules.
     */
    let availableRoutes: string[] = [];
    unique(['common', ...availableModules]).forEach((permission) => {
      availableRoutes = availableRoutes.concat(moduleRouteMapping[permission]);
    });
    return iterationAndFilterRoutes(routes, availableRoutes);
  }
  return routes;
}
