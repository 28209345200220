import actionCreators from 'redux/actionCreators';

/** Actions */
export const {
  fetchPatchUserApplyStatus,
  fetchPatchUserApplyStatusRequest,
  fetchPatchUserApplyStatusSuccess,
  fetchPatchUserApplyStatusFailure,
} = actionCreators.landingPages.activity.activityCancelApply;

/** Types */
export const FETCH_PATCH_USER_APPLY_STATUS = fetchPatchUserApplyStatus().type;
export const FETCH_PATCH_USER_APPLY_STATUS_REQUEST = fetchPatchUserApplyStatusRequest()
  .type;
export const FETCH_PATCH_USER_APPLY_STATUS_SUCCESS = fetchPatchUserApplyStatusSuccess()
  .type;
export const FETCH_PATCH_USER_APPLY_STATUS_FAILURE = fetchPatchUserApplyStatusFailure()
  .type;
