import React, { FC } from 'react';
import Button, { ButtonProps } from '@eGroupTeam/material/Button';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@eGroupTeam/material';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';
import { ActivityType } from 'enums';
import Center from '@eGroupTeam/material-layout/Center';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import MicepointTrialFeesDisplay from 'components/MicepointTrialFeesDisplay';
import { useHistory } from 'react-router';
import useActivityTrailFee from 'utils/useActivityTrailFee';

export const DIALOG = 'TrialFeeDialog';

type ActivityClassDay = {
  activityClassDayEndTimeString: Date | null;
  activityClassDayStartTimeString: Date | null;
};

type ActivityClass = {
  activityClassDayList: ActivityClassDay[];
  activityClassLimitCount: number;
};

type ServiceProduct = {
  serviceProductId: string;
};

export type Payload = {
  activityClassList?: ActivityClass[];
  activityType?: ActivityType;
  paymentOrder: {
    paymentProductList: ServiceProduct[];
  };
  serviceProductIsFix?: number;
  peopleChangeCount?: number;
  countPeopleByDB?: number;
  activityId?: string;
};
export interface TrialFeeDialogProps {
  payload?: Payload;
  onConfirmClick?: ButtonProps['onClick'];
  loading?: boolean;
}

const TrialFeeDialog: FC<TrialFeeDialogProps & WithReduxDialogProps> = ({
  isOpen,
  handleClose,
  payload,
  onConfirmClick,
  loading,
}) => {
  const history = useHistory();
  const selectedOrg = useSelector(getSelectedOrg);

  const {
    activityClassList,
    activityType,
    paymentOrder,
    serviceProductIsFix,
    peopleChangeCount,
    countPeopleByDB,
    activityId,
  } = payload || {};

  const { data, isValidating } = useActivityTrailFee(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    {
      activityClassList,
      activityType,
      paymentOrder,
    },
    {
      activityId,
      serviceProductIsFix,
      peopleChangeCount,
      countPeopleByDB,
    }
  );

  const renderContent = () => {
    if (isValidating || !data) {
      return (
        <Center height={200}>
          <CircularProgress />
        </Center>
      );
    }
    return <MicepointTrialFeesDisplay data={data} />;
  };

  const renderActions = () => {
    if (!data) {
      return (
        <>
          <Button onClick={handleClose}>取消</Button>
          <Button variant="contained" color="primary" disabled>
            確認付款
          </Button>
        </>
      );
    }
    if (data.isFree) {
      return (
        <Button onClick={handleClose} variant="contained" color="primary">
          確認
        </Button>
      );
    }
    return (
      <>
        <Button onClick={handleClose}>取消</Button>
        {data.isEnough ? (
          <Button
            onClick={onConfirmClick}
            variant="contained"
            color="primary"
            loading={loading}
          >
            確認付款
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push('/me/micepoint/shop');
            }}
          >
            購買 MICEPoint
          </Button>
        )}
      </>
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<unknown, TrialFeeDialogProps>(
  TrialFeeDialog
);
