import React, { useState, VFC } from "react";
import { makeStyles, Tab, Tabs } from "@material-ui/core";

import Container from "@eGroupTeam/material/Container";
import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import { Box, Button } from "@eGroupTeam/material";
import useTab from "@eGroupTeam/hooks/useTab";

import useServiceReferences from "utils/useServiceReferences";
import CustomTogglePanel from "./CustomTogglePanel";
import PhotoModal from "./PhotoModal";

const useStyles = makeStyles((theme) => ({
  contentTypeSection: {
    background: "#f0fcff",
    padding: "5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "5rem 0",
    },
  },
  sectonTitle: {
    fontSize: "1.57rem",
    color: "#2074a6 !important",
    marginBottom: "3rem",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "unset",
    },
    "& .MuiButtonBase-root": {
      width: "50%",
      maxWidth: "unset",
      padding: "20px",
      fontSize: "15px",
      borderRadius: "50px",
    },
    "& .MuiTabs-flexContainer": {
      border: "1px solid #dee2e6",
      borderRadius: "50px",
    },
    "& .Mui-selected": {
      backgroundColor: "#5fd3f3 !important",
      color: "white",
      fontSize: "16px",
    },
  },
  tabSection: {
    marginTop: "3rem",
    "& .tab-pane:not(.show)": {
      opacity: "0",
    },
  },
  loadMoreBtn: {
    marginTop: "130px",
    "& button": {
      color: "#2178AB",
      borderColor: "#2178AB",
    },
  },
}));

const ContentTypeSection: VFC = () => {
  const classes = useStyles();
  const { value, handleChange } = useTab("tab");
  const [size, setSize] = useState(10)
  const { data } = useServiceReferences(undefined, {
    startIndex: 0,
    size
  })

  return (
    <Box className={classes.contentTypeSection}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Box px={2}>
              <Typography
                variant="h3"
                fontWeight={700}
                align="center"
                className={classes.sectonTitle}
              >
                回顧精彩時刻，期待再次相見
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={8}>
            <Box className={classes.tabs} px={2}>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={(_, value) => handleChange(value)}
                centered
              >
                <Tab label="Event Video" />
                <Tab label="Event Photo" />
              </Tabs>
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <Box className={classes.tabSection} px={2}>
              <CustomTogglePanel
                index={0}
                value={value}
                data={data?.source.filter(el => el.serviceReferenceYoutubeURL)}
              />
              {/* <CustomTogglePanel
                index={1}
                value={value}
                data={data?.source.filter(el => !el.serviceReferenceYoutubeURL)}
              /> */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className={classes.loadMoreBtn}
              display="flex"
              justifyContent="center"
            >
              {
                data && size < data.total &&
                <Button variant="outlined" rounded onClick={() => setSize(val => val + 10)}>
                  Load More
                </Button>
              }
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContentTypeSection;
