import React, { FC, useCallback } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@eGroupTeam/material';
import useAlertOSNotSupport from 'utils/useAlertOSNotSupport';
import useWindowSize from 'utils/useWindowSize';

import MediaStreamClipper, {
  MediaStreamClipperProps,
} from '@eGroupTeam/material-lab/MediaStreamClipper';
import Container from '@eGroupTeam/material/Container';
import Typography from '@eGroupTeam/material/Typography';
import clsx from 'clsx';
import FacePlaceholder from './FacePlaceholder';
import TextBox from './TextBox';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      minHeight: '100vh',
      background: '#000',
      overflow: 'hidden',
    },
    mediaStreamClipper: {
      objectFit: 'cover',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',

      position: 'absolute',
      top: '10%',
      left: '50%',
      transform: ' translate(-50%, 0)',
    },
    logo: {
      position: 'fixed',
      bottom: theme.spacing(1),
      transform: 'translate(-50%, 0)',
      left: '50%',
      maxWidth: 200,

      '& img': {
        width: '100%',
      },
    },
    facePlaceholder: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 400,
      width: '100%',

      [theme.breakpoints.down('xs')]: {
        maxWidth: 300,
      },
    },
  });

export interface FaceRecognizerProps
  extends Omit<MediaStreamClipperProps, 'classes'> {
  /**
   * Display error message.
   */
  errorMsg?: string;
}

const FaceRecognizer: FC<FaceRecognizerProps & WithStyles<typeof styles>> = (
  props
) => {
  const { classes, className, style, facingMode, errorMsg, ...other } = props;
  const [width, height] = useWindowSize();
  const isOSNotSupport = useAlertOSNotSupport();

  const handleUserMediaFulfilled = useCallback((video) => {
    // eslint-disable-next-line no-param-reassign
    video.onloadedmetadata = function (e) {
      video.play();
    };
  }, []);

  if (isOSNotSupport) {
    return <div />;
  }

  return (
    <div className={clsx(className, classes.root)} style={style}>
      <MediaStreamClipper
        width={width}
        height={height}
        className={classes.mediaStreamClipper}
        mirrored={facingMode !== 'environment'}
        facingMode={facingMode}
        autoPlay
        onGetUserMediaFulfilled={handleUserMediaFulfilled}
        playsInline
        muted
        {...other}
      />
      <div className={classes.content}>
        <div className={classes.facePlaceholder}>
          <FacePlaceholder />
        </div>
        <Container maxWidth="sm" disableGutters>
          {errorMsg ? (
            <TextBox>
              <Typography align="center" variant="h4">
                <div dangerouslySetInnerHTML={{ __html: errorMsg }} />
              </Typography>
            </TextBox>
          ) : (
            <TextBox>
              <Typography align="center" variant="h4">
                請將臉部對準鏡頭
              </Typography>
            </TextBox>
          )}
        </Container>
      </div>
    </div>
  );
};

export default withStyles(styles)(FaceRecognizer);
