import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  SET_SELECTED_ACTIVITY_CLASS_NAME,
  SET_SELECTED_ACTIVITY_CLASS_ID,
  HANDLE_TUTORIAL_DRAWER_OPEN,
  HANDLE_TUTORIAL_DRAWER_CLOSE,
  RESET,
} from './redux';

const initialState = fromJS({
  selectedActivityClassName: '全部',
  tutorialDrawerIsOpen: false,
});

const reducer = handleActions(
  {
    [SET_SELECTED_ACTIVITY_CLASS_NAME]: (state, action) =>
      state.set('selectedActivityClassName', action.payload),
    [SET_SELECTED_ACTIVITY_CLASS_ID]: (state, action) =>
      state.set('selectedActivityClassId', action.payload),
    [HANDLE_TUTORIAL_DRAWER_OPEN]: (state) =>
      state.set('tutorialDrawerIsOpen', true),
    [HANDLE_TUTORIAL_DRAWER_CLOSE]: (state) =>
      state.set('tutorialDrawerIsOpen', false),
    [RESET]: () => initialState,
  },
  initialState
);

export default reducer;
