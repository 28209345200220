import React from 'react';
import Button from '@eGroupTeam/material/Button';
import {
  makeStyles,
  Hidden,
  MobileStepper,
  CircularProgress,
  Grid,
  Segment,
  SegmentHeader,
  Typography,
  SegmentContent,
} from '@eGroupTeam/material';
import { Checkbox } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useServiceProducts from 'utils/useServiceProducts';
import Center from '@eGroupTeam/material-layout/Center';
import ProductCard from 'components/ProductCard';
import Toolbar from './Toolbar';
import { handleNextStep, handlePrevStep } from './actions';

const useStyles = makeStyles(() => ({
  card: {
    width: 300,
  },
}));

const StepSelectProducts = ({ activeStep, value, onChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useServiceProducts({
    serviceName: 'micepass',
  });

  const handleNext = () => {
    dispatch(handleNextStep());
  };

  const handlePrev = () => {
    dispatch(handlePrevStep());
  };

  const renderContent = () => {
    if (!data) {
      return (
        <Center height={200}>
          <CircularProgress />
        </Center>
      );
    }
    return (
      <Grid container spacing={2}>
        {data?.source
          .filter((el) => el.serviceProductIsFix !== 1)
          .map((el) => (
            <Grid key={el.serviceProductId} item>
              <ProductCard
                className={classes.card}
                title={el.serviceProductNameZh}
                subTitle={el.serviceProductNameZh}
                description={el.serviceProductDescriptionZh}
                priceDiscount={el.serviceProductUnitPriceZh}
                priceOriginal={el.serviceProductListPriceZh}
                priceUnit={el.serviceProductUnitZh}
                onClick={() => {
                  let next = [...value];
                  if (value.includes(el.serviceProductId)) {
                    next = next.filter((id) => id !== el.serviceProductId);
                  } else {
                    next = [...next, el.serviceProductId];
                  }
                  onChange(next);
                }}
                hover
                actions={
                  <Checkbox
                    tabIndex={-1}
                    disableRipple
                    checked={value.includes(el.serviceProductId)}
                  />
                }
              />
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <>
      <Segment variant="outlined">
        <SegmentHeader>
          <Typography variant="h5">加購服務</Typography>
          <Typography variant="body2" gutterBottom>
            選擇需要加購的服務
          </Typography>
        </SegmentHeader>
        <SegmentContent>
          {renderContent()}
          <Hidden smDown>
            <Toolbar
              activeStep={activeStep}
              onPrevClick={handlePrev}
              onNextClick={handleNext}
            />
          </Hidden>
        </SegmentContent>
      </Segment>
      <Hidden mdUp>
        <MobileStepper
          variant="progress"
          steps={6}
          position="bottom"
          activeStep={activeStep}
          nextButton={
            <Button size="small" color="primary" onClick={handleNext}>
              繼續
            </Button>
          }
          backButton={
            <Button size="small" onClick={handlePrev}>
              上一步
            </Button>
          }
        />
      </Hidden>
    </>
  );
};

export default StepSelectProducts;
