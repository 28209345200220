import React, { FC } from 'react';

import { makeStyles } from '@eGroupTeam/material';

import { QuestionOptionType, Question } from 'types';
import QuestionOption from '../QuestionOption';

const useStyles = makeStyles((theme) => ({
  option: {
    padding: theme.spacing(1.5, 0),
  },
}));

export interface QuestionOptionsProps {
  type: QuestionOptionType;
  question: Question;
}

const QuestionOptions: FC<QuestionOptionsProps> = ({
  type = 'radio',
  question,
}) => {
  const classes = useStyles();
  const options = question.optionList || [];
  return (
    <>
      {options.map((option, index) => (
        <QuestionOption
          key={option.optionId}
          className={classes.option}
          index={index}
          type={type}
        >
          {option.optionName}
        </QuestionOption>
      ))}
    </>
  );
};

export default QuestionOptions;
