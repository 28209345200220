import { combineReducers } from 'redux-immutable';
import { combineEpics } from 'redux-observable';

import common from './common/reducers';

import commonEpic from './common/epics';

export const modules = combineReducers({
  common,
});

export const modulesEpics = combineEpics(commonEpic);
