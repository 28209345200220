import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@eGroupTeam/material';

const TextBoxComponent = ({ className, classes, ...other }) => (
  <div className={clsx(className, classes.root)} {...other} />
);

export default withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 1),
    marginTop: theme.spacing(4),
    letterSpacing: 4,
  },
}))(TextBoxComponent);
