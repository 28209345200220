import React, { FC } from 'react';

import useOrgMpTransactionRecord from 'utils/useOrgMpTransactionRecord';
import { getSelectedOrg } from 'redux/modules/common';
import { useHistory, useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Activity, UrlParams } from 'types';

import BackAppbar from '@eGroupTeam/material-module/BackAppbar';
import Main from '@eGroupTeam/material-layout/Main';
import Center from '@eGroupTeam/material-layout/Center';
import {
  makeStyles,
  Container,
  Paper,
  CircularProgress,
  Typography,
  Box,
} from '@eGroupTeam/material';
import PaymentProductList from 'components/PaymentProductList';
import OrderTotalAmount from 'components/OrderTotalAmount';
import ScrollableTypography from 'components/ScrollableTypography';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #C0C3CE",
    boxShadow: "0 3px 16px rgba(10, 75, 109, 0.08)",
    borderRadius: "8px 8px 0 0",
  },
  header: {
    padding: theme.spacing(3),
    "& h3": {
      fontSize: "1.75rem",
      lineHeight: "1.2",
    },
  },
}));

export interface ActivityTransactionProps {
  activity: Activity;
}

const ActivityTransaction: FC<ActivityTransactionProps> = ({ activity }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const selectedOrg = useSelector(getSelectedOrg);
  const { paymentOrderId } = useParams<UrlParams>();
  const organizationId = selectedOrg.get('organizationId');
  const { data } = useOrgMpTransactionRecord({
    organizationId,
    paymentOrderId,
  });

  if (!data) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  const order = data.paymentOrder;

  return (
    <>
      <BackAppbar
        push={history.push}
        go={history.go}
        pointerTrigger={location}
        position="fixed"
        elevation={0}
        backPath={`/me/activities/${activity.activityId}/transactions`}
      >
        <ScrollableTypography variant="h6">
          {order.paymentOrderId}
        </ScrollableTypography>
      </BackAppbar>
      <Main>
        <Container>
          <Paper className={classes.paper}>
            <Box className={classes.header}>
              <Typography variant="h3" fontWeight={400}>
                MICEPoint 使用內容
              </Typography>
            </Box>
            <Box>
              <PaymentProductList
                paymentOrderType={data.paymentOrder.paymentOrderType}
                data={data.paymentProductList}
              />
              <OrderTotalAmount
                paymentOrderType={data.paymentOrder.paymentOrderType}
                amount={order.paymentOrderTotalAmount}
              />
            </Box>
          </Paper>
        </Container>
      </Main>
    </>
  );
};

export default ActivityTransaction;
