import { FormErrors } from 'redux-form';

export default function getErrorFieldNames(
  errors: FormErrors,
  prefix?: string
) {
  let result: string[] = [];
  const keys = Object.keys(errors);
  keys.forEach((key) => {
    const error = errors[key];
    if (typeof error === 'string') {
      if (prefix) {
        result.push(`${prefix}.${key}`);
      } else {
        result.push(key);
      }
    } else if (prefix) {
      result = result.concat(getErrorFieldNames(error, `${prefix}.${key}`));
    } else {
      result = result.concat(getErrorFieldNames(error, key));
    }
  });
  return result;
}
