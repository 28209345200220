import React, { FC } from 'react';
import {
  createStyles,
  IconButton,
  IconButtonProps,
  Theme,
  WithStyles,
  withStyles,
} from '@eGroupTeam/material';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '6px 3px',
      '& div': {
        width: 18,
        height: 2,
        marginBottom: 3,
        position: 'relative',

        background: theme.palette.primary.main,
        borderRadius: 10,
      },
      '& div:last-child': {
        marginBottom: 0,
      },
    },
  });

export type HamburgerProps = IconButtonProps & WithStyles<typeof styles>;

const Hamburger: FC<HamburgerProps> = ({ classes, ...other }) => (
  <IconButton {...other}>
    <div className={classes.root}>
      <div />
      <div />
      <div />
    </div>
  </IconButton>
);

export default withStyles(styles)(Hamburger);
