import React, { FC, ReactNode } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Logo from 'components/Logo';
import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import { Box } from "@eGroupTeam/material";
import Paper from "@eGroupTeam/material/Paper";

const useStyles = makeStyles((theme) => ({
  hAuto: {
    height: "auto",
  },
  logo: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"
  },
  paper: {
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
    height: "100%",
  },
  header: {
    "& p": {
      fontSize: "20px",
      fontWeight: "500",
      color: "#505050",
      marginLeft: theme.spacing(1),
    },
    borderBottom: "1px solid #DBDDE3",
    "@media screen and (max-width: 400px)": {
      flexWrap: "wrap",
      "& p": {
        marginLeft: "0",
        textAlign: "center",
      },
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  pointCell: {
    "& div": {
      background: "#034C8C",
      width: "24px",
      height: "24px",
      color: "white",
      borderRadius: "50%",
    },
    "& div+p": {
      color: "#034C8C",
      fontWeight: "bold",
      margin: theme.spacing(0, 1),
    },
    "& p:last-child": {
      color: "#B1B1B1",
      textDecoration: "line-through",
    },
  },
  unitText: {
    color: "#B1B1B1",
    fontSize: "18px",
  },
}));

interface Props {
  title: string;
  subTitle: string;
  description: string;
  priceOriginal: number;
  priceDiscount: number;
  priceUnit: string;
  actions: ReactNode;
}

const ServiceItem: FC<Props> = (props) => {
  const classes = useStyles();

  const { title, description, priceOriginal, priceDiscount, priceUnit, actions } = props;

  return (
    <Grid item xs={12} sm={12} md={6} className={classes.hAuto}>
      <Paper className={classes.paper}>
        <Box
          p={3}
          pb={2}
          display="flex"
          alignItems="center"
          className={classes.header}
        >
          <Logo variant="rounded" className={classes.logo}/>
          <Typography variant="body1">{title}</Typography>
        </Box>
        <Box p={3}>
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1">{description}</Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={4}
            mb={2}
          >
            <Box display="flex" className={classes.pointCell}>
              <Box display="flex" justifyContent="center" alignItems="center">
                M
              </Box>
              <Typography variant="body1">{priceOriginal}</Typography>
              <Typography variant="body1">{priceDiscount}</Typography>
            </Box>
            <Typography variant="body1" className={classes.unitText}>
              {priceUnit}
            </Typography>
          </Box>
          {actions}
        </Box>
      </Paper>
    </Grid>
  );
};

export default ServiceItem;
