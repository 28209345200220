import { combineEpics } from 'redux-observable';
import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import { replace } from 'connected-react-router/immutable';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  fetchPostLogoutRequest,
  fetchPostLogoutSuccess,
  fetchPostLogoutFailure,
  fetchGetServiceProductsRequest,
  fetchGetServiceProductsSuccess,
  fetchGetServiceProductsFailure,
  FETCH_POST_LOGOUT,
  FETCH_GET_SERVICE_PRODUCTS,
} from './index';

export const fetchPostLogoutEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_LOGOUT,
  apiName: 'member.fetchPostLogout',
  fetchRequest: fetchPostLogoutRequest,
  handleSuccess: () => [fetchPostLogoutSuccess(), replace('/')],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostLogoutFailure(error))
    ),
});

export const fetchGetServiceProductsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SERVICE_PRODUCTS,
  apiName: 'thirdParty.fetchGetServiceProducts',
  fetchRequest: fetchGetServiceProductsRequest,
  handleSuccess: (response) => [
    fetchGetServiceProductsSuccess(fromJS(response.data.source || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetServiceProductsFailure(error))
    ),
});

export default combineEpics(fetchPostLogoutEpic, fetchGetServiceProductsEpic);
