import * as yup from 'yup';

const schema = yup.object().shape({
  paymentOrderContactName: yup
    .string()
    .max(50, '長度不能超過50個字元')
    .required('「姓名」是必填欄位'),
  paymentOrderContactPhone: yup
    .string()
    .max(20, '長度不能超過20個字元')
    .phone('「手機」格式錯誤', 'zh-TW')
    .required('「手機」是必填欄位'),
  paymentOrderContactLocalPhone: yup.string(),
  paymentOrderContactEmail: yup
    .string()
    .email('「聯絡 Email」格式錯誤')
    .max(100, '長度不能超過100個字元')
    .required('「Email」是必填欄位'),
  paymentOrderInvoiceTitle: yup.string().when('invoiceResultType', {
    is: '5',
    then: (s) => s.required('「發票抬頭」是必填欄位'),
  }),
  paymentOrderInvoiceTaxIdNumber: yup.string().when('invoiceResultType', {
    is: '5',
    then: (s) => s.required('「統一編號」是必填欄位'),
  }),
});

export default schema