import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@eGroupTeam/material';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import useMemberInfo from 'utils/useMemberInfo';
import { useCookies } from 'react-cookie';

export const DIALOG = 'goToNewSiteDialog';

const GoToNewSiteDialog = ({ isOpen, handleClose }) => {
  const [, setCookie] = useCookies();
  const [memberInfo] = useMemberInfo();
  const handleMyClose = () => {
    setCookie('askedGotoNewSite', 'true', {
      expires: new Date('9999-12-31'),
    });
    handleClose();
  };
  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle>使用最新功能</DialogTitle>
      <DialogContent>
        <DialogContentText>
          親愛的 {memberInfo?.memberName}{' '}
          您好，您被我們選為新網頁功能的使用者，您可以隨時從右上方的個人選單中前往。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleMyClose}>取消</Button>
        <Button
          onClick={handleMyClose}
          variant="contained"
          color="primary"
          disableElevation
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)(GoToNewSiteDialog);
