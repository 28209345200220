import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import { normalize } from 'normalizr';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  setEntities,
  setEntitiesArrayConcat,
} from '@eGroupTeam/redux-modules/immutable/entities';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import apiErrorMsgMapper from 'utils/apiErrorMsgMapper';
import {
  fetchGetActivityApplyField,
  fetchGetActivityApplyFieldRequest,
  fetchGetActivityApplyFieldSuccess,
  fetchGetActivityApplyFieldFailure,
  fetchGetActivityApplyFieldsRequest,
  fetchGetActivityApplyFieldsSuccess,
  fetchGetActivityApplyFieldsFailure,
  fetchPostActivityApplyFieldRequest,
  fetchPostActivityApplyFieldSuccess,
  fetchPostActivityApplyFieldFailure,
  fetchPatchActivityApplyFieldsOrderRequest,
  fetchPatchActivityApplyFieldsOrderSuccess,
  fetchPatchActivityApplyFieldsOrderFailure,
  fetchPatchActivityApplyFieldRequest,
  fetchPatchActivityApplyFieldSuccess,
  fetchPatchActivityApplyFieldFailure,
  fetchDeleteActivityApplyFieldRequest,
  fetchDeleteActivityApplyFieldSuccess,
  fetchDeleteActivityApplyFieldFailure,
  fetchPostActivityApplyFieldOptionRequest,
  fetchPostActivityApplyFieldOptionSuccess,
  fetchPostActivityApplyFieldOptionFailure,
  fetchPatchActivityApplyFieldOptionsOrderRequest,
  fetchPatchActivityApplyFieldOptionsOrderSuccess,
  fetchPatchActivityApplyFieldOptionsOrderFailure,
  fetchPatchActivityApplyFieldOptionRequest,
  fetchPatchActivityApplyFieldOptionSuccess,
  fetchPatchActivityApplyFieldOptionFailure,
  fetchDeleteActivityApplyFieldOptionRequest,
  fetchDeleteActivityApplyFieldOptionSuccess,
  fetchDeleteActivityApplyFieldOptionFailure,
  FETCH_GET_ACTIVITY_APPLY_FIELDS,
  FETCH_POST_ACTIVITY_APPLY_FIELD,
  FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER,
  FETCH_PATCH_ACTIVITY_APPLY_FIELD,
  FETCH_DELETE_ACTIVITY_APPLY_FIELD,
  FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION,
  FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER,
  FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION,
  FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION,
  FETCH_GET_ACTIVITY_APPLY_FIELD,
} from './redux';

export const fetchGetActivityApplyFieldsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_APPLY_FIELDS,
  apiName: 'fetchGetActivityApplyFields',
  fetchRequest: fetchGetActivityApplyFieldsRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.applyFields
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetActivityApplyFieldsSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityApplyFieldsFailure(error))
    ),
});

export const fetchPostActivityApplyFieldEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_APPLY_FIELD,
  apiName: 'fetchPostActivityApplyField',
  fetchRequest: fetchPostActivityApplyFieldRequest,
  handleSuccess: (response, { schema }) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    const { result, entities } = normalize(
      response.data || {},
      schema.applyField
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostActivityApplyFieldSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityApplyFieldFailure(error))
    ),
});

export const fetchGetActivityApplyFieldEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_APPLY_FIELD,
  apiName: 'fetchGetActivityApplyField',
  fetchRequest: fetchGetActivityApplyFieldRequest,
  handleSuccess: (response, { schema }) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    const { result, entities } = normalize(response.data, schema.applyField);
    return [
      setEntities(fromJS(entities)),
      fetchGetActivityApplyFieldSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityApplyFieldFailure(error))
    ),
});

export const fetchPatchActivityApplyFieldsOrderEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER,
  apiName: 'fetchPatchActivityApplyFieldsOrder',
  fetchRequest: fetchPatchActivityApplyFieldsOrderRequest,
  handleSuccess: (response, { schema }) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    const { result, entities } = normalize(response.data, schema.applyFields);
    return [
      fetchPatchActivityApplyFieldsOrderSuccess(fromJS(result)),
      setEntities(fromJS(entities)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchActivityApplyFieldsOrderFailure(error))
    ),
});

export const fetchPatchActivityApplyFieldEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_ACTIVITY_APPLY_FIELD,
  apiName: 'fetchPatchActivityApplyField',
  debounceTime: 500,
  fetchRequest: fetchPatchActivityApplyFieldRequest,
  handleSuccess: (response, { schema }) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    const { result, entities } = normalize(response.data, schema.applyField);
    return [
      setEntities(fromJS(entities)),
      fetchPatchActivityApplyFieldSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchActivityApplyFieldFailure(error))
    ),
});

export const fetchDeleteActivityApplyFieldEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_ACTIVITY_APPLY_FIELD,
  apiName: 'fetchDeleteActivityApplyField',
  fetchRequest: fetchDeleteActivityApplyFieldRequest,
  handleSuccess: (response) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    return [fetchDeleteActivityApplyFieldSuccess(response.data.applyFieldId)];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteActivityApplyFieldFailure(error))
    ),
});

export const fetchPostActivityApplyFieldOptionEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION,
  apiName: 'fetchPostActivityApplyFieldOption',
  fetchRequest: fetchPostActivityApplyFieldOptionRequest,
  handleSuccess: (response, { schema }) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    const { result, entities } = normalize(response.data, schema.applyField);
    return [
      setEntitiesArrayConcat(fromJS(entities)),
      fetchPostActivityApplyFieldOptionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityApplyFieldOptionFailure(error))
    ),
});

export const fetchPatchActivityApplyFieldOptionsOrderEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER,
  apiName: 'fetchPatchActivityApplyFieldOptionsOrder',
  fetchRequest: fetchPatchActivityApplyFieldOptionsOrderRequest,
  handleSuccess: (response, { schema }) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    const { result, entities } = normalize(response.data, schema.applyField);
    return [
      setEntities(fromJS(entities)),
      fetchPatchActivityApplyFieldOptionsOrderSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchActivityApplyFieldOptionsOrderFailure(error))
    ),
});

export const fetchPatchActivityApplyFieldOptionEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION,
  apiName: 'fetchPatchActivityApplyFieldOption',
  debounceTime: 500,
  fetchRequest: fetchPatchActivityApplyFieldOptionRequest,
  handleSuccess: (response, { schema }) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    const { result, entities } = normalize(
      response.data,
      schema.applyFieldOption
    );
    return [
      setEntities(fromJS(entities)),
      fetchPatchActivityApplyFieldOptionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchActivityApplyFieldOptionFailure(error))
    ),
});

export const fetchDeleteActivityApplyFieldOptionEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION,
  apiName: 'fetchDeleteActivityApplyFieldOption',
  fetchRequest: fetchDeleteActivityApplyFieldOptionRequest,
  handleSuccess: (response, { action }) => {
    if (typeof response.data === 'string') {
      return [
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    return [
      fetchGetActivityApplyField({
        organizationId: action.payload.organizationId,
        activityId: action.payload.activityId,
        applyFieldId: action.payload.applyFieldId,
      }),
      fetchDeleteActivityApplyFieldOptionSuccess(
        response.data.applyFieldOptionId
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteActivityApplyFieldOptionFailure(error))
    ),
});

export default combineEpics(
  fetchGetActivityApplyFieldsEpic,
  fetchGetActivityApplyFieldEpic,
  fetchPostActivityApplyFieldEpic,
  fetchPatchActivityApplyFieldsOrderEpic,
  fetchPatchActivityApplyFieldEpic,
  fetchDeleteActivityApplyFieldEpic,
  fetchPostActivityApplyFieldOptionEpic,
  fetchPatchActivityApplyFieldOptionsOrderEpic,
  fetchPatchActivityApplyFieldOptionEpic,
  fetchDeleteActivityApplyFieldOptionEpic
);
