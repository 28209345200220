import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { submit } from 'redux-form/immutable';
import { getSelectedOrg } from 'redux/modules/common';

import Button from '@eGroupTeam/material/Button';
import Dialog from '@eGroupTeam/material/Dialog';
import DialogContent from '@eGroupTeam/material/DialogContent';
import DialogActions from '@eGroupTeam/material/DialogActions';
import DialogTitle from '@eGroupTeam/material/DialogTitle';
import { getIsPosting } from './redux';
import UserAddForm, { FORM } from './UserAddForm';

export const DIALOG = 'userAddDialog';

const UserAddDialog = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const isPosting = useSelector(getIsPosting);
  const selectedOrg = useSelector(getSelectedOrg);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>新增會員資料</DialogTitle>
      <DialogContent>
        <UserAddForm organizationId={selectedOrg.get('organizationId')} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>取消</Button>
        <Button
          loading={isPosting}
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(submit(FORM));
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserAddDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(UserAddDialog);
