import { fetcher } from 'redux/apisOrg';
import makePostHook from '@eGroupTeam/hooks/makePostHook';

export type PathParams = {
  organizationId?: string;
  serviceName?: string;
  paymentOrderId?: string;
};
const usePaymentCode = makePostHook<string, PathParams>(
  '/organizations/{{organizationId}}/{{serviceName}}/payment-orders/{{paymentOrderId}}/pay',
  fetcher
);
export default usePaymentCode;
