import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import { Box } from "@eGroupTeam/material";
import Paper from "@eGroupTeam/material/Paper";

import useServiceProducts from "utils/useServiceProducts";
import { ServiceProduct } from "types";
import BuyMicePointItem, { BuyMicePointItemProps } from "./BuyMicePointItem";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3),
    "& h3": {
      fontSize: "1.75rem",
      lineHeight: "1.2",
    },
  },
  paper: {
    border: "1px solid #C0C3CE",
    boxShadow: "0 3px 16px rgba(10, 75, 109, 0.08)",
    borderRadius: "8px 8px 0 0",
  },
}));

export interface BuyMicePointProps {
  onChange: (el:ServiceProduct) => BuyMicePointItemProps["onChange"]
}

const BuyMicePoint: FC<BuyMicePointProps> = ({
  onChange
}) => {
  const classes = useStyles();
  const { data } = useServiceProducts({
    serviceName: 'micepoint',
  });

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant="h3" fontWeight={400}>
            購買 MICEPoint
          </Typography>
        </Box>
        <Box>
          {data?.source.map((item) => (
            <BuyMicePointItem
              key={item.serviceProductId}
              title={item.serviceProductNameZh}
              value={item.serviceProductUnitPriceZh}
              onChange={onChange(item)}
            />
          ))}
        </Box>
      </Paper>
    </Grid>
  );
};

export default BuyMicePoint;
