import { combineReducers } from 'redux-immutable';
import { combineEpics } from 'redux-observable';

import Activity from 'mainPages/Activity/reducers';
import ActivityFields from 'mainPages/Activity/ActivityFields/reducers';
import ActivityNotification from 'mainPages/Activity/ActivityNotification/reducers';
import ActivityCheckIn from 'mainPages/Activity/ActivityCheckIn/reducers';
import ActivityCertificates from 'mainPages/Activity/ActivityCertificates/reducers';
import NewActivity from 'mainPages/NewActivity/reducers';
import SurveyEditor from 'mainPages/Survey/SurveyEditor/reducers';

import ActivityEpics from 'mainPages/Activity/epics';
import NewOrganizationEpics from 'mainPages/NewOrganization/epics';
import UsersEpics from 'mainPages/Users/epics';
import RootEpics from 'mainPages/Root/epics';
import SurveyEpics from 'mainPages/Survey/epics';
import SurveysEpics from 'mainPages/Surveys/epics';
import NotificationEpics from 'mainPages/Notification/epics';

export const mainPages = combineReducers({
  SurveyEditor,
  Activity,
  ActivityFields,
  ActivityNotification,
  ActivityCheckIn,
  NewActivity,
  ActivityCertificates,
});

export const mainPagesEpics = combineEpics(
  ActivityEpics,
  NewOrganizationEpics,
  UsersEpics,
  RootEpics,
  SurveyEpics,
  SurveysEpics,
  NotificationEpics
);
