import axios from 'axios';
import queryString from 'query-string';
import apiConfig from './apiConfig';

export const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  ...apiConfig,
});

export default {
  /**
   * User
   */

  fetchGetUsers: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.get(
      `/organizations/${organizationId}/users?${queryString.stringify(other)}`
    )
  },

  fetchGetUser: ({ organizationId, userId }) =>
    fetcher.get(`/organizations/${organizationId}/users/${userId}`),

  fetchPostUser: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(`/organizations/${organizationId}/users`, other)
  },

  fetchPatchUser: ({ organizationId, userId, ...other }) =>
    fetcher.patch(`/organizations/${organizationId}/users/${userId}`, other),

  fetchDeleteUser: ({ organizationId, userId }) =>
    fetcher.delete(`/organizations/${organizationId}/users/${userId}`),
  /** 單位的更改會員的檢查手機 */
  fetchPostCheckUserDuplicate: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(
      `/organizations/${organizationId}/users/check-duplicate-phone`,
      other
    )
  },

  /**
   * Import user by excel.
   */
  fetchPostUsersByExcel: ({ organizationId, formData }) =>
    fetcher.post(`/organizations/${organizationId}/users/excel`, formData),

  /**
   * User Activity
   */
  fetchGetUserActivities: ({ organizationId, userId, ...other }) =>
    fetcher.get(
      `/organizations/${organizationId}/users/${userId}/activities?${queryString.stringify(
        other
      )}`
    ),

  /**
   * Import user attended activities information by excel.
   */
  fetchPostUserActivitiesByExcel: ({ organizationId, formData }) =>
    fetcher.post(
      `/organizations/${organizationId}/activity-user-temp/excel`,
      formData
    ),

  /**
   * User Applies
   */
  /**
   * 取得活動報名列表
   * @param activityId 活動編號
   */
  fetchGetUserApplies: (payload) => {
    const { organizationId, activityId, ...other } = payload || {}

    return fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/user-applies?${queryString.stringify(other)}`
    )
  },

  /**
   * 取得單筆活動報名
   */
  fetchGetUserApply: ({ organizationId, activityId, userApplyId }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/user-applies/${userApplyId}`
    ),

  /**
   * 邀請重新訓練
   */
  fetchPostUserApplyRetrainEmail: ({
    organizationId,
    activityId,
    userApplyId,
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/user-applies/${userApplyId}/retrain-email`
    ),

  /**
   * 報名會員的檢查手機
   */
  fetchPostCheckUserApplyDuplicate: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(
      `/organizations/${organizationId}/users/check-duplicate-phone`,
      other
    )
  },

  /**
   * 編輯單筆活動報名
   */
  fetchPatchUserApply: (payload) => {
    const {
      organizationId,
      activityId,
      userApplyId,
      ...other
    } = payload || {}
    return fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}/user-applies/${userApplyId}`,
      other
    )
  },

  /**
   * 取得活動報名人數
   * @param activityId 活動編號
   * @param hasActivityVerifyRecord 是否有活動驗證紀錄(0:否;1:是)
   */
  fetchGetUserApplyTotalCounts: ({ organizationId, activityId, ...other }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/user-applies/total-counts?${queryString.stringify(
        other
      )}`
    ),
  /**
   * 取得活動報名每日數量
   */
  fetchGetUserApplyDailyCounts: ({ organizationId, activityId, ...other }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/user-applies/daily-counts?${queryString.stringify(
        other
      )}`
    ),
  /**
   * get activity result counts
   */
  fetchGetUserAppliesTotalResultCounts: ({ organizationId, activityId }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/user-applies/total-result-counts`
    ),

  /**
   * Activity
   */

  /**
   * 取得活動列表
   */
  fetchGetActivities: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.get(
      `/organizations/${organizationId}/activities?${queryString.stringify(
        other
      )}`
    )
  },
  /**
   * 新增活動
   */
  fetchPostActivity: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(`/organizations/${organizationId}/activities`, other)
  },
  /**
   * 編輯活動
   */
  fetchPatchActivity: (payload) => {
    const { organizationId, activityId, ...other } = payload || {}
    return fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}`,
      other
    )
  },
  /**
   * Update activity service product
   */
  fetchPatchActivityServiceProducts: (payload) => {
    const {
      organizationId,
      activityId,
      ...other
    } = payload

    return fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}/service-products`,
      other
    )
  },
  /**
   * Get changed activity attended people amount.
   */
  fetchPostActivityChangedPeopleAmount: (payload) => {
    const {
      organizationId,
      activityId,
      ...other
    } = payload || {}

    return fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/calculate-people-change`,
      other
    )
  },
  /**
   * 上傳活動封面照
   */
  fetchPostActivityCover: ({ organizationId, formData }) =>
    fetcher.post(`/organizations/${organizationId}/activities/cover`, formData),
  /**
   * 上傳活動Logo
   */
  fetchPostActivityLogo: ({ organizationId, formData }) =>
    fetcher.post(`/organizations/${organizationId}/activities/logo`, formData),
  /**
   * 取得活動內容
   */
  fetchGetActivity: ({ organizationId, activityId }) =>
    fetcher.get(`/organizations/${organizationId}/activities/${activityId}`),
  /**
   * 取的活動場次清單
   */
  fetchGetActivitySections: ({ organizationId, activityId }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/sections`
    ),
  /**
   * Update user activity apply status.
   */
  fetchPatchUserActivityApplyStatus: (payload) => {
    const {
      organizationId,
      activityId,
      userApplyId,
      activityClassId,
      ...other
    } = payload || {}
    return fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}/user-applies/${userApplyId}/activity-class/${activityClassId}/apply-status`,
      other
    )
  }
    ,
  /**
   * 送出活動問卷
   */
  fetchPostActivitySurvey: ({
    organizationId,
    activityId,
    surveyId,
    payload,
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/surveys/${surveyId}`,
      payload
    ),
  /**
   * 取得活動場次清單
   */
  fetchGetActivityClasses: ({ organizationId, activityId, ...other }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/activity-class?${queryString.stringify(
        other
      )}`
    ),
  /**
   * create user apply activity class
   */
  fetchPostUserApplyActivityClass: ({
    organizationId,
    activityId,
    userApplyId,
    activityClassId,
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/user-applies/${userApplyId}/activity-class/${activityClassId}/user-apply-activity-class`
    ),
  /**
   * batch create user apply activity class
   */
  fetchPostBatchUserApplyActivityClass: (payload) => {
    const {
      organizationId,
      activityId,
      ...other
    } = payload || {}

    return fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/user-apply-activity-class`,
      other
    )
  },
  /**
   * batch create user apply activity class
   */
  fetchDeleteBatchUserApplyActivityClass: (payload) => {
    const {
      organizationId,
      activityId,
      ...other
    } = payload || {}

    return fetcher.delete(
      `/organizations/${organizationId}/activities/${activityId}/user-apply-activity-class`,
      {
        data: other,
      }
    )
  },
  /**
   * 一般驗證
   */
  fetchPostActivityUserApplyDays: ({
    organizationId,
    activityId,
    userApplyId,
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/verify/userInfo/${userApplyId}`
    ),
  /**
   * QRCODE 驗證
   * @param {Object} formData 夾帶檔案的 formData
   */
  fetchPostActivityRecognizeQrcode: ({
    organizationId,
    activityId,
    formData,
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/verify/qrcode`,
      formData,
      {
        timeout: 3000,
      }
    ),
  /**
   * 人臉驗證
   * @param {Object} formData 夾帶檔案的 formData
   */
  fetchPostActivityRecognizeFace: ({ organizationId, activityId, formData }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/verify/face`,
      formData,
      {
        timeout: 3000,
      }
    ),
  /**
   * 線上簽到
   */
  fetchPostActivityUserApplyOnline: (payload) => {
    const { organizationId, activityId, activityClassId, userApplyId } = payload || {}
    return fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/verify/online/${activityClassId}/${userApplyId}`
    )
  },
  /**
   * 取得Activity EDM列表
   */
  fetchGetActivityEdm: ({ organizationId, activityId, ...other }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/edm?${queryString.stringify(
        other
      )}`
    ),

  /**
   * 取得寄活動信件會員列表
   */
  fetchGetActivityEdmUsers: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.get(
      `/organizations/${organizationId}/users/sending-email-list?${queryString.stringify(
        other
      )}`
    )
  },

  /**
   * 寄送活動通知
   */
  fetchPostNotificationEmail: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(
      `/organizations/${organizationId}/notification-email`,
      other
    )
  },

  /**
   * 測試寄信
   */
  fetchPostTestNotificationEmail: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(
      `/organizations/${organizationId}/notification-email/test-email`,
      other
    )
  },

  /**
   * 匯入會員活動參與紀錄
   */
  fetchPostActivityUserTemp: ({ organizationId, formData }) =>
    fetcher.post(
      `/organizations/${organizationId}/activity-user-temp/excel`,
      formData
    ),

  /**
   * Get activity files
   */
  fetchGetActivityFiles: ({ organizationId, activityId }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/activity-files`
    ),

  /**
   * Upload activity file
   */
  fetchPostActivityFile: ({ organizationId, activityId, formData }, config) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/activity-files`,
      formData,
      config
    ),

  /**
   * Delete activity file
   */
  fetchDeleteActivityFile: ({ organizationId, activityId, activityFileId }) =>
    fetcher.delete(
      `/organizations/${organizationId}/activities/${activityId}/activity-files/${activityFileId}`
    ),

  /**
   * Surveys
   */

  /**
   * Get Survey List
   */
  fetchGetSurveys: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.get(
      `/organizations/${organizationId}/surveys?${queryString.stringify(
        other
      )}`
    )
  },

  /**
   * Get Survey
   */
  fetchGetSurvey: ({ organizationId, surveyId }) =>
    fetcher.get(`/organizations/${organizationId}/surveys/${surveyId}`),

  /**
   * Create Survey
   */
  fetchPostSurvey: (payload) => {
    const { organizationId, ...other } = payload

    return fetcher.post(`/organizations/${organizationId}/surveys`, other)
  },

  /**
   * Update Survey
   */
  fetchPatchSurvey: ({ organizationId, surveyId, ...other }) =>
    fetcher.patch(
      `/organizations/${organizationId}/surveys/${surveyId}`,
      other
    ),

  /**
   * Delete Survey
   */
  fetchDeleteSurvey: ({ organizationId, surveyId }) =>
    fetcher.delete(`/organizations/${organizationId}/surveys/${surveyId}`),

  /**
   * Get Survey Questions
   */
  fetchGetSurveyQuestions: ({ organizationId, surveyId }) =>
    fetcher.get(
      `/organizations/${organizationId}/surveys/${surveyId}/questions`
    ),

  /**
   * Create Survey Question
   */
  fetchPostSurveyQuestion: ({ organizationId, surveyId, ...other }) =>
    fetcher.post(
      `/organizations/${organizationId}/surveys/${surveyId}/questions`,
      other
    ),

  /**
   * Get Survey Question
   */
  fetchGetSurveyQuestion: ({ organizationId, surveyId, questionId }) =>
    fetcher.get(
      `/organizations/${organizationId}/surveys/${surveyId}/questions/${questionId}`
    ),

  /**
   * Update Survey Question
   */
  fetchPatchSurveyQuestion: ({
    organizationId,
    surveyId,
    questionId,
    ...other
  }) =>
    fetcher.patch(
      `/organizations/${organizationId}/surveys/${surveyId}/questions/${questionId}`,
      other
    ),

  /**
   * Delete Survey Question
   */
  fetchDeleteSurveyQuestion: ({ organizationId, surveyId, questionId }) =>
    fetcher.delete(
      `/organizations/${organizationId}/surveys/${surveyId}/questions/${questionId}`
    ),

  /**
   * Update Survey Questions Order
   */
  fetchPatchSurveyQuestionsOrder: ({ organizationId, surveyId, payload }) =>
    fetcher.patch(
      `/organizations/${organizationId}/surveys/${surveyId}/questions/sort`,
      payload
    ),

  /**
   * Create Survey Question Option
   */
  fetchPostSurveyQuestionOption: ({
    organizationId,
    surveyId,
    questionId,
    ...other
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/surveys/${surveyId}/questions/${questionId}/options`,
      other
    ),

  /**
   * Update Survey Question Option
   */
  fetchPatchSurveyQuestionOption: ({
    organizationId,
    surveyId,
    questionId,
    optionId,
    ...other
  }) =>
    fetcher.patch(
      `/organizations/${organizationId}/surveys/${surveyId}/questions/${questionId}/options/${optionId}`,
      other
    ),

  /**
   * Delete Survey Question Option
   */
  fetchDeleteSurveyQuestionOption: ({
    organizationId,
    surveyId,
    questionId,
    optionId,
  }) =>
    fetcher.delete(
      `/organizations/${organizationId}/surveys/${surveyId}/questions/${questionId}/options/${optionId}`
    ),

  /**
   * Update Survey Question Options Order
   */
  fetchPatchSurveyQuestionOptionsOrder: ({
    organizationId,
    surveyId,
    questionId,
    payload,
  }) =>
    fetcher.patch(
      `/organizations/${organizationId}/surveys/${surveyId}/questions/${questionId}/options/sort`,
      payload
    ),

  /**
   * Get Survey Respondent Count
   */
  fetchGetSurveysRespondentCount: ({ organizationId, surveyId }) =>
    fetcher.get(
      `/organizations/${organizationId}/surveys/${surveyId}/respondent-count`
    ),

  /**
   * Get Survey Result
   */
  fetchGetSurveyResult: ({ organizationId, surveyId, ...other }) =>
    fetcher.get(
      `/organizations/${organizationId}/surveys/${surveyId}/result?${queryString.stringify(
        other
      )}`
    ),

  /**
   * Notifications
   */

  /**
   * 回傳單位EDM範本預覽
   */
  fetchPostPreviewEdm: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(
      `/organizations/${organizationId}/edm-templates/preview-edm`,
      other
    )
  },

  /**
   * 新增單位EDM範本
   */
  fetchPostOrganizationEdmTemplate: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(`/organizations/${organizationId}/edm-templates`, other)
  },

  /**
   * 取得單位EDM範本列表
   */
  fetchGetOrganizationEdmTemplates: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.get(
      `/organizations/${organizationId}/edm-templates?${queryString.stringify(
        other
      )}`
    )
  },

  /**
   * 刪除單位EDM範本
   */
  fetchDeleteOrganizationEdmTemplate: ({
    organizationId,
    organizationEdmTemplateId,
  }) =>
    fetcher.delete(
      `/organizations/${organizationId}/edm-templates/${organizationEdmTemplateId}`
    ),

  /**
   * 編輯單位EDM範本
   */
  fetchPatchOrganizationEdmTemplate: ({
    organizationId,
    organizationEdmTemplateId,
    ...other
  }) =>
    fetcher.patch(
      `/organizations/${organizationId}/edm-templates/${organizationEdmTemplateId}`,
      other
    ),

  /**
   * Study Prove
   */

  /**
   * 取得活動參加證明模板
   */
  fetchGetActivityStudyProve: ({ organizationId, activityId }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/activity-study-prove`
    ),

  /**
   * 上傳單位logo圖片
   */
  fetchPostActivityStudyProveOrganizationLogo: ({
    organizationId,
    activityId,
    formData,
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/activity-study-prove/logo`,
      formData
    ),

  /**
   * 編輯參加證明
   */
  fetchPatchActivityStudyProve: ({ organizationId, activityId, ...other }) =>
    fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}/activity-study-prove`,
      other
    ),

  /**
   * 參加證明預覽
   */
  fetchPostActivityStudyProvePreview: ({
    organizationId,
    activityId,
    ...other
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/activity-study-prove/preview`,
      other
    ),

  /**
   * 產生參加證明
   */
  fetchPostActivityStudyProveGenerator: ({
    organizationId,
    activityId,
    ...other
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/activity-study-prove/generator-prove`,
      other
    ),

  /**
   * Get custom activity apply fields.
   */
  fetchGetActivityApplyFields: ({ organizationId, activityId }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields`
    ),

  /**
   * Create activity apply field
   */
  fetchPostActivityApplyField: ({ organizationId, activityId, ...other }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields`,
      other
    ),

  /**
   * Get activity apply field
   */
  fetchGetActivityApplyField: ({ organizationId, activityId, applyFieldId }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/${applyFieldId}`
    ),

  /**
   * Update activity apply fields order.
   */
  fetchPatchActivityApplyFieldsOrder: ({
    organizationId,
    activityId,
    payload,
  }) =>
    fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/sort`,
      payload
    ),

  /**
   * Update activity apply field.
   */
  fetchPatchActivityApplyField: ({
    organizationId,
    activityId,
    applyFieldId,
    ...other
  }) =>
    fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/${applyFieldId}`,
      other
    ),

  /**
   * Delete activity apply field.
   */
  fetchDeleteActivityApplyField: ({
    organizationId,
    activityId,
    applyFieldId,
  }) =>
    fetcher.delete(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/${applyFieldId}`
    ),

  /**
   * Create activity apply field option
   */
  fetchPostActivityApplyFieldOption: ({
    organizationId,
    activityId,
    applyFieldId,
    ...other
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/${applyFieldId}/apply-field-options`,
      other
    ),

  /**
   * Update activity apply field options order.
   */
  fetchPatchActivityApplyFieldOptionsOrder: ({
    organizationId,
    activityId,
    applyFieldId,
    payload,
  }) =>
    fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/${applyFieldId}/apply-field-options/sort`,
      payload
    ),

  /**
   * Update activity apply field option.
   */
  fetchPatchActivityApplyFieldOption: ({
    organizationId,
    activityId,
    applyFieldId,
    applyFieldOptionId,
    ...other
  }) =>
    fetcher.patch(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/${applyFieldId}/apply-field-options/${applyFieldOptionId}`,
      other
    ),

  /**
   * Delete activity apply field option.
   */
  fetchDeleteActivityApplyFieldOption: ({
    organizationId,
    activityId,
    applyFieldId,
    applyFieldOptionId,
  }) =>
    fetcher.delete(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/${applyFieldId}/apply-field-options/${applyFieldOptionId}`
    ),

  /**
   * Get activity apply field option responses.
   */
  fetchGetActivityApplyFieldOptionResponses: ({
    organizationId,
    activityId,
    applyFieldId,
    applyFieldOptionId,
    ...other
  }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/${applyFieldId}/apply-field-options/${applyFieldOptionId}/responses?${queryString.stringify(
        other
      )}`
    ),

  /**
   * get activity apply field dashboard result
   */
  fetchGetActivityApplyFieldResult: ({
    organizationId,
    activityId,
    ...other
  }) =>
    fetcher.get(
      `/organizations/${organizationId}/activities/${activityId}/apply-fields/result?${queryString.stringify(
        other
      )}`
    ),

  /**
   * Payment
   */

  /**
   * get activity trail fee
   */
  fetchPostActivityTrailFee: ({
    organizationId,
    serviceProductIsFix,
    peopleChangeCount,
    activityId,
    countPeopleByDB,
    ...other
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/activities/trial-fee?${queryString.stringify(
        {
          activityId,
          serviceProductIsFix,
          peopleChangeCount,
          countPeopleByDB,
        }
      )}`,
      other
    ),

  /**
   * get activity payment code
   */
  fetchPostActivityPaymentCode: ({
    organizationId,
    actitvityPaymentOrderId,
    ...other
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/pay/${actitvityPaymentOrderId}`,
      other
    ),

  /**
   * get order list
   */
  fetchGetOrders: (payload) => {
    const { organizationId, serviceName, ...other } = payload || {}

    return fetcher.get(
      `/organizations/${organizationId}/${serviceName}/payment-orders?${queryString.stringify(
        other
      )}`
    )
  },

  /**
   * get order
   */
  fetchGetOrder: ({ organizationId, paymentOrderId, serviceName }) =>
    fetcher.get(
      `/organizations/${organizationId}/${serviceName}/payment-orders/${paymentOrderId}`
    ),

  /**
   * Create order.
   */
  fetchPostOrder: (payload) => {
    const { organizationId, serviceName, ...other } = payload || {}

    return fetcher.post(
      `/organizations/${organizationId}/${serviceName}/payment-orders`,
      other
    )
  },

  /**
   * Create activity order.
   */
  fetchPostActivityOrder: ({
    organizationId,
    serviceName,
    activityId,
    ...other
  }) =>
    fetcher.post(
      `/organizations/${organizationId}/${serviceName}/activities/${activityId}/payment-orders`,
      other
    ),

  /**
   * Create Payment Url.
   */
  fetchPostPayment: ({ organizationId, paymentOrderId, serviceName }) =>
    fetcher.post(
      `/organizations/${organizationId}/${serviceName}/payment-orders/${paymentOrderId}/pay`
    ),

  /**
   * 刪除單位內帳號
   */
  fetchDeleteOrganizationMembers: (payload) => {
    const { organizationId, loginId } = payload

    return fetcher.delete(`organizations/${organizationId}/members/${loginId}`)
  },

  /**
   * 邀請帳號進入單位
   */
  fetchPostOrganizationMemberInvitation: (payload) => {
    const { organizationId, ...other } = payload

    return fetcher.post(
      `/organizations/${organizationId}/members/invitation`,
      other
    )
  },

  /**
   * 取得邀請資訊
   */
  fetchGetMemberInvitationByEmail: ({
    organizationId,
    organizationInvitationToken,
  }) =>
    fetcher.get(
      `/organizations/${organizationId}/members/invitationToken/${organizationInvitationToken}`
    ),

  /**
   * 新增帳號進入單位
   */
  fetchPostMemberIntoOrganization: (payload) => {
    const { organizationId, ...other } = payload
    return fetcher.post(`organizations/${organizationId}/members`, other)
  },

  /**
   * Role
   */
  /**
   * Create org role
   */
  fetchPostOrganizationRole: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.post(`organizations/${organizationId}/roles`, other)
  },

  /**
   * Get Org role
   */
  fetchGetOrganizationRole: (payload) => {
    const { organizationId, organizationRoleId } = payload || {}

    return fetcher.get(`organizations/${organizationId}/roles/${organizationRoleId}`)
  },

  /**
   * Update Org role
   */
  fetchPatchOrganizationRole: (payload) => {
    const {
      organizationId,
      organizationRoleId,
      ...other
    } = payload || {}

    return fetcher.patch(
      `organizations/${organizationId}/roles/${organizationRoleId}`,
      other
    )
  },

  /**
   * Delete Org role
   */
  fetchDeleteOrganizationRole: (payload) => {
    const { organizationId, organizationRoleId } = payload || {}

    return fetcher.delete(
      `organizations/${organizationId}/roles/${organizationRoleId}`
    )
  },

  /**
   * Get Org role modules
   */
  fetchGetOrganizationRoleModules: ({
    organizationId,
    organizationRoleId,
    ...other
  }) =>
    fetcher.get(
      `organizations/${organizationId}/roles/${organizationRoleId}/modules?${queryString.stringify(
        other
      )}`
    ),

  /**
   * Create Org role module
   */
  fetchPostOrganizationRoleModule: (payload) => {
    const {
      organizationId,
      organizationRoleId,
      ...other
    } = payload || {}

    return fetcher.post(
      `organizations/${organizationId}/roles/${organizationRoleId}/modules`,
      other
    )
  },

  /**
   * Get Org member roles
   */
  fetchGetOrganizationMemberRoles: ({ organizationId, loginId }) =>
    fetcher.get(`organizations/${organizationId}/member/${loginId}/roles`),

  /**
   * Update Org member roles
   */
  fetchPostOrganizationMemberRoles: (payload) => {
    const { organizationId, loginId, ...other } = payload

    return fetcher.post(
      `organizations/${organizationId}/member/${loginId}/roles`,
      other
    )
  },

  /**
   * Create Org member activity permission
   */
  fetchPostMemberActivityPermission: (payload) => {
    const {
      organizationId,
      loginId,
      ...other
    } = payload || {}

    return fetcher.post(
      `organizations/${organizationId}/member/${loginId}/activity-auths`,
      other
    )
  },

  /**
   * Delete Org member activity permission
   */
  fetchDeleteMemberActivityPermission: (payload) => {
    const {
      organizationId,
      loginId,
      activityId,
    } = payload || {}

    return fetcher.delete(
      `/organizations/${organizationId}/member/${loginId}/activity-auths/${activityId}`
    )
  },
};
