import tools from './apisTools';
import thirdParty from './apisThirdParty';
import qrpass from './apisQrpass';
import landingPage from './apisLP';
import member from './apisMember';
import invoice from './apisInvoice';
import org from './apisOrg';

export default {
  tools,
  thirdParty,
  qrpass,
  landingPage,
  member,
  invoice,
  ...org,
};
