import React, { useEffect } from 'react';

import apis from 'redux/apisOrg';
import clsx from 'clsx';
import {
  withReduxDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  makeStyles,
  Button,
  Paper,
  Hidden,
  Typography,
  CircularProgress,
  Container,
  IconButton,
} from '@eGroupTeam/material';
import { useParams, useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import { getSelectedOrg } from 'redux/modules/common';
import useHandleActivityShare from 'utils/useHandleActivityShare';

import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import Main from '@eGroupTeam/material-layout/Main';
import BackAppbar from '@eGroupTeam/material-module/BackAppbar';
import SideMenu from '@eGroupTeam/material-module/SideMenu';

import EventIcon from '@material-ui/icons/Event';
import ShareIcon from '@material-ui/icons/Share';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import SideMenuHeader from 'components/SideMenuHeader';
import MainHeader, { MainHeaderProvider } from 'components/MainHeader';
import ScrollableTypography from 'components/ScrollableTypography';
import Center from '@eGroupTeam/material-layout/Center';
import ActivityShareDialog from 'components/ActivityShareDialog';
import { UrlParams } from 'types';
import { getMemberModuleValues } from 'components/RouterRoot/selectors';
import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import useOrgActivity from 'utils/useOrgActivity';
import useActivityClasses from 'utils/useActivityClasses';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import {
  openSnackbar,
  setSnackbarData,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import apiErrorMsgMapper from 'utils/apiErrorMsgMapper';
import {
  handleTutorialDrawerOpen,
  handleTutorialDrawerClose,
  reset,
  getTutorialDrawerIsOpen,
  getSelectedActivityClassName,
  getSelectedActivityClassId,
} from './redux';
import ActivityCreateSuccessDialog from './components/ActivityCreateSuccessDialog';
import ActivityTutorialDrawer from './components/ActivityTutorialDrawer';

export const CONFIRM_RELEASE_DIALOG = 'activityConfirmReleaseDialog';
export const CONFIRM_PAY_DIALOG = 'activityConfirmPayDialog';

const ConfirmReleaseDialog = withReduxDialog(CONFIRM_RELEASE_DIALOG)<
  HTMLDivElement,
  ConfirmDialogProps
>(ConfirmDialog);
const ConfirmPayDialog = withReduxDialog(CONFIRM_PAY_DIALOG)<
  HTMLDivElement,
  ConfirmDialogProps
>(ConfirmDialog);

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    paddingLeft: theme.spacing(32),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  innerSideMenu: {
    width: theme.spacing(32),
    position: 'fixed',
    top: theme.spacing(8),
    left: 0,
    bottom: 0,
    overflow: 'auto',
    paddingBottom: theme.spacing(5),
  },
  appBar: {
    [theme.breakpoints.up('xl')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  appBarShift: {
    [theme.breakpoints.up('xl')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.layout.tutorialMenuWidth}px)`,
      marginRight: theme.layout.tutorialMenuWidth,
    },
  },
  container: {
    width: '100%',
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('xl')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -theme.layout.tutorialMenuWidth,
    },
  },
  containerShift: {
    [theme.breakpoints.up('xl')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
  },
  mainHeader: {
    [theme.breakpoints.up('xl')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
    },
    zIndex: theme.zIndex.appBar,
  },
  mainHeaderShift: {
    [theme.breakpoints.up('xl')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.spacing(32)}px - ${
        theme.layout.tutorialMenuWidth
      }px)`,
      marginRight: theme.layout.tutorialMenuWidth,
    },
  },
  grow: {
    flexGrow: 1,
  },
  releaseBtn: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
}));

const SIDE_MENU_HEADER_TEXT = '活動管理';

const ActivityRoot = ({ route }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { activityId } = useParams<UrlParams>();
  const selectedOrg = useSelector(getSelectedOrg);
  const { data: activity, response, mutate: mutateActivity } = useOrgActivity({
    organizationId: selectedOrg.get('organizationId'),
    activityId,
  });
  const {
    data: activityClasses,
    mutate: mutateActivityClasses,
  } = useActivityClasses({
    activityId,
  });
  const isOpen = useSelector(getTutorialDrawerIsOpen);
  const selectedActivityClassId = useSelector(getSelectedActivityClassId);
  const selectedActivityClassName = useSelector(getSelectedActivityClassName);
  const [handleShareClick] = useHandleActivityShare(activity);
  const memberModuleValues = useSelector(getMemberModuleValues);
  const { excute: updateActivity } = useAxiosApiWrapper(
    apis.fetchPatchActivity
  );

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  const handleRelease = () => {
    dispatch(openDialog(CONFIRM_RELEASE_DIALOG));
  };

  const handleConfirmRelease = () => {
    if (activity) {
      updateActivity({
        organizationId: selectedOrg.get('organizationId'),
        activityId: activity.activityId,
        isRelease: Number(!activity.isRelease),
      }).then((response) => {
        mutateActivity();
        if (typeof response.data === 'string') {
          dispatch(
            setSnackbarData({
              name: SNACKBAR,
              message: apiErrorMsgMapper.get(response.data),
              variant: 'error',
            })
          );
          dispatch(openSnackbar(SNACKBAR));
        }
      });
    }
  };

  const handleConfirmPay = () => {
    history.push(`/me/activities/${activityId}/products`);
  };

  if (response?.status === 204) {
    return (
      <Container>
        <Center>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom>
              查無此活動
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                history.push('/me/activities');
              }}
            >
              返回列表
            </Button>
          </div>
        </Center>
      </Container>
    );
  }

  if (!activity || !activityClasses) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  const isRelease = Boolean(activity.isRelease);
  const isEnd = Boolean(activity.isEnd);

  const sideMenuRoutes = route.routes.map((el) => ({
    ...el,
    path: el.path ? el.path.replace(':activityId', activityId) : undefined,
  }));
  const headerProviderRoutes = sideMenuRoutes.filter((el) => el.path);
  const routeRoutes = route.routes.filter((el) => el.component);

  const renderReleaseBtn = () => {
    if (!memberModuleValues.includes('activityEdit')) {
      return undefined;
    }
    if (isEnd) {
      return (
        <Button fullWidth variant="contained" disableElevation disabled>
          活動已結束
        </Button>
      );
    }
    if (isRelease) {
      return (
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleRelease}
          disableElevation
        >
          取消開放報名
        </Button>
      );
    }
    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleRelease}
        disableElevation
      >
        開放報名
      </Button>
    );
  };

  const renderMobileReleaseBtn = () => {
    if (!memberModuleValues.includes('activityEdit')) {
      return undefined;
    }
    if (isEnd) {
      return (
        <Button fullWidth variant="contained" disableElevation disabled>
          活動已結束
        </Button>
      );
    }
    if (isRelease) {
      return (
        <Button
          color="secondary"
          onClick={handleRelease}
          disableElevation
          variant="outlined"
        >
          取消開放報名
        </Button>
      );
    }
    return (
      <Button
        color="primary"
        onClick={handleRelease}
        disableElevation
        variant="outlined"
      >
        開放報名
      </Button>
    );
  };

  return (
    <>
      <ConfirmReleaseDialog
        primary={`確認${isRelease ? '取消開放報名' : '開放報名'}嗎？`}
        message={`確認${isRelease ? '取消開放報名' : '開放報名'} ${
          activity.activityName
        } 嗎？`}
        onConfirm={handleConfirmRelease}
      />
      <ConfirmPayDialog
        primary="您尚未付款"
        message="前往付款，即可發佈活動。"
        onConfirm={handleConfirmPay}
      />
      <ActivityShareDialog activity={activity} />
      <ActivityCreateSuccessDialog />
      <MainHeaderProvider
        location={location}
        routes={headerProviderRoutes}
        sideMenuHeaderText={SIDE_MENU_HEADER_TEXT}
      >
        <BackAppbar
          push={history.push}
          go={history.go}
          pointerTrigger={location}
          position="fixed"
          elevation={0}
          backPath="/me/activities"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: isOpen,
          })}
        >
          <ScrollableTypography variant="h6">
            {activity.activityName}
          </ScrollableTypography>
          <div className={classes.grow} />
          <Hidden smDown>
            <Button
              color="inherit"
              variant="outlined"
              startIcon={<ShareIcon />}
              onClick={handleShareClick}
            >
              分享活動
            </Button>
          </Hidden>
          <Hidden mdUp>
            <IconButton color="inherit" onClick={handleShareClick}>
              <ShareIcon />
            </IconButton>
          </Hidden>
        </BackAppbar>
        <NavbarBrick />
        <div className={classes.layout}>
          <Hidden smDown>
            <Paper className={classes.innerSideMenu}>
              <SideMenuHeader icon={EventIcon}>
                <Typography variant="body1">
                  <strong>{SIDE_MENU_HEADER_TEXT}</strong>
                </Typography>
              </SideMenuHeader>
              <div className={classes.releaseBtn}>{renderReleaseBtn()}</div>
              <SideMenu pathname={location.pathname} routes={sideMenuRoutes} />
            </Paper>
          </Hidden>
          <div
            className={clsx(classes.container, {
              [classes.containerShift]: isOpen,
            })}
          >
            <MainHeader
              className={clsx(classes.mainHeader, {
                [classes.mainHeaderShift]: isOpen,
              })}
            >
              <Hidden mdUp>
                <div>{renderMobileReleaseBtn()}</div>
              </Hidden>
              <div className={classes.grow} />
              <Button onClick={() => dispatch(handleTutorialDrawerOpen())}>
                使用教學
              </Button>
            </MainHeader>
            <Main>
              {renderRoutes(routeRoutes, {
                activity,
                mutateActivity,
                activityClasses: activityClasses.source,
                mutateActivityClasses,
                selectedActivityClassId,
                selectedActivityClassName,
                activityIsRelease: isRelease,
                activityIsEnd: isEnd,
              })}
            </Main>
          </div>
          <ActivityTutorialDrawer
            open={isOpen}
            onCloseClick={() => dispatch(handleTutorialDrawerClose())}
          />
        </div>
      </MainHeaderProvider>
    </>
  );
};

export default ActivityRoot;
