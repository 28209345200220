import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';

import { Link } from 'react-router-dom';
import Dialog from '@eGroupTeam/material/Dialog';
import DialogContent from '@eGroupTeam/material/DialogContent';
import DialogActions from '@eGroupTeam/material/DialogActions';
import DialogTitle from '@eGroupTeam/material/DialogTitle';
import TableContainer from '@eGroupTeam/material/TableContainer';
import Table from '@eGroupTeam/material/Table';
import TableHead from '@eGroupTeam/material/TableHead';
import TableBody from '@eGroupTeam/material/TableBody';
import TableRow from '@eGroupTeam/material/TableRow';
import TableCell from '@eGroupTeam/material/TableCell';
import Button from '@eGroupTeam/material/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getPostUsersByExcelResult } from './redux';

export const DIALOG = 'usersImportResultDialog';

const UsersImportResultDialog = ({ isOpen, handleClose }) => {
  const postUsersByExcelResult = useSelector(getPostUsersByExcelResult);

  const renderContent = () => {
    if (!postUsersByExcelResult) return undefined;
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>姓名</TableCell>
              <TableCell>單位</TableCell>
              <TableCell>職稱</TableCell>
              <TableCell>email</TableCell>
              <TableCell>電話</TableCell>
              <TableCell>匯入結果</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {postUsersByExcelResult.map((el) => (
              <TableRow key={el.get('userEmail')}>
                <TableCell>{el.get('userName')}</TableCell>
                <TableCell>{el.get('userOrganizationName')}</TableCell>
                <TableCell>{el.get('userJobTitle')}</TableCell>
                <TableCell>{el.get('userEmail')}</TableCell>
                <TableCell>{el.get('userPhone')}</TableCell>
                <TableCell>{el.get('result')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>會員匯入結果</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button component={Link} to="/me/hrm/users" onClick={handleClose}>
          <ArrowBackIcon />
          前往會員列表
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          disableElevation
          color="primary"
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UsersImportResultDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(UsersImportResultDialog);
