import React from 'react';

import { Redirect } from 'react-router-dom';

import ProfileRoot from 'profilePages/Root';
import ProfileActivities from 'profilePages/Activities';
// import ProfileAccount from 'profilePages/Account';
import EventIcon from '@material-ui/icons/Event';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';

export default [
  {
    component: ProfileRoot,
    path: '/me/profile',
    routes: [
      {
        path: '/me/profile/activities',
        breadcrumbName: '我的活動',
        icon: <EventIcon />,
        component: ProfileActivities,
      },
      // {
      //   path: '/me/profile/account',
      //   breadcrumbName: '帳戶管理',
      //   icon: <AccountBoxIcon />,
      //   component: ProfileAccount,
      // },
      {
        path: '/me/profile',
        render: () => <Redirect to="/me/profile/activities" />,
      },
      {
        path: '/me/profile/*',
        render: () => <Redirect to="/me/profile/activities" />,
      },
    ],
  },
];
