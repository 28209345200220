import React from 'react';

import { format } from '@eGroupTeam/utils/dateUtils';
import { useSelector } from 'react-redux';
import getVideoPlayList from 'utils/getVideoPlayList';
import { getSelectedOrg } from 'redux/modules/common';

import Main from '@eGroupTeam/material-layout/Main';
import YoutubePlayer from '@eGroupTeam/material-module/YoutubePlayer';
import {
  Container,
  Typography,
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@eGroupTeam/material';
import useOrgActivities from 'utils/useOrgActivities';
import ActivityCard from 'components/ActivityCard';

const videos = getVideoPlayList();
const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(4),
  },
  videoInfo: {
    marginTop: theme.spacing(2),
  },
}));

const Home = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedOrg = useSelector(getSelectedOrg);
  const classes = useStyles();
  const { data, response } = useOrgActivities(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    {
      activeDate: format(new Date(), 'yyyy-MM-dd'),
    }
  );
  const hasActivities = response?.status !== 204;

  if (matches) {
    return (
      <Main>
        <Container maxWidth={false}>
          <Grid container spacing={1}>
            {hasActivities && (
              <Grid item xs={12}>
                <div className={classes.header}>
                  <Typography variant="h6" gutterBottom color="primary">
                    本日活動
                  </Typography>
                </div>
                <Grid container spacing={2} direction="column">
                  {data?.source.map((el) => (
                    <Grid item xs={12} key={el.activityId}>
                      <ActivityCard
                        activityId={el.activityId}
                        activityName={el.activityName}
                        activityStartDateString={el.activityStartDateString}
                        activityEndDateString={el.activityEndDateString}
                        activityDescription={el.activityDescription}
                        activityCoverPath={el.activityCoverPath}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={classes.header}>
                <Typography variant="h5" gutterBottom color="primary">
                  歡迎使用 MICEPass!
                </Typography>
                <Typography variant="body1">
                  您可以透過下面的教學影片快速上手所有功能。
                </Typography>
              </div>
              <Grid container spacing={2}>
                {videos.map((el) => (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    key={el.iframeSrc}
                    className={classes.item}
                  >
                    <YoutubePlayer
                      placeholder={el.placeholder}
                      iframeSrc={el.iframeSrc}
                      iframeTitle={el.title}
                    />
                    <div className={classes.videoInfo}>
                      <Typography variant="body1" gutterBottom>
                        <strong>{el.title}</strong>
                      </Typography>
                      <Typography color="textSecondary">
                        {el.subtitle}
                      </Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Main>
    );
  }

  return (
    <Main>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={hasActivities ? 9 : 12}>
            <div className={classes.header}>
              <Typography variant="h5" gutterBottom color="primary">
                歡迎使用 MICEPass!
              </Typography>
              <Typography variant="body1">
                您可以透過下面的教學影片快速上手所有功能。
              </Typography>
            </div>
            <Grid container spacing={2}>
              {videos.map((el) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  key={el.iframeSrc}
                  className={classes.item}
                >
                  <YoutubePlayer
                    placeholder={el.placeholder}
                    iframeSrc={el.iframeSrc}
                    iframeTitle={el.title}
                  />
                  <div className={classes.videoInfo}>
                    <Typography variant="body1" gutterBottom>
                      <strong>{el.title}</strong>
                    </Typography>
                    <Typography color="textSecondary">{el.subtitle}</Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {hasActivities && (
            <Grid item xs={3}>
              <div className={classes.header}>
                <Typography variant="h6" gutterBottom color="primary">
                  本日活動
                </Typography>
              </div>
              <Grid container spacing={2} direction="column">
                {data?.source.map((el) => (
                  <Grid item xs={12} key={el.activityId}>
                    <ActivityCard
                      activityId={el.activityId}
                      activityName={el.activityName}
                      activityStartDateString={el.activityStartDateString}
                      activityEndDateString={el.activityEndDateString}
                      activityDescription={el.activityDescription}
                      activityCoverPath={el.activityCoverPath}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </Main>
  );
};

export default Home;
