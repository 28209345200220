import React, { FC, useState } from 'react';

import Checkbox from '@eGroupTeam/material/Checkbox';
import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
} from '@eGroupTeam/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { OrganizationModule } from 'types';

const useStyles = makeStyles({
  paper: {
    position: 'relative',

    '&::after': {
      top: '-1px',
      left: '0',
      right: '0',
      height: '1px',
      content: '""',
      opacity: '1',
      position: 'absolute',
      transition:
        'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
});
export interface PermissionAccordionProps {
  modules: OrganizationModule[];
  label: string;
  defaultValues?: string[];
  onValuesChange?: (values: string[]) => void;
}

const PermissionAccordion: FC<PermissionAccordionProps> = ({
  modules,
  label,
  defaultValues = [],
  onValuesChange,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState<string[]>(defaultValues);

  const handleAllChange = (e) => {
    if (e.target.checked && values.length === 0) {
      const nextValues = modules.map((el) => el.serviceModule.serviceModuleId);
      setValues(nextValues);
      if (onValuesChange) {
        onValuesChange(nextValues);
      }
    } else {
      setValues([]);
      if (onValuesChange) {
        onValuesChange([]);
      }
    }
  };

  const handleSingleChange = (e) => {
    if (e.target.checked) {
      const nextValues = [...values, e.target.value];
      setValues(nextValues);
      if (onValuesChange) {
        onValuesChange(nextValues);
      }
    } else {
      const nextValues = values.filter((el) => el !== e.target.value);
      setValues(nextValues);
      if (onValuesChange) {
        onValuesChange(nextValues);
      }
    }
  };

  if (modules.length === 1 && modules[0]) {
    const el = modules[0];
    return (
      <Paper square className={classes.paper}>
        <AccordionSummary>
          <Checkbox
            label={el.serviceModule.serviceModuleNameZh}
            value={el.serviceModule.serviceModuleId}
            onChange={handleSingleChange}
            checked={values.includes(el.serviceModule.serviceModuleId)}
          />
        </AccordionSummary>
      </Paper>
    );
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Checkbox
          label={label}
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
          onChange={handleAllChange}
          MuiCheckboxProps={{
            indeterminate: values.length > 0 && values.length < modules.length,
          }}
          checked={modules.length === values.length}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {modules.map((el) => (
            <Grid item xs={12} key={el.organizationModuleId}>
              <Checkbox
                label={el.serviceModule.serviceModuleNameZh}
                value={el.serviceModule.serviceModuleId}
                onChange={handleSingleChange}
                checked={values.includes(el.serviceModule.serviceModuleId)}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PermissionAccordion;
