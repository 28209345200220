import { AxiosError } from 'axios';
import { createAction } from '@reduxjs/toolkit';
import { MutatorCallback } from 'swr/dist/types';

export const fetchPostUserApplyRetrainEmail = createAction<{
  organizationId: string;
  activityId: string;
  userApplyId: string;
}>('mainPages/activity/activityPeople/fetchPostUserApplyRetrainEmail');
export const fetchPostUserApplyRetrainEmailRequest = createAction(
  'mainPages/activity/activityPeople/fetchPostUserApplyRetrainEmailRequest'
);
export const fetchPostUserApplyRetrainEmailSuccess = createAction<unknown>(
  'mainPages/activity/activityPeople/fetchPostUserApplyRetrainEmailSuccess'
);
export const fetchPostUserApplyRetrainEmailFailure = createAction<AxiosError>(
  'mainPages/activity/activityPeople/fetchPostUserApplyRetrainEmailFailure'
);

export const fetchPostUserApplyActivityClass = createAction<{
  organizationId: string;
  activityId: string;
  userApplyId: string;
  activityClassId: string;
  callback: MutatorCallback;
}>('mainPages/activity/activityPeople/fetchPostUserApplyActivityClass');
export const fetchPostUserApplyActivityClassRequest = createAction(
  'mainPages/activity/activityPeople/fetchPostUserApplyActivityClassRequest'
);
export const fetchPostUserApplyActivityClassSuccess = createAction<unknown>(
  'mainPages/activity/activityPeople/fetchPostUserApplyActivityClassSuccess'
);
export const fetchPostUserApplyActivityClassFailure = createAction<AxiosError>(
  'mainPages/activity/activityPeople/fetchPostUserApplyActivityClassFailure'
);
