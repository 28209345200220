import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles } from '@eGroupTeam/material';

import Paper from '@eGroupTeam/material/Paper';

const styles = (theme) => ({
  root: {
    maxWidth: 600,
  },
});

const IntroduceCard = React.forwardRef((props, ref) => {
  const { classes, className, ...other } = props;

  return (
    <Paper className={clsx(classes.root, className)} ref={ref} {...other} />
  );
});

IntroduceCard.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
};

export default withStyles(styles)(IntroduceCard);
