import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Box,
  Fade,
  Typography,
  Container,
  Hidden,
  AppBar,
  Toolbar,
} from '@eGroupTeam/material';
// TODO: Need migrate to react hook form
// @ts-ignore
import { submit } from 'redux-form/immutable';

import Main from '@eGroupTeam/material-layout/Main';
import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import Button from '@eGroupTeam/material/Button';

import Logo from 'components/Logo';
import UserMenu from 'components/UserMenu';
import { getIsPosting } from './redux';
import NewOrganizationForm, { FORM } from './NewOrganizationForm';

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: '#fff',
  },
  form: {
    maxWidth: 535,
    margin: '0 auto',
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

const NewOrganization = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPosting = useSelector(getIsPosting);

  return (
    <>
      <AppBar elevation={1} className={classes.appbar}>
        <Toolbar>
          <Logo />
          <Box flexGrow={1} />
          <UserMenu />
        </Toolbar>
      </AppBar>
      <NavbarBrick />
      <Main>
        <Container>
          <Hidden smDown>
            <Fade in timeout={1000}>
              <Typography variant="h2" align="center" gutterBottom>
                歡迎使用 MICEPass
              </Typography>
            </Fade>
          </Hidden>
          <Hidden mdUp>
            <Fade in timeout={1000}>
              <Typography variant="h4" align="center" gutterBottom>
                歡迎使用 MICEPass
              </Typography>
            </Fade>
          </Hidden>
          <Fade in timeout={3000}>
            <Typography variant="h5" align="center">
              建立主辦單位
            </Typography>
          </Fade>
          <Fade in timeout={4000}>
            <div className={classes.form}>
              <NewOrganizationForm />
              <Button
                className={classes.button}
                loading={isPosting}
                onClick={() => dispatch(submit(FORM))}
                fullWidth
                variant="contained"
                color="primary"
              >
                建立
              </Button>
            </div>
          </Fade>
        </Container>
      </Main>
    </>
  );
};

export default NewOrganization;
