import React, { useCallback } from 'react';

import apis from 'redux/apisMember';
import { useLocation } from 'react-router';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';

import {
  makeStyles,
  Typography,
  Modal,
  Fade,
  Paper,
  Box,
  IconButton,
  Backdrop,
  Grid,
  Button
} from '@eGroupTeam/material';
import CloseIcon from "@material-ui/icons/Close";
import GoogleIcon from '@eGroupTeam/material-icons/Google';
import FacebookIcon from '@material-ui/icons/Facebook';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';

export const DIALOG = 'loginDialog';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalContent: {
    width: "100%",
    maxWidth: "500px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  modalHeader: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #dee2e6",
  },
  modalHeaderTitle: {
    width: "100%",
    fontSize: "1.5rem",
    lineHeight: "1.5",
  },
  icon: {
    position: 'absolute',
    left: theme.spacing(),
  },
  googleBtn: {
    backgroundColor: '#fff',
    borderColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
    '&:active': {
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
  },
  fbBtn: {
    backgroundColor: '#4267b2',
    borderColor: '#4267b2',
    '&:hover': {
      backgroundColor: '#4267b2',
      borderColor: '#4267b2',
    },
    '&:active': {
      backgroundColor: '#4267b2',
      borderColor: '#4267b2',
    },
  }
}));

const redirectToMeList = ['/', '/pricing', '/reference', '/terms', '/privacy'];

const LoginDialog = ({ isOpen, handleClose }) => {
  const classes = useStyles();
  const location = useLocation();
  const { excute: getGoogleLoginUrl } = useAxiosApiWrapper(
    apis.fetchGetGoogleLoginUrl
  );
  const { excute: getFacebookLoginUrl } = useAxiosApiWrapper(
    apis.fetchGetFacebookLoginUrl
  );

  const setLoginRedirectURL = useCallback(() => {
    let url = `${location.pathname}${location.search}`;
    if (redirectToMeList.indexOf(location.pathname) !== -1) {
      url = '/me';
    }
    window.localStorage.setItem('loginRedirectURL', url);
  }, [location.pathname, location.search]);

  const handleGoogleLogin = () => {
    getGoogleLoginUrl().then((response) => {
      setLoginRedirectURL();
      window.open(response.data as string, '_self');
    });
  };

  const handleFbLogin = () => {
    getFacebookLoginUrl().then((response) => {
      setLoginRedirectURL();
      window.open(response.data as string, '_self');
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper elevation={0} className={classes.modalContent}>
          <Box
            className={classes.modalHeader}
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h4"
              fontWeight={500}
              color="inherit"
              align="center"
              className={classes.modalHeaderTitle}
            >
              馬上登入，開始你的活動
            </Typography>
            <IconButton color="inherit" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleGoogleLogin}
                  muiButtonClasses={{
                    root: classes.googleBtn
                  }}
                >
                  <GoogleIcon className={classes.icon} /> Google
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleFbLogin}
                  muiButtonClasses={{
                    root: classes.fbBtn
                  }}
                >
                  <FacebookIcon className={classes.icon} /> Facebook
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default withReduxDialog(DIALOG)(LoginDialog);
