import React, { useEffect } from 'react';

import { getSelectedOrg, setSelectedOrg } from 'redux/modules/common';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import apis from 'redux/apis';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { useHistory } from 'react-router';
import Main from '@eGroupTeam/material-layout/Main';
import {
  makeStyles,
  Container,
  Hidden,
  Step,
  StepLabel,
  Stepper,
  Grid,
  StepContent,
} from '@eGroupTeam/material';
import TogglePanel from '@eGroupTeam/material/TogglePanel';
import { fromJS } from 'immutable';
import { ActivityFormInput } from 'types';
import transformer from 'utils/formValuesTransformer';
import PageSection from 'components/PageSection';
import StepperConnector from 'components/StepperConnector';
import StepLabelIcon from 'components/StepLabelIcon';
import { DIALOG as SUCCESS_DIALOG } from 'mainPages/Activity/components/ActivityCreateSuccessDialog';
import { setActiveStep, reset, handleNextStep, setFormValues } from './actions';
import { getActiveStep, getFormValues } from './selectors';
import StepSelectOrg from './StepSelectOrg';
import StepSelectActivityType from './StepSelectActivityType';
import StepEditActivity from './StepEditActivity';
import StepEditActivityDetail from './StepEditActivityDetail';
import StepSelectProducts from './StepSelectProducts';
import StepGetTrialFee from './StepGetTrialFee';

const useStyles = makeStyles((theme) => ({
  stepper: {
    width: theme.layout.sideMenuWidth,
    background: 'none',
  },
  step: {
    cursor: 'pointer',
  },
  main: {
    paddingLeft: theme.layout.sideMenuWidth,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  backBtn: {
    width: '100%',
    padding: theme.spacing(1),
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));

export const STEP_SELECT_ORG_INDEX = 0;
export const STEP_SELECT_ACTIVITY_TYPE_INDEX = 1;
export const STEP_EDIT_ACTIVITY_INDEX = 2;
export const STEP_EDIT_ACTIVITY_DETAIL_INDEX = 3;
export const STEP_SELECT_PRODUCT_INDEX = 4;
export const STEP_GET_TRIAL_FEE_INDEX = 5;

const NewActivity = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const activeStep = useSelector(getActiveStep);
  const formValues: ActivityFormInput = useSelector(getFormValues);
  const history = useHistory();
  const { excute: createActivity, isLoading: isPosting } = useAxiosApiWrapper(
    apis.fetchPostActivity
  );
  const { excute: getOrg } = useAxiosApiWrapper(
    apis.member.fetchGetOrganization
  );

  useEffect(() => {
    window.localStorage.setItem(
      'newActivityState',
      JSON.stringify({
        activeStep,
        formValues,
      })
    );
  }, [activeStep, formValues]);

  const handleTypeChange = (e) => {
    const nextType = Number(e.target.value);
    dispatch(
      setFormValues({
        ...formValues,
        activityType: nextType,
      })
    );
  };

  const handleModelChange = (model: string) => {
    dispatch(
      setFormValues({
        ...formValues,
        activityContentEditor: model,
      })
    );
  };

  const handleStepClick = (step: number) => () => {
    if (activeStep > step) {
      dispatch(setActiveStep(step));
    }
  };

  const handleFinish = () => {
    const payload = {
      organizationId: selectedOrg.get('organizationId'),
      ...transformer.activity.formToApi(formValues),
    };
    createActivity(payload).then((response) => {
      history.push(`/me/activities/${response.data.activityId}/quick-guide`);
      window.localStorage.removeItem('newActivityState');
      window.localStorage.removeItem('isCreatingActivity');
      dispatch(reset());
      dispatch(openDialog(SUCCESS_DIALOG));
      getOrg({
        organizationId: selectedOrg.get('organizationId'),
      }).then((response) => {
        dispatch(setSelectedOrg(fromJS(response.data)));
      });
    });
  };

  return (
    <Main className={classes.main}>
      <Container>
        <PageSection>
          <Grid container wrap="wrap">
            <Grid item>
              <Hidden smDown>
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  className={classes.stepper}
                  connector={<StepperConnector />}
                >
                  <Step
                    className={clsx({
                      [classes.step]: activeStep > STEP_SELECT_ORG_INDEX,
                    })}
                    onClick={handleStepClick(STEP_SELECT_ORG_INDEX)}
                    expanded={false}
                  >
                    <StepLabel StepIconComponent={StepLabelIcon}>
                      選擇主辦單位
                    </StepLabel>
                    <StepContent />
                  </Step>
                  <Step
                    className={clsx({
                      [classes.step]:
                        activeStep > STEP_SELECT_ACTIVITY_TYPE_INDEX,
                    })}
                    onClick={handleStepClick(STEP_SELECT_ACTIVITY_TYPE_INDEX)}
                    expanded={false}
                  >
                    <StepLabel StepIconComponent={StepLabelIcon}>
                      選擇活動類型
                    </StepLabel>
                    <StepContent />
                  </Step>
                  <Step
                    className={clsx({
                      [classes.step]: activeStep > STEP_EDIT_ACTIVITY_INDEX,
                    })}
                    onClick={handleStepClick(STEP_EDIT_ACTIVITY_INDEX)}
                    expanded={false}
                  >
                    <StepLabel StepIconComponent={StepLabelIcon}>
                      編輯基本資料
                    </StepLabel>
                    <StepContent />
                  </Step>
                  <Step
                    className={clsx({
                      [classes.step]:
                        activeStep > STEP_EDIT_ACTIVITY_DETAIL_INDEX,
                    })}
                    onClick={handleStepClick(STEP_EDIT_ACTIVITY_DETAIL_INDEX)}
                    expanded={false}
                  >
                    <StepLabel StepIconComponent={StepLabelIcon}>
                      編輯活動內容
                    </StepLabel>
                    <StepContent />
                  </Step>
                  <Step
                    className={clsx({
                      [classes.step]: activeStep > STEP_SELECT_PRODUCT_INDEX,
                    })}
                    onClick={handleStepClick(STEP_SELECT_PRODUCT_INDEX)}
                    expanded={false}
                  >
                    <StepLabel StepIconComponent={StepLabelIcon}>
                      選擇加購服務
                    </StepLabel>
                    <StepContent />
                  </Step>
                  <Step
                    className={clsx({
                      [classes.step]: activeStep > STEP_GET_TRIAL_FEE_INDEX,
                    })}
                    onClick={handleStepClick(STEP_GET_TRIAL_FEE_INDEX)}
                    expanded={false}
                  >
                    <StepLabel StepIconComponent={StepLabelIcon}>
                      確認付款
                    </StepLabel>
                    <StepContent />
                  </Step>
                </Stepper>
              </Hidden>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <TogglePanel index={STEP_SELECT_ORG_INDEX} value={activeStep}>
                <StepSelectOrg activeStep={activeStep} />
              </TogglePanel>
              <TogglePanel
                index={STEP_SELECT_ACTIVITY_TYPE_INDEX}
                value={activeStep}
              >
                <StepSelectActivityType
                  activeStep={activeStep}
                  value={formValues.activityType}
                  onChange={handleTypeChange}
                />
              </TogglePanel>
              <TogglePanel
                index={STEP_EDIT_ACTIVITY_INDEX}
                value={activeStep}
                implementation="js"
              >
                <StepEditActivity
                  activeStep={activeStep}
                  activityType={formValues.activityType}
                  onSubmit={(values) => {
                    dispatch(
                      setFormValues({
                        ...formValues,
                        ...values,
                      })
                    );
                    dispatch(handleNextStep());
                  }}
                  defaultValues={formValues}
                />
              </TogglePanel>
              <TogglePanel
                index={STEP_EDIT_ACTIVITY_DETAIL_INDEX}
                value={activeStep}
              >
                <StepEditActivityDetail
                  activeStep={activeStep}
                  value={formValues.activityContentEditor}
                  onModelChange={handleModelChange}
                />
              </TogglePanel>
              <TogglePanel index={STEP_SELECT_PRODUCT_INDEX} value={activeStep}>
                <StepSelectProducts
                  activeStep={activeStep}
                  onChange={(value) => {
                    dispatch(
                      setFormValues({
                        ...formValues,
                        paymentOrder: {
                          paymentProductList: value.map((el) => ({
                            serviceProductId: el,
                          })),
                        },
                      })
                    );
                  }}
                  value={formValues.paymentOrder.paymentProductList.map(
                    (el) => el.serviceProductId
                  )}
                />
              </TogglePanel>
              <TogglePanel
                index={STEP_GET_TRIAL_FEE_INDEX}
                value={activeStep}
                implementation="js"
              >
                <StepGetTrialFee
                  loading={isPosting}
                  onNextClick={handleFinish}
                  value={transformer.activityTrialFee.formToApi(formValues)}
                />
              </TogglePanel>
            </Grid>
          </Grid>
        </PageSection>
      </Container>
    </Main>
  );
};

export default NewActivity;
