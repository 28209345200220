import React, { useState } from 'react';

import {
  withReduxDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import SearchBarOptionsWidget from '@eGroupTeam/material-module/SearchBarOptionsWidget';
import Main from '@eGroupTeam/material-layout/Main';
import DataTableCollapseRow from '@eGroupTeam/material-module/DataTableCollapseRow';
import {
  Button,
  Container,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@eGroupTeam/material';
import PageHeader from 'components/PageHeader';
import PageSection from 'components/PageSection';
import {
  UserApply,
  UserApplyActivityClass,
} from 'types';
import {
  UserApplyActivityClassIsCanceled,
} from 'enums'
import { format } from '@eGroupTeam/utils/dateUtils';
import AddActivityClassDialog, {
  DIALOG as ADD_ACTIVITY_CLASS_DIALOG,
} from 'components/AddActivityClassDialog';
import useMemberApplyActivities from 'utils/useMemberApplyActivities';
import useActivityClasses from 'utils/useActivityClasses';
import useMemberUserApply from 'utils/useMemberUserApply';
import {
  fetchPatchUserApplyActivityClass,
  fetchPostUserApplyActivityClass,
} from './actions';

const CONFIRM_STATUS_CHANGE_DIALOG = 'confirmUserApplyStatusChangeDialog';

const ConfirmStatusChangeDialog = withReduxDialog(CONFIRM_STATUS_CHANGE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);

const Activities = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedUserApply, setSelectedUserApply] = useState<UserApply>();
  const [
    selectedUserApplyActivityClass,
    setSelectedUserApplyActivityClass,
  ] = useState<UserApplyActivityClass>();
  const [isCanceled, setIsCanceled] = useState('all');
  const [canceledReason, setCanceledReason] = useState('');
  const { data: userApply, mutate: userApplyMutate } = useMemberUserApply({
    activityId: selectedUserApply?.activity.activityId,
    userApplyId: selectedUserApply?.userApplyId,
  });
  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    setPayload,
    page,
    rowsPerPage,
  } = useDataTable('Activities', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });
  const {
    data,
    isValidating,
    mutate: memberApplyActivitiesMutate,
  } = useMemberApplyActivities({}, payload);
  const { data: activityClasses } = useActivityClasses({
    activityId: selectedUserApply?.activity.activityId,
  });

  const handleCancelStatusChange = (e) => {
    setIsCanceled(e.target.value);
    setPayload((value) => ({
      ...value,
      isCanceled: e.target.value !== 'all' ? e.target.value : undefined,
    }));
  };

  const handleSaveStatus = () => {
    if (!selectedUserApply || !selectedUserApplyActivityClass) return;
    dispatch(
      fetchPatchUserApplyActivityClass({
        activityId: selectedUserApply.activity.activityId,
        userApplyId: selectedUserApplyActivityClass.userApply.userApplyId,
        activityClassId:
          selectedUserApplyActivityClass.activityClass.activityClassId,
        userApplyActivityClassCanceledReason: canceledReason,
        isCanceled: selectedUserApplyActivityClass.isCanceled ? 0 : 1,
        callback: memberApplyActivitiesMutate,
      })
    );
  };

  const handleAddActivityClass = (userApply: UserApply) => (e) => {
    e.stopPropagation();
    setSelectedUserApply(userApply);
    dispatch(openDialog(ADD_ACTIVITY_CLASS_DIALOG));
  };

  const renderColumns = (rowData) => (
    <TableRow>
      {rowData.map((el) => (
        <TableCell key={el}>
          <Typography variant="body2">{el}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );

  const renderStatusContext = () => {
    if (!selectedUserApplyActivityClass) return undefined;
    return (
      <>
        <Typography variant="body1" gutterBottom>
          確定要
          {selectedUserApplyActivityClass.isCanceled === 1 ? '恢復' : '取消'}
        </Typography>
        {selectedUserApplyActivityClass.isCanceled === 0 ? (
          <TextField
            label="取消報名原因"
            value={canceledReason}
            onChange={(e) => setCanceledReason(e.target.value)}
            fullWidth
          />
        ) : (
          <Typography>
            取消報名原因：
            {
              selectedUserApplyActivityClass.userApplyActivityClassCanceledReason
            }
          </Typography>
        )}
      </>
    );
  };

  const renderDataRow = (rowData: unknown) => {
    const userApply = rowData as UserApply;
    return (
      <DataTableCollapseRow
        key={userApply.userApplyId}
        colSpan={10}
        hover
        onClick={() => {
          history.push(`/activities/${userApply.activity.activityId}`);
        }}
      >
        <>
          <TableCell>{userApply.activity.activityName}</TableCell>
          <TableCell>{userApply.userApplyCreateDateString}</TableCell>
          <TableCell>
            <Button onClick={handleAddActivityClass(userApply)}>
              加報場次
            </Button>
          </TableCell>
        </>
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>場次名稱</TableCell>
                <TableCell>場次開始時間</TableCell>
                <TableCell>場次結束時間</TableCell>
                <TableCell>報名狀態</TableCell>
                <TableCell>變更狀態</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userApply.userApplyActivityClassList.map((el) => (
                <TableRow key={el.activityClass.activityClassId}>
                  <TableCell>{el.activityClass.activityClassName}</TableCell>
                  <TableCell>
                    {format(
                      el.activityClass.activityClassStartDateString,
                      'PPPP p'
                    )}
                  </TableCell>
                  <TableCell>
                    {format(
                      el.activityClass.activityClassEndDateString,
                      'PPPP p'
                    )}
                  </TableCell>
                  <TableCell>
                    {UserApplyActivityClassIsCanceled[el.isCanceled]}
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => {
                        setSelectedUserApply(userApply);
                        setSelectedUserApplyActivityClass(el);
                        dispatch(openDialog(CONFIRM_STATUS_CHANGE_DIALOG));
                      }}
                    >
                      {el.isCanceled === 1 ? '恢復報名' : '取消報名'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </DataTableCollapseRow>
    );
  };

  return (
    <>
      <ConfirmStatusChangeDialog
        primary={`${
          selectedUserApplyActivityClass &&
          selectedUserApplyActivityClass.isCanceled === 1
            ? '恢復'
            : '取消'
        }報名狀態`}
        onConfirm={handleSaveStatus}
        maxWidth="xs"
        fullWidth
      >
        {renderStatusContext()}
      </ConfirmStatusChangeDialog>
      <AddActivityClassDialog
        activityClasses={activityClasses?.source}
        userApply={userApply}
        onConfirm={(selectedActivityClassId) => {
          if (selectedActivityClassId && userApply) {
            dispatch(
              fetchPostUserApplyActivityClass({
                activityId: userApply.activity.activityId,
                userApplyId: userApply.userApplyId,
                activityClassId: selectedActivityClassId,
                callback: () => {
                  userApplyMutate();
                  memberApplyActivitiesMutate();
                },
              })
            );
          }
        }}
      />
      <Main>
        <Container>
          <PageHeader
            title="我的活動"
            subtitle="查看所有活動報名狀態，你可以在這裡取消活動報名或是恢復活動報名。"
          />
          <PageSection>
            <Paper>
              <DataTable
                size="small"
                title="活動列表"
                SearchBarProps={{
                  placeholder: '搜尋活動',
                  onChange: handleSearchChange,
                  defaultValue: payload.query,
                  renderOptions: ({ handleDropDownClose }) => (
                    <SearchBarOptionsWidget>
                      <>
                        <TextField
                          value={isCanceled}
                          onChange={handleCancelStatusChange}
                          label="篩選報名狀態"
                          select
                          fullWidth
                        >
                          <MenuItem value="all">全部</MenuItem>
                          <MenuItem value="0">
                            {UserApplyActivityClassIsCanceled[0]}
                          </MenuItem>
                          <MenuItem value="1">
                            {UserApplyActivityClassIsCanceled[1]}
                          </MenuItem>
                        </TextField>
                      </>
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleDropDownClose}
                        >
                          關閉
                        </Button>
                      </>
                    </SearchBarOptionsWidget>
                  ),
                }}
                data={data?.source ?? []}
                loading={isValidating}
                columns={['', '活動名稱', '報名時間', '操作']}
                serverSide
                renderColumns={renderColumns}
                renderDataRow={renderDataRow}
                MuiTablePaginationProps={{
                  count: data?.total ?? 0,
                  labelRowsPerPage: '每頁幾筆',
                  page,
                  rowsPerPage,
                  onPageChange: handleChangePage,
                  onRowsPerPageChange: handleRowsPerPageChange,
                }}
              />
            </Paper>
          </PageSection>
        </Container>
      </Main>
    </>
  );
};

export default Activities;
