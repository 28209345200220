import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchPostOrganization,
  fetchPostOrganizationRequest,
  fetchPostOrganizationSuccess,
  fetchPostOrganizationFailure,
} = actionCreators.mainPages.root;

/** Types */
export const FETCH_POST_ORGANIZATION = fetchPostOrganization().type;
export const FETCH_POST_ORGANIZATION_REQUEST = fetchPostOrganizationRequest()
  .type;
export const FETCH_POST_ORGANIZATION_SUCCESS = fetchPostOrganizationSuccess()
  .type;
export const FETCH_POST_ORGANIZATION_FAILURE = fetchPostOrganizationFailure()
  .type;

/** Selectors */
/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'root'], Map());

const getFetchPostOrganization = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostOrganization', Map())
);

export const getIsPosting = createSelector(
  getFetchPostOrganization,
  (fetchPost) => fetchPost.get('isLoading')
);
