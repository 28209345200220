import React, { VFC } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import Container from "@eGroupTeam/material/Container";
import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import { Box, Button } from "@eGroupTeam/material";
import Aos from "@eGroupTeam/material-module/Aos";
import { DIALOG as LOGIN_DIALOG } from 'components/LoginDialog';
import { openDialog } from "@eGroupTeam/redux-modules/immutable/dialogs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import adminImg from "./images/admin-image.svg";
import printImg from "./images/print-image.svg";
import Bounce from "../components/Bounce";
import ContactUsSection from "../components/ContactUsSection";
import HeaderSection from "./HeaderSection";
import MiceValueSection from "./MiceValueSection";
import MiceFeatureSection from "./MiceFeatureSection";
import TestimonialsSection from "./TestimonialsSection";

const useStyles = makeStyles((theme) => ({
  sectionRow: {
    marginTop: "4rem",
  },
  textContents: {
    marginTop: "5rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  subTitle: {
    color: theme.egPalette.primary[1],
    marginBottom: "0",
    fontSize: "20px",
  },
  title: {
    color: "#42495b",
    fontSize: "31px",
    marginBottom: "0.5rem",
    lineHeight: "1.2",
  },
  description: {
    color: "#444444",
    marginBottom: theme.spacing(2),
  },
  requestBtn: {
    "& button": {
      background: "#104c74 !important",
      color: "white",
      outline: "none !important",
      padding: "0.75rem 3rem",
      fontSize: "1rem",
    },
    "& button:hover": {
      background: "white !important",
      color: "#104c74",
      border: "1px solid #104c74",
    },
  },
  btnMarginBottom: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "4rem",
    },
  },
}));

const Home: VFC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <HeaderSection />
      <Container className={classes.sectionRow}>
        <Aos variant="slideInDown" config={{ duration: 900 }}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <Bounce>
                <Box textAlign="center">
                  <img src={adminImg} alt="Admin" width="100%" />
                </Box>
              </Bounce>
            </Grid>
            <Grid item sm={12} md={6}>
              <Box className={classes.textContents}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  className={classes.subTitle}
                >
                  最棒的活動幫手
                </Typography>
                <Typography
                  variant="h2"
                  fontWeight={700}
                  className={classes.title}
                >
                  輕鬆管理活動，專注經營客戶
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  活動的成功來自出席率與轉換率，MICEPass 降低行政成本，並提供簡單的方式永續經營客戶，更了解你的客戶，是活動成功的第一步。
                </Typography>
                <Button rounded className={classes.requestBtn} onClick={() => dispatch(openDialog(LOGIN_DIALOG))}>
                  首次登入，免費辦活動
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Aos>
      </Container>
      <Container className={classes.sectionRow}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <Aos variant="slideInLeft" config={{ duration: 500 }}>
              <Box className={classes.textContents}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  className={classes.subTitle}
                >
                  智能平台，人性互動
                </Typography>
                <Typography
                  variant="h2"
                  fontWeight={700}
                  className={classes.title}
                >
                  讓客戶體驗更棒的活動
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  智能化的平台，讓客戶收到即時資訊、體驗更快速的報到動線 ; 客戶需要經營，好的平台讓你更輕省，客戶更滿意。
                </Typography>
                <Link to="/reference" style={{ textDecoration: 'none' }}>
                  <Button
                    rounded
                    className={clsx({
                      [classes.requestBtn]: true,
                      [classes.btnMarginBottom]: true,
                    })}
                  >
                    精彩活動，完美呈現
                  </Button>
                </Link>
              </Box>
            </Aos>
          </Grid>
          <Grid item sm={12} md={6}>
            <Aos variant="slideInRight" config={{ duration: 500 }}>
              <Bounce>
                <Box textAlign="center">
                  <img src={printImg} alt="Admin" width="100%" />
                </Box>
              </Bounce>
            </Aos>
          </Grid>
        </Grid>
      </Container>
      <MiceValueSection />
      <MiceFeatureSection />
      <TestimonialsSection />
      <ContactUsSection />
    </>
  );
};

export default Home;
