import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchPostLogout,
  fetchPostLogoutRequest,
  fetchPostLogoutSuccess,
  fetchPostLogoutFailure,

  fetchGetServiceProducts,
  fetchGetServiceProductsRequest,
  fetchGetServiceProductsSuccess,
  fetchGetServiceProductsFailure,

  setSelectedOrg,
} = actionCreators.modules.common;

/** Types */
export const FETCH_POST_LOGOUT = fetchPostLogout().type;
export const FETCH_POST_LOGOUT_REQUEST = fetchPostLogoutRequest().type;
export const FETCH_POST_LOGOUT_SUCCESS = fetchPostLogoutSuccess().type;
export const FETCH_POST_LOGOUT_FAILURE = fetchPostLogoutFailure().type;

export const FETCH_GET_SERVICE_PRODUCTS = fetchGetServiceProducts().type;
export const FETCH_GET_SERVICE_PRODUCTS_REQUEST = fetchGetServiceProductsRequest()
  .type;
export const FETCH_GET_SERVICE_PRODUCTS_SUCCESS = fetchGetServiceProductsSuccess()
  .type;
export const FETCH_GET_SERVICE_PRODUCTS_FAILURE = fetchGetServiceProductsFailure()
  .type;

export const SET_SELECTED_ORG = setSelectedOrg().type;

/** Selectors */
/**
 * Ui states
 */
const getUiStates = (state) => state.getIn(['modules', 'common']);

export const getSelectedOrg = createSelector(getUiStates, (uiStates) =>
  uiStates.get('selectedOrg')
);

/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'modules', 'common'], Map());

const getFetchGetServiceProducts = createSelector(getApiStates, (rootStates) =>
  rootStates.get('fetchGetServiceProducts', Map())
);

export const getServiceProducts = createSelector(
  getFetchGetServiceProducts,
  (fetchResult) => fetchResult.get('response')
);
