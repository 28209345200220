import React, { VFC } from "react";
import { makeStyles } from "@material-ui/core";

import Container from "@eGroupTeam/material/Container";
import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import { Box } from "@eGroupTeam/material";

import crmImg from "./images/crm.svg";
import notificationImg from "./images/notification.svg";
import multiCheckImg from "./images/multi-check.svg";
import realTimeImg from "./images/real-time.svg";
import onlineSurvayImg from "./images/online-survay.svg";
import MiceFeatureItem from "./MiceFeatureItem";

const useStyles = makeStyles((theme) => ({
  featureSection: {
    backgroundImage:
      "linear-gradient(to bottom, #2176a8, #1d6995, #195c83, #154f71, #12435f)",
    padding: "1rem",
  },
  sectionHeader: {
    marginBottom: "2rem",
    "& h2": {
      fontSize: "23px",
      color: theme.palette.common.white,
      lineHeight: "1.2",
      marginBottom: "0.5rem",
    },
    "& p": {
      fontSize: "15px",
      color: theme.palette.common.white,
    },
  },
}));

const MiceFeatureSection: VFC = () => {
  const classes = useStyles();

  const featureList = [
    {
      title: "客戶關係管理",
      img: <img src={crmImg} alt="CRM" decoding="async" />,
      firstLine: "掌握你的VIP客群",
      secondLine: "提升活動報到率",
    },
    {
      title: "通知系統",
      img: <img src={notificationImg} alt="Notification" decoding="async" />,
      firstLine:
        "大量寄信，不用擔心信箱被Email服務商擋信",
      secondLine: "監控信件狀態，開啟、點擊、退回，有效排除無效信箱",
    },
    {
      title: "多元報到方式",
      img: <img src={multiCheckImg} alt="Multi-Check" decoding="async" />,
      firstLine: "人臉辨識、QRCode、基本資料",
      secondLine: "適應各種簽到退情境，讓應用與動線規劃更便利",
    },
    {
      title: "即時戰情",
      img: <img src={realTimeImg} alt="Real-time" decoding="async" />,
      firstLine: "手機即可掌握活動動態",
      secondLine:
        "活動狀態、簽到退比例",
    },
    {
      title: "線上問卷與統計分析",
      img: <img src={onlineSurvayImg} alt="Online survay" decoding="async" />,
      firstLine: "自動產生結果，製作成果報告更方便",
      secondLine: "輸出Excel、PDF、SPSS檔案",
    },
  ];

  return (
    <Box className={classes.featureSection}>
      <Container>
        <div className={classes.sectionHeader}>
          <Typography variant="h2" fontWeight={700} align="center">
            MICEPass 特色
          </Typography>
          <Typography variant="body1" align="center" fontWeight={700}>
            完整的服務功能，涵蓋活動前、中、後，一個平台就搞定
          </Typography>
        </div>
        <Grid container spacing={3}>
          {featureList.map((feature) => (
            <MiceFeatureItem feature={feature} key={feature.title} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MiceFeatureSection;
