import axios from 'axios';
import config from './apiConfig';
import { LogPayload } from './root';

export const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  ...config,
});

export default {
  // 錯誤 log
  createLog: (payload: LogPayload) => fetcher.post('/logs', payload),
};
