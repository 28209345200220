import React, { FC, useCallback, useState } from 'react';

import apis from 'redux/apisOrg';
import {
  closeDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { makeStyles, Box, Container, Segment, SegmentHeader, SegmentContent, Typography } from '@eGroupTeam/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';

import Button from '@eGroupTeam/material/Button';

import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { Activity, ActivityFormInput } from 'types';
import transformer from 'utils/formValuesTransformer';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import TrialFeeDialog, {
  Payload,
  DIALOG as TRIAL_DIALOG,
} from 'components/TrialFeeDialog';
import {
  openSnackbar,
  setSnackbarData,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import { SWRResponse } from 'swr';
import apiErrorMsgMapper from 'utils/apiErrorMsgMapper';
import ActivityUpdateForm from './ActivityUpdateForm';
import ActivityPreviewDialog, { DIALOG } from './ActivityPreviewDialog';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export interface ActivityEditProps {
  activity: Activity;
  mutateActivity: SWRResponse<unknown, unknown>['mutate'];
  mutateActivityClasses: SWRResponse<unknown, unknown>['mutate'];
}

const ActivityEdit: FC<ActivityEditProps> = ({
  activity,
  mutateActivity,
  mutateActivityClasses,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const formMethods = useForm<ActivityFormInput>({
    defaultValues: transformer.activity.apiToForm(activity),
  });
  const { excute: getChangedPeopleAmount } = useAxiosApiWrapper(
    apis.fetchPostActivityChangedPeopleAmount
  );
  const { excute: updateActivity, isLoading } = useAxiosApiWrapper(
    apis.fetchPatchActivity
  );
  const [payload, setPayload] = useState<Payload>();

  const updateActivityProcess = useCallback(() => {
    dispatch(closeDialog(TRIAL_DIALOG));
    updateActivity({
      ...transformer.activity.formToApi(formMethods.getValues()),
      organizationId: selectedOrg.get('organizationId'),
      activityId: activity.activityId,
      activityType: activity.activityType,
    })
      .then(() => {
        dispatch(
          setSnackbarData({
            name: SNACKBAR,
            message: '編輯完成！',
            variant: 'success',
          })
        );
        dispatch(openSnackbar(SNACKBAR));
        mutateActivity();
        mutateActivityClasses();
      })
      .catch((e) => {
        dispatch(
          setSnackbarData({
            name: SNACKBAR,
            message: apiErrorMsgMapper.get(e.response?.data?.message),
            variant: 'error',
          })
        );
        dispatch(openSnackbar(SNACKBAR));
      });
  }, [
    activity.activityId,
    activity.activityType,
    dispatch,
    formMethods,
    mutateActivity,
    mutateActivityClasses,
    selectedOrg,
    updateActivity,
  ]);

  const handleSubmit = () => {
    getChangedPeopleAmount({
      ...transformer.activityTrialFee.formToApi(formMethods.getValues()),
      organizationId: selectedOrg.get('organizationId'),
      activityId: activity.activityId,
      activityType: activity.activityType,
    }).then((response) => {
      if (response.data as number > 0) {
        setPayload({
          ...transformer.activityTrialFee.formToApi(formMethods.getValues()),
          activityId: activity.activityId,
          activityType: activity.activityType,
          peopleChangeCount: response.data as number,
          serviceProductIsFix: 1,
        });
        dispatch(openDialog(TRIAL_DIALOG));
      } else {
        updateActivityProcess();
      }
    });
  };

  return (
    <>
      <ActivityPreviewDialog
        previewUrl={`${window.location.origin}/activities/${activity.activityId}`}
        title={`${activity.activityName}-預覽`}
      />
      <TrialFeeDialog
        payload={payload}
        onConfirmClick={updateActivityProcess}
      />
      <Container>
        <Segment variant="outlined">
          <SegmentHeader>
            <Typography variant="h5">
              活動基本資料
            </Typography>
          </SegmentHeader>
          <SegmentContent>
            <ActivityUpdateForm
              activityType={activity.activityType}
              organizationId={selectedOrg.get('organizationId')}
              {...formMethods}
            />
            <Box
              pt={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="outlined"
                className={classes.button}
                startIcon={<VisibilityIcon />}
                onClick={() => {
                  dispatch(openDialog(DIALOG));
                }}
              >
                活動預覽
              </Button>
              <Button
                loading={isLoading}
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={() => {
                  formMethods.handleSubmit(handleSubmit)();
                }}
              >
                儲存
              </Button>
            </Box>
          </SegmentContent>
        </Segment>
      </Container>
    </>
  );
};

export default ActivityEdit;
