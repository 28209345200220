import React from 'react';

import ProfileCarrier from 'profilePages/Carrier';
import NewActivityRoot from 'mainPages/NewActivity/NewActivityRoot';
import NewActivity from 'mainPages/NewActivity';
import NewOrganization from 'mainPages/NewOrganization';
import PaymentFailed from 'mainPages/PaymentFailed';
import PaymentComplete from 'mainPages/PaymentComplete';
import EventIcon from '@material-ui/icons/Event';

export default [
  {
    component: NewOrganization,
    path: '/me/new-org',
  },
  {
    component: ProfileCarrier,
    path: '/me/carrier',
  },
  {
    // Following api redirect path settings that payComplete is needed.
    path: '/me/pay-complete',
    component: PaymentComplete,
  },
  {
    // Following api redirect path settings that payFailed is needed.
    path: '/me/pay-failed',
    component: PaymentFailed,
  },
  {
    component: NewActivityRoot,
    path: '/me/new-activity',
    routes: [
      {
        path: '/me/new-activity',
        breadcrumbName: '新增活動',
        icon: <EventIcon />,
        component: NewActivity,
      },
    ],
  },
];
