import React from 'react';

import { useDispatch } from 'react-redux';
import { makeStyles } from '@eGroupTeam/material';

import { Redirect } from 'react-router-dom';
import Typography from '@eGroupTeam/material/Typography';
import Center from '@eGroupTeam/material-layout/Center';

import useLocationSearch from 'utils/useLocationSearch';
import PhoneFillSurveyForm from './PhoneFillSurveyForm';
import { fetchPostVerifySurveyQualify } from './redux';

const useStyles = makeStyles(() => ({
  root: {
    width: 450,
    padding: '48px 40px 36px',
    border: '1px solid #dadce0',
  },
}));

/**
 * Use `phone` to login and to fill survey.
 */
const PhoneFillSurvey = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { surveyId } = useLocationSearch();

  const handleFormSubmit = (values) => {
    dispatch(
      fetchPostVerifySurveyQualify({
        ...values.toJS(),
        surveyId,
      })
    );
  };

  if (!surveyId) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Center>
      <div className={classes.root}>
        <Typography variant="h6" align="center">
          請輸入您的手機以進行問卷填寫
        </Typography>
        <PhoneFillSurveyForm onSubmit={handleFormSubmit} />
      </div>
    </Center>
  );
};

export default PhoneFillSurvey;
