import React, { useEffect, useRef, useState } from 'react';

import pdfAddPages from '@eGroupTeam/utils/pdfAddPages';
import { useLocation, useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';
import {
  makeStyles,
  CircularProgress,
  Container,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@eGroupTeam/material';
import { jsPDF as JsPDF } from 'jspdf';
import { format } from '@eGroupTeam/utils/dateUtils';

import FixedFab from 'components/FixedFab';
import Main from '@eGroupTeam/material-layout/Main';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import BackAppbar from '@eGroupTeam/material-module/BackAppbar';

import { UrlParams } from 'types';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FunctionsIcon from '@material-ui/icons/Functions';
import SurveyCharts from '@eGroupTeam/material-module/SurveyCharts';
import {
  fetchGetSurveysRespondentCount,
  fetchGetSurveyResult,
  getSurveysResult,
  getSurveysRespondentCount,
} from './redux';

const useStyles = makeStyles((theme) => ({
  fab: {
    marginBottom: theme.spacing(8),
  },
  fab2: {
    marginBottom: theme.spacing(16),
  },
  pdfContainer: {
    width: 980,
    position: 'absolute',
    left: -16384,
  },
}));

const SurveyAnalysis = ({ survey, backPath = '/me/surveys' }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [isPdfCreating, setIsPdfCreating] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { surveyId } = useParams<UrlParams>();
  const selectedOrg = useSelector(getSelectedOrg);
  const surveysResult = useSelector(getSurveysResult);
  const surveysRespondentCount = useSelector(getSurveysRespondentCount);
  const itemRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    dispatch(
      fetchGetSurveysRespondentCount({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
      })
    );
    dispatch(
      fetchGetSurveyResult({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
      })
    );
  }, [dispatch, selectedOrg, surveyId]);

  const handlePrintPdf = async () => {
    setIsPdfCreating(true);
    const pdf = new JsPDF('p', 'mm', 'a4');
    await pdfAddPages('pdf-container', pdf, itemRefs.current, {
      xPadding: 8,
      yPadding: 8,
    });
    pdf.save(
      `${format(new Date(), 'yyyy.MM.dd.HH.mm')}_${survey.get(
        'surveyName'
      )}.pdf`
    );
    setIsPdfCreating(false);
  };

  if (!surveysResult || surveysRespondentCount === undefined) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <div id="pdf-container" className={classes.pdfContainer} />
      <BackAppbar
        push={history.push}
        go={history.go}
        pointerTrigger={location}
        position="fixed"
        elevation={0}
        backPath={backPath}
      >
        <Typography variant="h6">{survey.get('surveyName')}</Typography>
      </BackAppbar>
      {!matches && (
        <Tooltip title="下載PDF檔" onClick={handlePrintPdf}>
          <FixedFab
            className={classes.fab2}
            color="primary"
            disabled={isPdfCreating}
          >
            <PictureAsPdfIcon />
          </FixedFab>
        </Tooltip>
      )}
      <Tooltip title="下載一般格式" placement="left">
        <FixedFab
          href={`/api/organizations/${selectedOrg.get(
            'organizationId'
          )}/surveys/${survey.get('surveyId')}/export-responses`}
          color="secondary"
          aria-label="Download"
          className={classes.fab}
        >
          <CloudDownloadIcon />
        </FixedFab>
      </Tooltip>
      <Tooltip title="下載SPSS格式" placement="left">
        <FixedFab
          href={`/api/organizations/${selectedOrg.get(
            'organizationId'
          )}/surveys/${survey.get('surveyId')}/export-spss-responses`}
          color="secondary"
          aria-label="Download"
        >
          <FunctionsIcon />
        </FixedFab>
      </Tooltip>
      <Main>
        {isPdfCreating && (
          <FixedCenter>
            <Typography variant="h6" gutterBottom>PDF 產生中請稍候...</Typography>
          </FixedCenter>
        )}
        <Container style={{ maxWidth: 980 }}>
          {surveysRespondentCount === 0 ? (
            <Typography variant="h6" align="center">
              尚無人填答
            </Typography>
          ) : (
            <SurveyCharts
              data={surveysResult.toJS()}
              totalResponses={surveysRespondentCount}
              GridItemProps={{
                ref: (ref) => {
                  if (ref && itemRefs.current.length <= surveysResult.size) {
                    itemRefs.current.push(ref);
                  }
                },
              }}
            />
          )}
        </Container>
      </Main>
    </>
  );
};

export default SurveyAnalysis;
