import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles } from '@eGroupTeam/material';
import { matchRoutes } from 'react-router-config';

import ButtonMenu from '@eGroupTeam/material-module/ButtonMenu';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@eGroupTeam/material/Typography';
import Hidden from '@eGroupTeam/material/Hidden';
import Button from '@eGroupTeam/material/Button';
import MenuItem from '@eGroupTeam/material/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MainHeaderContext from './MainHeaderContext';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    height: theme.spacing(6),
    width: '100%',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    borderLeft: `solid 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    top: theme.spacing(7),
    left: theme.spacing(32),
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(32)}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      left: 0,
    },
    '@media (min-width:0px) and (orientation: landscape)': {
      top: theme.spacing(6),
    },
    '@media (min-width:600px)': {
      top: theme.spacing(8),
    },
  },
});

const MainHeader = ({ children, classes, className, ...other }) => {
  const location = useLocation();
  const { mainHeaderTitle, routes, setMainHeaderTitle } = React.useContext(
    MainHeaderContext
  );
  const firstMatchRoutes = React.useMemo(
    () => matchRoutes(routes, location.pathname)[0],
    [location.pathname, routes]
  );

  React.useEffect(() => {
    if (firstMatchRoutes && firstMatchRoutes.route.breadcrumbName) {
      setMainHeaderTitle(
        <Typography variant="h6">
          {firstMatchRoutes.route.breadcrumbName}
        </Typography>
      );
    } else {
      // Solve `Hidden` need children bug
      setMainHeaderTitle(<div />);
    }
  }, [firstMatchRoutes, setMainHeaderTitle]);

  return (
    <div className={clsx(classes.root, className)} {...other}>
      <Hidden mdUp>
        <ButtonMenu
          id="main-header-mobile-menu"
          button={
            <Button>
              {firstMatchRoutes && firstMatchRoutes.route.breadcrumbName}
              <ArrowDropDownIcon />
            </Button>
          }
        >
          {routes
            .filter((route) => Boolean(route.breadcrumbName))
            .map((route) => (
              <MenuItem
                key={route.path}
                component={Link}
                to={route.path}
                {...route.MuiMenuItemProps}
              >
                {route.breadcrumbName}
              </MenuItem>
            ))}
        </ButtonMenu>
      </Hidden>
      <Hidden smDown>{mainHeaderTitle}</Hidden>
      {children}
    </div>
  );
};

MainHeader.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
};

export default withStyles(styles)(MainHeader);
