import React from 'react';
import PropTypes from 'prop-types';

import TableHead from '@eGroupTeam/material/TableHead';
import TableRow from '@eGroupTeam/material/TableRow';
import TableCell from '@eGroupTeam/material/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

const HEAD_CELLS = ['姓名', 'Email', '單位名稱'];

const EnhancedTableHead = ({ numSelected, rowCount, onSelectAllClick }) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
        />
      </TableCell>
      {HEAD_CELLS.map((headCell) => (
        <TableCell key={headCell} padding="default">
          {headCell}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func,
};

export default EnhancedTableHead;
