import { of } from 'rxjs';
import getDeviceInfo from '@eGroupTeam/utils/getDeviceInfo';
import { HandlerArgs } from '@eGroupTeam/utils/makeBasicFetchEpic';
import { AxiosError } from 'axios';
import { FETCH_POST_ACTIVITY_RECOGNIZE_FACE } from 'mainPages/Activity/ActivityCheckIn/redux';
import { FETCH_POST_FACE_DETECTION } from 'landingPages/Train/redux';
import { LogPayload } from './root';

import apis from './apis';

export default function apiErrorsHandler(
  error: AxiosError,
  { state$, action }: HandlerArgs
) {
  let payload: LogPayload = {
    function: 'apiErrorsHandler',
    browserDescription: window.navigator.userAgent,
    jsonData: {
      action,
      store: state$.value.toJS(),
      deviceInfo: getDeviceInfo(),
    },
    level: 'ERROR',
  };

  if (error.response && error.response.status) {
    switch (error.response.status) {
      // bypass face recognize errors
      case 462: // TRAIN_FAIL_FACE
      case 465: // SERVER_ENGINE_LOAD_MODEL
      case 466: // FRAME_NO_FACE
      case 467: // FRAME_NO_SIMILARFACE
      case 468: // OUTPUT_JSON_NOT_EXIST
      case 469: // SERVER_ENGINE_TIMEOUT
        return of();
      // log server side errors
      default:
        if (error.response.status === 403 || error.response.status === 422) {
          payload.level = 'INFO';
        }
        apis.tools.createLog(payload);
        return of();
    }
  }

  // bypass recognize & detection timeout
  if (error.code === 'ECONNABORTED') {
    if (
      action.type === FETCH_POST_ACTIVITY_RECOGNIZE_FACE ||
      action.type === FETCH_POST_FACE_DETECTION
    ) {
      return of();
    }
  }

  // log client side errors
  payload = {
    ...payload,
    message: error.message,
  };
  apis.tools.createLog(payload);
  return of();
}
