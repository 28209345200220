import actionCreators from 'redux/actionCreators';
import { Map } from 'immutable';
import { createSelector } from 'reselect';

/** Actions */
export const {
  fetchPostActivityUserTemp,
  fetchPostActivityUserTempRequest,
  fetchPostActivityUserTempSuccess,
  fetchPostActivityUserTempFailure,
} = actionCreators.mainPages.users.usersActivityImport;

/** Types */
export const FETCH_POST_ACTIVITY_USER_TEMP = fetchPostActivityUserTemp().type;
export const FETCH_POST_ACTIVITY_USER_TEMP_REQUEST = fetchPostActivityUserTempRequest()
  .type;
export const FETCH_POST_ACTIVITY_USER_TEMP_SUCCESS = fetchPostActivityUserTempSuccess()
  .type;
export const FETCH_POST_ACTIVITY_USER_TEMP_FAILURE = fetchPostActivityUserTempFailure();

/** Selectors */
/**
 * Ui states
 */
const getUiStates = (state) =>
  state.getIn(['mainPages', 'Activity', 'Activity']);

export const getUserApplyTotalCounts = createSelector(getUiStates, (uiStates) =>
  uiStates.getIn(['userApplyCounts', 'total'])
);

export const getUserApplyTotalCheckInCounts = createSelector(
  getUiStates,
  (uiStates) => uiStates.getIn(['userApplyCounts', 'checkIn'])
);

export const getUserApplyTotalCheckOutCounts = createSelector(
  getUiStates,
  (uiStates) => uiStates.getIn(['userApplyCounts', 'checkOut'])
);

export const getActiveStep = createSelector(getUiStates, (uiStates) =>
  uiStates.get('activeStep')
);

export const getActiveStepMax = createSelector(getUiStates, (uiStates) =>
  uiStates.get('activeStepMax')
);

/**
 * Api states
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'users', 'usersActivityImport'], Map());

const getFetchPostActivityUserTemp = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostActivityUserTemp', Map())
);

export const getActivityUserTempIsPosting = createSelector(
  getFetchPostActivityUserTemp,
  (fetchResult) => fetchResult.get('isLoading')
);

export const getPostActivityUserTempResult = createSelector(
  getFetchPostActivityUserTemp,
  (fetchPost) => fetchPost.getIn(['response', 'response'])
);

export const getPostActivityUserTempActivityId = createSelector(
  getFetchPostActivityUserTemp,
  (fetchPost) => fetchPost.getIn(['response', 'activityId'])
);
