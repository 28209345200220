import React, { ReactNode, forwardRef } from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@eGroupTeam/material';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingRight: theme.spacing(8),
    },
  });

export type Ref = HTMLDivElement;

export interface SurveyProps extends WithStyles<typeof styles> {
  children?: ReactNode;
  className?: string;
}

const Survey = forwardRef<Ref, SurveyProps>((props, ref) => {
  const { classes, className, ...other } = props;
  return <div ref={ref} className={clsx(className, classes.root)} {...other} />;
});

export default withStyles(styles)(Survey);
