import React from 'react';

import apis from 'redux/apis';
import getDeviceInfo from '@eGroupTeam/utils/getDeviceInfo';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { withReduxSnackbar } from '@eGroupTeam/redux-modules/immutable/snackbars';
import history from 'redux/history';
import { store } from 'redux/configureAppStore';
import { withStyles } from '@eGroupTeam/material';
import DateFnsUtils from '@date-io/date-fns';
import { getDateFnsLocale } from '@eGroupTeam/utils/dateUtils';
import getNavigatorLanguage from '@eGroupTeam/utils/getNavigatorLanguage';

import AlertDialog from '@eGroupTeam/material-module/AlertDialog';
import Snackbar, { SnackbarProps } from '@eGroupTeam/material-lab/Snackbar';
import IntlControlProvider from '@eGroupTeam/material-intl/IntlControlProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import MuiThemeProvider from 'components/MuiThemeProvider';
import LoginDialog from 'components/LoginDialog';
import RouterRoot from 'components/RouterRoot';
import { SWRConfig } from 'swr';
import ErrorBoundry from './ErrorBoundry';
import CookieSnackbar from './CookieSnackbar';
import AlertOsNotSupportDialog from './AlertOsNotSupportDialog';
import { globalStyles } from './theme';

export const DIALOG = 'globalAlertDialog';
export const SNACKBAR = 'globalSnackbar';
const GlobalAlertDialog = withReduxDialog(DIALOG)(AlertDialog);
const GlobalSnackbar = withReduxSnackbar(SNACKBAR)<
  HTMLDivElement,
  SnackbarProps
>(Snackbar);
const WrapedRouterRoot = withStyles(globalStyles)(RouterRoot);

const locale = getNavigatorLanguage();
const dateFnsLocale = getDateFnsLocale(locale);

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <CookiesProvider>
        <IntlControlProvider
          defaultLocale="en"
          locale={locale}
          messages={{}}
          onUpdateLocale={(locale, setMessages) => {
            // load messages
            import(`static/locales/${locale}.json`).then((res) =>
              setMessages(res.default)
            );
          }}
        >
          <MuiThemeProvider>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={dateFnsLocale}
            >
              <ErrorBoundry>
                <SWRConfig
                  value={{
                    shouldRetryOnError: false,
                    onError: (error, key) => {
                      if (
                        error?.response?.status === 404 ||
                        error?.response?.status === 403
                      ) {
                        return;
                      }
                      apis.tools.createLog({
                        function: 'SWRConfig',
                        browserDescription: window.navigator.userAgent,
                        jsonData: {
                          action: key,
                          data: error.response,
                          deviceInfo: getDeviceInfo(),
                        },
                        level: 'ERROR',
                      });
                    },
                    onErrorRetry: (
                      error,
                      key,
                      config,
                      revalidate,
                      { retryCount = 0 }
                    ) => {
                      // Never retry on 404.
                      if (
                        error?.response?.status === 404 ||
                        error?.response?.status === 403
                      ) {
                        return;
                      }

                      // Retry after 5 seconds.
                      setTimeout(
                        () => revalidate({ retryCount: retryCount + 1 }),
                        5000
                      );
                    },
                  }}
                >
                  <LoginDialog />
                  <GlobalAlertDialog />
                  <GlobalSnackbar
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    autoHideDuration={2000}
                  />
                  <CookieSnackbar />
                  <AlertOsNotSupportDialog />
                  <WrapedRouterRoot />
                </SWRConfig>
              </ErrorBoundry>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </IntlControlProvider>
      </CookiesProvider>
    </ConnectedRouter>
  </Provider>
);

export default App;
