import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  withReduxDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import useLocationSearch from 'utils/useLocationSearch';
import { useHistory } from 'react-router';
import apis from 'redux/apis';

import AlertDialog, {
  AlertDialogProps,
} from '@eGroupTeam/material-module/AlertDialog';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import { CircularProgress } from '@eGroupTeam/material';
import { DIALOG as LOGIN_DIALOG } from 'components/LoginDialog';

export const DIALOG = 'alertFacebookLoginFailureDialog';
const AlertFailureDialog = withReduxDialog(DIALOG)<unknown, AlertDialogProps>(
  AlertDialog
);

const FacebookLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { code } = useLocationSearch();

  useEffect(() => {
    if (code) {
      apis.member
        .fetchPostFacebookLogin({
          code,
        })
        .then(() => {
          history.replace(
            window.localStorage.getItem('loginRedirectURL') || '/me'
          );
        });
    } else {
      dispatch(openDialog(DIALOG));
    }
  }, [code, dispatch, history]);

  return (
    <>
      <AlertFailureDialog
        title="Facebook 帳號驗證失敗"
        message="Facebook 帳號驗證失敗請再試一次"
        onConfirm={() => {
          history.replace(
            window.localStorage.getItem('loginRedirectURL') || '/me'
          );
          dispatch(openDialog(LOGIN_DIALOG));
        }}
      />
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    </>
  );
};

export default FacebookLogin;
