import { fetcher } from 'redux/apisOrg';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';
import { MicepointTransactionRecord } from 'types';

export type PathParams = {
  organizationId?: string;
  paymentOrderId?: string;
};
const useOrgOrder = makeGetHook<MicepointTransactionRecord, PathParams>(
  '/organizations/{{organizationId}}/micepoint-transaction-records/{{paymentOrderId}}',
  fetcher
);
export default useOrgOrder;
