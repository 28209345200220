import React, { FC, useCallback, useState } from 'react';

import apis from 'redux/apis';

import Checkbox from '@eGroupTeam/material/Checkbox';
import {
  Grid,
  Typography,
  Button,
  FormHelperText,
  TextField,
} from '@eGroupTeam/material';
import ActivityCoverUploadImage from 'components/ActivityCoverUploadImage';
import ImageUploadArea from 'components/ImageUploadArea';
import { Controller, useFieldArray, UseFormMethods } from 'react-hook-form';
import ActivityEventClass from 'components/ActivityEventClass';
import ActivityClass from 'components/ActivityClass';
import { ActivityFormInput } from 'types';
import { ActivityType } from 'enums'
import FroalaEditor from 'components/FroalaEditor';

export interface ActivityUpdateFormProps extends UseFormMethods<ActivityFormInput> {
  organizationId: string
  activityType: ActivityType
}

const ActivityUpdateForm: FC<ActivityUpdateFormProps> = ({
  control,
  register,
  watch,
  errors,
  formState,
  setValue,
  activityType,
  organizationId,
  clearErrors
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [uploadedCoverPath, setUploadedCoverPath] = useState();
  const [newIndex, setNewIndex] = useState<number>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'activityClassList',
  });
  const { isSubmitted } = formState;

  const handleModelChange = (model: string) => {
    setValue('activityContentEditor', model);
  };

  const handleCoverClick = (cover) => {
    setValue('activityCoverPath', cover.src);
  };

  const handleCoverDrop = useCallback(
    (acceptedFiles) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      setIsUploading(true);
      apis
        .fetchPostActivityCover({
          organizationId,
          formData,
        })
        .then((response) => {
          setUploadedCoverPath(response.data.activityCoverPath);
          setValue('activityCoverPath', response.data.activityCoverPath);
          setIsUploading(false);
        })
        .catch(() => {
          setIsUploading(false);
        });
    },
    [organizationId, setValue]
  );

  const handleLogoDrop = useCallback(
    (acceptedFiles) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      setIsLogoUploading(true);
      apis
        .fetchPostActivityLogo({
          organizationId,
          formData,
        })
        .then((response) => {
          setValue('activityLogoPath', response.data.activityLogoPath);
          setIsLogoUploading(false);
        })
        .catch(() => {
          setIsLogoUploading(false);
        });
    },
    [organizationId, setValue]
  );

  const renderActivityClassList = () => {
    if (activityType === ActivityType.event) {
      return (
        <ActivityEventClass
          name="activityClassList[0]"
          control={control}
          register={register}
          watch={watch}
          clearErrors={clearErrors}
          errors={errors}
          defaultTabValue={watch('activityClassList[0].activityClassMeetingUrl') ? 1 : 0}
        />
      );
    }
    return (
      <>
        <Grid item xs={12}>
          {fields.map((field, index) => (
            <ActivityClass
              key={field.id}
              name={`activityClassList[${index}]`}
              index={index}
              remove={remove}
              control={control}
              register={register}
              watch={watch}
              errors={errors}
              disabledDelete={fields.length === 1}
              style={{ marginBottom: 16 }}
              defaultValues={field}
              defaultOpen={newIndex === index}
              clearErrors={clearErrors}
            />
          ))}
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              append({
                activityClassName: '',
                activityClassLimitCount: 0,
                activityClassPlace: '',
                activityClassMeetingUrl: '',
                activityClassMail: {
                  city: '',
                  area: '',
                },
                activityClassAddress: '',
                activityClassDescription: '',
              });
              setNewIndex(fields.length);
            }}
          >
            新增場次
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="isAllowSelectMultipleActivityClass"
            render={({ ref, onChange, value, ...other }) => (
              <Checkbox
                label="報名者可複選場次(一人可以報名不同時段的多個場次)"
                onChange={(_, checked) => onChange(checked)}
                checked={value}
                {...other}
              />
            )}
          />
        </Grid>
      </>
    );
  };

  return (
    <form>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item container xs={12} lg={8} spacing={2}>
          <Grid item xs={12}>
            <Controller
              as={TextField}
              control={control}
              name="activityName"
              label="活動名稱"
              fullWidth
              variant="outlined"
              required
              error={!!errors.activityName}
              helperText={errors.activityName?.message}
              size="small"
              rules={{
                required: '「活動名稱」是必填欄位',
                maxLength: {
                  value: 100,
                  message: '長度不能超過100個字元'
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={TextField}
              control={control}
              name="activityDescription"
              label="活動摘要"
              fullWidth
              multiline
              rows="6"
              variant="outlined"
              required
              error={!!errors.activityDescription}
              helperText={
                errors.activityDescription?.message ||
                '請針對您的活動撰寫簡短的摘要'
              }
              size="small"
              rules={{
                required: '「活動摘要」是必填欄位',
                maxLength: {
                  value: 150,
                  message: '長度不能超過150個字元'
                }
              }}
            />
          </Grid>
          {renderActivityClassList()}
        </Grid>
        <Grid item container xs={12} lg={4} spacing={2}>
          <Grid item xs={12}>
            <input name="activityCoverPath" type="hidden" ref={register} />
            <ActivityCoverUploadImage
              isUploading={isUploading}
              uploadedCoverPath={uploadedCoverPath}
              onDrop={handleCoverDrop}
              onCoverClick={handleCoverClick}
              selectedCoverPath={watch('activityCoverPath')}
            />
          </Grid>
          <Grid item xs={12}>
            <input name="activityLogoPath" type="hidden" ref={register} />
            <ImageUploadArea
              uploading={isLogoUploading}
              uploadedImagePath={watch('activityLogoPath')}
              onDrop={handleLogoDrop}
              ratio="1920:800"
            >
              活動 Logo
              <br />
              1920:800
            </ImageUploadArea>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            活動內容
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <input
            name="activityContentEditor"
            type="hidden"
            ref={register({
              required: '「活動內容」是必填欄位'
            })}
          />
          <FroalaEditor
            model={watch('activityContentEditor')}
            onModelChange={handleModelChange}
            config={{
              toolbarSticky: true,
              toolbarStickyOffset: 112,
              heightMin: 300,
            }}
          />
          {isSubmitted && errors.activityContentEditor && (
            <FormHelperText error>
              {errors.activityContentEditor.message}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default ActivityUpdateForm;
