import { combineReducers } from 'redux-immutable';
import { combineEpics } from 'redux-observable';

import ActivityApply from 'landingPages/Activity/ActivityApply/reducers';
import Train from 'landingPages/Train/reducers';

import ActivityEpics from 'landingPages/Activity/epics';
import EmailFillSurveyEpics from 'landingPages/EmailFillSurvey/epics';
import OrganizationInvitationEpics from 'landingPages/OrganizationInvitation/epics';
import PhoneFillSurveyEpics from 'landingPages/PhoneFillSurvey/epics';
import SurveyEpics from 'landingPages/Survey/epics';
import TrainEpics from 'landingPages/Train/epics';

export const landingPages = combineReducers({
  ActivityApply,
  Train,
});

export const landingPagesEpics = combineEpics(
  ActivityEpics,
  EmailFillSurveyEpics,
  OrganizationInvitationEpics,
  PhoneFillSurveyEpics,
  SurveyEpics,
  TrainEpics
);
