import React from 'react';

import isLength from 'validator/lib/isLength';
import clsx from 'clsx';
import useInputActions from 'utils/useInputActions';

import {
  makeStyles,
  IconButton,
  IconButtonProps,
  TextFieldProps,
} from '@eGroupTeam/material';
import CloseIcon from '@material-ui/icons/Close';
import QuestionTextField from 'components/QuestionTextField';
import QuestionOptionDecorated from 'components/QuestionOptionDecorated';
import DragIcon from 'components/DragIcon';
import { QuestionOptionType } from 'types';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    alignItems: 'center',

    '& $dragIcon': {
      visibility: 'hidden',
    },

    '&:hover': {
      '& $dragIcon': {
        visibility: 'visible',
      },
    },
  },
  hideDragIcon: {
    '&:hover': {
      '& $dragIcon': {
        visibility: 'hidden',
      },
    },
  },
  hide: {
    visibility: 'hidden',
  },
  dragIcon: {
    paddingLeft: 4,
  },
}));
export interface QuestionOptionEditableProps {
  className?: string;
  type: QuestionOptionType;
  index: number;
  MuiIconButtonProps?: IconButtonProps;
  hideCloseIconButton?: boolean;
  hideDragIcon?: boolean;
  DragHandleProps?: DraggableProvidedDragHandleProps;
  MuiTextFieldProps?: TextFieldProps;
}

const MAX_OPTION = 50;

const QuestionOptionEditable = React.forwardRef<
  HTMLDivElement,
  QuestionOptionEditableProps
>((props, ref) => {
  const {
    className,
    type = 'radio',
    index,
    hideDragIcon,
    hideCloseIconButton,
    DragHandleProps,
    MuiTextFieldProps = {},
    MuiIconButtonProps = {},
    ...other
  } = props;
  const classes = useStyles();
  const {
    onChange,
    onClick,
    autoFocus,
    ...otherMuiTextFieldProps
  } = MuiTextFieldProps;
  const [value, setValue] = React.useState();
  const { inputEl, select } = useInputActions();
  const isError = !isLength(value || '', {
    min: 0,
    max: MAX_OPTION,
  });

  React.useEffect(() => {
    if (autoFocus) {
      select();
    }
  }, [autoFocus, select]);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (
      onChange &&
      isLength(e.target.value, {
        min: 0,
        max: MAX_OPTION,
      })
    ) {
      onChange(e);
    }
  };

  const handleClick = (e) => {
    select();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={clsx(className, classes.option, {
        [classes.hideDragIcon]: hideDragIcon,
      })}
      ref={ref}
      {...other}
    >
      <div {...DragHandleProps}>
        <DragIcon className={classes.dragIcon} color="action" />
      </div>
      <QuestionOptionDecorated type={type} index={index} />
      <QuestionTextField
        inputRef={inputEl}
        onChange={handleChange}
        onClick={handleClick}
        value={value}
        error={isError}
        helperText={isError ? `選項請勿超過 ${MAX_OPTION} 個字元` : undefined}
        autoFocus={autoFocus}
        {...otherMuiTextFieldProps}
      />
      <IconButton
        {...MuiIconButtonProps}
        className={clsx(MuiIconButtonProps.className, {
          [classes.hide]: hideCloseIconButton,
        })}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
});

export default QuestionOptionEditable;
