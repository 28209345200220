import { useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';
import useMemberInfo from './useMemberInfo';

export default function useOrgOwner() {
  const [memberInfo] = useMemberInfo();
  const selectedOrg = useSelector(getSelectedOrg);
  if (!memberInfo?.loginId || !selectedOrg) {
    return {};
  }
  const orgOwnerLoginId: string = selectedOrg.getIn(['member', 'loginId']);
  return {
    isOrgOwner: memberInfo.loginId === orgOwnerLoginId,
    orgOwnerLoginId,
    loginId: memberInfo.loginId,
  };
}
