import React from 'react';

import { renderRoutes } from 'react-router-config';

import NestedSideMenu from '@eGroupTeam/material-module/NestedSideMenu';
import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import { makeStyles, Hidden, Paper } from '@eGroupTeam/material';
import { useLocation } from 'react-router';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: theme.layout.sideMenuWidth,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  sideMenu: {
    position: 'fixed',
    top: theme.spacing(8),
    left: 0,
    bottom: 0,
  },
}));

const Root = ({ route }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <Navbar routes={route.routes} />
      <NavbarBrick />
      <div className={classes.main}>{renderRoutes(route.routes)}</div>
      <Hidden smDown>
        <Paper className={classes.sideMenu}>
          <NestedSideMenu
            disablePadding
            routes={route.routes}
            pathname={location.pathname}
          />
        </Paper>
      </Hidden>
    </>
  );
};

export default Root;
