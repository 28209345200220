import React, { useState } from 'react';

import { format } from '@eGroupTeam/utils/dateUtils';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  openDialog,
  withReduxDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import { Helmet } from 'react-helmet';
import Button from '@eGroupTeam/material/Button';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import {
  UrlParams,
  UserApplyActivityClass,
} from 'types';
import {
  UserApplyActivityClassIsCanceled,
} from 'enums'
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@eGroupTeam/material';
import useUserApplyByEmailToken from 'utils/useUserApplyByEmailToken';
import { fetchPatchUserApplyStatus } from './redux';

const CONFIRM_CHANGE_APPLY_STATUS_DIALOG = 'confirmChangeApplyStatusDialog';
const ConfirmChangeApplyStatusDialog = withReduxDialog(
  CONFIRM_CHANGE_APPLY_STATUS_DIALOG
)<unknown, ConfirmDialogProps>(ConfirmDialog);

const ActivityCancelApply = ({ activity }) => {
  const { activityId, emailTokenId } = useParams<UrlParams>();
  const dispatch = useDispatch();
  const [canceledReason, setCanceledReason] = useState('');
  const [
    selectedUserApplyActivityClass,
    setSelectedUserApplyActivityClass,
  ] = useState<UserApplyActivityClass>();
  const { data: userApply, mutate } = useUserApplyByEmailToken({
    activityId,
    emailTokenId,
  });

  const handleApplyStatusConfirm = () => {
    if (!userApply) return;
    dispatch(
      fetchPatchUserApplyStatus({
        activityId,
        userApplyId: userApply.userApplyId,
        activityClassId:
          selectedUserApplyActivityClass?.activityClass.activityClassId,
        isCanceled: selectedUserApplyActivityClass?.isCanceled === 1 ? 0 : 1,
        userApplyActivityClassCanceledReason: canceledReason,
        callback: mutate,
      })
    );
  };

  const renderStatusContext = () => {
    if (!selectedUserApplyActivityClass || !userApply) return undefined;
    return (
      <>
        <Typography variant="body1" gutterBottom>
          確定要
          {selectedUserApplyActivityClass.isCanceled === 1 ? '恢復' : '取消'}
          {userApply.user.userName}的報名嗎?
        </Typography>
        {selectedUserApplyActivityClass.isCanceled === 0 ? (
          <TextField
            label="取消報名原因"
            value={canceledReason}
            onChange={(e) => setCanceledReason(e.target.value)}
            fullWidth
          />
        ) : (
          <Typography>
            取消報名原因：
            {
              selectedUserApplyActivityClass.userApplyActivityClassCanceledReason
            }
          </Typography>
        )}
      </>
    );
  };

  if (!userApply) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <Helmet>
        <title>[更改報名狀態] {activity.get('activityName')}</title>
      </Helmet>
      <ConfirmChangeApplyStatusDialog
        primary={`${
          selectedUserApplyActivityClass &&
          selectedUserApplyActivityClass.isCanceled === 1
            ? '恢復'
            : '取消'
        }報名狀態`}
        onConfirm={handleApplyStatusConfirm}
        maxWidth="xs"
        fullWidth
      >
        {renderStatusContext()}
      </ConfirmChangeApplyStatusDialog>
      <Box pt={10}>
        <Container>
          <Typography variant="body1" gutterBottom>
            {userApply.user.userName}您好，以下為您參加「
            {userApply.activity.activityName}」的相關資訊。
          </Typography>
          <Paper>
            <Box p={2}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>場次名稱</TableCell>
                    <TableCell>開始時間</TableCell>
                    <TableCell>結束時間</TableCell>
                    <TableCell>報名狀態</TableCell>
                    <TableCell>取消原因</TableCell>
                    <TableCell>編輯</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userApply.userApplyActivityClassList.map((el) => (
                    <TableRow key={el.activityClass.activityClassId}>
                      <TableCell>
                        {el.activityClass.activityClassName}
                      </TableCell>
                      <TableCell>
                        {format(
                          el.activityClass.activityClassStartDateString,
                          'yyyy/MM/dd HH:mm'
                        )}
                      </TableCell>
                      <TableCell>
                        {format(
                          el.activityClass.activityClassEndDateString,
                          'yyyy/MM/dd HH:mm'
                        )}
                      </TableCell>
                      <TableCell>
                        {UserApplyActivityClassIsCanceled[el.isCanceled]}
                      </TableCell>
                      <TableCell>
                        {el.userApplyActivityClassCanceledReason}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setSelectedUserApplyActivityClass(el);
                            dispatch(
                              openDialog(CONFIRM_CHANGE_APPLY_STATUS_DIALOG)
                            );
                          }}
                        >
                          {el.isCanceled === 0 ? '取消報名' : '恢復報名'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default ActivityCancelApply;
