import * as yup from 'yup';
import isMobilePhone, { MobilePhoneLocale } from 'validator/lib/isMobilePhone';

declare module 'yup' {
  interface StringSchema {
    phone(message: string, locale: MobilePhoneLocale): StringSchema;
  }
}

yup.addMethod(
  yup.string,
  'phone',
  function TestPhone(message: string, locale: MobilePhoneLocale) {
    return this.test('is-phone', message, (value) => {
      if (value) {
        return isMobilePhone(value, locale);
      }
      return false;
    });
  }
);
