import React, { FC, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { makeStyles } from '@eGroupTeam/material';
import { getSelectedOrg } from 'redux/modules/common';

import Box from '@eGroupTeam/material/Box';
import Tooltip from '@eGroupTeam/material/Tooltip';
import Button from '@eGroupTeam/material/Button';
import Dialog from '@eGroupTeam/material/Dialog';
import Toolbar from '@eGroupTeam/material/Toolbar';
import IconButton from '@eGroupTeam/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import FaceRecognizer from 'components/FaceRecognizer';
import TransparentAppBar from 'components/TransparentAppBar';
import { FacingMode } from '@eGroupTeam/material-lab/MediaStreamClipper';
import clsx from 'clsx';
import {
  fetchPostActivityRecognizeFace,
  emptyRecognizedResult,
  emptyUnknownRecognizedResult,
  getRecognizedResult,
  getUnknownRecognizedResult,
} from './redux';
import RecognizedResult from './RecognizedResult';

export const DIALOG = 'checkInByFaceDialog';

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

export interface CheckInByFaceDialogProps {
  activityId: string;
}

const CheckInByFaceDialog: FC<
  CheckInByFaceDialogProps & WithReduxDialogProps
> = ({ isOpen, activityId, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [facingMode, setFacingMode] = useState<FacingMode>('user');
  const recognizedResult = useSelector(getRecognizedResult);
  const unknownRecognizedResult = useSelector(getUnknownRecognizedResult);
  const selectedOrg = useSelector(getSelectedOrg);

  const handleGetIntervalShot = useCallback(
    (blob) => {
      const formData = new FormData();
      formData.append(
        'file',
        new File([blob], 'screenshot.jpeg', {
          lastModified: new Date().getTime(),
        })
      );
      dispatch(
        fetchPostActivityRecognizeFace({
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          formData,
        })
      );
    },
    [dispatch, selectedOrg, activityId]
  );

  const handleBackScanner = () => {
    dispatch(emptyRecognizedResult());
    dispatch(emptyUnknownRecognizedResult());
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <TransparentAppBar>
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Box flexGrow={1} />
          <Tooltip title={`使用${facingMode === 'user' ? '後' : '前'}鏡頭`}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() =>
                setFacingMode((val) =>
                  val === 'user' ? 'environment' : 'user'
                )
              }
            >
              <FlipCameraIosIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </TransparentAppBar>
      {recognizedResult && (
        <RecognizedResult userApply={recognizedResult.get('userApply')}>
          <Button
            className={classes.button}
            variant="outlined"
            fullWidth
            onClick={handleBackScanner}
          >
            繼續人臉辨識
          </Button>
        </RecognizedResult>
      )}
      <FaceRecognizer
        className={clsx({
          [classes.hide]: !!recognizedResult,
        })}
        intervalTime={450}
        facingMode={facingMode}
        handleGetIntervalShot={handleGetIntervalShot}
        errorMsg={unknownRecognizedResult}
      />
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<unknown, CheckInByFaceDialogProps>(
  CheckInByFaceDialog
);
