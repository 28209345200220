import React, { useEffect, useState } from 'react';

import { List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import makeIsFormFieldsError from 'utils/makeIsFormFieldsError';
import { makeStyles, CircularProgress } from '@eGroupTeam/material';
import {
  reduxForm,
  Field,
  change,
  formValueSelector,
  getFormValues,
} from 'redux-form/immutable';
import { getSelectedOrg } from 'redux/modules/common';

import PickerField from '@eGroupTeam/material-form/immutable/PickerField';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import FormControl from '@eGroupTeam/material/FormControl';
import MenuItem from '@eGroupTeam/material/MenuItem';
import ListItem from '@eGroupTeam/material/ListItem';
import ListItemSecondaryAction from '@eGroupTeam/material/ListItemSecondaryAction';
import Tooltip from '@eGroupTeam/material/Tooltip';
import Typography from '@eGroupTeam/material/Typography';
import Table from '@eGroupTeam/material/Table';
import TableBody from '@eGroupTeam/material/TableBody';
import TableCell from '@eGroupTeam/material/TableCell';
import TableContainer from '@eGroupTeam/material/TableContainer';
import TablePagination from '@eGroupTeam/material/TablePagination';
import TableRow from '@eGroupTeam/material/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Stepper from '@eGroupTeam/material/Stepper';
import Step from '@eGroupTeam/material/Step';
import StepLabel from '@eGroupTeam/material/StepLabel';
import Grid from '@eGroupTeam/material/Grid';
import Button from '@eGroupTeam/material/Button';
import Box from '@eGroupTeam/material/Box';
import Select from '@eGroupTeam/material/Select';
import FormHelperText from '@eGroupTeam/material/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@eGroupTeam/material/Paper';
import PreviewIcon from '@material-ui/icons/Visibility';
import TogglePanel from '@eGroupTeam/material/TogglePanel';
import useOptions from 'utils/useOptions';
import useOrgActivity from 'utils/useOrgActivity';

import useActivityClasses from 'utils/useActivityClasses';
import FroalaEditor from 'components/FroalaEditor';
import {
  fetchGetOrganizationEdmTemplates,
  fetchGetActivityEdmUsers,
  getActivityEdmUsers,
  getOrganizationEdmTemplates,
  getOrganizationEdmTemplatesTotal,
  getIsLoading,
  handleNext,
  setActiveStep,
  getActiveStep,
} from './redux';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';

export const FORM = 'activityNotificationForm';

const useIsFormFieldsError = makeIsFormFieldsError(FORM);

const validate = (values) => {
  const errors: Record<string, string> = {};
  if (!values.get('organizationEdmTemplateType')) {
    errors.organizationEdmTemplateType = '「信件類型」是必填欄位';
  }
  if (!values.get('organizationEdmTemplateTitle')) {
    errors.organizationEdmTemplateTitle = '「信件標題」是必填欄位';
  }
  if (
    values.get('organizationEdmTemplateTitle') &&
    values.get('organizationEdmTemplateTitle').length > 120
  ) {
    errors.organizationEdmTemplateTitle = '「信件標題」超過長度限制';
  }
  if (!values.get('organizationEdmTemplateContent')) {
    errors.organizationEdmTemplateContent = '「信件內容」是必填欄位';
  }
  if (!values.get('organizationEdmTemplateContactContent')) {
    errors.organizationEdmTemplateContactContent = '「信件聯絡內容」是必填欄位';
  }
  if (!values.get('userList')) {
    errors.userList = '請選擇收信者';
  } else if (values.get('userList').size === 0) {
    errors.userList = '請選擇收信者';
  }
  return errors;
};

const styles = (theme) => ({
  paper: {
    width: 360,
    [theme.breakpoints.down(500)]: {
      width: 200,
    },
  },
  formControl: {
    margin: 'auto',
    minWidth: 150,
  },
});

const useStyles = makeStyles(styles);

const selector = formValueSelector(FORM);

const ActivityNotificationForm = ({ activityId, isSent, handleSubmit }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const activeStep = useSelector(getActiveStep);
  const activityEdmUsers = useSelector(getActivityEdmUsers);
  const organizationEdmTemplates = useSelector(getOrganizationEdmTemplates);
  const organizationEdmTemplatesTotal = useSelector(
    getOrganizationEdmTemplatesTotal
  );
  const values: any = useSelector(getFormValues(FORM));
  const isLoading = useSelector(getIsLoading);
  const [isFieldsError, submitFailed, formSyncErrors] = useIsFormFieldsError();
  const [selected, setSelected] = useState(List());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userName, setUserName] = useState('');
  const [
    searchOrganizationEdmTemplateType,
    setSearchOrganizationEdmTemplateType,
  ] = useState('');
  const organizationEdmTemplateType = useSelector((state) =>
    selector(state, 'organizationEdmTemplateType')
  );
  const organizationEdmTemplateTitle = useSelector((state) =>
    selector(state, 'organizationEdmTemplateTitle')
  );
  const activityClass = useSelector((state) =>
    selector(state, 'activityClass')
  );
  const selectedOrg = useSelector(getSelectedOrg);
  const { data } = useOptions(undefined, {
    isShowOrganizationEdmTemplateType: true,
  });
  const { data: activity } = useOrgActivity({
    organizationId: selectedOrg.get('organizationId'),
    activityId,
  });
  const { data: activityClasses } = useActivityClasses({
    activityId,
  });

  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    setPayload,
    page: tablePage,
    rowsPerPage: tableRowsPerPage,
  } = useDataTable('ActivityNotificationForm', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });

  useEffect(() => {
    dispatch(
      fetchGetOrganizationEdmTemplates({
        organizationId: selectedOrg.get('organizationId'),
        ...payload,
      })
    );
  },
  [dispatch, payload, selectedOrg])

  useEffect(
    () => () => {
      dispatch(setActiveStep(0));
    },
    [dispatch]
  );

  const handleInputChange = (e) => {
    setUserName(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      dispatch(
        fetchGetActivityEdmUsers({
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          activityClassId: values.get('activityClass'),
          activityEdmType: values.get('organizationEdmTemplateType'),
          query: userName,
        })
      );
    }
  };

  const handleUserSearch = () => {
    dispatch(
      fetchGetActivityEdmUsers({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        activityClassId: values.get('activityClass'),
        activityEdmType: values.get('organizationEdmTemplateType'),
        query: userName,
      })
    );
  };

  const handleFilterChange = (e) => {
    setSearchOrganizationEdmTemplateType(e.target.value);
    setPayload((value) => ({
      ...value,
      organizationEdmTemplateType:
        e.target.value !== 'all' ? e.target.value : undefined,
    }));
  };

  const handleEditEdm = (e, value) => {
    if (value.organizationEdmTemplateType === 'activityInvitation') {
      dispatch(change(FORM, 'activityClass', null));
    }
    dispatch(
      change(
        FORM,
        'organizationEdmTemplateName',
        value.organizationEdmTemplateName
      )
    );
    dispatch(
      change(
        FORM,
        'organizationEdmTemplateType',
        value.organizationEdmTemplateType
      )
    );
    dispatch(
      change(
        FORM,
        'organizationEdmTemplateTitle',
        value.organizationEdmTemplateTitle
      )
    );
    dispatch(
      change(
        FORM,
        'organizationEdmTemplateContent',
        value.organizationEdmTemplateContent
      )
    );
    dispatch(
      change(
        FORM,
        'organizationEdmTemplateContactContent',
        value.organizationEdmTemplateContactContent
      )
    );

    dispatch(handleNext());
  };

  const handleEdmTemplateContentModelChange = (model: string) => {
    dispatch(change(FORM, 'organizationEdmTemplateContent', model));
  };

  const handleEdmTemplateContactContentModelChange = (model: string) => {
    dispatch(change(FORM, 'organizationEdmTemplateContactContent', model));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && selected.size === 0) {
      const newSelecteds = activityEdmUsers.map((n) => n.get('userId'));
      setSelected(newSelecteds);
      dispatch(change(FORM, 'userList', newSelecteds));
    } else {
      setSelected(List());
      dispatch(change(FORM, 'userList', List()));
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = List();

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.size - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    dispatch(change(FORM, 'userList', newSelected));
  };

  const handleChangeUserPage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeUserRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderColumns = (rowData) => (
    <ListItem>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Typography variant="body2">{rowData[0]}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body2">{rowData[1]}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">{rowData[2]}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );

  const renderDataRow = (rowData) => (
    <ListItem
      key={rowData.organizationEdmTemplateId}
      button
      onClick={(e) => handleEditEdm(e, rowData)}
    >
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Typography noWrap variant="body2">
            {rowData.organizationEdmTemplateName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography noWrap variant="body2">
            {
              data?.organizationEdmTemplateType[
                rowData.organizationEdmTemplateType
              ]
            }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography
            variant="body2"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {rowData.organizationEdmTemplateTitle}
          </Typography>
        </Grid>
      </Grid>
      <ListItemSecondaryAction>
        <Tooltip title="預覽">
          <IconButton
            component="a"
            href={rowData.organizationEdmTemplatePath}
            target="_blank"
          >
            <PreviewIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );

  const renderEmptyUser = (activityType) => {
    if (
      values.get('organizationEdmTemplateType') !== 'activityInvitation' &&
      !values.get('activityClass') &&
      activityType === 2
    ) {
      return (
        <TableRow>
          <TableCell colSpan={12}>（請先選擇場次）</TableCell>
        </TableRow>
      );
    }
    if (activityEdmUsers.size === 0) {
      return (
        <TableRow>
          <TableCell colSpan={12}>目前無符合寄信條件之會員</TableCell>
        </TableRow>
      );
    }
    return undefined;
  };

  if (!activity) {
    return <CircularProgress />;
  }

  return (
    <>
      <Stepper
        activeStep={activeStep}
        style={{ padding: 0, paddingBottom: 24 }}
      >
        <Step>
          <StepLabel>選擇信件範本或自行創建</StepLabel>
        </Step>
        <Step>
          <StepLabel
            // @ts-ignore
            error={isFieldsError([
              'organizationEdmTemplateType',
              'organizationEdmTemplateTitle',
              'activityClass',
              'organizationEdmTemplateContent',
              'organizationEdmTemplateContactContent',
            ])}
          >
            編輯信件內容
          </StepLabel>
        </Step>
        <Step>
          {/* @ts-ignore */}
          <StepLabel error={isFieldsError(['userList'])}>選擇會員</StepLabel>
        </Step>
      </Stepper>
      <TogglePanel index={0} value={activeStep}>
        {organizationEdmTemplates && (
          <Paper>
            <DataTable
              title="選擇信件範本"
              SearchBarProps={{
                placeholder: '搜尋信件範本',
                onChange: handleSearchChange,
                defaultValue: payload.query,
                renderOptions: ({ handleDropDownClose }) => (
                  <Paper className={classes.paper}>
                    <Box p={3}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={5}>
                          <Typography color="textSecondary">
                            篩選信件類型
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <Select
                              value={searchOrganizationEdmTemplateType}
                              onChange={handleFilterChange}
                            >
                              <MenuItem value="all">全部</MenuItem>
                              {data?.organizationEdmTemplateType &&
                                Object.keys(
                                  data.organizationEdmTemplateType
                                ).map((key) => (
                                  <MenuItem key={key} value={key}>
                                    {data.organizationEdmTemplateType[key]}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box p={3} pt={0} textAlign="right">
                      <Button
                        onClick={() => {
                          handleDropDownClose();
                        }}
                        color="primary"
                      >
                        關閉
                      </Button>
                    </Box>
                  </Paper>
                ),
              }}
              data={organizationEdmTemplates.toJS()}
              isEmpty={organizationEdmTemplates.size === 0}
              columns={['名稱', '類型', '標題']}
              serverSide
              loading={isLoading}
              renderColumns={renderColumns}
              renderDataRow={renderDataRow}
              MuiTablePaginationProps={{
                count: organizationEdmTemplatesTotal,
                labelRowsPerPage: '每頁幾筆',
                page: tablePage,
                rowsPerPage: tableRowsPerPage,
                onPageChange: handleChangePage,
                onRowsPerPageChange: handleRowsPerPageChange,
              }}
            />
          </Paper>
        )}
      </TogglePanel>
      <form onSubmit={handleSubmit}>
        <TogglePanel index={1} value={activeStep}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {isSent ? (
                <Typography variant="body1">
                  信件類型：
                  {
                    data?.organizationEdmTemplateType[
                      organizationEdmTemplateType
                    ]
                  }
                </Typography>
              ) : (
                <Field
                  component={TextLoadingField}
                  label="信件類型"
                  name="organizationEdmTemplateType"
                  fullWidth
                  select
                  variant="outlined"
                >
                  {data?.organizationEdmTemplateType &&
                    Object.keys(data.organizationEdmTemplateType).map((key) => (
                      <MenuItem key={key} value={key}>
                        {data.organizationEdmTemplateType[key]}
                      </MenuItem>
                    ))}
                </Field>
              )}
            </Grid>
            {values.get('organizationEdmTemplateType') !==
              'activityInvitation' &&
              activity.activityType === 2 && (
                <Grid item xs={12} sm={12}>
                  {isSent ? (
                    activityClass && (
                      <Typography variant="body1">
                        活動場次：
                        {
                          activityClasses?.source.find(
                            (el) => el.activityClassId === activityClass
                          )?.activityClassName
                        }
                      </Typography>
                    )
                  ) : (
                    <Field
                      component={TextLoadingField}
                      label="活動場次"
                      name="activityClass"
                      fullWidth
                      select
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>全部</em>
                      </MenuItem>
                      {activityClasses?.source.map((el) => (
                        <MenuItem
                          key={el.activityClassId}
                          value={el.activityClassId}
                        >
                          {el.activityClassName}
                        </MenuItem>
                      ))}
                    </Field>
                  )}
                </Grid>
              )}
            <Grid item xs={12}>
              {isSent ? (
                <Typography variant="body1">
                  信件標題：{organizationEdmTemplateTitle}
                </Typography>
              ) : (
                <Field
                  name="organizationEdmTemplateTitle"
                  label="信件標題"
                  component={TextLoadingField}
                  fullWidth
                  variant="outlined"
                  required
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                信件內容 *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                component="input"
                name="organizationEdmTemplateContent"
                type="hidden"
              />
              <FroalaEditor
                model={values.get('organizationEdmTemplateContent', '')}
                onModelChange={handleEdmTemplateContentModelChange}
              />
              {submitFailed && (
                <FormHelperText error>
                  {/* @ts-ignore */}
                  {formSyncErrors.organizationEdmTemplateContent}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                信件聯絡內容 *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                component="input"
                name="organizationEdmTemplateContactContent"
                type="hidden"
              />
              <FroalaEditor
                model={values.get('organizationEdmTemplateContactContent', '')}
                onModelChange={handleEdmTemplateContactContentModelChange}
              />
              {submitFailed && (
                <FormHelperText error>
                  {/* @ts-ignore */}
                  {formSyncErrors.organizationEdmTemplateContactContent}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </TogglePanel>
        <TogglePanel index={2} value={activeStep}>
          <Box pb={2}>
            <Field
              name="notificationSendEmailDateString"
              component={PickerField}
              label="預定寄送時間"
              inputVariant="outlined"
              picker="dateTime"
              disablePast
              pickerFormat="yyyy-MM-dd HH:mm"
            />
          </Box>
          <Field component="input" name="userList" type="hidden" />
          {submitFailed && (
            // @ts-ignore
            <FormHelperText error>{formSyncErrors.userList}</FormHelperText>
          )}
          <Paper>
            <EnhancedTableToolbar
              numSelected={selected.size}
              userName={userName}
              handleInputChange={handleInputChange}
              handleInputKeyPress={handleInputKeyPress}
              handleUserSearch={handleUserSearch}
            />
            <TableContainer>
              <Table>
                <EnhancedTableHead
                  numSelected={selected.size}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={activityEdmUsers.size}
                />
                <TableBody>
                  {activityEdmUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected =
                        selected.indexOf(row.get('userId')) !== -1;
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.get('userId'))
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.get('userId')}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="default"
                          >
                            {row.get('userName')}
                          </TableCell>
                          <TableCell>{row.get('userEmail')}</TableCell>
                          <TableCell>
                            {row.get('userOrganizationName')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {renderEmptyUser(activity.activityType)}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={activityEdmUsers.size}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="每頁幾筆"
              onPageChange={handleChangeUserPage}
              onRowsPerPageChange={handleChangeUserRowsPerPage}
            />
          </Paper>
        </TogglePanel>
      </form>
    </>
  );
};

export default reduxForm<any, any>({
  form: FORM,
  validate,
})(ActivityNotificationForm);
