import { fetcher } from 'redux/apisOrg';
import { OrganizationModule, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
};
const useOrgModules = makeGetHook<EntityList<OrganizationModule>, PathParams>(
  '/organizations/{{organizationId}}/modules',
  fetcher
);
export default useOrgModules;
