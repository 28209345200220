import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import { getEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import * as schema from 'redux/schema';

/** Actions */
export const {
  fetchGetActivityApplyFields,
  fetchGetActivityApplyFieldsRequest,
  fetchGetActivityApplyFieldsSuccess,
  fetchGetActivityApplyFieldsFailure,

  fetchPostActivityApplyField,
  fetchPostActivityApplyFieldRequest,
  fetchPostActivityApplyFieldSuccess,
  fetchPostActivityApplyFieldFailure,

  fetchPatchActivityApplyFieldsOrder,
  fetchPatchActivityApplyFieldsOrderRequest,
  fetchPatchActivityApplyFieldsOrderSuccess,
  fetchPatchActivityApplyFieldsOrderFailure,

  fetchPatchActivityApplyField,
  fetchPatchActivityApplyFieldRequest,
  fetchPatchActivityApplyFieldSuccess,
  fetchPatchActivityApplyFieldFailure,

  fetchDeleteActivityApplyField,
  fetchDeleteActivityApplyFieldRequest,
  fetchDeleteActivityApplyFieldSuccess,
  fetchDeleteActivityApplyFieldFailure,

  fetchPostActivityApplyFieldOption,
  fetchPostActivityApplyFieldOptionRequest,
  fetchPostActivityApplyFieldOptionSuccess,
  fetchPostActivityApplyFieldOptionFailure,

  fetchPatchActivityApplyFieldOptionsOrder,
  fetchPatchActivityApplyFieldOptionsOrderRequest,
  fetchPatchActivityApplyFieldOptionsOrderSuccess,
  fetchPatchActivityApplyFieldOptionsOrderFailure,

  fetchPatchActivityApplyFieldOption,
  fetchPatchActivityApplyFieldOptionRequest,
  fetchPatchActivityApplyFieldOptionSuccess,
  fetchPatchActivityApplyFieldOptionFailure,

  fetchDeleteActivityApplyFieldOption,
  fetchDeleteActivityApplyFieldOptionRequest,
  fetchDeleteActivityApplyFieldOptionSuccess,
  fetchDeleteActivityApplyFieldOptionFailure,

  fetchGetActivityApplyField,
  fetchGetActivityApplyFieldRequest,
  fetchGetActivityApplyFieldSuccess,
  fetchGetActivityApplyFieldFailure,
} = actionCreators.mainPages.activity.activityFields;

/** Types */
export const FETCH_GET_ACTIVITY_APPLY_FIELDS = fetchGetActivityApplyFields()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELDS_REQUEST = fetchGetActivityApplyFieldsRequest()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELDS_SUCCESS = fetchGetActivityApplyFieldsSuccess()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELDS_FAILURE = fetchGetActivityApplyFieldsFailure()
  .type;

export const FETCH_POST_ACTIVITY_APPLY_FIELD = fetchPostActivityApplyField()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_FIELD_REQUEST = fetchPostActivityApplyFieldRequest()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_FIELD_SUCCESS = fetchPostActivityApplyFieldSuccess()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_FIELD_FAILURE = fetchPostActivityApplyFieldFailure()
  .type;

export const FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER = fetchPatchActivityApplyFieldsOrder()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER_REQUEST = fetchPatchActivityApplyFieldsOrderRequest()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER_SUCCESS = fetchPatchActivityApplyFieldsOrderSuccess()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER_FAILURE = fetchPatchActivityApplyFieldsOrderFailure()
  .type;

export const FETCH_PATCH_ACTIVITY_APPLY_FIELD = fetchPatchActivityApplyField()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_REQUEST = fetchPatchActivityApplyFieldRequest()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_SUCCESS = fetchPatchActivityApplyFieldSuccess()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_FAILURE = fetchPatchActivityApplyFieldFailure()
  .type;

export const FETCH_DELETE_ACTIVITY_APPLY_FIELD = fetchDeleteActivityApplyField()
  .type;
export const FETCH_DELETE_ACTIVITY_APPLY_FIELD_REQUEST = fetchDeleteActivityApplyFieldRequest()
  .type;
export const FETCH_DELETE_ACTIVITY_APPLY_FIELD_SUCCESS = fetchDeleteActivityApplyFieldSuccess()
  .type;
export const FETCH_DELETE_ACTIVITY_APPLY_FIELD_FAILURE = fetchDeleteActivityApplyFieldFailure()
  .type;

export const FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION = fetchPostActivityApplyFieldOption()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION_REQUEST = fetchPostActivityApplyFieldOptionRequest()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION_SUCCESS = fetchPostActivityApplyFieldOptionSuccess()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION_FAILURE = fetchPostActivityApplyFieldOptionFailure()
  .type;

export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER = fetchPatchActivityApplyFieldOptionsOrder()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER_REQUEST = fetchPatchActivityApplyFieldOptionsOrderRequest()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER_SUCCESS = fetchPatchActivityApplyFieldOptionsOrderSuccess()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER_FAILURE = fetchPatchActivityApplyFieldOptionsOrderFailure()
  .type;

export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION = fetchPatchActivityApplyFieldOption()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION_REQUEST = fetchPatchActivityApplyFieldOptionRequest()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION_SUCCESS = fetchPatchActivityApplyFieldOptionSuccess()
  .type;
export const FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION_FAILURE = fetchPatchActivityApplyFieldOptionFailure()
  .type;

export const FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION = fetchDeleteActivityApplyFieldOption()
  .type;
export const FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION_REQUEST = fetchDeleteActivityApplyFieldOptionRequest()
  .type;
export const FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION_SUCCESS = fetchDeleteActivityApplyFieldOptionSuccess()
  .type;
export const FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION_FAILURE = fetchDeleteActivityApplyFieldOptionFailure()
  .type;

export const FETCH_GET_ACTIVITY_APPLY_FIELD = fetchGetActivityApplyField().type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_REQUEST = fetchGetActivityApplyFieldRequest()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_SUCCESS = fetchGetActivityApplyFieldSuccess()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_FAILURE = fetchGetActivityApplyFieldFailure()
  .type;

/** Selectors */
/**
 * Ui States
 */
const getUiStates = (state) => state.getIn(['mainPages', 'ActivityFields']);

export const getActivityApplyFields = createSelector(
  getEntities,
  getUiStates,
  (entities, uiStates) =>
    denormalize(
      uiStates.get('activityApplyFields'),
      schema.applyFields,
      entities
    )
);

/**
 * Api states
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'activity', 'activityFields'], Map());

const getFetchPostActivityApplyFields = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostActivityApplyField', Map())
);

export const getIsPostingApplyFields = createSelector(
  getFetchPostActivityApplyFields,
  (fetchResult) => fetchResult.get('isLoading')
);
