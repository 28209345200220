import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import TogglePanel from "@eGroupTeam/material/TogglePanel";
import Grid from "@eGroupTeam/material/Grid";
import Fade from "@material-ui/core/Fade";

import { ServiceReference } from "types";
import TogglePanelContentItem from "./TogglePanelContentItem";

interface Props {
  index: number;
  value: number;
  data?: ServiceReference[];
}

const useStyles = makeStyles({
  togglePanel: {
    transition: "opacity .15s linear",
  },
});

const CustomTogglePanel: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const { index, value, data } = props;

  const classnames = clsx({
    [classes.togglePanel]: true,
    "tab-pane": true,
    show: value === index,
  });

  return (
    <Fade in={value === index} timeout={1500}>
      <TogglePanel index={index} value={value} className={classnames}>
        <Grid container spacing={4}>
          {data?.map((el) => (
            <TogglePanelContentItem
              key={el.serviceReferenceId}
              title={el.serviceReferenceTitleZh}
              description={el.serviceReferenceDescriptionZh}
              imagePath={el.serviceReferenceImagePath}
              youtubeUrl={el.serviceReferenceYoutubeURL}
            />
          ))}
        </Grid>
      </TogglePanel>
    </Fade>
  );
};

export default CustomTogglePanel;
