import React, { FC } from 'react';

import { PaymentProduct } from 'types';
import { PaymentOrderType } from 'enums';

import { Box, Grid, makeStyles, Typography } from '@eGroupTeam/material';
import Currency from 'components/Currency';

export interface PaymentProductListProps {
  paymentOrderType: PaymentOrderType;
  data: PaymentProduct[];
}

const useStyles = makeStyles((theme) => ({
  pointItem: {
    padding: theme.spacing(3, 3, 2),
    borderTop: "1px solid #DBDDE3",
  },
  titleCell: {
    display: "flex",
    alignItems: "center",
  },
  valueCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "end",
    },
  },
  quantityCell: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "end",
    },
  },
  dropdown: {
    boxShadow: "none",
    "& .MuiButtonGroup-root": {
      height: "unset",
    },
    "& .MuiButtonGroup-grouped": {
      border: "0",
      borderBottom: "1px solid #DBDDE3",
      borderRadius: "0",
    },
  },
}));

const PaymentProductList: FC<PaymentProductListProps> = ({
  data,
  paymentOrderType,
}) => {
  const classes = useStyles();

  return (
    <>
      {
        data.map((row) => {
          if (row.serviceProductUnitPriceZh !== undefined) {
            return (
              <Box
                key={row.paymentProductId}
                className={classes.pointItem}
              >
                <Grid container>
                  <Grid item xs={12} sm={6} md={4} className={classes.titleCell}>
                    <Typography variant="body1">{row.serviceProductNameZh}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className={classes.valueCell}>
                    <Typography variant="body1">
                      <Currency
                        value={row.serviceProductUnitPriceZh}
                        isMicepoint={paymentOrderType === 3}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.quantityCell}
                    >
                      <Box mr={2}>
                        <Typography variant="body1">數量:</Typography>
                      </Box>
                      {row.paymentProductQuantity}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            );
          }
          return undefined;
        })
      }
    </>
  )
};

export default PaymentProductList;
