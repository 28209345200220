import { fetcher } from 'redux/apisOrg';
import { PaymentProduct } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';
import useServiceProducts from './useServiceProducts';

export type PathParams = {
  organizationId?: string;
  activityId?: string;
};

const useActivityServiceProductsRow = makeGetHook<PaymentProduct[], PathParams>(
  '/organizations/{{organizationId}}/activities/{{activityId}}/service-products',
  fetcher
);

/**
 * We need mapping activity service product with default service product
 * because api is not fullfill this.
 */
export default function useActivityServiceProducts(params?: PathParams) {
  const {
    data: data1,
    isValidating: isValidating1,
    mutate: mutate1,
    response: response1,
  } = useServiceProducts({
    serviceName: 'micepass',
  });
  const {
    data: data2,
    isValidating: isValidating2,
    mutate: mutate2,
    response: response2,
  } = useActivityServiceProductsRow(params);

  const isEmpty1 = response1?.status === 204;
  const isEmpty2 = response2?.status === 204;
  const isEmpty = isEmpty1 || isEmpty2;
  const isValidating = isValidating1 || isValidating2;
  const paidServiceProductIds = isEmpty
    ? undefined
    : data2?.map((el) => el.serviceProductId);
  const data = data1?.source.map((el) => ({
    ...el,
    paymentProductStatus: paidServiceProductIds?.includes(el.serviceProductId)
      ? 2
      : 1,
  }));
  return {
    isValidating,
    data,
    isEmpty,
    mutate: () => {
      mutate1();
      mutate2();
    },
  };
}
