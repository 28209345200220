import { createSelector } from '@reduxjs/toolkit';

/** Selectors */
const getUiStates = (state) => state.getIn(['mainPages', 'NewActivity']);

export const getActiveStep = createSelector(
  getUiStates,
  (uiStates) => uiStates.activeStep
);

export const getFormValues = createSelector(
  getUiStates,
  (uiStates) => uiStates.formValues
);
