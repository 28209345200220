export enum PaymentOrderStatus {
  '未付款' = 1,
  '已付訂' = 2,
  '全額付款' = 3,
  '付款失敗' = 4,
}

export enum IsTrainSuccess {
  '未訓練',
  '訓練成功',
  '訓練失敗',
}

export enum ActivityType {
  'event' = 1,
  'course' = 2,
}

export enum MicepointTransactionRecordType {
  '購買' = 1,
  '兌換' = 2,
}

export enum PaymentOrderType {
  '信用卡' = 1,
  'ATM轉帳' = 2,
  'MICEPoint' = 3,
  'Paypal' = 4,
}

export enum QrpassRecordRetrunStatus {
  '未發送',
  '發送成功',
}

export enum SurveyType {
  normal = '一般問卷',
  preTest = '會前問卷',
  postTest = '會後問卷',
}

export enum UserApplyActivityClassIsCanceled {
  '正常',
  '取消報名',
}

export enum OrganizationInvitationStatus {
  '已邀請' = 1,
  '拒絕' = 2,
  '接受' = 3,
  '取消邀請' = 4,
  '邀請已過期' = 5
}