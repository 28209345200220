import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { getEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schema from 'redux/schema';

/** Actions */
export const {
  fetchPostPreviewEdm,
  fetchPostPreviewEdmRequest,
  fetchPostPreviewEdmSuccess,
  fetchPostPreviewEdmFailure,

  fetchPostOrganizationEdmTemplate,
  fetchPostOrganizationEdmTemplateRequest,
  fetchPostOrganizationEdmTemplateSuccess,
  fetchPostOrganizationEdmTemplateFailure,

  fetchGetOrganizationEdmTemplates,
  fetchGetOrganizationEdmTemplatesRequest,
  fetchGetOrganizationEdmTemplatesSuccess,
  fetchGetOrganizationEdmTemplatesFailure,

  fetchDeleteOrganizationEdmTemplate,
  fetchDeleteOrganizationEdmTemplateRequest,
  fetchDeleteOrganizationEdmTemplateSuccess,
  fetchDeleteOrganizationEdmTemplateFailure,

  fetchPatchOrganizationEdmTemplate,
  fetchPatchOrganizationEdmTemplateRequest,
  fetchPatchOrganizationEdmTemplateSuccess,
  fetchPatchOrganizationEdmTemplateFailure,

  fetchPostOrganizationEdmCopyTemplate,
  fetchPostOrganizationEdmCopyTemplateRequest,
  fetchPostOrganizationEdmCopyTemplateSuccess,
  fetchPostOrganizationEdmCopyTemplateFailure,
} = actionCreators.mainPages.notification.notificationTemplates;

/** Types */
export const FETCH_POST_PREVIEW_EDM = fetchPostPreviewEdm().type;
export const FETCH_POST_PREVIEW_EDM_REQUEST = fetchPostPreviewEdmRequest().type;
export const FETCH_POST_PREVIEW_EDM_SUCCESS = fetchPostPreviewEdmSuccess().type;
export const FETCH_POST_PREVIEW_EDM_FAILURE = fetchPostPreviewEdmFailure().type;

export const FETCH_POST_ORGANIZATION_EDM_TEMPLATE = fetchPostOrganizationEdmTemplate()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_TEMPLATE_REQUEST = fetchPostOrganizationEdmTemplateRequest()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_TEMPLATE_SUCCESS = fetchPostOrganizationEdmTemplateSuccess()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_TEMPLATE_FAILURE = fetchPostOrganizationEdmTemplateFailure()
  .type;

export const FETCH_GET_ORGANIZATION_EDM_TEMPLATES = fetchGetOrganizationEdmTemplates()
  .type;
export const FETCH_GET_ORGANIZATION_EDM_TEMPLATES_REQUEST = fetchGetOrganizationEdmTemplatesRequest()
  .type;
export const FETCH_GET_ORGANIZATION_EDM_TEMPLATES_SUCCESS = fetchGetOrganizationEdmTemplatesSuccess()
  .type;
export const FETCH_GET_ORGANIZATION_EDM_TEMPLATES_FAILURE = fetchGetOrganizationEdmTemplatesFailure()
  .type;

export const FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE = fetchDeleteOrganizationEdmTemplate()
  .type;
export const FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE_REQUEST = fetchDeleteOrganizationEdmTemplateRequest()
  .type;
export const FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE_SUCCESS = fetchDeleteOrganizationEdmTemplateSuccess()
  .type;
export const FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE_FAILURE = fetchDeleteOrganizationEdmTemplateFailure()
  .type;

export const FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE = fetchPatchOrganizationEdmTemplate()
  .type;
export const FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE_REQUEST = fetchPatchOrganizationEdmTemplateRequest()
  .type;
export const FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE_SUCCESS = fetchPatchOrganizationEdmTemplateSuccess()
  .type;
export const FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE_FAILURE = fetchPatchOrganizationEdmTemplateFailure()
  .type;

export const FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE = fetchPostOrganizationEdmCopyTemplate()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE_REQUEST = fetchPostOrganizationEdmCopyTemplateRequest()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE_SUCCESS = fetchPostOrganizationEdmCopyTemplateSuccess()
  .type;
export const FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE_FAILURE = fetchPostOrganizationEdmCopyTemplateFailure()
  .type;

/** Selectors */

/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(
    ['apis', 'mainPages', 'notification', 'notificationTemplates'],
    Map()
  );

const getFetchPostOrganizationEdmTemplate = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostOrganizationEdmTemplate', Map())
);

const getFetchPatchOrganizationEdmTemplate = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPatchOrganizationEdmTemplate', Map())
);

const getFetchGetOrganizationEdmTemplates = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetOrganizationEdmTemplates', Map())
);

const getFetchPostPreviewEdm = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostPreviewEdm', Map())
);

const getFetchPostOrganizationEdmCopyTemplate = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostOrganizationEdmCopyTemplate', Map())
);

export const getIsPosting = createSelector(
  getFetchPostOrganizationEdmTemplate,
  (fetchPost) => fetchPost.get('isLoading')
);

export const getIsPatching = createSelector(
  getFetchPatchOrganizationEdmTemplate,
  (fetchPatch) => fetchPatch.get('isLoading')
);

export const getIsPreviewPosting = createSelector(
  getFetchPostPreviewEdm,
  (fetchPost) => fetchPost.get('isLoading')
);

export const getOrganizationEdmTemplates = createSelector(
  getEntities,
  getFetchGetOrganizationEdmTemplates,
  (entities, fetchGet) =>
    denormalize(
      fetchGet.getIn(['response', 'result']),
      schema.organizationEdmTemplates,
      entities
    )
);

export const getOrganizationEdmTemplatesTotal = createSelector(
  getFetchGetOrganizationEdmTemplates,
  (fetchGet) => fetchGet.getIn(['response', 'total'])
);

export const getPreviewEdm = createSelector(
  getFetchPostPreviewEdm,
  (fetchGet) => fetchGet.get('response')
);

export const getCopyTemplate = createSelector(
  getFetchPostOrganizationEdmCopyTemplate,
  (fetchGet) => fetchGet.get('response')
);

export const getOrganizationEdmCopyTemplateIsPosting = createSelector(
  getFetchPostOrganizationEdmCopyTemplate,
  (fetchGet) => fetchGet.get('isLoading')
);

export const getIsLoading = createSelector(
  getFetchGetOrganizationEdmTemplates,
  (fetchGet) => fetchGet.get('isLoading')
);
