import React, { useCallback, useState } from 'react';

import objectToBase64 from '@eGroupTeam/utils/objectToBase64';
import { fromJS } from 'immutable';
import { setSelectedOrg, getSelectedOrg } from 'redux/modules/common';
import { fetchGetOrganizations } from 'components/RouterRoot/actions';
import { useDispatch, useSelector } from 'react-redux';
import apis from 'redux/apis';

import Main from '@eGroupTeam/material-layout/Main';
import { Box, Container, Paper } from '@eGroupTeam/material';
import PageHeader from 'components/PageHeader';
import PageSection from 'components/PageSection';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import OrgInfoForm from './OrgInfoForm';

const OrgInfo = () => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const [isUploading, setIsUploading] = useState(false);
  const { excute: updateOrg } = useAxiosApiWrapper(
    apis.member.fetchPatchOrganization
  );

  const updateOrgProcess = useCallback(
    (payload) => {
      updateOrg(payload).then((response) => {
        dispatch(setSelectedOrg(fromJS(response.data)));
        dispatch(fetchGetOrganizations());
      });
    },
    [dispatch, updateOrg]
  );

  const handleSubmit = (values) => {
    updateOrgProcess(values.toJS());
  };

  const handleCoverDrop = useCallback(
    (acceptedFiles) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      setIsUploading(true);
      apis.member
        .fetchPostOrganizationLogo({
          formData,
        })
        .then((response) => {
          updateOrgProcess({
            organizationId: selectedOrg.get('organizationId'),
            organizationLogoPath: response.data.organizationLogoPath,
          });
          setIsUploading(false);
        })
        .catch((e) => {
          setIsUploading(false);
        });
    },
    [selectedOrg, updateOrgProcess]
  );

  return (
    <Main>
      <Container>
        <PageHeader
          title="基本資料"
          subtitle="您可以在這裡進行單位的基本資料維護。"
        />
        <PageSection>
          <Paper>
            <Box p={2}>
              <OrgInfoForm
                onSubmit={handleSubmit}
                initialValues={selectedOrg}
                onDrop={handleCoverDrop}
                isUploading={isUploading}
              />
            </Box>
          </Paper>
        </PageSection>
      </Container>
    </Main>
  );
};

export default OrgInfo;
