import { AxiosError } from 'axios';
import { createAction } from '@reduxjs/toolkit';
import { MutatorCallback } from 'swr/dist/types';

export const fetchPatchUserApplyActivityClass = createAction<unknown>(
  'profilePages/activities/fetchPatchUserApplyActivityClass'
);
export const fetchPatchUserApplyActivityClassRequest = createAction(
  'profilePages/activities/fetchPatchUserApplyActivityClassRequest'
);
export const fetchPatchUserApplyActivityClassSuccess = createAction<unknown>(
  'profilePages/activities/fetchPatchUserApplyActivityClassSuccess'
);
export const fetchPatchUserApplyActivityClassFailure = createAction<AxiosError>(
  'profilePages/activities/fetchPatchUserApplyActivityClassFailure'
);

export const fetchPostUserApplyActivityClass = createAction<{
  activityId: string;
  userApplyId: string;
  activityClassId: string;
  callback: MutatorCallback;
}>('profilePages/activities/fetchPostUserApplyActivityClass');
export const fetchPostUserApplyActivityClassRequest = createAction(
  'profilePages/activities/fetchPostUserApplyActivityClassRequest'
);
export const fetchPostUserApplyActivityClassSuccess = createAction<unknown>(
  'profilePages/activities/fetchPostUserApplyActivityClassSuccess'
);
export const fetchPostUserApplyActivityClassFailure = createAction<AxiosError>(
  'profilePages/activities/fetchPostUserApplyActivityClassFailure'
);
