import React, { FC, useEffect } from 'react';

import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { makeStyles } from '@eGroupTeam/material';

import { Link, useHistory } from 'react-router-dom';
import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import Box from '@eGroupTeam/material/Box';
import AppBar from '@eGroupTeam/material/AppBar';
import Toolbar from '@eGroupTeam/material/Toolbar';
import Button from '@eGroupTeam/material/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logo from 'components/Logo';
import UserMenu from 'components/UserMenu';
import { ImuOrganization } from 'types';
import { List } from 'immutable';

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: '#fff',
  },
  backBtn: {
    marginRight: theme.spacing(2),
  },
}));

export interface NewActivityRootProps extends RouteConfigComponentProps {
  organizations: List<ImuOrganization>;
}

const NewActivityRoot: FC<NewActivityRootProps> = ({
  route,
  organizations,
}) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (organizations.size === 0) {
      history.push('/me/new-org');
    }
  }, [history, organizations]);

  return (
    <>
      <AppBar elevation={1} className={classes.appbar}>
        <Toolbar>
          <Link to="/me">
            <Logo />
          </Link>
          <Box flexGrow={1} />
          <Button
            className={classes.backBtn}
            color="primary"
            variant="outlined"
            onClick={() => {
              history.push('/me/activities');
            }}
          >
            <ArrowBackIcon />
            返回活動列表
          </Button>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <NavbarBrick />
      {renderRoutes(route?.routes)}
    </>
  );
};

export default NewActivityRoot;
