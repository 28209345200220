import React from 'react';

import clsx from 'clsx';
import { createStyles, Theme, withStyles } from '@eGroupTeam/material';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: theme.spacing(3),
      },
    },
  });

const ActivityGridTitle = ({ classes, className, ...other }) => (
  <div className={clsx(classes.root, className)} {...other} />
);

export default withStyles(styles)(ActivityGridTitle);
