import React, { FC } from 'react';

import { Typography, makeStyles } from '@eGroupTeam/material';
import RoomIcon from '@material-ui/icons/Room';
import ActivityGrid, { ActivityGridProps } from './ActivityGrid';

const useStyles = makeStyles(() => ({
  content: {
    width: '100%',
  },
}));

export type ActivityClassPlaceProps = ActivityGridProps

const ActivityClassOnlineMeeting: FC<ActivityClassPlaceProps> = (props) => {
  const classes = useStyles();
  return (
    <ActivityGrid {...props}>
      <>
        <RoomIcon color="action" />
        <Typography display="inline" variant="h6">
          活動地點
        </Typography>
      </>
      <div className={classes.content}>
        <Typography variant="body1">
          <strong>此活動為線上活動</strong>
        </Typography>
        <Typography variant="body1">
          報名參加後將會提供線上會議連結
        </Typography>
      </div>
    </ActivityGrid>
  );
};

export default ActivityClassOnlineMeeting;
