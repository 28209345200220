import React, { useEffect, useState } from 'react';

import axios from 'axios';

import MarkdownElement from '@eGroupTeam/material-lab/MarkdownElement';
import Container from '@eGroupTeam/material/Container';
import Paper from '@eGroupTeam/material/Paper';
import Box from '@eGroupTeam/material/Box';
import CircularProgress from '@eGroupTeam/material/CircularProgress';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import Header from 'landingPages/components/Header';
import textMd from './text.md';

const Terms = () => {
  const [text, setText] = useState("");
  useEffect(() => {
    axios.get(textMd).then((res) => {
      setText(res.data);
    });
  }, []);
 
  if (!text) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <Header style={{ height: 85, padding: 0 }}/>
      <Container>
        <Paper square={false} elevation={0}>
          <Box py={2} px={3}>
            <MarkdownElement text={text} />
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Terms;
