import React from 'react';

import { Map, List } from 'immutable';

import { Field } from 'redux-form/immutable';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import RadioInputGroupField from '@eGroupTeam/material-form/immutable/RadioInputGroupField';
import CheckboxInputGroupField from '@eGroupTeam/material-form/immutable/CheckboxInputGroupField';
import PickerField from '@eGroupTeam/material-form/immutable/PickerField';
import { FormLabel, makeStyles, MenuItem, Paper } from '@eGroupTeam/material';
import FieldAnchor from 'components/FieldAnchor';
import clsx from 'clsx';
import indexPath from 'utils/indexPath';

const useStyles = makeStyles((theme) => ({
  field: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  fieldError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const ApplyField = ({ data, validate, submitFailed, formSyncErrors }) => {
  const classes = useStyles();

  const renderField = () => {
    const applyFieldOptionList = data.get('applyFieldOptionList', List());
    switch (data.get('applyFieldType')) {
      case 'text':
        return (
          <Field
            name={data.get('applyFieldId')}
            component={TextLoadingField}
            fullWidth
            helperText={data.get('applyFieldRemark')}
            validate={validate}
          />
        );
      case 'email':
        return (
          <Field
            name={data.get('applyFieldId')}
            component={TextLoadingField}
            fullWidth
            helperText={data.get('applyFieldRemark')}
            validate={validate}
            placeholder="Email"
            type="email"
          />
        );
      case 'textarea':
        return (
          <Field
            name={data.get('applyFieldId')}
            component={TextLoadingField}
            fullWidth
            helperText={data.get('applyFieldRemark')}
            validate={validate}
            multiline
          />
        );
      case 'select':
        return (
          <Field
            name={data.get('applyFieldId')}
            component={TextLoadingField}
            fullWidth
            select
            helperText={data.get('applyFieldRemark')}
            validate={validate}
          >
            {applyFieldOptionList.map((option) => (
              <MenuItem
                key={option.get('applyFieldOptionId')}
                value={option.get('applyFieldOptionId')}
              >
                {option.get('applyFieldOptionName')}
              </MenuItem>
            ))}
          </Field>
        );
      case 'date':
        return (
          <Field
            name={data.get('applyFieldId')}
            component={PickerField}
            fullWidth
            helperText={data.get('applyFieldRemark')}
            validate={validate}
            pickerFormat="yyyy-MM-dd"
          />
        );
      case 'choiceone':
        return (
          <Field
            name={data.get('applyFieldId')}
            component={RadioInputGroupField}
            fullWidth
            helperText={data.get('applyFieldRemark')}
            validate={validate}
            options={applyFieldOptionList
              .map((option) =>
                Map({
                  key: option.get('applyFieldOptionId'),
                  value: option.get('applyFieldOptionId'),
                  label: option.get('applyFieldOptionName'),
                  toggleInput: Boolean(option.get('isOther')),
                })
              )
              .toJS()}
          />
        );
      case 'choicemulti':
        return (
          <Field
            name={data.get('applyFieldId')}
            component={CheckboxInputGroupField}
            fullWidth
            helperText={data.get('applyFieldRemark')}
            validate={validate}
            options={applyFieldOptionList
              .map((option) =>
                Map({
                  key: option.get('applyFieldOptionId'),
                  name: option.get('applyFieldOptionId'),
                  label: option.get('applyFieldOptionName'),
                  toggleInput: Boolean(option.get('isOther')),
                })
              )
              .toJS()}
          />
        );
      default:
        return undefined;
    }
  };
  if (!data) {
    return null;
  }
  return (
    <>
      <FieldAnchor name={`position-${data.get('applyFieldId')}`} />
      <Paper
        className={clsx(classes.field, {
          [classes.fieldError]:
            submitFailed &&
            !!indexPath(data.get('applyFieldId'), formSyncErrors),
        })}
      >
        <div className={classes.header}>
          <FormLabel
            className={classes.label}
            required={Boolean(data.get('isRequired'))}
            color="primary"
          >
            {data.get('applyFieldName')}
          </FormLabel>
        </div>
        {renderField()}
      </Paper>
    </>
  );
};

export default ApplyField;
