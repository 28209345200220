import React, { useEffect } from 'react';

import { useParams } from 'react-router';
import useHasLogined from 'utils/useHasLogined';
import headimg from 'static/images/classroom.jpg';
import { useCookies } from 'react-cookie';
import Beforeunload from '@eGroupTeam/utils/Beforeunload';
import { useSelector, useDispatch } from 'react-redux';

import { Redirect } from 'react-router-dom';
import RatioImage from '@eGroupTeam/material/RatioImage';
import Button from '@eGroupTeam/material/Button';
import Center from '@eGroupTeam/material-layout/Center';
import {
  Container,
  Box,
  CircularProgress,
  Typography,
  TextField,
} from '@eGroupTeam/material';
import { Formik } from 'formik';
import { UrlParams } from 'types';
import SurveyForm from './SurveyForm';
import {
  fetchGetSurveyQuestions,
  fetchPostSurveyResponses,
  getIsPosting,
  getSurveyQuestions,
  getSurveyQuestionProperties,
} from './redux';
import parseResponseList from './parseResponseList';

const Survey = ({ survey }) => {
  const dispatch = useDispatch();
  const { surveyId } = useParams<UrlParams>();
  const [cookies] = useCookies(['uid']);
  const isPosting = useSelector(getIsPosting);
  const surveyQuestions = useSelector(getSurveyQuestions);
  const questionProperties = useSelector(getSurveyQuestionProperties);
  const hasLogin = useHasLogined();

  useEffect(() => {
    dispatch(
      fetchGetSurveyQuestions({
        surveyId,
      })
    );
  }, [dispatch, surveyId]);

  useEffect(() => {
    if (survey && surveyQuestions) {
      Beforeunload.block();
    }
  }, [survey, surveyQuestions]);

  const handleSubmit = (values) => {
    dispatch(
      fetchPostSurveyResponses({
        surveyId,
        responseList: parseResponseList(values, questionProperties.toJS()),
      })
    );
    Beforeunload.unblock();
  };

  /**
   * uid is userId it'll get when user filled phone
   */
  if (!cookies.uid && !hasLogin) {
    return <Redirect to={`/phone-fill-survey?surveyId=${surveyId}`} />;
  }

  if (!survey || !surveyQuestions) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {(props) => (
        <Container>
          <RatioImage
            src={headimg}
            fit="cover"
            ratio="16:5"
            alt="Photo by Ferran Feixas on Unsplash"
          />
          <Box my={2}>
            <Typography
              variant="h5"
              gutterBottom={survey.has('surveyDescription')}
            >
              {survey.get('surveyName')}
            </Typography>
            {survey.has('surveyDescription') && (
              <TextField
                multiline
                value={survey.get('surveyDescription')}
                fullWidth
                disabled
                InputProps={{ style: { color: '#000' } }}
              />
            )}
          </Box>
          <SurveyForm {...props} />
          <Box py={3} textAlign="right">
            <Button
              loading={isPosting}
              type="submit"
              variant="contained"
              color="primary"
              onClick={props.submitForm}
              fullWidth
              disableElevation
            >
              送出
            </Button>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default Survey;
