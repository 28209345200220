import React from "react";

import * as yup from "yup";
import { Box, makeStyles, MenuItem, TextField } from "@material-ui/core";
import Grid from "@eGroupTeam/material/Grid";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import RateReviewOutlinedIcon from "@material-ui/icons/RateReviewOutlined";
import SubtitlesOutlinedIcon from "@material-ui/icons/SubtitlesOutlined";
import { Controller, useForm } from "react-hook-form";
import { ContactUsSectionFormInput } from "types";
import useFeedbackTypes from "@eGroupTeam/hooks/useFeedbackTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import StyledButton from "../StyledButton";

const useStyles = makeStyles((theme) => ({
  contactSecton: {
    padding: "2rem",
    backgroundColor: theme.egPalette.primary[1],
  },
  title: {
    color: theme.palette.common.white,
    fontSize: "1.75rem",
    lineHeight: "1.2",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      color: theme.egPalette.primary[1],
    },
  },
  contactForm: {
    "& .MuiOutlinedInput-root": {
      background: theme.palette.common.white,
      border: "0 !important",
    },
    "& svg": {
      color: "#AAAAAA",
      marginRight: theme.spacing(1),
    },
    outline: "unset !important",
    border: "0 !important",
  },
}));

const schema = yup.object().shape({
  organizationFeedbackPersonName: yup
    .string()
    .max(10, "長度不能超過10個字元")
    .required("「姓名」是必填欄位"),
  organizationFeedbackPersonEmail: yup
    .string()
    .max(100, "長度不能超過100個字元")
    .email("「信箱」格式錯誤")
    .required("「信箱」是必填欄位"),
  feedbackTypeId: yup.string().required("「回饋類型」是必填欄位"),
  organizationFeedbackTitle: yup
    .string()
    .max(40, "長度不能超過40個字元")
    .required("「標題」是必填欄位"),
  organizationFeedbackContent: yup.string().required("「內容」是必填欄位"),
});

export interface ContactUsSectionFormProps {
  onSubmit: (values: ContactUsSectionFormInput) => void;
}

const ContactUsSectionForm = ({ onSubmit }) => {
  const classes = useStyles();
  const { data } = useFeedbackTypes();
  const { control, errors, handleSubmit } = useForm<ContactUsSectionFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      organizationFeedbackPersonName: "",
      organizationFeedbackPersonEmail: "",
      organizationFeedbackTitle: "",
      organizationFeedbackContent: "",
      feedbackTypeId: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Controller
            as={TextField}
            control={control}
            name="organizationFeedbackPersonName"
            placeholder="姓名"
            fullWidth
            variant="outlined"
            required
            className={classes.contactForm}
            error={!!errors.organizationFeedbackPersonName}
            helperText={errors.organizationFeedbackPersonName?.message}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            as={TextField}
            control={control}
            name="organizationFeedbackPersonEmail"
            placeholder="信箱"
            fullWidth
            variant="outlined"
            required
            className={classes.contactForm}
            InputProps={{
              startAdornment: <MailOutlineIcon />,
            }}
            error={!!errors.organizationFeedbackPersonEmail}
            helperText={errors.organizationFeedbackPersonEmail?.message}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            as={TextField}
            control={control}
            name="feedbackTypeId"
            fullWidth
            variant="outlined"
            select
            required
            className={classes.contactForm}
            InputProps={{
              startAdornment: <RateReviewOutlinedIcon />,
            }}
            error={!!errors.feedbackTypeId}
            helperText={errors.feedbackTypeId?.message}
            size="small"
          >
            <MenuItem disabled>回饋類型</MenuItem>
            {data?.source.map((el) => (
              <MenuItem key={el.feedbackTypeId} value={el.feedbackTypeId}>
                {el.feedbackTypeName}
              </MenuItem>
            ))}
          </Controller>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            as={TextField}
            control={control}
            name="organizationFeedbackTitle"
            placeholder="標題"
            fullWidth
            variant="outlined"
            required
            className={classes.contactForm}
            InputProps={{
              startAdornment: <SubtitlesOutlinedIcon />,
            }}
            error={!!errors.organizationFeedbackTitle}
            helperText={errors.organizationFeedbackTitle?.message}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={TextField}
            control={control}
            name="organizationFeedbackContent"
            placeholder="內容"
            fullWidth
            variant="outlined"
            required
            multiline
            className={classes.contactForm}
            error={!!errors.organizationFeedbackContent}
            helperText={errors.organizationFeedbackContent?.message}
            size="small"
            rules={{
              required: "「內容」是必填欄位",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box width="100%" textAlign="center">
            <StyledButton rounded type="submit">
              送出
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactUsSectionForm;
