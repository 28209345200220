import React, { VFC } from "react";
import { makeStyles } from "@material-ui/core";

import Container from "@eGroupTeam/material/Container";
import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import Box from "@eGroupTeam/material/Box";

import { DIALOG as LOGIN_DIALOG } from 'components/LoginDialog';
import { openDialog } from "@eGroupTeam/redux-modules/immutable/dialogs";
import { useDispatch } from "react-redux";
import StyledButton from "../components/StyledButton";
import BasicServiceSection from "./BasicServiceSection";
import TrailEventSection from "./TrailEventSection";

const useStyles = makeStyles((theme) => ({
  servicePlanSection: {
    background: "#f0fcff",
    padding: "5rem 0 2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 0 2rem",
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(6),
    "& h3": {
      color: theme.egPalette.primary[1],
      fontSize: "1.75rem",
      lineHeight: "1.2",
      marginBottom: "0.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0",
    },
  },
}));

const ServicePlanSection: VFC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box className={classes.servicePlanSection}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.sectionTitle}>
            <Typography variant="h3" fontWeight={700} align="center">
              服務方案與價格
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} >
            <BasicServiceSection />
          </Grid>
          <Grid item sm={12} md={8}>
            <TrailEventSection />
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center" width="100%">
              <StyledButton rounded onClick={() => dispatch(openDialog(LOGIN_DIALOG))}>舉辦活動</StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ServicePlanSection;
