import React, { FC } from 'react';

import displayValues from '@eGroupTeam/utils/displayValues';
import { format } from '@eGroupTeam/utils/dateUtils';
import { PaymentOrder } from 'types';
import { PaymentOrderStatus } from 'enums';

import { Grid, makeStyles, TextField } from '@eGroupTeam/material';
import PaymentStatusTag from 'components/PaymentStatusTag';

export interface OrderInfoProps {
  data: PaymentOrder;
}

const useStyles = makeStyles({
  textField: {
    "& .MuiInput-underline:before": {
      borderColor: "#DBDDE3",
    },
  },
});

const OrderInfo: FC<OrderInfoProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {displayValues(
        [
          {
            key: '訂單編號',
            value: data.paymentOrderId,
          },
          {
            key: '訂單日期',
            value: data.paymentOrderCreateDateString
              ? format(data.paymentOrderCreateDateString, 'PP pp')
              : undefined,
          },
          {
            key: '聯絡人',
            value: data.paymentOrderContactName,
          },
          {
            key: '聯絡人電話',
            value: data.paymentOrderContactPhone,
          },
          {
            key: '公司抬頭',
            value: data.paymentOrderInvoiceTitle,
          },
          {
            key: '公司統編',
            value: data.paymentOrderInvoiceTaxIdNumber,
          },
          {
            value: data.paymentOrderStatus,
            render: () => (
              <Grid item xs={12} sm={6}>
                付款狀態：
                <PaymentStatusTag success={data.paymentOrderStatus === 3} error={data.paymentOrderStatus === 4}>
                  {PaymentOrderStatus[data.paymentOrderStatus]}
                </PaymentStatusTag>
              </Grid>
            )
          },
        ],
        ({ key, value }) => (
          <Grid item xs={12} sm={6} key={key}>
            <TextField
              fullWidth
              label={key}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              defaultValue={value}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        )
      )}
    </Grid>
  )
};

export default OrderInfo;
