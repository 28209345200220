import React from 'react';

import useLocationSearch from 'utils/useLocationSearch';
import useMemberInfo from 'utils/useMemberInfo';

import { Redirect, useHistory } from 'react-router-dom';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import Main from '@eGroupTeam/material-layout/Main';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Button,
  makeStyles,
} from '@eGroupTeam/material';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OrderInfo from 'components/OrderInfo';
import PaymentProductList from 'components/PaymentProductList';
import useOrgOrder from 'utils/useOrgOrder';
import OrderTotalAmount from 'components/OrderTotalAmount';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #C0C3CE",
    boxShadow: "0 3px 16px rgba(10, 75, 109, 0.08)",
    borderRadius: "8px 8px 0 0",
    marginBottom: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(3),
    "& h3": {
      fontSize: "1.75rem",
      lineHeight: "1.2",
    },
  },
}));

const PaymentComplete = () => {
  const classes = useStyles();
  const history = useHistory();
  const { organizationId, paymentOrderId } = useLocationSearch();
  const [memberInfo] = useMemberInfo();
  const { data } = useOrgOrder({
    organizationId: organizationId as string,
    paymentOrderId: paymentOrderId as string,
  });

  if (!paymentOrderId || !organizationId) {
    return <Redirect to="/" />;
  }

  if (!data) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <Main>
      <Container maxWidth="sm">
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            <strong>付款完成！</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            您的付款已經完成，我們將會把更多詳細資訊寄至
            <br />
            {memberInfo?.memberAccount}，若有其他問題，請與我們聯絡。
          </Typography>
        </Box>
        <Paper className={classes.paper}>
          <Box className={classes.header}>
            <Typography variant="h3" fontWeight={400}>
              您的訂單：
            </Typography>
          </Box>
          <Box p={3}>
            <OrderInfo data={data} />
          </Box>
        </Paper>
        <Paper className={classes.paper}>
          <Box className={classes.header}>
            <Typography variant="h3" fontWeight={400}>
              訂單內容
            </Typography>
          </Box>
          <Box>
            <PaymentProductList
              paymentOrderType={data.paymentOrderType}
              data={data.paymentProductList}
            />
            <OrderTotalAmount
              paymentOrderType={data.paymentOrderType}
              amount={data.paymentOrderTotalAmount}
            />
          </Box>
          <Box px={1} pb={3}>
            {window.localStorage.getItem('isCreatingActivity') ? (
              <Button
                color="primary"
                onClick={() => {
                  history.push('/me/new-activity');
                }}
              >
                <ArrowBackIcon />
                繼續舉辦活動
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  history.push('/me');
                }}
              >
                <ArrowBackIcon />
                返回主辦中心
              </Button>
            )}
          </Box>
        </Paper>
      </Container>
    </Main>
  );
};

export default PaymentComplete;
