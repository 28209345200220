import React, { FC } from 'react';

import { toDate } from '@eGroupTeam/utils/dateUtils';
import validator from '@eGroupTeam/utils/validator';
import { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form/immutable';
import PickerField from '@eGroupTeam/material-form/immutable/PickerField';
import TextLoadingField from '@eGroupTeam/material-form/TextLoadingField';
import { MenuItem } from '@eGroupTeam/material';

type Errors = {
  surveyName?: string;
  surveyType?: string;
};

const validate = (values) => {
  const errors: Errors = {};
  validator(errors, 'surveyName', values.get('surveyName'))
    .isRequired('「問卷名稱」是必填欄位')
    .lengthLimit(100, '長度不能超過100個字元');
  validator(errors, 'surveyType', values.get('surveyType')).isRequired(
    '「問卷類型」是必填欄位'
  );
  validator(
    errors,
    'surveyStartDateString',
    values.get('surveyStartDateString')
  ).isRequired('「問卷開始時間」是必填欄位');
  validator(
    errors,
    'surveyEndDateString',
    values.get('surveyEndDateString')
      ? toDate(values.get('surveyEndDateString'))
      : undefined
  )
    .isRequired('「問卷結束時間」是必填欄位')
    .isDateBefore(
      toDate(values.get('surveyStartDateString') || new Date()) as Date,
      '「問卷結束時間」不能早於「問卷開始時間」'
    );
  return errors;
};

export interface SurveyFormProps {
  showSurveyType?: boolean;
}

export default function createSurveyForm(form: string) {
  const SurveyForm: FC<SurveyFormProps & InjectedFormProps> = (props) => {
    const { handleSubmit, showSurveyType } = props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          label="問卷名稱"
          name="surveyName"
          component={TextLoadingField}
          fullWidth
          required
          autoFocus
          margin="normal"
        />
        {showSurveyType && (
          <Field
            label="問卷類型"
            name="surveyType"
            component={TextLoadingField}
            fullWidth
            required
            select
            margin="normal"
          >
            <MenuItem value="preTest">會前問卷</MenuItem>
            <MenuItem value="postTest">會後問卷</MenuItem>
          </Field>
        )}
        <Field
          name="surveyStartDateString"
          component={PickerField}
          label="問卷開始時間"
          picker="dateTime"
          size="small"
          required
          fullWidth
          margin="normal"
          pickerFormat="yyyy-MM-dd HH:mm"
          disablePast
        />
        <Field
          name="surveyEndDateString"
          component={PickerField}
          label="問卷結束時間"
          picker="dateTime"
          size="small"
          required
          fullWidth
          margin="normal"
          pickerFormat="yyyy-MM-dd HH:mm"
          disablePast
        />
      </form>
    );
  };

  return reduxForm<any, SurveyFormProps>({
    form,
    validate,
  })(SurveyForm);
}
