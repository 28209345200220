import axios from 'axios';
import queryString from 'query-string';
import config from './apiConfig';

export const fetcher = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/member`,
  ...config,
});

export default {
  /**
   * Account
   */

  /**
   * Logout
   */
  fetchPostLogout: () => fetcher.post('/logout'),

  /**
   * Get Facebook login URL
   */
  fetchGetFacebookLoginUrl: () => fetcher.get('/facebook/login-url'),
  /**
   * Facebook login
   */
  fetchPostFacebookLogin: (payload) => fetcher.post('/facebook/login', payload),
  /**
   * Get Google login URL
   */
  fetchGetGoogleLoginUrl: () => fetcher.get('/google/login-url'),
  /**
   * Google login
   */
  fetchPostGoogleLogin: (payload) => fetcher.post('/google/login', payload),

  /**
   * 更改報名狀態
   */
  fetchPatchUserApplyActivityClass: ({
    activityId,
    userApplyId,
    activityClassId,
    ...other
  }) =>
    fetcher.patch(
      `/activities/${activityId}/user-applies/${userApplyId}/activity-class/${activityClassId}/apply-status`,
      other
    ),

  /**
   * Organizations
   */
  /**
   * get organizations
   */
  fetchGetOrganizations: (payload) =>
    fetcher.get(`/organizations?${queryString.stringify(payload)}`),

  fetchGetOrganization: (payload) => {
    const { organizationId } = payload
    return fetcher.get(`/organizations/${organizationId}`)
  },

  /**
   * create organization
   */
  fetchPostOrganization: (payload) => fetcher.post('/organizations', payload),

  /**
   * 上傳單位logo照
   */
  fetchPostOrganizationLogo: ({ formData }) =>
    fetcher.post(`/organizations/logo`, formData),

  /**
   * update organization
   */
  fetchPatchOrganization: (payload) => {
    const { organizationId, ...other } = payload || {}

    return fetcher.patch(`/organizations/${organizationId}`, other)
  },

  /**
   * get member info
   */
  fetchGetMemberInfo: () => fetcher.get(`/user-info`),

  /**
   * get member modules
   */
  fetchGetMemberModules: ({ organizationId }) =>
    fetcher.get(`/organizations/${organizationId}/module-auth`),
};
