import React, { forwardRef, useState } from "react";

import useUploadFiles from "@eGroupTeam/hooks/useUploadFiles";
import useCreateFeedback from "@eGroupTeam/hooks/useCreateFeedback";
import useFeedbackTypes from "@eGroupTeam/hooks/useFeedbackTypes";
import { useCookies } from "react-cookie";
import {
  withStyles,
  WithStyles,
  Avatar,
  IconButton,
  MenuItem,
  IconButtonProps,
  Theme,
  ButtonProps,
  createStyles,
  Button,
} from "@eGroupTeam/material";
import { useDispatch } from "react-redux";
import useHasLogined from "utils/useHasLogined";
import useMemberInfo from "utils/useMemberInfo";
import { openDialog } from "@eGroupTeam/redux-modules/immutable/dialogs";
import { fetchPostLogout } from "redux/modules/common";
import { DIALOG as LOGIN_DIALOG } from "components/LoginDialog";

import FeedbackDialog from "@eGroupTeam/material-module/FeedbackDialog";
import { Link } from "react-router-dom";
import ButtonMenu from "@eGroupTeam/material-module/ButtonMenu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  openSnackbar,
  setSnackbarData,
} from "@eGroupTeam/redux-modules/immutable/snackbars";
import { SNACKBAR } from "App";

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      width: 32,
      height: 32,
    },
    avatar: {
      cursor: "pointer",
      background: theme.palette.primary.main,
      width: 32,
      height: 32,
      fontSize: theme.typography.pxToRem(14),
    },
    loginBtn: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      outline: 0,
    },
  });

export interface UserMenuProps extends WithStyles<typeof styles> {
  MuiIconButtonProps?: IconButtonProps;
  loginButtonColor?: ButtonProps["color"];
}

const UserMenu = forwardRef<HTMLButtonElement, UserMenuProps>((props, ref) => {
  const { classes, MuiIconButtonProps, loginButtonColor } = props;
  const dispatch = useDispatch();
  const hasLogined = useHasLogined();
  const [memberInfo] = useMemberInfo();
  const [, , removeCookie] = useCookies();
  const { excute: createFeedback, isLoading } = useCreateFeedback();
  const { excute: uploadFiles, isLoading: isUploading } = useUploadFiles();
  const { data: feedbackTypes } = useFeedbackTypes();
  const [
    organizationFeedbackFilePathList,
    setOrganizationFeedbackFilePathList,
  ] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    window.localStorage.clear();
    removeCookie("lid");
    removeCookie("tid");
    removeCookie("u_info");
    dispatch(fetchPostLogout());
  };

  const renderAvatar = () => {
    if (memberInfo?.loginProfilePhotoPath) {
      return (
        <Avatar
          classes={{ root: classes.avatar }}
          src={memberInfo?.loginProfilePhotoPath}
        />
      );
    }
    if (memberInfo?.memberName) {
      return (
        <Avatar classes={{ root: classes.avatar }}>
          {memberInfo?.memberName.slice(0, 1).toUpperCase()}
        </Avatar>
      );
    }
    return <AccountCircleIcon className={classes.icon} />;
  };

  const renderToNewSiteButton = () => {
    if (memberInfo?.isTestList) {
      if (
        window.location.hostname === process.env.REACT_APP_CURRENT_WEBSITE_URL
      ) {
        return (
          <MenuItem
            onClick={() => {
              window.location.replace(
                `https://${process.env.REACT_APP_TARGET_WEBSITE_URL}${window.location.pathname}`
              );
            }}
          >
            前往新版網頁
          </MenuItem>
        );
      }
      if (
        window.location.hostname === process.env.REACT_APP_TARGET_WEBSITE_URL
      ) {
        return (
          <MenuItem
            onClick={() => {
              window.location.replace(
                `https://${process.env.REACT_APP_CURRENT_WEBSITE_URL}${window.location.pathname}`
              );
            }}
          >
            返回舊版網頁
          </MenuItem>
        );
      }
    }
    return undefined;
  };

  if (!hasLogined) {
    return (
      <Button
        variant="outlined"
        rounded
        color={loginButtonColor}
        onClick={() => dispatch(openDialog(LOGIN_DIALOG))}
      >
        登入
      </Button>
    );
  }

  return (
    <>
      <FeedbackDialog
        title="線上回饋"
        isOpen={isOpen}
        loading={isLoading}
        isUploading={isUploading}
        disabledSubmit={isUploading}
        onClose={() => setIsOpen(false)}
        typeOptions={feedbackTypes?.source.map((type) => ({
          label: type.feedbackTypeName,
          value: type.feedbackTypeId,
        }))}
        onSubmit={(values) => {
          setIsOpen(false);
          createFeedback({
            organizationFeedbackTitle: values.title,
            organizationFeedbackContent: values.content,
            organizationFeedbackPersonEmail: values.email,
            organizationFeedbackPersonName: values.name,
            feedbackType: {
              feedbackTypeId: values.type,
            },
            organizationFeedbackFilePathList,
          })
            .then(() => {
              dispatch(
                setSnackbarData({
                  name: SNACKBAR,
                  message: "回饋成功",
                  variant: "success",
                })
              );
              dispatch(openSnackbar(SNACKBAR));
            })
            .catch(() => {
              dispatch(
                setSnackbarData({
                  name: SNACKBAR,
                  message: "回饋失敗",
                  variant: "error",
                })
              );
              dispatch(openSnackbar(SNACKBAR));
            });
        }}
        onUploadChange={(e) => {
          if (e.target.files) {
            uploadFiles({
              filePathType: "FEEDBACK",
              files: Array.from(e.target.files),
            }).then((res) => {
              setOrganizationFeedbackFilePathList(
                res.data.map((el) => el.filePath)
              );
            });
          }
        }}
        hideCompany
        hideCountry
      />
      <ButtonMenu
        id="user-menu"
        button={
          <IconButton size="small" edge="end" ref={ref} {...MuiIconButtonProps}>
            {renderAvatar()}
          </IconButton>
        }
      >
        <MenuItem component={Link} to="/">
          回首頁
        </MenuItem>
        <MenuItem component={Link} to="/me/profile/activities">
          會員中心
        </MenuItem>
        <MenuItem component={Link} to="/me">
          主辦中心
        </MenuItem>
        <MenuItem onClick={() => setIsOpen(true)}>線上回饋</MenuItem>
        {renderToNewSiteButton()}
        <MenuItem onClick={handleLogout}>登出</MenuItem>
      </ButtonMenu>
    </>
  );
});

export default withStyles(styles)(UserMenu);
