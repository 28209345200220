import React, { FC, useState } from 'react';

import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import { TextField, Typography } from '@eGroupTeam/material';

export const DIALOG = 'confirmUserApplyStatusChangeDialog';
const ConfirmChangeDialog = withReduxDialog(DIALOG)<unknown, ConfirmDialogProps>(
  ConfirmDialog
);

export interface ConfirmStatusChangeDialogProps {
  onConfirm?: (canceledReason: string) => void;
  isCanceled: number;
  userName: string;
  userApplyActivityClassCanceledReason?: string;
}

const ConfirmStatusChangeDialog: FC<ConfirmStatusChangeDialogProps> = ({
  onConfirm,
  isCanceled,
  userName,
  userApplyActivityClassCanceledReason,
}) => {
  const [canceledReason, setCanceledReason] = useState('');

  return (
    <ConfirmChangeDialog
      primary={`${isCanceled === 1 ? '恢復' : '取消'}報名狀態`}
      onConfirm={() => {
        if (onConfirm) {
          onConfirm(canceledReason);
        }
      }}
      maxWidth="xs"
      fullWidth
    >
      <Typography variant="body1" gutterBottom>
        確定要
        {isCanceled === 1 ? '恢復' : '取消'}
        {userName}的報名嗎?
      </Typography>
      {isCanceled === 0 ? (
        <TextField
          label="取消報名原因"
          value={canceledReason}
          onChange={(e) => setCanceledReason(e.target.value)}
          fullWidth
        />
      ) : (
        <Typography>
          取消報名原因：
          {userApplyActivityClassCanceledReason}
        </Typography>
      )}
    </ConfirmChangeDialog>
  );
};

export default ConfirmStatusChangeDialog;
