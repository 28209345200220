import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchPostCarrierConsumer,
  fetchPostCarrierConsumerRequest,
  fetchPostCarrierConsumerSuccess,
  fetchPostCarrierConsumerFailure
} = actionCreators.profilePages.carrier;

/** Types */
export const FETCH_POST_CARRIER_CONSUMER = fetchPostCarrierConsumer().type;
export const FETCH_POST_CARRIER_CONSUMER_REQUEST = fetchPostCarrierConsumerRequest()
  .type;
export const FETCH_POST_CARRIER_CONSUMER_SUCCESS = fetchPostCarrierConsumerSuccess()
  .type;
export const FETCH_POST_CARRIER_CONSUMER_FAILURE = fetchPostCarrierConsumerFailure()
  .type;

/** Selectors */

/**
 * API States
 */
const getApiStates = state =>
  state.getIn(['apis', 'profilePages', 'carrier'], Map());

const getFetchPostCarrierConsumer = createSelector(getApiStates, apiStates =>
  apiStates.get('fetchPostCarrierConsumer', Map())
);

export const getCarrierConsumer = createSelector(
  getFetchPostCarrierConsumer,
  fetchGet => fetchGet.get('response')
);

export const getIsLoading = createSelector(
  getFetchPostCarrierConsumer,
  fetchGet => fetchGet.get('isLoading')
);
