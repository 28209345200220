import { createAction } from '@reduxjs/toolkit';
import { ActivityFormInput } from 'types';

export const reset = createAction('mainPages/newActivity/reset');
export const setActiveStep = createAction<number>(
  'mainPages/newActivity/setActiveStep'
);
export const handlePrevStep = createAction(
  'mainPages/newActivity/handlePrevStep'
);
export const handleNextStep = createAction(
  'mainPages/newActivity/handleNextStep'
);
export const setFormValues = createAction<ActivityFormInput>(
  'mainPages/newActivity/setFormValues'
);
