import React, { FC, useEffect, useState } from 'react';

import groupBy from '@eGroupTeam/utils/groupBy';
import { getSelectedOrg } from 'redux/modules/common';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import Button from '@eGroupTeam/material/Button';
import {
  Accordion,
  AccordionSummary,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@eGroupTeam/material';
import { useSelector } from 'react-redux';
import useOrgModules from 'utils/useOrgModules';
import useOrgRole from 'utils/useOrgRole';
import useOrgRoleModules from 'utils/useOrgRoleModules';
import { OrganizationModule } from 'types';
import Checkbox from '@eGroupTeam/material/Checkbox';
import PermissionAccordion from './PermissionAccordion';

export const DIALOG = 'orgEditRolePermissionsDialog';

export type GroupValues = {
  [key: string]: string[];
};

export type Values = {
  groups?: GroupValues;
  permissions?: {
    accessActivities?: boolean;
  };
};
export interface OrgEditRolePermissionsDialogProps {
  onSubmit?: (values: Values) => void;
  loading?: boolean;
  organizationRoleId?: string;
  onChange?: (values: Values, type: 'groups' | 'permissions') => void;
}

const OrgEditRolePermissionsDialog: FC<
  OrgEditRolePermissionsDialogProps & WithReduxDialogProps
> = ({
  onSubmit,
  isOpen,
  handleClose,
  loading,
  organizationRoleId,
  onChange,
}) => {
  const selectedOrg = useSelector(getSelectedOrg);
  const { data: modules } = useOrgModules({
    organizationId: selectedOrg.get('organizationId'),
  });

  const [values, setValues] = useState<Values>({});

  const {
    mutate: mutateRoleModules,
    data: dataRoleModules,
  } = useOrgRoleModules(
    {
      organizationId: selectedOrg.get('organizationId'),
      organizationRoleId,
    },
    undefined,
    {
      onSuccess: (response) => {
        const { source = [] } = response.data;
        const groups = groupBy<OrganizationModule>(source, (el) => {
          const groupName = el.serviceModule.serviceModuleNameZh.split('-')[0];
          if (groupName) return groupName
          return '';
        });
        const groupValues = {};
        Object.keys(groups).forEach((groupName) => {
          groupValues[groupName] = groups[groupName]?.map(
            (el) => el.serviceModule.serviceModuleId
          );
        });
        setValues((val) => ({
          ...val,
          groups: groupValues,
        }));
      },
    }
  );

  const { mutate: mutateRole, data: dataRole } = useOrgRole(
    {
      organizationId: selectedOrg.get('organizationId'),
      organizationRoleId,
    },
    undefined,
    {
      onSuccess: (response) => {
        setValues((val) => ({
          ...val,
          permissions: {
            accessActivities: Boolean(
              response.data.organizationRoleActiviyAuth
            ),
          },
        }));
      },
    }
  );

  useEffect(() => {
    if (!isOpen) {
      setValues({});
    } else {
      if (dataRoleModules) {
        mutateRoleModules();
      }
      if (dataRole) {
        mutateRole();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleGroupChange = (groupName: string) => (group: string[]) => {
    const nextValues = {
      ...values,
      groups: {
        ...values.groups,
        [groupName]: group,
      },
    };
    setValues(nextValues);
    if (onChange) {
      onChange(nextValues, 'groups');
    }
  };

  const handlePermissionChange = (permissionName: string) => (
    _,
    checked: boolean
  ) => {
    const nextValues = {
      ...values,
      permissions: {
        ...values.permissions,
        [permissionName]: checked,
      },
    };
    setValues(nextValues);
    if (onChange) {
      onChange(nextValues, 'permissions');
    }
  };

  const renderContent = () => {
    if (!modules || !values.groups || !values.permissions) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }
    const groups = groupBy<OrganizationModule>(
      modules.source,
      (el) => {
        const groupName = el.serviceModule.serviceModuleNameZh.split('-')[0]
        if (groupName) return groupName
        return ''
      }
    );
    return (
      <>
        {Object.keys(groups).map((groupName) => {
          const group = groups[groupName];
          if (!group) return undefined
          return (
            <PermissionAccordion
              key={groupName}
              label={groupName}
              modules={group.map((el) => ({
                ...el,
                serviceModule: {
                  ...el.serviceModule,
                  serviceModuleNameZh:
                    el.serviceModule.serviceModuleNameZh.split('-')[1] ||
                    el.serviceModule.serviceModuleNameZh,
                  serviceModuleNameEn:
                    el.serviceModule.serviceModuleNameEn.split('-')[1] ||
                    el.serviceModule.serviceModuleNameEn,
                },
              }))}
              defaultValues={values.groups && values.groups[groupName]}
              onValuesChange={handleGroupChange(groupName)}
            />
          );
        })}
        <Accordion expanded={false}>
          <AccordionSummary component="label">
            <Checkbox
              label="允許檢視所有活動"
              checked={values.permissions?.accessActivities}
              onChange={handlePermissionChange('accessActivities')}
            />
          </AccordionSummary>
        </Accordion>
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={handleClose}
      transitionDuration={{
        exit: 0,
      }}
    >
      <DialogTitle>編輯角色權限</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>取消</Button>
        <Button
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => {
            if (onSubmit) {
              onSubmit(values);
            }
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<
  HTMLDivElement,
  OrgEditRolePermissionsDialogProps
>(OrgEditRolePermissionsDialog);
