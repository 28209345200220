import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Option } from '@eGroupTeam/material/DropDown'
import { getSelectedOrg } from 'redux/modules/common';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { ServiceProduct, ImuOrganization, PaymentContactFormInput, PaymentInvoiceFormInput } from 'types';
import calcTotal from '@eGroupTeam/utils/calcTotal';
import apis from 'redux/apisOrg';

import Main from '@eGroupTeam/material-layout/Main';
import Button from '@eGroupTeam/material/Button';
import {
  Container,
  Paper,
  Grid,
  Box,
  Typography,
  makeStyles,
  Icomoon
} from '@eGroupTeam/material';
import Currency from 'components/Currency';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import { useHistory } from 'react-router';
import useMemberInfo from 'utils/useMemberInfo';
import { useForm } from 'react-hook-form';
import BuyMicePoint from './components/BuyMicePoint';
import PaymentInformation from './components/PaymentInformation';
import InvoiceInformation from './components/InvoiceInformation';
import { defaultValues, schema } from './components/PaymentForm';

const useStyles = makeStyles({
  wrapper: {
    padding: "100px 0px 50px",
  },
  containerFluid: {
    maxWidth: "unset",
  },
  paper: {
    border: "1px solid #C0C3CE",
    boxShadow: "0 3px 16px rgba(10, 75, 109, 0.08)",
    borderRadius: "8px 8px 0 0",
  },
  subTotalSection: {
    background: "#F1FFFA",
    borderRadius: "8px",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  },
});

const Shop = () => {
  const classes = useStyles();
  const history = useHistory();
  const [memberInfo] = useMemberInfo();
  const selectedOrg: ImuOrganization = useSelector(getSelectedOrg);
  const { excute: createOrder, isLoading: isCreating } = useAxiosApiWrapper(
    apis.fetchPostOrder
  );
  const [selectedProductList, setSelectedProductList] = useState<
    Record<string, unknown>[]
  >([]);

  const formMethods = useForm<PaymentContactFormInput & PaymentInvoiceFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      paymentOrderContactName: memberInfo?.memberName,
      paymentOrderContactPhone: memberInfo?.memberPhone,
      paymentOrderContactEmail: memberInfo?.memberAccount,
      paymentOrderInvoiceTaxIdNumber: selectedOrg.get(
        'organizationInvoiceTaxIdNumber'
      ) as string,
      paymentOrderInvoiceTitle: selectedOrg.get('organizationName') as string,
    },
  });

  const handleChange = (product: ServiceProduct) => (
    selected: Option | Option[]
  ) => {
    if (typeof(selected) === 'string') {
      setSelectedProductList((val) => {
        const next = [...val].filter(
          (el) => el.serviceProductId !== product.serviceProductId
        );
        next.push({
          serviceProductId: product.serviceProductId,
          serviceProductUnitPriceZh: product.serviceProductUnitPriceZh,
          paymentProductQuantity: selected,
        });
        return next;
      });
    }
  };
  const handleSubmit = (values: PaymentContactFormInput & PaymentInvoiceFormInput) => {
    createOrder({
      serviceName: 'micepoint',
      organizationId: selectedOrg.get('organizationId'),
      paymentOrderContactName: values.paymentOrderContactName,
      paymentOrderContactPhone: values.paymentOrderContactPhone,
      paymentOrderContactLocalPhone: values.paymentOrderContactLocalPhone,
      paymentOrderContactEmail: values.paymentOrderContactEmail,
      paymentOrderInvoiceTitle: values.paymentOrderInvoiceTitle,
      paymentOrderInvoiceTaxIdNumber: values.paymentOrderInvoiceTaxIdNumber,
      paymentProductList: selectedProductList.filter(Boolean).map((el) => ({
        serviceProductId: el.serviceProductId,
        paymentProductQuantity: el.paymentProductQuantity,
      })),
    }).then((response) => {
      history.push(
        `/me/checkout?${queryString.stringify({
          paymentOrderId: response.data.paymentOrderId,
          organizationId: selectedOrg.get('organizationId'),
        })}`
      );
    });
  };

  const totalAmouts = calcTotal(
    selectedProductList.map((el) => {
      if (el.serviceProductUnitPriceZh && el.paymentProductQuantity) {
        return (el.serviceProductUnitPriceZh as number) * (el.paymentProductQuantity as number);
      }
      return 0;
    })
  );

  return (
    <Main>
      <Container maxWidth="md">
        <Grid container spacing={4}>
          <BuyMicePoint onChange={handleChange}/>
          <PaymentInformation onSubmit={handleSubmit} formMethods={formMethods} />
          <InvoiceInformation onSubmit={handleSubmit} formMethods={formMethods} />
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Box
                className={classes.subTotalSection}
                m={3}
                p={3}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="body1">小計</Typography>
                <Typography variant="body1">
                  <Currency value={totalAmouts} />
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="right">
              <Button
                rounded
                variant="contained"
                startIcon={
                  <Icomoon type="wallet"/>
                }
                size="large"
                color="info"
                loading={isCreating}
                disabled={totalAmouts === 0}
                onClick={() => formMethods.handleSubmit(handleSubmit)()}
              >
                前往付款
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Main>
  );
};

export default Shop;