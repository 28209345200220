import React from 'react';
import * as yup from 'yup';
import { Grid, IconButton } from '@eGroupTeam/material';
import { Controller } from 'react-hook-form';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import findDeepValue from '@eGroupTeam/utils/findDeepValue';

const ActivityClassDay = ({
  control,
  name,
  index,
  remove,
  disabledDelete,
  errors,
  defaultValues,
  ...other
}) => {
  const dateError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassDate`
  );
  const startError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassDayStartTimeString`
  );
  const endError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassDayEndTimeString`
  );
  return (
    <Grid container spacing={1} alignItems="center" {...other}>
      <Grid item>
        <Controller
          control={control}
          name={`${name}.activityClassDate`}
          defaultValue={defaultValues.activityClassDate}
          rules={{
            required: '此為必填欄位'
          }}
          render={({ ref, ...other }) => (
            <DatePicker
              label="日期"
              size="small"
              required
              fullWidth
              format="yyyy-MM-dd"
              disablePast
              error={!!dateError}
              helperText={dateError?.message}
              style={{ width: 120 }}
              inputVariant="filled"
              {...other}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name={`${name}.activityClassDayStartTimeString`}
          defaultValue={defaultValues.activityClassDayStartTimeString}
          rules={{
            required: '此為必填欄位'
          }}
          render={({ ref, ...other }) => (
            <TimePicker
              label="開始時間"
              size="small"
              required
              ampm={false}
              error={!!startError}
              helperText={startError?.message}
              style={{ width: 100 }}
              inputVariant="filled"
              {...other}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name={`${name}.activityClassDayEndTimeString`}
          defaultValue={defaultValues.activityClassDayEndTimeString}
          rules={{
            required: '此為必填欄位'
          }}
          render={({ ref, ...other }) => (
            <TimePicker
              label="結束時間"
              size="small"
              required
              ampm={false}
              error={!!endError}
              helperText={endError?.message}
              style={{ width: 100 }}
              inputVariant="filled"
              {...other}
            />
          )}
        />
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => remove(index)}
          size="small"
          disabled={disabledDelete}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ActivityClassDay;
