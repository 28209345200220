import React, { useState } from 'react';

import { getSelectedOrg } from 'redux/modules/common';
import useOrgRoles from 'utils/useOrgRoles';
import { useDispatch, useSelector } from 'react-redux';

import Switch from '@eGroupTeam/material/Switch';
import Main from '@eGroupTeam/material-layout/Main';
import {
  Container,
  Button,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  IconButton,
} from '@eGroupTeam/material';
import DeleteIcon from '@material-ui/icons/Delete';
import PageHeader from 'components/PageHeader';
import PageSection from 'components/PageSection';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import { OrganizationRole } from 'types';
import {
  closeDialog,
  openDialog,
  withReduxDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import apis from 'redux/apisOrg';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import OrgAddRoleDialog, { DIALOG as ADD_DIALOG } from './OrgAddRoleDialog';
import OrgEditRoleDialog, { DIALOG as EDIT_DIALOG } from './OrgEditRoleDialog';
import OrgEditRolePermissionsDialog, {
  DIALOG as EDIT_PERMISSION_DIALOG,
  Values,
} from './OrgEditRolePermissionsDialog';

const CONFIRM_DELETE_DIALOG = 'confirmDeleteOrgRoleDialog';

const ConfirmDeleteDialog = withReduxDialog(CONFIRM_DELETE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);

const OrgRole = () => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const [selectedOrgRole, setSelectedOrgRole] = useState<OrganizationRole>();
  const {
    handleChangePage,
    handleRowsPerPageChange,
    handleSearchChange,
    payload,
    page,
    rowsPerPage,
  } = useDataTable('orgRoles', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });
  const { data, isValidating, response, mutate } = useOrgRoles(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    payload
  );
  const { excute: createRole, isLoading: isPosting } = useAxiosApiWrapper(
    apis.fetchPostOrganizationRole
  );
  const { excute: updateRole, isLoading: isPatching } = useAxiosApiWrapper(
    apis.fetchPatchOrganizationRole
  );
  const { excute: deleteRole } = useAxiosApiWrapper(
    apis.fetchDeleteOrganizationRole
  );
  const {
    excute: updateRoleModule,
    isLoading: isRoleModulePosting,
  } = useAxiosApiWrapper(apis.fetchPostOrganizationRoleModule);

  const handleRoleStatusChange = (orgRole: OrganizationRole) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateRole({
      organizationId: selectedOrg.get('organizationId'),
      organizationRoleStatus: Number(event.target.checked),
      organizationRoleId: orgRole.organizationRoleId,
    }).then(() => {
      mutate();
    });
  };

  const handleAddSubmit = (values) => {
    createRole({
      organizationId: selectedOrg.get('organizationId'),
      organizationRoleNameZh: values.get('organizationRoleNameZh'),
      organizationRoleStatus: 1,
    }).then(() => {
      mutate();
      dispatch(closeDialog(ADD_DIALOG));
    });
  };

  const handleEditSubmit = (values) => {
    updateRole({
      organizationId: selectedOrg.get('organizationId'),
      organizationRoleNameZh: values.get('organizationRoleNameZh'),
      organizationRoleId: values.get('organizationRoleId'),
    }).then(() => {
      mutate();
      dispatch(closeDialog(EDIT_DIALOG));
    });
  };

  const handleEditPermissionSubmit = (values: Values) => {
    if (selectedOrgRole && values.groups) {
      updateRoleModule({
        organizationId: selectedOrg.get('organizationId'),
        organizationRoleId: selectedOrgRole.organizationRoleId,
        serviceModuleSet: Object.values(values.groups).reduce((p, c) => [
          ...p,
          ...c,
        ]),
      }).then(() => {
        dispatch(closeDialog(EDIT_PERMISSION_DIALOG));
      });
    }
  };

  const handleEditPermissionChange = (
    values: Values,
    type: 'groups' | 'permissions'
  ) => {
    if (selectedOrgRole && type === 'permissions' && values.permissions) {
      updateRole({
        organizationId: selectedOrg.get('organizationId'),
        organizationRoleId: selectedOrgRole.organizationRoleId,
        organizationRoleActiviyAuth: Number(
          values.permissions.accessActivities
        ),
      });
    }
  };

  const handleEditRole = (orgRole: OrganizationRole) => () => {
    setSelectedOrgRole(orgRole);
    dispatch(openDialog(EDIT_DIALOG));
  };

  const handleEditRoleModule = (orgRole: OrganizationRole) => () => {
    setSelectedOrgRole(orgRole);
    dispatch(openDialog(EDIT_PERMISSION_DIALOG));
  };

  const handleDeleteRole = (orgRole: OrganizationRole) => () => {
    setSelectedOrgRole(orgRole);
    dispatch(openDialog(CONFIRM_DELETE_DIALOG));
  };

  const handleConfirmDelete = () => {
    if (selectedOrgRole) {
      deleteRole({
        organizationId: selectedOrg.get('organizationId'),
        organizationRoleId: selectedOrgRole.organizationRoleId,
      }).then(() => {
        mutate();
      });
    }
  };

  const renderColumns = (rowData: string[]) => (
    <TableRow>
      {rowData.map((el) => (
        <TableCell key={el}>{el}</TableCell>
      ))}
    </TableRow>
  );

  const renderDataRow = (rowData: unknown) => {
    const orgRole = rowData as OrganizationRole;
    return (
      <TableRow key={orgRole.organizationRoleId}>
        <TableCell width={50}>
          <Switch
            defaultChecked={Boolean(orgRole.organizationRoleStatus)}
            onChange={handleRoleStatusChange(orgRole)}
            disabled={Boolean(orgRole.organizationRoleFix)}
          />
        </TableCell>
        <TableCell>{orgRole.organizationRoleNameZh}</TableCell>
        <TableCell>
          <Button
            style={{ marginRight: 8 }}
            onClick={handleEditRoleModule(orgRole)}
            variant="outlined"
            color="primary"
            disableElevation
          >
            編輯權限
          </Button>
          <Button onClick={handleEditRole(orgRole)} variant="outlined">
            編輯角色名稱
          </Button>
        </TableCell>
        <TableCell align="right">
          <Tooltip title="刪除角色">
            <span>
              <IconButton
                onClick={handleDeleteRole(orgRole)}
                disabled={Boolean(orgRole.organizationRoleFix)}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <OrgAddRoleDialog onSubmit={handleAddSubmit} loading={isPosting} />
      <OrgEditRoleDialog
        organizationRole={selectedOrgRole}
        onSubmit={handleEditSubmit}
        loading={isPatching}
      />
      <OrgEditRolePermissionsDialog
        organizationRoleId={selectedOrgRole?.organizationRoleId}
        onSubmit={handleEditPermissionSubmit}
        onChange={handleEditPermissionChange}
        loading={isRoleModulePosting}
      />
      <ConfirmDeleteDialog
        primary="刪除角色"
        message={
          <span>
            確定要刪除&quot;
            <strong>{selectedOrgRole?.organizationRoleNameZh}</strong>
            &quot;嗎？
          </span>
        }
        onConfirm={handleConfirmDelete}
      />
      <Main>
        <Container>
          <PageHeader
            title="角色管理"
            subtitle="您可以替您的單位創建角色並且給予各個成員不同的使用權限。"
            actions={
              <Button
                style={{ width: 102 }}
                onClick={() => dispatch(openDialog(ADD_DIALOG))}
                variant="contained"
                color="primary"
              >
                新增角色
              </Button>
            }
          />
          <PageSection>
            <Paper square>
              <DataTable
                data={data?.source ?? []}
                columns={['啟用狀態', '角色名稱', '操作', '']}
                serverSide
                SearchBarProps={{
                  placeholder: '搜尋角色',
                  onChange: handleSearchChange,
                }}
                loading={isValidating}
                isEmpty={response?.status === 204}
                renderColumns={renderColumns}
                renderDataRow={renderDataRow}
                MuiTablePaginationProps={{
                  count: data?.total ?? 0,
                  labelRowsPerPage: '每頁幾筆',
                  page,
                  rowsPerPage,
                  onPageChange: handleChangePage,
                  onRowsPerPageChange: handleRowsPerPageChange,
                }}
              />
            </Paper>
          </PageSection>
        </Container>
      </Main>
    </>
  );
};

export default OrgRole;
