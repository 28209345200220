import React, { forwardRef } from 'react';

export interface CtbcAuthFormProps {
  paymentCode?: string;
}

const CtbcAuthForm = forwardRef<HTMLFormElement, CtbcAuthFormProps>(
  (props, ref) => {
    const { paymentCode } = props;

    return (
      <form
        ref={ref}
        method="POST"
        style={{ display: 'none' }}
        action={
          process.env.NODE_ENV === 'production'
            ? 'https://epos.ctbcbank.com/mauth/SSLAuthUI.jsp'
            : 'https://testepos.ctbcbank.com/mauth/SSLAuthUI.jsp'
        }
      >
        <input
          type="hidden"
          name="merID"
          value={process.env.NODE_ENV === 'production' ? '8892' : '3197'}
        />
        <input type="hidden" name="URLEnc" value={paymentCode} />
      </form>
    );
  }
);

export default CtbcAuthForm;
