import React, { FC } from 'react';

import {
  withReduxDialog,
  openDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { useDispatch, useSelector } from 'react-redux';

import makeFormValueIsEqual from 'utils/makeFormValueIsEqual';
// @ts-ignore
import { submit, formValueSelector } from 'redux-form/immutable';

import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import Button from '@eGroupTeam/material/Button';
import Dialog from '@eGroupTeam/material/Dialog';
import DialogTitle from '@eGroupTeam/material/DialogTitle';
import DialogContent from '@eGroupTeam/material/DialogContent';
import DialogContentText from '@eGroupTeam/material/DialogContentText';
import DialogActions from '@eGroupTeam/material/DialogActions';
import {
  fetchPostActivityApplyPotential,
  getActivityApplyPotentialIsPosting,
} from './redux';
import ActivityApplyPotentialForm, { FORM } from './ActivityApplyPotentialForm';

export const DIALOG = 'activityApplyPotentialDialog';

export const CONFIRM_LEAVE_DIALOG = 'confirmLeaveActivityApplyPotentialDialog';

const ConfirmLeaveDialog = withReduxDialog(CONFIRM_LEAVE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);

const selector = formValueSelector(FORM);

const useFormValueIsEqual = makeFormValueIsEqual(FORM);

export interface ActivityApplyPotentialDialogProps {
  activityId?: string;
}

const ActivityApplyPotentialDialog: FC<
  ActivityApplyPotentialDialogProps & WithReduxDialogProps
> = ({ isOpen, handleClose, activityId }) => {
  const dispatch = useDispatch();
  const isPosting = useSelector(getActivityApplyPotentialIsPosting);
  const isEqual = useFormValueIsEqual();
  const isAgreePrivacy = useSelector((state) =>
    selector(state, 'isAgreePrivacy')
  );

  const closeConform = () => {
    if (!isEqual) {
      dispatch(openDialog(CONFIRM_LEAVE_DIALOG));
    } else {
      handleClose();
    }
  };

  const handleFormSubmit = (values) => {
    const payload = values.update('isAgreePrivacy', Number);
    if (activityId) {
      dispatch(
        fetchPostActivityApplyPotential({
          activityId,
          ...payload.toJS(),
        })
      );
    }
  };

  return (
    <>
      <ConfirmLeaveDialog
        primary="資料變更"
        message="你有未儲存的變更，確定要離開嗎？"
        onConfirm={handleClose}
      />
      <Dialog open={isOpen} onClose={closeConform}>
        <DialogTitle>聯絡主辦單位</DialogTitle>
        <DialogContent>
          <DialogContentText>
            請留下您的資訊告知主辦單位您的需求...
          </DialogContentText>
          <ActivityApplyPotentialForm onSubmit={handleFormSubmit} />
        </DialogContent>
        <DialogActions>
          <Button
            loading={isPosting}
            disableElevation
            color="primary"
            variant="contained"
            onClick={() => dispatch(submit(FORM))}
            disabled={!isAgreePrivacy || isPosting}
          >
            送出
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withReduxDialog(DIALOG)<unknown, ActivityApplyPotentialDialogProps>(
  ActivityApplyPotentialDialog
);
