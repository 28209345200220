import React from 'react';
import { getSelectedOrg } from 'redux/modules/common';
import Button from '@eGroupTeam/material/Button';
import {
  CircularProgress,
  makeStyles,
  Button as MuiButton,
  Segment,
  SegmentContent,
} from '@eGroupTeam/material';
import { useDispatch, useSelector } from 'react-redux';
import Center from '@eGroupTeam/material-layout/Center';
import { useHistory } from 'react-router-dom';
import MicepointTrialFeesDisplay from 'components/MicepointTrialFeesDisplay';
import useActivityTrailFee from 'utils/useActivityTrailFee';
import { handlePrevStep } from './actions';

const useStyles = makeStyles((theme) => ({
  buyBtn: {
    marginTop: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    margin: -theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  btn: {
    margin: theme.spacing(0.5),
  },
}));

const StepGetTrialFee = ({ loading, onNextClick, value }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const handlePrev = () => {
    dispatch(handlePrevStep());
  };

  const {
    activityClassList,
    activityType,
    paymentOrder,
    peopleChangeCount,
    countPeopleByDB,
    activityId,
  } = value || {};

  const { data, isValidating } = useActivityTrailFee(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    {
      activityClassList,
      activityType,
      paymentOrder,
    },
    {
      activityId,
      serviceProductIsFix: 1,
      peopleChangeCount,
      countPeopleByDB,
    }
  );

  const renderActions = () => {
    if (!data) return undefined;
    if (data.isFree) {
      return (
        <Button
          fullWidth
          className={classes.btn}
          variant="contained"
          size="large"
          disableElevation
          color="primary"
          loading={loading}
          onClick={onNextClick}
        >
          完成
        </Button>
      );
    }
    if (data.isEnough) {
      return (
        <Button
          fullWidth
          className={classes.btn}
          variant="contained"
          size="large"
          disableElevation
          color="primary"
          loading={loading}
          onClick={onNextClick}
        >
          付款
        </Button>
      );
    }
    return (
      <MuiButton
        fullWidth
        className={classes.btn}
        variant="contained"
        size="large"
        disableElevation
        color="primary"
        onClick={() => {
          history.push('/me/micepoint/shop');
          window.localStorage.setItem('isCreatingActivity', 'true');
        }}
      >
        前往購買 MICEPoint
      </MuiButton>
    );
  };

  if (isValidating || !data) {
    return (
      <Center height={200}>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Segment variant="outlined">
      <SegmentContent>
        <MicepointTrialFeesDisplay data={data} />
        <div className={classes.actions}>
          <Button
            fullWidth
            className={classes.btn}
            variant="contained"
            size="large"
            disableElevation
            disabled={loading}
            onClick={handlePrev}
          >
            上一步
          </Button>
          {renderActions()}
        </div>
      </SegmentContent>
    </Segment>
  );
};

export default StepGetTrialFee;
