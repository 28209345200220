import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchPostVerifySurveyQualify,
  fetchPostVerifySurveyQualifyRequest,
  fetchPostVerifySurveyQualifySuccess,
  fetchPostVerifySurveyQualifyFailure,
} = actionCreators.landingPages.phoneFillSurvey;

/** Types */
export const FETCH_POST_VERIFY_SURVEY_QUALIFY = fetchPostVerifySurveyQualify()
  .type;
export const FETCH_POST_VERIFY_SURVEY_QUALIFY_REQUEST = fetchPostVerifySurveyQualifyRequest()
  .type;
export const FETCH_POST_VERIFY_SURVEY_QUALIFY_SUCCESS = fetchPostVerifySurveyQualifySuccess()
  .type;
export const FETCH_POST_VERIFY_SURVEY_QUALIFY_FAILURE = fetchPostVerifySurveyQualifyFailure()
  .type;

/** Selectors */
const getApiStates = (state) =>
  state.getIn(['apis', 'landingPages', 'phoneFillSurvey'], Map());

const getFetchPostVerifySurveyQualify = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostVerifySurveyQualify', Map())
);

export const getIsLoading = createSelector(
  getFetchPostVerifySurveyQualify,
  (fetchResult) => fetchResult.get('isLoading')
);
