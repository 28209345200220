import React, { FC, useState } from 'react';
import apis from 'redux/apisOrg';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Segment,
  SegmentContent,
  SegmentHeader,
  Typography,
} from '@eGroupTeam/material';
import TrialFeeDialog, { DIALOG, Payload } from 'components/TrialFeeDialog';
import {
  closeDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  openSnackbar,
  setSnackbarData,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import Center from '@eGroupTeam/material-layout/Center';
import { Activity, ServiceProduct } from 'types';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import { getSelectedOrg } from 'redux/modules/common';
import { SNACKBAR } from 'App';
import useActivityServiceProducts from 'utils/useActivityServiceProducts';
import { SWRResponse } from 'swr';
import ServiceItem from './ServiceItem';

export interface ActivitySettingsProps {
  activity: Activity;
  mutateActivity: SWRResponse<unknown, unknown>['mutate'];
}

const ActivitySettings: FC<ActivitySettingsProps> = ({
  activity,
  mutateActivity,
}) => {
  const selectedOrg = useSelector(getSelectedOrg);
  const dispatch = useDispatch();
  const { data, mutate } = useActivityServiceProducts({
    organizationId: selectedOrg.get('organizationId'),
    activityId: activity.activityId,
  });
  const [payload, setPayload] = useState<Payload>();
  const [
    selectedServiceProduct,
    setSelectedServiceProduct,
  ] = useState<ServiceProduct>();
  const {
    excute: updateActivityServiceProducts,
    isLoading,
  } = useAxiosApiWrapper(apis.fetchPatchActivityServiceProducts);

  const handleProductClick = (serviceProduct: ServiceProduct) => () => {
    dispatch(openDialog(DIALOG));
    setSelectedServiceProduct(serviceProduct);
    setPayload({
      countPeopleByDB: 1,
      activityId: activity.activityId,
      paymentOrder: {
        paymentProductList: [
          {
            serviceProductId: serviceProduct.serviceProductId,
          },
        ],
      },
    });
  };

  const handleConfirm = () => {
    if (selectedServiceProduct) {
      updateActivityServiceProducts({
        activityId: activity.activityId,
        organizationId: selectedOrg.get('organizationId'),
        paymentOrder: {
          paymentProductList: [
            {
              serviceProductId: selectedServiceProduct.serviceProductId,
            },
          ],
        },
      }).then(() => {
        dispatch(closeDialog(DIALOG));
        dispatch(
          setSnackbarData({
            name: SNACKBAR,
            message: '啟用成功！',
            variant: 'success',
          })
        );
        dispatch(openSnackbar(SNACKBAR));
        mutate();
        mutateActivity();
      });
    }
  };
  const renderContent = () => {
    if (!data) {
      return (
        <Center height={200}>
          <CircularProgress />
        </Center>
      );
    }
    return data
      ?.filter((el) => el.serviceProductIsFix !== 1)
      .map((el) => (
        <ServiceItem
          key={el.serviceProductId}
          title={el.serviceProductNameZh}
          subTitle={el.serviceProductNameZh}
          description={el.serviceProductDescriptionZh}
          priceDiscount={el.serviceProductUnitPriceZh}
          priceOriginal={el.serviceProductListPriceZh}
          priceUnit={el.serviceProductUnitZh}
          actions={
            <Button
              onClick={handleProductClick(el)}
              disabled={el.paymentProductStatus === 2}
              variant="contained"
              rounded
              color="info"
            >
              {el.paymentProductStatus === 2 ? '已啟用' : '啟用'}
            </Button>
          }
        />
      ))
  };

  return (
    <>
      <TrialFeeDialog
        payload={payload}
        onConfirmClick={handleConfirm}
        loading={isLoading}
      />
      <Container>
        <Segment>
          <SegmentHeader>
            <Typography variant="h5" fontWeight={400}>
              活動設定
            </Typography>
            <Typography variant="body1">
              您可以設定您的活動相關功能。
            </Typography>
          </SegmentHeader>
          <SegmentContent>
            <Typography variant="h5" fontWeight={400}>
              可以加購的服務
            </Typography>
            <Box py={3}>
              <Grid container spacing={3}>
                {renderContent()}
              </Grid>
            </Box>
          </SegmentContent>
        </Segment>
      </Container>
    </>
  );
};

export default ActivitySettings;
