import React, { FC, HTMLAttributes } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    padding: theme.spacing(0.5, 1),
    border: `solid 1px ${theme.egPalette.text[4]}`
  },
  success: {
    borderColor: theme.egPalette.success[1],
    color: theme.egPalette.success[1],
  },
  error: {
    borderColor: theme.egPalette.error[1],
    color: theme.egPalette.error[1],
  }
}))

export interface PaymentStatusTagProps extends HTMLAttributes<HTMLDivElement>{
  success?: boolean
  error?: boolean
}

const PaymentStatusTag: FC<PaymentStatusTagProps> = ({
  className,
  success,
  error,
  ...other
}) => {
  const classes = useStyles()
  return (
    <div className={clsx(
      className,
      classes.root,
      success && classes.success,
      error && classes.error
    )} {...other} />
  )
}

export default PaymentStatusTag
