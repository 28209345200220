import React from 'react';

import { renderRoutes } from 'react-router-config';

import Footer from 'components/Footer';
import Navbar from "../components/Navbar";

const Root = ({ route }) => (
  <>
    <Navbar />
    {renderRoutes(route.routes)}
    <Footer />
  </>
);

export default Root;
