import React, { FC, useEffect, useState } from 'react';

import QRCode from '@eGroupTeam/material-lab/QRCode';
import { Dialog, DialogContent } from '@eGroupTeam/material';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

export const DIALOG = 'qrCodeDialog';

export interface QrCodeDialogProps {
  value: string;
}

const MAX_SIZE = 512;

const QrCodeDialog: FC<QrCodeDialogProps & WithReduxDialogProps> = ({
  isOpen,
  handleClose,
  value,
}) => {
  const [displaySize, setDisplaySize] = useState(window.innerWidth * 0.7);
  const size = displaySize <= MAX_SIZE ? displaySize : MAX_SIZE;
  const [dataUrl, setDataUrl] = useState('');

  useEffect(() => {
    const update = () => {
      setDisplaySize(window.innerWidth * 0.7);
    };
    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('resize', update);
    };
  }, []);

  const refCallback = (canvas: HTMLCanvasElement) => {
    if (canvas) {
      setDataUrl(canvas.toDataURL());
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <a href={dataUrl} download>
          <QRCode ref={refCallback} value={value} size={size} includeMargin />
        </a>
      </DialogContent>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<unknown, QrCodeDialogProps>(QrCodeDialog);
