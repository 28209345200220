import React from 'react';

import { getSelectedOrg } from 'redux/modules/common';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { UrlParams } from 'types';

import Main from '@eGroupTeam/material-layout/Main';
import Center from '@eGroupTeam/material-layout/Center';
import {
  makeStyles,
  Container,
  Paper,
  CircularProgress,
  Typography,
  Box,
  Button,
  Icomoon,
} from '@eGroupTeam/material';
import PaymentProductList from 'components/PaymentProductList';
import OrderInfo from 'components/OrderInfo';
import useOrgOrder from 'utils/useOrgOrder';
import OrderTotalAmount from 'components/OrderTotalAmount';
import BackAppbar from '@eGroupTeam/material-module/BackAppbar';
import ScrollableTypography from 'components/ScrollableTypography';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(1, 0),
    border: "1px solid #C0C3CE",
    boxShadow: "0 3px 16px rgba(10, 75, 109, 0.08)",
    borderRadius: "8px 8px 0 0",
  },
  header: {
    padding: theme.spacing(3),
    "& h3": {
      fontSize: "1.75rem",
      lineHeight: "1.2",
    },
  },
}));

const Order = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const selectedOrg = useSelector(getSelectedOrg);
  const { paymentOrderId } = useParams<UrlParams>();
  const organizationId = selectedOrg.get('organizationId');
  const { data } = useOrgOrder({
    organizationId,
    paymentOrderId,
  });

  if (!data) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <>
      <BackAppbar
        push={history.push}
        go={history.go}
        pointerTrigger={location}
        position="fixed"
        elevation={0}
        backPath="/me/micepoint/orders"
      >
        <ScrollableTypography variant="h6">
          訂單編號-{data.paymentOrderId}
        </ScrollableTypography>
      </BackAppbar>
      <Main>
        <Container>
          {(data.paymentOrderStatus === 1 || data.paymentOrderStatus === 4) && (
            <Button
              rounded
              variant="contained"
              startIcon={
                <Icomoon type="check" />
              }
              color="info"
              size="large"
              onClick={() => {
                history.push(
                  `/me/checkout?organizationId=${organizationId}&paymentOrderId=${data.paymentOrderId}`
                );
              }}
            >
              前往付款
            </Button>
          )}
          <Paper className={classes.paper} square elevation={2}>
            <Box className={classes.header}>
              <OrderInfo data={data} />
            </Box>
          </Paper>
          <Paper className={classes.paper}>
            <Box className={classes.header}>
              <Typography variant="h3" fontWeight={400}>
                訂單內容
              </Typography>
            </Box>
            <Box>
              <PaymentProductList
                paymentOrderType={data.paymentOrderType}
                data={data.paymentProductList}
              />
              <OrderTotalAmount
                paymentOrderType={data.paymentOrderType}
                amount={data.paymentOrderTotalAmount}
              />
            </Box>
          </Paper>
        </Container>
      </Main>
    </>
  );
};

export default Order;
