import { createActions } from 'redux-actions';

const actionMap = {
  COMPONENTS: {
    LOGIN_DIALOG: {
      FETCH_GET_FACEBOOK_LOGIN_URL: undefined,
      FETCH_GET_FACEBOOK_LOGIN_URL_REQUEST: undefined,
      FETCH_GET_FACEBOOK_LOGIN_URL_SUCCESS: undefined,
      FETCH_GET_FACEBOOK_LOGIN_URL_FAILURE: undefined,

      FETCH_GET_GOOGLE_LOGIN_URL: undefined,
      FETCH_GET_GOOGLE_LOGIN_URL_REQUEST: undefined,
      FETCH_GET_GOOGLE_LOGIN_URL_SUCCESS: undefined,
      FETCH_GET_GOOGLE_LOGIN_URL_FAILURE: undefined,
    },
  },
  QRPASS_PAGES: {
    ORDER: {
      FETCH_GET_ORDER: undefined,
      FETCH_GET_ORDER_REQUEST: undefined,
      FETCH_GET_ORDER_SUCCESS: undefined,
      FETCH_GET_ORDER_FAILURE: undefined,
    },
    ORDERS: {
      FETCH_GET_ORDERS: undefined,
      FETCH_GET_ORDERS_REQUEST: undefined,
      FETCH_GET_ORDERS_SUCCESS: undefined,
      FETCH_GET_ORDERS_FAILURE: undefined,
    },
    PRODUCTS: {
      FETCH_POST_ORDER: undefined,
      FETCH_POST_ORDER_REQUEST: undefined,
      FETCH_POST_ORDER_SUCCESS: undefined,
      FETCH_POST_ORDER_FAILURE: undefined,
    },
    HOME: {
      FETCH_POST_QRPASS_RECORD: undefined,
      FETCH_POST_QRPASS_RECORD_REQUEST: undefined,
      FETCH_POST_QRPASS_RECORD_SUCCESS: undefined,
      FETCH_POST_QRPASS_RECORD_FAILURE: undefined,

      FETCH_GET_QRPASS_SETTINGS: undefined,
      FETCH_GET_QRPASS_SETTINGS_REQUEST: undefined,
      FETCH_GET_QRPASS_SETTINGS_SUCCESS: undefined,
      FETCH_GET_QRPASS_SETTINGS_FAILURE: undefined,

      FETCH_GET_TAGS: undefined,
      FETCH_GET_TAGS_REQUEST: undefined,
      FETCH_GET_TAGS_SUCCESS: undefined,
      FETCH_GET_TAGS_FAILURE: undefined,

      SET_CODE_DATA: undefined,
      SET_ERROR_MSG: undefined,
      SET_HELPER_TEXT: undefined,
      SET_TAG_NAMES: undefined,
    },
    RECORDS: {
      FETCH_GET_QRPASS_RECORDS: undefined,
      FETCH_GET_QRPASS_RECORDS_REQUEST: undefined,
      FETCH_GET_QRPASS_RECORDS_SUCCESS: undefined,
      FETCH_GET_QRPASS_RECORDS_FAILURE: undefined,

      FETCH_POST_QRPASS_RECORDS_TO_END_POINT: undefined,
      FETCH_POST_QRPASS_RECORDS_TO_END_POINT_REQUEST: undefined,
      FETCH_POST_QRPASS_RECORDS_TO_END_POINT_SUCCESS: undefined,
      FETCH_POST_QRPASS_RECORDS_TO_END_POINT_FAILURE: undefined,
    },
    RECORD: {
      FETCH_GET_QRPASS_RECORD: undefined,
      FETCH_GET_QRPASS_RECORD_REQUEST: undefined,
      FETCH_GET_QRPASS_RECORD_SUCCESS: undefined,
      FETCH_GET_QRPASS_RECORD_FAILURE: undefined,
    },
    ROOT: {
      FETCH_POST_ORGANIZATION: undefined,
      FETCH_POST_ORGANIZATION_REQUEST: undefined,
      FETCH_POST_ORGANIZATION_SUCCESS: undefined,
      FETCH_POST_ORGANIZATION_FAILURE: undefined,
    },
    SETTINGS: {
      FETCH_GET_QRPASS_SETTINGS: undefined,
      FETCH_GET_QRPASS_SETTINGS_REQUEST: undefined,
      FETCH_GET_QRPASS_SETTINGS_SUCCESS: undefined,
      FETCH_GET_QRPASS_SETTINGS_FAILURE: undefined,

      FETCH_POST_QRPASS_SETTING: undefined,
      FETCH_POST_QRPASS_SETTING_REQUEST: undefined,
      FETCH_POST_QRPASS_SETTING_SUCCESS: undefined,
      FETCH_POST_QRPASS_SETTING_FAILURE: undefined,

      FETCH_PATCH_QRPASS_SETTING: undefined,
      FETCH_PATCH_QRPASS_SETTING_REQUEST: undefined,
      FETCH_PATCH_QRPASS_SETTING_SUCCESS: undefined,
      FETCH_PATCH_QRPASS_SETTING_FAILURE: undefined,
    },
    TAGS: {
      FETCH_GET_TAGS: undefined,
      FETCH_GET_TAGS_REQUEST: undefined,
      FETCH_GET_TAGS_SUCCESS: undefined,
      FETCH_GET_TAGS_FAILURE: undefined,

      FETCH_GET_TAG: undefined,
      FETCH_GET_TAG_REQUEST: undefined,
      FETCH_GET_TAG_SUCCESS: undefined,
      FETCH_GET_TAG_FAILURE: undefined,

      FETCH_POST_TAG: undefined,
      FETCH_POST_TAG_REQUEST: undefined,
      FETCH_POST_TAG_SUCCESS: undefined,
      FETCH_POST_TAG_FAILURE: undefined,

      FETCH_PATCH_TAG: undefined,
      FETCH_PATCH_TAG_REQUEST: undefined,
      FETCH_PATCH_TAG_SUCCESS: undefined,
      FETCH_PATCH_TAG_FAILURE: undefined,

      FETCH_DELETE_TAG: undefined,
      FETCH_DELETE_TAG_REQUEST: undefined,
      FETCH_DELETE_TAG_SUCCESS: undefined,
      FETCH_DELETE_TAG_FAILURE: undefined,
    },
  },
  PROFILE_PAGES: {
    ACCOUNT: {
      FETCH_POST_CARRIER_MEMBER_VAN: undefined,
      FETCH_POST_CARRIER_MEMBER_VAN_REQUEST: undefined,
      FETCH_POST_CARRIER_MEMBER_VAN_SUCCESS: undefined,
      FETCH_POST_CARRIER_MEMBER_VAN_FAILURE: undefined,
    },
    CARRIER: {
      FETCH_POST_CARRIER_CONSUMER: undefined,
      FETCH_POST_CARRIER_CONSUMER_REQUEST: undefined,
      FETCH_POST_CARRIER_CONSUMER_SUCCESS: undefined,
      FETCH_POST_CARRIER_CONSUMER_FAILURE: undefined,
    },
  },
  MAIN_PAGES: {
    ACTIVITY: {
      ACTIVITY_DASHBOARD: {
        FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_REQUEST: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_FAILURE: undefined,

        FETCH_GET_USER_APPLY_DAILY_COUNTS: undefined,
        FETCH_GET_USER_APPLY_DAILY_COUNTS_REQUEST: undefined,
        FETCH_GET_USER_APPLY_DAILY_COUNTS_SUCCESS: undefined,
        FETCH_GET_USER_APPLY_DAILY_COUNTS_FAILURE: undefined,

        FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS: undefined,
        FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS_REQUEST: undefined,
        FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS_SUCCESS: undefined,
        FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS_FAILURE: undefined,

        FETCH_GET_CONDITIONS: undefined,
        FETCH_GET_CONDITIONS_REQUEST: undefined,
        FETCH_GET_CONDITIONS_SUCCESS: undefined,
        FETCH_GET_CONDITIONS_FAILURE: undefined,

        FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES_REQUEST: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES_FAILURE: undefined,
      },
      ACTIVITY_EDIT: {
        FETCH_PATCH_ACTIVITY: undefined,
        FETCH_PATCH_ACTIVITY_REQUEST: undefined,
        FETCH_PATCH_ACTIVITY_SUCCESS: undefined,
        FETCH_PATCH_ACTIVITY_FAILURE: undefined,
      },
      ACTIVITY_FIELDS: {
        FETCH_GET_ACTIVITY_APPLY_FIELDS: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELDS_REQUEST: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELDS_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELDS_FAILURE: undefined,

        FETCH_GET_ACTIVITY_APPLY_FIELD: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_REQUEST: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_FAILURE: undefined,

        FETCH_POST_ACTIVITY_APPLY_FIELD: undefined,
        FETCH_POST_ACTIVITY_APPLY_FIELD_REQUEST: undefined,
        FETCH_POST_ACTIVITY_APPLY_FIELD_SUCCESS: undefined,
        FETCH_POST_ACTIVITY_APPLY_FIELD_FAILURE: undefined,

        FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER_REQUEST: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER_SUCCESS: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER_FAILURE: undefined,

        FETCH_PATCH_ACTIVITY_APPLY_FIELD: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_REQUEST: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_SUCCESS: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_FAILURE: undefined,

        FETCH_DELETE_ACTIVITY_APPLY_FIELD: undefined,
        FETCH_DELETE_ACTIVITY_APPLY_FIELD_REQUEST: undefined,
        FETCH_DELETE_ACTIVITY_APPLY_FIELD_SUCCESS: undefined,
        FETCH_DELETE_ACTIVITY_APPLY_FIELD_FAILURE: undefined,

        FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION: undefined,
        FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION_REQUEST: undefined,
        FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION_SUCCESS: undefined,
        FETCH_POST_ACTIVITY_APPLY_FIELD_OPTION_FAILURE: undefined,

        FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER_REQUEST: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER_SUCCESS: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTIONS_ORDER_FAILURE: undefined,

        FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION_REQUEST: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION_SUCCESS: undefined,
        FETCH_PATCH_ACTIVITY_APPLY_FIELD_OPTION_FAILURE: undefined,

        FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION: undefined,
        FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION_REQUEST: undefined,
        FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION_SUCCESS: undefined,
        FETCH_DELETE_ACTIVITY_APPLY_FIELD_OPTION_FAILURE: undefined,
      },
      ACTIVITY_FILES: {
        FETCH_GET_ACTIVITY_FILES: undefined,
        FETCH_GET_ACTIVITY_FILES_REQUEST: undefined,
        FETCH_GET_ACTIVITY_FILES_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_FILES_FAILURE: undefined,

        FETCH_DELETE_ACTIVITY_FILE: undefined,
        FETCH_DELETE_ACTIVITY_FILE_REQUEST: undefined,
        FETCH_DELETE_ACTIVITY_FILE_SUCCESS: undefined,
        FETCH_DELETE_ACTIVITY_FILE_FAILURE: undefined,
      },
      ACTIVITY_NOTIFICATION: {
        FETCH_GET_ACTIVITY_EDM: undefined,
        FETCH_GET_ACTIVITY_EDM_REQUEST: undefined,
        FETCH_GET_ACTIVITY_EDM_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_EDM_FAILURE: undefined,

        FETCH_GET_ACTIVITY_EDM_USERS: undefined,
        FETCH_GET_ACTIVITY_EDM_USERS_REQUEST: undefined,
        FETCH_GET_ACTIVITY_EDM_USERS_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_EDM_USERS_FAILURE: undefined,

        FETCH_POST_NOTIFICATION_EMAIL: undefined,
        FETCH_POST_NOTIFICATION_EMAIL_REQUEST: undefined,
        FETCH_POST_NOTIFICATION_EMAIL_SUCCESS: undefined,
        FETCH_POST_NOTIFICATION_EMAIL_FAILURE: undefined,

        FETCH_POST_TEST_NOTIFICATION_EMAIL: undefined,
        FETCH_POST_TEST_NOTIFICATION_EMAIL_REQUEST: undefined,
        FETCH_POST_TEST_NOTIFICATION_EMAIL_SUCCESS: undefined,
        FETCH_POST_TEST_NOTIFICATION_EMAIL_FAILURE: undefined,

        FETCH_GET_ORGANIZATION_EDM_TEMPLATES: undefined,
        FETCH_GET_ORGANIZATION_EDM_TEMPLATES_REQUEST: undefined,
        FETCH_GET_ORGANIZATION_EDM_TEMPLATES_SUCCESS: undefined,
        FETCH_GET_ORGANIZATION_EDM_TEMPLATES_FAILURE: undefined,

        FETCH_POST_ORGANIZATION_EDM_TEMPLATE: undefined,
        FETCH_POST_ORGANIZATION_EDM_TEMPLATE_REQUEST: undefined,
        FETCH_POST_ORGANIZATION_EDM_TEMPLATE_SUCCESS: undefined,
        FETCH_POST_ORGANIZATION_EDM_TEMPLATE_FAILURE: undefined,

        FETCH_POST_PREVIEW_EDM: undefined,
        FETCH_POST_PREVIEW_EDM_REQUEST: undefined,
        FETCH_POST_PREVIEW_EDM_SUCCESS: undefined,
        FETCH_POST_PREVIEW_EDM_FAILURE: undefined,

        SET_ACTIVE_STEP: undefined,
        HANDLE_NEXT: undefined,
        HANDLE_PREV: undefined,
      },
      ACTIVITY_CHECK_IN: {
        FETCH_GET_USER_APPLY_TOTAL_COUNTS: undefined,
        FETCH_GET_USER_APPLY_TOTAL_COUNTS_REQUEST: undefined,
        FETCH_GET_USER_APPLY_TOTAL_COUNTS_SUCCESS: undefined,
        FETCH_GET_USER_APPLY_TOTAL_COUNTS_FAILURE: undefined,

        FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_REQUEST: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_FAILURE: undefined,

        FETCH_GET_USER_APPLIES_FOR_SELECT: undefined,
        FETCH_GET_USER_APPLIES_FOR_SELECT_REQUEST: undefined,
        FETCH_GET_USER_APPLIES_FOR_SELECT_SUCCESS: undefined,
        FETCH_GET_USER_APPLIES_FOR_SELECT_FAILURE: undefined,

        FETCH_POST_ACTIVITY_USER_APPLY_DAYS: undefined,
        FETCH_POST_ACTIVITY_USER_APPLY_DAYS_REQUEST: undefined,
        FETCH_POST_ACTIVITY_USER_APPLY_DAYS_SUCCESS: undefined,
        FETCH_POST_ACTIVITY_USER_APPLY_DAYS_FAILURE: undefined,

        FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE: undefined,
        FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE_REQUEST: undefined,
        FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE_SUCCESS: undefined,
        FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE_FAILURE: undefined,

        FETCH_POST_ACTIVITY_RECOGNIZE_FACE: undefined,
        FETCH_POST_ACTIVITY_RECOGNIZE_FACE_REQUEST: undefined,
        FETCH_POST_ACTIVITY_RECOGNIZE_FACE_SUCCESS: undefined,
        FETCH_POST_ACTIVITY_RECOGNIZE_FACE_FAILURE: undefined,

        FETCH_GET_USER_APPLY_COUNT_INFO: undefined,

        SET_SELECTED_USER_APPLY: undefined,
        SET_RESULT: undefined,
        SET_RECOGNIZED_RESULT: undefined,
        SET_UNKNOWN_RECOGNIZED_RESULT: undefined,
        EMPTY_RECOGNIZED_RESULT: undefined,
        EMPTY_UNKNOWN_RECOGNIZED_RESULT: undefined,
      },
      ACTIVITY_PREVIEW: {
        FETCH_GET_LP_ACTIVITY: undefined,
        FETCH_GET_LP_ACTIVITY_REQUEST: undefined,
        FETCH_GET_LP_ACTIVITY_SUCCESS: undefined,
        FETCH_GET_LP_ACTIVITY_FAILURE: undefined,
      },
      ACTIVITY_PRODUCTS: {
        FETCH_POST_ORDER: undefined,
        FETCH_POST_ORDER_REQUEST: undefined,
        FETCH_POST_ORDER_SUCCESS: undefined,
        FETCH_POST_ORDER_FAILURE: undefined,
      },
      ACTIVITY_CERTIFICATES: {
        FETCH_GET_ACTIVITY_STUDY_PROVE: undefined,
        FETCH_GET_ACTIVITY_STUDY_PROVE_REQUEST: undefined,
        FETCH_GET_ACTIVITY_STUDY_PROVE_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_STUDY_PROVE_FAILURE: undefined,

        FETCH_PATCH_ACTIVITY_STUDY_PROVE: undefined,
        FETCH_PATCH_ACTIVITY_STUDY_PROVE_REQUEST: undefined,
        FETCH_PATCH_ACTIVITY_STUDY_PROVE_SUCCESS: undefined,
        FETCH_PATCH_ACTIVITY_STUDY_PROVE_FAILURE: undefined,

        FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR: undefined,
        FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR_REQUEST: undefined,
        FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR_SUCCESS: undefined,
        FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR_FAILURE: undefined,

        FETCH_GET_ACTIVITY_EDM_USERS: undefined,
        FETCH_GET_ACTIVITY_EDM_USERS_REQUEST: undefined,
        FETCH_GET_ACTIVITY_EDM_USERS_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_EDM_USERS_FAILURE: undefined,

        FETCH_POST_NOTIFICATION_EMAIL: undefined,
        FETCH_POST_NOTIFICATION_EMAIL_REQUEST: undefined,
        FETCH_POST_NOTIFICATION_EMAIL_SUCCESS: undefined,
        FETCH_POST_NOTIFICATION_EMAIL_FAILURE: undefined,

        FETCH_POST_TEST_NOTIFICATION_EMAIL: undefined,
        FETCH_POST_TEST_NOTIFICATION_EMAIL_REQUEST: undefined,
        FETCH_POST_TEST_NOTIFICATION_EMAIL_SUCCESS: undefined,
        FETCH_POST_TEST_NOTIFICATION_EMAIL_FAILURE: undefined,

        FETCH_POST_PREVIEW_EDM: undefined,
        FETCH_POST_PREVIEW_EDM_REQUEST: undefined,
        FETCH_POST_PREVIEW_EDM_SUCCESS: undefined,
        FETCH_POST_PREVIEW_EDM_FAILURE: undefined,

        SET_DIRECT_NOTIFICATION_ACTIVE_STEP: undefined,
        HANDLE_DIRECT_NOTIFICATION_NEXT: undefined,
        HANDLE_DIRECT_NOTIFICATION_PREV: undefined,
      },
      ACTIVITY_SURVEYS: {
        FETCH_GET_SURVEYS: undefined,
        FETCH_GET_SURVEYS_REQUEST: undefined,
        FETCH_GET_SURVEYS_SUCCESS: undefined,
        FETCH_GET_SURVEYS_FAILURE: undefined,

        FETCH_POST_SURVEY: undefined,
        FETCH_POST_SURVEY_REQUEST: undefined,
        FETCH_POST_SURVEY_SUCCESS: undefined,
        FETCH_POST_SURVEY_FAILURE: undefined,
      },
      ACTIVITY_USER_APPLIES: {
        FETCH_PATCH_USER_ACTIVITY_APPLY_STATUS: undefined,
        FETCH_PATCH_USER_ACTIVITY_APPLY_STATUS_REQUEST: undefined,
        FETCH_PATCH_USER_ACTIVITY_APPLY_STATUS_SUCCESS: undefined,
        FETCH_PATCH_USER_ACTIVITY_APPLY_STATUS_FAILURE: undefined,

        FETCH_GET_USER_APPLY: undefined,
        FETCH_GET_USER_APPLY_REQUEST: undefined,
        FETCH_GET_USER_APPLY_SUCCESS: undefined,
        FETCH_GET_USER_APPLY_FAILURE: undefined,

        FETCH_PATCH_USER_APPLY: undefined,
        FETCH_PATCH_USER_APPLY_REQUEST: undefined,
        FETCH_PATCH_USER_APPLY_SUCCESS: undefined,
        FETCH_PATCH_USER_APPLY_FAILURE: undefined,

        FETCH_POST_USER_APPLY_RETRAIN_EMAIL: undefined,
        FETCH_POST_USER_APPLY_RETRAIN_EMAIL_REQUEST: undefined,
        FETCH_POST_USER_APPLY_RETRAIN_EMAIL_SUCCESS: undefined,
        FETCH_POST_USER_APPLY_RETRAIN_EMAIL_FAILURE: undefined,
      },
      FETCH_GET_ACTIVITY: undefined,
      FETCH_GET_ACTIVITY_REQUEST: undefined,
      FETCH_GET_ACTIVITY_SUCCESS: undefined,
      FETCH_GET_ACTIVITY_FAILURE: undefined,

      FETCH_PATCH_ACTIVITY_IS_RELEASE: undefined,
      FETCH_PATCH_ACTIVITY_IS_RELEASE_REQUEST: undefined,
      FETCH_PATCH_ACTIVITY_IS_RELEASE_SUCCESS: undefined,
      FETCH_PATCH_ACTIVITY_IS_RELEASE_FAILURE: undefined,

      FETCH_GET_ACTIVITY_CLASSES: undefined,
      FETCH_GET_ACTIVITY_CLASSES_REQUEST: undefined,
      FETCH_GET_ACTIVITY_CLASSES_SUCCESS: undefined,
      FETCH_GET_ACTIVITY_CLASSES_FAILURE: undefined,

      FETCH_GET_USER_APPLIES: undefined,
      FETCH_GET_USER_APPLIES_REQUEST: undefined,
      FETCH_GET_USER_APPLIES_SUCCESS: undefined,
      FETCH_GET_USER_APPLIES_FAILURE: undefined,

      FETCH_GET_ORDERS: undefined,
      FETCH_GET_ORDERS_REQUEST: undefined,
      FETCH_GET_ORDERS_SUCCESS: undefined,
      FETCH_GET_ORDERS_FAILURE: undefined,

      SET_SELECTED_ACTIVITY_CLASS_NAME: undefined,
      SET_SELECTED_ACTIVITY_CLASS_ID: undefined,
      HANDLE_TUTORIAL_DRAWER_OPEN: undefined,
      HANDLE_TUTORIAL_DRAWER_CLOSE: undefined,
      RESET: undefined,
    },
    CHECKOUT: {
      FETCH_GET_ORDER: undefined,
      FETCH_GET_ORDER_REQUEST: undefined,
      FETCH_GET_ORDER_SUCCESS: undefined,
      FETCH_GET_ORDER_FAILURE: undefined,

      FETCH_POST_PAYMENT: undefined,
      FETCH_POST_PAYMENT_REQUEST: undefined,
      FETCH_POST_PAYMENT_SUCCESS: undefined,
      FETCH_POST_PAYMENT_FAILURE: undefined,
    },
    NEW_ORGANIZATION: {
      FETCH_POST_ORGANIZATION: undefined,
      FETCH_POST_ORGANIZATION_REQUEST: undefined,
      FETCH_POST_ORGANIZATION_SUCCESS: undefined,
      FETCH_POST_ORGANIZATION_FAILURE: undefined,
    },
    USERS: {
      USERS_ACTIVITY_IMPORT: {
        FETCH_POST_ACTIVITY_USER_TEMP: undefined,
        FETCH_POST_ACTIVITY_USER_TEMP_REQUEST: undefined,
        FETCH_POST_ACTIVITY_USER_TEMP_SUCCESS: undefined,
        FETCH_POST_ACTIVITY_USER_TEMP_FAILURE: undefined,
      },
      FETCH_GET_USERS: undefined,
      FETCH_GET_USERS_REQUEST: undefined,
      FETCH_GET_USERS_SUCCESS: undefined,
      FETCH_GET_USERS_FAILURE: undefined,

      FETCH_GET_USER: undefined,
      FETCH_GET_USER_REQUEST: undefined,
      FETCH_GET_USER_SUCCESS: undefined,
      FETCH_GET_USER_FAILURE: undefined,

      FETCH_POST_USER: undefined,
      FETCH_POST_USER_REQUEST: undefined,
      FETCH_POST_USER_SUCCESS: undefined,
      FETCH_POST_USER_FAILURE: undefined,

      FETCH_PATCH_USER: undefined,
      FETCH_PATCH_USER_REQUEST: undefined,
      FETCH_PATCH_USER_SUCCESS: undefined,
      FETCH_PATCH_USER_FAILURE: undefined,

      FETCH_POST_USERS_BY_EXCEL: undefined,
      FETCH_POST_USERS_BY_EXCEL_REQUEST: undefined,
      FETCH_POST_USERS_BY_EXCEL_SUCCESS: undefined,
      FETCH_POST_USERS_BY_EXCEL_FAILURE: undefined,

      FETCH_GET_USER_ACTIVITIES: undefined,
      FETCH_GET_USER_ACTIVITIES_REQUEST: undefined,
      FETCH_GET_USER_ACTIVITIES_SUCCESS: undefined,
      FETCH_GET_USER_ACTIVITIES_FAILURE: undefined,

      FETCH_POST_USER_ACTIVITIES_BY_EXCEL: undefined,
      FETCH_POST_USER_ACTIVITIES_BY_EXCEL_REQUEST: undefined,
      FETCH_POST_USER_ACTIVITIES_BY_EXCEL_SUCCESS: undefined,
      FETCH_POST_USER_ACTIVITIES_BY_EXCEL_FAILURE: undefined,

      FETCH_DELETE_USER: undefined,
      FETCH_DELETE_USER_REQUEST: undefined,
      FETCH_DELETE_USER_SUCCESS: undefined,
      FETCH_DELETE_USER_FAILURE: undefined,
    },
    SURVEY: {
      SURVEY_ANALYSIS: {
        FETCH_GET_SURVEY_RESULT: undefined,
        FETCH_GET_SURVEY_RESULT_REQUEST: undefined,
        FETCH_GET_SURVEY_RESULT_SUCCESS: undefined,
        FETCH_GET_SURVEY_RESULT_FAILURE: undefined,

        FETCH_GET_SURVEYS_RESPONDENT_COUNT: undefined,
        FETCH_GET_SURVEYS_RESPONDENT_COUNT_REQUEST: undefined,
        FETCH_GET_SURVEYS_RESPONDENT_COUNT_SUCCESS: undefined,
        FETCH_GET_SURVEYS_RESPONDENT_COUNT_FAILURE: undefined,
      },
      SURVEY_EDITOR: {
        FETCH_PATCH_SURVEY: undefined,
        FETCH_PATCH_SURVEY_REQUEST: undefined,
        FETCH_PATCH_SURVEY_SUCCESS: undefined,
        FETCH_PATCH_SURVEY_FAILURE: undefined,

        FETCH_GET_SURVEY_QUESTIONS: undefined,
        FETCH_GET_SURVEY_QUESTIONS_REQUEST: undefined,
        FETCH_GET_SURVEY_QUESTIONS_SUCCESS: undefined,
        FETCH_GET_SURVEY_QUESTIONS_FAILURE: undefined,

        FETCH_POST_SURVEY_QUESTION: undefined,
        FETCH_POST_SURVEY_QUESTION_REQUEST: undefined,
        FETCH_POST_SURVEY_QUESTION_SUCCESS: undefined,
        FETCH_POST_SURVEY_QUESTION_FAILURE: undefined,

        FETCH_PATCH_SURVEY_QUESTION: undefined,
        FETCH_PATCH_SURVEY_QUESTION_REQUEST: undefined,
        FETCH_PATCH_SURVEY_QUESTION_SUCCESS: undefined,
        FETCH_PATCH_SURVEY_QUESTION_FAILURE: undefined,

        FETCH_DELETE_SURVEY_QUESTION: undefined,
        FETCH_DELETE_SURVEY_QUESTION_REQUEST: undefined,
        FETCH_DELETE_SURVEY_QUESTION_SUCCESS: undefined,
        FETCH_DELETE_SURVEY_QUESTION_FAILURE: undefined,

        FETCH_PATCH_SURVEY_QUESTIONS_ORDER: undefined,
        FETCH_PATCH_SURVEY_QUESTIONS_ORDER_REQUEST: undefined,
        FETCH_PATCH_SURVEY_QUESTIONS_ORDER_SUCCESS: undefined,
        FETCH_PATCH_SURVEY_QUESTIONS_ORDER_FAILURE: undefined,

        FETCH_POST_SURVEY_QUESTION_OPTION: undefined,
        FETCH_POST_SURVEY_QUESTION_OPTION_REQUEST: undefined,
        FETCH_POST_SURVEY_QUESTION_OPTION_SUCCESS: undefined,
        FETCH_POST_SURVEY_QUESTION_OPTION_FAILURE: undefined,

        FETCH_PATCH_SURVEY_QUESTION_OPTION: undefined,
        FETCH_PATCH_SURVEY_QUESTION_OPTION_REQUEST: undefined,
        FETCH_PATCH_SURVEY_QUESTION_OPTION_SUCCESS: undefined,
        FETCH_PATCH_SURVEY_QUESTION_OPTION_FAILURE: undefined,

        FETCH_DELETE_SURVEY_QUESTION_OPTION: undefined,
        FETCH_DELETE_SURVEY_QUESTION_OPTION_REQUEST: undefined,
        FETCH_DELETE_SURVEY_QUESTION_OPTION_SUCCESS: undefined,
        FETCH_DELETE_SURVEY_QUESTION_OPTION_FAILURE: undefined,

        FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER: undefined,
        FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER_REQUEST: undefined,
        FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER_SUCCESS: undefined,
        FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER_FAILURE: undefined,

        FETCH_GET_SURVEY_QUESTION: undefined,
        FETCH_GET_SURVEY_QUESTION_REQUEST: undefined,
        FETCH_GET_SURVEY_QUESTION_SUCCESS: undefined,
        FETCH_GET_SURVEY_QUESTION_FAILURE: undefined,
      },
      FETCH_GET_SURVEY: undefined,
      FETCH_GET_SURVEY_REQUEST: undefined,
      FETCH_GET_SURVEY_SUCCESS: undefined,
      FETCH_GET_SURVEY_FAILURE: undefined,
    },
    SURVEYS: {
      FETCH_GET_SURVEYS: undefined,
      FETCH_GET_SURVEYS_REQUEST: undefined,
      FETCH_GET_SURVEYS_SUCCESS: undefined,
      FETCH_GET_SURVEYS_FAILURE: undefined,

      FETCH_POST_SURVEY: undefined,
      FETCH_POST_SURVEY_REQUEST: undefined,
      FETCH_POST_SURVEY_SUCCESS: undefined,
      FETCH_POST_SURVEY_FAILURE: undefined,
    },
    NOTIFICATION: {
      NOTIFICATION_TEMPLATES: {
        FETCH_POST_PREVIEW_EDM: undefined,
        FETCH_POST_PREVIEW_EDM_REQUEST: undefined,
        FETCH_POST_PREVIEW_EDM_SUCCESS: undefined,
        FETCH_POST_PREVIEW_EDM_FAILURE: undefined,

        FETCH_POST_ORGANIZATION_EDM_TEMPLATE: undefined,
        FETCH_POST_ORGANIZATION_EDM_TEMPLATE_REQUEST: undefined,
        FETCH_POST_ORGANIZATION_EDM_TEMPLATE_SUCCESS: undefined,
        FETCH_POST_ORGANIZATION_EDM_TEMPLATE_FAILURE: undefined,

        FETCH_GET_ORGANIZATION_EDM_TEMPLATES: undefined,
        FETCH_GET_ORGANIZATION_EDM_TEMPLATES_REQUEST: undefined,
        FETCH_GET_ORGANIZATION_EDM_TEMPLATES_SUCCESS: undefined,
        FETCH_GET_ORGANIZATION_EDM_TEMPLATES_FAILURE: undefined,

        FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE: undefined,
        FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE_REQUEST: undefined,
        FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE_SUCCESS: undefined,
        FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE_FAILURE: undefined,

        FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE: undefined,
        FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE_REQUEST: undefined,
        FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE_SUCCESS: undefined,
        FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE_FAILURE: undefined,

        FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE: undefined,
        FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE_REQUEST: undefined,
        FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE_SUCCESS: undefined,
        FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE_FAILURE: undefined,
      },
    },
    ROOT: {
      FETCH_POST_ORGANIZATION: undefined,
      FETCH_POST_ORGANIZATION_REQUEST: undefined,
      FETCH_POST_ORGANIZATION_SUCCESS: undefined,
      FETCH_POST_ORGANIZATION_FAILURE: undefined,
    },
    PAYMENT: {
      FETCH_GET_ACTIVITY: undefined,
      FETCH_GET_ACTIVITY_REQUEST: undefined,
      FETCH_GET_ACTIVITY_SUCCESS: undefined,
      FETCH_GET_ACTIVITY_FAILURE: undefined,

      FETCH_GET_ACTIVITY_TRAIL_FEE: undefined,
      FETCH_GET_ACTIVITY_TRAIL_FEE_REQUEST: undefined,
      FETCH_GET_ACTIVITY_TRAIL_FEE_SUCCESS: undefined,
      FETCH_GET_ACTIVITY_TRAIL_FEE_FAILURE: undefined,

      FETCH_POST_ACTIVITY_PAYMENT_CODE: undefined,
      FETCH_POST_ACTIVITY_PAYMENT_CODE_REQUEST: undefined,
      FETCH_POST_ACTIVITY_PAYMENT_CODE_SUCCESS: undefined,
      FETCH_POST_ACTIVITY_PAYMENT_CODE_FAILURE: undefined,

      FETCH_GET_ORDER: undefined,
      FETCH_GET_ORDER_REQUEST: undefined,
      FETCH_GET_ORDER_SUCCESS: undefined,
      FETCH_GET_ORDER_FAILURE: undefined,
    },
    PAYMENT_COMPLETE: {
      FETCH_GET_ORDER: undefined,
      FETCH_GET_ORDER_REQUEST: undefined,
      FETCH_GET_ORDER_SUCCESS: undefined,
      FETCH_GET_ORDER_FAILURE: undefined,
    },
    PAYMENT_FAILED: {
      FETCH_GET_ORDER: undefined,
      FETCH_GET_ORDER_REQUEST: undefined,
      FETCH_GET_ORDER_SUCCESS: undefined,
      FETCH_GET_ORDER_FAILURE: undefined,
    },
  },
  LANDING_PAGES: {
    ACTIVITY: {
      ACTIVITY_APPLY: {
        FETCH_POST_USER_APPLY_ACTIVITY: undefined,
        FETCH_POST_USER_APPLY_ACTIVITY_REQUEST: undefined,
        FETCH_POST_USER_APPLY_ACTIVITY_SUCCESS: undefined,
        FETCH_POST_USER_APPLY_ACTIVITY_FAILURE: undefined,

        FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID: undefined,
        FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID_REQUEST: undefined,
        FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID_SUCCESS: undefined,
        FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID_FAILURE: undefined,

        FETCH_GET_ACTIVITY_SURVEYS: undefined,
        FETCH_GET_ACTIVITY_SURVEYS_REQUEST: undefined,
        FETCH_GET_ACTIVITY_SURVEYS_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_SURVEYS_FAILURE: undefined,

        FETCH_GET_ACTIVITY_CLASSES: undefined,
        FETCH_GET_ACTIVITY_CLASSES_REQUEST: undefined,
        FETCH_GET_ACTIVITY_CLASSES_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_CLASSES_FAILURE: undefined,

        FETCH_GET_ACTIVITY_APPLY_FIELDS: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELDS_REQUEST: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELDS_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_APPLY_FIELDS_FAILURE: undefined,

        FETCH_GET_ACTIVITY_SURVEY_QUESTIONS: undefined,
        FETCH_GET_ACTIVITY_SURVEY_QUESTIONS_REQUEST: undefined,
        FETCH_GET_ACTIVITY_SURVEY_QUESTIONS_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_SURVEY_QUESTIONS_FAILURE: undefined,

        UPDATE_STEP: undefined,
        ADD_STEP: undefined,
        RESET_STEPS: undefined,
        SET_ACTIVE_STEP: undefined,
        HANDLE_NEXT: undefined,
        HANDLE_PREV: undefined,
      },
      ACTIVITY_APPLY_TRAIN_QRCODE: {
        FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE: undefined,
        FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE_REQUEST: undefined,
        FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE_SUCCESS: undefined,
        FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE_FAILURE: undefined,

        FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK: undefined,
        FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_REQUEST: undefined,
        FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_SUCCESS: undefined,
        FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_FAILURE: undefined,
      },
      ACTIVITY_CANCEL_APPLY: {
        FETCH_PATCH_USER_APPLY_STATUS: undefined,
        FETCH_PATCH_USER_APPLY_STATUS_REQUEST: undefined,
        FETCH_PATCH_USER_APPLY_STATUS_SUCCESS: undefined,
        FETCH_PATCH_USER_APPLY_STATUS_FAILURE: undefined,
      },
      FETCH_GET_ACTIVITY: undefined,
      FETCH_GET_ACTIVITY_REQUEST: undefined,
      FETCH_GET_ACTIVITY_SUCCESS: undefined,
      FETCH_GET_ACTIVITY_FAILURE: undefined,

      FETCH_POST_ACTIVITY_APPLY_POTENTIAL: undefined,
      FETCH_POST_ACTIVITY_APPLY_POTENTIAL_REQUEST: undefined,
      FETCH_POST_ACTIVITY_APPLY_POTENTIAL_SUCCESS: undefined,
      FETCH_POST_ACTIVITY_APPLY_POTENTIAL_FAILURE: undefined,
    },
    EMAIL_FILL_SURVEY: {
      FETCH_POST_USER_INFO: undefined,
      FETCH_POST_USER_INFO_REQUEST: undefined,
      FETCH_POST_USER_INFO_SUCCESS: undefined,
      FETCH_POST_USER_INFO_FAILURE: undefined,
    },
    ORGANIZATION_INVITATION: {
      FETCH_GET_MEMBER_INVITATION_BY_EMAIL: undefined,
      FETCH_GET_MEMBER_INVITATION_BY_EMAIL_REQUEST: undefined,
      FETCH_GET_MEMBER_INVITATION_BY_EMAIL_SUCCESS: undefined,
      FETCH_GET_MEMBER_INVITATION_BY_EMAIL_FAILURE: undefined,

      FETCH_POST_MEMBER_INTO_ORGANIZATION: undefined,
      FETCH_POST_MEMBER_INTO_ORGANIZATION_REQUEST: undefined,
      FETCH_POST_MEMBER_INTO_ORGANIZATION_SUCCESS: undefined,
      FETCH_POST_MEMBER_INTO_ORGANIZATION_FAILURE: undefined,
    },
    PHONE_FILL_SURVEY: {
      FETCH_POST_VERIFY_SURVEY_QUALIFY: undefined,
      FETCH_POST_VERIFY_SURVEY_QUALIFY_REQUEST: undefined,
      FETCH_POST_VERIFY_SURVEY_QUALIFY_SUCCESS: undefined,
      FETCH_POST_VERIFY_SURVEY_QUALIFY_FAILURE: undefined,
    },
    SURVEY: {
      FETCH_GET_SURVEY: undefined,
      FETCH_GET_SURVEY_REQUEST: undefined,
      FETCH_GET_SURVEY_SUCCESS: undefined,
      FETCH_GET_SURVEY_FAILURE: undefined,

      FETCH_GET_SURVEY_QUESTIONS: undefined,
      FETCH_GET_SURVEY_QUESTIONS_REQUEST: undefined,
      FETCH_GET_SURVEY_QUESTIONS_SUCCESS: undefined,
      FETCH_GET_SURVEY_QUESTIONS_FAILURE: undefined,

      FETCH_POST_SURVEY_RESPONSES: undefined,
      FETCH_POST_SURVEY_RESPONSES_REQUEST: undefined,
      FETCH_POST_SURVEY_RESPONSES_SUCCESS: undefined,
      FETCH_POST_SURVEY_RESPONSES_FAILURE: undefined,

      FETCH_POST_CHECK_USER_SURVEY: undefined,
      FETCH_POST_CHECK_USER_SURVEY_REQUEST: undefined,
      FETCH_POST_CHECK_USER_SURVEY_SUCCESS: undefined,
      FETCH_POST_CHECK_USER_SURVEY_FAILURE: undefined,
    },
    TRAIN: {
      FETCH_POST_ACTIVITY_USER_MODEL_TRAIN: undefined,
      FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_REQUEST: undefined,
      FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_SUCCESS: undefined,
      FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_FAILURE: undefined,

      FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK: undefined,
      FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_REQUEST: undefined,
      FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_SUCCESS: undefined,
      FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_FAILURE: undefined,

      FETCH_POST_FACE_DETECTION: undefined,
      FETCH_POST_FACE_DETECTION_REQUEST: undefined,
      FETCH_POST_FACE_DETECTION_SUCCESS: undefined,
      FETCH_POST_FACE_DETECTION_FAILURE: undefined,

      SET_IS_STOP_SNAPSHOT: undefined,
    },
  },
  MODULES: {
    COMMON: {
      FETCH_GET_OPTIONS: undefined,
      FETCH_GET_OPTIONS_REQUEST: undefined,
      FETCH_GET_OPTIONS_SUCCESS: undefined,
      FETCH_GET_OPTIONS_FAILURE: undefined,

      FETCH_POST_LOGOUT: undefined,
      FETCH_POST_LOGOUT_REQUEST: undefined,
      FETCH_POST_LOGOUT_SUCCESS: undefined,
      FETCH_POST_LOGOUT_FAILURE: undefined,

      FETCH_GET_SERVICE_PRODUCTS: undefined,
      FETCH_GET_SERVICE_PRODUCTS_REQUEST: undefined,
      FETCH_GET_SERVICE_PRODUCTS_SUCCESS: undefined,
      FETCH_GET_SERVICE_PRODUCTS_FAILURE: undefined,

      SET_SELECTED_ORG: undefined,
    },
  },
};

export default createActions(actionMap);
