import { fetcher } from 'redux/apisOrg';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';
import { PaymentOrder, EntityList } from 'types';

export type PathParams = {
  organizationId?: string;
};
const useOrgOrders = makeGetHook<EntityList<PaymentOrder>, PathParams>(
  '/organizations/{{organizationId}}/micepass/payment-orders',
  fetcher
);
export default useOrgOrders;
