import React, { FC } from 'react';

import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import {
  AppBar,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
} from '@eGroupTeam/material';
import CloseIcon from '@material-ui/icons/Close';
import ScrollableTypography from 'components/ScrollableTypography';

export const DIALOG = 'activityPreviewDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

export interface ActivityPreviewDialogProps {
  previewUrl: string;
  title?: string;
}

const ActivityPreviewDialog: FC<
  ActivityPreviewDialogProps & WithReduxDialogProps
> = (props) => {
  const classes = useStyles();

  const { isOpen, handleClose, previewUrl, title } = props;
  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} disableEnforceFocus>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <ScrollableTypography variant="h6" className={classes.title}>
            {title ?? '活動預覽'}
          </ScrollableTypography>
        </Toolbar>
      </AppBar>
      <iframe
        src={previewUrl}
        height={window.innerHeight}
        title="preview activity"
      />
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<
  HTMLDivElement,
  ActivityPreviewDialogProps
>(ActivityPreviewDialog);
