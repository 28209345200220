import React from 'react';
import PropTypes from 'prop-types';

// TODO: need fixed.
// @ts-ignore
import { submit } from 'redux-form/immutable';
import { getSelectedOrg } from 'redux/modules/common';
import { useDispatch, useSelector } from 'react-redux';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';

import Button from '@eGroupTeam/material/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@eGroupTeam/material';
import { fetchPostSurvey, getIsPosting } from './redux';
import SurveyAddForm, { FORM } from './SurveyAddForm';

export const DIALOG = 'surveyAddDialog';

const SurveyAddDialog = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const isPosting = useSelector(getIsPosting);
  const selectedOrg = useSelector(getSelectedOrg);

  const handleSubmit = (values) => {
    dispatch(
      fetchPostSurvey({
        organizationId: selectedOrg.get('organizationId'),
        ...values.toJS(),
      })
    );
  };

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>新增問卷</DialogTitle>
      <DialogContent>
        <SurveyAddForm onSubmit={handleSubmit} />
      </DialogContent>
      <DialogActions>
        <Button loading={isPosting} onClick={() => dispatch(submit(FORM))}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SurveyAddDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withReduxDialog(DIALOG)(SurveyAddDialog);
