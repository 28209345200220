import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Hidden,
} from '@eGroupTeam/material';
import NestedSideMenu, {
  EgRouteConfig,
} from '@eGroupTeam/material-module/NestedSideMenu';
import Logo from 'components/Logo';
import SideMenuBody from 'components/SideMenuBody';

const styles = (theme: Theme) =>
  createStyles({
    desktopSideMenu: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      overflow: 'auto',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  });

export interface SideMenuProps {
  pathname: string;
  routes: EgRouteConfig[];
}

const SideMenu: FC<SideMenuProps & WithStyles<typeof styles>> = ({
  classes,
  pathname,
  routes,
}) => (
  <Hidden smDown>
    <SideMenuBody className={classes.desktopSideMenu}>
      <div className={classes.header}>
        <Link to="/me">
          <Logo variant="rounded" size="large" />
        </Link>
      </div>
      <NestedSideMenu disablePadding routes={routes} pathname={pathname} />
    </SideMenuBody>
  </Hidden>
);

export default withStyles(styles)(SideMenu);
