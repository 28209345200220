import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { normalize } from 'normalizr';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { setEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import {
  fetchGetSurveyRequest,
  fetchGetSurveySuccess,
  fetchGetSurveyFailure,
  FETCH_GET_SURVEY,
} from './redux';
import SurveyAnalysisEpics from './SurveyAnalysis/epics';
import SurveyEditorEpics from './SurveyEditor/epics';

export const fetchGetSurveyEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SURVEY,
  apiName: 'fetchGetSurvey',
  fetchRequest: fetchGetSurveyRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data || {}, schema.survey);
    return [
      setEntities(fromJS(entities)),
      fetchGetSurveySuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetSurveyFailure(error))
    ),
});

export default combineEpics(
  SurveyAnalysisEpics,
  SurveyEditorEpics,
  fetchGetSurveyEpic
);
