import { encode } from 'js-base64';

export default function encodeFilesNameToBase64(files: FileList) {
  const result: File[] = [];
  for (let i = 0; i < files.length; i+=1) {
    const file = files[i];
    if (file) {
      const exts = file.name.split('.').pop();
      const name = file.name.replace(`.${exts}`, '');
      const newFile = new File([file], `${encode(name)}.${exts}`, {
        type: file.type,
      });
      result.push(newFile);
    }
  }
  return result;
}