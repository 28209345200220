import React from 'react';
import PropTypes from 'prop-types';

import CopyTextField from '@eGroupTeam/material/CopyTextField';
import {
  makeStyles,
  Dialog,
  InputAdornment,
  Button,
  DialogTitle,
  Box,
  IconButton,
} from '@eGroupTeam/material';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import CloseIcon from '@material-ui/icons/Close';
import { SNACKBAR } from 'App';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { getSurvey } from '../redux';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 64,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const DIALOG = 'surveyShareDialog';

const SurveyShareDialog = ({ isOpen, handleClose, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const survey = useSelector(getSurvey);

  const handleCopy = () => {
    dispatch(
      setSnackbarData({
        name: SNACKBAR,
        message: '複製成功',
        variant: 'success',
      })
    );
    dispatch(openSnackbar(SNACKBAR));
  };

  const myHandleClose = () => {
    if (onClose) {
      onClose();
    }
    handleClose();
  };

  const shareUrl = `${window.location.origin}/surveys/${survey.get(
    'surveyId'
  )}`;

  return (
    <Dialog fullWidth open={isOpen} onClose={myHandleClose} disableEnforceFocus>
      <DialogTitle>
        取得問卷連結
        <IconButton className={classes.closeButton} onClick={myHandleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box px={3} pt={1} pb={3}>
        <CopyTextField
          margin="dense"
          value={`${shareUrl}?${queryString.stringify({
            openExternalBrowser: 1,
          })}`}
          onCopy={handleCopy}
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button color="primary">複製</Button>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Dialog>
  );
};

SurveyShareDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // parent props
  onClose: PropTypes.func,
};

export default withReduxDialog(DIALOG)(SurveyShareDialog);
