import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@eGroupTeam/material';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.egPalette.info[1]}`,
      zIndex: 1,
      color: theme.egPalette.info[1],
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      background: theme.egPalette.info[1],
      boxShadow: theme.egShadows[1],
      color: theme.palette.background.paper,
    },
    completed: {
      background: theme.egPalette.info[1],
      color: theme.palette.background.paper,
    },
  });

export interface StepLabelIconProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  completed?: boolean;
  icon?: ReactNode;
}

const StepLabelIcon: FC<StepLabelIconProps & WithStyles<typeof styles>> = (
  props
) => {
  const { classes, active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {props.icon}
    </div>
  );
};

export default withStyles(styles)(StepLabelIcon);
