import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  reduxForm,
  change,
  Field,
  Form,
  getFormValues,
} from 'redux-form/immutable';
import makeIsFormFieldsError from 'utils/makeIsFormFieldsError';

import Grid from '@eGroupTeam/material/Grid';
import MenuItem from '@eGroupTeam/material/MenuItem';
import Typography from '@eGroupTeam/material/Typography';
import FormHelperText from '@eGroupTeam/material/FormHelperText';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import useOptions from 'utils/useOptions';
import FroalaEditor from 'components/FroalaEditor';

export const FORM = 'notificationTemplatesForm';

const validate = (values) => {
  const errors: Record<string, string> = {};
  if (!values.has('organizationEdmTemplateName')) {
    errors.organizationEdmTemplateName = '「信件範本名稱」是必填欄位';
  }
  if (!values.has('organizationEdmTemplateType')) {
    errors.organizationEdmTemplateType = '「信件類型」是必填欄位';
  }
  if (!values.has('organizationEdmTemplateTitle')) {
    errors.organizationEdmTemplateTitle = '「信件範本標題」是必填欄位';
  }
  if (!values.has('organizationEdmTemplateContent')) {
    errors.organizationEdmTemplateContent = '「信件範本內容」是必填欄位';
  }
  if (!values.has('organizationEdmTemplateContactContent')) {
    errors.organizationEdmTemplateContactContent =
      '「信件範本聯絡內容」是必填欄位';
  }
  if (
    values.get('organizationEdmTemplateTitle') &&
    values.get('organizationEdmTemplateTitle').length > 150
  ) {
    errors.organizationEdmTemplateTitle = '「信件範本標題」超過長度限制';
  }
  return errors;
};

const useIsFormFieldsError = makeIsFormFieldsError(FORM);

const NotificationTemplatesForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const values: any = useSelector(getFormValues(FORM));
  const [, submitFailed, formSyncErrors] = useIsFormFieldsError();
  const { data } = useOptions(undefined, {
    isShowOrganizationEdmTemplateType: true,
  });

  const handleEdmTemplateContentModelChange = (model: string) => {
    dispatch(change(FORM, 'organizationEdmTemplateContent', model));
  };

  const handleEdmTemplateContactContentModelChange = (model: string) => {
    dispatch(change(FORM, 'organizationEdmTemplateContactContent', model));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Field
            name="organizationEdmTemplateName"
            label="信件範本名稱"
            component={TextLoadingField}
            fullWidth
            variant="outlined"
            required
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextLoadingField}
            label="信件類型"
            name="organizationEdmTemplateType"
            fullWidth
            select
            required
            variant="outlined"
            margin="normal"
          >
            {data?.organizationEdmTemplateType &&
              Object.keys(data.organizationEdmTemplateType).map((key) => (
                <MenuItem key={key} value={key}>
                  {data.organizationEdmTemplateType[key]}
                </MenuItem>
              ))}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field
            name="organizationEdmTemplateTitle"
            label="信件範本標題"
            component={TextLoadingField}
            fullWidth
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            信件內容 *
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            component="input"
            name="organizationEdmTemplateContent"
            type="hidden"
          />
          <FroalaEditor
            model={values.get('organizationEdmTemplateContent', '')}
            onModelChange={handleEdmTemplateContentModelChange}
          />
          {submitFailed && (
            <FormHelperText error>
              {/* @ts-ignore */}
              {formSyncErrors.organizationEdmTemplateContent}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            信件聯絡內容 *
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            component="input"
            name="organizationEdmTemplateContactContent"
            type="hidden"
          />
          <FroalaEditor
            model={values.get('organizationEdmTemplateContactContent', '')}
            onModelChange={handleEdmTemplateContactContentModelChange}
          />
          {submitFailed && (
            <FormHelperText error>
              {/* @ts-ignore */}
              {formSyncErrors.organizationEdmTemplateContactContent}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </Form>
  );
};

export default reduxForm({
  form: FORM,
  validate,
})(NotificationTemplatesForm);
