import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import { normalize } from 'normalizr';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  openDialog,
  setDialogData,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { setEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import { SNACKBAR, DIALOG } from 'App';
import {
  fetchGetActivityStudyProve,
  fetchGetActivityStudyProveRequest,
  fetchGetActivityStudyProveSuccess,
  fetchGetActivityStudyProveFailure,
  fetchPatchActivityStudyProveRequest,
  fetchPatchActivityStudyProveSuccess,
  fetchPatchActivityStudyProveFailure,
  fetchPostActivityStudyProveGeneratorRequest,
  fetchPostActivityStudyProveGeneratorSuccess,
  fetchPostActivityStudyProveGeneratorFailure,
  fetchGetActivityEdmUsersRequest,
  fetchGetActivityEdmUsersSuccess,
  fetchGetActivityEdmUsersFailure,
  fetchPostNotificationEmailRequest,
  fetchPostNotificationEmailSuccess,
  fetchPostNotificationEmailFailure,
  fetchPostTestNotificationEmailRequest,
  fetchPostTestNotificationEmailSuccess,
  fetchPostTestNotificationEmailFailure,
  fetchPostPreviewEdmRequest,
  fetchPostPreviewEdmSuccess,
  fetchPostPreviewEdmFailure,
  FETCH_GET_ACTIVITY_STUDY_PROVE,
  FETCH_PATCH_ACTIVITY_STUDY_PROVE,
  FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR,
  FETCH_GET_ACTIVITY_EDM_USERS,
  FETCH_POST_NOTIFICATION_EMAIL,
  FETCH_POST_TEST_NOTIFICATION_EMAIL,
  FETCH_POST_PREVIEW_EDM,
} from './redux';

export const fetchGetActivityStudyProveEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_STUDY_PROVE,
  apiName: 'fetchGetActivityStudyProve',
  fetchRequest: fetchGetActivityStudyProveRequest,
  handleSuccess: (response) => [
    fetchGetActivityStudyProveSuccess(fromJS(response.data || {})),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityStudyProveFailure(error))
    ),
});

export const fetchPatchActivityStudyProveEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_ACTIVITY_STUDY_PROVE,
  apiName: 'fetchPatchActivityStudyProve',
  fetchRequest: fetchPatchActivityStudyProveRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.activity
    );
    return [
      setEntities(fromJS(entities)),
      fetchPatchActivityStudyProveSuccess(fromJS(result)),
      setSnackbarData({
        name: SNACKBAR,
        message: '儲存參加證明成功！',
        variant: 'success',
      }),
      openSnackbar(SNACKBAR),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchActivityStudyProveFailure(error))
    ),
});

export const fetchPostActivityStudyProveGeneratorEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR,
  apiName: 'fetchPostActivityStudyProveGenerator',
  fetchRequest: fetchPostActivityStudyProveGeneratorRequest,
  handleSuccess: (response, { schema, action }) => {
    if (response.status === 204) {
      return [
        fetchPostActivityStudyProveGeneratorSuccess(),
        setSnackbarData({
          name: SNACKBAR,
          message: '尚未有來賓符合產生條件(必須完成簽到簽退)',
          variant: 'error',
        }),
        fetchGetActivityStudyProve({
          organizationId: action.payload.organizationId,
          activityId: action.payload.activityId,
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    return [
      fetchGetActivityStudyProve({
        organizationId: action.payload.organizationId,
        activityId: action.payload.activityId,
      }),
      fetchPostActivityStudyProveGeneratorSuccess(),
      setSnackbarData({
        name: SNACKBAR,
        message: '參加證明產生完成！',
        variant: 'success',
      }),
      openSnackbar(SNACKBAR),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      of(fetchPostActivityStudyProveGeneratorFailure(error)),
      of(
        setSnackbarData({
          name: SNACKBAR,
          message: error.response.data.clientMessage,
          variant: 'error',
        })
      ),
      of(openSnackbar(SNACKBAR))
    ),
});

export const fetchGetActivityEdmUsersEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_EDM_USERS,
  apiName: 'fetchGetActivityEdmUsers',
  fetchRequest: fetchGetActivityEdmUsersRequest,
  handleSuccess: (response) => [
    fetchGetActivityEdmUsersSuccess(fromJS(response.data || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityEdmUsersFailure(error))
    ),
});

export const fetchPostNotificationEmailEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_NOTIFICATION_EMAIL,
  apiName: 'fetchPostNotificationEmail',
  fetchRequest: fetchPostNotificationEmailRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.activityEdm
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostNotificationEmailSuccess(fromJS(result)),
      setSnackbarData({
        name: SNACKBAR,
        message: '傳送成功！',
        variant: 'success',
      }),
      openSnackbar(SNACKBAR),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostNotificationEmailFailure(error))
    ),
});

export const fetchPostTestNotificationEmailEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_TEST_NOTIFICATION_EMAIL,
  apiName: 'fetchPostTestNotificationEmail',
  fetchRequest: fetchPostTestNotificationEmailRequest,
  handleSuccess: (response) => [
    fetchPostTestNotificationEmailSuccess(fromJS(response.data)),
    setSnackbarData({
      name: SNACKBAR,
      message: '傳送成功！',
      variant: 'success',
    }),
    openSnackbar(SNACKBAR),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) => {
    if (error.response && error.response.status === 476) {
      return concat(
        of(
          setDialogData({
            name: DIALOG,
            primary: '寄信失敗',
            message: '已通知系統管理員。',
          })
        ),
        of(openDialog(DIALOG)),
        of(fetchPostTestNotificationEmailFailure(error))
      );
    }

    return concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostTestNotificationEmailFailure(error)),
      of(
        setDialogData({
          name: DIALOG,
          primary: '傳送失敗',
          message: '請先完成欄位。',
        })
      ),
      of(openDialog(DIALOG))
    );
  },
});

export const fetchPostPreviewEdmEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_PREVIEW_EDM,
  apiName: 'fetchPostPreviewEdm',
  fetchRequest: fetchPostPreviewEdmRequest,
  handleSuccess: (response) => {
    window.open(response.data, '_blank');
    return [fetchPostPreviewEdmSuccess(fromJS(response.data))];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostPreviewEdmFailure(error)),
      of(
        setSnackbarData({
          name: SNACKBAR,
          message: '預覽失敗',
          variant: 'error',
        })
      ),
      of(openSnackbar(SNACKBAR))
    ),
});

export default combineEpics(
  fetchGetActivityStudyProveEpic,
  fetchPatchActivityStudyProveEpic,
  fetchPostActivityStudyProveGeneratorEpic,
  fetchGetActivityEdmUsersEpic,
  fetchPostNotificationEmailEpic,
  fetchPostTestNotificationEmailEpic,
  fetchPostPreviewEdmEpic
);
