import React, { useCallback, useEffect } from 'react';

import MobileDetect from 'mobile-detect';
import { useDispatch, useSelector } from 'react-redux';
import { clearApiResponse } from '@eGroupTeam/redux-modules/immutable/apis';

import { Redirect } from 'react-router-dom';
import useLocationSearch from 'utils/useLocationSearch';

import FaceRecognizer from 'components/FaceRecognizer';
import {
  fetchPostActivityUserModelTrainCheck,
  fetchPostFaceDetection,
  setIsStopSnapshot,
  getFaceDetection,
  getIsStopSnapshot,
} from './redux';

const md = new MobileDetect(window.navigator.userAgent);

const Train = () => {
  const { activityId, qrcodeTokenId } = useLocationSearch();
  const dispatch = useDispatch();
  const faceDetection = useSelector(getFaceDetection);
  const isStopSnapshot = useSelector(getIsStopSnapshot);

  // send log
  useEffect(() => {
    dispatch(
      fetchPostActivityUserModelTrainCheck({
        activityId,
        qrcodeTokenId,
      })
    );
  }, [activityId, dispatch, qrcodeTokenId]);

  // reset to default state when component unmount
  useEffect(
    () => () => {
      dispatch(clearApiResponse(fetchPostFaceDetection()));
      dispatch(setIsStopSnapshot(false));
    },
    [dispatch]
  );

  const handleGetIntervalShot = useCallback(
    (blob) => {
      const formData = new FormData();
      formData.append(
        'file',
        new File([blob], 'screenshot.jpeg', {
          lastModified: new Date().getTime(),
        })
      );
      dispatch(
        fetchPostFaceDetection({
          activityId,
          qrcodeTokenId,
          formData,
        })
      );
    },
    [activityId, dispatch, qrcodeTokenId]
  );

  const getFaceDetectionErrorMsg = () => {
    if (isStopSnapshot) {
      return '人臉上傳中請稍候...';
    }
    if (!faceDetection) return undefined;
    if (faceDetection === 'No face') {
      return '請將臉部對準鏡頭';
    }
    if (faceDetection.get('faceQualityLowLuminance') === 'Fail') {
      return '臉部過暗，請勿背光';
    }
    if (faceDetection.get('faceQualityHeadpose') === 'Fail') {
      return '請將臉擺正對準鏡頭';
    }
    return '人臉偵測中...';
  };

  if (!activityId || !qrcodeTokenId || !md.mobile()) {
    return <Redirect to="/not-found" />;
  }

  return (
    <FaceRecognizer
      intervalTime={333}
      handleGetIntervalShot={handleGetIntervalShot}
      errorMsg={getFaceDetectionErrorMsg()}
      isStopSnapshot={isStopSnapshot}
    />
  );
};

export default Train;
