import React, { FC, useState } from 'react';

import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { ActivityClass, UserApply } from 'types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  MenuItem,
  TextField,
  CircularProgress,
} from '@eGroupTeam/material';

export const DIALOG = 'addActivityClassDialog';

export interface AddActivityClassDialogProps {
  activityClasses?: ActivityClass[];
  userApply?: UserApply;
  onConfirm?: (selectedActivityClassId?: string) => void;
}

const AddActivityClassDialog: FC<
  AddActivityClassDialogProps & WithReduxDialogProps
> = ({ isOpen, handleClose, activityClasses, userApply, onConfirm }) => {
  const [
    selectedActivityClassId,
    setSelectedActivityClassId,
  ] = useState<string>();

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(selectedActivityClassId);
    }
  };

  const renderOptions = () => {
    if (!activityClasses || !userApply)
      return (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      );
    const { userApplyActivityClassList } = userApply;
    const appliedClassIds = userApplyActivityClassList.map(
      (el) => el.activityClass.activityClassId
    );
    return activityClasses.map((el) => (
      <MenuItem
        key={el.activityClassId}
        value={el.activityClassId}
        disabled={appliedClassIds.includes(el.activityClassId)}
      >
        {el.activityClassName}
      </MenuItem>
    ));
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>新增報名場次</DialogTitle>
      <DialogContent>
        <TextField
          select
          fullWidth
          label="選擇場次"
          variant="outlined"
          size="small"
          value={selectedActivityClassId}
          onChange={(e) => setSelectedActivityClassId(e.target.value)}
          helperText="請選擇要增加報名的場次送出"
        >
          {renderOptions()}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>關閉</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleConfirm}
        >
          新增
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<
  HTMLDivElement,
  AddActivityClassDialogProps
>(AddActivityClassDialog);
