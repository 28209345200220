import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schema from 'redux/schema';
import { getEntities } from '@eGroupTeam/redux-modules/immutable/entities';

/** Actions */
export const {
  fetchGetSurveys,
  fetchGetSurveysRequest,
  fetchGetSurveysSuccess,
  fetchGetSurveysFailure,

  fetchPostSurvey,
  fetchPostSurveyRequest,
  fetchPostSurveySuccess,
  fetchPostSurveyFailure,
} = actionCreators.mainPages.surveys;

/** Types */
export const FETCH_GET_SURVEYS = fetchGetSurveys().type;
export const FETCH_GET_SURVEYS_REQUEST = fetchGetSurveysRequest().type;
export const FETCH_GET_SURVEYS_SUCCESS = fetchGetSurveysSuccess().type;
export const FETCH_GET_SURVEYS_FAILURE = fetchGetSurveysFailure().type;

export const FETCH_POST_SURVEY = fetchPostSurvey().type;
export const FETCH_POST_SURVEY_REQUEST = fetchPostSurveyRequest().type;
export const FETCH_POST_SURVEY_SUCCESS = fetchPostSurveySuccess().type;
export const FETCH_POST_SURVEY_FAILURE = fetchPostSurveyFailure().type;
/** Selectors */
/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'surveys'], Map());

const getFetchGetSurveys = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetSurveys', Map())
);
const getFetchPostSurvey = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostSurvey', Map())
);

export const getSurveys = createSelector(
  getEntities,
  getFetchGetSurveys,
  (entities, fetchGet) =>
    denormalize(
      fetchGet.getIn(['response', 'result']),
      schema.surveys,
      entities
    )
);

export const getSurveysTotal = createSelector(getFetchGetSurveys, (fetchGet) =>
  fetchGet.getIn(['response', 'total'])
);

export const getIsPosting = createSelector(getFetchPostSurvey, (fetchGet) =>
  fetchGet.get('isLoading')
);
