import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchPostActivityUserModelTrain,
  fetchPostActivityUserModelTrainRequest,
  fetchPostActivityUserModelTrainSuccess,
  fetchPostActivityUserModelTrainFailure,

  fetchPostActivityUserModelTrainCheck,
  fetchPostActivityUserModelTrainCheckRequest,
  fetchPostActivityUserModelTrainCheckSuccess,
  fetchPostActivityUserModelTrainCheckFailure,

  fetchPostFaceDetection,
  fetchPostFaceDetectionRequest,
  fetchPostFaceDetectionSuccess,
  fetchPostFaceDetectionFailure,

  setIsStopSnapshot,
} = actionCreators.landingPages.train;

/** Types */
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN = fetchPostActivityUserModelTrain()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_REQUEST = fetchPostActivityUserModelTrainRequest()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_SUCCESS = fetchPostActivityUserModelTrainSuccess()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_FAILURE = fetchPostActivityUserModelTrainFailure()
  .type;

export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK = fetchPostActivityUserModelTrainCheck()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_REQUEST = fetchPostActivityUserModelTrainCheckRequest()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_SUCCESS = fetchPostActivityUserModelTrainCheckSuccess()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_FAILURE = fetchPostActivityUserModelTrainCheckFailure()
  .type;

export const FETCH_POST_FACE_DETECTION = fetchPostFaceDetection().type;
export const FETCH_POST_FACE_DETECTION_REQUEST = fetchPostFaceDetectionRequest()
  .type;
export const FETCH_POST_FACE_DETECTION_SUCCESS = fetchPostFaceDetectionSuccess()
  .type;
export const FETCH_POST_FACE_DETECTION_FAILURE = fetchPostFaceDetectionFailure()
  .type;

export const SET_IS_STOP_SNAPSHOT = setIsStopSnapshot().type;

/** Selectors */

/**
 * UiStates
 */
const getUiStates = (state) => state.getIn(['landingPages', 'Train']);

export const getIsStopSnapshot = createSelector(getUiStates, (uiStates) =>
  uiStates.get('isStopSnapshot')
);

/**
 * ApiStates
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'landingPages', 'train'], Map());

const getFetchPostActivityUserModelTrain = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostActivityUserModelTrain', Map())
);

const getFetchPostFaceDetection = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostFaceDetection', Map())
);

export const getActivityUserModelTrain = createSelector(
  getFetchPostActivityUserModelTrain,
  (fetchResult) => fetchResult.get('response')
);

export const getActivityUserModelTrainUserFacePath = createSelector(
  getActivityUserModelTrain,
  (activityUserModelTrain) => {
    if (activityUserModelTrain) {
      return activityUserModelTrain.get('userFacePath');
    }
    return undefined;
  }
);

export const getActivityUserModelTrainUser = createSelector(
  getActivityUserModelTrain,
  (activityUserModelTrain) => {
    if (activityUserModelTrain) {
      return activityUserModelTrain.get('user');
    }
    return undefined;
  }
);

export const getFaceDetection = createSelector(
  getFetchPostFaceDetection,
  (fetchResult) => fetchResult.get('response')
);
