import { schema } from 'normalizr';

/**
 * Activity
 */
export const activity = new schema.Entity(
  'activities',
  {},
  {
    idAttribute: 'activityId',
  }
);
export const activities = [activity];

/**
 * Survey
 */
export const survey = new schema.Entity(
  'surveys',
  {},
  {
    idAttribute: 'surveyId',
  }
);
export const surveys = [survey];

/**
 * User
 */
export const user = new schema.Entity(
  'users',
  {},
  {
    idAttribute: 'userId',
  }
);
export const users = [user];

/**
 * Condition
 */
export const condition = new schema.Entity(
  'conditions',
  {},
  {
    idAttribute: 'conditionId',
  }
);
export const conditions = [condition];

/**
 * User Apply
 */
export const userApply = new schema.Entity(
  'userApplies',
  {
    user,
  },
  {
    idAttribute: 'userApplyId',
  }
);
export const userApplies = [userApply];

/**
 * Organization Edm Templates
 */
export const organizationEdmTemplate = new schema.Entity(
  'organizationEdmTemplates',
  {},
  {
    idAttribute: 'organizationEdmTemplateId',
  }
);
export const organizationEdmTemplates = [organizationEdmTemplate];

/**
 * Activity Edm
 */
export const activityEdm = new schema.Entity(
  'activityEdms',
  {},
  {
    idAttribute: 'activityEdmId',
  }
);
export const activityEdms = [activityEdm];

/**
 * Organization
 */
export const organization = new schema.Entity(
  'organizations',
  {},
  {
    idAttribute: 'organizationId',
  }
);
export const organizations = [organization];

/**
 * Member
 */
export const member = new schema.Entity(
  'members',
  {},
  {
    idAttribute: 'loginId',
  }
);
export const members = [member];

/**
 * Organization Member
 */
export const organizationMember = new schema.Entity(
  'organizationMembers',
  {
    organization,
    member,
  },
  {
    idAttribute: (el) =>
      `${el.organization.organizationId}${el.member.loginId}`,
  }
);
export const organizationMembers = [organizationMember];

/**
 * Organization Invitation
 */
export const organizationInvitation = new schema.Entity(
  'organizationInvitations',
  {},
  {
    idAttribute: 'organizationInvitationId',
  }
);
export const organizationInvitations = [organizationInvitation];

/**
 * Option
 */
export const option = new schema.Entity(
  'options',
  {},
  {
    idAttribute: 'optionId',
  }
);
export const options = [option];

/**
 * Question
 */
export const question = new schema.Entity(
  'questions',
  {
    optionList: options,
  },
  {
    idAttribute: 'questionId',
  }
);
export const questions = [question];

/**
 * ApplyFieldOption
 */
export const applyFieldOption = new schema.Entity(
  'applyFieldOptions',
  {},
  {
    idAttribute: 'applyFieldOptionId',
  }
);
export const applyFieldOptions = [applyFieldOption];

/**
 * ApplyField
 */
export const applyField = new schema.Entity(
  'applyFields',
  {
    applyFieldOptionList: applyFieldOptions,
  },
  {
    idAttribute: 'applyFieldId',
  }
);
export const applyFields = [applyField];

/**
 * Qrpass tag
 */
export const qrpassTag = new schema.Entity(
  'qrpassTags',
  {},
  {
    idAttribute: 'qrpassTagId',
  }
);
export const qrpassTags = [qrpassTag];
