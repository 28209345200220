import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  fetchGetActivityFiles,
  fetchGetActivityFilesRequest,
  fetchGetActivityFilesSuccess,
  fetchGetActivityFilesFailure,
  fetchDeleteActivityFileRequest,
  fetchDeleteActivityFileSuccess,
  fetchDeleteActivityFileFailure,
  FETCH_GET_ACTIVITY_FILES,
  FETCH_DELETE_ACTIVITY_FILE,
} from './redux';

export const fetchGetActivityFilesEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_FILES,
  apiName: 'fetchGetActivityFiles',
  fetchRequest: fetchGetActivityFilesRequest,
  handleSuccess: (response) => [
    fetchGetActivityFilesSuccess(fromJS(response.data.source || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityFilesFailure(error))
    ),
});

export const fetchDeleteActivityFileEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_ACTIVITY_FILE,
  apiName: 'fetchDeleteActivityFile',
  fetchRequest: fetchDeleteActivityFileRequest,
  handleSuccess: (response, { action }) => [
    fetchDeleteActivityFileSuccess(),
    fetchGetActivityFiles({
      organizationId: action.payload.organizationId,
      activityId: action.payload.activityId,
    }),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteActivityFileFailure(error))
    ),
});

export default combineEpics(
  fetchGetActivityFilesEpic,
  fetchDeleteActivityFileEpic
);
