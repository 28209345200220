export type Option = {
  optionId: string;
  responseContent?: string;
};

export type Response =
  | {
      questionId: string;
      optionList?: Option[];
      responseContent?: string;
    }
  | undefined;

/**
 * Parse formValues to api acceptable format.
 * @param {*} formValues
 * @param {*} properties
 */
export default function parseResponseList(
  formValues: any,
  properties
): Response[] {
  return Object.keys(formValues).map((key) => {
    const response = formValues[key];
    const { type } = properties[key];
    switch (type) {
      case 'checkboxGroup': {
        const optionList: Option[] = [];
        Object.keys(response).forEach((key) => {
          const el = response[key];
          if (el.checked) {
            optionList.push({
              optionId: key,
              responseContent: el.text,
            });
          }
        });
        return {
          questionId: key,
          optionList,
        };
      }
      case 'radioGroup':
        return {
          questionId: key,
          optionList: [
            {
              optionId: response.value,
              responseContent: response.text,
            },
          ],
        };
      case 'select':
        return {
          questionId: key,
          optionList: [
            {
              optionId: response.value,
            },
          ],
        };
      case 'rating':
        return {
          questionId: key,
          responseContent: response,
        };
      case 'titleBlock':
        return undefined;
      default:
        return {
          questionId: key,
          responseContent: response,
        };
    }
  });
}
