import React from 'react';

import clsx from 'clsx';
import { createStyles, withStyles } from '@eGroupTeam/material';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
    },
  });

const ActivityGridContent = ({ classes, className, ...other }) => (
  <div className={clsx(classes.root, className)} {...other} />
);

export default withStyles(styles)(ActivityGridContent);
