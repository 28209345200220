import React from 'react';

import useHasLogined from 'utils/useHasLogined';
import { useDispatch, useSelector } from 'react-redux';
import useLocationSearch from 'utils/useLocationSearch';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { DIALOG as LOGIN_DIALOG } from 'components/LoginDialog';

import Paper from '@eGroupTeam/material/Paper';
import Box from '@eGroupTeam/material/Box';
import Typography from '@eGroupTeam/material/Typography';
import Grid from '@eGroupTeam/material/Grid';
import Button from '@eGroupTeam/material/Button';
import Center from '@eGroupTeam/material-layout/Center';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import CircularProgress from '@eGroupTeam/material/CircularProgress';
import {
  fetchGetMemberInvitationByEmail,
  fetchPostMemberIntoOrganization,
  getMemberInvitationByEmail,
} from './redux';

const OrganizationInvitation = () => {
  const dispatch = useDispatch();
  const memberInvitationByEmailResult = useSelector(getMemberInvitationByEmail);
  const { organizationId, token } = useLocationSearch();
  const hasLogined = useHasLogined();

  React.useEffect(() => {
    if (hasLogined) {
      dispatch(
        fetchGetMemberInvitationByEmail({
          organizationId,
          organizationInvitationToken: token,
        })
      );
    }
  }, [dispatch, hasLogined, organizationId, token]);

  const handleAccept = () => {
    dispatch(
      fetchPostMemberIntoOrganization({
        organizationId,
        organizationInvitationStatus: 3,
        organizationInvitationToken: token,
      })
    );
  };

  const handleDecline = () => {
    dispatch(
      fetchPostMemberIntoOrganization({
        organizationId,
        organizationInvitationStatus: 2,
        organizationInvitationToken: token,
      })
    );
  };

  if (!hasLogined) {
    return (
      <Center>
        <div>
          <Typography variant="h5" gutterBottom>
            請先登入後才能接受單位管理邀請
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => dispatch(openDialog(LOGIN_DIALOG))}
          >
            登入
          </Button>
        </div>
      </Center>
    );
  }

  if (!memberInvitationByEmailResult) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (memberInvitationByEmailResult.isEmpty()) {
    return (
      <Center offsetTop={64}>
        <Typography variant="h6" align="center">
          邀請函已失效
        </Typography>
      </Center>
    );
  }

  return (
    <Center offsetTop={64}>
      <Paper>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom align="center">
                {`${memberInvitationByEmailResult.getIn([
                  'organization',
                  'organizationContactPerson',
                ])}邀請你共同管理`}
              </Typography>
              <Box pb={4} />
              <Typography variant="h6" gutterBottom align="center">
                {memberInvitationByEmailResult.getIn([
                  'organization',
                  'organizationName',
                ])}
              </Typography>
              <Box pb={4} />
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleAccept}
                fullWidth
              >
                接受邀請
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="secondary" onClick={handleDecline} fullWidth>
                拒絕邀請
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Center>
  );
};

export default OrganizationInvitation;
