import { Action } from 'redux-actions';
import { fromJS } from 'immutable';
import { combineEpics, ofType } from 'redux-observable';
import { of, concat } from 'rxjs';
import {
  mergeMap,
  takeUntil,
  debounceTime,
  mapTo,
  delay,
} from 'rxjs/operators';
import makeBasicFetchEpic, {
  ObservableAction,
} from '@eGroupTeam/utils/makeBasicFetchEpic';

import apiErrorMsgMapper from 'utils/apiErrorMsgMapper';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  fetchGetUserApplyTotalCounts,
  fetchGetUserApplyTotalCountsRequest,
  fetchGetUserApplyTotalCountsSuccess,
  fetchGetUserApplyTotalCountsFailure,
  fetchPostActivityUserApplyDaysRequest,
  fetchPostActivityUserApplyDaysSuccess,
  fetchPostActivityUserApplyDaysFailure,
  fetchPostActivityRecognizeQrcodeRequest,
  fetchPostActivityRecognizeQrcodeSuccess,
  fetchPostActivityRecognizeQrcodeFailure,
  fetchPostActivityRecognizeFaceRequest,
  fetchPostActivityRecognizeFaceSuccess,
  fetchPostActivityRecognizeFaceFailure,
  fetchGetActivityApplyFieldResultRequest,
  fetchGetActivityApplyFieldResultSuccess,
  fetchGetActivityApplyFieldResultFailure,
  setSelectedUserApply,
  setRecognizedResult,
  setUnknownRecognizedResult,
  emptyRecognizedResult,
  emptyUnknownRecognizedResult,
  FETCH_GET_USER_APPLY_COUNT_INFO,
  FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT,
  FETCH_GET_USER_APPLY_TOTAL_COUNTS,
  FETCH_POST_ACTIVITY_USER_APPLY_DAYS,
  FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE,
  FETCH_POST_ACTIVITY_RECOGNIZE_FACE,
  EMPTY_RECOGNIZED_RESULT,
  SET_RECOGNIZED_RESULT,
  SET_UNKNOWN_RECOGNIZED_RESULT,
} from './redux';

export const fetchGetUserApplyTotalCountsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_USER_APPLY_TOTAL_COUNTS,
  apiName: 'fetchGetUserApplyTotalCounts',
  fetchRequest: fetchGetUserApplyTotalCountsRequest,
  observableMap: mergeMap,
  handleSuccess: (response, { action }) => {
    if (action.payload.hasActivityVerifyRecord === 1) {
      return [
        fetchGetUserApplyTotalCountsSuccess(
          fromJS({
            count: response.data,
          })
        ),
      ];
    }
    return [
      fetchGetUserApplyTotalCountsSuccess(
        fromJS({
          total: response.data,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetUserApplyTotalCountsFailure(error))
    ),
});

export const fetchGetUserApplyCountInfoEpic = (action$: ObservableAction) =>
  action$.pipe(
    ofType(FETCH_GET_USER_APPLY_COUNT_INFO),
    mergeMap((action) =>
      concat(
        of(fetchGetUserApplyTotalCounts(action.payload[0])),
        of(fetchGetUserApplyTotalCounts(action.payload[1])).pipe(delay(100))
      )
    )
  );

export const fetchGetActivityApplyFieldResultEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT,
  apiName: 'fetchGetActivityApplyFieldResult',
  fetchRequest: fetchGetActivityApplyFieldResultRequest,
  handleSuccess: (response) => [
    fetchGetActivityApplyFieldResultSuccess(fromJS(response.data || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityApplyFieldResultFailure(error))
    ),
});

export const fetchPostActivityUserApplyDaysEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_USER_APPLY_DAYS,
  apiName: 'fetchPostActivityUserApplyDays',
  fetchRequest: fetchPostActivityUserApplyDaysRequest,
  handleSuccess: (response) => {
    if (typeof response.data === 'string') {
      return [
        fetchPostActivityUserApplyDaysSuccess(),
        setSelectedUserApply(fromJS({})),
        setSnackbarData({
          name: SNACKBAR,
          message: apiErrorMsgMapper.get(response.data),
          variant: 'error',
        }),
        openSnackbar(SNACKBAR),
      ];
    }
    const { userApply } = response.data;
    return [
      fetchPostActivityUserApplyDaysSuccess(),
      setSelectedUserApply(fromJS({})),
      setSnackbarData({
        name: SNACKBAR,
        message: `${userApply.user.userName} 驗證成功`,
        variant: 'success',
      }),
      openSnackbar(SNACKBAR),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityUserApplyDaysFailure(error))
    ),
});

export const fetchPostActivityRecognizeQrcodeEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_RECOGNIZE_QRCODE,
  apiName: 'fetchPostActivityRecognizeQrcode',
  observableMap: mergeMap,
  fetchRequest: fetchPostActivityRecognizeQrcodeRequest,
  handleTakeUntil: (action$) =>
    takeUntil(action$.pipe(ofType(EMPTY_RECOGNIZED_RESULT))),
  handleSuccess: (response, { state$ }) => {
    if (typeof response.data === 'string') {
      return [
        setUnknownRecognizedResult(apiErrorMsgMapper.get(response.data)),
        fetchPostActivityRecognizeQrcodeSuccess(),
      ];
    }
    if (
      state$.value.hasIn(['mainPages', 'ActivityCheckIn', 'recognizedResult'])
    ) {
      return [fetchPostActivityRecognizeQrcodeSuccess()];
    }
    return [
      setRecognizedResult(fromJS(response.data)),
      fetchPostActivityRecognizeQrcodeSuccess(),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityRecognizeQrcodeFailure(error))
    ),
});

export const fetchPostActivityRecognizeFaceEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_RECOGNIZE_FACE,
  apiName: 'fetchPostActivityRecognizeFace',
  observableMap: mergeMap,
  fetchRequest: fetchPostActivityRecognizeFaceRequest,
  handleTakeUntil: (action$) =>
    takeUntil(action$.pipe(ofType(EMPTY_RECOGNIZED_RESULT))),
  handleSuccess: (response, { state$ }) => {
    if (typeof response.data === 'string') {
      return [
        setUnknownRecognizedResult(apiErrorMsgMapper.get(response.data)),
        fetchPostActivityRecognizeFaceSuccess(),
      ];
    }
    if (
      state$.value.hasIn(['mainPages', 'ActivityCheckIn', 'recognizedResult'])
    ) {
      return [fetchPostActivityRecognizeQrcodeSuccess()];
    }
    return [
      setRecognizedResult(fromJS(response.data)),
      fetchPostActivityRecognizeFaceSuccess(),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityRecognizeFaceFailure(error))
    ),
});

export const clearAfterSetRecognizedResultEpic = (action$) =>
  action$.pipe(
    ofType(SET_RECOGNIZED_RESULT),
    debounceTime(5000),
    mapTo(emptyRecognizedResult())
  );

export const clearUnknownEpic = (action$) =>
  action$.pipe(
    ofType(SET_UNKNOWN_RECOGNIZED_RESULT),
    debounceTime(3000),
    mapTo(emptyUnknownRecognizedResult())
  );

export default combineEpics(
  fetchGetUserApplyTotalCountsEpic,
  fetchPostActivityUserApplyDaysEpic,
  fetchPostActivityRecognizeQrcodeEpic,
  fetchPostActivityRecognizeFaceEpic,
  fetchGetActivityApplyFieldResultEpic,
  fetchGetUserApplyCountInfoEpic,
  clearAfterSetRecognizedResultEpic,
  clearUnknownEpic
);
