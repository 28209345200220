import React, { FC, useMemo } from 'react';

import {
  openDialog,
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  makeStyles,
  Dialog,
  Box,
  Divider,
  InputAdornment,
  Button,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@eGroupTeam/material';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';

import CopyTextField from '@eGroupTeam/material/CopyTextField';
import LineIcon from '@eGroupTeam/material-icons/Line';
import FacebookIcon from '@material-ui/icons/Facebook';
import CloseIcon from '@material-ui/icons/Close';
import QrCodeIcon from '@eGroupTeam/material-icons/QrCode';
import ShareButton from 'components/ShareButton';
import { Activity } from 'types';
import QrCodeDialog, { DIALOG as QRCODE_DIALOG } from './QrCodeDialog';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 64,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const DIALOG = 'activityShareDialog';

export interface ActivityShareDialogProps {
  activity: Activity;
  onClose?: () => void;
}

const ActivityShareDialog: FC<
  ActivityShareDialogProps & WithReduxDialogProps
> = ({ activity, isOpen, handleClose, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const shareUrl = useMemo(
    () => `${window.location.origin}/activities/${activity.activityId}`,
    [activity]
  );

  const handleCopy = () => {
    dispatch(
      setSnackbarData({
        name: SNACKBAR,
        message: '複製成功',
        variant: 'success',
      })
    );
    dispatch(openSnackbar(SNACKBAR));
  };

  const myHandleClose = () => {
    if (onClose) {
      onClose();
    }
    handleClose();
  };

  return (
    <>
      <QrCodeDialog value={shareUrl} />
      <Dialog
        fullWidth
        open={isOpen}
        onClose={myHandleClose}
        disableEnforceFocus
      >
        <DialogTitle>
          分享
          <IconButton className={classes.closeButton} onClick={myHandleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ShareButton
            href={`https://www.facebook.com/sharer/sharer.php?${queryString.stringify(
              {
                u: shareUrl,
              }
            )}`}
          >
            <FacebookIcon
              className={classes.icon}
              style={{ color: '#4267b2' }}
            />
          </ShareButton>
          <ShareButton
            href={`https://social-plugins.line.me/lineit/share?${queryString.stringify(
              {
                url: `${shareUrl}?${queryString.stringify({
                  openExternalBrowser: 1,
                })}`,
                text: activity.activityName,
              }
            )}`}
          >
            <LineIcon className={classes.icon} />
          </ShareButton>
          <ShareButton
            onClick={() => {
              dispatch(openDialog(QRCODE_DIALOG));
            }}
          >
            <QrCodeIcon className={classes.icon} />
          </ShareButton>
        </DialogContent>
        <Divider />
        <Box px={3} py={2}>
          <CopyTextField
            margin="dense"
            value={`${shareUrl}?${queryString.stringify({
              openExternalBrowser: 1,
            })}`}
            onCopy={handleCopy}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button color="primary">複製</Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default withReduxDialog(DIALOG)<unknown, ActivityShareDialogProps>(
  ActivityShareDialog
);
