import { combineEpics } from 'redux-observable';

import AccountEpics from 'profilePages/Account/epics';
import ActivitiesEpics from 'profilePages/Activities/epics';
import CarrierEpics from 'profilePages/Carrier/epics';

export const profilePagesEpics = combineEpics(
  AccountEpics,
  ActivitiesEpics,
  CarrierEpics
);
