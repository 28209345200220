import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import useCountDown from '@eGroupTeam/hooks/useCountDown';

import { Redirect } from 'react-router-dom';
import Typography from '@eGroupTeam/material/Typography';
import Container from '@eGroupTeam/material/Container';
import Center from '@eGroupTeam/material-layout/Center';
import useLocationSearch from 'utils/useLocationSearch';
import { fetchPostUserInfo, getIsError } from './redux';

/**
 * Use `emailTokenId` to login and to fill survey.
 */
const EmailFillSurvey = () => {
  const dispatch = useDispatch();
  const isError = useSelector(getIsError);
  const { emailTokenId, surveyId } = useLocationSearch();
  const [number, setStartCountDown] = useCountDown(3, {
    min: 0,
  });

  React.useEffect(() => {
    if (emailTokenId && surveyId) {
      dispatch(
        fetchPostUserInfo({
          emailTokenId,
          surveyId,
        })
      );
    }
  }, [dispatch, emailTokenId, surveyId]);

  React.useEffect(() => {
    if (isError) {
      setStartCountDown(true);
    }
  }, [isError, setStartCountDown]);

  if (!emailTokenId || !surveyId) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Center>
      <Container>
        <Typography variant="h5" align="center">
          {isError
            ? `email 驗證失敗將於 ${number} 秒後重新跳轉至身份驗證頁面。`
            : 'email 驗證中...'}
        </Typography>
      </Container>
    </Center>
  );
};

export default EmailFillSurvey;
