import { combineEpics } from 'redux-observable';
import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { closeDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  fetchGetActivityRequest,
  fetchGetActivitySuccess,
  fetchGetActivityFailure,
  fetchPostActivityApplyPotentialRequest,
  fetchPostActivityApplyPotentialSuccess,
  fetchPostActivityApplyPotentialFailure,
  FETCH_GET_ACTIVITY,
  FETCH_POST_ACTIVITY_APPLY_POTENTIAL,
} from './redux';
import { DIALOG } from './ActivityApplyPotentialDialog';
import ActivityApplyEpics from './ActivityApply/epics';
import ActivityApplyTrainQrcodeEpics from './ActivityApplyTrainQrcode/epics';
import ActivityCancelApplyEpics from './ActivityCancelApply/epics';

export const fetchGetActivityEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY,
  apiName: 'landingPage.fetchGetActivity',
  fetchRequest: fetchGetActivityRequest,
  handleSuccess: (response) => [
    fetchGetActivitySuccess(fromJS(response.data || {})),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityFailure(error))
    ),
});

export const fetchPostActivityApplyPotentialEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_APPLY_POTENTIAL,
  apiName: 'landingPage.fetchPostActivityApplyPotential',
  fetchRequest: fetchPostActivityApplyPotentialRequest,
  handleSuccess: (response) => [
    fetchPostActivityApplyPotentialSuccess(fromJS(response.data)),
    closeDialog(DIALOG),
    setSnackbarData({
      name: SNACKBAR,
      message: '送出完成！',
      variant: 'success',
    }),
    openSnackbar(SNACKBAR),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityApplyPotentialFailure(error))
    ),
});

export default combineEpics(
  ActivityApplyEpics,
  ActivityApplyTrainQrcodeEpics,
  ActivityCancelApplyEpics,
  fetchGetActivityEpic,
  fetchPostActivityApplyPotentialEpic
);
