import { fetcher } from 'redux/apisOrg';
import { Activity, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
};
const useOrgActivities = makeGetHook<EntityList<Activity>, PathParams>(
  '/organizations/{{organizationId}}/activities',
  fetcher
);

export default useOrgActivities;
