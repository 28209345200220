export default function fieldToQuestion(applyField) {
  return {
    questionId: applyField.applyFieldId,
    questionType: applyField.applyFieldType,
    questionName: applyField.applyFieldName,
    isRequired: Boolean(applyField.isRequired),
    isOther: Boolean(applyField.isOther),
    optionList: applyField.applyFieldOptionList
      ? applyField.applyFieldOptionList.filter(Boolean).map((el) => ({
          optionId: el.applyFieldOptionId,
          optionName: el.applyFieldOptionName,
          optionNo: el.applyFieldOptionNo,
          isOther: el.isOther,
        }))
      : undefined,
  };
}
