import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import {
  FETCH_GET_USER_APPLY_TOTAL_COUNTS_SUCCESS,
  SET_SELECTED_USER_APPLY,
  SET_RESULT,
  SET_RECOGNIZED_RESULT,
  SET_UNKNOWN_RECOGNIZED_RESULT,
  EMPTY_RECOGNIZED_RESULT,
  EMPTY_UNKNOWN_RECOGNIZED_RESULT,
} from './redux';

const reducer = handleActions(
  {
    [SET_SELECTED_USER_APPLY]: (state, action) =>
      state.set('selectedUserApply', action.payload),
    [SET_RESULT]: (state, action) => state.set('result', action.payload),
    [SET_RECOGNIZED_RESULT]: (state, action) =>
      state.set('recognizedResult', action.payload),
    [EMPTY_RECOGNIZED_RESULT]: (state) => state.delete('recognizedResult'),
    [SET_UNKNOWN_RECOGNIZED_RESULT]: (state, action) =>
      state.set('unknownRecognizedResult', action.payload),
    [EMPTY_UNKNOWN_RECOGNIZED_RESULT]: (state) =>
      state.delete('unknownRecognizedResult'),
    [FETCH_GET_USER_APPLY_TOTAL_COUNTS_SUCCESS]: (state, action) =>
      state.update('userApplyCountInfo', (userApplyCountInfo) =>
        userApplyCountInfo.merge(action.payload)
      ),
  },
  fromJS({
    selectedUserApply: {},
    userApplyCountInfo: {
      count: 0,
      total: 0,
    },
  })
);

export default reducer;
