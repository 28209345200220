import React, { FC } from 'react';

import { makeStyles } from '@eGroupTeam/material';
import makeChartColors from '@eGroupTeam/utils/makeChartColors';

import {
  ResponsiveContainer,
  Tooltip,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Legend,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import clsx from 'clsx';

const COLORS = makeChartColors();

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 300,
  },
}));

export interface CompareDataChartProps {
  className?: string;
  BarChartProps?: CategoricalChartProps;
}

const CompareDataChart: FC<CompareDataChartProps> = (props) => {
  const classes = useStyles();
  const { className, BarChartProps, ...other } = props;
  const {
    margin = {
      top: 10,
      right: 30,
      left: 0,
      bottom: 0,
    },
    ...otherBarChartProps
  } = BarChartProps || {};

  return (
    <div className={clsx(className, classes.root)} {...other}>
      <ResponsiveContainer>
        <BarChart margin={margin} {...otherBarChartProps}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="applyFieldOptionName" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            name="選項數量"
            dataKey="applyFieldOptionCount"
            barSize={30}
            fill={COLORS[0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CompareDataChart;
