import { Map } from 'immutable';
import validator from '@eGroupTeam/utils/validator';
import { toDate, isBefore, format } from '@eGroupTeam/utils/dateUtils';

export const orgValidate = (values) => {
  const errors = {};
  validator(errors, 'organizationName', values.get('organizationName'))
    .isRequired('「主辦單位名稱」是必填欄位')
    .lengthLimit(100, '長度不能超過100個字元');
  validator(
    errors,
    'organizationContactPerson',
    values.get('organizationContactPerson')
  )
    .isRequired('「主辦單位聯絡人」是必填欄位')
    .lengthLimit(10, '長度不能超過10個字元');
  validator(
    errors,
    'organizationContactEmail',
    values.get('organizationContactEmail')
  )
    .isRequired('「主辦單位聯絡 Email」是必填欄位')
    .isEmail('「主辦單位聯絡 Email」格式錯誤')
    .lengthLimit(100, '長度不能超過100個字元');
  validator(
    errors,
    'organizationContactPhone',
    values.get('organizationContactPhone')
  )
    .isRequired('「主辦單位聯絡電話」是必填欄位')
    .lengthLimit(30, '長度不能超過30個字元');
  validator(
    errors,
    'organizationContactAddress',
    values.get('organizationContactAddress')
  )
    .isRequired('「主辦單位聯絡地址」是必填欄位')
    .lengthLimit(100, '長度不能超過100個字元');
  validator(
    errors,
    'organizationInvoiceTaxIdNumber',
    values.get('organizationInvoiceTaxIdNumber')
  ).lengthLimit(8, '長度不能超過8個字元');
  return errors;
};

export type ActivityClassDayError = {
  activityClassDayStartTimeString?: string;
  activityClassDayEndTimeString?: string;
};

export type ActivityClassError = {
  activityClassDayList?: ActivityClassDayError[];
};

export type ActivityErrors = {
  activityClassList?: ActivityClassError[];
};

export const activityValidate = (values, props) => {
  const { eventType = 'event' } = props;
  const isEvent = eventType === 'event';
  const errors: ActivityErrors = {};
  validator(errors, 'activityName', values.get('activityName'))
    .isRequired('「活動名稱」是必填欄位')
    .lengthLimit(100, '長度不能超過100個字元');
  validator(
    errors,
    'activityDescription',
    values.get('activityDescription')
  ).isRequired('「活動簡述」是必填欄位');
  validator(
    errors,
    'activityCoverPath',
    values.get('activityCoverPath')
  ).isRequired('「活動圖片」是必填欄位');
  if (values.get('activityClassList')) {
    const activityClassListErrors: ActivityClassError[] = [];
    values.get('activityClassList').forEach((activityClass, index) => {
      const activityClassError: ActivityClassError = {};
      validator(
        activityClassError,
        'activityClassName',
        activityClass.get('activityClassName')
      )
        .isRequired('「場次名稱」是必填欄位')
        .lengthLimit(30, '長度不能超過30個字元');
      validator(
        activityClassError,
        'activityClassStartDateString',
        activityClass.get('activityClassStartDateString')
      ).isRequired('「活動開始時間」是必填欄位');
      validator(
        activityClassError,
        'activityClassEndDateString',
        activityClass.get('activityClassEndDateString')
          ? toDate(activityClass.get('activityClassEndDateString'))
          : undefined
      )
        .isRequired('「活動結束時間」是必填欄位')
        .isDateBefore(
          toDate(activityClass.get('activityClassStartDateString')) ||
            new Date(),
          '「活動結束時間」不能早於「活動開始時間」'
        );
      validator(
        activityClassError,
        'activityClassLimitCount',
        activityClass.get('activityClassLimitCount')
      )
        .isRequired(
          isEvent ? '「活動人數」是必填欄位' : '「場次限制人數」是必填欄位'
        )
        .lengthLimit(11, '長度不能超過11個字元');
      validator(
        activityClassError,
        'activityClassPlace',
        activityClass.get('activityClassPlace')
      )
        .isRequired(
          isEvent ? '「活動地點名稱」是必填欄位' : '「場次地點」是必填欄位'
        )
        .lengthLimit(150, '長度不能超過150個字元');

      if (activityClass.get('activityClassDayList')) {
        const activityClassDayListErrors: ActivityClassDayError[] = [];
        activityClass
          .get('activityClassDayList')
          .forEach((activityClassDay, index2) => {
            const activityClassDayError: ActivityClassDayError = {};
            const activityClassDate: Date | undefined = activityClassDay.get(
              'activityClassDate'
            );
            const activityClassDayStartTimeString:
              | Date
              | undefined = activityClassDay.get(
              'activityClassDayStartTimeString'
            );
            const activityClassDayEndTimeString:
              | Date
              | undefined = activityClassDay.get(
              'activityClassDayEndTimeString'
            );
            const date = format(activityClassDate, 'yyyy-MM-dd');
            const startTime = format(
              activityClassDayStartTimeString,
              'HH:mm:ss'
            );
            const endTime = format(activityClassDayEndTimeString, 'HH:mm:ss');

            validator(
              activityClassDayError,
              'activityClassDate',
              activityClassDate
            ).isRequired('「日期」是必填欄位');
            validator(
              activityClassDayError,
              'activityClassDayStartTimeString',
              activityClassDayStartTimeString
            ).isRequired('「開始時間」是必填欄位');
            validator(
              activityClassDayError,
              'activityClassDayEndTimeString',
              activityClassDayEndTimeString
            ).isRequired('「結束時間」是必填欄位');
            if (
              !isBefore(
                toDate(`${date}T${startTime}`),
                toDate(`${date}T${endTime}`)
              )
            ) {
              activityClassDayError.activityClassDayStartTimeString =
                '「開始時間」晚於結束時間';
              activityClassDayError.activityClassDayEndTimeString =
                '「結束時間」早於開始時間';
            }
            if (!Map(activityClassDayError).isEmpty()) {
              activityClassDayListErrors[index2] = activityClassDayError;
            }
          });
        if (activityClassDayListErrors.length) {
          activityClassError.activityClassDayList = activityClassDayListErrors;
        }
      }
      if (!Map(activityClassError).isEmpty()) {
        activityClassListErrors[index] = activityClassError;
      }
    });
    if (activityClassListErrors.length) {
      errors.activityClassList = activityClassListErrors;
    }
  }
  return errors;
};
