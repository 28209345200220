import React, { useState, useEffect, FC } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import { AppBar, Button, Toolbar } from "@eGroupTeam/material";
import Container from "@eGroupTeam/material/Container";
import { Link } from 'react-router-dom'
import Grid from "@eGroupTeam/material/Grid";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import IconButton from "@eGroupTeam/material/IconButton";
import Collapse from "@material-ui/core/Collapse";

import Logo from 'components/Logo'
import { fetchPostLogout } from 'redux/modules/common';

import { DIALOG as LOGIN_DIALOG } from 'components/LoginDialog';
import { openDialog } from "@eGroupTeam/redux-modules/immutable/dialogs";
import { useDispatch } from "react-redux";
import useHasLogined from "utils/useHasLogined";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: "all 0.3s ease-out",
    background: "transparent",
  },
  stickyBar: {
    backgroundColor: "#2176a8d6 !important",
    boxShadow: "0 0 5px -1px rgb(0 0 0 / 74%)",
  },
  navbarContainer: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  navbarCollpase: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      backgroundColor: "#000000d9",
    },
  },
  navbar: {
    listStyle: "none",
    padding: theme.spacing(0),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  navItem: {
    marginLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
      paddingRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
    },
  },
  navLink: {
    color: "white",
    textDecoration: "none !important",
    fontSize: "15px",
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 0 10px",
    },
  },
  navButton: {
    paddingRight: theme.spacing(0),
    margin: "0 4px",
  },
  langDropdown: {
    boxShadow: "none",
    "& .MuiButtonGroup-root": {
      height: "auto",
      minWidth: "117px",
    },
    "& .MuiButtonBase-root": {
      fontSize: "0.875rem",
      borderColor: "#5FD3F3",
      padding: "6.4px 20px",
    },
    "& .MuiButtonGroup-grouped": {
      borderRadius: "20px",
      backgroundColor: "#5FD3F3",
    },
    "& .MuiButton-label": {
      color: "white",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
}));

const Navbar: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasLogined = useHasLogined();
  const [, , removeCookie] = useCookies();

  const [showNav, setShowNav] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    const resizeListener = () => {
      const width = window.innerWidth;
      if (width > 960) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };
    resizeListener();
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      className={clsx({ [classes.appBar]: true, [classes.stickyBar]: trigger })}
    >
      <Toolbar>
        <Container className={classes.navbarContainer}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Link
              to="/"
            >
              <Logo width="70" color="white" decoding="async"/>
            </Link>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-haspopup="true"
                onClick={() => setShowNav(!showNav)}
                color="white"
              >
                {showNav ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </div>
            <Collapse
              in={!isMobile ? true : showNav}
              className={classes.navbarCollpase}
            >
              <Grid
                item
                className={clsx({
                  [classes.show]: !isMobile,
                  [classes.show]: isMobile && showNav,
                  [classes.hide]: isMobile && !showNav,
                })}
              >
                <ul className={classes.navbar}>
                  <li className={classes.navItem}>
                    <Link
                      to="/"
                      className={classes.navLink}
                      onClick={() => setShowNav(false)}
                    >
                      首頁
                    </Link>
                  </li>
                  <li className={classes.navItem}>
                    <Link
                      to="/pricing"
                      className={classes.navLink}
                      onClick={() => setShowNav(false)}
                    >
                      價格
                    </Link>
                  </li>
                  <li className={classes.navItem}>
                    <Link
                      to="/reference"
                      className={classes.navLink}
                      onClick={() => setShowNav(false)}
                    >
                      花絮
                    </Link>
                  </li>
                  {
                    hasLogined ? (
                      <>
                        <li className={classes.navItem}>
                          <Link
                            to="/me/profile/activities"
                            className={classes.navLink}
                          >
                            會員中心
                          </Link>
                        </li>
                        <li className={classes.navItem}>
                          <Link
                            to="/me"
                            className={classes.navLink}
                          >
                            主辦中心
                          </Link>
                        </li>
                        <li className={clsx(classes.navItem, classes.navButton)}>
                          <Button
                            variant="outlined"
                            rounded
                            color="white"
                            onClick={() => {
                              window.localStorage.clear();
                              removeCookie('lid');
                              removeCookie('tid');
                              removeCookie('u_info');
                              dispatch(fetchPostLogout());
                            }}
                          >
                            登出
                          </Button>
                        </li>
                      </>
                    ) : (
                      <li className={clsx(classes.navItem, classes.navButton)}>
                        <Button
                          variant="outlined"
                          rounded
                          color="white"
                          onClick={() => dispatch(openDialog(LOGIN_DIALOG))}
                        >
                          登入
                        </Button>
                      </li>
                    )
                  }
                  
                  {/* <li className={clsx(classes.navItem, classes.navButton)}>
                    <DropDown
                      options={["繁體中文","English"]}
                      select
                      className={classes.langDropdown}
                    >
                      繁體中文
                    </DropDown>
                  </li> */}
                </ul>
              </Grid>
            </Collapse>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
