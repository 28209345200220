import React, { FC } from 'react';

import {
  withReduxSnackbar,
  WithReduxSnackbarProps,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { useCookies } from 'react-cookie';

import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@eGroupTeam/material/Button';
import MuiSnackbar from '@eGroupTeam/material/Snackbar';
import MuiLink from '@eGroupTeam/material/Link';

export const COOKIE_SNACKBAR = 'cookieSnackbar';

export type CookieSnackbarProps = WithReduxSnackbarProps;

const CookieSnackbar: FC<CookieSnackbarProps> = ({ isOpen, handleClose }) => {
  const [, setCookie] = useCookies();
  const handleConfirm = () => {
    setCookie('confirmCookiePolicy', 'true', {
      expires: new Date('9999-12-31'),
    });
    handleClose();
  };
  return (
    <MuiSnackbar
      open={isOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Alert
        severity="info"
        elevation={6}
        variant="filled"
        onClose={handleClose}
        action={
          <Button color="inherit" size="small" onClick={handleConfirm}>
            同意
          </Button>
        }
      >
        <AlertTitle>Cookie 使用政策</AlertTitle>
        MICEPass 將使用 cookie 來改善您的使用體驗，詳細使用方式請參考
        <MuiLink
          to="/privacy"
          component={Link}
          color="inherit"
          underline="always"
        >
          隱私政策
        </MuiLink>
        。
      </Alert>
    </MuiSnackbar>
  );
};

export default withReduxSnackbar(COOKIE_SNACKBAR)(CookieSnackbar);
