import React from 'react';

import clsx from 'clsx';
import { Field, getIn, useFormikContext } from 'formik';
import { makeStyles, Paper, Typography, FormLabel } from '@eGroupTeam/material';
import FieldAnchor from 'components/FieldAnchor';

const useStyles = makeStyles((theme) => ({
  field: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  fieldError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    position: 'relative',
    overflow: 'hidden',
  },
  titleHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 6,
    backgroundColor: theme.palette.primary.main,
  },
}));

const SurveyField = (props) => {
  const { label, desc, required, name, type, ...other } = props;
  const classes = useStyles();
  const { errors, touched } = useFormikContext();
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  if (type === 'titleBlock') {
    return (
      <Paper key={name} className={clsx(classes.field, classes.title)}>
        <div className={classes.titleHeader} />
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
        <Typography>{desc}</Typography>
      </Paper>
    );
  }

  return (
    <React.Fragment key={name}>
      <FieldAnchor name={`position-${name}`} />
      <Paper
        className={clsx(classes.field, {
          [classes.fieldError]: showError,
        })}
      >
        <div className={classes.header}>
          <FormLabel
            className={classes.label}
            required={required}
            color="primary"
          >
            {label}
          </FormLabel>
        </div>
        <Field name={name} {...other} />
      </Paper>
    </React.Fragment>
  );
};

export default SurveyField;
