import { fetcher } from 'redux/apisOrg';
import { UserApply, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
  activityId?: string;
};
const useUserApplies = makeGetHook<EntityList<UserApply>, PathParams>(
  '/organizations/{{organizationId}}/activities/{{activityId}}/user-applies',
  fetcher
);
export default useUserApplies;
