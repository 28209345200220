import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map, OrderedMap, fromJS } from 'immutable';
import schemaFieldsTypeMapper from 'utils/schemaFieldsTypeMapper';

/** Actions */
export const {
  fetchGetSurvey,
  fetchGetSurveyRequest,
  fetchGetSurveySuccess,
  fetchGetSurveyFailure,

  fetchGetSurveyQuestions,
  fetchGetSurveyQuestionsRequest,
  fetchGetSurveyQuestionsSuccess,
  fetchGetSurveyQuestionsFailure,

  fetchPostSurveyResponses,
  fetchPostSurveyResponsesRequest,
  fetchPostSurveyResponsesSuccess,
  fetchPostSurveyResponsesFailure,

  fetchPostCheckUserSurvey,
  fetchPostCheckUserSurveyRequest,
  fetchPostCheckUserSurveySuccess,
  fetchPostCheckUserSurveyFailure,
} = actionCreators.landingPages.survey;

/** Types */
export const FETCH_GET_SURVEY = fetchGetSurvey().type;
export const FETCH_GET_SURVEY_REQUEST = fetchGetSurveyRequest().type;
export const FETCH_GET_SURVEY_SUCCESS = fetchGetSurveySuccess().type;
export const FETCH_GET_SURVEY_FAILURE = fetchGetSurveyFailure().type;

export const FETCH_GET_SURVEY_QUESTIONS = fetchGetSurveyQuestions().type;
export const FETCH_GET_SURVEY_QUESTIONS_REQUEST = fetchGetSurveyQuestionsRequest()
  .type;
export const FETCH_GET_SURVEY_QUESTIONS_SUCCESS = fetchGetSurveyQuestionsSuccess()
  .type;
export const FETCH_GET_SURVEY_QUESTIONS_FAILURE = fetchGetSurveyQuestionsFailure()
  .type;

export const FETCH_POST_SURVEY_RESPONSES = fetchPostSurveyResponses().type;
export const FETCH_POST_SURVEY_RESPONSES_REQUEST = fetchPostSurveyResponsesRequest()
  .type;
export const FETCH_POST_SURVEY_RESPONSES_SUCCESS = fetchPostSurveyResponsesSuccess()
  .type;
export const FETCH_POST_SURVEY_RESPONSES_FAILURE = fetchPostSurveyResponsesFailure()
  .type;

export const FETCH_POST_CHECK_USER_SURVEY = fetchPostCheckUserSurvey().type;
export const FETCH_POST_CHECK_USER_SURVEY_REQUEST = fetchPostCheckUserSurveyRequest()
  .type;
export const FETCH_POST_CHECK_USER_SURVEY_SUCCESS = fetchPostCheckUserSurveySuccess()
  .type;
export const FETCH_POST_CHECK_USER_SURVEY_FAILURE = fetchPostCheckUserSurveyFailure()
  .type;

/** Selectors */
const getApiStates = (state) =>
  state.getIn(['apis', 'landingPages', 'survey'], Map());

const getFetchGetSurvey = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetSurvey', Map())
);

const getFetchGetSurveyQuestions = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetSurveyQuestions', Map())
);

const getFetchPostSurveyResponses = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostSurveyResponses', Map())
);

export const getSurvey = createSelector(getFetchGetSurvey, (fetchGet) =>
  fetchGet.get('response')
);

export const getSurveyQuestions = createSelector(
  getFetchGetSurveyQuestions,
  (fetchGet) => fetchGet.get('response')
);

export const getSurveyQuestionProperties = createSelector(
  getSurveyQuestions,
  (surveyQuestions) => {
    let properties = OrderedMap();
    if (surveyQuestions) {
      surveyQuestions.forEach((surveyQuestion) => {
        const type = schemaFieldsTypeMapper[surveyQuestion.get('questionType')];
        const property = {
          label: surveyQuestion.get('questionName'),
          desc: surveyQuestion.get('questionDescription'),
          name: surveyQuestion.get('questionId'),
          type,
          questionId: surveyQuestion.get('questionId'),
          isRequired: surveyQuestion.get('isRequired'),
        };
        switch (type) {
          case 'checkboxGroup':
            property.options = surveyQuestion
              .get('optionList')
              .map((option) => ({
                key: option.get('optionId'),
                label: option.get('optionName'),
                toggleInput: Boolean(option.get('hasInput')),
                name: option.get('optionId'),
              }));
            break;
          case 'rating': {
            const start = surveyQuestion.get('questionRatingStartValue');
            const end = surveyQuestion.get('questionRatingEndValue');
            property.start = start;
            property.end = end;
            property.startLabel = surveyQuestion.get('questionRatingStartName');
            property.endLabel = surveyQuestion.get('questionRatingEndName');
            break;
          }
          case 'radioGroup':
            property.options = surveyQuestion
              .get('optionList')
              .map((option) => ({
                key: option.get('optionId'),
                label: option.get('optionName'),
                toggleInput: Boolean(option.get('hasInput')),
                value: option.get('optionId'),
              }));
            break;
          case 'select':
            property.options = surveyQuestion
              .get('optionList')
              .map((option) => ({
                key: option.get('optionId'),
                label: option.get('optionName'),
                value: option.get('optionId'),
              }));
            break;
          default:
            break;
        }
        properties = properties.set(
          surveyQuestion.get('questionId'),
          fromJS(property)
        );
      });
    }
    return properties;
  }
);

export const getRequired = createSelector(
  getSurveyQuestionProperties,
  (questionProperties) =>
    questionProperties
      .toList()
      .filter((el) => Boolean(el.get('isRequired')))
      .map((el) => el.get('questionId'))
);

export const getProperties = createSelector(
  getSurveyQuestionProperties,
  (questionProperties) =>
    questionProperties.map((el) =>
      el.deleteAll(['questionId', 'questionGroup', 'isRequired'])
    )
);

export const getSchema = createSelector(
  getRequired,
  getProperties,
  (required, properties) => ({
    type: 'object',
    properties: properties.toJS(),
    required: required.toJS(),
  })
);

export const getIsPosting = createSelector(
  getFetchPostSurveyResponses,
  (fetchPost) => fetchPost.get('isLoading')
);
