import React, { ReactNode, MouseEvent } from 'react';

import { Grid, IconButton, Tooltip } from '@eGroupTeam/material';

export interface QuestionsToolbarProps {
  title: string;
  icon: ReactNode;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const ToolButton = ({ title, icon, onClick, ...other }) => (
  <Grid item {...other}>
    <Tooltip title={title}>
      <IconButton size="small" onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  </Grid>
);

export default ToolButton;
