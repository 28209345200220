import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import { combineEpics } from 'redux-observable';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  fetchGetSurveyResultRequest,
  fetchGetSurveyResultSuccess,
  fetchGetSurveyResultFailure,
  fetchGetSurveysRespondentCountRequest,
  fetchGetSurveysRespondentCountSuccess,
  fetchGetSurveysRespondentCountFailure,
  FETCH_GET_SURVEY_RESULT,
  FETCH_GET_SURVEYS_RESPONDENT_COUNT,
} from './redux';

export const fetchGetSurveyResultEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SURVEY_RESULT,
  apiName: 'fetchGetSurveyResult',
  fetchRequest: fetchGetSurveyResultRequest,
  handleSuccess: (response) => [
    fetchGetSurveyResultSuccess(fromJS(response.data || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetSurveyResultFailure(error))
    ),
});

export const fetchGetSurveysRespondentCountEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SURVEYS_RESPONDENT_COUNT,
  apiName: 'fetchGetSurveysRespondentCount',
  fetchRequest: fetchGetSurveysRespondentCountRequest,
  handleSuccess: (response) => [
    fetchGetSurveysRespondentCountSuccess(fromJS(response.data)),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetSurveysRespondentCountFailure(error))
    ),
});

export default combineEpics(
  fetchGetSurveyResultEpic,
  fetchGetSurveysRespondentCountEpic
);
