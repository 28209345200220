import React, { useEffect } from 'react';

import {
  Hidden,
  MobileStepper,
  Segment,
  SegmentContent,
  SegmentHeader,
  Typography,
  List,
  Button,
  CircularProgress,
  ListItem,
  ListItemIcon,
  Icomoon,
} from '@eGroupTeam/material';

import { Checkbox } from '@material-ui/core';
import Center from '@eGroupTeam/material-layout/Center';
import {
  closeDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import apis from 'redux/apisMember';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import { fromJS } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedOrg, getSelectedOrg } from 'redux/modules/common';
import CreateOrgDialog, { DIALOG } from 'components/CreateOrgDialog';
import ListItemBreakText from 'components/ListItemBreakText';
import useOrgs from 'utils/useOrgs';
import Toolbar from './Toolbar';
import { handleNextStep } from './actions';

const StepSelectOrg = ({ activeStep }) => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const { data, mutate } = useOrgs();
  const { excute: createOrg, isLoading } = useAxiosApiWrapper(
    apis.fetchPostOrganization
  );

  useEffect(() => {
    if (data?.source && data?.total > 0 && selectedOrg.isEmpty()) {
      dispatch(setSelectedOrg(fromJS(data?.source[0])));
    }
  }, [data?.source, data?.total, dispatch, selectedOrg]);

  const handleClick = (el) => () => {
    dispatch(setSelectedOrg(fromJS(el)));
  };

  const handleSubmit = (values) => {
    createOrg(values.toJS()).then((response) => {
      window.localStorage.setItem(
        'selectedOrgId',
        response.data.organizationId
      );
      mutate();
      dispatch(setSelectedOrg(fromJS(response.data)));
      dispatch(closeDialog(DIALOG));
    });
  };

  const handleNext = () => {
    dispatch(handleNextStep());
  };

  if (!data) {
    return (
      <Center height={200}>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <>
      <CreateOrgDialog onSubmit={handleSubmit} loading={isLoading} />
      <Segment variant="outlined">
        <SegmentHeader display="flex" alignItems="center">
          <div>
            <Typography variant="h5">選擇主辦單位</Typography>
            <Typography variant="body2" gutterBottom>
              選擇舉辦活動的單位
            </Typography>
          </div>
          <div style={{ flexGrow: 1 }} />
          <div>
            <Button
              size="large"
              variant="contained"
              color="info"
              rounded
              onClick={() => dispatch(openDialog(DIALOG))}
              startIcon={<Icomoon type="people-setting_2" color="white" />}
            >
              新增單位
            </Button>
          </div>
        </SegmentHeader>
        <SegmentContent>
          {data.total > 0 && (
            <List>
              {data.source.map((el) => (
                <ListItem
                  key={el.organizationId}
                  button
                  onClick={handleClick(el)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        selectedOrg.get('organizationId') === el.organizationId
                      }
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemBreakText primary={el.organizationName} />
                </ListItem>
              ))}
            </List>
          )}
          <Hidden smDown>
            <Toolbar
              activeStep={activeStep}
              onNextClick={handleNext}
              nextDisabled={!selectedOrg.has('organizationId')}
            />
          </Hidden>
        </SegmentContent>
      </Segment>
      <Hidden mdUp>
        <MobileStepper
          variant="progress"
          steps={6}
          position="bottom"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              color="primary"
              onClick={handleNext}
              disabled={!selectedOrg.has('organizationId')}
            >
              繼續
            </Button>
          }
          backButton={
            <Button size="small" disabled>
              上一步
            </Button>
          }
        />
      </Hidden>
    </>
  );
};

export default StepSelectOrg;
