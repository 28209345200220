import React, { FC } from 'react';

import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@eGroupTeam/material';
import { ApplyFieldResponse } from 'types';

export const DIALOG = 'applyFieldResponsesDialog';

export interface ApplyFieldResponsesDialogProps {
  applyFieldResponses?: ApplyFieldResponse[];
}

const ApplyFieldResponsesDialog: FC<
  ApplyFieldResponsesDialogProps & WithReduxDialogProps
> = (props) => {
  const { isOpen, handleClose, applyFieldResponses } = props;
  return (
    <Dialog open={isOpen} onClose={handleClose} disableEnforceFocus fullWidth>
      {applyFieldResponses && applyFieldResponses[0] && (
        <DialogTitle style={{ paddingBottom: 0 }}>
          {applyFieldResponses[0].applyFieldOptionName}
        </DialogTitle>
      )}
      <DialogContent>
        {applyFieldResponses ? (
          <List disablePadding>
            {applyFieldResponses.map((el) => (
              <ListItem disableGutters divider key={el.applyFieldResponseId}>
                <ListItemText primary={el.applyFieldResponseContent} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box padding={2} textAlign="center">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<
  HTMLDivElement,
  ApplyFieldResponsesDialogProps
>(ApplyFieldResponsesDialog);
