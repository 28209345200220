import { fetcher } from 'redux/apisOrg';
import { OrganizationModule, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
  organizationRoleId?: string;
};
const useOrgRoleModules = makeGetHook<
  EntityList<OrganizationModule>,
  PathParams
>(
  'organizations/{{organizationId}}/roles/{{organizationRoleId}}/modules',
  fetcher
);

export default useOrgRoleModules;
