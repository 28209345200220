import { combineEpics } from 'redux-observable';
import ActivityDashboardEpics from './ActivityDashboard/epics';
import ActivityFieldsEpics from './ActivityFields/epics';
import ActivityFilesEpics from './ActivityFiles/epics';
import ActivityNotificationEpics from './ActivityNotification/epics';
import ActivityCheckInEpics from './ActivityCheckIn/epics';
import ActivityCertificatesEpics from './ActivityCertificates/epics';
import ActivityPeopleEpics from './ActivityPeople/epics';

export default combineEpics(
  ActivityDashboardEpics,
  ActivityFieldsEpics,
  ActivityFilesEpics,
  ActivityNotificationEpics,
  ActivityCheckInEpics,
  ActivityCertificatesEpics,
  ActivityPeopleEpics
);
