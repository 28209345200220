import React from 'react';

import isEmail from 'validator/lib/isEmail';
import { reduxForm, Field, Form } from 'redux-form/immutable';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';

export const FORM = 'orgMemberForm';

const validate = (values) => {
  const errors: Record<string, string> = {};
  if (!values.get('organizationInvitationEmail')) {
    errors.organizationInvitationEmail = '「Email」是必填欄位';
  } else if (!isEmail(values.get('organizationInvitationEmail'))) {
    errors.organizationInvitationEmail = '「Email」格式錯誤';
  }
  return errors;
};

const OrgMemberForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Field
      name="organizationInvitationEmail"
      label="Email"
      component={TextLoadingField}
      fullWidth
      variant="outlined"
      required
      margin="normal"
    />
  </Form>
);

export default reduxForm({
  form: FORM,
  validate,
})(OrgMemberForm);
