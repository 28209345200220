import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { normalize } from 'normalizr';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  setEntities,
  setEntitiesArrayConcat,
} from '@eGroupTeam/redux-modules/immutable/entities';
import { closeDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  fetchPatchSurveyRequest,
  fetchPatchSurveySuccess,
  fetchPatchSurveyFailure,
  fetchGetSurveyQuestionsRequest,
  fetchGetSurveyQuestionsSuccess,
  fetchGetSurveyQuestionsFailure,
  fetchPatchSurveyQuestionRequest,
  fetchPatchSurveyQuestionSuccess,
  fetchPatchSurveyQuestionFailure,
  fetchDeleteSurveyQuestionRequest,
  fetchDeleteSurveyQuestionSuccess,
  fetchDeleteSurveyQuestionFailure,
  fetchPostSurveyQuestionRequest,
  fetchPostSurveyQuestionSuccess,
  fetchPostSurveyQuestionFailure,
  fetchPatchSurveyQuestionsOrderRequest,
  fetchPatchSurveyQuestionsOrderSuccess,
  fetchPatchSurveyQuestionsOrderFailure,
  fetchPostSurveyQuestionOptionRequest,
  fetchPostSurveyQuestionOptionSuccess,
  fetchPostSurveyQuestionOptionFailure,
  fetchPatchSurveyQuestionOptionRequest,
  fetchPatchSurveyQuestionOptionSuccess,
  fetchPatchSurveyQuestionOptionFailure,
  fetchDeleteSurveyQuestionOptionRequest,
  fetchDeleteSurveyQuestionOptionSuccess,
  fetchDeleteSurveyQuestionOptionFailure,
  fetchPatchSurveyQuestionOptionsOrderRequest,
  fetchPatchSurveyQuestionOptionsOrderSuccess,
  fetchPatchSurveyQuestionOptionsOrderFailure,
  fetchGetSurveyQuestion,
  fetchGetSurveyQuestionRequest,
  fetchGetSurveyQuestionSuccess,
  fetchGetSurveyQuestionFailure,
  FETCH_PATCH_SURVEY,
  FETCH_GET_SURVEY_QUESTIONS,
  FETCH_POST_SURVEY_QUESTION,
  FETCH_PATCH_SURVEY_QUESTION,
  FETCH_DELETE_SURVEY_QUESTION,
  FETCH_PATCH_SURVEY_QUESTIONS_ORDER,
  FETCH_POST_SURVEY_QUESTION_OPTION,
  FETCH_PATCH_SURVEY_QUESTION_OPTION,
  FETCH_DELETE_SURVEY_QUESTION_OPTION,
  FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER,
  FETCH_GET_SURVEY_QUESTION,
} from './redux';
import { DIALOG } from './SurveyEditDialog';

export const fetchPatchSurveyEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_SURVEY,
  apiName: 'fetchPatchSurvey',
  debounceTime: 500,
  fetchRequest: fetchPatchSurveyRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data, schema.survey);
    return [
      setEntities(fromJS(entities)),
      fetchPatchSurveySuccess(fromJS(result)),
      closeDialog(DIALOG),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchSurveyFailure(error))
    ),
});

export const fetchGetSurveyQuestionsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SURVEY_QUESTIONS,
  apiName: 'fetchGetSurveyQuestions',
  fetchRequest: fetchGetSurveyQuestionsRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.questions
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetSurveyQuestionsSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetSurveyQuestionsFailure(error))
    ),
});

export const fetchPostSurveyQuestionEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_SURVEY_QUESTION,
  apiName: 'fetchPostSurveyQuestion',
  fetchRequest: fetchPostSurveyQuestionRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data, schema.question);
    return [
      setEntities(fromJS(entities)),
      fetchPostSurveyQuestionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostSurveyQuestionFailure(error))
    ),
});

export const fetchGetSurveyQuestionEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SURVEY_QUESTION,
  apiName: 'fetchGetSurveyQuestion',
  fetchRequest: fetchGetSurveyQuestionRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data, schema.question);
    return [
      setEntities(fromJS(entities)),
      fetchGetSurveyQuestionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetSurveyQuestionFailure(error))
    ),
});

export const fetchPatchSurveyQuestionEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_SURVEY_QUESTION,
  apiName: 'fetchPatchSurveyQuestion',
  fetchRequest: fetchPatchSurveyQuestionRequest,
  debounceTime: 500,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data, schema.question);
    return [
      setEntities(fromJS(entities)),
      fetchPatchSurveyQuestionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchSurveyQuestionFailure(error))
    ),
});

export const fetchDeleteSurveyQuestionEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_SURVEY_QUESTION,
  apiName: 'fetchDeleteSurveyQuestion',
  fetchRequest: fetchDeleteSurveyQuestionRequest,
  handleSuccess: (response) => [
    fetchDeleteSurveyQuestionSuccess(response.data.questionId),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteSurveyQuestionFailure(error))
    ),
});

export const fetchPatchSurveyQuestionsOrderEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_SURVEY_QUESTIONS_ORDER,
  apiName: 'fetchPatchSurveyQuestionsOrder',
  fetchRequest: fetchPatchSurveyQuestionsOrderRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data, schema.questions);
    return [
      fetchPatchSurveyQuestionsOrderSuccess(fromJS(result)),
      setEntities(fromJS(entities)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchSurveyQuestionsOrderFailure(error))
    ),
});

export const fetchPostSurveyQuestionOptionEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_SURVEY_QUESTION_OPTION,
  apiName: 'fetchPostSurveyQuestionOption',
  fetchRequest: fetchPostSurveyQuestionOptionRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data, schema.question);
    return [
      setEntitiesArrayConcat(fromJS(entities)),
      fetchPostSurveyQuestionOptionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostSurveyQuestionOptionFailure(error))
    ),
});

export const fetchPatchSurveyQuestionOptionEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_SURVEY_QUESTION_OPTION,
  apiName: 'fetchPatchSurveyQuestionOption',
  fetchRequest: fetchPatchSurveyQuestionOptionRequest,
  debounceTime: 500,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data, schema.option);
    return [
      setEntities(fromJS(entities)),
      fetchPatchSurveyQuestionOptionSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchSurveyQuestionOptionFailure(error))
    ),
});

export const fetchDeleteSurveyQuestionOptionEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_SURVEY_QUESTION_OPTION,
  apiName: 'fetchDeleteSurveyQuestionOption',
  fetchRequest: fetchDeleteSurveyQuestionOptionRequest,
  handleSuccess: (response, { action }) => [
    fetchGetSurveyQuestion({
      organizationId: action.payload.organizationId,
      surveyId: action.payload.surveyId,
      questionId: action.payload.questionId,
    }),
    fetchDeleteSurveyQuestionOptionSuccess(response.data.optionId),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteSurveyQuestionOptionFailure(error))
    ),
});

export const fetchPatchSurveyQuestionOptionsOrderEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER,
  apiName: 'fetchPatchSurveyQuestionOptionsOrder',
  fetchRequest: fetchPatchSurveyQuestionOptionsOrderRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data, schema.question);
    return [
      setEntities(fromJS(entities)),
      fetchPatchSurveyQuestionOptionsOrderSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchSurveyQuestionOptionsOrderFailure(error))
    ),
});

export default combineEpics(
  fetchPatchSurveyEpic,
  fetchGetSurveyQuestionsEpic,
  fetchPostSurveyQuestionEpic,
  fetchPatchSurveyQuestionEpic,
  fetchDeleteSurveyQuestionEpic,
  fetchPatchSurveyQuestionsOrderEpic,
  fetchPostSurveyQuestionOptionEpic,
  fetchPatchSurveyQuestionOptionEpic,
  fetchDeleteSurveyQuestionOptionEpic,
  fetchPatchSurveyQuestionOptionsOrderEpic,
  fetchGetSurveyQuestionEpic
);
