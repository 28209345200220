import React, { FC, HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import SimpleAddress from '@eGroupTeam/material-module/SimpleAddress';
import findDeepValue from '@eGroupTeam/utils/findDeepValue';
import useTab from '@eGroupTeam/hooks/useTab';
import { ArrayField, Controller, useFieldArray, UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import Button from '@eGroupTeam/material/Button'
import Dialog from '@eGroupTeam/material/Dialog'
import DialogActions from '@eGroupTeam/material/DialogActions'
import DialogContent from '@eGroupTeam/material/DialogContent'
import Grid from '@eGroupTeam/material/Grid'
import IconButton from '@eGroupTeam/material/IconButton'
import Table from '@eGroupTeam/material/Table'
import TableBody from '@eGroupTeam/material/TableBody'
import TableCell from '@eGroupTeam/material/TableCell'
import TableRow from '@eGroupTeam/material/TableRow'
import TextField from '@eGroupTeam/material/TextField'
import Tooltip from '@eGroupTeam/material/Tooltip'
import Typography from '@eGroupTeam/material/Typography'
import { withStyles, Theme, createStyles, useTheme, useMediaQuery, Tabs, Tab, WithStyles } from '@eGroupTeam/material';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import * as yup from 'yup';
import { format } from '@eGroupTeam/utils/dateUtils';
import DialogHeaderActions from 'components/DialogHeaderActions';
import clsx from 'clsx';
import { ActivityClassDateFormInput, ActivityClassFormInput, ActivityFormInput } from 'types';
import ActivityClassDay from '../ActivityClassDay';

const styles = (theme: Theme) =>
  createStyles({
    iconGrid: {
      width: 40,
    },
    fieldGrid: {
      flex: 1,
    },
    button: {
      marginLeft: theme.spacing(),
    },
    error: {
      '& .MuiTableCell-root': {
        borderColor: theme.egPalette.error[1],
        color: theme.egPalette.error[1],
      },
    },
    tabs: {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  });

export interface ActivityClassProps extends HTMLAttributes<HTMLDivElement> {
  name: string
  index: number
  defaultOpen?: boolean
  disabledDelete?: boolean
  remove: UseFieldArrayMethods<ActivityClassFormInput>["remove"];
  defaultValues: Partial<ArrayField<Record<string, unknown>, "id">>
  control: UseFormMethods<ActivityFormInput>["control"]
  register: UseFormMethods<ActivityFormInput>["register"]
  watch: UseFormMethods<ActivityFormInput>["watch"]
  clearErrors: UseFormMethods<ActivityFormInput>["clearErrors"]
  errors: UseFormMethods<ActivityFormInput>["errors"]
}

const ActivityClass: FC<ActivityClassProps & WithStyles<typeof styles>> = ({
  classes,
  disabledDelete,
  name,
  index,
  remove,
  defaultOpen = false,
  defaultValues,
  control,
  register,
  watch,
  clearErrors,
  errors,
  ...other
}) => {
  const { value, handleChange } = useTab('activityClass', defaultValues.activityClassMeetingUrl ? 1 : 0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(defaultOpen);
  const methods = useFieldArray({
    control,
    name: `${name}.activityClassDayList`
  });
  const nameError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassName`
  );
  const limitError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassLimitCount`
  );
  const placeError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassPlace`
  );
  const meetingUrlError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassMeetingUrl`
  );

  useEffect(() => {
    if (methods.fields.length === 0) {
      methods.append({
        activityClassDate: null,
        activityClassDayStartTimeString: null,
        activityClassDayEndTimeString: null,
      });
    }
  }, [methods]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const hasError = nameError || limitError || placeError || meetingUrlError;

  return (
    <div {...other}>
      <input
        type="hidden"
        name={`${name}.activityClassId`}
        value={(defaultValues.activityClassId as string)}
        ref={register}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        keepMounted
        fullScreen={matches}
      >
        <DialogHeaderActions>
          <Tooltip title="取消變更">
            <IconButton onClick={handleClose} size="medium-small">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogHeaderActions>
        <DialogContent>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item container xs={12} spacing={1}>
              <Grid item className={classes.iconGrid}>
                <PeopleOutlinedIcon />
              </Grid>
              <Grid item className={classes.fieldGrid}>
                <Controller
                  as={TextField}
                  control={control}
                  defaultValue={defaultValues.activityClassName}
                  name={`${name}.activityClassName`}
                  fullWidth
                  label="場次名稱"
                  error={!!nameError}
                  helperText={nameError?.message}
                  variant="filled"
                  size="small"
                  required
                  rules={{
                    required: '「場次名稱」是必填欄位',
                    maxLength: {
                      value: 30,
                      message: '長度不能超過30個字元'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item className={classes.iconGrid} />
              <Grid item className={classes.fieldGrid}>
                <Controller
                  as={TextField}
                  control={control}
                  defaultValue={defaultValues.activityClassLimitCount}
                  name={`${name}.activityClassLimitCount`}
                  label="場次人數"
                  fullWidth
                  required
                  variant="filled"
                  size="small"
                  inputProps={{
                    type: 'number',
                    min: 1,
                  }}
                  error={!!limitError}
                  helperText={limitError?.message || '將依照活動人數收取費用'}
                  rules={{
                    min: {
                      value: 1,
                      message: '「場次人數」是必填欄位'
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item className={classes.iconGrid}/>
              <Grid item className={classes.fieldGrid}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={value}
                  onChange={(_, v) => {
                    clearErrors()
                    handleChange(v)
                  }}
                  className={classes.tabs}
                >
                  <Tab label="現場活動" />
                  <Tab label="線上活動" />
                </Tabs>
              </Grid>
            </Grid>
            {
              value === 0 && (
                <>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item className={classes.iconGrid}>
                      <PlaceOutlinedIcon />
                    </Grid>
                    <Grid item className={classes.fieldGrid}>
                      <Controller
                        as={TextField}
                        control={control}
                        defaultValue={defaultValues.activityClassPlace}
                        name={`${name}.activityClassPlace`}
                        label="地點名稱"
                        fullWidth
                        required
                        variant="filled"
                        size="small"
                        error={!!placeError}
                        helperText={placeError?.message || '地點名稱(例如，台北101)'}
                        rules={{
                          required: '「地點名稱」是必填欄位',
                          maxLength: {
                            value: 150,
                            message: '長度不能超過150個字元'
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Controller
                    as={SimpleAddress}
                    control={control}
                    defaultValue={defaultValues.activityClassMail}
                    name={`${name}.activityClassMail`}
                    MuiTextFieldProps={{
                      variant: 'filled',
                      size: 'small',
                      fullWidth: true,
                      style: {
                        minWidth: 120,
                      },
                    }}
                    cityProps={{
                      label: '場次縣市',
                    }}
                    areaProps={{
                      label: '場次地區',
                    }}
                    disableZipCode
                    renderFields={(field1: ReactNode, field2: ReactNode) => (
                      <>
                        <Grid item container xs={12} spacing={1}>
                          <Grid item className={classes.iconGrid} />
                          <Grid item className={classes.fieldGrid}>
                            {field1}
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={1}>
                          <Grid item className={classes.iconGrid} />
                          <Grid item className={classes.fieldGrid}>
                            {field2}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  />
                  <Grid item container xs={12} spacing={1}>
                    <Grid item className={classes.iconGrid} />
                    <Grid item className={classes.fieldGrid}>
                      <Controller
                        as={TextField}
                        control={control}
                        defaultValue={defaultValues.activityClassAddress}
                        name={`${name}.activityClassAddress`}
                        label="場次地址"
                        fullWidth
                        variant="filled"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </>
              )
            }
            {
              value === 1 && (
                <Grid item container xs={12} spacing={1}>
                  <Grid item className={classes.iconGrid}>
                    <LinkIcon />
                  </Grid>
                  <Grid item className={classes.fieldGrid}>
                    <Controller
                      as={TextField}
                      control={control}
                      defaultValue={defaultValues.activityClassMeetingUrl}
                      name={`${name}.activityClassMeetingUrl`}
                      label="線上會議連結"
                      fullWidth
                      required
                      variant="filled"
                      size="small"
                      error={!!meetingUrlError}
                      helperText={meetingUrlError?.message || '填寫您所使用的線上會議連結，例如： google meet 會議連結、youtube 直播連結'}
                      rules={{
                        required: '「線上會議連結」是必填欄位',
                        maxLength: {
                          value: 255,
                          message: '長度不能超過255個字元'
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )
            }
            <Grid item container xs={12} spacing={1}>
              <Grid item className={classes.iconGrid}>
                <DescriptionOutlinedIcon />
              </Grid>
              <Grid item className={classes.fieldGrid}>
                <Controller
                  as={TextField}
                  control={control}
                  defaultValue={defaultValues.activityClassDescription}
                  name={`${name}.activityClassDescription`}
                  label="場次敘述"
                  variant="filled"
                  size="small"
                  multiline
                  fullWidth
                  rows="3"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={1}>
              <Grid item className={classes.iconGrid} />
              <Grid item className={classes.fieldGrid}>
                <Typography variant="h6">場次時間</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item className={classes.iconGrid}>
                <CalendarTodayIcon />
              </Grid>
              <Grid item className={classes.fieldGrid}>
                {methods.fields.map((field, index2) => (
                  <ActivityClassDay
                    key={field.id}
                    name={`${name}.activityClassDayList[${index2}]`}
                    control={control}
                    index={index2}
                    remove={methods.remove}
                    disabledDelete={methods.fields.length === 1}
                    errors={errors}
                    defaultValues={field}
                    style={{ marginBottom: 8 }}
                  />
                ))}
                <Button
                  fullWidth
                  color="primary"
                  onClick={() => {
                    methods.append({
                      activityClassDate: null,
                      activityClassDayStartTimeString: null,
                      activityClassDayEndTimeString: null,
                    });
                  }}
                >
                  新增時間
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              remove(index);
              handleClose();
            }}
            variant="contained"
            rounded
            color="error"
            disabled={disabledDelete}
            className={classes.button}
          >
            刪除此場次
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            rounded
            color="info"
            className={classes.button}
          >
            儲存
          </Button>
        </DialogActions>
      </Dialog>
      <Table>
        <TableBody>
          <TableRow
            hover
            onClick={handleOpen}
            className={clsx(hasError && classes.error)}
          >
            <TableCell style={{ width: 150 }}>
              {hasError
                ? '場次資訊有誤'
                : watch(`${name}.activityClassName`) || '編輯場次資訊'}
            </TableCell>
            <TableCell>
              {(watch(`${name}.activityClassDayList`) as ActivityClassDateFormInput[]).map((el, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div style={{ marginTop: 8, marginBottom: 8 }} key={index}>
                  {format(el.activityClassDate, 'PP')}{' '}
                  {format(el.activityClassDayStartTimeString, 'p')}{' '}
                  {el.activityClassDayEndTimeString && '~'}{' '}
                  {format(el.activityClassDayEndTimeString, 'p')}
                </div>
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(ActivityClass);
