import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@eGroupTeam/material';

import Typography from '@eGroupTeam/material/Typography';
import Divider from '@eGroupTeam/material/Divider';

const PageHeader = ({ classes, title, subtitle, actions, disableDivider }) => (
  <>
    <div className={classes.root}>
      <div className={classes.grid}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2" gutterBottom>
          {subtitle}
        </Typography>
      </div>
      <div className={classes.grid}>{actions}</div>
    </div>
    {!disableDivider && <Divider className={classes.divider} />}
  </>
);

PageHeader.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * main content
   */
  title: PropTypes.string,
  /**
   * secondary content
   */
  subtitle: PropTypes.string,
  /**
   * header actions
   */
  actions: PropTypes.node,
  /**
   * Set `true` to disable divider.
   */
  disableDivider: PropTypes.bool,
};

export default withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0, -1),
  },
  grid: {
    margin: theme.spacing(0, 1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))(PageHeader);
