import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Hidden,
  MobileStepper,
  Segment,
  SegmentContent,
  SegmentHeader,
  Typography,
} from '@eGroupTeam/material';
import { ActivityFormInput } from 'types';
import { ActivityType } from 'enums'
import { handlePrevStep } from './actions';
import ActivityCreateForm from './ActivityCreateForm';
import Toolbar from './Toolbar';

export interface StepEditActivityProps {
  activeStep: number;
  activityType: ActivityType;
  onSubmit: SubmitHandler<ActivityFormInput>;
  defaultValues: ActivityFormInput;
}

const StepEditActivity: FC<StepEditActivityProps> = ({
  activeStep,
  activityType,
  onSubmit,
  defaultValues,
}) => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const formMethods = useForm<ActivityFormInput>({
    defaultValues
  });
  const { isSubmitted } = formMethods.formState;

  const handleNext = () => {
    formMethods.handleSubmit(onSubmit)();
  };

  const handlePrev = () => {
    dispatch(handlePrevStep());
  };

  return (
    <>
      <Segment variant="outlined">
        <SegmentHeader>
          <Typography variant="h5">填寫活動資料</Typography>
          <Typography variant="body2" gutterBottom>
            填寫活動基本資料，開始您的活動吧!
          </Typography>
        </SegmentHeader>
        <SegmentContent>
          <ActivityCreateForm
            organizationId={selectedOrg.get('organizationId')}
            activityType={activityType}
            {...formMethods}
          />
          <Hidden smDown>
            <Toolbar
              activeStep={activeStep}
              onNextClick={handleNext}
              onPrevClick={handlePrev}
              nextDisabled={isSubmitted && Object.keys(formMethods.errors).length > 0}
            />
          </Hidden>
        </SegmentContent>
      </Segment>
      <Hidden mdUp>
        <MobileStepper
          variant="progress"
          steps={6}
          position="bottom"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              color="primary"
              onClick={handleNext}
              disabled={isSubmitted && Object.keys(formMethods.errors).length > 0}
            >
              繼續
            </Button>
          }
          backButton={
            <Button size="small" onClick={handlePrev}>
              上一步
            </Button>
          }
        />
      </Hidden>
    </>
  );
};

export default StepEditActivity;
