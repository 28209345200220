import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchGetSurveyResult,
  fetchGetSurveyResultRequest,
  fetchGetSurveyResultSuccess,
  fetchGetSurveyResultFailure,

  fetchGetSurveysRespondentCount,
  fetchGetSurveysRespondentCountRequest,
  fetchGetSurveysRespondentCountSuccess,
  fetchGetSurveysRespondentCountFailure,
} = actionCreators.mainPages.survey.surveyAnalysis;

/** Types */
export const FETCH_GET_SURVEY_RESULT = fetchGetSurveyResult().type;
export const FETCH_GET_SURVEY_RESULT_REQUEST = fetchGetSurveyResultRequest()
  .type;
export const FETCH_GET_SURVEY_RESULT_SUCCESS = fetchGetSurveyResultSuccess()
  .type;
export const FETCH_GET_SURVEY_RESULT_FAILURE = fetchGetSurveyResultFailure()
  .type;

export const FETCH_GET_SURVEYS_RESPONDENT_COUNT = fetchGetSurveysRespondentCount()
  .type;
export const FETCH_GET_SURVEYS_RESPONDENT_COUNT_REQUEST = fetchGetSurveysRespondentCountRequest()
  .type;
export const FETCH_GET_SURVEYS_RESPONDENT_COUNT_SUCCESS = fetchGetSurveysRespondentCountSuccess()
  .type;
export const FETCH_GET_SURVEYS_RESPONDENT_COUNT_FAILURE = fetchGetSurveysRespondentCountFailure()
  .type;

/** Selectors */

/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'survey', 'surveyAnalysis'], Map());

const getFetchGetSurveyResult = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetSurveyResult', Map())
);

const getFetchGetSurveysRespondentCount = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetSurveysRespondentCount', Map())
);

export const getSurveysResult = createSelector(
  getFetchGetSurveyResult,
  (fetchResult) => fetchResult.get('response')
);

export const getSurveysRespondentCount = createSelector(
  getFetchGetSurveysRespondentCount,
  (fetchResult) => fetchResult.get('response')
);
