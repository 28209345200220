import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchGetActivityUserTrainQrCode,
  fetchGetActivityUserTrainQrCodeRequest,
  fetchGetActivityUserTrainQrCodeSuccess,
  fetchGetActivityUserTrainQrCodeFailure,

  fetchPostActivityUserModelTrainCheck,
  fetchPostActivityUserModelTrainCheckRequest,
  fetchPostActivityUserModelTrainCheckSuccess,
  fetchPostActivityUserModelTrainCheckFailure,
} = actionCreators.landingPages.activity.activityApplyTrainQrcode;

/** Types */
export const FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE = fetchGetActivityUserTrainQrCode()
  .type;
export const FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE_REQUEST = fetchGetActivityUserTrainQrCodeRequest()
  .type;
export const FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE_SUCCESS = fetchGetActivityUserTrainQrCodeSuccess()
  .type;
export const FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE_FAILURE = fetchGetActivityUserTrainQrCodeFailure()
  .type;

export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK = fetchPostActivityUserModelTrainCheck()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_REQUEST = fetchPostActivityUserModelTrainCheckRequest()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_SUCCESS = fetchPostActivityUserModelTrainCheckSuccess()
  .type;
export const FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK_FAILURE = fetchPostActivityUserModelTrainCheckFailure()
  .type;

/** Selectors */

/**
 * ApiStates
 */
const getApiStates = (state) =>
  state.getIn(
    ['apis', 'landingPages', 'activity', 'activityApplyTrainQrcode'],
    Map()
  );

const getFetchGetActivityUserTrainQrCode = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetActivityUserTrainQrCode', Map())
);

const getFetchPostActivityUserModelTrainCheck = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostActivityUserModelTrainCheck', Map())
);

export const getActivityUserQrCodeToken = createSelector(
  getFetchGetActivityUserTrainQrCode,
  (fetchGet) => fetchGet.get('response')
);

export const getActivityUserModelTrainCheck = createSelector(
  getFetchPostActivityUserModelTrainCheck,
  (fetchGet) => fetchGet.get('response')
);
