import React from 'react';
import { Typography } from '@eGroupTeam/material';

import Center from '@eGroupTeam/material-layout/Center';

const NoFound = () => (
  <Center>
    <Typography variant="h5" align="center">
      哎呀，好像找不到這個頁面了。
    </Typography>
  </Center>
);

export default NoFound;
