import React from 'react';
import PropTypes from 'prop-types';

import { isMobilePhone } from 'validator';
import { useSelector } from 'react-redux';
import { makeStyles } from '@eGroupTeam/material';
import { reduxForm, Field } from 'redux-form/immutable';

import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import Button from '@eGroupTeam/material/Button';
import { getIsLoading } from './redux';

export const FORM = 'phoneFillSurveyForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px 0 0',
  },
  button: {
    marginTop: '32px',
  },
}));

const validate = (values) => {
  const errors = {};
  if (!values.has('userPhone')) {
    errors.userPhone = '「手機號碼」是必填欄位';
  } else if (!isMobilePhone(values.get('userPhone'), 'zh-TW')) {
    errors.userPhone = '「手機號碼」格式錯誤';
  }
  return errors;
};

const PhoneFillSurveyForm = ({ handleSubmit, submitting }) => {
  const isLoading = useSelector(getIsLoading);
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Field
        name="userPhone"
        label="手機號碼"
        fullWidth
        component={TextLoadingField}
        autoFocus
        variant="outlined"
        required
      />
      <Button
        className={classes.button}
        loading={isLoading}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting || isLoading}
      >
        確認
      </Button>
    </form>
  );
};

PhoneFillSurveyForm.propTypes = {
  // redux form
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: FORM,
  validate,
})(PhoneFillSurveyForm);
