import React, { useState } from 'react';

import unique from '@eGroupTeam/utils/unique';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import queryString from 'query-string';

import RatioImage from '@eGroupTeam/material/RatioImage';
import { Redirect, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  makeStyles,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Divider,
} from '@eGroupTeam/material';
import s3Url from 'utils/s3Url';
import { Alert, AlertTitle } from '@material-ui/lab';
import Checkbox from '@eGroupTeam/material/Checkbox';
import { Activity, ActivityClass } from 'types';
import ActivityClassDayList from '../ActivityClassDayList';
import ActivityClassPlace from '../ActivityClassPlace';
import ActivityClassDescription from '../ActivityClassDescription';
import ActivityAppBar from '../ActivityAppBar';
import ActivityApplyPotentialDialog, {
  DIALOG,
} from '../ActivityApplyPotentialDialog';
import ActivityClassOnlineMeeting from '../ActivityClassOnlineMeeting';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  activityInfo: {
    padding: theme.spacing(2),
  },
  classInfo: {
    '&:hover': {
      '& $applyBtn': {
        textDecoration: 'none',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  applyBtn: {},
  actions: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

const ActivitySelectApplyClass = ({ activity: activityProp }) => {
  const activity: Activity = activityProp.toJS()
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedActivityClassIds, setSelectedActivityClassIds] = useState<
    string[]
  >([]);

  const isAllowSelectMultipleActivityClass = activity.isAllowSelectMultipleActivityClass === 1;
  const { activityClassList } = activity;

  const handleContact = () => {
    dispatch(openDialog(DIALOG));
  };

  const handleChange = (activityClass: ActivityClass) => (e) => {
    if (e.target.checked) {
      setSelectedActivityClassIds((val) =>
        unique([...val, activityClass.activityClassId])
      );
    } else {
      setSelectedActivityClassIds((val) =>
        val.filter((el) => el !== activityClass.activityClassId)
      );
    }
  };

  const handleNext = () => {
    history.push(
      `/activities/${activity.activityId}/apply?${queryString.stringify({
        activityClassIds: selectedActivityClassIds,
      })}`
    );
  };

  const renderBtn = (activityClass: ActivityClass) => {
    const isEnd = Boolean(activityClass.isEnd);
    const isFull = Boolean(activityClass.isFull);
    const isRelease = Boolean(activity.isRelease);
    const disabled = !isRelease || isEnd || isFull;
    let text = '報名此場次';
    if (!isRelease) {
      text = '尚未開放報名';
    }
    if (isEnd) {
      text = '場次已結束';
    }
    if (isFull) {
      text = '場次已額滿';
    }
    if (isAllowSelectMultipleActivityClass) {
      return (
        <Checkbox
          onClick={handleChange(activityClass)}
          label={text}
          color="primary"
          disabled={disabled}
        />
      );
    }
    return (
      <Button
        muiButtonClassName={clsx({
          [classes.applyBtn]: !disabled,
        })}
        variant="outlined"
        color="primary"
        disabled={disabled}
        fullWidth
        onClick={() => {
          history.push(
            `/activities/${activity.activityId}/apply?${queryString.stringify({
              activityClassIds: [activityClass.activityClassId],
            })}`
          );
        }}
      >
        {text}
      </Button>
    );
  };

  if (Object.keys(activity).length === 0) {
    return <Redirect to="/activity-404" />;
  }

  if (activityClassList.length === 1) {
    const activityClass = activityClassList[0];
    const isFull = Boolean(activityClass?.isFull);

    if (isFull) {
      return <Redirect to={`/activities/${activity.activityId}`} />;
    }

    return (
      <Redirect
        to={`/activities/${activity.activityId}/apply?${queryString.stringify({
          activityClassIds: [activityClass?.activityClassId],
        })}`}
      />
    );
  }

  return (
    <>
      <ActivityApplyPotentialDialog activityId={activity.activityId} />
      <Helmet>
        <title>[場次選擇] {activity.activityName}</title>
      </Helmet>
      <ActivityAppBar position="static" />
      <Container>
        <main className={classes.main}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Paper>
                <RatioImage
                  src={s3Url(activity.activityCoverPath)}
                  fit="cover"
                  ratio="840:620"
                  alt={activity.activityName}
                />
                <div className={classes.activityInfo}>
                  <Typography variant="body1" gutterBottom>
                    <strong>{activity.activityName}</strong>
                  </Typography>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              {isAllowSelectMultipleActivityClass && (
                <Alert security="info" className={classes.alert}>
                  <AlertTitle>您可以同時報名，不同時段的多個場次。</AlertTitle>
                </Alert>
              )}
              {activity.activityClassList.map((activityClass) => {
                const isFull = Boolean(activityClass.isFull);
                return (
                  <Paper
                    key={activityClass.activityClassId}
                    square={false}
                    className={clsx(classes.paper, classes.classInfo)}
                  >
                    <Typography variant="h6" gutterBottom noWrap>
                      {activityClass.activityClassName}
                    </Typography>
                    <Divider />
                    <ActivityClassDayList activityClass={activityClass} dense />
                    {
                      activityClass.activityClassPlace && (
                        <ActivityClassPlace
                          activityType={activity.activityType}
                          activityClass={activityClass}
                          dense
                        />
                      )
                    }
                    {
                      activityClass.activityClassMeetingUrl && (
                        <ActivityClassOnlineMeeting />
                      )
                    }
                    {activityClass.activityClassDescription && activityClass.activityClassDescription !== '' && (
                      <ActivityClassDescription
                        activityClass={activityClass}
                        dense
                      />
                    )}
                    <div className={classes.actions}>
                      {isFull ? (
                        <>
                          <Typography color="secondary" gutterBottom>
                            活動已額滿您可以嘗試聯絡主辦單位
                          </Typography>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleContact}
                            fullWidth
                          >
                            聯絡主辦人
                          </Button>
                        </>
                      ) : (
                        renderBtn(activityClass)
                      )}
                    </div>
                  </Paper>
                );
              })}
              {isAllowSelectMultipleActivityClass && (
                <Button
                  onClick={handleNext}
                  fullWidth
                  color="primary"
                  size="large"
                  variant="contained"
                  disabled={selectedActivityClassIds.length === 0}
                >
                  下一步
                </Button>
              )}
            </Grid>
          </Grid>
        </main>
      </Container>
    </>
  );
};

export default ActivitySelectApplyClass;
