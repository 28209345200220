import React from 'react';

import useOrgOrders from 'utils/useOrgOrders';
import { format } from '@eGroupTeam/utils/dateUtils';
import { useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';
import { PaymentOrder } from 'types';
import { PaymentOrderStatus, PaymentOrderType } from 'enums';

import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import Main from '@eGroupTeam/material-layout/Main';
import {
  TableRow,
  TableCell,
  Typography,
  Container,
  Paper,
} from '@eGroupTeam/material';
import Currency from 'components/Currency';
import { useHistory } from 'react-router';
import PaymentStatusTag from 'components/PaymentStatusTag';

const Orders = () => {
  const history = useHistory();
  const selectedOrg = useSelector(getSelectedOrg);
  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    page,
    rowsPerPage,
  } = useDataTable('orders', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });
  const { data, isValidating, response } = useOrgOrders(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    payload
  );

  const renderColumns = (rowData: string[]) => (
    <TableRow>
      {rowData.map((el) => (
        <TableCell key={el}>
          <Typography variant="body2">{el}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );

  const renderDataRow = (rowData: unknown) => {
    const order = rowData as PaymentOrder;
    return (
      <TableRow
        key={order.paymentOrderId}
        hover
        onClick={() => {
          history.push(`/me/micepoint/orders/${order.paymentOrderId}`);
        }}
      >
        <TableCell>
          {order.paymentOrderId}
        </TableCell>
        <TableCell>
          {format(order.paymentOrderCreateDateString, 'PP pp')}
        </TableCell>
        <TableCell>
          {PaymentOrderType[order.paymentOrderType]}
        </TableCell>
        <TableCell>
          <PaymentStatusTag success={order.paymentOrderStatus === 3} error={order.paymentOrderStatus === 4}>
            {PaymentOrderStatus[order.paymentOrderStatus]}
          </PaymentStatusTag>
        </TableCell>
        <TableCell>
          <Currency
            value={order.paymentOrderTotalAmount}
            isMicepoint={order.paymentOrderType === 3}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Main>
      <Container>
        <Paper>
          <DataTable
            minWidth={1200}
            title="訂單紀錄"
            size="small"
            data={data?.source ?? []}
            SearchBarProps={{
              placeholder: '搜尋',
              onChange: handleSearchChange,
              defaultValue: payload.query,
            }}
            columns={[
              '訂單編號',
              '訂單時間',
              '支付方式',
              '付款狀態',
              '訂單金額',
            ]}
            serverSide
            renderColumns={renderColumns}
            renderDataRow={renderDataRow}
            loading={isValidating}
            isEmpty={response?.status === 204}
            MuiTablePaginationProps={{
              count: data?.total ?? 0,
              labelRowsPerPage: '每頁幾筆',
              page,
              rowsPerPage,
              onPageChange: handleChangePage,
              onRowsPerPageChange: handleRowsPerPageChange,
            }}
          />
        </Paper>
      </Container>
    </Main>
  );
};

export default Orders;
