import React, { FC } from 'react';

// TODO: need fixed.
// @ts-ignore
import { submit } from 'redux-form/immutable';
import { useDispatch } from 'react-redux';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import Button from '@eGroupTeam/material/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@eGroupTeam/material';

import ActivitySurveyAddForm, { FORM } from './ActivitySurveyAddForm';

export const DIALOG = 'activitySurveyAddDialog';

export interface ActivitySurveyAddDialogProps {
  onSubmit?: (values: any) => void;
  loading?: boolean;
}

const ActivitySurveyAddDialog: FC<
  ActivitySurveyAddDialogProps & WithReduxDialogProps
> = ({ isOpen, handleClose, onSubmit, loading }) => {
  const dispatch = useDispatch();

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={handleClose} fullWidth>
      <DialogTitle>新增問卷</DialogTitle>
      <DialogContent>
        <ActivitySurveyAddForm onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button loading={loading} onClick={() => dispatch(submit(FORM))}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<unknown, ActivitySurveyAddDialogProps>(
  ActivitySurveyAddDialog
);
