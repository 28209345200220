import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "webrtc-adapter";
import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import "jspdf/dist/polyfills.es";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min";
import "froala-lang/zh_tw";
import "@eGroupTeam/material/stylesheet/styles.css";
import "./stylesheets/froala.css";
import "./stylesheets/icomoon-styles.css";
import "./yupAddMethod";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== "production") {
  reportWebVitals(console.log);
}
