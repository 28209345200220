import React, { FC } from 'react';

import { Typography, Link, makeStyles } from '@eGroupTeam/material';
import RoomIcon from '@material-ui/icons/Room';
import ResponsiveEmbed from '@eGroupTeam/material-module/ResponsiveEmbed';
import { ActivityClass } from 'types';
import ActivityGrid, { ActivityGridProps } from './ActivityGrid';

const API_KEY = 'AIzaSyAoB8CIYtqVImRA-lPcmzUNOfpPpt_HrrE';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 0,
  },
  map: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
}));

export interface ActivityClassPlaceProps extends ActivityGridProps {
  activityType: number;
  activityClass: ActivityClass;
  showMap?: boolean;
}

const ActivityClassPlace: FC<ActivityClassPlaceProps> = (props) => {
  const { activityClass, showMap, activityType, ...other } = props;
  const classes = useStyles();
  return (
    <ActivityGrid {...other}>
      <>
        <RoomIcon color="action" />
        <Typography display="inline" variant="h6">
          {activityType === 1 ? '活動' : '場次'}地點
        </Typography>
      </>
      <div className={classes.content}>
        <Typography variant="body1">
          <strong>{activityClass.activityClassPlace}</strong>
        </Typography>
        {showMap && (
          <ResponsiveEmbed ratio="16:9" className={classes.map}>
            <iframe
              className={classes.iframe}
              frameBorder="0"
              src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${activityClass.activityClassCity}, ${activityClass.activityClassArea}, ${activityClass.activityClassAddress}`}
              allowFullScreen
              title="google map address"
            />
          </ResponsiveEmbed>
        )}
        <Link
          href={`http://maps.google.com/?q=${activityClass.activityClassCity} ${activityClass.activityClassArea} ${activityClass.activityClassAddress}`}
          variant="body1"
          target="_blank"
        >
          {activityClass.activityClassCity}
          {activityClass.activityClassArea}
          {activityClass.activityClassAddress}
        </Link>
      </div>
    </ActivityGrid>
  );
};

export default ActivityClassPlace;
