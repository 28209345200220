import axios from 'axios';
import queryString from 'query-string';
import config from './apiConfig';

export const fetcher = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/landing-page`,
  ...config,
});

export default {
  /**
   * Activity
   */

  /**
   * Get activity data.
   */
  fetchGetActivity: ({ activityId }) =>
    fetcher.get(`/activities/${activityId}`),

  /**
   * Get activity list.
   */
  fetchGetActivities: (payload) =>
    fetcher.get(`/activities?${queryString.stringify(payload)}`),

  /**
   * Send activity forms that user filled.
   */
  fetchPostUserApplyActivity: ({ activityId, ...payload }) =>
    fetcher.post(`/activities/${activityId}/user-applies/user`, payload),

  /**
   * Get custom fields for attended users to fill the forms.
   */
  fetchGetActivityApplyFields: ({ activityId }) =>
    fetcher.get(`/activities/${activityId}/apply-fields`),

  /**
   * Get activity classes(`sections` in other word).
   */
  fetchGetActivityClasses: ({ activityId, ...payload }) =>
    fetcher.get(
      `/activities/${activityId}/activity-class?${queryString.stringify(
        payload
      )}`
    ),

  /**
   * Get survey list of activity.
   */
  fetchGetActivitySurveys: ({ activityId, ...payload }) =>
    fetcher.get(
      `/activities/${activityId}/surveys?${queryString.stringify(payload)}`
    ),

  /**
   * Get survey of activity.
   */
  fetchGetActivitySurvey: ({ activityId, surveyId }) =>
    fetcher.get(`/activities/${activityId}/surveys/${surveyId}`),

  /**
   * Create activity survey.
   */
  fetchPostActivitySurvey: ({ activityId, surveyId, ...payload }) =>
    fetcher.post(`/activities/${activityId}/surveys/${surveyId}`, payload),

  /**
   * Get survey-questions of activity.
   */
  fetchGetActivitySurveyQuestions: ({ activityId, surveyId }) =>
    fetcher.get(`/activities/${activityId}/surveys/${surveyId}/questions`),

  /**
   * Check if phone number has attended activity.
   */
  fetchPostCheckUserActivityDuplicate: ({ activityId, ...payload }) =>
    fetcher.post(`/activities/${activityId}/check-duplicate-phone`, payload),

  /**
   * Get face detection quality.
   */
  fetchPostFaceDetection: ({ activityId, formData }) =>
    fetcher.post(`/activities/${activityId}/face/detection`, formData, {
      timeout: 3000,
    }),

  /**
   * Train user face by `qrcodeTokenId` in activity.
   */
  fetchPostActivityUserModelTrain: ({ activityId, qrcodeTokenId, formData }) =>
    fetcher.post(
      `/activities/${activityId}/user-model/train/${qrcodeTokenId}`,
      formData
    ),

  /**
   * Check if user has trained face by `qrcodeTokenId`.
   */
  fetchPostActivityUserModelTrainCheck: ({ activityId, qrcodeTokenId }) =>
    fetcher.post(
      `/activities/${activityId}/user-model/train-check/${qrcodeTokenId}`
    ),

  /**
   * Generate face training QR Code.
   */
  fetchGetActivityUserTrainQrCode: ({ activityId, userId }) =>
    fetcher.get(`/activities/${activityId}/user-model/${userId}/train-qrcode`),

  /**
   * Add potential users who want attend activity.
   */
  fetchPostActivityApplyPotential: ({ activityId, ...payload }) =>
    fetcher.post(
      `/activities/${activityId}/activity-apply-potentials`,
      payload
    ),

  /**
   * email更改報名狀態
   */
  fetchPatchUserApplyStatus: ({
    activityId,
    userApplyId,
    activityClassId,
    ...payload
  }) =>
    fetcher.patch(
      `/activities/${activityId}/user-applies/${userApplyId}/activity-class/${activityClassId}/apply-status`,
      payload
    ),

  /**
   * Survey
   */

  /**
   * Get survey data.
   */
  fetchGetSurvey: ({ surveyId }) => fetcher.get(`/surveys/${surveyId}`),

  /**
   * Get survey questions.
   */
  fetchGetSurveyQuestions: ({ surveyId }) =>
    fetcher.get(`/surveys/${surveyId}/questions`),

  /**
   * Send survey responses.
   */
  fetchPostSurveyResponses: ({ surveyId, ...payload }) =>
    fetcher.post(`/surveys/${surveyId}/responses`, payload),

  /**
   * User
   */

  /**
   * use `emailTokenId` or `phone` login to system
   * TODO: NOTICE: This has security risk should be fixed first.
   */
  fetchPostUserInfo: (payload) => fetcher.post('/user-info', payload),

  /**
   * Other
   */

  /**
   * 查詢會員接續要填寫的活動問卷
   */
  fetchPostCheckUserSurvey: (payload) =>
    fetcher.post('/check-user-survey', payload),

  fetchPostVerifySurveyQualify: ({ surveyId, ...payload }) =>
    fetcher.post(`/surveys/${surveyId}/identity-verification`, payload),
};
