import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles } from '@eGroupTeam/material';

import Paper from '@eGroupTeam/material/Paper';
import CardActionArea from '@eGroupTeam/material/CardActionArea';
import Box from '@eGroupTeam/material/Box';
import Typography from '@eGroupTeam/material/Typography';

const styles = (theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: '#fff',
    '&$disabled': {
      background: theme.palette.action.disabled,
    },
  },
  actionIcon: {
    fontSize: theme.spacing(6),
    color: 'inherit',
  },
  /* Styles applied to the root element if `disabled={true}`. */
  disabled: {},
});

const ActionCard = ({
  classes,
  onClick,
  actionIcon: ActionIcon,
  title,
  disabled,
}) => (
  <Paper
    className={clsx(classes.root, {
      [classes.disabled]: disabled,
    })}
  >
    <CardActionArea onClick={onClick} disabled={disabled}>
      <Box py={3} textAlign="center">
        <ActionIcon className={classes.actionIcon} />
        <Typography color="inherit">{title}</Typography>
      </Box>
    </CardActionArea>
  </Paper>
);

ActionCard.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The icon to display as the action indicator.
   */
  actionIcon: PropTypes.elementType.isRequired,
  /**
   * The content of the action description.
   */
  title: PropTypes.string.isRequired,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * @ignore
   * If `true`, the card will be displayed in a disabled state.
   */
  disabled: PropTypes.bool,
};

export default withStyles(styles)(ActionCard);
