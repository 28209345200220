import React, { FC } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
  withReduxDialog,
  openDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

// @ts-ignore
import { submit, getFormValues } from 'redux-form/immutable';

import { getSelectedOrg } from 'redux/modules/common';
import makeFormValueIsEqual from 'utils/makeFormValueIsEqual';

import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import Button from '@eGroupTeam/material/Button';
import Dialog from '@eGroupTeam/material/Dialog';
import DialogContent from '@eGroupTeam/material/DialogContent';
import DialogActions from '@eGroupTeam/material/DialogActions';
import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import AppBar from '@eGroupTeam/material/AppBar';
import Toolbar from '@eGroupTeam/material/Toolbar';
import Typography from '@eGroupTeam/material/Typography';
import IconButton from '@eGroupTeam/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  fetchPostPreviewEdm,
  fetchPostOrganizationEdmTemplate,
  fetchPatchOrganizationEdmTemplate,
  getCopyTemplate,
  getIsPosting,
  getIsPatching,
  getIsPreviewPosting,
} from './redux';
import NotificationTemplatesForm, { FORM } from './NotificationTemplatesForm';

export const DIALOG = 'notificationTemplatesDialog';

const CONFIRM_LEAVE_DIALOG = 'confirmLeaveCaseDataTalkEditDialog';
const ConfirmLeaveDialog = withReduxDialog(CONFIRM_LEAVE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);
const useFormValueIsEqual = makeFormValueIsEqual(FORM);

export interface NotificationTemplatesDialogProps {
  isCopy: boolean;
  isEditing: boolean;
  initialValues: any;
}

const NotificationTemplatesDialog: FC<
  NotificationTemplatesDialogProps & WithReduxDialogProps
> = ({ isOpen, handleClose, isCopy, isEditing, initialValues }) => {
  const dispatch = useDispatch();
  const isPosting = useSelector(getIsPosting);
  const isPatching = useSelector(getIsPatching);
  const isPreviewPosting = useSelector(getIsPreviewPosting);
  const isEqual = useFormValueIsEqual();
  const copyTemplate = useSelector(getCopyTemplate);
  const selectedOrg = useSelector(getSelectedOrg);
  const values: any = useSelector(getFormValues(FORM));

  const handlePreview = () => {
    const payload = values
      .set('organizationId', selectedOrg.get('organizationId'))
      .deleteAll([
        'organizationEdmTemplateCreateDateString',
        'organizationEdmTemplateUpdateDateString',
        'organization',
        'organizationEdmTemplatePath',
        'organizationEdmTemplateTypeString',
      ]);

    dispatch(fetchPostPreviewEdm(payload.toJS()));
  };

  const handleTemplateSubmit = (values) => {
    const payload = values.deleteAll([
      'organizationEdmTemplateCreateDateString',
      'organizationEdmTemplateUpdateDateString',
      'organization',
      'organizationEdmTemplatePath',
      'organizationEdmTemplateTypeString',
      'organizationEdmTemplateId',
    ]);

    if (isEditing && isCopy) {
      dispatch(
        fetchPatchOrganizationEdmTemplate({
          organizationId: selectedOrg.get('organizationId'),
          organizationEdmTemplateId: copyTemplate,
          ...payload.toJS(),
        })
      );
    } else if (isEditing && !isCopy) {
      dispatch(
        fetchPatchOrganizationEdmTemplate({
          organizationId: selectedOrg.get('organizationId'),
          organizationEdmTemplateId: values.get('organizationEdmTemplateId'),
          ...payload.toJS(),
        })
      );
    } else {
      dispatch(
        fetchPostOrganizationEdmTemplate({
          organizationId: selectedOrg.get('organizationId'),
          organizationEdmTemplateId: values.get('organizationEdmTemplateId'),
          ...payload.toJS(),
        })
      );
    }
  };

  const closeConform = () => {
    if (!isEqual) {
      dispatch(openDialog(CONFIRM_LEAVE_DIALOG));
    } else {
      handleClose();
    }
  };

  return (
    <>
      <ConfirmLeaveDialog
        primary="資料變更"
        message="你有未儲存的變更，確定要離開嗎？"
        onConfirm={handleClose}
      />
      <Dialog
        open={isOpen}
        fullScreen
        onClose={closeConform}
        disableEnforceFocus
      >
        <AppBar elevation={1} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeConform}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {isEditing ? '編輯' : '新增'}信件範本
            </Typography>
          </Toolbar>
        </AppBar>
        <NavbarBrick />
        <DialogContent>
          <NotificationTemplatesForm
            initialValues={initialValues}
            onSubmit={handleTemplateSubmit}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConform}>取消</Button>
          <Button
            loading={isPreviewPosting}
            variant="outlined"
            color="primary"
            onClick={handlePreview}
          >
            預覽
          </Button>
          <Button
            loading={isEditing ? isPatching : isPosting}
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(submit(FORM));
            }}
          >
            保存範本
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withReduxDialog(DIALOG)<unknown, NotificationTemplatesDialogProps>(
  NotificationTemplatesDialog
);
