import React, { FC, useState } from 'react';

import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { ActivityClass } from 'types';
import ReactSelect, { OptionType } from '@eGroupTeam/material-module/ReactSelect';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@eGroupTeam/material';

export const DIALOG = 'BatchCreateUserApplyActivityClassDialog';

export interface BatchCreateUserApplyActivityClassDialogProps {
  activityClasses?: ActivityClass[];
  onConfirm?: (selectedActivityClasses?: OptionType[]) => void;
  loading?: boolean
}

const BatchCreateUserApplyActivityClassDialog: FC<
BatchCreateUserApplyActivityClassDialogProps & WithReduxDialogProps
> = ({ isOpen, handleClose, activityClasses, onConfirm, loading }) => {
  const [
    selectedActivityClasses,
    setSelectedActivityClasses,
  ] = useState<OptionType[]>([]);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(selectedActivityClasses);
    }
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>批次新增報名場次</DialogTitle>
      <DialogContent>
        <ReactSelect
          MuiTextFieldProps={{
            fullWidth: true,
            variant: "outlined",
            size: "small",
            helperText: "請選擇要增加報名的場次送出"
          }}
          placeholder="選擇場次"
          isMulti
          isClearable
          value={selectedActivityClasses}
          onChange={(values) => {
            setSelectedActivityClasses(values as OptionType[])
          }}
          options={activityClasses?.map(el => ({
            value: el.activityClassId,
            label: el.activityClassName
          }))}
          isLoading={!activityClasses}
          menuPortalTarget={document.body}
          menuPosition="fixed"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>關閉</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleConfirm}
          loading={loading}
          disabled={selectedActivityClasses.length === 0}
        >
          批次增加報名
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<
  unknown,
  BatchCreateUserApplyActivityClassDialogProps
>(BatchCreateUserApplyActivityClassDialog);
