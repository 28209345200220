import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import { normalize } from 'normalizr';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  closeDialog,
  openDialog,
  setDialogData,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { setEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import { SNACKBAR, DIALOG } from 'App';
import {
  fetchGetActivityEdm,
  fetchGetActivityEdmRequest,
  fetchGetActivityEdmSuccess,
  fetchGetActivityEdmFailure,
  fetchGetActivityEdmUsersRequest,
  fetchGetActivityEdmUsersSuccess,
  fetchGetActivityEdmUsersFailure,
  fetchPostNotificationEmailRequest,
  fetchPostNotificationEmailSuccess,
  fetchPostNotificationEmailFailure,
  fetchPostTestNotificationEmailRequest,
  fetchPostTestNotificationEmailSuccess,
  fetchPostTestNotificationEmailFailure,
  fetchGetOrganizationEdmTemplatesRequest,
  fetchGetOrganizationEdmTemplatesSuccess,
  fetchGetOrganizationEdmTemplatesFailure,
  fetchPostOrganizationEdmTemplateRequest,
  fetchPostOrganizationEdmTemplateSuccess,
  fetchPostOrganizationEdmTemplateFailure,
  fetchPostPreviewEdmRequest,
  fetchPostPreviewEdmSuccess,
  fetchPostPreviewEdmFailure,
  FETCH_GET_ACTIVITY_EDM,
  FETCH_GET_ACTIVITY_EDM_USERS,
  FETCH_POST_NOTIFICATION_EMAIL,
  FETCH_POST_TEST_NOTIFICATION_EMAIL,
  FETCH_GET_ORGANIZATION_EDM_TEMPLATES,
  FETCH_POST_ORGANIZATION_EDM_TEMPLATE,
  FETCH_POST_PREVIEW_EDM,
} from './redux';
import { DIALOG as ACTIVITY_NOTIFICATION_DIALOG } from './ActivityNotificationDialog';

export const fetchGetActivityEdmEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_EDM,
  apiName: 'fetchGetActivityEdm',
  fetchRequest: fetchGetActivityEdmRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.activityEdms
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetActivityEdmSuccess(
        fromJS({
          total: response.data.total || 0,
          result,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityEdmFailure(error))
    ),
});

export const fetchGetActivityEdmUsersEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_EDM_USERS,
  apiName: 'fetchGetActivityEdmUsers',
  fetchRequest: fetchGetActivityEdmUsersRequest,
  handleSuccess: (response) => [
    fetchGetActivityEdmUsersSuccess(fromJS(response.data || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityEdmUsersFailure(error))
    ),
});

export const fetchPostNotificationEmailEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_NOTIFICATION_EMAIL,
  apiName: 'fetchPostNotificationEmail',
  fetchRequest: fetchPostNotificationEmailRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.activityEdm
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostNotificationEmailSuccess(fromJS(result)),
      setSnackbarData({
        name: SNACKBAR,
        message: '傳送成功！',
        variant: 'success',
      }),
      openSnackbar(SNACKBAR),
      fetchGetActivityEdm({
        organizationId: action.payload.organizationId,
        activityId: response.data.activityId,
        startIndex: 0,
        size: 10,
      }),
      closeDialog(ACTIVITY_NOTIFICATION_DIALOG),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostNotificationEmailFailure(error))
    ),
});

export const fetchPostTestNotificationEmailEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_TEST_NOTIFICATION_EMAIL,
  apiName: 'fetchPostTestNotificationEmail',
  fetchRequest: fetchPostTestNotificationEmailRequest,
  handleSuccess: (response) => [
    fetchPostTestNotificationEmailSuccess(fromJS(response.data)),
    setSnackbarData({
      name: SNACKBAR,
      message: '傳送成功！',
      variant: 'success',
    }),
    openSnackbar(SNACKBAR),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) => {
    if (error.response && error.response.status === 476) {
      return concat(
        of(
          setDialogData({
            name: DIALOG,
            primary: '寄信失敗',
            message: '已通知系統管理員。',
          })
        ),
        of(openDialog(DIALOG)),
        of(fetchPostTestNotificationEmailFailure(error))
      );
    }

    return concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostTestNotificationEmailFailure(error)),
      of(
        setDialogData({
          name: DIALOG,
          primary: '傳送失敗',
          message: '請先完成欄位。',
        })
      ),
      of(openDialog(DIALOG))
    );
  },
});

export const fetchGetOrganizationEdmTemplatesEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ORGANIZATION_EDM_TEMPLATES,
  apiName: 'fetchGetOrganizationEdmTemplates',
  fetchRequest: fetchGetOrganizationEdmTemplatesRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.organizationEdmTemplates
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetOrganizationEdmTemplatesSuccess(
        fromJS({
          result,
          total: response.data.total || 0,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetOrganizationEdmTemplatesFailure(error))
    ),
});

export const fetchPostOrganizationEdmTemplateEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ORGANIZATION_EDM_TEMPLATE,
  apiName: 'fetchPostOrganizationEdmTemplate',
  fetchRequest: fetchPostOrganizationEdmTemplateRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.organizationEdmTemplate
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostOrganizationEdmTemplateSuccess(fromJS(result)),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostOrganizationEdmTemplateFailure(error))
    ),
});

export const fetchPostPreviewEdmEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_PREVIEW_EDM,
  apiName: 'fetchPostPreviewEdm',
  fetchRequest: fetchPostPreviewEdmRequest,
  handleSuccess: (response) => {
    window.open(response.data, '_blank');
    return [fetchPostPreviewEdmSuccess(fromJS(response.data))];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostPreviewEdmFailure(error)),
      of(
        setSnackbarData({
          name: SNACKBAR,
          message: '預覽失敗',
          variant: 'error',
        })
      ),
      of(openSnackbar(SNACKBAR))
    ),
});

export default combineEpics(
  fetchGetActivityEdmEpic,
  fetchGetActivityEdmUsersEpic,
  fetchPostNotificationEmailEpic,
  fetchPostTestNotificationEmailEpic,
  fetchGetOrganizationEdmTemplatesEpic,
  fetchPostOrganizationEdmTemplateEpic,
  fetchPostPreviewEdmEpic
);
