import actionCreators from 'redux/actionCreators';
import { Map } from 'immutable';
import { createSelector } from 'reselect';

/** Actions */
export const {
  fetchGetActivityApplyFieldResult,
  fetchGetActivityApplyFieldResultRequest,
  fetchGetActivityApplyFieldResultSuccess,
  fetchGetActivityApplyFieldResultFailure,

  fetchGetUserApplyDailyCounts,
  fetchGetUserApplyDailyCountsRequest,
  fetchGetUserApplyDailyCountsSuccess,
  fetchGetUserApplyDailyCountsFailure,

  fetchGetUserAppliesTotalResultCounts,
  fetchGetUserAppliesTotalResultCountsRequest,
  fetchGetUserAppliesTotalResultCountsSuccess,
  fetchGetUserAppliesTotalResultCountsFailure,

  fetchGetConditions,
  fetchGetConditionsRequest,
  fetchGetConditionsSuccess,
  fetchGetConditionsFailure,

  fetchGetActivityApplyFieldOptionResponses,
  fetchGetActivityApplyFieldOptionResponsesRequest,
  fetchGetActivityApplyFieldOptionResponsesSuccess,
  fetchGetActivityApplyFieldOptionResponsesFailure,
} = actionCreators.mainPages.activity.activityDashboard;

/** Types */
export const FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT = fetchGetActivityApplyFieldResult()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_REQUEST = fetchGetActivityApplyFieldResultRequest()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_SUCCESS = fetchGetActivityApplyFieldResultSuccess()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT_FAILURE = fetchGetActivityApplyFieldResultFailure()
  .type;

export const FETCH_GET_USER_APPLY_DAILY_COUNTS = fetchGetUserApplyDailyCounts()
  .type;
export const FETCH_GET_USER_APPLY_DAILY_COUNTS_REQUEST = fetchGetUserApplyDailyCountsRequest()
  .type;
export const FETCH_GET_USER_APPLY_DAILY_COUNTS_SUCCESS = fetchGetUserApplyDailyCountsSuccess()
  .type;
export const FETCH_GET_USER_APPLY_DAILY_COUNTS_FAILURE = fetchGetUserApplyDailyCountsFailure()
  .type;

export const FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS = fetchGetUserAppliesTotalResultCounts()
  .type;
export const FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS_REQUEST = fetchGetUserAppliesTotalResultCountsRequest()
  .type;
export const FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS_SUCCESS = fetchGetUserAppliesTotalResultCountsSuccess()
  .type;
export const FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS_FAILURE = fetchGetUserAppliesTotalResultCountsFailure()
  .type;

export const FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES = fetchGetActivityApplyFieldOptionResponses()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES_REQUEST = fetchGetActivityApplyFieldOptionResponsesRequest()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES_SUCCESS = fetchGetActivityApplyFieldOptionResponsesSuccess()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES_FAILURE = fetchGetActivityApplyFieldOptionResponsesFailure()
  .type;
/** Selectors */

/**
 * Api states
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'activity', 'activityDashboard'], Map());

const getFetchGetActivityApplyFieldResult = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetActivityApplyFieldResult', Map())
);

const getFetchGetUserApplyDailyCounts = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetUserApplyDailyCounts', Map())
);

const getFetchGetUserAppliesTotalResultCounts = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetUserAppliesTotalResultCounts', Map())
);

const getFetchGetActivityApplyFieldOptionResponses = createSelector(
  getApiStates,
  (apiStates) =>
    apiStates.get('fetchGetActivityApplyFieldOptionResponses', Map())
);

export const getUserApplyDailyCountsData = createSelector(
  getFetchGetUserApplyDailyCounts,
  (fetchResult) => {
    if (fetchResult.get('response')) {
      return fetchResult.get('response').toJS();
    }
    return undefined;
  }
);

export const getActivityApplyFieldResult = createSelector(
  getFetchGetActivityApplyFieldResult,
  (fetchResult) =>
    fetchResult.get('response') ? fetchResult.get('response').toJS() : undefined
);

export const getAppliesTotalResultCounts = createSelector(
  getFetchGetUserAppliesTotalResultCounts,
  (fetchResult) =>
    fetchResult.get('response') ? fetchResult.get('response').toJS() : undefined
);

export const getApplyFieldResponses = createSelector(
  getFetchGetActivityApplyFieldOptionResponses,
  (fetchResult) =>
    fetchResult.get('response') ? fetchResult.get('response').toJS() : undefined
);
