import { StepConnector, withStyles } from '@eGroupTeam/material';

export default withStyles((theme) => ({
  active: {
    '& $line': {
      borderColor: theme.egPalette.info[1],
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.egPalette.info[1],
    },
  },
  line: {
    borderColor: theme.palette.grey[300],
    marginLeft: 12,
    minHeight: 36,
  },
}))(StepConnector);
