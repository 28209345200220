import React from 'react';
import PropTypes from 'prop-types';

import makeChartColors from '@eGroupTeam/utils/makeChartColors';
import { makeStyles } from '@eGroupTeam/material';

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';

const COLORS = makeChartColors();
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 300,
  },
}));

const ActivityDailyApplyChart = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            name="人數"
            dataKey="count"
            stroke={COLORS[0]}
            fill={COLORS[0]}
          />
          {/* https://github.com/recharts/recharts/issues/2093 */}
          {data.length > 0 && <Brush />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

ActivityDailyApplyChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ActivityDailyApplyChart;
