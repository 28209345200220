import React, { FC } from 'react';

import Theme, { ThemeProps } from '@eGroupTeam/material/Theme';
import theme from 'App/theme';

/**
 * Customized Mui Theme Provider.
 */
const MuiThemeProvider: FC<Omit<ThemeProps, 'theme'>> = (props) => (
  <Theme theme={theme} {...props} />
);

export default MuiThemeProvider;
