import React, { useRef } from 'react';

import useLocationSearch from 'utils/useLocationSearch';

import Main from '@eGroupTeam/material-layout/Main';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import {
  Button,
  Container,
  Grid,
  Paper,
  Box,
  Typography,
  Divider,
  CircularProgress,
  makeStyles,
  Link,
  Icomoon,
} from '@eGroupTeam/material';
import Currency from 'components/Currency';
import CtbcAuthForm from 'components/CtbcAuthForm';
import OrderInfo from 'components/OrderInfo';
import useOrgOrder from 'utils/useOrgOrder';
import PaymentProductList from 'components/PaymentProductList';
import OrderTotalAmount from 'components/OrderTotalAmount';
import usePaymentCode from 'utils/usePaymentCode';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #C0C3CE",
    boxShadow: "0 3px 16px rgba(10, 75, 109, 0.08)",
    borderRadius: "8px 8px 0 0",
  },
  header: {
    padding: theme.spacing(3),
    "& h3": {
      fontSize: "1.75rem",
      lineHeight: "1.2",
    },
  },
  contents: {
    boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(3, 2, 2),
  },
  totalPrice: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0 !important",
      "& h6": {
        textAlign: "left !important",
      },
    },
  },
  divider: {
    paddingTop: "0 !important",
    "& div": {
      margin: "0",
    },
  },
  note: {
    "& p:first-child": {
      fontWeight: "500",
    },
    "& p:nth-child(2)": {
      fontSize: "14px",
    },
    "& a": {
      fontSize: "14px",
      color: "#4F4FF3",
    },
  },
  confirmBtn: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
}));

const Checkout = () => {
  const classes = useStyles();
  const formEl = useRef<HTMLFormElement>(null);
  const { organizationId, paymentOrderId } = useLocationSearch();
  const { data } = useOrgOrder({
    organizationId: organizationId as string,
    paymentOrderId: paymentOrderId as string,
  });
  const { data: paymentCode } = usePaymentCode({
    serviceName: 'micepoint',
    organizationId: organizationId as string,
    paymentOrderId: paymentOrderId as string,
  });

  const handleClick = () => {
    if (formEl && formEl.current) {
      formEl.current.submit();
    }
  };

  if (!data || !paymentCode) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <Main>
      <Container maxWidth="md">
        <CtbcAuthForm ref={formEl} paymentCode={paymentCode} />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Box p={3}>
                <OrderInfo data={data} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <Typography variant="h3" fontWeight={400}>
                  訂單內容
                </Typography>
              </Box>
              <Box>
                <PaymentProductList
                  paymentOrderType={data.paymentOrderType}
                  data={data.paymentProductList}
                />
                <OrderTotalAmount
                  paymentOrderType={data.paymentOrderType}
                  amount={data.paymentOrderTotalAmount}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Box className={classes.header}>
                <Typography variant="h3" fontWeight={400}>
                  訂單費用
                </Typography>
              </Box>
              <Box p={3}>
                <Paper className={classes.contents}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="h6" fontWeight={500}>
                            付款總金額
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.totalPrice}>
                          <Typography align="right" variant="h6" fontWeight={500}>
                            <Currency value={data.paymentOrderTotalAmount} />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.divider}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className={classes.note}>
                          <Typography variant="body1" fontWeight={500}>
                            目前僅提供信用卡方式付款。
                          </Typography>
                          <Typography variant="body1">
                            完成交易後即代表您同意我們的
                            <Link href="/terms" variant="body1">
                              服務條款
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.confirmBtn}
                        >
                          <Button
                            rounded
                            variant="contained"
                            startIcon={
                              <Icomoon type="check" />
                            }
                            onClick={handleClick}
                            disabled={!paymentCode}
                            color="info"
                            size="large"
                          >
                            前往付款
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Main>
  );
};

export default Checkout;
