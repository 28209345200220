import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { MainHeaderContext } from 'components/MainHeader';

const MainHeaderProvider = ({ routes, ...other }) => {
  const [mainHeaderTitle, setMainHeaderTitle] = useState();
  const publicSetMainHeaderTitle = useCallback((currentMainHeader) => {
    setMainHeaderTitle(currentMainHeader);
  }, []);
  const contextValue = useMemo(
    () => ({
      routes,
      mainHeaderTitle,
      setMainHeaderTitle: publicSetMainHeaderTitle,
    }),
    [mainHeaderTitle, routes, publicSetMainHeaderTitle]
  );

  return <MainHeaderContext.Provider value={contextValue} {...other} />;
};

MainHeaderProvider.propTypes = {
  /**
   * react router props
   */
  routes: PropTypes.array.isRequired,
};

export default MainHeaderProvider;
