class ApiErrorMsgMapper {
  private msgMapper = {
    'FORBIDDEN - update people count need to more than apply people.':
      '編輯人數時不可以低於目前報名的人數。',
    'FORBIDDEN - activity is end.': '活動已結束。',
    ENDED: '活動已結束',
    NOT_START: '活動尚未開始',
    NO_MODEL: '本活動無來賓註冊人臉',
    'unknown qrcode': '未知的 QRCode',
    'Model is loading': '人臉資料載入中<br />請稍後15秒',
    'unknown face': '查無您的資料<br />建議您調整角度再試一次',
  };

  public get(key: string) {
    const msg = this.msgMapper[key];
    return msg ?? key;
  }
}

const mapper = new ApiErrorMsgMapper();

export default mapper;
