import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { dialogs } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { snackbars } from '@eGroupTeam/redux-modules/immutable/snackbars';
import { entities } from '@eGroupTeam/redux-modules/immutable/entities';
import { apis } from '@eGroupTeam/redux-modules/immutable/apis';
import { Outcome } from '@eGroupTeam/utils/getDeviceInfo';
import { FETCH_POST_LOGOUT_SUCCESS } from 'redux/modules/common';
import { ErrorInfo } from 'react';
import { componentsEpics } from './components';
import { landingPages, landingPagesEpics } from './landingPages';
// import { qrpassPages, qrpassPagesEpics } from './qrpassPages';
import { mainPages, mainPagesEpics } from './mainPages';
import { profilePagesEpics } from './profilePages';
import { modules, modulesEpics } from './modules';
import history from './history';

export const rootEpic = combineEpics(
  componentsEpics,
  landingPagesEpics,
  // qrpassPagesEpics,
  mainPagesEpics,
  profilePagesEpics,
  modulesEpics
);

// root reducer
const appReducer = combineReducers({
  router: connectRouter(history),
  form: formReducer,
  dialogs,
  snackbars,
  entities,
  apis,
  landingPages,
  // qrpassPages,
  mainPages,
  modules,
});
export const rootReducer = (state, action) => {
  if (action.type === FETCH_POST_LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};
export type RootState = ReturnType<typeof rootReducer>;
export type LogPayload = {
  function: string;
  browserDescription: string;
  jsonData: {
    error?: Error;
    errorInfo?: ErrorInfo;
    action?: unknown;
    store?: RootState;
    deviceInfo?: Outcome[];
    data?: unknown;
  };
  level: 'ERROR' | 'INFO';
  message?: string;
};
