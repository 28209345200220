import React, { FC, useEffect, useState } from 'react';

import { getSelectedOrg } from 'redux/modules/common';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import Button from '@eGroupTeam/material/Button';
import Checkbox from '@eGroupTeam/material/Checkbox';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@eGroupTeam/material';
import { useSelector } from 'react-redux';
import useOrgRoles from 'utils/useOrgRoles';
import useOrgMemberRoles from 'utils/useOrgMemberRoles';

export const DIALOG = 'orgMemberEditRoleDialog';

export interface OrgMemberEditRoleDialogProps {
  onSubmit?: (values?: string[]) => void;
  loading?: boolean;
  loginId?: string;
}

const OrgMemberEditRoleDialog: FC<
  OrgMemberEditRoleDialogProps & WithReduxDialogProps
> = ({ onSubmit, isOpen, handleClose, loading, loginId }) => {
  const selectedOrg = useSelector(getSelectedOrg);
  const { data: roles } = useOrgRoles(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    {
      organizationRoleStatus: '1',
    }
  );

  const [values, setValues] = useState<string[] | undefined>();

  const {
    mutate,
    data: memberRoles,
  } = useOrgMemberRoles(
    {
      organizationId: selectedOrg.get('organizationId'),
      loginId,
    },
    undefined,
    {
      onSuccess: (response) => {
        const { source = [] } = response.data;
        setValues(source.map((el) => el.organizationRole.organizationRoleId));
      },
    }
  );

  useEffect(() => {
    if (!isOpen) {
      setValues(undefined);
    } else if (memberRoles) {
      mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleChange = (e) => {
    if (e.target.checked) {
      setValues((values) => {
        if (values) {
          return [...values, e.target.value];
        }
        return [e.target.value];
      });
    } else {
      setValues((values) => {
        if (values) {
          return values.filter((el) => el !== e.target.value);
        }
        return values;
      });
    }
  };

  const renderContent = () => {
    if (!roles || !values) {
      return (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      );
    }
    return roles.source.map((el) => (
      <Box key={el.organizationRoleId} display="block">
        <Checkbox
          label={el.organizationRoleNameZh}
          value={el.organizationRoleId}
          onChange={handleChange}
          checked={values.includes(el.organizationRoleId)}
        />
      </Box>
    ));
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={handleClose}
      transitionDuration={{
        exit: 0,
      }}
    >
      <DialogTitle>編輯成員角色</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>取消</Button>
        <Button
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => {
            if (onSubmit) {
              onSubmit(values);
            }
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<
  HTMLDivElement,
  OrgMemberEditRoleDialogProps
>(OrgMemberEditRoleDialog);
