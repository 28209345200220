import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchGetActivityFiles,
  fetchGetActivityFilesRequest,
  fetchGetActivityFilesSuccess,
  fetchGetActivityFilesFailure,

  fetchDeleteActivityFile,
  fetchDeleteActivityFileRequest,
  fetchDeleteActivityFileSuccess,
  fetchDeleteActivityFileFailure,
} = actionCreators.mainPages.activity.activityFiles;

/** Types */
export const FETCH_GET_ACTIVITY_FILES = fetchGetActivityFiles().type;
export const FETCH_GET_ACTIVITY_FILES_REQUEST = fetchGetActivityFilesRequest()
  .type;
export const FETCH_GET_ACTIVITY_FILES_SUCCESS = fetchGetActivityFilesSuccess()
  .type;
export const FETCH_GET_ACTIVITY_FILES_FAILURE = fetchGetActivityFilesFailure()
  .type;

export const FETCH_DELETE_ACTIVITY_FILE = fetchDeleteActivityFile().type;
export const FETCH_DELETE_ACTIVITY_FILE_REQUEST = fetchDeleteActivityFileRequest()
  .type;
export const FETCH_DELETE_ACTIVITY_FILE_SUCCESS = fetchDeleteActivityFileSuccess()
  .type;
export const FETCH_DELETE_ACTIVITY_FILE_FAILURE = fetchDeleteActivityFileFailure()
  .type;

/** Selectors */
/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'activity', 'activityFiles'], Map());

const getFetchGetActivityFiles = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetActivityFiles', Map())
);

export const getActivityFiles = createSelector(
  getFetchGetActivityFiles,
  (fetchResult) => fetchResult.get('response')
);
