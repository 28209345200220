import axios from 'axios';
import config from './apiConfig';

export const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  ...config,
});

export default {
  // Service Products
  fetchGetServiceProducts: ({ serviceName }) => fetcher.get(`/service-products/${serviceName}`),
  // Post feedback
  fetchPostFeedback: (payload) => fetcher.post(`/feedbacks`, payload),
};
