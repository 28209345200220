import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchGetMemberInvitationByEmail,
  fetchGetMemberInvitationByEmailRequest,
  fetchGetMemberInvitationByEmailSuccess,
  fetchGetMemberInvitationByEmailFailure,

  fetchPostMemberIntoOrganization,
  fetchPostMemberIntoOrganizationRequest,
  fetchPostMemberIntoOrganizationSuccess,
  fetchPostMemberIntoOrganizationFailure,
} = actionCreators.landingPages.organizationInvitation;

/** Types */

export const FETCH_GET_MEMBER_INVITATION_BY_EMAIL = fetchGetMemberInvitationByEmail()
  .type;
export const FETCH_GET_MEMBER_INVITATION_BY_EMAIL_REQUEST = fetchGetMemberInvitationByEmailRequest()
  .type;
export const FETCH_GET_MEMBER_INVITATION_BY_EMAIL_SUCCESS = fetchGetMemberInvitationByEmailSuccess()
  .type;
export const FETCH_GET_MEMBER_INVITATION_BY_EMAIL_FAILURE = fetchGetMemberInvitationByEmailFailure()
  .type;

export const FETCH_POST_MEMBER_INTO_ORGANIZATION = fetchPostMemberIntoOrganization()
  .type;
export const FETCH_POST_MEMBER_INTO_ORGANIZATION_REQUEST = fetchPostMemberIntoOrganizationRequest()
  .type;
export const FETCH_POST_MEMBER_INTO_ORGANIZATION_SUCCESS = fetchPostMemberIntoOrganizationSuccess()
  .type;
export const FETCH_POST_MEMBER_INTO_ORGANIZATION_FAILURE = fetchPostMemberIntoOrganizationFailure()
  .type;

/** Selectors */
/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'landingPages', 'organizationInvitation'], Map());

const getFetchGetMemberInvitationByEmail = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetMemberInvitationByEmail', Map())
);

const getFetchPostMemberIntoOrganization = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostMemberIntoOrganization', Map())
);

export const getMemberInvitationByEmail = createSelector(
  getFetchGetMemberInvitationByEmail,
  (fetchResult) => fetchResult.get('response')
);

export const getMemberIntoOrganizationIsPosting = createSelector(
  getFetchPostMemberIntoOrganization,
  (fetchPost) => fetchPost.get('isLoading')
);
