import React, { FC } from 'react';
import Currency from 'components/Currency';
import { Box, makeStyles, Typography } from '@eGroupTeam/material';
import { PaymentOrderType } from 'enums';

export interface OrderTotalAmountProps {
  paymentOrderType: PaymentOrderType;
  amount: number;
}

const useStyles = makeStyles(() => ({
  root: {
    background: "#F1FFFA",
    borderRadius: "8px",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  },
}));

const OrderTotalAmount: FC<OrderTotalAmountProps> = ({
  paymentOrderType,
  amount,
}) => {
  const classes = useStyles();
  
  return (
    <Box
      className={classes.root}
      m={3}
      p={3}
      display="flex"
      justifyContent="space-between"
    >
      <Typography variant="body1">總計</Typography>
      <Typography variant="body1">
        <Currency value={amount} isMicepoint={paymentOrderType === 3} />
      </Typography>
    </Box>
  )
};

export default OrderTotalAmount;
