import React, { FC } from 'react';

import { Activity } from 'types';
import { CardActionAreaProps, Grid } from '@eGroupTeam/material';
import Skeleton from '@material-ui/lab/Skeleton';
import ActivityCard from 'components/ActivityCard';

export interface ActivityCardsProps {
  activities?: Activity[];
  MuiCardActionAreaProps?: CardActionAreaProps;
}

const ActivityCards: FC<ActivityCardsProps> = ({
  activities,
  MuiCardActionAreaProps,
}) => {
  if (!activities) {
    return (
      <Grid container spacing={2}>
        {Array.from(Array(10).keys()).map((val) => (
          <Grid item xs={12} sm={6} lg={4} key={val}>
            <Skeleton variant="rect" width="100%" height={140} />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton width="60%" />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {activities.map((activity) => (
        <Grid key={activity.activityId} item xs={12} sm={6} lg={4} xl={3}>
          <ActivityCard
            MuiCardActionAreaProps={MuiCardActionAreaProps}
            activityId={activity.activityId}
            activityCoverPath={activity.activityCoverPath}
            activityName={activity.activityName}
            activityStartDateString={activity.activityStartDateString}
            activityEndDateString={activity.activityEndDateString}
            activityDescription={activity.activityDescription}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ActivityCards;
