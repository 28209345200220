import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  fetchPatchUserApplyActivityClass,
  fetchPatchUserApplyActivityClassRequest,
  fetchPatchUserApplyActivityClassSuccess,
  fetchPatchUserApplyActivityClassFailure,
  fetchPostUserApplyActivityClass,
  fetchPostUserApplyActivityClassFailure,
  fetchPostUserApplyActivityClassRequest,
  fetchPostUserApplyActivityClassSuccess,
} from './actions';

export const fetchPatchUserApplyActivityClassEpic = makeBasicFetchEpic({
  actionType: fetchPatchUserApplyActivityClass.type,
  apiName: 'member.fetchPatchUserApplyActivityClass',
  fetchRequest: fetchPatchUserApplyActivityClassRequest,
  handleSuccess: (response, { action }) => {
    action.payload.callback();
    return [fetchPatchUserApplyActivityClassSuccess(fromJS(response.data))];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) => {
    if (error.response && error.response.status === 409) {
      return concat(
        of(
          setSnackbarData({
            name: SNACKBAR,
            message: '已額滿',
            variant: 'error',
          })
        ),
        of(openSnackbar(SNACKBAR)),
        of(fetchPatchUserApplyActivityClassFailure(error))
      );
    }
    return concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchUserApplyActivityClassFailure(error))
    );
  },
});

export const fetchPostUserApplyActivityClassEpic = makeBasicFetchEpic({
  actionType: fetchPostUserApplyActivityClass.type,
  apiName: 'fetchPostUserApplyActivityClass',
  fetchRequest: fetchPostUserApplyActivityClassRequest,
  handleSuccess: (response, { action }) => {
    action.payload.callback();
    return [
      fetchPostUserApplyActivityClassSuccess(fromJS(response.data)),
      setSnackbarData({
        name: SNACKBAR,
        message: '新增成功',
        variant: 'success',
      }),
      openSnackbar(SNACKBAR),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostUserApplyActivityClassFailure(error))
    ),
});

export default combineEpics(
  fetchPatchUserApplyActivityClassEpic,
  fetchPostUserApplyActivityClassEpic
);
