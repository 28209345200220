import React, { FC } from 'react';
import Radio from '@eGroupTeam/material/Radio';
import {
  Button,
  Hidden,
  MobileStepper,
  RadioGroupProps,
  Segment,
  SegmentContent,
  SegmentHeader,
  Typography,
} from '@eGroupTeam/material';
import { RadioGroup } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ActivityType } from 'enums';
import Toolbar from './Toolbar';
import { handleNextStep, handlePrevStep } from './actions';

export interface StepSelectActivityTypeProps {
  activeStep: number;
  value: ActivityType;
  onChange: RadioGroupProps['onChange'];
}

const StepSelectActivityType: FC<StepSelectActivityTypeProps> = ({
  activeStep,
  value,
  onChange,
}) => {
  const dispatch = useDispatch();
  const handleNext = () => {
    dispatch(handleNextStep());
  };
  const handlePrev = () => {
    dispatch(handlePrevStep());
  };

  return (
    <>
      <Segment variant="outlined">
        <SegmentHeader>
          <Typography variant="h5">選擇活動類型</Typography>
          <Typography variant="body2" gutterBottom>
            MICEPass 可以舉辦各種形式活動，請選擇本次活動類型！
          </Typography>
        </SegmentHeader>
        <SegmentContent>
          <Typography variant="body1">
            <strong>請依照您的會議性質選擇活動類型</strong>
          </Typography>
          <RadioGroup value={value} onChange={onChange}>
            <Radio label="單場活動" value={ActivityType.event} />
            <Radio
              label="多場次活動，例如 : 週期課程、帶狀課程"
              value={ActivityType.course}
            />
          </RadioGroup>
          <Hidden smDown>
            <Toolbar
              activeStep={activeStep}
              onNextClick={handleNext}
              onPrevClick={handlePrev}
            />
          </Hidden>
        </SegmentContent>
      </Segment>
      <Hidden mdUp>
        <MobileStepper
          variant="progress"
          steps={6}
          position="bottom"
          activeStep={activeStep}
          nextButton={
            <Button size="small" color="primary" onClick={handleNext}>
              繼續
            </Button>
          }
          backButton={
            <Button size="small" onClick={handlePrev}>
              上一步
            </Button>
          }
        />
      </Hidden>
    </>
  );
};

export default StepSelectActivityType;
