import { format } from '@eGroupTeam/utils/dateUtils';

const parseApplyFieldResponseList = (
  applyFieldResponseList,
  activityApplyFields
) =>
  applyFieldResponseList
    .map((applyFieldResponse, key) => {
      const applyFieldType = activityApplyFields
        .find((field) => field.get('applyFieldId') === key)
        .get('applyFieldType');
      switch (applyFieldType) {
        case 'choicemulti': {
          const applyFieldOptionList = [];
          applyFieldResponse.forEach((el, key) => {
            if (el.get('checked')) {
              applyFieldOptionList.push({
                applyFieldOptionId: key,
                applyFieldResponseContent: el.get('text'),
              });
            }
          });
          return {
            applyFieldId: key,
            applyFieldOptionList,
          };
        }
        case 'choiceone':
          return {
            applyFieldId: key,
            applyFieldOptionList: [
              {
                applyFieldOptionId: applyFieldResponse.get('value'),
                applyFieldResponseContent: applyFieldResponse.get('text'),
              },
            ],
          };
        case 'select':
          return {
            applyFieldId: key,
            applyFieldOptionList: [
              {
                applyFieldOptionId: applyFieldResponse,
              },
            ],
          };
        case 'date':
          return {
            applyFieldId: key,
            applyFieldResponseContent: format(applyFieldResponse, 'yyyy-MM-dd'),
          };
        default:
          return {
            applyFieldId: key,
            applyFieldResponseContent: applyFieldResponse,
          };
      }
    })
    .toList();

export default parseApplyFieldResponseList;
