import { combineEpics } from 'redux-observable';
import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  fetchPostActivityUserTempRequest,
  fetchPostActivityUserTempSuccess,
  fetchPostActivityUserTempFailure,
  FETCH_POST_ACTIVITY_USER_TEMP,
} from './redux';
import { DIALOG } from './UsersActivityImportResultDialog';

export const fetchPostActivityUserTempEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_USER_TEMP,
  apiName: 'fetchPostActivityUserTemp',
  fetchRequest: fetchPostActivityUserTempRequest,
  handleSuccess: (response) => [
    fetchPostActivityUserTempSuccess(
      fromJS({
        response: response.data,
        activityId: response.data[0].activity.activityId,
      })
    ),
    setSnackbarData({
      name: SNACKBAR,
      message: '活動匯入成功',
      variant: 'success',
    }),
    openSnackbar(SNACKBAR),
    openDialog(DIALOG),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityUserTempFailure(error)),
      of(
        setSnackbarData({
          name: SNACKBAR,
          message: error.response.data.clientMessage,
          variant: 'error',
        })
      ),
      of(openSnackbar(SNACKBAR))
    ),
});

export default combineEpics(fetchPostActivityUserTempEpic);
