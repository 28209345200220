import { useCallback } from 'react';
import apis from 'redux/apis';
import getDeviceInfo from '@eGroupTeam/utils/getDeviceInfo';
import { LogPayload } from 'redux/root';
import { useSelector } from 'react-redux';
import useAxiosApi, { AxiosApi } from '@eGroupTeam/hooks/useAxiosApi';
import { AxiosError } from 'axios';

export default function useAxiosApiWrapper<
  Data = unknown,
  P = unknown,
  ErrorData = unknown
>(api: AxiosApi<Data, P>) {
  const store = useSelector<unknown, unknown>((state) => state);
  const onrejected = useCallback(
    (error: AxiosError<ErrorData>) => {
      // send error log
      let logPayload: LogPayload = {
        function: 'useAxiosApi',
        browserDescription: window.navigator.userAgent,
        jsonData: {
          action: api.name,
          store: (store as any).toJS(),
          deviceInfo: getDeviceInfo(),
        },
        level: 'ERROR',
      };
      if (!error.response) {
        logPayload = {
          ...logPayload,
          message: error.message,
        };
      }
      apis.tools.createLog(logPayload);
    },
    [api.name, store]
  );
  const parameters = useAxiosApi<Data, P, ErrorData>(api, onrejected);
  return parameters;
}
