import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { normalize } from 'normalizr';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';

import { setEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import { push } from 'connected-react-router/immutable';
import { closeDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  fetchGetSurveysRequest,
  fetchGetSurveysSuccess,
  fetchGetSurveysFailure,
  fetchPostSurveyRequest,
  fetchPostSurveySuccess,
  fetchPostSurveyFailure,
  FETCH_GET_SURVEYS,
  FETCH_POST_SURVEY,
} from './redux';
import { DIALOG } from './SurveyAddDialog';

export const fetchGetSurveysEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SURVEYS,
  apiName: 'fetchGetSurveys',
  observableMap: mergeMap,
  fetchRequest: fetchGetSurveysRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.surveys
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetSurveysSuccess(
        fromJS({
          total: response.data.total || 0,
          result,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetSurveysFailure(error))
    ),
});

export const fetchPostSurveyEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_SURVEY,
  apiName: 'fetchPostSurvey',
  fetchRequest: fetchPostSurveyRequest,
  handleSuccess: (response) => [
    fetchPostSurveySuccess(fromJS(response.data)),
    push(`/me/surveys/${response.data.surveyId}/editor`),
    closeDialog(DIALOG),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostSurveyFailure(error))
    ),
});

export default combineEpics(fetchGetSurveysEpic, fetchPostSurveyEpic);
