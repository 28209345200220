import React, { FC, HTMLAttributes } from 'react';

import clsx from 'clsx';
import {
  withStyles,
  Theme,
  WithStyles,
  createStyles,
  Button,
} from '@eGroupTeam/material';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: theme.spacing(2),

      '& button': {
        marginLeft: theme.spacing(),
      },
    },
  });

export interface ToolbarProps
  extends HTMLAttributes<HTMLDivElement>,
    WithStyles<typeof styles> {
  /**
   * Current step index
   */
  activeStep: number;
  /**
   * on next button click
   */
  onPrevClick?: () => void;
  /**
   * on next button click
   */
  onNextClick?: () => void;
  /**
   * Set `true` to disable next button
   */
  nextDisabled?: boolean;
}

const Toolbar: FC<ToolbarProps> = ({
  classes,
  className,
  activeStep,
  onPrevClick,
  onNextClick,
  nextDisabled,
  ...other
}) => (
  <div className={clsx(classes.root, className)} {...other}>
    {activeStep !== 0 && (
      <Button
        onClick={onPrevClick}
        color="warning"
        rounded
        variant="contained"
        startIcon={<ChevronLeftIcon />}
      >
        上一步
      </Button>
    )}
    <Button
      variant="contained"
      color="success"
      rounded
      onClick={onNextClick}
      disabled={nextDisabled}
      endIcon={<ChevronRightIcon />}
    >
      繼續
    </Button>
  </div>
);

export default withStyles(styles)(Toolbar);
