import React, { FC } from 'react';

import { Activity } from 'types';

import Container from '@eGroupTeam/material/Container';
import { Typography, makeStyles } from '@eGroupTeam/material';
import EditIcon from '@material-ui/icons/Edit';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EmailIcon from '@material-ui/icons/Email';
import ShareIcon from '@material-ui/icons/Share';
import { Link } from 'react-router-dom';
import useHandleActivityShare from 'utils/useHandleActivityShare';
import Feature from './Feature';

export interface ActivityQuickGuideProps {
  activity: Activity;
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
  },
}));

const ActivityQuickGuide: FC<ActivityQuickGuideProps> = ({ activity }) => {
  const classes = useStyles();
  const [handleShareClick] = useHandleActivityShare(activity);

  return (
    <Container>
      <div className={classes.section}>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          基礎設定
        </Typography>
        <Link
          to={`/me/activities/${activity.activityId}/edit`}
          className={classes.link}
        >
          <Feature
            primary="活動編輯"
            secondary="編輯您的活動內容，使活動呈現更豐富。"
            icon={<EditIcon />}
          />
        </Link>
        <Link
          to={`/me/activities/${activity.activityId}/fields`}
          className={classes.link}
        >
          <Feature
            primary="自訂報名欄位"
            secondary="依照活動需求自訂您的活動報名欄位，輕鬆掌握來賓資訊。"
            icon={<TextFieldsIcon />}
          />
        </Link>
        <Link
          to={`/me/activities/${activity.activityId}/files`}
          className={classes.link}
        >
          <Feature
            primary="上傳活動檔案"
            secondary="上傳附加檔案供來賓下載。"
            icon={<CloudUploadIcon />}
          />
        </Link>
      </div>
      <div className={classes.section}>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          活動推廣
        </Typography>
        <Feature
          primary="分享活動"
          secondary="透過社群媒體分享活動資訊。"
          icon={<ShareIcon />}
          onClick={handleShareClick}
        />
        <Link
          to={`/me/activities/${activity.activityId}/notification`}
          className={classes.link}
        >
          <Feature
            primary="活動通知"
            secondary="通過 Email 將最新消息寄給您的會員。"
            icon={<EmailIcon />}
          />
        </Link>
      </div>
    </Container>
  );
};

export default ActivityQuickGuide;
