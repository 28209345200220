import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  fetchPostUserApplyRetrainEmail,
  fetchPostUserApplyRetrainEmailRequest,
  fetchPostUserApplyRetrainEmailSuccess,
  fetchPostUserApplyRetrainEmailFailure,
  fetchPostUserApplyActivityClass,
  fetchPostUserApplyActivityClassFailure,
  fetchPostUserApplyActivityClassRequest,
  fetchPostUserApplyActivityClassSuccess,
} from './actions';

export const fetchPostUserApplyRetrainEmailEpic = makeBasicFetchEpic({
  actionType: fetchPostUserApplyRetrainEmail.type,
  apiName: 'fetchPostUserApplyRetrainEmail',
  fetchRequest: fetchPostUserApplyRetrainEmailRequest,
  handleSuccess: (response) => [
    fetchPostUserApplyRetrainEmailSuccess(fromJS(response.data)),
    setSnackbarData({
      name: SNACKBAR,
      message: '發送成功',
      variant: 'success',
    }),
    openSnackbar(SNACKBAR),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostUserApplyRetrainEmailFailure(error))
    ),
});

export const fetchPostUserApplyActivityClassEpic = makeBasicFetchEpic({
  actionType: fetchPostUserApplyActivityClass.type,
  apiName: 'fetchPostUserApplyActivityClass',
  fetchRequest: fetchPostUserApplyActivityClassRequest,
  handleSuccess: (response, { action }) => {
    action.payload.callback();
    return [
      fetchPostUserApplyActivityClassSuccess(fromJS(response.data)),
      setSnackbarData({
        name: SNACKBAR,
        message: '新增成功',
        variant: 'success',
      }),
      openSnackbar(SNACKBAR),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostUserApplyActivityClassFailure(error))
    ),
});

export default combineEpics(
  fetchPostUserApplyRetrainEmailEpic,
  fetchPostUserApplyActivityClassEpic
);
