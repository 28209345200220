import React from 'react';

import { Redirect } from 'react-router-dom';

import MainRoot from 'mainPages/Root';
import Home from 'mainPages/Home';
import ActivityRoot from 'mainPages/Activity/ActivityRoot';
import ActivityQuickGuide from 'mainPages/Activity/ActivityQuickGuide';
import ActivityDashboard from 'mainPages/Activity/ActivityDashboard';
import ActivityPeople from 'mainPages/Activity/ActivityPeople';
import ActivityCheckIn from 'mainPages/Activity/ActivityCheckIn';
import ActivityEdit from 'mainPages/Activity/ActivityEdit';
import ActivityFields from 'mainPages/Activity/ActivityFields';
import ActivityFiles from 'mainPages/Activity/ActivityFiles';
import ActivityNotification from 'mainPages/Activity/ActivityNotification';
import ActivityCertificates from 'mainPages/Activity/ActivityCertificates';
import ActivitySurveys from 'mainPages/Activity/ActivitySurveys';
import ActivityPermissions from 'mainPages/Activity/ActivityPermissions';
import ActivityTransactions from 'mainPages/Activity/ActivityTransactions';
import ActivityTransaction from 'mainPages/Activity/ActivityTransaction';
import ActivitySettings from 'mainPages/Activity/ActivitySettings';
import Activities from 'mainPages/Activities';
import Users from 'mainPages/Users';
import UsersImport from 'mainPages/Users/UsersImport';
import UsersActivityImport from 'mainPages/Users/UsersActivityImport';
import SurveyRoot from 'mainPages/Survey/SurveyRoot';
import SurveyEditor from 'mainPages/Survey/SurveyEditor';
import SurveyAnalysis from 'mainPages/Survey/SurveyAnalysis';
import Surveys from 'mainPages/Surveys';
import NotificationTemplates from 'mainPages/Notification/NotificationTemplates';
import OrgInfo from 'mainPages/Organization/OrgInfo';
import OrgMembers from 'mainPages/Organization/OrgMembers';
import OrgRole from 'mainPages/Organization/OrgRole';
import Checkout from 'mainPages/Checkout';
import Shop from 'mainPages/Shop';
import Orders from 'mainPages/Orders';
import Order from 'mainPages/Order';
import Transactions from 'mainPages/Transactions';
import Transaction from 'mainPages/Transaction';

import HomeIcon from '@material-ui/icons/Home';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EditIcon from '@material-ui/icons/Edit';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import BusinessIcon from '@material-ui/icons/Business';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SecurityIcon from '@material-ui/icons/Security';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import MicepointIcon from 'components/MicepointIcon';
import RenderRoutes from 'components/RenderRoutes';

export default [
  {
    component: ActivityRoot,
    path: '/me/activities/:activityId',
    routes: [
      {
        path: '/me/activities/:activityId/quick-guide',
        breadcrumbName: '快速開始',
        icon: <BeenhereIcon />,
        component: ActivityQuickGuide,
      },
      {
        path: '/me/activities/:activityId/dashboard',
        breadcrumbName: '活動戰情',
        icon: <DashboardIcon />,
        component: ActivityDashboard,
      },
      {
        path: '/me/activities/:activityId/check-in',
        breadcrumbName: '現場報到',
        icon: <AssignmentIndIcon />,
        component: ActivityCheckIn,
      },
      {
        subheader: '活動管理',
      },
      {
        path: '/me/activities/:activityId/people',
        breadcrumbName: '報名會員',
        icon: <HowToRegIcon />,
        component: ActivityPeople,
      },
      {
        path: '/me/activities/:activityId/edit',
        breadcrumbName: '活動編輯',
        icon: <EditIcon />,
        component: ActivityEdit,
      },
      {
        path: '/me/activities/:activityId/notification',
        breadcrumbName: '活動通知',
        icon: <EmailIcon />,
        component: ActivityNotification,
      },
      {
        path: '/me/activities/:activityId/fields',
        breadcrumbName: '自訂報名欄位',
        icon: <TextFieldsIcon />,
        component: ActivityFields,
      },
      {
        path: '/me/activities/:activityId/files',
        breadcrumbName: '上傳活動檔案',
        icon: <CloudUploadIcon />,
        component: ActivityFiles,
      },
      {
        path: '/me/activities/:activityId/permissions',
        breadcrumbName: '活動權限管理',
        icon: <SecurityIcon />,
        component: ActivityPermissions,
      },
      {
        subheader: '進階功能',
      },
      {
        path: '/me/activities/:activityId/surveys',
        breadcrumbName: '活動問卷',
        icon: <EqualizerIcon />,
        exact: true,
        component: ActivitySurveys,
      },
      {
        path: '/me/activities/:activityId/surveys/:surveyId',
        component: SurveyRoot,
        routes: [
          {
            path: '/me/activities/:activityId/surveys/:surveyId/editor',
            render: (props) => (
              <SurveyEditor
                backPath={`/me/activities/${props.activity.activityId}/surveys`}
                {...props}
              />
            ),
          },
          {
            path: '/me/activities/:activityId/surveys/:surveyId/analysis',
            render: (props) => (
              <SurveyAnalysis
                backPath={`/me/activities/${props.activity.activityId}/surveys`}
                {...props}
              />
            ),
          },
        ],
      },
      // {
      //   path: '/me/activities/:activityId/certificates',
      //   breadcrumbName: '參加證明',
      //   icon: <DescriptionIcon />,
      //   component: ActivityCertificates,
      // },
      {
        subheader: '其他',
      },
      {
        path: '/me/activities/:activityId/transactions',
        breadcrumbName: '點數交易紀錄',
        icon: <ListAltIcon />,
        exact: true,
        component: ActivityTransactions,
      },
      {
        path: '/me/activities/:activityId/transactions/:paymentOrderId',
        component: ActivityTransaction,
      },
      {
        path: '/me/activities/:activityId/settings',
        breadcrumbName: '活動設定',
        icon: <SettingsIcon />,
        component: ActivitySettings,
      },
      {
        path: '/me/activities/:activityId/*',
        render: () => <Redirect to="/me/activities" />,
      },
    ],
  },
  {
    component: MainRoot,
    path: '/me',
    routes: [
      {
        path: '/me',
        breadcrumbName: '首頁',
        icon: <HomeIcon />,
        exact: true,
        component: Home,
      },
      {
        path: '/me/activities',
        component: Activities,
        breadcrumbName: '活動管理',
        icon: <EventIcon />,
      },
      {
        path: '/me/hrm',
        breadcrumbName: '會員管理',
        icon: <GroupIcon />,
        component: RenderRoutes,
        routes: [
          {
            path: '/me/hrm/users',
            component: Users,
            breadcrumbName: '會員列表',
          },
          {
            path: '/me/hrm/import-users',
            component: UsersImport,
            breadcrumbName: '匯入會員',
          },
          {
            path: '/me/hrm/import-activities',
            breadcrumbName: '匯入活動參加名單',
            component: UsersActivityImport,
          },
        ],
      },
      {
        path: '/me/surveys',
        breadcrumbName: '問卷管理',
        icon: <EqualizerIcon />,
        component: RenderRoutes,
        routes: [
          {
            path: '/me/surveys',
            exact: true,
            component: Surveys,
          },
          {
            path: '/me/surveys/:surveyId',
            component: SurveyRoot,
            routes: [
              {
                path: '/me/surveys/:surveyId/editor',
                component: SurveyEditor,
              },
              {
                path: '/me/surveys/:surveyId/analysis',
                component: SurveyAnalysis,
              },
            ],
          },
        ],
      },
      {
        path: '/me/notification/templates',
        breadcrumbName: 'Email 範本管理',
        icon: <EmailIcon />,
        component: NotificationTemplates,
      },
      {
        path: '/me/org',
        breadcrumbName: '主辦單位',
        icon: <BusinessIcon />,
        component: RenderRoutes,
        routes: [
          {
            path: '/me/org/info',
            component: OrgInfo,
            breadcrumbName: '基本資料',
          },
          {
            path: '/me/org/members',
            component: OrgMembers,
            breadcrumbName: '單位成員',
          },
          {
            path: '/me/org/roles',
            component: OrgRole,
            breadcrumbName: '角色管理',
          },
        ],
      },
      {
        path: '/me/micepoint',
        breadcrumbName: 'MICEPoint',
        icon: <MicepointIcon />,
        component: RenderRoutes,
        routes: [
          {
            path: '/me/micepoint/shop',
            component: Shop,
            breadcrumbName: '購買 MICEPoint',
          },
          {
            path: '/me/micepoint/orders',
            component: Orders,
            breadcrumbName: '訂單紀錄',
            exact: true,
          },
          {
            path: '/me/micepoint/orders/:paymentOrderId',
            component: Order,
          },
          {
            path: '/me/micepoint/transactions',
            component: Transactions,
            breadcrumbName: '交易紀錄',
            exact: true,
          },
          {
            path: '/me/micepoint/transactions/:paymentOrderId',
            component: Transaction,
          },
        ],
      },
      {
        path: '/me/checkout',
        component: Checkout,
      },
      {
        path: '/me/*',
        render: () => <Redirect to="/me" />,
      },
    ],
  },
];
