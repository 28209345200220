import React, { FC } from 'react';
import apis from 'redux/apisOrg';
import { getSelectedOrg } from 'redux/modules/common';
import useOrgMembers from 'utils/useOrgMembers';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  Button,
  Container,
  IconButton,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
} from '@eGroupTeam/material';
import DeleteIcon from '@material-ui/icons/Delete';
import PageHeader from 'components/PageHeader';
import PageSection from 'components/PageSection';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import { Activity, OrganizationMember } from 'types';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import ActivityPermissionsAddDialog, {
  DIALOG,
} from './ActivityPermissionsAddDialog';

export interface ActivityPermissionsProps {
  activity: Activity;
}

const ActivityPermissions: FC<ActivityPermissionsProps> = ({ activity }) => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const {
    handleChangePage,
    handleRowsPerPageChange,
    handleSearchChange,
    payload,
    page,
    rowsPerPage,
  } = useDataTable('orgMembers', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });
  const { data, isValidating, response, mutate } = useOrgMembers(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    {
      ...payload,
      activityId: activity.activityId,
    }
  );
  const {
    excute: createMemberActivityPermission,
    isLoading: isCreating,
  } = useAxiosApiWrapper(apis.fetchPostMemberActivityPermission);
  const { excute: deleteMemberActivityPermission } = useAxiosApiWrapper(
    apis.fetchDeleteMemberActivityPermission
  );

  const handleAddSubmit = (values) => {
    createMemberActivityPermission({
      organizationId: selectedOrg.get('organizationId'),
      loginId: values.getIn(['loginId', 'value']),
      memberActivitySet: [activity.activityId],
    }).then(() => {
      mutate();
      dispatch(
        setSnackbarData({
          name: SNACKBAR,
          message: '邀請成功',
          variant: 'success',
        })
      );
      dispatch(openSnackbar(SNACKBAR));
    });
  };

  const handleDelete = (orgMember: OrganizationMember) => () => {
    deleteMemberActivityPermission({
      organizationId: selectedOrg.get('organizationId'),
      loginId: orgMember.member.loginId,
      activityId: activity.activityId,
    }).then(() => {
      mutate();
    });
  };

  const renderColumns = (rowData) => (
    <TableRow>
      {rowData.map((el) => (
        <TableCell key={el}>{el}</TableCell>
      ))}
    </TableRow>
  );

  const renderDataRow = (rowData: unknown) => {
    const orgMember = rowData as OrganizationMember;
    return (
      <TableRow key={orgMember.member.memberAccount}>
        <TableCell>{orgMember.member.memberName}</TableCell>
        <TableCell>{orgMember.member.memberAccount}</TableCell>
        <TableCell align="right">
          {activity.loginId !== orgMember.member.loginId &&
            !orgMember?.organizationRoleList?.find(
              (el) =>
                el.organizationRoleAdmin === 1 ||
                el.organizationRoleActiviyAuth === 1
            ) && (
              <Tooltip title="刪除活動行政人員">
                <span>
                  <IconButton onClick={handleDelete(orgMember)}>
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <ActivityPermissionsAddDialog
        onSubmit={handleAddSubmit}
        loading={isCreating}
      />
      <Container>
        <PageHeader
          title="活動行政人員"
          subtitle="您可以管理您的活動行政人員，只要邀請他們便可以協同管理活動。"
          actions={
            <Button
              onClick={() => dispatch(openDialog(DIALOG))}
              variant="contained"
              color="primary"
            >
              邀請行政人員
            </Button>
          }
        />
        <PageSection>
          <Paper square>
            <DataTable
              data={data ? data.source : []}
              columns={['名稱', '帳號', '']}
              serverSide
              SearchBarProps={{
                placeholder: '搜尋管理員',
                onChange: handleSearchChange,
              }}
              loading={isValidating}
              isEmpty={response?.status === 204}
              renderColumns={renderColumns}
              renderDataRow={renderDataRow}
              MuiTablePaginationProps={{
                count: data ? data.total : 0,
                labelRowsPerPage: '每頁幾筆',
                page,
                rowsPerPage,
                onPageChange: handleChangePage,
                onRowsPerPageChange: handleRowsPerPageChange,
              }}
            />
          </Paper>
        </PageSection>
      </Container>
    </>
  );
};

export default ActivityPermissions;
