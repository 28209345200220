import React, { useEffect, useState, FC } from 'react';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedOrg } from 'redux/modules/common';
import { Activity, Question as TQuestion, UrlParams } from 'types';

import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles, Container, CircularProgress } from '@eGroupTeam/material';
import Survey from 'components/Survey';
import SurveyToolbar from 'components/SurveyToolbar';
import Question from 'components/Question';
import QuestionEditable from 'components/QuestionEditable';
import questionToApplyField from './questionToApplyField';
import fieldToQuestion from './fieldToQuestion';
import {
  fetchGetActivityApplyFields,
  fetchPostActivityApplyField,
  fetchPatchActivityApplyFieldsOrder,
  fetchPatchActivityApplyField,
  fetchDeleteActivityApplyField,
  fetchPostActivityApplyFieldOption,
  fetchPatchActivityApplyFieldOptionsOrder,
  fetchPatchActivityApplyFieldOption,
  fetchDeleteActivityApplyFieldOption,
  getActivityApplyFields,
} from './redux';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingRight: theme.spacing(8),
  },
  toolbar: {
    position: 'absolute',
    right: 0,
    transition: 'all 0.4s',
  },
}));
export interface ActivityFieldsProps {
  activity: Activity;
}

const ActivityFields: FC<ActivityFieldsProps> = ({ activity }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activityApplyFields = useSelector(getActivityApplyFields);
  const selectedOrg = useSelector(getSelectedOrg);
  const { activityId } = useParams<UrlParams>();
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>();
  const [controledQuestions, setControledQuestions] = useState<TQuestion[]>([]);

  useEffect(() => {
    dispatch(
      fetchGetActivityApplyFields({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
      })
    );
  }, [activityId, dispatch, selectedOrg]);

  useEffect(() => {
    if (activityApplyFields) {
      setControledQuestions(activityApplyFields.toJS().map(fieldToQuestion));
    }
  }, [activityApplyFields]);

  const handleDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const sourceQuestion = controledQuestions[source.index];

    if (!sourceQuestion) return
    
    const payload = [...controledQuestions];
    payload.splice(source.index, 1);
    payload.splice(destination.index, 0, sourceQuestion);
    setControledQuestions(payload);
    dispatch(
      fetchPatchActivityApplyFieldsOrder({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        payload: payload.map((question) => ({
          applyFieldId: question.questionId,
        })),
      })
    );
  };

  const handleNameChange = (event, question) => {
    const applyField = questionToApplyField(question);
    dispatch(
      fetchPatchActivityApplyField({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
        applyFieldType: applyField.applyFieldType,
        applyFieldName: event.target.value,
      })
    );
  };

  const handleTypeChange = (event, question) => {
    const applyField = questionToApplyField(question);
    const nextApplyFieldType = event.target.value;
    let nextApplyFieldOptionList;
    if (
      (nextApplyFieldType === 'choicemulti' ||
        nextApplyFieldType === 'choiceone' ||
        nextApplyFieldType === 'select') &&
      (!applyField.applyFieldOptionList ||
        applyField.applyFieldOptionList.length === 0)
    ) {
      nextApplyFieldOptionList = [
        {
          applyFieldOptionName: '選項 1',
          applyFieldOptionNo: 1,
        },
      ];
    }
    dispatch(
      fetchPatchActivityApplyField({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
        applyFieldType: nextApplyFieldType,
        applyFieldOptionList: nextApplyFieldOptionList,
      })
    );
  };

  const handleIsRequiredChange = (event, question) => {
    const applyField = questionToApplyField(question);
    dispatch(
      fetchPatchActivityApplyField({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
        applyFieldType: applyField.applyFieldType,
        isRequired: Number(event.target.checked),
      })
    );
  };

  const handleDelete = (event, question) => {
    const applyField = questionToApplyField(question);
    dispatch(
      fetchDeleteActivityApplyField({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
      })
    );
  };

  const handleCreateQuestion = () => {
    dispatch(
      fetchPostActivityApplyField({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldName: '問題',
        applyFieldType: 'text',
        applyFieldNo: controledQuestions.length + 1,
      })
    );
  };

  const handleOptionChange = (event, question, option) => {
    const applyField = questionToApplyField(question);
    dispatch(
      fetchPatchActivityApplyFieldOption({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
        applyFieldOptionId: option.optionId,
        applyFieldOptionName: event.target.value,
      })
    );
  };

  const handleOptionDelete = (e, question, deletedOption) => {
    const applyField = questionToApplyField(question);
    dispatch(
      fetchDeleteActivityApplyFieldOption({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
        applyFieldOptionId: deletedOption.optionId,
      })
    );
  };

  const handleOptionDragEnd = (result, question, options) => {
    const applyField = questionToApplyField(question);
    dispatch(
      fetchPatchActivityApplyFieldOptionsOrder({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
        payload: options.map((option) => ({
          applyFieldOptionId: option.optionId,
        })),
      })
    );
  };

  const handleOptionCreate = (e, question, index) => {
    const applyField = questionToApplyField(question);
    dispatch(
      fetchPostActivityApplyFieldOption({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
        applyFieldOptionName: `選項 ${index}`,
        applyFieldOptionNo: index,
      })
    );
  };

  const handleOptionCreateOther = (e, question) => {
    const applyField = questionToApplyField(question);
    dispatch(
      fetchPostActivityApplyFieldOption({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        applyFieldId: applyField.applyFieldId,
        applyFieldOptionName: '其他...',
        isOther: Number(true),
      })
    );
  };

  if (!activityApplyFields) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <Container>
      <Survey>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={activity.activityId}>
            {(dropProvided) => (
              <div {...dropProvided.droppableProps}>
                <div ref={dropProvided.innerRef}>
                  <Question
                    question={{
                      questionId: '0',
                      questionType: 'text',
                      questionName: '姓名',
                      isRequired: 1,
                    }}
                    disabled
                  />
                  <Question
                    question={{
                      questionId: '1',
                      questionType: 'text',
                      questionName: '手機',
                      isRequired: 1,
                    }}
                    disabled
                  />
                  <Question
                    question={{
                      questionId: '2',
                      questionType: 'email',
                      questionName: 'Email',
                    }}
                    disabled
                  />
                  {controledQuestions.map((question, index) => (
                    <Draggable
                      draggableId={question.questionId}
                      key={question.questionId}
                      index={index}
                    >
                      {(dragProvided) => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                        >
                          {question.questionId === selectedQuestionId ? (
                            <QuestionEditable
                              DragHandleProps={dragProvided.dragHandleProps}
                              question={question}
                              onNameChange={handleNameChange}
                              onTypeChange={handleTypeChange}
                              onRequiredChange={handleIsRequiredChange}
                              onDelete={handleDelete}
                              onOptionChange={handleOptionChange}
                              onOptionDelete={handleOptionDelete}
                              onOptionDragEnd={handleOptionDragEnd}
                              onOptionCreate={handleOptionCreate}
                              onOptionCreateOther={handleOptionCreateOther}
                              optionTypes={[
                                'text',
                                'textarea',
                                '|',
                                'choiceone',
                                'choicemulti',
                                'select',
                                '|',
                                'date',
                                '|',
                                'email',
                              ]}
                            />
                          ) : (
                            <Question
                              DragHandleProps={dragProvided.dragHandleProps}
                              onClick={() =>
                                setSelectedQuestionId(question.questionId)
                              }
                              question={question}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <SurveyToolbar
          className={classes.toolbar}
          onCreateQuestionClick={handleCreateQuestion}
          tools={['createQuestion']}
        />
      </Survey>
    </Container>
  );
};

export default ActivityFields;
