import React, { VFC } from "react";
import { makeStyles } from "@material-ui/core";

import { Box } from "@eGroupTeam/material";
import Container from "@eGroupTeam/material/Container";
import Grid from "@eGroupTeam/material/Grid";
import Link from "@material-ui/core/Link";
import Divider from "@eGroupTeam/material-lab/Divider";
import Typography from "@eGroupTeam/material/Typography";

import Logo from 'components/Logo'
import facebook from "./images/facebook.png";
import youtube from "./images/youtube.png";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    backgroundColor: "#111111",
    color: "white",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& ul": {
      listStyle: "none",
      padding: theme.spacing(0),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      "& li": {
        display: "contents",
        "& a": {
          marginLeft: "4px",
          marginRight: "4px",
        },
      },
    },
  },
  divider: {
    borderColor: "#dee2e6",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  copyInfoText: {
    fontSize: "12px",
  },
}));

const Footer: VFC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footerSection}>
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <Box textAlign="center">
              <Logo width="70" color="white" decoding="async"/>
              <ul>
                <li>
                  <Link href="https://www.facebook.com/">
                    <img src={facebook} alt="Facebook" width="30" />
                  </Link>
                </li>
                <li>
                  <Link href="https://www.youtube.com/">
                    <img src={youtube} alt="Youtube" width="30" />
                  </Link>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item>
            <Typography
              variant="body1"
              color="inherit"
              className={classes.copyInfoText}
            >
              © 2020 - 2021 MICEPass, All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
