import { RouteConfig } from 'react-router-config';
import routesPublic from '../routes-public';
// import routesQrpass from './routes-qrpass';
import routesProfile from './routes-profile';
import routesMain from './routes-main';
import routesNoRoot from './routes-noroot';

const routesPrivate: RouteConfig[] = [
  // TODO: Need fixed as any
  ...routesNoRoot,
  // ...routesQrpass,
  ...routesProfile,
  ...routesMain,
  ...routesPublic,
] as any;

export default routesPrivate;
