import React, { FC } from 'react';
import { Divider, makeStyles, Typography } from '@eGroupTeam/material';
import MicepointIcon from 'components/MicepointIcon';
import { MicepointTrialFee } from 'types';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(),
  },
  icon: {
    marginRight: theme.spacing(),
    color: theme.palette.primary.main,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
}));

export interface MicepointTrialFeesDisplayProps {
  data: MicepointTrialFee;
}

const MicepointTrialFeesDisplay: FC<MicepointTrialFeesDisplayProps> = ({
  data,
}) => {
  const classes = useStyles();

  if (data.isFree) {
    return (
      <Typography variant="h6" align="center">
        使用免費額度進行操作
      </Typography>
    );
  }

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6">目前點數</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MicepointIcon className={classes.icon} />
          <Typography variant="h5">
            <strong>{data.micepointAmount}</strong>
          </Typography>
        </div>
      </div>
      {Object.keys(data.productMICEPointMap).map((el) => {
        const point = data.productMICEPointMap[el];
        return (
          <div className={classes.item} key={el}>
            <Typography variant="body1" color="textSecondary">
              {el}
            </Typography>
            <Typography variant="body1">
              <strong>{point}</strong>
            </Typography>
          </div>
        );
      })}
      <Divider />
      <div className={classes.item}>
        <Typography variant="body1" color="textSecondary">
          支付點數
        </Typography>
        <Typography variant="h6" color="primary">
          <strong>{data.micepointPay}</strong>
        </Typography>
      </div>
      <div className={classes.item}>
        <Typography
          variant="body1"
          color={data.isEnough ? 'textSecondary' : 'secondary'}
        >
          {data.isEnough ? '預計剩餘點數' : <strong>您的點數不足！</strong>}
        </Typography>
        <Typography
          variant="body1"
          color={data.isEnough ? 'textPrimary' : 'secondary'}
        >
          <strong>{data.micepointAmount - data.micepointPay}</strong>
        </Typography>
      </div>
    </>
  );
};

export default MicepointTrialFeesDisplay;
