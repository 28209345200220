import React, { FC } from "react";

import Typography from "@eGroupTeam/material/Typography";

import CheckImg from "./images/check.png";

interface BasicServiceItemProps {
  text: string;
}

const BasicServiceItem: FC<BasicServiceItemProps> = (props) => {
  const { text } = props;

  return (
    <Typography variant="body1">
      <img src={CheckImg} alt="Check" decoding="async" />
      {text}
    </Typography>
  );
};

export default BasicServiceItem;
