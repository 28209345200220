import React, { forwardRef } from 'react';

import { SvgIcon, SvgIconProps } from '@eGroupTeam/material';

const MicepointIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
  <SvgIcon viewBox="0 0 174.69 174.69" ref={ref} {...props}>
    <g>
      <path d="M87.35,0a87.35,87.35,0,1,0,87.34,87.35A87.35,87.35,0,0,0,87.35,0ZM129,125.8H115.38V64h-.22L91.71,125.8h-9.6L59,64h-.22V125.8H45.68V48.89H67.17l20.07,54.64h.32l19.85-54.64H129Z" />
    </g>
  </SvgIcon>
));

export default MicepointIcon;
