import { fetcher } from 'redux/apisOrg';
import { Survey, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
};
const useActivitySurveys = makeGetHook<EntityList<Survey>, PathParams>(
  '/organizations/{{organizationId}}/surveys',
  fetcher
);

export default useActivitySurveys;
