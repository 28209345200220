const defaultValues = {
  paymentOrderContactName: '',
  paymentOrderContactPhone: '',
  paymentOrderContactLocalPhone: '',
  paymentOrderContactEmail: '',
  invoiceResultType: '1',
  paymentOrderInvoiceTaxIdNumber: '',
  paymentOrderInvoiceTitle: '',
};

export default defaultValues