/* eslint-disable no-useless-escape */
import { isImmutable } from 'immutable';

/**
 * To solve index path bug please read this for more detail.
 * https://stackoverflow.com/questions/6393943/convert-javascript-string-in-dot-notation-into-an-object-reference
 * @param {*} path
 * @param {*} obj
 */
const indexPath = (path, obj) => {
  if (typeof path !== 'string' || !obj) {
    return undefined;
  }
  if (isImmutable(obj)) {
    return path
      .match(/[^\]\[.]+/g)
      .reduce((el, key) => (el ? el.get(key) : undefined), obj);
  }
  return path
    .match(/[^\]\[.]+/g)
    .reduce((el, key) => (el ? el[key] : undefined), obj);
};

export default indexPath;
