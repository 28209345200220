import React, { FC } from 'react';

import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import queryString from 'query-string';
import { makeStyles } from '@eGroupTeam/material';

import Dialog from '@eGroupTeam/material/Dialog';

export const DIALOG = 'videoPlayerDialog';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    display: 'block',
    width: '100%',
    overflow: 'hidden',

    '&::before': {
      display: 'block',
      content: "''",
      zIndex: 0,
      paddingTop: '56.25%',
    },
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
}));

export interface VideoPlayerDialogProps {
  iframeSrc?: string;
  iframeTitle?: string;
}
const VideoPlayerDialog: FC<VideoPlayerDialogProps & WithReduxDialogProps> = ({
  isOpen,
  handleClose,
  iframeSrc = '',
  iframeTitle,
}) => {
  const classes = useStyles();
  const { url, query } = queryString.parseUrl(iframeSrc);

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <div className={classes.wrapper}>
        <iframe
          className={classes.iframe}
          src={`${url}?${queryString.stringify({
            ...query,
            autoplay: 1,
          })}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={iframeTitle}
        />
      </div>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<unknown, VideoPlayerDialogProps>(
  VideoPlayerDialog
);
