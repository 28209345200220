import React, { useEffect } from 'react';

import useLocationSearch from 'utils/useLocationSearch';
import { format } from '@eGroupTeam/utils/dateUtils';
import { Map, isImmutable } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import {
  // @ts-ignore
  // TODO: Need fixed ts-ignore
  submit,
  formValueSelector,
  hasSubmitFailed,
  isInvalid,
} from 'redux-form/immutable';
import getErrorFieldNames from 'utils/getErrorFieldNames';
import useMemberInfo from 'utils/useMemberInfo';

import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import Button from '@eGroupTeam/material/Button';
import {
  makeStyles,
  Container,
  CircularProgress,
  Paper,
  Typography,
  Icomoon,
  Box,
} from '@eGroupTeam/material';
import { ConfigProps } from 'redux-form';
import Main from '@eGroupTeam/material-layout/Main';
import {
  fetchPostUserApplyActivity,
  fetchGetActivitySurveys,
  fetchGetActivityClasses,
  fetchGetUserDataByEmailTokenId,
  fetchGetActivityApplyFields,
  handlePrev,
  handleNext,
  setActiveStep,
  resetSteps,
  getActivityClasses,
  getIsPosting,
  getUser,
  getActivityApplyFields,
  getSteps,
  getActiveStep,
  getSurveyQuestionSchemaProperties,
} from './redux';
import parseResponseList from './parseResponseList';
import parseApplyFieldResponseList from './parseApplyFieldResponseList';
import ActivityApplyStepsForm, { FORM } from './ActivityApplyStepsForm';
import ActivityAppBar from '../ActivityAppBar';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(4)
  },
  paper: {
    boxShadow: theme.egShadows[1],
    padding: theme.spacing(3),
    color:"#898989",
    marginBottom: theme.spacing(3),
  },
  actions: {
    paddingTop: theme.spacing(3),
    textAlign: 'right',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  iconSend: {
    marginLeft: theme.spacing(1)
  },
}));

const selector = formValueSelector(FORM);
const getHasSubmitFailed = hasSubmitFailed(FORM);
const getIsInvalid = isInvalid(FORM);

const ActivityApply = ({ activity }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    tokenId,
    activityClassIds: activityClassIdsQuery,
  } = useLocationSearch();
  const isPosting = useSelector(getIsPosting);
  const user = useSelector(getUser);
  const activityClasses = useSelector(getActivityClasses);
  const activityApplyFields = useSelector(getActivityApplyFields);
  const activeStep = useSelector(getActiveStep);
  const steps = useSelector(getSteps);
  const surveyQuestionSchemaProperties = useSelector(
    getSurveyQuestionSchemaProperties
  );
  const hasSubmitFailed = useSelector(getHasSubmitFailed);
  const isInvalid = useSelector(getIsInvalid);
  const isAgreePrivacy = useSelector((state) =>
    selector(state, 'isAgreePrivacy')
  );
  const [memberInfo] = useMemberInfo();
  const activityClassIds = Array.isArray(activityClassIdsQuery)
    ? activityClassIdsQuery
    : [activityClassIdsQuery];

  useEffect(() => {
    dispatch(setActiveStep(0));
    dispatch(resetSteps());
    dispatch(
      fetchGetActivitySurveys({
        activityId: activity.get('activityId'),
        surveyType: 'apply',
      })
    );
    dispatch(
      fetchGetActivityClasses({
        activityId: activity.get('activityId'),
      })
    );
    dispatch(
      fetchGetActivityApplyFields({
        activityId: activity.get('activityId'),
      })
    );
  }, [activity, dispatch]);

  useEffect(() => {
    if (tokenId) {
      dispatch(
        fetchGetUserDataByEmailTokenId({
          tokenId,
        })
      );
    }
  }, [dispatch, tokenId]);

  const handleFormSubmit = (values) => {
    if (activeStep !== steps.size - 1) {
      dispatch(handleNext());
      window.scroll({
        top: 0,
        left: 0,
      });
    } else {
      const payload = values
        .set('activityId', activity.get('activityId'))
        .set(
          'userApplyActivityClassList',
          activityClassIds.map((activityClassId) => ({
            activityClass: {
              activityClassId,
            },
          }))
        )
        .set('emailTokenId', tokenId)
        .update('isAgreeFaceRecognize', (e) => Number(e))
        .update('isAgreePrivacy', (e) => Number(e))
        .update('applyFieldResponseList', (applyFieldResponseList) => {
          if (isImmutable(applyFieldResponseList)) {
            return parseApplyFieldResponseList(
              applyFieldResponseList,
              activityApplyFields
            );
          }
          return applyFieldResponseList;
        })
        .update('responseList', (responseList) => {
          if (isImmutable(responseList)) {
            return parseResponseList(
              responseList,
              surveyQuestionSchemaProperties
            );
          }
          return responseList;
        });
      dispatch(fetchPostUserApplyActivity(payload.toJS()));
    }
  };

  const handleFormSubmitFail: ConfigProps['onSubmitFail'] = (errors) => {
    if (errors) {
      const errorFieldNames = getErrorFieldNames(errors);
      // Using breakable for loop
      for (let i = 0; i < errorFieldNames.length; i += 1) {
        const fieldAnchorName = `position-${errorFieldNames[i]}`;
        const elements = document.querySelectorAll(
          `[name="${fieldAnchorName}"]`
        );
        // Checking if the marker exists in DOM
        if (elements.length && elements[0]) {
          const y = elements[0].getBoundingClientRect().top + window.scrollY;
          window.scroll({
            top: y,
            behavior: 'smooth',
          });
          break;
        }
      }
    }
  };

  const renderButtons = () => {
    const submitBtn = (
      <Button
        className={classes.button}
        color="primary"
        loading={isPosting}
        variant="contained"
        rounded type="submit"
        disableElevation
        disabled={
          !isAgreePrivacy || (hasSubmitFailed && isInvalid) || isPosting
        }
        onClick={() => dispatch(submit(FORM))}
      >
        送出
        <Icomoon type="send" color="white" className={classes.iconSend} />
      </Button>
    );
    const prevBtn = (
      <Button
        disabled={isPosting}
        variant="outlined"
        className={classes.button}
        onClick={() => {
          dispatch(handlePrev());
        }}
      >
        上一步
      </Button>
    );
    const nextBtn = (
      <Button
        disabled={!isAgreePrivacy || hasSubmitFailed || isInvalid}
        variant="contained"
        color="primary"
        className={classes.button}
        disableElevation
        onClick={() => dispatch(submit(FORM))}
      >
        下一步
      </Button>
    );
    if (steps.size > 1) {
      return (
        <>
          {activeStep !== 0 && prevBtn}
          {activeStep === steps.size - 1 ? submitBtn : nextBtn}
        </>
      );
    }
    return submitBtn;
  };

  if (!activityClasses || !activityApplyFields) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  const selectedActivityClasses = activityClasses.filter((el) =>
    activityClassIds?.includes(el.get('activityClassId'))
  );

  if (!selectedActivityClasses || selectedActivityClasses.size === 0) {
    return <Redirect to="/not-found" />;
  }

  const hasActivityClass = activity.get('activityType') === 2;

  return (
    <>
      <Helmet>
        <title>[報名] {activity.get('activityName')}</title>
      </Helmet>
      <ActivityAppBar position="static" />
      <Container>
        <Main>
          <Paper className={classes.main}>
            <Paper className={classes.paper}>
              <Typography variant="body2">
                活動名稱：{activity.get('activityName')}
                {hasActivityClass && (
                  <>
                    場次名稱：
                    {selectedActivityClasses.map((el) => (
                      <React.Fragment key={el.get('activityClassId')}>
                        <br />
                        {el.get('activityClassName')}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Typography>
              <Typography variant="body2">
                {hasActivityClass ? (
                  <>
                    活動時間：
                    {selectedActivityClasses.map((el) => (
                      <React.Fragment key={el.get('activityClassId')}>
                        <br />
                        {format(el.get('activityClassStartDateString'), 'PPPP p ~ ')}
                        {format(el.get('activityClassEndDateString'), 'PPPP p')}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    活動時間：
                    {format(activity.get('activityStartDateString'), 'PPPP p ~ ')}
                    {format(activity.get('activityEndDateString'), 'PPPP p')}
                  </>
                )}
              </Typography>
              <Typography variant="body2">
                {`主辦單位：${activity.getIn([
                  'organization',
                  'organizationName',
                ])}`}
              </Typography>
            </Paper>
            <ActivityApplyStepsForm
              initialValues={
                user ||
                Map({
                  userName: memberInfo?.memberName || '',
                  userPhone: memberInfo?.memberPhone || '',
                  userEmail: memberInfo?.memberAccount || '',
                  isAgreeFaceRecognize: !!activity.get(
                    'activityHasFaceRecognize'
                  ),
                })
              }
              activityId={activity.get('activityId')}
              activityHasFaceRecognize={activity.get('activityHasFaceRecognize')}
              activityClassIds={activityClassIds}
              onSubmit={handleFormSubmit}
              onSubmitFail={handleFormSubmitFail}
            />
            <div className={classes.actions}>{renderButtons()}</div>
          </Paper>
        </Main>
      </Container>
    </>
  );
};

export default ActivityApply;
