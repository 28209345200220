import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';

import {
  fetchGetOrganizations,
  fetchGetOrganizationsRequest,
  fetchGetOrganizationsSuccess,
  fetchGetOrganizationsFailure,
  fetchGetQrpassQualify,
  fetchGetQrpassQualifyRequest,
  fetchGetQrpassQualifySuccess,
  fetchGetQrpassQualifyFailure,
  fetchGetMemberInfo,
  fetchGetMemberInfoRequest,
  fetchGetMemberInfoSuccess,
  fetchGetMemberInfoFailure,
  fetchGetMemberModules,
  fetchGetMemberModulesRequest,
  fetchGetMemberModulesSuccess,
  fetchGetMemberModulesFailure,
} from './actions';

export const fetchGetOrganizationsEpic = makeBasicFetchEpic({
  actionType: fetchGetOrganizations.type,
  apiName: 'member.fetchGetOrganizations',
  fetchRequest: fetchGetOrganizationsRequest,
  handleSuccess: (response) => [
    fetchGetOrganizationsSuccess(fromJS(response.data.source || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetOrganizationsFailure(error))
    ),
});

export const fetchGetQrpassQualifyEpic = makeBasicFetchEpic({
  actionType: fetchGetQrpassQualify.type,
  apiName: 'qrpass.fetchGetQrpassQualify',
  fetchRequest: fetchGetQrpassQualifyRequest,
  handleSuccess: (response) => [
    fetchGetQrpassQualifySuccess(fromJS(response.data || {})),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetQrpassQualifyFailure(error))
    ),
});

export const fetchGetMemberInfoEpic = makeBasicFetchEpic({
  actionType: fetchGetMemberInfo.type,
  apiName: 'member.fetchGetMemberInfo',
  fetchRequest: fetchGetMemberInfoRequest,
  handleSuccess: (response) => [fetchGetMemberInfoSuccess(response.data)],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetMemberInfoFailure(error))
    ),
});

export const fetchGetMemberModulesEpic = makeBasicFetchEpic({
  actionType: fetchGetMemberModules.type,
  apiName: 'member.fetchGetMemberModules',
  fetchRequest: fetchGetMemberModulesRequest,
  handleSuccess: (response) => [
    fetchGetMemberModulesSuccess(fromJS(response.data || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetMemberModulesFailure(error))
    ),
});

export default combineEpics(
  fetchGetOrganizationsEpic,
  fetchGetQrpassQualifyEpic,
  fetchGetMemberInfoEpic,
  fetchGetMemberModulesEpic
);
