import React, { FC } from 'react';

import clsx from 'clsx';
import {
  makeStyles,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Grid,
} from '@eGroupTeam/material';
import { Question } from 'types';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import QuestionOptions from '../QuestionOptions';
import QuestionRating from '../QuestionRating';
import QuestionDragHandle from '../QuestionDragHandle';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1.5, 0),

    '& $dragHandle': {
      visibility: 'hidden',
    },

    '&:hover': {
      '& $dragHandle': {
        visibility: 'visible',
      },
    },
  },
  main: {
    padding: theme.spacing(0, 3),
    paddingBottom: theme.spacing(3),
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  text: {
    width: '50%',
  },
  textarea: {
    width: '85%',
  },
  dragHandle: {},
}));

export interface QuestionProps {
  className?: string;
  question: Question;
  disabled?: boolean;
  DragHandleProps?: DraggableProvidedDragHandleProps;
  onClick?: () => void;
}

const QuestionComponent: FC<QuestionProps> = ({
  className,
  question,
  disabled,
  DragHandleProps,
  ...other
}) => {
  const classes = useStyles();
  const renderContent = () => {
    switch (question.questionType) {
      case 'titleBlock':
        return (
          <TextField
            className={classes.textarea}
            label="區塊描述"
            disabled
            value={question.questionDescription}
            multiline
          />
        );
      case 'text':
        return <TextField className={classes.text} label="簡答文字" disabled />;
      case 'textarea':
        return (
          <TextField className={classes.textarea} label="詳答文字" disabled />
        );
      case 'choiceone':
        return <QuestionOptions type="radio" question={question} />;
      case 'choicemulti':
        return <QuestionOptions type="checkbox" question={question} />;
      case 'select':
        return <QuestionOptions type="select" question={question} />;
      case 'email':
        return <TextField className={classes.text} label="電子郵件" disabled />;
      case 'rating':
        return <QuestionRating question={question} />;
      case 'date':
        return (
          <TextField
            className={classes.text}
            label="年/月/日"
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InsertInvitationIcon />
                </InputAdornment>
              ),
            }}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <Paper
      className={clsx(className, classes.root, disabled && classes.disabled)}
      {...other}
    >
      <QuestionDragHandle
        className={classes.dragHandle}
        disabled={disabled}
        DragHandleProps={DragHandleProps}
      />
      <div className={classes.main}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="body1">
              {question.questionName || '問題'}{' '}
              {Boolean(question.isRequired) && (
                <span style={{ color: 'red' }}>*</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default QuestionComponent;
