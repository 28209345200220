import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { fetchPostCheckUserSurvey } from './redux';

/**
 * Send email to user to fill survey.
 */
const CheckUserSurvey = ({ location }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const { activityId, emailTokenId } = queryString.parse(location.search);
    dispatch(
      fetchPostCheckUserSurvey({
        activityId,
        emailTokenId,
      })
    );
  }, [dispatch, location.search]);

  return <div>loading...</div>;
};

CheckUserSurvey.propTypes = {
  // router props
  location: PropTypes.object.isRequired,
};

export default CheckUserSurvey;
