import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchPostCarrierMemberVan,
  fetchPostCarrierMemberVanRequest,
  fetchPostCarrierMemberVanSuccess,
  fetchPostCarrierMemberVanFailure
} = actionCreators.profilePages.account;

/** Types */
export const FETCH_POST_CARRIER_MEMBER_VAN = fetchPostCarrierMemberVan().type;
export const FETCH_POST_CARRIER_MEMBER_VAN_REQUEST = fetchPostCarrierMemberVanRequest()
  .type;
export const FETCH_POST_CARRIER_MEMBER_VAN_SUCCESS = fetchPostCarrierMemberVanSuccess()
  .type;
export const FETCH_POST_CARRIER_MEMBER_VAN_FAILURE = fetchPostCarrierMemberVanFailure()
  .type;

/** Selectors */

/**
 * API States
 */
const getApiStates = state =>
  state.getIn(['apis', 'profilePages', 'account'], Map());

const getFetchPostCarrierMemberVan = createSelector(getApiStates, apiStates =>
  apiStates.get('fetchPostCarrierMemberVan', Map())
);

export const getCarrierMemberVan = createSelector(
  getFetchPostCarrierMemberVan,
  fetchGet => fetchGet.get('response')
);

export const getIsLoading = createSelector(
  getFetchPostCarrierMemberVan,
  fetchGet => fetchGet.get('isLoading')
);
