import axios from 'axios';
import queryString from 'query-string';
import config from './apiConfig';

const qrFetcher = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/qrpass`,
  ...config,
});

export default {
  /**
   * Create record.
   */
  fetchPostQrpassRecord: ({ organizationId, ...payload }) =>
    qrFetcher.post(`/organizations/${organizationId}/qrpass-records`, payload),
  /**
   * Get records.
   */
  fetchGetQrpassRecords: ({ organizationId, ...payload }) =>
    qrFetcher.get(
      `/organizations/${organizationId}/qrpass-records?${queryString.stringify(
        payload
      )}`
    ),
  /**
   * Get record by it's id.
   */
  fetchGetQrpassRecord: ({ organizationId, qrpassRecordId, ...payload }) =>
    qrFetcher.get(
      `/organizations/${organizationId}/qrpass-records/${qrpassRecordId}`
    ),
  /**
   * Get endpoint settings.
   */
  fetchGetQrpassSettings: ({ organizationId }) =>
    qrFetcher.get(`/organizations/${organizationId}/qrpass-setting`),
  /**
   * Create endpoint setting.
   */
  fetchPostQrpassSetting: ({ organizationId, ...payload }) =>
    qrFetcher.post(`/organizations/${organizationId}/qrpass-setting`, payload),
  /**
   * Update endpoint setting.
   */
  fetchPatchQrpassSetting: ({ organizationId, qrpassSettingId, ...payload }) =>
    qrFetcher.patch(
      `/organizations/${organizationId}/qrpass-setting/${qrpassSettingId}`,
      payload
    ),

  fetchGetTags: ({ organizationId, ...payload }) =>
    qrFetcher.get(
      `/organizations/${organizationId}/qrpass-tags?${queryString.stringify(
        payload
      )}`
    ),

  fetchGetTag: ({ organizationId, qrpassTagId }) =>
    qrFetcher.get(
      `/organizations/${organizationId}/qrpass-tags/qrpass-tags/${qrpassTagId}`
    ),

  fetchPostTag: ({ organizationId, ...payload }) =>
    qrFetcher.post(`/organizations/${organizationId}/qrpass-tags`, payload),

  fetchPatchTag: ({ organizationId, qrpassTagId, ...payload }) =>
    qrFetcher.patch(
      `/organizations/${organizationId}/qrpass-tags/${qrpassTagId}`,
      payload
    ),

  fetchDeleteTag: ({ organizationId, qrpassTagId }) =>
    qrFetcher.delete(
      `/organizations/${organizationId}/qrpass-tags/${qrpassTagId}`
    ),

  /**
   * TODO: Temporary workaround.
   * Get qualify and quota.
   */
  fetchGetQrpassQualify: ({ organizationId }) =>
    qrFetcher.get(`/organizations/${organizationId}`),

  /**
   * TODO: Temporary workaround.
   * Send Records to customer endpoint.
   */
  fetchPostQrpassRecordsToEndPoint: ({ organizationId }) =>
    qrFetcher.post(
      `/organizations/${organizationId}/qrpass-records/send-return`
    ),
};
