import { combineEpics } from 'redux-observable';
import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import { push } from 'connected-react-router/immutable';
import {
  openDialog,
  setDialogData,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { DIALOG } from 'App';
import {
  fetchGetSurveyRequest,
  fetchGetSurveySuccess,
  fetchGetSurveyFailure,
  fetchGetSurveyQuestionsRequest,
  fetchGetSurveyQuestionsSuccess,
  fetchGetSurveyQuestionsFailure,
  fetchPostSurveyResponsesRequest,
  fetchPostSurveyResponsesSuccess,
  fetchPostSurveyResponsesFailure,
  fetchPostCheckUserSurveyRequest,
  fetchPostCheckUserSurveySuccess,
  fetchPostCheckUserSurveyFailure,
  FETCH_GET_SURVEY,
  FETCH_GET_SURVEY_QUESTIONS,
  FETCH_POST_SURVEY_RESPONSES,
  FETCH_POST_CHECK_USER_SURVEY,
} from './redux';

export const fetchGetSurveyEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SURVEY,
  apiName: 'landingPage.fetchGetSurvey',
  fetchRequest: fetchGetSurveyRequest,
  handleSuccess: (response) => [
    fetchGetSurveySuccess(fromJS(response.data || {})),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetSurveyFailure(error))
    ),
});

export const fetchGetSurveyQuestionsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_SURVEY_QUESTIONS,
  apiName: 'landingPage.fetchGetSurveyQuestions',
  fetchRequest: fetchGetSurveyQuestionsRequest,
  handleSuccess: (response) => [
    fetchGetSurveyQuestionsSuccess(fromJS(response.data.source || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetSurveyQuestionsFailure(error))
    ),
});

export const fetchPostSurveyResponsesEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_SURVEY_RESPONSES,
  apiName: 'landingPage.fetchPostSurveyResponses',
  fetchRequest: fetchPostSurveyResponsesRequest,
  handleSuccess: (response) => {
    if (response.data.clientMessage) {
      return [
        setDialogData({
          name: DIALOG,
          primary: '錯誤',
          message: response.data.clientMessage,
        }),
        openDialog(DIALOG),
        fetchPostSurveyResponsesSuccess(fromJS(response.data)),
      ];
    }
    return [
      fetchPostSurveyResponsesSuccess(fromJS(response.data)),
      push(`/surveys/${response.data.surveyId}/done`),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostSurveyResponsesFailure(error))
    ),
});

export const fetchPostCheckUserSurveyEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_CHECK_USER_SURVEY,
  apiName: 'landingPage.fetchPostCheckUserSurvey',
  fetchRequest: fetchPostCheckUserSurveyRequest,
  handleSuccess: (response) => {
    const survey = response.data ? response.data.nextSurvey : undefined;
    if (survey) {
      return [
        fetchPostCheckUserSurveySuccess(),
        push(
          `/survey?activityId=${survey.activity.activityId}&surveyId=${survey.surveyId}`
        ),
      ];
    }
    return [fetchPostCheckUserSurveySuccess(), push('/survey-done')];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostCheckUserSurveyFailure(error))
    ),
});

export default combineEpics(
  fetchGetSurveyEpic,
  fetchGetSurveyQuestionsEpic,
  fetchPostSurveyResponsesEpic,
  fetchPostCheckUserSurveyEpic
);
