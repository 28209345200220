import React, { useState } from 'react';

import { getSelectedOrg } from 'redux/modules/common';
import { reduxForm, Field, Form } from 'redux-form/immutable';
import ReactSelectField from 'components/ReactSelectField';
import useOrgMembers from 'utils/useOrgMembers';
import { useSelector } from 'react-redux';

export const FORM = 'activityPermissionsForm';

const ActivityPermissionsForm = ({ handleSubmit }) => {
  const selectedOrg = useSelector(getSelectedOrg);
  const [query, setQuery] = useState('');
  const { data } = useOrgMembers(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    {
      query,
    }
  );

  const handleChange = (value) => {
    if (value === '') return;
    setQuery(value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="loginId"
        label="選擇會員"
        onInputChange={handleChange}
        options={data ? data.source.map((el) => ({
          value: el.member.loginId,
          label: el.member.memberName,
        })) : []}
        isClearable
        component={ReactSelectField}
        MuiTextFieldProps={{
          fullWidth: true,
          variant: 'outlined',
          required: true,
          margin: 'normal',
        }}
      />
    </Form>
  );
};

export default reduxForm({
  form: FORM,
})(ActivityPermissionsForm);
