import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { denormalize } from 'normalizr';
import * as schema from 'redux/schema';
import { getEntities } from '@eGroupTeam/redux-modules/immutable/entities';

/** Actions */
export const {
  fetchPatchSurvey,
  fetchPatchSurveyRequest,
  fetchPatchSurveySuccess,
  fetchPatchSurveyFailure,

  fetchGetSurveyQuestions,
  fetchGetSurveyQuestionsRequest,
  fetchGetSurveyQuestionsSuccess,
  fetchGetSurveyQuestionsFailure,

  fetchPostSurveyQuestion,
  fetchPostSurveyQuestionRequest,
  fetchPostSurveyQuestionSuccess,
  fetchPostSurveyQuestionFailure,

  fetchPatchSurveyQuestion,
  fetchPatchSurveyQuestionRequest,
  fetchPatchSurveyQuestionSuccess,
  fetchPatchSurveyQuestionFailure,

  fetchDeleteSurveyQuestion,
  fetchDeleteSurveyQuestionRequest,
  fetchDeleteSurveyQuestionSuccess,
  fetchDeleteSurveyQuestionFailure,

  fetchPatchSurveyQuestionsOrder,
  fetchPatchSurveyQuestionsOrderRequest,
  fetchPatchSurveyQuestionsOrderSuccess,
  fetchPatchSurveyQuestionsOrderFailure,

  fetchPostSurveyQuestionOption,
  fetchPostSurveyQuestionOptionRequest,
  fetchPostSurveyQuestionOptionSuccess,
  fetchPostSurveyQuestionOptionFailure,

  fetchPatchSurveyQuestionOption,
  fetchPatchSurveyQuestionOptionRequest,
  fetchPatchSurveyQuestionOptionSuccess,
  fetchPatchSurveyQuestionOptionFailure,

  fetchDeleteSurveyQuestionOption,
  fetchDeleteSurveyQuestionOptionRequest,
  fetchDeleteSurveyQuestionOptionSuccess,
  fetchDeleteSurveyQuestionOptionFailure,

  fetchPatchSurveyQuestionOptionsOrder,
  fetchPatchSurveyQuestionOptionsOrderRequest,
  fetchPatchSurveyQuestionOptionsOrderSuccess,
  fetchPatchSurveyQuestionOptionsOrderFailure,

  fetchGetSurveyQuestion,
  fetchGetSurveyQuestionRequest,
  fetchGetSurveyQuestionSuccess,
  fetchGetSurveyQuestionFailure,
} = actionCreators.mainPages.survey.surveyEditor;

/** Types */
export const FETCH_PATCH_SURVEY = fetchPatchSurvey().type;
export const FETCH_PATCH_SURVEY_REQUEST = fetchPatchSurveyRequest().type;
export const FETCH_PATCH_SURVEY_SUCCESS = fetchPatchSurveySuccess().type;
export const FETCH_PATCH_SURVEY_FAILURE = fetchPatchSurveyFailure().type;

export const FETCH_GET_SURVEY_QUESTIONS = fetchGetSurveyQuestions().type;
export const FETCH_GET_SURVEY_QUESTIONS_REQUEST = fetchGetSurveyQuestionsRequest()
  .type;
export const FETCH_GET_SURVEY_QUESTIONS_SUCCESS = fetchGetSurveyQuestionsSuccess()
  .type;
export const FETCH_GET_SURVEY_QUESTIONS_FAILURE = fetchGetSurveyQuestionsFailure()
  .type;

export const FETCH_POST_SURVEY_QUESTION = fetchPostSurveyQuestion().type;
export const FETCH_POST_SURVEY_QUESTION_REQUEST = fetchPostSurveyQuestionRequest()
  .type;
export const FETCH_POST_SURVEY_QUESTION_SUCCESS = fetchPostSurveyQuestionSuccess()
  .type;
export const FETCH_POST_SURVEY_QUESTION_FAILURE = fetchPostSurveyQuestionFailure()
  .type;

export const FETCH_PATCH_SURVEY_QUESTION = fetchPatchSurveyQuestion().type;
export const FETCH_PATCH_SURVEY_QUESTION_REQUEST = fetchPatchSurveyQuestionRequest()
  .type;
export const FETCH_PATCH_SURVEY_QUESTION_SUCCESS = fetchPatchSurveyQuestionSuccess()
  .type;
export const FETCH_PATCH_SURVEY_QUESTION_FAILURE = fetchPatchSurveyQuestionFailure()
  .type;

export const FETCH_DELETE_SURVEY_QUESTION = fetchDeleteSurveyQuestion().type;
export const FETCH_DELETE_SURVEY_QUESTION_REQUEST = fetchDeleteSurveyQuestionRequest()
  .type;
export const FETCH_DELETE_SURVEY_QUESTION_SUCCESS = fetchDeleteSurveyQuestionSuccess()
  .type;
export const FETCH_DELETE_SURVEY_QUESTION_FAILURE = fetchDeleteSurveyQuestionFailure()
  .type;

export const FETCH_PATCH_SURVEY_QUESTIONS_ORDER = fetchPatchSurveyQuestionsOrder()
  .type;
export const FETCH_PATCH_SURVEY_QUESTIONS_ORDER_REQUEST = fetchPatchSurveyQuestionsOrderRequest()
  .type;
export const FETCH_PATCH_SURVEY_QUESTIONS_ORDER_SUCCESS = fetchPatchSurveyQuestionsOrderSuccess()
  .type;
export const FETCH_PATCH_SURVEY_QUESTIONS_ORDER_FAILURE = fetchPatchSurveyQuestionsOrderFailure()
  .type;

export const FETCH_POST_SURVEY_QUESTION_OPTION = fetchPostSurveyQuestionOption()
  .type;
export const FETCH_POST_SURVEY_QUESTION_OPTION_REQUEST = fetchPostSurveyQuestionOptionRequest()
  .type;
export const FETCH_POST_SURVEY_QUESTION_OPTION_SUCCESS = fetchPostSurveyQuestionOptionSuccess()
  .type;
export const FETCH_POST_SURVEY_QUESTION_OPTION_FAILURE = fetchPostSurveyQuestionOptionFailure()
  .type;

export const FETCH_PATCH_SURVEY_QUESTION_OPTION = fetchPatchSurveyQuestionOption()
  .type;
export const FETCH_PATCH_SURVEY_QUESTION_OPTION_REQUEST = fetchPatchSurveyQuestionOptionRequest()
  .type;
export const FETCH_PATCH_SURVEY_QUESTION_OPTION_SUCCESS = fetchPatchSurveyQuestionOptionSuccess()
  .type;
export const FETCH_PATCH_SURVEY_QUESTION_OPTION_FAILURE = fetchPatchSurveyQuestionOptionFailure()
  .type;

export const FETCH_DELETE_SURVEY_QUESTION_OPTION = fetchDeleteSurveyQuestionOption()
  .type;
export const FETCH_DELETE_SURVEY_QUESTION_OPTION_REQUEST = fetchDeleteSurveyQuestionOptionRequest()
  .type;
export const FETCH_DELETE_SURVEY_QUESTION_OPTION_SUCCESS = fetchDeleteSurveyQuestionOptionSuccess()
  .type;
export const FETCH_DELETE_SURVEY_QUESTION_OPTION_FAILURE = fetchDeleteSurveyQuestionOptionFailure()
  .type;

export const FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER = fetchPatchSurveyQuestionOptionsOrder()
  .type;
export const FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER_REQUEST = fetchPatchSurveyQuestionOptionsOrderRequest()
  .type;
export const FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER_SUCCESS = fetchPatchSurveyQuestionOptionsOrderSuccess()
  .type;
export const FETCH_PATCH_SURVEY_QUESTION_OPTIONS_ORDER_FAILURE = fetchPatchSurveyQuestionOptionsOrderFailure()
  .type;

export const FETCH_GET_SURVEY_QUESTION = fetchGetSurveyQuestion().type;
export const FETCH_GET_SURVEY_QUESTION_REQUEST = fetchGetSurveyQuestionRequest()
  .type;
export const FETCH_GET_SURVEY_QUESTION_SUCCESS = fetchGetSurveyQuestionSuccess()
  .type;
export const FETCH_GET_SURVEY_QUESTION_FAILURE = fetchGetSurveyQuestionFailure()
  .type;

/** Selectors */
/**
 * Ui States
 */
const getUiStates = (state) => state.getIn(['mainPages', 'SurveyEditor']);

export const getSurveyQuestions = createSelector(
  getEntities,
  getUiStates,
  (entities, uiStates) =>
    denormalize(uiStates.get('questions'), schema.questions, entities)
);

/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'survey', 'surveyEditor'], Map());

const getFetchPatchSurvey = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPatchSurvey', Map())
);

export const getIsPatching = createSelector(
  getFetchPatchSurvey,
  (fetchResult) => fetchResult.get('isLoading')
);
