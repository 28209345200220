import React from 'react';
import { isImmutable } from 'immutable';
import { useSelector } from 'react-redux';
import {
  getFormInitialValues,
  getFormValues,
  hasSubmitSucceeded
} from 'redux-form/immutable';

export default function makeFormValueIsEqual(form) {
  return function useFormValueIsEqual() {
    const initialValues = useSelector(getFormInitialValues(form));
    const values = useSelector(getFormValues(form));
    const submitSucceeded = useSelector(hasSubmitSucceeded(form));
    const [isEquals, setIsEquals] = React.useState(true);

    React.useEffect(() => {
      if (!isImmutable(values)) return;
      if (!values.equals(initialValues)) {
        setIsEquals(false);
      } else {
        setIsEquals(true);
      }
    }, [initialValues, values]);

    React.useEffect(() => {
      if (submitSucceeded) {
        setIsEquals(true);
      }
    }, [submitSucceeded]);

    return isEquals;
  };
}
