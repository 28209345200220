import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { getSelectedOrg } from 'redux/modules/common';

import { User } from 'types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Avatar,
  makeStyles,
} from '@eGroupTeam/material';
import useUserFaces from 'utils/useUserFaces';
import s3Url from 'utils/s3Url';
import { Typography } from '@material-ui/core';

export const DIALOG = 'userFacesDialog';

export interface UserFacesDialogProps {
  user?: User;
}

const useStyles = makeStyles(() => ({
  avatar: {
    width: 150,
    height: 150,
  },
}));

const UserFacesDialog: FC<UserFacesDialogProps & WithReduxDialogProps> = ({
  isOpen,
  handleClose,
  user,
}) => {
  const classes = useStyles();
  const selectedOrg = useSelector(getSelectedOrg);
  const organizationId = selectedOrg.get('organizationId');
  const userId = user?.userId;
  const { data } = useUserFaces({
    organizationId,
    userId,
  });

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>報名會員人臉</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {data ? (
            data.source.map((el) => (
              <Grid item key={el.userFaceId}>
                <Avatar
                  src={s3Url(el.userFacePath)}
                  className={classes.avatar}
                />
              </Grid>
            ))
          ) : (
            <Typography align="center" variant="h6">
              無人臉
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>關閉</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<HTMLDivElement, UserFacesDialogProps>(
  UserFacesDialog
);
