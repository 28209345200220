import React, { RefObject } from 'react';

type Hook = (inputEl: RefObject<HTMLInputElement>) => () => void;

/**
 * Provide reset input[type="file"]'s value func.
 */
const useClearInputFileValue: Hook = (inputEl) => {
  const clearInputFileValue = React.useCallback(() => {
    if (inputEl && inputEl.current && inputEl.current.value) {
      // eslint-disable-next-line no-param-reassign
      inputEl.current.value = '';
    }
  }, [inputEl]);

  return clearInputFileValue;
};

export default useClearInputFileValue;
