import React, { FC, useState } from 'react';

import apis from 'redux/apisOrg';
import { format } from '@eGroupTeam/utils/dateUtils';
import {
  closeDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';

import { EachRowState, useDataTable } from '@eGroupTeam/material-module/DataTable';
import SearchBarOptionsWidget from '@eGroupTeam/material-module/SearchBarOptionsWidget';
import DataTableCollapseRow from '@eGroupTeam/material-module/DataTableCollapseRow';
import DataTableRowCheckbox from '@eGroupTeam/material-module/DataTableRowCheckbox'
import DataTableCheckedAllCheckbox from '@eGroupTeam/material-module/DataTableCheckedAllCheckbox';
import {
  Container,
  Button,
  MenuItem,
  Paper,
  Tooltip,
  TableRow,
  TextField,
  Table,
  TableHead,
  TableBody,
  Segment,
  SegmentHeader,
  Typography,
  TableCell
} from '@eGroupTeam/material';
import { IconButton } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ImageIcon from '@material-ui/icons/Image';
import {
  UserApply,
  UrlParams,
  UserApplyActivityClass,
  Activity,
  ActivityClass,
} from 'types';
import {
  IsTrainSuccess,
  UserApplyActivityClassIsCanceled,
} from 'enums'
import AddActivityClassDialog, {
  DIALOG as ADD_ACTIVITY_CLASS_DIALOG,
} from 'components/AddActivityClassDialog';
import useUserApply from 'utils/useUserApply';
import useUserApplies from 'utils/useUserApplies';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import StyledDataTable from 'components/StyledDataTable';
import StyledTableCell from 'components/StyledTableCell';
import { openSnackbar, setSnackbarData } from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  fetchPostUserApplyActivityClass,
  fetchPostUserApplyRetrainEmail,
} from './actions';
import ActivityPeopleEditDialog, { DIALOG as UPDATE_PEOPLE_DIALOG } from './ActivityPeopleEditDialog';
import UserFacesDialog, {
  DIALOG as USER_FACES_DIALOG,
} from './UserFacesDialog';
import ConfirmStatusChangeDialog, {
  DIALOG as CONFIRM_STATUS_CHANGE_DIALOG,
} from './ConfirmStatusChangeDialog';
import BatchCreateUserApplyActivityClassDialog, { DIALOG as BATCH_CREATE_USER_APPLY_ACTIVITY_CLASS_DIALOG } from './BatchCreateUserApplyActivityClassDialog';
import BatchDeleteUserApplyActivityClassDialog, { DIALOG as BATCH_DELETE_USER_APPLY_ACTIVITY_CLASS_DIALOG } from './BatchDeleteUserApplyActivityClassDialog';

export interface ActivityPeopleProps {
  activity: Activity;
  activityClasses: ActivityClass[];
}

const ActivityPeople: FC<ActivityPeopleProps> = ({
  activity,
  activityClasses,
}) => {
  const [eachRowState, setEachRowState] = useState<EachRowState>({})
  const { activityId } = useParams<UrlParams>();
  const dispatch = useDispatch();
  const [
    selectedUserApplyActivityClass,
    setSelectedUserApplyActivityClass,
  ] = useState<UserApplyActivityClass>();
  const [selectedUserApply, setSelectedUserApply] = useState<UserApply>();
  const [filterActivityClassId, setFilterActivityClassId] = useState('all');
  const [isCanceled, setIsCanceled] = useState('all');
  const selectedOrg = useSelector(getSelectedOrg);
  const { data: userApply, mutate } = useUserApply({
    organizationId: selectedOrg.get('organizationId'),
    activityId,
    userApplyId: selectedUserApply?.userApplyId,
  });
  const { excute: updateUserActivityApplyStatus } = useAxiosApiWrapper(
    apis.fetchPatchUserActivityApplyStatus
  );
  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    setPayload,
    payload,
    page,
    rowsPerPage,
  } = useDataTable('activityPeople', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });
  const {
    data,
    response,
    mutate: mutateUserApplies,
  } = useUserApplies(
    {
      organizationId: selectedOrg.get('organizationId'),
      activityId,
    },
    payload
  );
  const { excute: updateUserApply, isLoading: isUpdating } = useAxiosApiWrapper(apis.fetchPatchUserApply)
  const { excute: batchCreateUserApplyActivityClass, isLoading: batchCreating } = useAxiosApiWrapper(apis.fetchPostBatchUserApplyActivityClass)
  const { excute: batchDeleteUserApplyActivityClass, isLoading: batchDeleting } = useAxiosApiWrapper(apis.fetchDeleteBatchUserApplyActivityClass)

  const handleFilterChange = (e) => {
    setFilterActivityClassId(e.target.value);
    setPayload((value) => ({
      ...value,
      activityClassId: e.target.value !== 'all' ? e.target.value : undefined,
    }));
  };

  const handleCancelStatusChange = (e) => {
    setIsCanceled(e.target.value);
    setPayload((value) => ({
      ...value,
      isCanceled: e.target.value !== 'all' ? e.target.value : undefined,
    }));
  };

  // const handlePreviewActivityStudyProve = (rowData) => (e) => {
  //   e.stopPropagation();
  //   window.open(rowData.studyProvePath, '_blank');
  // };

  const handleEditUserApply = (userApply: UserApply) => (e) => {
    setSelectedUserApply(userApply);
    dispatch(openDialog(UPDATE_PEOPLE_DIALOG));
  };

  const handleViewUserFaces = (userApply: UserApply) => (e) => {
    e.stopPropagation();
    setSelectedUserApply(userApply);
    dispatch(openDialog(USER_FACES_DIALOG));
  };

  const handleSaveStatus = (canceledReason: string) => {
    if (!selectedUserApplyActivityClass) return;
    updateUserActivityApplyStatus({
      organizationId: selectedOrg.get('organizationId'),
      activityId,
      activityClassId:
        selectedUserApplyActivityClass.activityClass.activityClassId,
      userApplyId: selectedUserApplyActivityClass.userApply.userApplyId,
      userApplyActivityClassCanceledReason: canceledReason,
      isCanceled: selectedUserApplyActivityClass.isCanceled ? 0 : 1,
    }).then(() => {
      dispatch(closeDialog(CONFIRM_STATUS_CHANGE_DIALOG));
      mutateUserApplies();
    });
  };

  const handleAddActivityClass = (userApply: UserApply) => (e) => {
    e.stopPropagation();
    setSelectedUserApply(userApply);
    dispatch(openDialog(ADD_ACTIVITY_CLASS_DIALOG));
  };

  const renderDataRow = (rowData: unknown) => {
    const userApply = rowData as UserApply;
    return (
      <DataTableCollapseRow
        key={userApply.userApplyId}
        colSpan={10}
        startActions={
          <DataTableRowCheckbox dataId={userApply.userApplyId} size="small" />
        }
        selected={eachRowState[userApply.userApplyId]?.checked}
      >
        <>
          <StyledTableCell>{userApply.user.userName}</StyledTableCell>
          <StyledTableCell>
            {userApply.user.userOrganizationName}
            {userApply.user.userJobTitle && `/${userApply.user.userJobTitle}`}
          </StyledTableCell>
          <StyledTableCell>{userApply.user.userPhone}</StyledTableCell>
          <StyledTableCell>{userApply.user.userEmail}</StyledTableCell>
          <StyledTableCell>
            {format(userApply.userApplyCreateDateString, 'PP pp')}
          </StyledTableCell>
          {/* <StyledTableCell>
            {userApply.studyProveStatus === 2 && (
              <Tooltip title="預覽">
                <IconButton
                  size="small"
                  onClick={handlePreviewActivityStudyProve(userApply)}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            )}
            {userApply.studyProveStatus === 1 && (
              <Typography variant="body2" noWrap>
                報到紀錄異常
              </Typography>
            )}
            {userApply.studyProveStatus === 0 && (
              <Typography variant="body2" noWrap>
                處理中
              </Typography>
            )}
          </StyledTableCell> */}
          {activity.activityHasFaceRecognize === 1 && (
            <>
              <StyledTableCell>
                {IsTrainSuccess[userApply.isTrainSuccess ?? 0]}
              </StyledTableCell>
              <StyledTableCell>
                <IconButton
                  size="small"
                  onClick={handleViewUserFaces(userApply)}
                  disabled={
                    !userApply.isAgreeFaceRecognize ||
                    userApply.isTrainSuccess !== 1
                  }
                >
                  <ImageIcon />
                </IconButton>
              </StyledTableCell>
            </>
          )}
          <StyledTableCell>
            <Button onClick={handleEditUserApply(userApply)} color="info">
              編輯資訊
            </Button>
            <Button onClick={handleAddActivityClass(userApply)} color="primary">
              加報場次
            </Button>
          </StyledTableCell>
        </>
        <>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>場次名稱</StyledTableCell>
                <StyledTableCell>場次開始時間</StyledTableCell>
                <StyledTableCell>場次結束時間</StyledTableCell>
                <StyledTableCell>報名狀態</StyledTableCell>
                <StyledTableCell>變更狀態</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userApply.userApplyActivityClassList.map((el) => (
                <TableRow key={el.activityClass.activityClassId}>
                  <StyledTableCell>
                    {el.activityClass.activityClassName}
                  </StyledTableCell>
                  <StyledTableCell>
                    {format(
                      el.activityClass.activityClassStartDateString,
                      'PPPP p'
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {format(
                      el.activityClass.activityClassEndDateString,
                      'PPPP p'
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {UserApplyActivityClassIsCanceled[el.isCanceled]}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      color="primary"
                      onClick={() => {
                        setSelectedUserApply(userApply);
                        setSelectedUserApplyActivityClass(el);
                        dispatch(openDialog(CONFIRM_STATUS_CHANGE_DIALOG));
                      }}
                    >
                      {el.isCanceled === 1 ? '恢復報名' : '取消報名'}
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </DataTableCollapseRow>
    );
  };

  const checkedNum = Object.values(eachRowState).filter(el => el?.checked).length

  return (
    <>
      {selectedUserApplyActivityClass && selectedUserApply && (
        <ConfirmStatusChangeDialog
          onConfirm={handleSaveStatus}
          isCanceled={selectedUserApplyActivityClass.isCanceled}
          userName={selectedUserApply.user.userName}
          userApplyActivityClassCanceledReason={
            selectedUserApplyActivityClass.userApplyActivityClassCanceledReason
          }
        />
      )}
      <UserFacesDialog user={selectedUserApply?.user} />
      <ActivityPeopleEditDialog
        userApply={userApply}
        onSubmit={(values) => {
          if (!userApply) return;
          updateUserApply({
            organizationId: selectedOrg.get('organizationId'),
            activityId,
            userApplyId: userApply.userApplyId,
            user: {
              userName: values.get('userName'),
              userEmail: values.get('userEmail'),
              userPhone: values.get('userPhone'),
              userOrganizationName: values.get('userOrganizationName'),
              userJobTitle: values.get('userJobTitle'),
            },
            activityClassId: values.get('activityClassId'),
          }).then(() => {
            dispatch(
              setSnackbarData({
                name: SNACKBAR,
                message: '編輯成功',
                variant: 'success',
              })
            )
            dispatch(openSnackbar(SNACKBAR))
            mutateUserApplies();
          })
        }}
        onRetrainClick={() => {
          if (!activityId || !userApply) return;
          dispatch(
            fetchPostUserApplyRetrainEmail({
              organizationId: selectedOrg.get('organizationId'),
              activityId,
              userApplyId: userApply.userApplyId,
            })
          );
        }}
        loading={isUpdating}
      />
      <AddActivityClassDialog
        activityClasses={activityClasses}
        userApply={userApply}
        onConfirm={(selectedActivityClassId) => {
          if (selectedActivityClassId && activityId && userApply) {
            dispatch(
              fetchPostUserApplyActivityClass({
                organizationId: selectedOrg.get('organizationId'),
                activityId,
                userApplyId: userApply.userApplyId,
                activityClassId: selectedActivityClassId,
                callback: mutate,
              })
            );
          }
        }}
      />
      <BatchCreateUserApplyActivityClassDialog
        activityClasses={activityClasses}
        onConfirm={(selectedActivityClasses) => {
          const userApplyIdList = Object.keys(eachRowState)
          if (userApplyIdList.length > 0) {
            batchCreateUserApplyActivityClass({
              organizationId: selectedOrg.get('organizationId'),
              activityId,
              userApplyIdList,
              activityClassIdList: selectedActivityClasses?.map(el => el.value)
            }).then(() => {
              mutateUserApplies();
              dispatch(
                setSnackbarData({
                  name: SNACKBAR,
                  message: '批次加報場次成功',
                  variant: 'success',
                })
              )
              dispatch(openSnackbar(SNACKBAR))
              dispatch(closeDialog(BATCH_CREATE_USER_APPLY_ACTIVITY_CLASS_DIALOG))
            })
          }
        }}
        loading={batchCreating}
      />
      <BatchDeleteUserApplyActivityClassDialog
        activityClasses={activityClasses}
        onConfirm={(selectedActivityClasses) => {
          const userApplyIdList = Object.keys(eachRowState)
          if (userApplyIdList.length > 0) {
            batchDeleteUserApplyActivityClass({
              organizationId: selectedOrg.get('organizationId'),
              activityId,
              userApplyIdList,
              activityClassIdList: selectedActivityClasses?.map(el => el.value)
            }).then(() => {
              mutateUserApplies();
              dispatch(
                setSnackbarData({
                  name: SNACKBAR,
                  message: '批次取消報名場次成功',
                  variant: 'success',
                })
              )
              dispatch(openSnackbar(SNACKBAR))
              dispatch(closeDialog(BATCH_DELETE_USER_APPLY_ACTIVITY_CLASS_DIALOG))
            })
          }
        }}
        loading={batchDeleting}
      />
      <Container maxWidth={false}>
        <Segment variant="outlined">
          <SegmentHeader display="flex" alignItems="center">
            <div>
              <Typography variant="h5">活動報名會員</Typography>
              <Typography variant="body2" gutterBottom>
                可編輯報名會員的資料和場次資訊
              </Typography>
            </div>
            <div style={{ flexGrow: 1 }} />
            {
              activity.activityType === 2 && (
                <div>
                  <Button
                    size="large"
                    variant="contained"
                    color="info"
                    rounded
                    onClick={() => {
                      dispatch(
                        openDialog(BATCH_CREATE_USER_APPLY_ACTIVITY_CLASS_DIALOG)
                      )
                    }}
                    disabled={checkedNum === 0}
                    style={{marginRight: 8 }}
                  >
                    批次增加報名場次
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="error"
                    rounded
                    onClick={() => {
                      dispatch(
                        openDialog(BATCH_DELETE_USER_APPLY_ACTIVITY_CLASS_DIALOG)
                      )
                    }}
                    disabled={checkedNum === 0}
                  >
                    批次取消報名場次
                  </Button>
                </div>
              )
            }
          </SegmentHeader>
          <Paper>
            <StyledDataTable
              minWidth={1500}
              data={data?.source ?? []}
              subTitle={checkedNum ? `已選擇 ${checkedNum} 位成員` : undefined}
              onEachRowStateChange={(eachRowState) => {
                setEachRowState(eachRowState)
              }}
              SearchBarProps={{
                placeholder: '搜尋',
                onChange: handleSearchChange,
                defaultValue: payload.query,
                variant: 'outlined',
                size: 'small',
                rounded: true,
                shadowed: true,
                renderOptions: ({ handleDropDownClose }) => (
                  <SearchBarOptionsWidget>
                    <>
                      {activity.activityType === 2 && (
                        <TextField
                          label="篩選場次"
                          select
                          fullWidth
                          value={filterActivityClassId}
                          onChange={handleFilterChange}
                          margin="normal"
                        >
                          <MenuItem value="all">全部</MenuItem>
                          {activityClasses.map((el) => (
                            <MenuItem
                              key={el.activityClassId}
                              value={el.activityClassId}
                            >
                              {el.activityClassName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      <TextField
                        value={isCanceled}
                        onChange={handleCancelStatusChange}
                        label="篩選報名狀態"
                        select
                        fullWidth
                        margin="normal"
                      >
                        <MenuItem value="all">全部</MenuItem>
                        <MenuItem value="0">
                          {UserApplyActivityClassIsCanceled[0]}
                        </MenuItem>
                        <MenuItem value="1">
                          {UserApplyActivityClassIsCanceled[1]}
                        </MenuItem>
                      </TextField>
                    </>
                    <>
                      <Button onClick={handleDropDownClose}>關閉</Button>
                    </>
                  </SearchBarOptionsWidget>
                ),
              }}
              toolsbar={
                <Tooltip title="匯出報名資料">
                  <IconButton
                    component="a"
                    download
                    href={`/api/organizations/${selectedOrg.get(
                      'organizationId'
                    )}/activities/${activityId}/user-applies/export`}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
              }
              columns={
                activity.activityHasFaceRecognize === 1
                  ? [
                      '姓名',
                      '單位/職稱',
                      '手機',
                      'Email',
                      '報名時間',
                      '人臉訓練',
                      '照片',
                      '操作',
                    ]
                  : ['姓名', '單位/職稱', '手機', 'Email', '報名時間', '操作']
              }
              serverSide
              renderColumns={(columns) => (
                <TableRow>
                  <TableCell>
                    <DataTableCheckedAllCheckbox />
                  </TableCell>
                  {columns.map((el) => (
                    <TableCell key={el}>
                      {el}
                    </TableCell>
                  ))}
                </TableRow>
              )}
              renderDataRow={renderDataRow}
              isEmpty={response?.status === 204}
              MuiTablePaginationProps={{
                count: data?.total ?? 0,
                labelRowsPerPage: '每頁幾筆',
                page,
                rowsPerPage,
                onPageChange: handleChangePage,
                onRowsPerPageChange: handleRowsPerPageChange,
              }}
            />
          </Paper>
        </Segment>
      </Container>
    </>
  );
};

export default ActivityPeople;
