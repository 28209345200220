import React from 'react';

import { withStyles } from '@eGroupTeam/material';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const TagContainer = ({ className, classes, ...other }) => (
  <div className={clsx(className, classes.root)} {...other} />
);

export default withStyles(styles)(TagContainer);
