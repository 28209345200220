import React, { FC, HTMLAttributes } from 'react';

import clsx from 'clsx';
import { createStyles, Theme, WithStyles, withStyles } from '@eGroupTeam/material';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
  });

export type PageSectionProps = HTMLAttributes<HTMLDivElement>;

const PageSection: FC<PageSectionProps & WithStyles<typeof styles>> = ({
  classes,
  className,
  ...other
}) => <div className={clsx(classes.root, className)} {...other} />;

export default withStyles(styles)(PageSection);
