import React, { CSSProperties, FC } from 'react';
import { createStyles, WithStyles, withStyles } from '@eGroupTeam/material';
import clsx from 'clsx';
import FroalaEditorComponent, { MyComponentProps } from 'react-froala-wysiwyg';

const textButtons = [
  'undo',
  'redo',
  'paragraphFormat',
  'fontSize',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'textColor',
  'backgroundColor',
  'clearFormatting',
];
const paragraphButtons = [
  'alignLeft',
  'alignCenter',
  'alignRight',
  'alignJustify',
  'lineHeight',
  'formatOL',
  'formatUL',
  'outdent',
  'indent',
];
const richButtons = ['insertLink', 'insertImage', 'insertVideo', 'insertTable'];

const styles = () =>
  createStyles({
    root: {
      border: (props: FroalaEditorProps) =>
        props.disableBorder ? 'none' : '1px solid #b3b3b3',
      borderRadius: 5,
    },
  });

export interface FroalaEditorProps extends MyComponentProps {
  className?: string;
  style?: CSSProperties;
  /**
   * Hide border
   */
  disableBorder?: boolean;
}

const FroalaEditor: FC<FroalaEditorProps & WithStyles<typeof styles>> = ({
  className,
  style,
  classes,
  config: configProp,
  disableBorder,
  ...other
}) => {
  const {
    key,
    attribution,
    language,
    pluginsEnabled,
    imageUploadURL,
    fileUploadURL,
    ...config
  } = (configProp as any) || {};
  return (
    <div className={clsx(className, classes.root)} style={style}>
      <FroalaEditorComponent
        config={{
          key:
            'AVB8B-21B2C3D3F3F2E1ua2BD1IMNBUMRWAd1AYMSTRBUZYA-9H3E2J2C4C6B3D2B5F2B2==',
          toolbarSticky: false,
          attribution: false,
          language: 'zh_tw',
          pluginsEnabled: [
            'align',
            'colors',
            'draggable',
            'embedly',
            'emoticons',
            'entities',
            'file',
            'fontSize',
            'fullscreen',
            'image',
            'lineBreaker',
            'lineHeight',
            'link',
            'lists',
            'paragraphFormat',
            'quickInsert',
            'quote',
            'save',
            'table',
            'url',
            'video',
            'wordPaste',
          ],
          toolbarButtons: [textButtons, paragraphButtons, richButtons],
          toolbarButtonsSM: {
            moreText: {
              buttons: textButtons,
            },
            moreParagraph: {
              buttons: paragraphButtons,
            },
            moreRich: {
              buttons: richButtons,
            },
          },
          toolbarButtonsXS: {
            moreText: {
              buttons: textButtons,
            },
            moreParagraph: {
              buttons: paragraphButtons,
            },
            moreRich: {
              buttons: richButtons,
            },
          },
          imageUploadURL: `${process.env.REACT_APP_API_BASE_URL}/editor/upload-image`,
          fileUploadURL: `${process.env.REACT_APP_API_BASE_URL}/editor/upload-file`,
          htmlIgnoreCSSProperties: ['position'],
          ...config,
        }}
        {...other}
      />
    </div>
  );
};

export default withStyles(styles)(FroalaEditor);
