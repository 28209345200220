import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import {
  FETCH_GET_ACTIVITY_APPLY_FIELDS_SUCCESS,
  FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER_SUCCESS,
  FETCH_POST_ACTIVITY_APPLY_FIELD_SUCCESS,
  FETCH_DELETE_ACTIVITY_APPLY_FIELD_SUCCESS,
} from './redux';

const reducer = handleActions(
  {
    [FETCH_GET_ACTIVITY_APPLY_FIELDS_SUCCESS]: (state, action) =>
      state.set('activityApplyFields', action.payload),
    [FETCH_PATCH_ACTIVITY_APPLY_FIELDS_ORDER_SUCCESS]: (state, action) =>
      state.set('activityApplyFields', action.payload),
    [FETCH_POST_ACTIVITY_APPLY_FIELD_SUCCESS]: (state, action) =>
      state.update('activityApplyFields', (activityApplyFields) => {
        if (activityApplyFields) {
          return activityApplyFields.push(action.payload);
        }
        return fromJS([action.payload]);
      }),
    [FETCH_DELETE_ACTIVITY_APPLY_FIELD_SUCCESS]: (state, action) =>
      state.update('activityApplyFields', (applyFields) =>
        applyFields.filter((id) => id !== action.payload)
      ),
  },
  fromJS({})
);

export default reducer;
