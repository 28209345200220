import React, { useEffect, useCallback, useState } from 'react';

import useInterval from '@eGroupTeam/hooks/useInterval';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import calcPercent from '@eGroupTeam/utils/calcPercent';
import makeChartColors from '@eGroupTeam/utils/makeChartColors';
import { getSelectedOrg } from 'redux/modules/common';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';

import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import {
  MenuItem,
  Paper,
  Box,
  CircularProgress,
  Container,
  Grid,
  Card,
  CardHeader,
  Avatar,
  TextField,
  Typography,
  CardActionArea,
} from '@eGroupTeam/material';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import { ApplyFieldsResult, UrlParams } from 'types';
import CompareDataChart from 'components/CompareDataChart';
import { clearApiResponse } from '@eGroupTeam/redux-modules/apis';
import ActivityDailyApplyChart from './ActivityDailyApplyChart';
import ApplyFieldResponsesDialog, { DIALOG } from './ApplyFieldResponsesDialog';
import {
  fetchGetActivityApplyFieldResult,
  fetchGetUserApplyDailyCounts,
  fetchGetUserAppliesTotalResultCounts,
  fetchGetActivityApplyFieldOptionResponses,
  getActivityApplyFieldResult,
  getAppliesTotalResultCounts,
  getUserApplyDailyCountsData,
  getApplyFieldResponses,
} from './redux';
import useSelectedApplyField from '../useSelectedApplyField';

const COLORS = makeChartColors();

const ActivityDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { activityId } = useParams<UrlParams>();
  const applyFieldResult: ApplyFieldsResult[] = useSelector(
    getActivityApplyFieldResult
  );
  const appliesTotalResultCounts = useSelector(getAppliesTotalResultCounts);
  const selectedOrg = useSelector(getSelectedOrg);
  const userApplyDailyCountsData = useSelector(getUserApplyDailyCountsData);
  const applyFieldResponses = useSelector(getApplyFieldResponses);
  const {
    handleChange: handleApplyFieldChange,
    selectedApplyField,
  } = useSelectedApplyField(applyFieldResult);
  const [pointer, setPointer] = useState(false);

  const fetchData = useCallback(() => {
    dispatch(
      fetchGetUserAppliesTotalResultCounts({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
      })
    );
    dispatch(
      fetchGetUserApplyDailyCounts({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
      })
    );
    dispatch(
      fetchGetActivityApplyFieldResult({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
      })
    );
  }, [activityId, dispatch, selectedOrg]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useInterval(() => {
    fetchData();
  }, 1000 * 60 * 5);

  const handleCompareDataChartClick = (event) => {
    const { payload } = event.activePayload[0];
    if (payload.isOther) {
      dispatch(clearApiResponse(fetchGetActivityApplyFieldOptionResponses()));
      dispatch(
        fetchGetActivityApplyFieldOptionResponses({
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          applyFieldId: payload.applyField.applyFieldId,
          applyFieldOptionId: payload.applyFieldOptionId,
        })
      );
      dispatch(openDialog(DIALOG));
    }
  };

  const handleCompareDataChartMouseMove = (event) => {
    if (event.isTooltipActive) {
      const { payload } = event.activePayload[0];
      if (payload.isOther) {
        setPointer(true);
      } else {
        setPointer(false);
      }
    } else {
      setPointer(false);
    }
  };

  if (
    !applyFieldResult ||
    !appliesTotalResultCounts ||
    !userApplyDailyCountsData
  ) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <ApplyFieldResponsesDialog applyFieldResponses={applyFieldResponses} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardActionArea
                onClick={() =>
                  history.push(`/me/activities/${activityId}/people`)
                }
              >
                <CardHeader
                  avatar={
                    <Avatar style={{ backgroundColor: COLORS[0] }}>
                      <PeopleIcon />
                    </Avatar>
                  }
                  title="原始報名人數"
                  subheader={appliesTotalResultCounts.originalApplyCount}
                />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar style={{ backgroundColor: COLORS[1] }}>
                    <PersonAddDisabledIcon />
                  </Avatar>
                }
                title="取消人數"
                subheader={appliesTotalResultCounts.cancelApplyCount}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar style={{ backgroundColor: COLORS[1] }}>
                    <PersonAddIcon />
                  </Avatar>
                }
                title="實際報名人數"
                subheader={appliesTotalResultCounts.realApplyCount}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar style={{ backgroundColor: COLORS[2] }}>
                    <EventAvailableIcon />
                  </Avatar>
                }
                title="總報到率"
                subheader={`${calcPercent(
                  appliesTotalResultCounts.realCheckInCount,
                  appliesTotalResultCounts.realApplyCount
                )} (${appliesTotalResultCounts.realCheckInCount}/${
                  appliesTotalResultCounts.realApplyCount
                })`}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">每日報名人數變化</Typography>
              </Box>
              <Box p={2} pt={0}>
                <ActivityDailyApplyChart data={userApplyDailyCountsData} />
              </Box>
            </Paper>
          </Grid>
          {selectedApplyField && (
            <Grid item xs={12}>
              <Paper>
                <Box p={2} display="flex" alignItems="center">
                  <Typography variant="h6" display="inline">
                    報名欄位統計
                  </Typography>
                  <TextField
                    select
                    size="small"
                    value={selectedApplyField.applyFieldId}
                    onChange={handleApplyFieldChange}
                    variant="outlined"
                  >
                    {applyFieldResult.map((el) => (
                      <MenuItem key={el.applyFieldId} value={el.applyFieldId}>
                        {el.applyFieldName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box p={2} pt={0}>
                  <CompareDataChart
                    BarChartProps={{
                      data: selectedApplyField.applyFieldOptionList,
                      onClick: handleCompareDataChartClick,
                      onMouseMove: handleCompareDataChartMouseMove,
                      style: {
                        cursor: pointer ? 'pointer' : 'default',
                      },
                    }}
                  />
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default ActivityDashboard;
