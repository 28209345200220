import React, { Component, ComponentType, ErrorInfo, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import getDeviceInfo from '@eGroupTeam/utils/getDeviceInfo';

import Typography from '@eGroupTeam/material/Typography';
import Button from '@eGroupTeam/material/Button';
import Box from '@eGroupTeam/material/Box';

import apis from 'redux/apis';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

export interface OwnProps extends RouteComponentProps {
  children: ReactNode;
}

interface StateProps {
  store: any;
}
type Props = StateProps & OwnProps;

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundry extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      errorInfo: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    apis.tools.createLog({
      function: 'ErrorBoundry',
      browserDescription: window.navigator.userAgent,
      jsonData: {
        error,
        errorInfo,
        deviceInfo: getDeviceInfo(),
        store: this.props.store.toJS(),
      },
      level: 'ERROR',
    });
  }

  handleGoHome = () => {
    this.props.history.replace('/');
    this.setState({
      error: undefined,
      errorInfo: undefined,
    });
  };

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <div>
            <Typography variant="h5" gutterBottom align="center">
              MICEPass
              服務團隊發現您的操作出現了異常，已經正在處理中，我們會協助您解決此問題，感謝您的支持！
            </Typography>
            <Typography align="center">
              <Button variant="outlined" onClick={this.handleGoHome}>
                回首頁
              </Button>
            </Typography>
          </div>
        </Box>
      );
    }
    return children;
  }
}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => ({
  store: state,
});

export default compose<ComponentType>(
  withRouter,
  connect<StateProps, Record<string, never>, OwnProps>(mapStateToProps)
)(ErrorBoundry);
