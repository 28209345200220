import React, { FC, ComponentType } from 'react';

import isEmail from 'validator/lib/isEmail';
import { Field } from 'formik';
import TextLoadingField from '@eGroupTeam/material-formik/TextLoadingField';
import PickerField from '@eGroupTeam/material-formik/PickerField';
import RadioInputGroupField from '@eGroupTeam/material-formik/RadioInputGroupField';
import CheckboxInputGroupField from '@eGroupTeam/material-formik/CheckboxInputGroupField';
import CheckboxField from '@eGroupTeam/material-formik/CheckboxField';
import ReactSelectField from '@eGroupTeam/material-formik/ReactSelectField';
import RatingField from 'components/RatingField';
import DisplayField from 'components/DisplayField';

export type SchemaType =
  | 'null'
  | 'boolean'
  | 'object'
  | 'array'
  | 'number'
  | 'string';

export type Schema = {
  title?: string;
  description?: string;
  type: SchemaType;
  required: string[];
  properties: any;
};
export interface SchemaFieldsProps {
  schema: Schema;
  component?: ComponentType<any>;
}

const isRequiredVaild = (value, hasRequired) => {
  if (!hasRequired) return undefined;
  if (!value) {
    return '這個是必填問題';
  }
  if (
    !(value instanceof Date) &&
    typeof value === 'object' &&
    Object.keys(value).length === 0
  ) {
    return '這個是必填問題';
  }
  return undefined;
};

const isEmailVaild = (value, hasRequired) => {
  if (hasRequired && !value) {
    return '這個是必填問題';
  }
  if (value && !isEmail(value)) {
    return 'Email 格式錯誤';
  }
  return undefined;
};

const atLeastOneIsRequiredVaild = (value, hasRequired) => {
  if (!hasRequired) return undefined;
  if (!value) {
    return '這個是必填問題';
  }
  if (Object.keys(value).length === 0) {
    return '這個是必填問題';
  }
  const checks = Object.keys(value)
    .map((key) => {
      const el = value[key];
      if (el.checked) return true;
      return false;
    })
    .filter(Boolean);
  if (checks.length === 0) {
    return '這個是必填問題';
  }

  return undefined;
};

const getFieldProps = (field, hasRequired: boolean) => {
  let fieldProps = { ...field };
  switch (fieldProps.type) {
    case 'text':
      fieldProps = {
        ...fieldProps,
        required: hasRequired,
        fullWidth: true,
        component: TextLoadingField,
        validate: (value) => isRequiredVaild(value, hasRequired),
      };
      break;
    case 'textarea':
      fieldProps = {
        ...fieldProps,
        multiline: true,
        required: hasRequired,
        fullWidth: true,
        component: TextLoadingField,
        validate: (value) => isRequiredVaild(value, hasRequired),
      };
      break;
    case 'email':
      fieldProps = {
        ...fieldProps,
        required: hasRequired,
        fullWidth: true,
        component: TextLoadingField,
        validate: (value) => isEmailVaild(value, hasRequired),
        type: 'email',
      };
      break;
    case 'date':
      fieldProps = {
        ...fieldProps,
        required: hasRequired,
        fullWidth: true,
        component: PickerField,
        validate: (value) => isRequiredVaild(value, hasRequired),
        pickerFormat: 'yyyy-MM-dd',
      };
      break;
    case 'rating':
      fieldProps = {
        ...fieldProps,
        required: hasRequired,
        component: RatingField,
        validate: (value) => isRequiredVaild(value, hasRequired),
      };
      break;
    case 'radioGroup':
      fieldProps = {
        ...fieldProps,
        required: hasRequired,
        fullWidth: true,
        component: RadioInputGroupField,
        validate: (value) => isRequiredVaild(value, hasRequired),
      };
      break;
    case 'checkboxGroup':
      fieldProps = {
        ...fieldProps,
        required: hasRequired,
        fullWidth: true,
        component: CheckboxInputGroupField,
        validate: (value) => atLeastOneIsRequiredVaild(value, hasRequired),
      };
      break;
    case 'boolean':
      fieldProps = {
        ...fieldProps,
        required: hasRequired,
        fullWidth: true,
        component: CheckboxField,
      };
      break;
    case 'select':
      fieldProps = {
        ...fieldProps,
        MuiTextFieldProps: {
          fullWidth: true,
        },
        required: hasRequired,
        component: ReactSelectField,
        isSearchable: false,
        validate: (value) => isRequiredVaild(value, hasRequired),
      };
      break;
    case 'titleBlock':
      fieldProps = {
        ...fieldProps,
        component: DisplayField,
      };
      break;
    default:
      break;
  }
  return fieldProps;
};

/**
 * A simple React component capable of building HTML forms out of a JSON schema and using material ui by default.
 * To understand json schema https://json-schema.org/learn/getting-started-step-by-step.html.
 * Inspired by https://react-jsonschema-form.readthedocs.io/en/latest/#styling-your-forms.
 */
const SchemaFields: FC<SchemaFieldsProps> = ({ schema, component }) => {
  const { required, properties } = schema;

  const generateField = (field, key: string) => {
    const hasRequired = required ? required.indexOf(key) !== -1 : false;
    const fieldProps = getFieldProps(field, hasRequired);
    const FieldComponent = component ?? Field;
    return <FieldComponent key={fieldProps.name} {...fieldProps} />;
  };

  return (
    <>
      {Object.keys(properties).map((key) =>
        generateField(properties[key], key)
      )}
    </>
  );
};

export default SchemaFields;
