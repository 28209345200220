import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  openDialog,
  setDialogData,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { replace } from 'connected-react-router/immutable';

import { DIALOG } from 'App';
import {
  fetchPostVerifySurveyQualifyRequest,
  fetchPostVerifySurveyQualifySuccess,
  fetchPostVerifySurveyQualifyFailure,
  FETCH_POST_VERIFY_SURVEY_QUALIFY,
} from './redux';

export const fetchPostVerifySurveyQualifyEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_VERIFY_SURVEY_QUALIFY,
  apiName: 'landingPage.fetchPostVerifySurveyQualify',
  fetchRequest: fetchPostVerifySurveyQualifyRequest,
  handleSuccess: (response, { action }) => {
    if (response.data.clientMessage) {
      return [
        fetchPostVerifySurveyQualifySuccess(),
        setDialogData({
          name: DIALOG,
          primary: '錯誤',
          message: response.data.clientMessage,
        }),
        openDialog(DIALOG),
      ];
    }
    return [
      fetchPostVerifySurveyQualifySuccess(),
      replace(`/surveys/${action.payload.surveyId}`),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostVerifySurveyQualifyFailure(error))
    ),
});

export default combineEpics(fetchPostVerifySurveyQualifyEpic);
