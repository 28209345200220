import React from 'react';
import PropTypes from 'prop-types';

import Main from '@eGroupTeam/material-layout/Main';
import Center from '@eGroupTeam/material-layout/Center';
import RatioImage from '@eGroupTeam/material/RatioImage';
import {
  Paper,
  Typography,
  Container,
  Box,
  CircularProgress,
} from '@eGroupTeam/material';

import headimg from 'static/images/done.jpg';

const SurveyDone = ({ survey }) => {
  if (!survey) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Main>
      <Container maxWidth="sm">
        <Paper>
          <RatioImage
            fit="cover"
            ratio="16:9"
            src={headimg}
            alt="Photo by Vasily Koloda on Unsplash"
          />
          <Box p={3}>
            <Typography variant="h6" align="center">
              您已填寫完畢！
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Main>
  );
};

SurveyDone.propTypes = {
  // parent props
  survey: PropTypes.object.isRequired,
};

export default SurveyDone;
