import React, { FC } from "react";
import { makeStyles } from "@material-ui/core";

import Grid from "@eGroupTeam/material/Grid";
import { Box, RatioImage } from "@eGroupTeam/material";
import Typography from "@eGroupTeam/material/Typography";
import YoutubePlayer from '@eGroupTeam/material-module/YoutubePlayer';
import PhotoModal from "./PhotoModal";

interface Props {
  imagePath?: string
  youtubeUrl?: string
  title: string;
  description: string;
}

const useStyles = makeStyles({
  contentItem: {
    "& h3": {
      fontSize: "16px",
      marginTop: "10px",
      lineHeight: "1.2",
    },
    "& p": {
      fontSize: "14px",
      color: "#444444d6",
    },
  },
  cardItem: {
    backgroundColor: "white",
    borderRadius: "20px",
    margin: "0 auto",
    display: "block",
  },
});

const TogglePanelContentItem: FC<Props> = (props) => {
  const classes = useStyles();

  const { imagePath, youtubeUrl, title, description } = props;

  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);

  const handleOpenPhotoModal = () => {
    setOpenPhotoModal(true);
  };

  const handleClosePhotoModal = () => {
    setOpenPhotoModal(false);
  };

  return (
    <>
      <PhotoModal open={openPhotoModal} handleClose={handleClosePhotoModal} src={imagePath} alt={title}/>
      <Grid item xs={12} sm={6} md={3}>
        <Box className={classes.contentItem}>
          <Box className={classes.cardItem} textAlign="center">
            {
              youtubeUrl ? 
              <YoutubePlayer
                placeholder={imagePath}
                iframeSrc={youtubeUrl}
                iframeTitle={title}
                variant="lightbox"
              /> :
              <RatioImage src={imagePath} onClick={handleOpenPhotoModal} alt={title}/>
            }
          </Box>
          <Typography variant="h3" fontWeight={600}>
            {title}
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </Box>
      </Grid>
    </>
  );
};

export default TogglePanelContentItem;
