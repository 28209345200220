import React, { FC } from 'react';

import {
  Drawer,
  createStyles,
  withStyles,
  Toolbar,
  ToolbarProps,
  WithStyles,
  Theme,
  Link as MuiLink,
  Hidden,
} from '@eGroupTeam/material';
import { useLocation, NavLink } from 'react-router-dom';
import NestedSideMenu from '@eGroupTeam/material-module/NestedSideMenu';
import useIsOpen from '@eGroupTeam/hooks/useIsOpen';
import clsx from 'clsx';
import Hamburger from 'components/Hamburger';
import UserMenu from 'components/UserMenu';
import Logo from 'components/Logo';

const routes = [
  { key: '/pricing', path: '/pricing', breadcrumbName: '服務價格' },
  { key: '/reference', path: '/reference', breadcrumbName: '活動花絮' }
];

const styles = (theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    navContainer: {
      display: 'flex',
    },
    navItemActive: {
      color: theme.palette.primary.main,
    },
    nav: {
      color: theme.palette.text.primary,
      '& ul': {
        display: 'flex',
        gap: '2rem',
        listStyle: 'none',
        alignItems: 'center',
      },
    },
  });

export type AppToolBarProps = ToolbarProps & WithStyles<typeof styles>;

const AppToolBar: FC<AppToolBarProps> = ({ classes, ...other }) => {
  const location = useLocation();
  const { isOpen, handleOpen, handleClose } = useIsOpen();

  const handleToggle = () => {
    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  return (
    <>
      <Drawer open={isOpen} onClose={handleClose}>
        <NestedSideMenu
          routes={routes}
          pathname={location.pathname}
          NestedListItemProps={{
            MuiListItemProps: {
              onClick: handleClose,
            },
          }}
          NestedListItemItemsProps={{
            MuiListItemProps: {
              onClick: handleClose,
            },
          }}
        />
      </Drawer>
      <Toolbar {...other}>
        <Hidden mdUp>
          <Hamburger edge="start" onClick={handleToggle} aria-label="Menu" />
          <div className={clsx(classes.grow, classes.center)}>
            <Logo />
          </div>
          <UserMenu loginButtonColor="text" />
        </Hidden>
        <Hidden smDown>
          <Logo />
          <div className={classes.grow} />
          <div className={classes.navContainer}>
            <nav className={classes.nav}>
              <ul>
                <li>
                  <MuiLink
                    component={NavLink}
                    to="/pricing"
                    activeClassName={classes.navItemActive}
                    underline="none"
                    color="inherit"
                  >
                    服務價格
                  </MuiLink>
                </li>
                <li>
                  <UserMenu loginButtonColor="text" />
                </li>
              </ul>
            </nav>
          </div>
        </Hidden>
      </Toolbar>
    </>
  );
};

export default withStyles(styles)(AppToolBar);
