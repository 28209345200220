import React, { useState } from 'react';

import apis from 'redux/apisOrg';
import { SNACKBAR } from 'App';
import { format } from '@eGroupTeam/utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  openSnackbar,
  setSnackbarData,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import {
  withReduxDialog,
  closeDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import { getSelectedOrg } from 'redux/modules/common';

import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import CopyTextField from '@eGroupTeam/material/CopyTextField';
import {
  makeStyles,
  IconButton,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@eGroupTeam/material';
import DeleteIcon from '@material-ui/icons/Delete';
import useOrgInvitations from 'utils/useOrgInvitations';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import { OrgInvitation } from 'types';
import { OrganizationInvitationStatus } from 'enums';
import OrgMemberInvitationAddDialog, {
  DIALOG as ADD_INVITATION_DIALOG,
} from './OrgMemberInvitationAddDialog';

const DELETE_DIALOG = 'ConfirmDeleteMemberInvitationDialog';
const ConfirmDeleteDialog = withReduxDialog(DELETE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);

const useStyles = makeStyles((theme) => ({
  copyTextField: {
    margin: 0,
    marginRight: theme.spacing(1),
  },
}));

const OrgMemberInvitationDataList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedOrg = useSelector(getSelectedOrg);
  const [
    selectedOrgInvitation,
    setSelectedOrgInvitation,
  ] = useState<OrgInvitation>();

  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    page,
    rowsPerPage,
  } = useDataTable('orgInvitations', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });
  const { data, isValidating, response, mutate } = useOrgInvitations(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    payload
  );
  const {
    excute: createInvitation,
    isLoading: isCreating,
  } = useAxiosApiWrapper(apis.fetchPostOrganizationMemberInvitation);
  const { excute: updateInvitation } = useAxiosApiWrapper(
    apis.fetchPostMemberIntoOrganization
  );

  const handleCopy = () => {
    dispatch(
      setSnackbarData({
        name: SNACKBAR,
        message: '複製成功',
        variant: 'success',
      })
    );
    dispatch(openSnackbar(SNACKBAR));
  };

  const handleUpdateInvitation = (orgInvitation: OrgInvitation) => (e) => {
    e.stopPropagation();
    setSelectedOrgInvitation(orgInvitation);
    dispatch(openDialog(DELETE_DIALOG));
  };

  const handleUpdateConfirm = () => {
    if (selectedOrgInvitation) {
      updateInvitation({
        organizationId: selectedOrgInvitation.organization.organizationId,
        organizationInvitationStatus: 4,
        organizationInvitationToken:
          selectedOrgInvitation.organizationInvitationToken,
      }).then(() => {
        mutate();
      });
    }
  };

  const handleAddSubmit = (values) => {
    createInvitation({
      organizationId: selectedOrg.get('organizationId'),
      organizationInvitationEmail: values.get('organizationInvitationEmail'),
    }).then(() => {
      mutate();
      dispatch(
        setSnackbarData({
          name: SNACKBAR,
          message: '成功寄出邀請信件！',
          variant: 'success',
        })
      );
      dispatch(openSnackbar(SNACKBAR));
      dispatch(closeDialog(ADD_INVITATION_DIALOG));
    });
  };

  const renderColumns = (rowData) => (
    <TableRow>
      {rowData.map((el) => (
        <TableCell key={el}>
          <Typography variant="body2">{el}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );

  const renderDataRow = (rowData: unknown) => {
    const orgInvitation = rowData as OrgInvitation;
    return (
      <TableRow key={orgInvitation.organizationInvitationId}>
        <TableCell>
          {orgInvitation.organizationInvitationEmail}
        </TableCell>
        <TableCell>
          <CopyTextField
            margin="dense"
            value={`https://${window.location.host}/org/invitation?organizationId=${orgInvitation.organization.organizationId}&token=${orgInvitation.organizationInvitationToken}`}
            className={classes.copyTextField}
            onCopy={handleCopy}
            variant="outlined"
          />
        </TableCell>
        <TableCell>
          {OrganizationInvitationStatus[orgInvitation.organizationInvitationStatus]}
        </TableCell>
        <TableCell>
          {format(
            orgInvitation.organizationInvitationCreateDateString,
            'yyyy/MM/dd HH:mm'
          )}
        </TableCell>
        <TableCell>
          <Tooltip
            title="取消邀請"
            onClick={handleUpdateInvitation(orgInvitation)}
          >
            <IconButton size="small">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <OrgMemberInvitationAddDialog
        onSubmit={handleAddSubmit}
        loading={isCreating}
      />
      <ConfirmDeleteDialog
        primary="取消成員邀請"
        message="確定要取消此邀請嗎？"
        onConfirm={handleUpdateConfirm}
      />
      <Paper square>
        <DataTable
          minWidth={700}
          size="small"
          data={data?.source ?? []}
          columns={['信箱', '邀請網址', '邀請狀態', '邀請日期', '取消邀請']}
          SearchBarProps={{
            onChange: handleSearchChange,
          }}
          serverSide
          loading={isValidating}
          isEmpty={response?.status === 204}
          renderColumns={renderColumns}
          renderDataRow={renderDataRow}
          MuiTablePaginationProps={{
            count: data?.total ?? 0,
            labelRowsPerPage: '每頁幾筆',
            page,
            rowsPerPage,
            onPageChange: handleChangePage,
            onRowsPerPageChange: handleRowsPerPageChange,
          }}
        />
      </Paper>
    </>
  );
};

export default OrgMemberInvitationDataList;
