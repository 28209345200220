import React, { FC, HTMLAttributes } from 'react';
import { createStyles, WithStyles, withStyles } from '@eGroupTeam/material';
import clsx from 'clsx';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      padding: '8px 6px 0 6px',
      flexDirection: 'row-reverse',
    },
  });

export type DialogHeaderActionsProps = HTMLAttributes<HTMLDivElement>;

const DialogHeaderActions: FC<
  DialogHeaderActionsProps & WithStyles<typeof styles>
> = ({ className, classes, ...other }) => (
  <div className={clsx(className, classes.root)} {...other} />
);

export default withStyles(styles)(DialogHeaderActions);
