import React from 'react';

import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@eGroupTeam/material';

export const DIALOG = 'activityCreateSuccessDialog';

const ActivityCreateSuccessDialog = ({ isOpen, handleClose }) => {

  const myHandleClose = () => {
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={myHandleClose} disableEnforceFocus>
      <DialogTitle>恭喜~活動建立成功!</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">
          開放報名後來賓即可參加活動!
          您可以透過基礎設定讓活動內容更豐富，並透過分享與通知推廣您的活動!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={myHandleClose}>
          知道了
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)(ActivityCreateSuccessDialog);
