import { AxiosError } from 'axios';
import { createAction } from '@reduxjs/toolkit';

export const fetchGetOrganizations = createAction(
  'components/routerRoot/fetchGetOrganizations'
);
export const fetchGetOrganizationsRequest = createAction(
  'components/routerRoot/fetchGetOrganizationsRequest'
);
export const fetchGetOrganizationsSuccess = createAction<unknown>(
  'components/routerRoot/fetchGetOrganizationsSuccess'
);
export const fetchGetOrganizationsFailure = createAction<AxiosError>(
  'components/routerRoot/fetchGetOrganizationsFailure'
);

export const fetchGetQrpassQualify = createAction<unknown>(
  'components/routerRoot/fetchGetQrpassQualify'
);
export const fetchGetQrpassQualifyRequest = createAction(
  'components/routerRoot/fetchGetQrpassQualifyRequest'
);
export const fetchGetQrpassQualifySuccess = createAction<unknown>(
  'components/routerRoot/fetchGetQrpassQualifySuccess'
);
export const fetchGetQrpassQualifyFailure = createAction<AxiosError>(
  'components/routerRoot/fetchGetQrpassQualifyFailure'
);

export const fetchGetMemberInfo = createAction(
  'components/routerRoot/fetchGetMemberInfo'
);
export const fetchGetMemberInfoRequest = createAction(
  'components/routerRoot/fetchGetMemberInfoRequest'
);
export const fetchGetMemberInfoSuccess = createAction<unknown>(
  'components/routerRoot/fetchGetMemberInfoSuccess'
);
export const fetchGetMemberInfoFailure = createAction<AxiosError>(
  'components/routerRoot/fetchGetMemberInfoFailure'
);

export const fetchGetMemberModules = createAction<unknown>(
  'components/routerRoot/fetchGetMemberModules'
);
export const fetchGetMemberModulesRequest = createAction(
  'components/routerRoot/fetchGetMemberModulesRequest'
);
export const fetchGetMemberModulesSuccess = createAction<unknown>(
  'components/routerRoot/fetchGetMemberModulesSuccess'
);
export const fetchGetMemberModulesFailure = createAction<AxiosError>(
  'components/routerRoot/fetchGetMemberModulesFailure'
);
