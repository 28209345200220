import React from 'react';
import PropTypes from 'prop-types';

import { reduxForm, Field, Form } from 'redux-form/immutable';
import { orgValidate } from 'utils/validates';

import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';

export const FORM = 'createOrgForm';

const CreateOrgForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Field
      name="organizationName"
      label="名稱"
      component={TextLoadingField}
      fullWidth
      variant="outlined"
      required
      margin="normal"
    />
    <Field
      name="organizationContactPerson"
      label="聯絡人"
      component={TextLoadingField}
      fullWidth
      variant="outlined"
      required
      margin="normal"
    />
    <Field
      name="organizationContactEmail"
      label="聯絡 Email"
      component={TextLoadingField}
      fullWidth
      variant="outlined"
      required
      margin="normal"
    />
    <Field
      name="organizationContactPhone"
      label="聯絡電話"
      component={TextLoadingField}
      fullWidth
      variant="outlined"
      required
      margin="normal"
    />
    <Field
      name="organizationContactAddress"
      label="聯絡地址"
      component={TextLoadingField}
      fullWidth
      variant="outlined"
      required
      margin="normal"
    />
    <Field
      name="organizationInvoiceTaxIdNumber"
      label="統一編號"
      component={TextLoadingField}
      fullWidth
      variant="outlined"
      margin="normal"
    />
  </Form>
);

CreateOrgForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORM,
  validate: orgValidate,
})(CreateOrgForm);
