import React, { VFC } from "react";
import { makeStyles } from "@material-ui/core";

import Container from "@eGroupTeam/material/Container";
import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import { Box } from "@eGroupTeam/material";
import TestimonialItem from "./TestimonialItem";

const useStyles = makeStyles((theme) => ({
  testimonialSection: {
    marginTop: "3rem",
    paddingBottom: "1.5rem",
  },
  sectionHeader: {
    "& p": {
      color: theme.egPalette.primary[1],
      fontWeight: "700",
    },
    "& h2": {
      fontSize: "31px",
      color: "#42495b",
      lineHeight: "1.2",
      marginBottom: "0.5rem",
    },
  },
}));

const TestimonialsSection: VFC = () => {
  const classes = useStyles();

  const testimonialList = [
    // {
    //   id: 1,
    //   avatar: "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
    //   description: "Lorem Ipsum is simply dummy text of the printing & typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",
    //   name: "張小姐",
    //   dummyText: "馴錢師財商顧問(股)",
    // },
    {
      id: 2,
      avatar: "https://cdn.micepass.com/resources/testimonial/egroupai-daniel.jpg",
      description: "2020年舉辦產品體驗會，透過MICEPass平台在短短3週內完成活動舉辦、邀請、通知，當天來賓80人報到在短短15分鐘內就完成入場，體驗非常順暢 ; 活動後，管理會員資料非常方便，能夠清楚掌握 VIP客戶，未來我們還會繼續使用MICEPass，給來賓更好的體驗。",
      name: "林先生",
      dummyText: "eGroupAI",
    },
    // {
    //   id: 3,
    //   avatar: "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
    //   description: "2020年舉辦產品體驗會，透過MICEPass平台在短短3週內完成了活動舉辦、邀請、通知，當天來賓80人報到在短短15分鐘內就完成入場，體驗非常順暢;活動後管理會員資料非常方便，有夠有找出關鍵的VIP客戶，期待2021的產品研討會。",
    //   name: "林先生",
    //   dummyText: "eGroupAI",
    // },
  ];

  return (
    <Box className={classes.testimonialSection}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.sectionHeader}>
            <Typography variant="body1" fontWeight={700} align="center">
              是你最好的夥伴
            </Typography>
            <Typography variant="h2" fontWeight={700} align="center">
              喜愛 MICEPass 的客戶這樣說
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} justifyContent="center">
              {testimonialList.map((testimonial) => (
                <Grid item xs={12} sm={6} md={4} key={testimonial.id}>
                  <TestimonialItem testimonial={testimonial} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TestimonialsSection;
