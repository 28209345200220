import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import {
  Form,
  Field,
  formValueSelector,
  reduxForm,
} from 'redux-form/immutable';
import makeEditableFieldUtils from '@eGroupTeam/hooks/immutable/makeEditableFieldUtils';
import { orgValidate } from 'utils/validates';

import FormFieldGroup from '@eGroupTeam/material/FormFieldGroup';
import EditableField from '@eGroupTeam/material/EditableField';
import EditableFieldShowing from '@eGroupTeam/material/EditableFieldShowing';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import Typography from '@eGroupTeam/material/Typography';
import ImageUploadArea from 'components/ImageUploadArea';
import { DropzoneOptions } from 'react-dropzone';
import { InjectedFormProps } from 'redux-form';

export const FORM = 'orgInfoForm';

const useEditableFieldUtils = makeEditableFieldUtils(FORM);
const selector = formValueSelector(FORM);

export interface OrgInfoFormProps {
  onDrop?: DropzoneOptions['onDrop'];
  isUploading?: boolean;
}

const OrgInfoForm: FC<OrgInfoFormProps & InjectedFormProps> = ({
  onDrop,
  isUploading,
  handleSubmit,
}) => {
  const organizationLogoPath = useSelector((state) =>
    selector(state, 'organizationLogoPath')
  );
  const { formValues, handleSave, handleClose } = useEditableFieldUtils();

  return (
    <Form onSubmit={handleSubmit}>
      <FormFieldGroup required label="名稱">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1" style={{ wordBreak: 'break-all' }}>
              {formValues && formValues.get('organizationName')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="organizationName"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup required label="聯絡人">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('organizationContactPerson')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="organizationContactPerson"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup required label="聯絡 Email">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('organizationContactEmail')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="organizationContactEmail"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup required label="聯絡電話">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('organizationContactPhone')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="organizationContactPhone"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup required label="聯絡地址">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('organizationContactAddress')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="organizationContactAddress"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup required label="統一編號">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('organizationInvoiceTaxIdNumber')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="organizationInvoiceTaxIdNumber"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <ImageUploadArea
        onDrop={onDrop}
        uploadedImagePath={organizationLogoPath}
        uploading={isUploading}
        ratio="500:100"
      >
        上傳單位 Logo
        <br />
        500*100
      </ImageUploadArea>
    </Form>
  );
};

export default reduxForm<any, OrgInfoFormProps>({
  form: FORM,
  validate: orgValidate,
  enableReinitialize: true,
})(OrgInfoForm);
