import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import { normalize } from 'normalizr';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { initialize, reset } from 'redux-form/immutable';
import { setEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import {
  openDialog,
  closeDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  fetchGetUsers,
  fetchGetUsersRequest,
  fetchGetUsersSuccess,
  fetchGetUsersFailure,
  fetchGetUserRequest,
  fetchGetUserSuccess,
  fetchGetUserFailure,
  fetchPostUserRequest,
  fetchPostUserSuccess,
  fetchPostUserFailure,
  fetchPatchUserRequest,
  fetchPatchUserSuccess,
  fetchPatchUserFailure,
  fetchPostUsersByExcelRequest,
  fetchPostUsersByExcelSuccess,
  fetchPostUsersByExcelFailure,
  fetchGetUserActivitiesRequest,
  fetchGetUserActivitiesSuccess,
  fetchGetUserActivitiesFailure,
  fetchPostUserActivitiesByExcelRequest,
  fetchPostUserActivitiesByExcelSuccess,
  fetchPostUserActivitiesByExcelFailure,
  fetchDeleteUserRequest,
  fetchDeleteUserSuccess,
  fetchDeleteUserFailure,
  FETCH_GET_USERS,
  FETCH_GET_USER,
  FETCH_POST_USER,
  FETCH_PATCH_USER,
  FETCH_POST_USERS_BY_EXCEL,
  FETCH_GET_USER_ACTIVITIES,
  FETCH_POST_USER_ACTIVITIES_BY_EXCEL,
  FETCH_DELETE_USER,
  fetchGetUserActivities,
} from './redux';
import { DIALOG as USER_IMPORT_RESULT_DIALOG } from './UsersImportResultDialog';
import { DIALOG as ADD_DIALOG } from './UserAddDialog';
import { FORM as EDIT_FORM } from './UserEditForm';
import UsersActivityImportEpics from './UsersActivityImport/epics';

export const fetchGetUsersEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_USERS,
  apiName: 'fetchGetUsers',
  fetchRequest: fetchGetUsersRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.users
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetUsersSuccess(
        fromJS({
          result,
          total: response.data.total || 0,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetUsersFailure(error))
    ),
});

export const fetchGetUserEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_USER,
  apiName: 'fetchGetUser',
  fetchRequest: fetchGetUserRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data || {}, schema.user);
    return [setEntities(fromJS(entities)), fetchGetUserSuccess(fromJS(result))];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetUserFailure(error))
    ),
});

export const fetchPostUserEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_USER,
  apiName: 'fetchPostUser',
  fetchRequest: fetchPostUserRequest,
  handleSuccess: (response, { action }) => [
    fetchPostUserSuccess(fromJS(response.data)),
    fetchGetUsers({
      organizationId: action.payload.organizationId,
      startIndex: 0,
      size: 10,
    }),
    closeDialog(ADD_DIALOG),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostUserFailure(error)),
      of(
        setSnackbarData({
          name: SNACKBAR,
          message: error.response.data.clientMessage,
          variant: 'error',
        })
      ),
      of(openSnackbar(SNACKBAR))
    ),
});

export const fetchPatchUserEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_USER,
  apiName: 'fetchPatchUser',
  fetchRequest: fetchPatchUserRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(response.data || {}, schema.user);
    return [
      setEntities(fromJS(entities)),
      fetchPatchUserSuccess(fromJS(result)),
      initialize(EDIT_FORM, response.data, false),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchUserFailure(error)),
      of(reset(EDIT_FORM))
    ),
});

export const fetchPostUsersByExcelEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_USERS_BY_EXCEL,
  apiName: 'fetchPostUsersByExcel',
  fetchRequest: fetchPostUsersByExcelRequest,
  handleSuccess: (response, { action }) => [
    fetchPostUsersByExcelSuccess(fromJS(response.data)),
    fetchGetUsers({
      organizationId: action.payload.organizationId,
      startIndex: 0,
      size: 10,
    }),
    setSnackbarData({
      name: SNACKBAR,
      message: '會員匯入成功',
      variant: 'success',
    }),
    openSnackbar(SNACKBAR),
    openDialog(USER_IMPORT_RESULT_DIALOG),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostUsersByExcelFailure(error)),
      of(
        setSnackbarData({
          name: SNACKBAR,
          message: error.response.data.clientMessage,
          variant: 'error',
        })
      ),
      of(openSnackbar(SNACKBAR))
    ),
});

export const fetchGetUserActivitiesEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_USER_ACTIVITIES,
  apiName: 'fetchGetUserActivities',
  fetchRequest: fetchGetUserActivitiesRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.activities
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetUserActivitiesSuccess(
        fromJS({
          result,
          total: response.data.total || 0,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetUserActivitiesFailure(error))
    ),
});

export const fetchPostUserActivitiesByExcelEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_USER_ACTIVITIES_BY_EXCEL,
  apiName: 'fetchPostUserActivitiesByExcel',
  fetchRequest: fetchPostUserActivitiesByExcelRequest,
  handleSuccess: (response, { action }) => [
    fetchPostUserActivitiesByExcelSuccess(fromJS(response.data)),
    fetchGetUserActivities({
      organizationId: action.payload.organizationId,
      userId: response.data.userId,
      startIndex: 0,
      size: 10,
    }),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostUserActivitiesByExcelFailure(error))
    ),
});
export const fetchDeleteUserEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_USER,
  apiName: 'fetchDeleteUser',
  fetchRequest: fetchDeleteUserRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(response.data || {}, schema.user);
    return [
      setEntities(fromJS(entities)),
      fetchDeleteUserSuccess(fromJS(result)),
      fetchGetUsers({
        organizationId: action.payload.organizationId,
        startIndex: 0,
        size: 10,
      }),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteUserFailure(error))
    ),
});

export default combineEpics(
  UsersActivityImportEpics,
  fetchGetUsersEpic,
  fetchGetUserEpic,
  fetchPostUserEpic,
  fetchPatchUserEpic,
  fetchPostUsersByExcelEpic,
  fetchGetUserActivitiesEpic,
  fetchPostUserActivitiesByExcelEpic,
  fetchDeleteUserEpic
);
