import React, { FC } from 'react';

import { format } from '@eGroupTeam/utils/dateUtils';

import Typography from '@eGroupTeam/material/Typography';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ActivityClass } from 'types';
import ActivityGrid, { ActivityGridProps } from './ActivityGrid';

export interface ActivityClassDayListProps extends ActivityGridProps {
  activityClass: ActivityClass;
}

const ActivityClassDayList: FC<ActivityClassDayListProps> = ({
  activityClass,
  ...other
}) => (
  <ActivityGrid {...other}>
    <>
      <DateRangeIcon color="action" />
      <Typography display="inline" variant="h6">
        場次時間
      </Typography>
    </>
    <Typography variant="body1">
      {activityClass.activityClassDayList.map((activityClassDay) => {
        const date = format(
          activityClassDay.activityClassDayStartTimeString,
          'PPPP'
        );
        const startTime = format(
          activityClassDay.activityClassDayStartTimeString,
          'p'
        );
        const endTime = format(
          activityClassDay.activityClassDayEndTimeString,
          'p'
        );
        return (
          <React.Fragment key={activityClassDay.activityClassDayId}>
            {date} {startTime} ~ {endTime}
            <br />
          </React.Fragment>
        );
      })}
    </Typography>
  </ActivityGrid>
);

export default ActivityClassDayList;
