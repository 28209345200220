import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchPostUserInfo,
  fetchPostUserInfoRequest,
  fetchPostUserInfoSuccess,
  fetchPostUserInfoFailure
} = actionCreators.landingPages.emailFillSurvey;

/** Types */
export const FETCH_POST_USER_INFO = fetchPostUserInfo().type;
export const FETCH_POST_USER_INFO_REQUEST = fetchPostUserInfoRequest().type;
export const FETCH_POST_USER_INFO_SUCCESS = fetchPostUserInfoSuccess().type;
export const FETCH_POST_USER_INFO_FAILURE = fetchPostUserInfoFailure().type;

/** Selectors */
const getApiStates = state =>
  state.getIn(['apis', 'landingPages', 'emailFillSurvey'], Map());

const getFetchPostEmailTokenId = createSelector(getApiStates, apiStates =>
  apiStates.get('fetchPostUserInfo', Map())
);

export const getIsError = createSelector(getFetchPostEmailTokenId, fetchPost =>
  fetchPost.get('isError')
);
