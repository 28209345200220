import React, { FC } from 'react';

// TODO: Need fixed this.
// @ts-ignore
import { submit } from 'redux-form/immutable';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import Button from '@eGroupTeam/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@eGroupTeam/material';
import { useDispatch } from 'react-redux';
import ActivityPermissionsForm, { FORM } from './ActivityPermissionsForm';

export const DIALOG = 'activityPermissionsAddDialogProps';

export interface ActivityPermissionsAddDialogProps {
  onSubmit?: (values: any) => void;
  loading?: boolean;
}

const ActivityPermissionsAddDialog: FC<
  ActivityPermissionsAddDialogProps & WithReduxDialogProps
> = ({ onSubmit, loading, isOpen, handleClose }) => {
  const dispatch = useDispatch();
  return (
    <Dialog open={isOpen} fullWidth onClose={handleClose}>
      <DialogTitle>邀請活動行政人員</DialogTitle>
      <DialogContent>
        <ActivityPermissionsForm onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>關閉</Button>
        <Button
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(submit(FORM));
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<
  HTMLDivElement,
  ActivityPermissionsAddDialogProps
>(ActivityPermissionsAddDialog);
