import React, { FC } from 'react';

import { Controller, UseFormMethods } from 'react-hook-form';
import { Grid, makeStyles, TextField } from '@eGroupTeam/material';
import { PaymentContactFormInput } from 'types';

export interface PaymentContactFormProps extends UseFormMethods {
  onSubmit: (values: PaymentContactFormInput) => void;
}

const useStyles = makeStyles(() => ({
  textField: {
    "& .MuiInput-underline:before": {
      borderColor: "#DBDDE3",
    },
  },
}));

const PaymentContactForm: FC<PaymentContactFormProps> = ({
  control,
  handleSubmit,
  onSubmit,
  errors,
}) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Controller
            as={TextField}
            control={control}
            name="paymentOrderContactName"
            label="姓名"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            error={Boolean(errors?.paymentOrderContactName)}
            helperText={errors?.paymentOrderContactName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            as={TextField}
            control={control}
            name="paymentOrderContactPhone"
            label="手機"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            error={Boolean(errors?.paymentOrderContactPhone)}
            helperText={errors?.paymentOrderContactPhone?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            as={TextField}
            control={control}
            name="paymentOrderContactLocalPhone"
            label="市話(可不填)"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            error={Boolean(errors?.paymentOrderContactLocalPhone)}
            helperText={errors?.paymentOrderContactLocalPhone?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            as={TextField}
            control={control}
            name="paymentOrderContactEmail"
            label="聯絡 Email"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            error={Boolean(errors?.paymentOrderContactEmail)}
            helperText={errors?.paymentOrderContactEmail?.message}
          />
        </Grid>
      </Grid>
    </form>
  )
};

export default PaymentContactForm;
