import React, { FC } from 'react';

import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedOrg, getSelectedOrg } from 'redux/modules/common';
import {
  getOrganizations,
  getMemberModuleValues,
} from 'components/RouterRoot/selectors';

import ButtonMenu from '@eGroupTeam/material-module/ButtonMenu';
import { useHistory, useLocation } from 'react-router-dom';
import {
  makeStyles,
  AppBar,
  Button,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Hidden,
  Drawer,
} from '@eGroupTeam/material';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UserMenu from 'components/UserMenu';
import CreateOrgDialog, { DIALOG } from 'components/CreateOrgDialog';
import MicepointIcon from 'components/MicepointIcon';
import NestedSideMenu, { EgRouteConfig } from '@eGroupTeam/material-module/NestedSideMenu';
import Hamburger from 'components/Hamburger';
import useIsOpen from '@eGroupTeam/hooks/useIsOpen';
import SideMenuBody from 'components/SideMenuBody';
import { fetchPostOrganization, getIsPosting } from './redux';

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      left: theme.layout.sideMenuWidth,
      width: `calc(100vw - ${theme.layout.sideMenuWidth}px)`,
    },
  },
  orgButton: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    justifyContent: 'flex-start',
    marginRight: theme.spacing(),
  },
  grow: {
    flexGrow: 1,
  },
  addIcon: {
    marginRight: theme.spacing(),
  },
  newBtn: {
    marginRight: theme.spacing(),
  },
  micepoint: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(),
  },
  micepointItem: {
    margin: 2,
  },
}));

export interface NavbarProps {
  routes: EgRouteConfig[];
}

const Navbar: FC<NavbarProps> = ({
  routes
}) => {
  const location = useLocation();
  const { isOpen, handleOpen, handleClose } = useIsOpen();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const organizations = useSelector(getOrganizations);
  const selectedOrg = useSelector(getSelectedOrg);
  const isPosting = useSelector(getIsPosting);
  const memberModuleValues = useSelector(getMemberModuleValues);

  const handleClick = (el) => () => {
    dispatch(setSelectedOrg(el));
    window.localStorage.setItem('selectedOrgId', el.get('organizationId'));
  };

  const handleAddClick = () => {
    dispatch(openDialog(DIALOG));
  };

  const handleSubmit = (values) => {
    dispatch(fetchPostOrganization(values.toJS()));
  };

  return (
    <>
      <CreateOrgDialog onSubmit={handleSubmit} loading={isPosting} />
      <Drawer
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          component: SideMenuBody
        }}
      >
        <NestedSideMenu
          routes={routes}
          pathname={location.pathname}
          NestedListItemProps={{
            MuiListItemProps: {
              onClick: handleClose,
            },
          }}
          NestedListItemItemsProps={{
            MuiListItemProps: {
              onClick: handleClose,
            },
          }}
        />
      </Drawer>
      <AppBar elevation={0} className={classes.appbar} color="default">
        <Toolbar>
          <Hidden mdUp>
            <Hamburger
              onClick={handleOpen}
              aria-label="Menu"
              edge="start"
            />
          </Hidden>
          <div className={classes.grow} />
          <div className={classes.micepoint}>
            <Tooltip title="MICEPoint">
              <MicepointIcon
                color="primary"
                className={classes.micepointItem}
              />
            </Tooltip>
            <div className={classes.micepointItem}>
              <Typography>
                <strong>{selectedOrg.get('micepointTotalAmount')}</strong>
              </Typography>
            </div>
          </div>
          <ButtonMenu
            id="org-menu"
            button={
              <Button
                muiButtonClassName={classes.orgButton}
                color="white"
                rounded
                endIcon={<ArrowDropDownIcon />}
                variant="contained"
                disableElevation
              >
                主辦單位: {selectedOrg.get('organizationName')}
              </Button>
            }
          >
            {organizations.map((el) => (
              <MenuItem
                key={el.get('organizationId')}
                value={el.get('organizationId')}
                onClick={handleClick(el)}
              >
                {el.get('organizationName')}
              </MenuItem>
            ))}
            <MenuItem onClick={handleAddClick}>
              <AddCircleIcon
                fontSize="small"
                color="action"
                className={classes.addIcon}
              />
              新增新單位
            </MenuItem>
          </ButtonMenu>
          {memberModuleValues && memberModuleValues.includes('activityEdit') && (
            <Hidden smDown>
              <Button
                variant="contained"
                color="success"
                rounded
                className={classes.newBtn}
                onClick={() => {
                  history.push('/me/new-activity');
                }}
              >
                舉辦活動
              </Button>
            </Hidden>
          )}
          <UserMenu />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
