import React, { FC } from 'react';

import { fromJS } from 'immutable';
import { useSelector } from 'react-redux';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { getSelectedOrg } from 'redux/modules/common';
import { UserApply } from 'types';
import { IsTrainSuccess } from 'enums'
import {
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
} from '@eGroupTeam/material';
import ActivityPeopleEditForm from './ActivityPeopleEditForm';

export const DIALOG = 'activityUserApplyEditDialog';

export interface ActivityPeopleEditDialogProps {
  userApply?: UserApply;
  onSubmit?: (values: any) => void;
  onRetrainClick?: () => void;
  loading?: boolean
}

const ActivityPeopleEditDialog: FC<
  ActivityPeopleEditDialogProps & WithReduxDialogProps
> = ({ isOpen, handleClose, userApply, onSubmit, onRetrainClick, loading }) => {
  const selectedOrg = useSelector(getSelectedOrg);
  return (
    <>
      <Dialog fullWidth open={loading ? true: isOpen} onClose={handleClose}>
        <DialogTitle>報名會員資料</DialogTitle>
        <DialogContent>
          {userApply && (
            <>
              <ActivityPeopleEditForm
                onSubmit={onSubmit}
                initialValues={fromJS(userApply.user)}
                organizationId={selectedOrg.get('organizationId')}
              />
              <Typography variant="body1" gutterBottom>
                <strong>人臉訓練狀態：</strong>
                {userApply.isAgreeFaceRecognize
                  ? IsTrainSuccess[userApply.isTrainSuccess ?? 0]
                  : '未使用人臉報名'}
              </Typography>
              {userApply.isAgreeFaceRecognize !== 0 && (
                <Link
                  component="button"
                  onClick={() => {
                    if (onRetrainClick) {
                      onRetrainClick();
                    }
                  }}
                >
                  重新發送人臉訓練 Email
                </Link>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} loading={loading}>關閉</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withReduxDialog(DIALOG)<
  HTMLDivElement,
  ActivityPeopleEditDialogProps
>(ActivityPeopleEditDialog);
