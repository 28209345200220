import React, { FC, useState } from 'react';

import { Map } from 'immutable';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  withReduxDialog,
  openDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import makeFormValueIsEqual from 'utils/makeFormValueIsEqual';

import { makeStyles } from '@eGroupTeam/material';
// @ts-ignore
import { submit, reset, getFormValues, change } from 'redux-form/immutable';

import { getSelectedOrg } from 'redux/modules/common';

import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import Button from '@eGroupTeam/material/Button';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import TextField from '@eGroupTeam/material/TextField';
import AppBar from '@eGroupTeam/material/AppBar';
import Toolbar from '@eGroupTeam/material/Toolbar';
import Dialog from '@eGroupTeam/material/Dialog';
import DialogContent from '@eGroupTeam/material/DialogContent';
import DialogActions from '@eGroupTeam/material/DialogActions';
import IconButton from '@eGroupTeam/material/IconButton';
import Typography from '@eGroupTeam/material/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { UrlParams } from 'types';
import {
  fetchPostNotificationEmail,
  fetchGetActivityEdmUsers,
  fetchPostTestNotificationEmail,
  fetchPostPreviewEdm,
  fetchPostOrganizationEdmTemplate,
  getEmailIsSending,
  getTestEmailIsSending,
  getIsPosting,
  getIsPreviewPosting,
  handleNext,
  handlePrev,
  getActiveStep,
  getActiveStepMax,
} from './redux';
import ActivityNotificationForm, { FORM } from './ActivityNotificationForm';

export const DIALOG = 'activityNotificationDialog';

const CONFIRM_LEAVE_DIALOG = 'confirmLeaveCaseDataTalkEditDialog';
const CONFIRM_TEMPLATE_SAVE_DIALOG = 'confirmTemplateSaveDialog';

const ConfirmLeaveDialog = withReduxDialog(CONFIRM_LEAVE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);
const ConfirmTemplateSaveDialog = withReduxDialog(CONFIRM_TEMPLATE_SAVE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);

const useFormValueIsEqual = makeFormValueIsEqual(FORM);

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export interface ActivityNotificationDialogProps {
  isSent?: boolean
  initialValues?: any
}

const ActivityNotificationDialog: FC<ActivityNotificationDialogProps & WithReduxDialogProps> = ({
  isOpen,
  handleClose,
  isSent,
  initialValues,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activityId } = useParams<UrlParams>();
  const isEqual = useFormValueIsEqual();
  const activeStep = useSelector(getActiveStep);
  const activeStepMax = useSelector(getActiveStepMax);
  const isSending = useSelector(getEmailIsSending);
  const isTestEmailSending = useSelector(getTestEmailIsSending);
  const isPreviewPosting = useSelector(getIsPreviewPosting);
  const isSaving = useSelector(getIsPosting);
  const values: any = useSelector(getFormValues(FORM));
  const [
    organizationEdmTemplateName,
    setOrganizationEdmTemplateName,
  ] = useState('');
  const selectedOrg = useSelector(getSelectedOrg);
  const defaultContactContent = `<p style="text-align:center;">單位名稱：${selectedOrg.get(
    'organizationName'
  )}</p><p style="text-align:center;">窗口：${selectedOrg.get(
    'organizationContactPerson'
  )}</p><p style="text-align:center;">電話：${selectedOrg.get(
    'organizationContactPhone'
  )}</p><p style="text-align:center;">Email：${selectedOrg.get(
    'organizationContactEmail'
  )}</p>`;

  const closeConform = () => {
    if (!isEqual) {
      dispatch(openDialog(CONFIRM_LEAVE_DIALOG));
    } else {
      handleClose();
    }
  };

  const handleSave = () => {
    dispatch(openDialog(CONFIRM_TEMPLATE_SAVE_DIALOG));
  };

  const handleSaveConfirm = () => {
    const payload = values
      .delete('activityClass')
      .set('organizationEdmTemplateName', organizationEdmTemplateName);
    dispatch(
      fetchPostOrganizationEdmTemplate({
        organizationId: selectedOrg.get('organizationId'),
        ...payload.toJS(),
      })
    );
  };

  const handleUserNext = () => {
    if (values.get('organizationEdmTemplateType') && activeStep === 1) {
      dispatch(
        fetchGetActivityEdmUsers({
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          activityClassId: values.get('activityClass'),
          activityEdmType: values.get('organizationEdmTemplateType'),
        })
      );
    }
    dispatch(handleNext());
  };

  const handleSubmit = (values) => {
    if (!values.has('organizationEdmTemplateContactContent')) {
      values.set(
        'organizationEdmTemplateContactContent',
        defaultContactContent
      );
    }
    const organizationEdmTemplate = Map({
      organizationEdmTemplateType: values.get('organizationEdmTemplateType'),
      organizationEdmTemplateTitle: values.get('organizationEdmTemplateTitle'),
      organizationEdmTemplateContent: values.get(
        'organizationEdmTemplateContent'
      ),
      organizationEdmTemplateContactContent: values.get(
        'organizationEdmTemplateContactContent'
      ),
    });
    const userList = values.get('userList').map((el) => ({ userId: el }));
    const activityEdm = initialValues.get('activityEdm');
    if (isSent) {
      dispatch(
        fetchPostNotificationEmail({
          organizationId: selectedOrg.get('organizationId'),
          userList,
          organizationEdmTemplate,
          activityId,
          activityClassId: values.get('activityClass'),
          notificationSendEmailDateString: values.get(
            'notificationSendEmailDateString'
          ),
          activityEdm,
        })
      );
    } else {
      dispatch(
        fetchPostNotificationEmail({
          organizationId: selectedOrg.get('organizationId'),
          userList,
          organizationEdmTemplate,
          activityId,
          activityClassId: values.get('activityClass'),
          notificationSendEmailDateString: values.get(
            'notificationSendEmailDateString'
          ),
        })
      );
    }
  };

  const handleSendTestEmail = () => {
    const organizationEdmTemplate = values.deleteAll([
      'activityClass',
      'activityEdm',
      'organizationEdmTemplateName',
    ]);
    if (values.get('activityClass') && values.get('activityClass') !== null) {
      dispatch(
        fetchPostTestNotificationEmail({
          organizationEdmTemplate,
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          activityClassId: values.get('activityClass'),
        })
      );
    } else {
      dispatch(
        fetchPostTestNotificationEmail({
          organizationEdmTemplate,
          organizationId: selectedOrg.get('organizationId'),
          activityId,
        })
      );
    }
  };

  const handlePreview = () => {
    const payload = values
      .set('organizationId', selectedOrg.get('organizationId'))
      .deleteAll(['activityEdm', 'activityClass']);
    dispatch(fetchPostPreviewEdm(payload.toJS()));
  };

  const renderButton = () => {
    if (activeStep === activeStepMax - 1) {
      return (
        <Button
          loading={isSending}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => dispatch(submit(FORM))}
        >
          送出
        </Button>
      );
    }
    if (activeStep === 0) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(reset(FORM));
            dispatch(
              change(
                FORM,
                'organizationEdmTemplateContactContent',
                defaultContactContent
              )
            );
            dispatch(handleNext());
          }}
          className={classes.button}
        >
          自行創建
        </Button>
      );
    }
    if (activeStep !== 0 && activeStep < activeStepMax) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleUserNext}
          className={classes.button}
        >
          下一步
        </Button>
      );
    }
    return undefined;
  };

  return (
    <>
      <ConfirmLeaveDialog
        primary="資料變更"
        message="你有未儲存的變更，確定要離開嗎？"
        onConfirm={handleClose}
      />
      <ConfirmTemplateSaveDialog
        primary="儲存範本"
        onConfirm={handleSaveConfirm}
        maxWidth="xs"
        fullWidth
      >
        <TextField
          label="範本名稱"
          value={organizationEdmTemplateName}
          onChange={(e) => setOrganizationEdmTemplateName(e.target.value)}
          fullWidth
        />
      </ConfirmTemplateSaveDialog>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={closeConform}
        disableEnforceFocus
      >
        <AppBar elevation={1} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeConform}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">寄送活動通知</Typography>
          </Toolbar>
        </AppBar>
        <NavbarBrick style={{ marginTop: 16 }} />
        <DialogContent>
          <ActivityNotificationForm
            activityId={activityId}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            // @ts-ignore
            isSent={isSent}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={activeStep === 0 || (isSent && activeStep === 1)}
            variant="outlined"
            onClick={() => {
              dispatch(handlePrev());
            }}
            className={classes.button}
          >
            上一步
          </Button>
          {activeStep === 1 && (
            <>
              <Button
                loading={isTestEmailSending}
                variant="outlined"
                color="primary"
                onClick={handleSendTestEmail}
                className={classes.button}
              >
                測試寄信
              </Button>
              <Button
                loading={isPreviewPosting}
                variant="outlined"
                color="primary"
                onClick={handlePreview}
                className={classes.button}
              >
                預覽
              </Button>
              {!isSent && (
                <Button
                  loading={isSaving}
                  variant="outlined"
                  color="primary"
                  onClick={handleSave}
                  className={classes.button}
                >
                  儲存範本
                </Button>
              )}
            </>
          )}
          {renderButton()}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withReduxDialog(DIALOG)<unknown, ActivityNotificationDialogProps>(ActivityNotificationDialog);
