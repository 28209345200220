import React, { useState } from 'react';

import { SNACKBAR } from 'App';
import apis from 'redux/apisOrg';
import { useDispatch, useSelector } from 'react-redux';
import {
  withReduxDialog,
  openDialog,
  closeDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  openSnackbar,
  setSnackbarData,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import useOrgOwner from 'utils/useOrgOwner';

import { getSelectedOrg } from 'redux/modules/common';

import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import {
  Box,
  IconButton,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@eGroupTeam/material';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import useAxiosApiWrapper from 'utils/useAxiosApiWrapper';
import useOrgMembers from 'utils/useOrgMembers';
import { OrganizationMember } from 'types';

import OrgMemberEditRoleDialog, {
  DIALOG as EDIT_MEMBER_ROLE_DIALOG,
} from './OrgMemberEditRoleDialog';
import OrgMemberInvitationAddDialog from './OrgMemberInvitationAddDialog';

const DELETE_MEMBER_DIALOG = 'ConfirmDeleteOrganizationMemberDialog';
const ConfirmDeleteDialog = withReduxDialog(DELETE_MEMBER_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);

const OrgMemberDataList = () => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);
  const [selectedMember, setSelectedMember] = useState<OrganizationMember>();
  const { isOrgOwner, loginId, orgOwnerLoginId } = useOrgOwner();
  const {
    handleChangePage,
    handleRowsPerPageChange,
    handleSearchChange,
    payload,
    page,
    rowsPerPage,
  } = useDataTable('orgMembers', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });

  const { data, isValidating, response, mutate } = useOrgMembers(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    payload
  );
  const {
    excute: updateMemberRole,
    isLoading: isUpdateing,
  } = useAxiosApiWrapper(apis.fetchPostOrganizationMemberRoles);
  const { excute: deleteMember } = useAxiosApiWrapper(
    apis.fetchDeleteOrganizationMembers
  );
  const {
    excute: createInvitation,
    isLoading: isCreating,
  } = useAxiosApiWrapper(apis.fetchPostOrganizationMemberInvitation);

  const handleDeleteMember = (orgMember: OrganizationMember) => (e) => {
    e.stopPropagation();
    setSelectedMember(orgMember);
    dispatch(openDialog(DELETE_MEMBER_DIALOG));
  };

  const handleEditMemberRoles = (orgMember: OrganizationMember) => () => {
    dispatch(openDialog(EDIT_MEMBER_ROLE_DIALOG));
    setSelectedMember(orgMember);
  };

  const handleEditMemberRolesSubmit = (values?: string[]) => {
    if (values) {
      updateMemberRole({
        organizationId: selectedOrg.get('organizationId'),
        loginId: selectedMember?.member.loginId,
        organizationMemberRoleSet: values,
      }).then(() => {
        mutate();
        dispatch(closeDialog(EDIT_MEMBER_ROLE_DIALOG));
      });
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedMember) {
      deleteMember({
        organizationId: selectedOrg.get('organizationId'),
        loginId: selectedMember.member.loginId,
      }).then(() => {
        mutate();
      });
    }
  };

  const handleAddSubmit = (values) => {
    createInvitation({
      organizationId: selectedOrg.get('organizationId'),
      organizationInvitationEmail: values.get('organizationInvitationEmail'),
    })
      .then(() => {
        dispatch(
          setSnackbarData({
            name: SNACKBAR,
            message: '成功寄出邀請信件！',
            variant: 'success',
          })
        );
        dispatch(openSnackbar(SNACKBAR));
      })
      .catch(() => {
        dispatch(
          setSnackbarData({
            name: SNACKBAR,
            message: '此人已邀請',
            variant: 'error',
          })
        );
        dispatch(openSnackbar(SNACKBAR));
      });
  };

  const renderColumns = (rowData) => (
    <TableRow>
      {rowData.map((el) => (
        <TableCell key={el}>
          <Typography variant="body2">{el}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );

  const renderDataRow = (rowData) => {
    const orgMember = rowData as OrganizationMember;
    return (
      <TableRow key={orgMember.member.memberAccount}>
        <TableCell>
          {orgMember.member.memberName}
        </TableCell>
        <TableCell>
          {orgMember.member.memberAccount}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {orgMember.organizationRoleList
              ? orgMember.organizationRoleList
                  .map((el) => el.organizationRoleNameZh)
                  .join(', ')
              : '無角色'}
            {orgOwnerLoginId !== orgMember.member.loginId && (
              <Tooltip title="編輯角色">
                <IconButton
                  size="small"
                  onClick={handleEditMemberRoles(orgMember)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {isOrgOwner && loginId !== orgMember.member.loginId && (
            <Tooltip
              title="刪除單位成員"
              onClick={handleDeleteMember(orgMember)}
            >
              <IconButton size="small">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <ConfirmDeleteDialog
        primary="刪除單位成員"
        message="確定要刪除此成員嗎？"
        onConfirm={handleDeleteConfirm}
      />
      <OrgMemberEditRoleDialog
        loginId={selectedMember?.member.loginId}
        onSubmit={handleEditMemberRolesSubmit}
        loading={isUpdateing}
      />
      <OrgMemberInvitationAddDialog
        onSubmit={handleAddSubmit}
        loading={isCreating}
      />
      <Paper square>
        <DataTable
          data={data ? data.source : []}
          columns={['名稱', '帳號', '角色', '操作']}
          serverSide
          SearchBarProps={{
            placeholder: '搜尋單位成員',
            onChange: handleSearchChange,
          }}
          loading={isValidating}
          isEmpty={response?.status === 204}
          renderColumns={renderColumns}
          renderDataRow={renderDataRow}
          MuiTablePaginationProps={{
            count: data ? data.total : 0,
            labelRowsPerPage: '每頁幾筆',
            page,
            rowsPerPage,
            onPageChange: handleChangePage,
            onRowsPerPageChange: handleRowsPerPageChange,
          }}
        />
      </Paper>
    </>
  );
};

export default OrgMemberDataList;
