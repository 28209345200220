import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';

import { closeDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { setSelectedOrg } from 'redux/modules/common';
import { fetchGetOrganizations } from 'components/RouterRoot/actions';
import { DIALOG as CREATE_ORG_DIALOG } from 'components/CreateOrgDialog';
import {
  fetchPostOrganizationRequest,
  fetchPostOrganizationSuccess,
  fetchPostOrganizationFailure,
  FETCH_POST_ORGANIZATION,
} from './redux';

export const fetchPostOrganizationEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ORGANIZATION,
  apiName: 'member.fetchPostOrganization',
  fetchRequest: fetchPostOrganizationRequest,
  handleSuccess: (response) => [
    fetchGetOrganizations(),
    fetchPostOrganizationSuccess(),
    setSelectedOrg(fromJS(response.data)),
    closeDialog(CREATE_ORG_DIALOG),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostOrganizationFailure(error))
    ),
});

export default combineEpics(fetchPostOrganizationEpic);
