import React, { forwardRef } from 'react'

import {
  makeStyles,
  Paper,
  PaperProps
} from '@eGroupTeam/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
({
  root: {
    backgroundColor: theme.egPalette.info[1],
    paddingTop: theme.spacing(2),
    overflowX: 'hidden',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    scrollbarWidth: 'thin',
    scrollbarColor: `blue ${theme.egPalette.info[1]}`,

    [theme.breakpoints.up('md')]: {
      borderTopLeftRadius: 51,
      borderBottomLeftRadius: 51,
    },

    '& *': {
      color: theme.palette.common.white,
    },
    '& .MuiListItem-root.Mui-selected.active': {
      backgroundColor: theme.egPalette.primary[1],
    },
    '& .MuiListItem-root.Mui-selected.active:hover': {
      backgroundColor: theme.egPalette.primary[1],
    },
    '& .MuiCollapse-wrapper .MuiList-root': {
      borderBottom: `2px solid ${theme.palette.common.white}`,
      paddingTop: '0',
      paddingBottom: '0',
    },
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.egPalette.info[1],
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.egPalette.info[1],
      borderRadius: 7,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 42,
    },
  },
}));

const SideMenuBody = forwardRef<unknown, PaperProps>((props, ref) => {
  const { className, ...other} = props
  const classes = useStyles()
  return (
    <Paper ref={ref} className={clsx(className, classes.root)} {...other} />
  )
})

export default SideMenuBody
