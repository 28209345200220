import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import {
  FETCH_GET_SURVEY_QUESTIONS_SUCCESS,
  FETCH_POST_SURVEY_QUESTION_SUCCESS,
  FETCH_DELETE_SURVEY_QUESTION_SUCCESS,
  FETCH_PATCH_SURVEY_QUESTIONS_ORDER_SUCCESS,
} from './redux';

const reducer = handleActions(
  {
    [FETCH_GET_SURVEY_QUESTIONS_SUCCESS]: (state, action) =>
      state.set('questions', action.payload),
    [FETCH_PATCH_SURVEY_QUESTIONS_ORDER_SUCCESS]: (state, action) =>
      state.set('questions', action.payload),
    [FETCH_POST_SURVEY_QUESTION_SUCCESS]: (state, action) =>
      state.update('questions', (questions) => {
        if (questions) {
          return questions.push(action.payload);
        }
        return fromJS([action.payload]);
      }),
    [FETCH_DELETE_SURVEY_QUESTION_SUCCESS]: (state, action) =>
      state.update('questions', (questions) =>
        questions.filter((qId) => qId !== action.payload)
      ),
  },
  fromJS({})
);

export default reducer;
