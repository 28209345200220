import { useEffect } from 'react';
import useIsSupportMediaDevices from '@eGroupTeam/hooks/useIsSupportMediaDevices';
import { useDispatch } from 'react-redux';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { ALERT_OS_NOT_SUPPORT_DIALOG } from 'App';

/**
 * To alert user OS is not supprot.
 */
export default function useAlertOSNotSupport() {
  const dispatch = useDispatch();
  const [isSupportMediaDevices] = useIsSupportMediaDevices();

  useEffect(() => {
    if (!isSupportMediaDevices) {
      dispatch(openDialog(ALERT_OS_NOT_SUPPORT_DIALOG));
    }
  }, [dispatch, isSupportMediaDevices]);

  return !isSupportMediaDevices;
}
