import React, { useState } from "react";
import apis from "redux/apisOrg";
import {
  setSnackbarData,
  openSnackbar,
} from "@eGroupTeam/redux-modules/immutable/snackbars";
import { Button, CircularProgress, Typography } from "@eGroupTeam/material";
import FixedCenter from "@eGroupTeam/material-layout/FixedCenter";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { UrlParams } from "types";
import useActivity from "utils/useActivity";
import Center from "@eGroupTeam/material-layout/Center";
import useLocationSearch from "utils/useLocationSearch";
import useAxiosApiWrapper from "utils/useAxiosApiWrapper";
import { useDispatch } from "react-redux";
import { SNACKBAR } from "App";

enum ErrorStatus {
  "NOT_START" = "活動還沒開始",
  "ENDED" = "活動已結束",
}

const ActivityUserApplyOnlineCheckIn = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const { organizationId, activityClassId } = useLocationSearch();
  const { activityId, userApplyId } = useParams<UrlParams>();
  const { data: activity } = useActivity({
    activityId,
  });
  const { excute: verifyUserApplyOnline, isLoading } = useAxiosApiWrapper(
    apis.fetchPostActivityUserApplyOnline
  );

  const handleClick = () => {
    if (!activity) return;
    setSuccess(false);
    verifyUserApplyOnline({
      organizationId,
      activityId,
      activityClassId,
      userApplyId,
    })
      .then((res) => {
        if (typeof res.data === "string") {
          throw new Error(res.data);
        }
        setSuccess(true);
        setTimeout(() => {
          const activityClass = activity.activityClassList.find(
            (el) => el.activityClassId === activityClassId
          );
          if (activityClass?.activityClassMeetingUrl) {
            let url = activityClass.activityClassMeetingUrl;
            if (!url.startsWith("http://") && !url.startsWith("https://")) {
              url = "https://" + url;
            }
            window.location.href = url;
          }
        }, 2000);
      })
      .catch((error: Error) => {
        dispatch(
          setSnackbarData({
            name: SNACKBAR,
            message: `簽到失敗，${ErrorStatus[error.message]}`,
            variant: "error",
          })
        );
        dispatch(openSnackbar(SNACKBAR));
      });
  };

  if (!activity) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <Helmet>
        <title>[線上簽到] {activity.activityName}</title>
      </Helmet>
      <Center>
        <div>
          <Typography variant="h5" gutterBottom>
            您好，以下為「{activity.activityName}」的線上簽到連結，
            <br />
            點擊簽到後將自動跳轉至線上會議。
          </Typography>
          <Button
            loading={isLoading}
            fullWidth
            size="large"
            variant="contained"
            color={success ? "success" : "info"}
            onClick={handleClick}
          >
            {success ? "簽到成功，即將跳轉頁面。" : "線上簽到"}
          </Button>
        </div>
      </Center>
    </>
  );
};

export default ActivityUserApplyOnlineCheckIn;
