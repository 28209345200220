import React from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import { Redirect } from 'react-router-dom';
import { UrlParams } from 'types';
import { fetchGetSurvey, getSurvey } from './redux';

const SurveyRoot = ({ route }) => {
  const { surveyId } = useParams<UrlParams>();
  const dispatch = useDispatch();
  const survey = useSelector(getSurvey);

  React.useEffect(() => {
    dispatch(
      fetchGetSurvey({
        surveyId,
      })
    );
  }, [dispatch, surveyId]);

  if (survey && survey.isEmpty()) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      <NavbarBrick />
      {renderRoutes(route.routes, { survey })}
    </>
  );
};

SurveyRoot.propTypes = {
  // react-router props
  route: PropTypes.object.isRequired,
};

export default SurveyRoot;
