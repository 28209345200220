import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@eGroupTeam/material';

import Input from '@eGroupTeam/material/Input';
import InputLabel from '@eGroupTeam/material/InputLabel';
import InputAdornment from '@eGroupTeam/material/InputAdornment';
import Toolbar from '@eGroupTeam/material/Toolbar';
import Typography from '@eGroupTeam/material/Typography';
import FormControl from '@eGroupTeam/material/FormControl';
import IconButton from '@eGroupTeam/material/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const styles = () => ({
  title: {
    flex: '1 1 100%',
  },
});

const useStyles = makeStyles(styles);

const EnhancedTableToolbar = ({
  numSelected,
  userName,
  handleInputChange,
  handleInputKeyPress,
  handleUserSearch,
}) => {
  const classes = useStyles();

  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="h6"
          component="div"
        >
          已選擇{numSelected}位會員
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          會員名單(有Email的名單)
        </Typography>
      )}
      <FormControl>
        <InputLabel>搜尋會員</InputLabel>
        <Input
          value={userName}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleUserSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  userName: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleInputKeyPress: PropTypes.func,
  handleUserSearch: PropTypes.func,
};

export default EnhancedTableToolbar;
