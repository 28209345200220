import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';

/** Actions */
export const {
  setSelectedActivityClassName,
  setSelectedActivityClassId,
  handleTutorialDrawerOpen,
  handleTutorialDrawerClose,
  reset,
} = actionCreators.mainPages.activity;

/** Types */
export const SET_SELECTED_ACTIVITY_CLASS_NAME = setSelectedActivityClassName()
  .type;
export const SET_SELECTED_ACTIVITY_CLASS_ID = setSelectedActivityClassId().type;

export const HANDLE_TUTORIAL_DRAWER_OPEN = handleTutorialDrawerOpen().type;
export const HANDLE_TUTORIAL_DRAWER_CLOSE = handleTutorialDrawerClose().type;
export const RESET = reset().type;
/** Selectors */
/**
 * Ui states
 */
const getUiStates = (state) => state.getIn(['mainPages', 'Activity']);

export const getSelectedActivityClassName = createSelector(
  getUiStates,
  (uiStates) => uiStates.get('selectedActivityClassName')
);

export const getSelectedActivityClassId = createSelector(
  getUiStates,
  (uiStates) => uiStates.get('selectedActivityClassId')
);

export const getTutorialDrawerIsOpen = createSelector(getUiStates, (uiStates) =>
  uiStates.get('tutorialDrawerIsOpen')
);
