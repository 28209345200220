import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { SET_ACTIVE_STEP, HANDLE_NEXT, HANDLE_PREV } from './redux';

const reducer = handleActions(
  {
    [SET_ACTIVE_STEP]: (state, action) =>
      state.set('activeStep', action.payload),
    [HANDLE_NEXT]: (state) =>
      state.update('activeStep', (el) => {
        const nextStep = el + 1;
        if (nextStep <= state.get('activeStepMax')) {
          return nextStep;
        }
        return el;
      }),
    [HANDLE_PREV]: (state) =>
      state.update('activeStep', (el) => {
        const prevStep = el - 1;
        if (prevStep >= 0) {
          return prevStep;
        }
        return el;
      }),
  },
  fromJS({
    activeStep: 0,
    activeStepMax: 3,
  })
);

export default reducer;
