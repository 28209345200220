import React from 'react';

import { reduxForm, Form, Field } from 'redux-form/immutable';
import makeEditableFieldUtils from '@eGroupTeam/hooks/immutable/makeEditableFieldUtils';

import FormFieldGroup from '@eGroupTeam/material/FormFieldGroup';
import EditableField from '@eGroupTeam/material/EditableField';
import EditableFieldShowing from '@eGroupTeam/material/EditableFieldShowing';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import Typography from '@eGroupTeam/material/Typography';
import apis from 'redux/apis';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

export const FORM = 'activityUserApplyEditForm';

const useEditableFieldUtils = makeEditableFieldUtils(FORM);

const validate = (values) => {
  const errors: Record<string, string> = {};
  if (!values.get('userName')) {
    errors.userName = '「姓名」是必填欄位';
  }
  if (values.get('userEmail') && !isEmail(values.get('userEmail'))) {
    errors.userEmail = '「Email」格式錯誤';
  }
  return errors;
};

const asyncValidate = (values, dispatch, props) => {
  if (!isMobilePhone(values.get('userPhone'), 'zh-TW')) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('手機號碼格式錯誤');
    }).catch(() => {
      const error = {
        userPhone: '手機號碼格式錯誤',
      };
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error;
    });
  }
  return apis
    .fetchPostCheckUserApplyDuplicate({
      organizationId: props.organizationId,
      userPhone: values.get('userPhone'),
      userId: values.get('userId'),
    })
    .catch(() => {
      const error = {
        userPhone: '手機號碼已報名過',
      };
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error;
    });
};

const ActivityPeopleEditForm = ({ handleSubmit }) => {
  const { formValues, handleSave, handleClose } = useEditableFieldUtils();

  return (
    <Form onSubmit={handleSubmit}>
      <FormFieldGroup required label="姓名">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('userName')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="userName"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup label="單位">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('userOrganizationName')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="userOrganizationName"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup label="職稱">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('userJobTitle')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="userJobTitle"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup label="Email">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('userEmail')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="userEmail"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
      <FormFieldGroup label="電話">
        <EditableField
          onSaveClick={handleSave}
          onCloseClick={handleClose}
          disableClickAwayCloseEditing
          style={{ marginLeft: -8 }}
        >
          <EditableFieldShowing>
            <Typography variant="body1">
              {formValues && formValues.get('userPhone')}
            </Typography>
          </EditableFieldShowing>
          <Field
            name="userPhone"
            margin="normal"
            component={TextLoadingField}
            fullWidth
          />
        </EditableField>
      </FormFieldGroup>
    </Form>
  );
};

export default reduxForm({
  form: FORM,
  validate,
  asyncValidate,
  asyncChangeFields: ['userPhone'],
  shouldAsyncValidate: ({
    syncValidationPasses,
    trigger,
    pristine,
    initialized,
    blurredField,
  }) => {
    if (!syncValidationPasses || blurredField !== 'userPhone') {
      return false;
    }
    switch (trigger) {
      // @ts-ignore
      case 'change':
      case 'blur':
        return true;
      case 'submit':
        return !pristine || !initialized;
      default:
        return false;
    }
  },
})(ActivityPeopleEditForm);
