import React from 'react';

import s3Url from 'utils/s3Url';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';

import Main from '@eGroupTeam/material-layout/Main';
import Container from '@eGroupTeam/material/Container';
import Typography from '@eGroupTeam/material/Typography';
import Button from '@eGroupTeam/material/Button';
import CircularProgress from '@eGroupTeam/material/CircularProgress';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import IntroduceCard from 'components/IntroduceCard';
import IntroduceCardHeader from 'components/IntroduceCardHeader';
import IntroduceCardContent from 'components/IntroduceCardContent';
import IntroduceCardActions from 'components/IntroduceCardActions';
import { fetchPostUsersByExcel, getUsersIsPosting } from './redux';
import UsersImportResultDialog from './UsersImportResultDialog';

const UsersImport = (props) => {
  const dispatch = useDispatch();
  const isPosting = useSelector(getUsersIsPosting);
  const selectedOrg = useSelector(getSelectedOrg);

  const handleChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    dispatch(
      fetchPostUsersByExcel({
        organizationId: selectedOrg.get('organizationId'),
        formData,
      })
    );
  };

  if (isPosting) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <UsersImportResultDialog />
      <Main>
        <Container maxWidth="sm">
          <IntroduceCard>
            <IntroduceCardHeader title="匯入會員資料" />
            <IntroduceCardContent>
              <Typography variant="body1">
                MICEPass 讓您更了解您的客戶，管理好簡單、客戶很滿意。
                <br />
                必填欄位 : 姓名、信箱、電話
              </Typography>
            </IntroduceCardContent>
            <IntroduceCardActions>
              <Button
                component="label"
                htmlFor="usersExcelFile"
                variant="contained"
                disableElevation
                color="primary"
                size="small"
              >
                匯入會員
              </Button>
              <input
                onChange={handleChange}
                style={{ display: 'none' }}
                type="file"
                accept=".xlsx, .xls, .csv"
                id="usersExcelFile"
              />
              <Button
                component="a"
                download
                href={s3Url(
                  'resources/file/excel+template/importUserTemplate.xlsx'
                )}
                color="primary"
                size="small"
              >
                下載範本
              </Button>
            </IntroduceCardActions>
          </IntroduceCard>
        </Container>
      </Main>
    </>
  );
};

export default UsersImport;
