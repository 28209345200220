import { createReducer } from '@reduxjs/toolkit';
import { ActivityType } from 'enums';
import {
  reset,
  setActiveStep,
  handleNextStep,
  handlePrevStep,
  setFormValues,
} from './actions';

const localInitialState = window.localStorage.getItem('newActivityState');

const initialState = {
  activeStep: 0,
  formValues: {
    activityType: ActivityType.event,
    activityName: '',
    activityDescription: '',
    activityLogoPath: undefined,
    activityCoverPath: 'resources/image/activity/cover/default/1.jpg',
    isAllowSelectMultipleActivityClass: false,
    activityClassList: [
      {
        activityClassName: '',
        activityClassStartDateString: null,
        activityClassEndDateString: null,
        activityClassLimitCount: 0,
        activityClassPlace: '',
        activityClassMail: {
          city: '',
          area: '',
        },
        activityClassAddress: '',
        activityClassDescription: '',
        activityClassDayList: [
          {
            activityClassDate: null,
            activityClassDayStartTimeString: null,
            activityClassDayEndTimeString: null,
          },
        ],
      },
    ],
    paymentOrder: {
      paymentProductList: [],
    },
  },
};

const reducer = createReducer(
  localInitialState ? JSON.parse(localInitialState) : initialState,
  {
    [reset.type]: () => initialState,
    [handleNextStep.type]: (state) => ({
      ...state,
      activeStep: state.activeStep + 1,
    }),
    [handlePrevStep.type]: (state) => ({
      ...state,
      activeStep: state.activeStep - 1,
    }),
    [setActiveStep.type]: (state, action) => ({
      ...state,
      activeStep: action.payload,
    }),
    [setFormValues.type]: (state, action) => ({
      ...state,
      formValues: action.payload,
    }),
  }
);

export default reducer;
