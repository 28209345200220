import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  fetchGetUserApplyDailyCountsRequest,
  fetchGetUserApplyDailyCountsSuccess,
  fetchGetUserApplyDailyCountsFailure,
  fetchGetActivityApplyFieldResultRequest,
  fetchGetActivityApplyFieldResultSuccess,
  fetchGetActivityApplyFieldResultFailure,
  fetchGetUserAppliesTotalResultCountsRequest,
  fetchGetUserAppliesTotalResultCountsSuccess,
  fetchGetUserAppliesTotalResultCountsFailure,
  fetchGetActivityApplyFieldOptionResponsesRequest,
  fetchGetActivityApplyFieldOptionResponsesSuccess,
  fetchGetActivityApplyFieldOptionResponsesFailure,
  FETCH_GET_USER_APPLY_DAILY_COUNTS,
  FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT,
  FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS,
  FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES,
} from './redux';

export const fetchGetUserApplyDailyCountsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_USER_APPLY_DAILY_COUNTS,
  apiName: 'fetchGetUserApplyDailyCounts',
  fetchRequest: fetchGetUserApplyDailyCountsRequest,
  handleSuccess: (response) => [
    fetchGetUserApplyDailyCountsSuccess(fromJS(response.data || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetUserApplyDailyCountsFailure(error))
    ),
});

export const fetchGetActivityApplyFieldResultEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_APPLY_FIELD_RESULT,
  apiName: 'fetchGetActivityApplyFieldResult',
  fetchRequest: fetchGetActivityApplyFieldResultRequest,
  handleSuccess: (response) => [
    fetchGetActivityApplyFieldResultSuccess(fromJS(response.data || [])),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityApplyFieldResultFailure(error))
    ),
});

export const fetchGetUserAppliesTotalResultCountsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_USER_APPLIES_TOTAL_RESULT_COUNTS,
  apiName: 'fetchGetUserAppliesTotalResultCounts',
  fetchRequest: fetchGetUserAppliesTotalResultCountsRequest,
  handleSuccess: (response) => [
    fetchGetUserAppliesTotalResultCountsSuccess(fromJS(response.data || {})),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetUserAppliesTotalResultCountsFailure(error))
    ),
});

export const fetchGetActivityApplyFieldOptionResponsesEpic = makeBasicFetchEpic(
  {
    actionType: FETCH_GET_ACTIVITY_APPLY_FIELD_OPTION_RESPONSES,
    apiName: 'fetchGetActivityApplyFieldOptionResponses',
    fetchRequest: fetchGetActivityApplyFieldOptionResponsesRequest,
    handleSuccess: (response) => [
      fetchGetActivityApplyFieldOptionResponsesSuccess(
        fromJS(response.data.source || [])
      ),
    ],
    handleFailure: (error, { state$, action, apiErrorsHandler }) =>
      concat(
        apiErrorsHandler(error, { state$, action }),
        of(fetchGetActivityApplyFieldOptionResponsesFailure(error))
      ),
  }
);

export default combineEpics(
  fetchGetUserApplyDailyCountsEpic,
  fetchGetActivityApplyFieldResultEpic,
  fetchGetUserAppliesTotalResultCountsEpic,
  fetchGetActivityApplyFieldOptionResponsesEpic
);
