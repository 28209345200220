import { fetcher } from 'redux/apisOrg';
import { OrganizationRole, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
};
const defaultUseOrgRoles = makeGetHook<
  EntityList<OrganizationRole>,
  PathParams
>('/organizations/{{organizationId}}/roles', fetcher);
export default function useOrgRoles(params: PathParams, payload?) {
  const { data, ...other } = defaultUseOrgRoles(params, payload);
  const parsedData = data
    ? {
        total: data.total,
        source: data.source.sort(
          (a, b) => b.organizationRoleFix - a.organizationRoleFix
        ),
      }
    : undefined;
  return {
    data: parsedData,
    ...other,
  };
}
