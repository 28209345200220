import { fetcher } from 'redux/apisOrg';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';
import { MicepointTransactionRecord, EntityList } from 'types';

export type PathParams = {
  organizationId?: string;
};
const useOrgMpTransactionRecords = makeGetHook<
  EntityList<MicepointTransactionRecord>,
  PathParams
>('/organizations/{{organizationId}}/micepoint-transaction-records', fetcher);
export default useOrgMpTransactionRecords;
