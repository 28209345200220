import React, { forwardRef } from 'react';

import { WithStyles, Theme, createStyles, withStyles } from '@eGroupTeam/material';

import Typography from '@eGroupTeam/material/Typography';
import Divider from '@eGroupTeam/material/Divider';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
    },
  });

export interface IntroduceCardHeaderProps extends WithStyles<typeof styles> {
  title: string;
}

const IntroduceCardHeader = forwardRef<
  HTMLDivElement,
  IntroduceCardHeaderProps
>((props, ref) => {
  const { classes, title, ...other } = props;

  return (
    <div ref={ref} {...other}>
      <div className={classes.content}>
        <Typography variant="h6">{title}</Typography>
      </div>
      <Divider />
    </div>
  );
});

export default withStyles(styles)(IntroduceCardHeader);
