import React from 'react';
import PropTypes from 'prop-types';

const FieldAnchor = ({ name, ...other }) => <div name={name} {...other} />;

FieldAnchor.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FieldAnchor;
