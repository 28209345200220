import { fetcher } from 'redux/apisOrg';
import { OrganizationInvitation, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
};
const useOrgInvitations = makeGetHook<
  EntityList<OrganizationInvitation>,
  PathParams
>('/organizations/{{organizationId}}/members/invitation-list', fetcher);

export default useOrgInvitations;
