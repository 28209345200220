import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { fromJS } from 'immutable';
import {
  fetchPostCarrierConsumerRequest,
  fetchPostCarrierConsumerSuccess,
  fetchPostCarrierConsumerFailure,
  FETCH_POST_CARRIER_CONSUMER,
} from './redux';

export const fetchPostCarrierConsumerEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_CARRIER_CONSUMER,
  apiName: 'invoice.fetchPostCarrierConsumer',
  fetchRequest: fetchPostCarrierConsumerRequest,
  handleSuccess: (response) => [
    fetchPostCarrierConsumerSuccess(fromJS(response.data)),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostCarrierConsumerFailure(error))
    ),
});

export default combineEpics(fetchPostCarrierConsumerEpic);
