import React, { FC, useState } from 'react';

import InfoMark from "static/images/infoMark.png";
import { Controller, UseFormMethods } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
  DropDown,
  Box,
  isGroup
} from '@eGroupTeam/material';
import { PaymentInvoiceFormInput } from 'types';

export interface PaymentInvoiceFormProps extends UseFormMethods {
  onSubmit: (values: PaymentInvoiceFormInput) => void;
}

const useStyles = makeStyles((theme) => ({
  label: {
    height: "16px",
    "& span": {
      fontSize: "0.875rem",
      color: "#646464",
    },
  },
  dropdown: {
    boxShadow: "none",
    "& .MuiButtonGroup-root": {
      height: "unset",
    },
    "& .MuiButtonGroup-grouped": {
      border: "0",
      borderBottom: "1px solid #DBDDE3",
      borderRadius: "0",
      height: "32px",
      fontSize: "1rem",
      paddingRight: "0",
      paddingLeft: "0",
    },
  },
  description: {
    display: "flex",
    "& p": {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(2),
      color: "#05C7F2",
      "& img": {
        marginRight: theme.spacing(1),
        width: "18px",
      },
    },
  },
  textField: {
    "& .MuiInput-underline:before": {
      borderColor: "#DBDDE3",
    },
  },
}));

const PaymentInvoiceForm: FC<PaymentInvoiceFormProps> = ({
  control,
  handleSubmit,
  onSubmit,
  errors,
  watch,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const invoiceResultType = watch('invoiceResultType');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>會員載具</DialogTitle>
        <DialogContent>
          <DialogContentText>
            為因應環保以及政府政策本服務平台只提供電子發票，若有需要紙本發票請來電詢問。
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box>
            <Box mr={2} className={classes.label}>
              <span>發票類型 *</span>
            </Box>
            <Controller
              control={control}
              name="invoiceResultType"
              render={({ onChange }) => (
                <DropDown
                  options={[{
                    text: "會員載具",
                    value: "1"
                  }, {
                    text: "公司戶電子發票",
                    value: "5"
                  }]}
                  select
                  className={classes.dropdown}
                  onChange={(selected) => {
                    if (typeof(selected) === 'string' || Array.isArray(selected)) return
                    if (isGroup(selected)) return
                    onChange(selected.value)
                  }}
                >
                  會員載具
                </DropDown>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.description}>
          <Typography variant="body1" onClick={handleOpen}>
            <img src={InfoMark} alt="Info" />
            發票類型說明
          </Typography>
        </Grid>
        {invoiceResultType === '5' && (
          <>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                control={control}
                name="paymentOrderInvoiceTaxIdNumber"
                label="統一編號"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                error={Boolean(errors?.paymentOrderInvoiceTaxIdNumber)}
                helperText={errors?.paymentOrderInvoiceTaxIdNumber?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                control={control}
                name="paymentOrderInvoiceTitle"
                label="發票抬頭"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                error={Boolean(errors?.paymentOrderInvoiceTitle)}
                helperText={errors?.paymentOrderInvoiceTitle?.message}
              />
            </Grid>
          </>
        )}
        
      </Grid>
    </form>
  );
};

export default PaymentInvoiceForm;
