import React, { VFC } from "react";

import { SNACKBAR } from 'App';
import apis from 'redux/apisThirdParty'
import { makeStyles } from "@material-ui/core";
import useAxiosApiWrapper from "utils/useAxiosApiWrapper";

import Box from "@eGroupTeam/material/Box";
import Typography from "@eGroupTeam/material/Typography";
import Container from "@eGroupTeam/material/Container";
import { ContactUsSectionFormInput } from "types";
import { openSnackbar, setSnackbarData } from "@eGroupTeam/redux-modules/immutable/snackbars";
import { useDispatch } from "react-redux";
import ContactUsSectionForm from "./ContactUsSectionForm";

const useStyles = makeStyles((theme) => ({
  contactSecton: {
    padding: "2rem",
    backgroundColor: theme.egPalette.primary[1],
  },
  title: {
    color: theme.palette.common.white,
    fontSize: "1.75rem",
    lineHeight: "1.2",
    marginBottom: theme.spacing(3),
  },
}));

const ContactUsSection: VFC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { excute: createFeedback } = useAxiosApiWrapper(
    apis.fetchPostFeedback
  );

  const handleSubmit = (values: ContactUsSectionFormInput) => {
    createFeedback({
      organizationFeedbackPersonName: values.organizationFeedbackPersonName,
      organizationFeedbackPersonEmail: values.organizationFeedbackPersonEmail,
      organizationFeedbackTitle: values.organizationFeedbackTitle,
      organizationFeedbackContent: values.organizationFeedbackContent,
      feedbackType:{
        feedbackTypeId: values.feedbackTypeId
      }
    }).then(() => {
      dispatch(
        setSnackbarData({
          name: SNACKBAR,
          message: '送出成功',
          variant: 'success',
        })
      )
      dispatch(openSnackbar(SNACKBAR))
    })
  }

  return (
    <Box className={classes.contactSecton}>
      <Typography
        variant="h3"
        fontWeight={700}
        align="center"
        className={classes.title}
      >
        聯絡我們
      </Typography>
      <Container>
        <ContactUsSectionForm onSubmit={handleSubmit}/>
      </Container>
    </Box>
  );
};

export default ContactUsSection;
