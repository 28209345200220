import { combineEpics } from 'redux-observable';
import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  fetchGetActivityUserTrainQrCodeRequest,
  fetchGetActivityUserTrainQrCodeSuccess,
  fetchGetActivityUserTrainQrCodeFailure,
  fetchPostActivityUserModelTrainCheckRequest,
  fetchPostActivityUserModelTrainCheckSuccess,
  fetchPostActivityUserModelTrainCheckFailure,
  FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE,
  FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK,
} from './redux';

export const fetchGetActivityUserTrainQrCodeEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_USER_TRAIN_QR_CODE,
  apiName: 'landingPage.fetchGetActivityUserTrainQrCode',
  fetchRequest: fetchGetActivityUserTrainQrCodeRequest,
  handleSuccess: (response) => [
    fetchGetActivityUserTrainQrCodeSuccess(fromJS(response.data)),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityUserTrainQrCodeFailure(error))
    ),
});

export const fetchPostActivityUserModelTrainCheckEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK,
  apiName: 'landingPage.fetchPostActivityUserModelTrainCheck',
  fetchRequest: fetchPostActivityUserModelTrainCheckRequest,
  handleSuccess: (response) => [
    fetchPostActivityUserModelTrainCheckSuccess(fromJS(response.data)),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityUserModelTrainCheckFailure(error))
    ),
});

export default combineEpics(
  fetchGetActivityUserTrainQrCodeEpic,
  fetchPostActivityUserModelTrainCheckEpic
);
