import React, { FC, ReactNode } from 'react';

import clsx from 'clsx';
import displayValues from '@eGroupTeam/utils/displayValues';
import {
  Container,
  Typography,
  Grid,
  Avatar,
  makeStyles,
} from '@eGroupTeam/material';
import { grey } from '@eGroupTeam/material/colors';
import Snackbar from '@eGroupTeam/material-lab/Snackbar';
import s3Url from 'utils/s3Url';
import { ImuUserApply, ImuUser, ImuUserApplyTagList, ImuUserApplyTag } from 'types';
import TagContainer from './TagContainer';
import Tag from './Tag';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    background: grey[50],
  },
  tagContainer: {
    margin: theme.spacing(2),
  },
  avatar: {
    width: 300,
    height: 300,
    marginBottom: theme.spacing(2.5),

    [theme.breakpoints.down('xs')]: {
      width: 200,
      height: 200,
    },
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));

export interface RecognizedResultProps {
  /**
   * Apply data.
   */
  userApply: ImuUserApply;
  /**
   * HTML Attributes
   */
  className?: string;
  /**
   * Extend Result Content.
   */
  children?: ReactNode;
}

const RecognizedResult: FC<RecognizedResultProps> = ({
  className,
  userApply,
  children,
  ...other
}) => {
  const classes = useStyles();
  const user = userApply.get('user') as ImuUser;

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open
        variant="success"
        message="驗證完成 !"
        SnackbarContentProps={{
          action: [],
        }}
      />
      <div className={clsx(className, classes.root)} {...other}>
        <Container maxWidth="xs">
          <Grid container justifyContent="center" direction="column">
            <Grid item container justifyContent="center">
              <Avatar
                className={classes.avatar}
                src={s3Url(user.get('facePath') as string)}
              />
            </Grid>
            <Grid item>
              <Typography align="center" variant="h4" className={classes.text}>
                {user.get('userName') as string}
              </Typography>
              {displayValues(
                [
                  {
                    key: '單位名稱',
                    value: user.get('userOrganizationName') as string,
                  },
                  {
                    key: '職稱',
                    value: user.get('userJobTitle') as string,
                  },
                  {
                    key: '場次',
                    value: userApply.get('activityClassName') as string,
                  },
                ],
                ({ key, value }) => (
                  <React.Fragment key={key}>
                    <Typography
                      className={classes.text}
                      variant="h5"
                      align="center"
                    >
                      {value}
                    </Typography>
                  </React.Fragment>
                )
              )}
            </Grid>
          </Grid>
          {userApply.has('userApplyTagList') && (
            <TagContainer className={classes.tagContainer}>
              {(userApply.get('userApplyTagList') as ImuUserApplyTagList).map((el) => {
                const tag = el as ImuUserApplyTag
                return (
                  <Tag key={tag.get('userApplyTagId') as string}>
                    {tag.getIn(['tag', 'tagName']) as string}
                  </Tag>
                )  
              })}
            </TagContainer>
          )}
          {children}
        </Container>
      </div>
    </>
  );
};

export default RecognizedResult;
