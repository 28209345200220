import React, { forwardRef, ImgHTMLAttributes } from "react";

import clsx from "clsx";
import { makeStyles } from "@eGroupTeam/material";
import logo from "./images/logo.png";
import logoGray from "./images/logo-gray.png";
import logoWhite from "./images/logo-white.svg";

const logoVariant = {
  default: logo,
  gray: logoGray,
  white: logoWhite,
};

export interface LogoProps extends ImgHTMLAttributes<HTMLImageElement> {
  size?: "default" | "large" | "small";
  color?: "default" | "gray" | "white";
  backgroundColor?: "black" | "gray" | "white";
  variant?: "default" | "rounded";
  elevation?: number
  imgClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: ({ elevation = 0 }: LogoProps) => theme.shadows[elevation]
  },
  img: {
    width: (props: LogoProps) => props.width ?? 44,
    height: (props: LogoProps) => props.width ?? 44,
  },
  small: {
    width: 33,
    height: 33,
  },
  large: {
    width: 55,
    height: 55,
  },
  rounded: {
    borderRadius: "50%",
    padding: 12,
  },
  roundedBackgroundWhite: {
    backgroundColor: theme.palette.common.white,
  },
  roundedBackgroundBlack: {
    backgroundColor: theme.palette.common.black,
  },
  roundedBackgroundGray: {
    backgroundColor: "#333333",
  },
  roundedSmall: {
    padding: 8,
  },
  roundedLarge: {
    padding: 16,
  },
}));

const Logo = forwardRef<HTMLImageElement, LogoProps>((props, ref) => {
  const classes = useStyles(props)
  const {
    className,
    imgClassName,
    color = "default",
    variant = "default",
    size,
    backgroundColor = "white",
    elevation,
    ...other
  } = props;
  const src = logoVariant[color];
  const isRounded = variant === "rounded";
  return (
    <div
      className={clsx(className, classes.root, {
        [classes.rounded]: isRounded,
        [classes.roundedBackgroundWhite]: isRounded && backgroundColor === "white",
        [classes.roundedBackgroundBlack]: isRounded && backgroundColor === "black",
        [classes.roundedBackgroundGray]: isRounded && backgroundColor === "gray",
        [classes.roundedSmall]: isRounded && size === "small",
        [classes.roundedLarge]: isRounded && size === "large",
      })}
    >
      <img
        src={src}
        ref={ref}
        alt="logo"
        className={clsx(imgClassName, classes.img, {
          [classes.small]: size === "small",
          [classes.large]: size === "large",
        })}
        {...other}
      />
    </div>
  );
});

export default Logo
