import React, { ReactNode } from 'react';

import {
  Paper,
  Typography,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@eGroupTeam/material';
import { ButtonBase } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    actionArea: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: theme.spacing(1, 2),
      alignItems: 'center',
      cursor: 'pointer',

      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: theme.palette.action.hover,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    icon: {
      display: 'flex',
      minWidth: theme.spacing(7),
      color: theme.palette.action.active,
    },
    text: {
      flex: '1 1 auto',
      minWidth: 0,
      margin: '6px 0',
    },
  });

export interface FeatureProps extends WithStyles<typeof styles> {
  icon: ReactNode;
  primary: string;
  secondary: string;
  onClick?: () => void;
}

const Feature: React.FC<FeatureProps> = ({
  classes,
  icon,
  primary,
  secondary,
  ...other
}) => (
  <Paper className={classes.root} square elevation={1} {...other}>
    <ButtonBase className={classes.actionArea} component="div">
      {icon && <div className={classes.icon}>{icon}</div>}
      <div className={classes.text}>
        <Typography variant="body1">{primary}</Typography>
        <Typography variant="body2" color="textSecondary">
          {secondary}
        </Typography>
      </div>
    </ButtonBase>
  </Paper>
);

export default withStyles(styles)(Feature);
