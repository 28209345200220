import React, { FC } from 'react';

import {
  Divider,
  WithStyles,
  withStyles,
  createStyles,
  Theme,
} from '@eGroupTeam/material';
import ActivityGridTitle from './ActivityGridTitle';
import ActivityGridContent from './ActivityGridContent';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      margin: (props: ActivityGridProps) =>
        props.dense ? theme.spacing(2, 0) : theme.spacing(6, 0),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    content: {
      width: '100%',
    },
    divider: {
      width: '100%',
    },
  });

export interface ActivityGridProps {
  dense?: boolean;
}

const ActivityGrid: FC<ActivityGridProps & WithStyles<typeof styles>> = ({
  classes,
  children: childrenProp,
}) => {
  const [title, content] = React.Children.toArray(childrenProp);

  return (
    <>
      <div className={classes.root}>
        <ActivityGridTitle className={classes.title}>{title}</ActivityGridTitle>
        <ActivityGridContent className={classes.content}>
          {content}
        </ActivityGridContent>
      </div>
      <Divider className={classes.divider} />
    </>
  );
};

export default withStyles(styles)(ActivityGrid);
