import React, { VFC } from "react";
import { makeStyles } from "@material-ui/core";

import { DIALOG as LOGIN_DIALOG } from 'components/LoginDialog';
import Container from "@eGroupTeam/material/Container";
import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import Box from "@eGroupTeam/material/Box";
import Aos from "@eGroupTeam/material-module/Aos";
import bannerImage from "./images/banner-first-image.png";
import Header from "../components/Header";
import StyledButton from "../components/StyledButton";
import { openDialog } from "@eGroupTeam/redux-modules/immutable/dialogs";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  landingHeader: {
    height: "630px",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      height: "770px",
    },
    "& .MuiContainer-maxWidthLg": {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "540px",
      },
    },
    "& img": {
      maxWidth: "540px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "2rem",
      },
    },
  },
  contentRow: {
    paddingTop: "10.5rem",
    height: "100%",
    "& .MuiGrid-item": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "7rem",
      paddingBottom: "2rem",
    },
  },
  bannerContent: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    "& h2": {
      fontSize: "2rem",
      color: theme.palette.common.white,
      lineHeight: "1.2",
      marginBottom: "0.5rem",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    "& p": {
      fontSize: "27px",
      lineHeight: "1.5",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  },
}));

const HeaderSection: VFC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Header className={classes.landingHeader}>
      <Container>
        <Grid container spacing={2} className={classes.contentRow}>
          <Grid item xs={12} sm={12} md={6} className={classes.bannerContent}>
            <Aos variant="slideInLeft" config={{ duration: 500 }}>
              <Box>
                <Typography variant="h2" fontWeight={700}>
                  活動簡單辦，客戶輕鬆管
                </Typography>
                <Typography variant="body1">
                讓你的活動更精彩
                </Typography>
                <StyledButton rounded onClick={() => dispatch(openDialog(LOGIN_DIALOG))}>舉辦活動</StyledButton>
              </Box>
            </Aos>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Aos variant="slideInRight" config={{ duration: 500 }}>
              <Box textAlign="center">
                <img
                  src={bannerImage}
                  width="100%"
                  alt="Banner"
                  decoding="async"
                />
              </Box>
            </Aos>
          </Grid>
        </Grid>
      </Container>
    </Header>
  );
};

export default HeaderSection;
