import React from 'react';

import { useDispatch } from 'react-redux';
import useTab from '@eGroupTeam/hooks/useTab';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';

import Main from '@eGroupTeam/material-layout/Main';
import { Button, Container, Paper, Tab, Tabs } from '@eGroupTeam/material';
import PageHeader from 'components/PageHeader';
import PageSection from 'components/PageSection';
import OrgMemberDataList from './OrgMemberDataList';
import OrgMemberInvitationDataList from './OrgMemberInvitationDataList';
import { DIALOG as ADD_INVITATION_DIALOG } from './OrgMemberInvitationAddDialog';

const OrgMembers = () => {
  const dispatch = useDispatch();
  const { value, handleChange } = useTab('orgMembers');

  const handleAddMember = () => {
    dispatch(openDialog(ADD_INVITATION_DIALOG));
  };

  return (
    <Main>
      <Container>
        <PageHeader
          title="單位成員"
          subtitle="您可以管理您的單位成員，只要邀請他們便可以協同合作。"
          actions={
            <Button
              style={{ width: 102 }}
              onClick={handleAddMember}
              variant="contained"
              color="primary"
            >
              邀請成員
            </Button>
          }
        />
        <PageSection>
          <Paper square>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={value}
              onChange={(_, v) => handleChange(v)}
            >
              <Tab label="單位成員列表" />
              <Tab label="邀請等待列表" />
            </Tabs>
          </Paper>
          {value === 0 && <OrgMemberDataList />}
          {value === 1 && <OrgMemberInvitationDataList />}
        </PageSection>
      </Container>
    </Main>
  );
};

export default OrgMembers;
