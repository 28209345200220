import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import Main from '@eGroupTeam/material-layout/Main';
import Footer from 'components/Footer';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Container,
  Paper,
  Box,
  Typography,
  Button,
  Divider,
  CircularProgress,
} from '@eGroupTeam/material';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import Logo from 'components/Logo';
import UserMenu from 'components/UserMenu';
import { fetchPostCarrierConsumer, getCarrierConsumer } from './redux';

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: '#fff',
  },
  logo: {
    marginLeft: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
}));

const Carrier = () => {
  const classes = useStyles();
  const formEl = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const carrierConsumer = useSelector(getCarrierConsumer);

  useEffect(() => {
    dispatch(fetchPostCarrierConsumer());
  }, [dispatch]);

  const handleClick = () => {
    if (formEl && formEl.current) {
      formEl.current.submit();
    }
  };

  if (!carrierConsumer) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <form
        method="POST"
        style={{ display: 'none' }}
        ref={formEl}
        action={carrierConsumer.get('postUrl')}
      >
        <input
          type="hidden"
          name="card_ban"
          value={carrierConsumer.get('card_ban')}
        />
        <input
          type="hidden"
          name="card_no1"
          value={carrierConsumer.get('card_no1')}
        />
        <input
          type="hidden"
          name="card_no2"
          value={carrierConsumer.get('card_no2')}
        />
        <input
          type="hidden"
          name="card_type"
          value={carrierConsumer.get('card_type')}
        />
        <input
          type="hidden"
          name="token"
          value={carrierConsumer.get('token')}
        />
        <input
          type="hidden"
          name="signature"
          value={carrierConsumer.get('signature')}
        />
      </form>
      <AppBar elevation={1} className={classes.appbar}>
        <Toolbar>
          <Logo />
          <div className={classes.grow} />
          <UserMenu />
        </Toolbar>
      </AppBar>
      <NavbarBrick />
      <Main>
        <Container maxWidth="sm">
          <Paper>
            <Box p={2}>
              <Typography variant="h6" align="center" gutterBottom>
                電子發票歸戶設定
              </Typography>
              <Typography variant="body1" align="center">
                您好，請點選下方按鈕同意與財政部電子發票整合平台進行歸戶。
              </Typography>
              <Box py={2}>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  fullWidth
                  component="a"
                  onClick={handleClick}
                >
                  同意進行會員載具歸戶
                </Button>
              </Box>
              <Divider />
              <Box py={2}>
                <Typography variant="body2" color="textSecondary">
                  注意事項：
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                完成電子發票載具歸戶後，您即可在財政部電子發票整合服務平台查詢由「eGroup
                會員載具」所開立之發票資訊。
              </Typography>
            </Box>
          </Paper>
        </Container>
      </Main>
      <Footer />
    </>
  );
};

export default Carrier;
