import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { makeStyles, Typography } from '@eGroupTeam/material';
import Logo from 'components/Logo';
import clsx from 'clsx';
import Currency from 'components/Currency';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',

    '&:hover': {
      '& $actionArea': {
        opacity: 0.1,
      },
    },
  },
  actionArea: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    opacity: 0,
    overflow: 'hidden',
    transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: 'black',
    cursor: 'pointer',
  },
  cover: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(6, 3),
  },
  logo: {
    marginRight: theme.spacing(),
  },
  content: {
    padding: theme.spacing(1),
  },
  subTitle: {
    marginBottom: theme.spacing(1),
  },
  desc: {
    minHeight: 40,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    ...theme.typography.body1,
  },
  price: {
    display: 'flex',
    alignItems: 'center',
  },
  priceDiscount: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    height: 24,
  },
  priceOriginal: (props: ProductCardProps) => {
    const hasDiscount = props.priceDiscount !== undefined;
    return {
      color: hasDiscount
        ? theme.palette.text.secondary
        : theme.palette.primary.main,
      textDecoration: hasDiscount ? 'line-through' : 'none',
      marginLeft: hasDiscount ? theme.spacing(0.5) : 0,
      fontWeight: hasDiscount ? 500 : 700,
    };
  },
}));

export interface ProductCardProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  subTitle?: string;
  description?: ReactNode;
  actions?: ReactNode;
  hover?: boolean;
  disableDescriptionTypography?: boolean;
  priceDiscount?: number | bigint;
  priceOriginal: number | bigint;
  priceUnit: string;
}

const ProductCard: FC<ProductCardProps> = (props) => {
  const {
    className,
    title,
    subTitle,
    description,
    actions,
    hover,
    disableDescriptionTypography,
    priceDiscount,
    priceOriginal,
    priceUnit,
    ...other
  } = props;
  const classes = useStyles(props);
  return (
    <div className={clsx(className, classes.root)} {...other}>
      <div className={classes.cover}>
        <Logo className={classes.logo} />
        <Typography variant="h6">{title}</Typography>
      </div>
      <div className={classes.content}>
        <div className={classes.subTitle}>
          <Typography variant="body1">
            <strong>{subTitle}</strong>
          </Typography>
        </div>
        <div className={classes.desc}>
          {disableDescriptionTypography ? (
            description
          ) : (
            <Typography variant="body2">{description}</Typography>
          )}
        </div>
        <div className={classes.priceContainer}>
          <div className={classes.price}>
            {priceDiscount && (
              <div className={classes.priceDiscount}>
                <Currency value={priceDiscount} isMicepoint />
              </div>
            )}
            <div className={classes.priceOriginal}>{priceOriginal}</div>
          </div>
          <Typography variant="body2" align="center">
            {priceUnit}
          </Typography>
        </div>
      </div>
      <div className={classes.actions}>{actions}</div>
      {hover && <div className={classes.actionArea} />}
    </div>
  );
};

export default ProductCard;
