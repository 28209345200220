import React, { useEffect, useState } from 'react';

import useInterval from '@eGroupTeam/hooks/useInterval';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { Map } from 'immutable';
import { getSelectedOrg } from 'redux/modules/common';

import FixedFab from 'components/FixedFab';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import Paper from '@eGroupTeam/material/Paper';
import TableRow from '@eGroupTeam/material/TableRow';
import TableCell from '@eGroupTeam/material/TableCell';
import Typography from '@eGroupTeam/material/Typography';
import Container from '@eGroupTeam/material/Container';
import Tooltip from '@eGroupTeam/material/Tooltip';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import CircularProgress from '@eGroupTeam/material/CircularProgress';

import EmailIcon from '@material-ui/icons/Email';
import { UrlParams } from 'types';
import {
  fetchGetActivityEdm,
  getActivityEdm,
  getActivityEdmTotal,
  setActiveStep,
} from './redux';

import ActivityNotificationDialog, {
  DIALOG,
} from './ActivityNotificationDialog';

const ActivityNotification = () => {
  const dispatch = useDispatch();
  const activityEdm = useSelector(getActivityEdm);
  const activityEdmTotal = useSelector(getActivityEdmTotal);
  const selectedOrg = useSelector(getSelectedOrg);
  const { activityId } = useParams<UrlParams>();
  const [isSent, setIsSent] = useState(false);
  const [initialValues, setInitialValues] = useState(Map());

  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    page,
    rowsPerPage,
  } = useDataTable('ActivityNotification', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });

  useEffect(() => {
    dispatch(
      fetchGetActivityEdm({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        ...payload,
      })
    );
  },
  [activityId, dispatch, payload, selectedOrg])

  useInterval(() => {
    dispatch(
      fetchGetActivityEdm({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        ...payload,
      })
    );
  }, 1000 * 60 * 1);

  const handleAddActivityEdm = () => {
    setIsSent(false);
    dispatch(openDialog(DIALOG));
  };

  const handleResend = (e, value) => {
    const payload = Map({
      organizationEdmTemplateType: value.activityEdmType,
      organizationEdmTemplateTitle: value.activityEdmTitle,
      organizationEdmTemplateContent: value.activityEdmContent,
      organizationEdmTemplateContactContent: value.activityEdmContactContent,
      activityClass: value.activityClass.activityClassId,
      activityEdm: { activityEdmId: value.activityEdmId },
    });
    setIsSent(true);
    dispatch(openDialog(DIALOG));
    dispatch(setActiveStep(1));
    setInitialValues(payload);
  };

  const renderColumns = (rowData) => (
    <TableRow>
      <TableCell>
        <Typography variant="body2">{rowData[0]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{rowData[1]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{rowData[2]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{rowData[3]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{rowData[4]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{rowData[5]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{rowData[6]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{rowData[7]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{rowData[8]}</Typography>
      </TableCell>
    </TableRow>
  );

  const renderDataRow = (rowData) => (
    <TableRow
      key={rowData.activityEdmId}
      hover
      onClick={(e) => handleResend(e, rowData)}
    >
      <TableCell>
        {rowData.activityEdmTypeString}
      </TableCell>
      <TableCell>
        {rowData.activityEdmTitle}
      </TableCell>
      <TableCell>
        {rowData.notificationEmail.email.emailExpectSendCount}
      </TableCell>
      <TableCell>
        {rowData.notificationEmail.email.emailSendCount}
      </TableCell>
      <TableCell>
        {rowData.notificationEmail.email.emailDeliveryCount}
      </TableCell>
      <TableCell>
        {rowData.notificationEmail.email.emailOpenCount}
      </TableCell>
      <TableCell>
        {rowData.notificationEmail.email.emailClickCount}
      </TableCell>
      <TableCell>
        {rowData.notificationEmail.email.emailComplaintCount}
      </TableCell>
      <TableCell>
        {rowData.notificationEmail.email.emailSendDateString}
      </TableCell>
    </TableRow>
  );

  if (!activityEdm) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <ActivityNotificationDialog
        isSent={isSent}
        initialValues={initialValues}
      />
      <Tooltip title="寄送活動通知" onClick={handleAddActivityEdm}>
        <FixedFab color="primary">
          <EmailIcon />
        </FixedFab>
      </Tooltip>
      <Container>
        <Paper>
          <DataTable
            size="small"
            title="活動通知紀錄"
            SearchBarProps={{
              placeholder: '搜尋通知紀錄',
              onChange: handleSearchChange,
              defaultValue: payload.query,
            }}
            data={activityEdm.toJS()}
            columns={[
              '類型',
              '標題',
              '預計寄送數',
              '已寄送數',
              '已送達數',
              '開啟信件數',
              '點擊連結數',
              '設為垃圾信數',
              '寄送時間',
            ]}
            serverSide
            isEmpty={activityEdm.size === 0}
            renderColumns={renderColumns}
            renderDataRow={renderDataRow}
            MuiTablePaginationProps={{
              count: activityEdmTotal,
              labelRowsPerPage: '每頁幾筆',
              page,
              rowsPerPage,
              onPageChange: handleChangePage,
              onRowsPerPageChange: handleRowsPerPageChange,
            }}
          />
        </Paper>
      </Container>
    </>
  );
};

export default ActivityNotification;
