import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { submit } from 'redux-form/immutable';

import Button from '@eGroupTeam/material/Button';
import Dialog from '@eGroupTeam/material/Dialog';
import DialogContent from '@eGroupTeam/material/DialogContent';
import DialogActions from '@eGroupTeam/material/DialogActions';
import DialogTitle from '@eGroupTeam/material/DialogTitle';
import CreateOrgForm, { FORM } from './CreateOrgForm';

export const DIALOG = 'createOrgDialog';

const CreateOrgDialog = ({ isOpen, handleClose, onSubmit, loading }) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>新增主辦單位</DialogTitle>
      <DialogContent>
        <CreateOrgForm onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>取消</Button>
        <Button
          loading={loading}
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(submit(FORM));
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateOrgDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // parent props
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default withReduxDialog(DIALOG)(CreateOrgDialog);
