import React from 'react';

import useLocationSearch from 'utils/useLocationSearch';
import { red } from '@eGroupTeam/material/colors';

import { Link, useHistory } from 'react-router-dom';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import Main from '@eGroupTeam/material-layout/Main';
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
} from '@eGroupTeam/material';
import useOrgOrder from 'utils/useOrgOrder';

const PaymentFailed = () => {
  const { organizationId, paymentOrderId } = useLocationSearch();
  const history = useHistory();
  const { data } = useOrgOrder({
    organizationId: organizationId as string,
    paymentOrderId: paymentOrderId as string,
  });

  if (!paymentOrderId || !organizationId) {
    return (
      <Main>
        <Container maxWidth="sm">
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              <strong>付款失敗！</strong>
            </Typography>
            <Typography
              variant="body1"
              style={{ color: red[500] }}
              gutterBottom
            >
              信用卡授權失敗，交易被拒，請嘗試其他信用卡付款或聯絡您的發卡銀行。
            </Typography>
            <Typography variant="body1">
              如有任何問題請與MICEPass聯繫 : (02)2362-2508#12 林先生。
            </Typography>
          </Box>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={() => {
              history.push('/me');
            }}
          >
            返回主辦中心
          </Button>
        </Container>
      </Main>
    );
  }

  if (!data) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <Main>
      <Container maxWidth="sm">
        <Box mb={4}>
          <Typography variant="h4" gutterBottom>
            <strong>付款失敗！</strong>
          </Typography>
          <Typography variant="body1" style={{ color: red[500] }} gutterBottom>
            {data.paymentOrderErrDesc}
          </Typography>
          <Typography variant="body1">若持續發生問題，請聯絡我們。</Typography>
        </Box>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          onClick={() => {
            history.push(
              `/me/checkout?organizationId=${organizationId}&paymentOrderId=${data.paymentOrderId}`
            );
          }}
        >
          返回結帳頁面
        </Button>
      </Container>
    </Main>
  );
};

export default PaymentFailed;
