import React, { VFC } from "react";
import { makeStyles } from "@material-ui/core";

import Typography from "@eGroupTeam/material/Typography";
import { Box } from "@eGroupTeam/material";
import Aos from "@eGroupTeam/material-module/Aos";

import BasicImg from "./images/basic.png";
import BasicServiceItem from "./BasicServiceItem";

const useStyles = makeStyles((theme) => ({
  basicServiceSection: {
    backgroundColor: theme.egPalette.info[2],
    borderRadius: "20px",
    paddingBottom: theme.spacing(2),
    height: "100%",
  },
  header: {
    "& img": {
      marginBottom: theme.spacing(2),
      width: "25%",
    },
    "& h6": {
      fontSize: "1rem",
      color: theme.egPalette.primary[1],
      lineHeight: "1.2",
      marginBottom: "0.5rem",
    },
  },
  body: {
    borderTop: "2px solid #36a0bd",
    "& h5": {
      fontSize: "13px",
      color: theme.egPalette.primary[1],
      lineHeight: "1.2",
      marginBottom: "0.5rem",
    },
    "& p": {
      marginBottom: theme.spacing(2),
      color: theme.palette.common.white,
      display: "flex",
      alignItems: "center",
      "& img": {
        width: "18px",
        marginRight: theme.spacing(2),
      },
    },
  },
}));

const BasicServiceSection: VFC = () => {
  const classes = useStyles();

  const functionList = [
    {
      text: "顧客關係管理系統",
    },
    {
      text: "活動管理系統",
    },
    {
      text: "通知系統",
    },
    {
      text: "響應式網站",
    },
    {
      text: "即時戰情系統",
    },
    {
      text: "報到系統-QRCode,基本資料",
    },
  ];

  return (
    <Aos variant="slideInLeft" config={{ duration: 500 }}>
      <Box className={classes.basicServiceSection}>
        <Box p={4} textAlign="center" className={classes.header}>
          <img src={BasicImg} alt="Basic" decoding="async" />
          <Typography variant="h6" align="center" fontWeight={700}>
            一般方案
          </Typography>
        </Box>
        <Box className={classes.body} pt={2} px={2}>
          <Typography variant="h5" fontWeight={700}>
            功能
          </Typography>

          {functionList.map((item) => (
            <BasicServiceItem text={item.text} key={item.text} />
          ))}
          <Typography variant="h5" fontWeight={700}>
            服務費
          </Typography>
          <BasicServiceItem text="MP 19 per person every day." />
        </Box>
      </Box>
    </Aos>
  );
};

export default BasicServiceSection;
