import { withStyles } from '@eGroupTeam/material';
import CheckboxField from '@eGroupTeam/material-form/immutable/CheckboxField';

export default withStyles((theme) => ({
  root: {
    marginRight: 0,
    marginTop: theme.spacing(1),
    width: '100%',
  },
}))(CheckboxField);
