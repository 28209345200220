import React from 'react';

import { reduxForm, Field, Form } from 'redux-form/immutable';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';

export const FORM = 'orgRoleForm';

const validate = (values) => {
  const errors: Record<string, string> = {};
  if (!values.get('organizationRoleNameZh')) {
    errors.organizationRoleNameZh = '「角色名稱」是必填欄位';
  }
  return errors;
};

const OrgRoleForm = ({ handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Field
      name="organizationRoleNameZh"
      label="角色名稱"
      component={TextLoadingField}
      fullWidth
      variant="outlined"
      required
      margin="normal"
    />
  </Form>
);

export default reduxForm({
  form: FORM,
  validate,
})(OrgRoleForm);
