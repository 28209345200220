import { fetcher } from 'redux/apisOrg';
import { UserApply } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
  activityId?: string;
  userApplyId?: string;
};
const useUserApply = makeGetHook<UserApply, PathParams>(
  '/organizations/{{organizationId}}/activities/{{activityId}}/user-applies/{{userApplyId}}',
  fetcher
);
export default useUserApply;
