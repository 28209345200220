import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { SET_SELECTED_ORG } from './index';

const reducer = handleActions(
  {
    [SET_SELECTED_ORG]: (state, action) =>
      state.set('selectedOrg', action.payload)
  },
  fromJS({
    selectedOrg: {}
  })
);

export default reducer;
