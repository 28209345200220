import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { SET_IS_STOP_SNAPSHOT } from './redux';

const reducer = handleActions(
  {
    [SET_IS_STOP_SNAPSHOT]: (state, action) =>
      state.set('isStopSnapshot', action.payload),
  },
  fromJS({
    isStopSnapshot: false,
  })
);

export default reducer;
