import { fetcher } from 'redux/apisOrg';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';
import { Activity } from 'types';

export type PathParams = {
  organizationId?: string;
  activityId?: string;
};
const useOrgActivity = makeGetHook<Activity, PathParams>(
  '/organizations/{{organizationId}}/activities/{{activityId}}',
  fetcher
);
export default useOrgActivity;
