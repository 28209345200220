import React, { FC } from 'react';

import { useFieldStatus } from '@eGroupTeam/material-formik';
import {
  makeStyles,
  Typography,
  Grid,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import { FieldProps } from 'formik';

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(3),
  },
  edgeLabel: {
    marginBottom: theme.typography.pxToRem(9),
  },
}));

export interface RatingFieldProps extends FieldProps {
  start: number;
  end: number;
  startLabel?: string;
  endLabel?: string;
}

const RatingField: FC<RatingFieldProps> = ({
  field,
  form,
  start,
  end,
  startLabel,
  endLabel,
  ...other
}) => {
  const classes = useStyles();
  const { fieldError, showError, disabled } = useFieldStatus(field, form);

  return (
    <>
      <RadioGroup {...field} {...other}>
        <Grid container alignItems="flex-end" justifyContent="space-around">
          <Grid item>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.edgeLabel}
            >
              {startLabel}
            </Typography>
          </Grid>
          {Array.from(Array(end - start + 1).keys()).map((num) => {
            const sNum = String(num + start);
            return (
              <Grid key={sNum} item>
                <Typography
                  align="center"
                  variant="body2"
                  className={classes.label}
                >
                  {sNum}
                </Typography>
                <Radio value={sNum} disabled={disabled} />
              </Grid>
            );
          })}
          <Grid item>
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.edgeLabel}
            >
              {endLabel}
            </Typography>
          </Grid>
        </Grid>
      </RadioGroup>
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </>
  );
};

export default RatingField;
