import React, { FC } from 'react';

// TODO: Need fixed this.
// @ts-ignore
import { submit } from 'redux-form/immutable';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import Button from '@eGroupTeam/material/Button';
import {
  DialogContentText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@eGroupTeam/material';
import { useDispatch } from 'react-redux';
import OrgMemberForm, { FORM } from './OrgMemberForm';

export const DIALOG = 'orgMemberInvitationAddDialog';

export interface OrgMemberInvitationAddProps {
  onSubmit?: (values: any) => void;
  loading?: boolean;
}

const OrgMemberInvitationAddDialog: FC<
  OrgMemberInvitationAddProps & WithReduxDialogProps
> = ({ onSubmit, loading, isOpen, handleClose }) => {
  const dispatch = useDispatch();
  return (
    <Dialog open={isOpen} fullWidth onClose={handleClose}>
      <DialogTitle>邀請單位成員</DialogTitle>
      <DialogContent>
        <DialogContentText>請輸入對方的 email 進行邀請。</DialogContentText>
        <OrgMemberForm onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>關閉</Button>
        <Button
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(submit(FORM));
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<
  HTMLDivElement,
  OrgMemberInvitationAddProps
>(OrgMemberInvitationAddDialog);
