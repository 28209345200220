import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { fromJS } from 'immutable';
import {
  fetchPostCarrierMemberVanRequest,
  fetchPostCarrierMemberVanSuccess,
  fetchPostCarrierMemberVanFailure,
  FETCH_POST_CARRIER_MEMBER_VAN,
} from './redux';

export const fetchPostCarrierMemberVanEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_CARRIER_MEMBER_VAN,
  apiName: 'invoice.fetchPostCarrierMemberVan',
  fetchRequest: fetchPostCarrierMemberVanRequest,
  handleSuccess: (response) => [
    fetchPostCarrierMemberVanSuccess(fromJS(response.data)),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostCarrierMemberVanFailure(error))
    ),
});

export default combineEpics(fetchPostCarrierMemberVanEpic);
