import React, { useEffect } from 'react';

import { renderRoutes } from 'react-router-config';

import NavbarBrick from '@eGroupTeam/material-layout/NavbarBrick';
import { makeStyles } from '@eGroupTeam/material';
import { useHistory, useLocation } from 'react-router';
import SideMenu from 'components/SideMenu';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: theme.layout.sideMenuWidth,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));

const Root = ({ route, organizations }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (organizations.size === 0) {
      history.push('/me/new-org');
    }
  }, [history, organizations]);

  return (
    <>
      <Navbar routes={route.routes} />
      <NavbarBrick />
      <div className={classes.main}>{renderRoutes(route.routes)}</div>
      <SideMenu routes={route.routes} pathname={location.pathname} />
    </>
  );
};

export default Root;
