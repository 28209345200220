import { fetcher } from 'redux/apisThirdParty';
import { ServiceProduct, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  serviceName?: string;
};
const useServiceProducts = makeGetHook<EntityList<ServiceProduct>, PathParams>(
  '/service-products/{{serviceName}}',
  fetcher
);
export default useServiceProducts;
