import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

/** Actions */
export const {
  fetchGetActivity,
  fetchGetActivityRequest,
  fetchGetActivitySuccess,
  fetchGetActivityFailure,

  fetchPostActivityApplyPotential,
  fetchPostActivityApplyPotentialRequest,
  fetchPostActivityApplyPotentialSuccess,
  fetchPostActivityApplyPotentialFailure,
} = actionCreators.landingPages.activity;

/** Types */
export const FETCH_GET_ACTIVITY = fetchGetActivity().type;
export const FETCH_GET_ACTIVITY_REQUEST = fetchGetActivityRequest().type;
export const FETCH_GET_ACTIVITY_SUCCESS = fetchGetActivitySuccess().type;
export const FETCH_GET_ACTIVITY_FAILURE = fetchGetActivityFailure().type;

export const FETCH_POST_ACTIVITY_APPLY_POTENTIAL = fetchPostActivityApplyPotential()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_POTENTIAL_REQUEST = fetchPostActivityApplyPotentialRequest()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_POTENTIAL_SUCCESS = fetchPostActivityApplyPotentialSuccess()
  .type;
export const FETCH_POST_ACTIVITY_APPLY_POTENTIAL_FAILURE = fetchPostActivityApplyPotentialFailure()
  .type;

/** Selectors */

/**
 * ApiStates
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'landingPages', 'activity'], Map());

const getFetchGetActivity = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetActivity', Map())
);

const getFetchPostActivityApplyPotential = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostActivityApplyPotential', Map())
);

export const getActivity = createSelector(getFetchGetActivity, (fetchGet) =>
  fetchGet.get('response')
);

export const getActivityApplyPotentialIsPosting = createSelector(
  getFetchPostActivityApplyPotential,
  (fetchGet) => fetchGet.get('isLoading')
);
