import { fetcher } from 'redux/apisOrg';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';
import { PaymentOrder } from 'types';

export type PathParams = {
  organizationId?: string;
  paymentOrderId?: string;
};
const useOrgOrder = makeGetHook<PaymentOrder, PathParams>(
  '/organizations/{{organizationId}}/micepass/payment-orders/{{paymentOrderId}}',
  fetcher
);
export default useOrgOrder;
