import React from 'react';

import { reduxForm, Field } from 'redux-form/immutable';

import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { Link } from 'react-router-dom';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import MuiLink from '@eGroupTeam/material/Link';
import StyledCheckboxField from './StyledCheckboxField';

export const FORM = 'activityApplyPotentialForm';

const validate = (values) => {
  const errors: Record<string, string> = {};
  if (!values.has('userName')) {
    errors.userName = '「姓名」是必填欄位';
  }
  if (!values.has('userPhone')) {
    errors.userPhone = '「手機」是必填欄位';
  } else if (!isMobilePhone(values.get('userPhone'), 'zh-TW')) {
    errors.userPhone = '「手機」格式錯誤';
  }
  if (!values.has('userEmail')) {
    errors.userEmail = '「Email」是必填欄位';
  } else if (!isEmail(values.get('userEmail'))) {
    errors.userEmail = '「Email」格式錯誤';
  }
  if (!values.has('activityApplyPotentialContent')) {
    errors.activityApplyPotentialContent = '「需求」是必填欄位';
  }
  return errors;
};
const ActivityApplyPotentialForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field
      name="userName"
      autoComplete="nope"
      label="姓名"
      component={TextLoadingField}
      fullWidth
      autoFocus
      margin="normal"
      required
    />
    <Field
      name="userPhone"
      autoComplete="nope"
      label="個人手機"
      placeholder="09XXXXXXXX"
      component={TextLoadingField}
      fullWidth
      margin="normal"
      required
    />
    <Field
      name="userEmail"
      autoComplete="nope"
      label="Email"
      component={TextLoadingField}
      fullWidth
      margin="normal"
      required
    />
    <Field
      name="activityApplyPotentialContent"
      autoComplete="nope"
      label="您的需求"
      component={TextLoadingField}
      fullWidth
      margin="normal"
      required
    />
    <Field
      name="isAgreePrivacy"
      component={StyledCheckboxField}
      margin="normal"
      label={
        <>
          我已閱讀並同意 MICEPass{' '}
          <MuiLink component={Link} to="/terms" target="_blank">
            服務條款
          </MuiLink>{' '}
          以及{' '}
          <MuiLink component={Link} to="/privacy" target="_blank">
            隱私權政策
          </MuiLink>{' '}
          所有條款。
        </>
      }
    />
  </form>
);

export default reduxForm({
  form: FORM,
  validate,
})(ActivityApplyPotentialForm);
