import { format, toDate } from '@eGroupTeam/utils/dateUtils';
import { Activity, ActivityClassFormInput, ActivityFormInput } from 'types';

const parseActivityClassList = (list: ActivityClassFormInput[]) =>
  list.map(({ activityClassMail, ...el }) => {
    const activityClassDayList = el.activityClassDayList
      ? el.activityClassDayList.map((day) => {
          const date = format(day.activityClassDate, 'yyyy-MM-dd');
          const startTime = format(
            day.activityClassDayStartTimeString,
            'HH:mm'
          );
          const endTime = format(day.activityClassDayEndTimeString, 'HH:mm');
          return {
            activityClassDayStartTimeString: toDate(`${date} ${startTime}`),
            activityClassDayEndTimeString: toDate(`${date} ${endTime}`),
          };
        })
      : undefined;
    return {
      ...el,
      activityClassCity: activityClassMail?.city,
      activityClassArea: activityClassMail?.area,
      activityClassDayList,
    };
  });

export default {
  activity: {
    formToApi: (formValues: ActivityFormInput) => ({
      activityContentEditor: formValues.activityContentEditor,
      activityType: formValues.activityType,
      activityName: formValues.activityName,
      activityDescription: formValues.activityDescription,
      isAllowSelectMultipleActivityClass: Number(
        !!formValues.isAllowSelectMultipleActivityClass
      ),
      activityClassList: parseActivityClassList(formValues.activityClassList),
      activityCoverPath: formValues.activityCoverPath,
      activityLogoPath:
        formValues.activityLogoPath !== ''
          ? formValues.activityLogoPath
          : undefined,
      paymentOrder: formValues.paymentOrder,
    }),
    apiToForm: (activity: Activity) => ({
      ...activity,
      activityClassList: activity.activityClassList.map((activityClass) => ({
        ...activityClass,
        activityClassMail: {
          city: activityClass.activityClassCity,
          area: activityClass.activityClassArea,
        },
        activityClassDayList: activityClass.activityClassDayList.map(
          (activityClassDay) => ({
            ...activityClassDay,
            activityClassDate: format(
              activityClassDay.activityClassDayStartTimeString,
              'yyyy-MM-dd'
            ),
          })
        ),
      })),
      isAllowSelectMultipleActivityClass: Boolean(
        activity.isAllowSelectMultipleActivityClass
      ),
    }),
  },
  activityTrialFee: {
    formToApi: (formValues: ActivityFormInput) => ({
      activityClassList: formValues.activityClassList.map((activityClass) => ({
        activityClassStartDateString:
          activityClass.activityClassStartDateString,
        activityClassEndDateString: activityClass.activityClassEndDateString,
        activityClassDayList: activityClass.activityClassDayList?.map(
          (activityClassDay) => ({
            activityClassDayStartTimeString:
              activityClassDay.activityClassDayStartTimeString,
            activityClassDayEndTimeString:
              activityClassDay.activityClassDayEndTimeString,
          })
        ),
        activityClassLimitCount: activityClass.activityClassLimitCount,
      })),
      activityType: formValues.activityType,
      paymentOrder: formValues.paymentOrder,
    }),
  },
};
