import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import indexPath from 'utils/indexPath';
import clsx from 'clsx';
import {
  withReduxDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { useSelector, useDispatch } from 'react-redux';
import makeIsFormFieldsError from 'utils/makeIsFormFieldsError';
import { reduxForm, Field, FormSection } from 'redux-form/immutable';

import { Link } from 'react-router-dom';
import AlertDialog, {
  AlertDialogProps,
} from '@eGroupTeam/material-module/AlertDialog';
import SchemaFields from '@eGroupTeam/material-form/immutable/SchemaFields';
import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import {
  makeStyles,
  Link as MuiLink,
  IconButton,
  Paper,
  FormLabel,
  Stepper,
  Step,
  StepLabel,
} from '@eGroupTeam/material';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TogglePanel from '@eGroupTeam/material/TogglePanel';
import FieldAnchor from 'components/FieldAnchor';
import apis from 'redux/apis';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import StyledCheckboxField from '../StyledCheckboxField';
import ApplyField from './ApplyField';

import {
  setActiveStep,
  getActiveStep,
  getActivityApplyFields,
  getSteps,
  getSurveyQuestionSchema,
} from './redux';

export const FORM = 'activityApplyStepsForm';
export const DIALOG = 'agreeFaceRecognizeAlertDialog';

const useIsFormFieldsError = makeIsFormFieldsError(FORM);

const AgreeFaceRecognizeAlertDialog = withReduxDialog(DIALOG)<
  unknown,
  AlertDialogProps
>(AlertDialog);

const useStyles = makeStyles((theme) => ({
  stepper: {
    marginBottom: theme.spacing(3),
    boxShadow: theme.egShadows[1],
  },
  step: {
    cursor: 'pointer',
  },
  field: {
    boxShadow: theme.egShadows[1],
    padding: theme.spacing(3),
    color:"#898989",
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  fieldError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const validate = (values) => {
  const errors: Record<string, string> = {};
  if (!values.has('userName') || values.get('userName') === '') {
    errors.userName = '「姓名」是必填欄位';
  }
  if (!values.has('userPhone') || values.get('userPhone') === '') {
    errors.userPhone = '「手機」是必填欄位';
  }
  if (values.has('userEmail') && values.get('userEmail') !== '') {
    if (!isEmail(values.get('userEmail'))) {
      errors.userEmail = '「Email」格式錯誤';
    }
  }
  return errors;
};

const asyncValidate = (values, dispatch, props) => {
  if (!isMobilePhone(values.get('userPhone'), 'zh-TW')) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('手機號碼格式錯誤');
    }).catch(() => {
      const error = {
        userPhone: '手機號碼格式錯誤',
      };
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error;
    });
  }
  const { activityId, activityClassIds } = props;

  return apis.landingPage
    .fetchPostCheckUserActivityDuplicate({
      activityId,
      userPhone: values.get('userPhone'),
    })
    .then((res) => {
      const selectedFailActivityClasses = res.data.filter(
        (el) =>
          activityClassIds.includes(el.activityClassId) &&
          el.applyResult === 'fail'
      );
      if (selectedFailActivityClasses.length === activityClassIds.length) {
        const error = {
          userPhone: '您所選的場次均已報名過。',
        };
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw error;
      }
    });
};

const ActivityApplyStepsForm = ({ activityHasFaceRecognize, handleSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isFieldsError, submitFailed, formSyncErrors] = useIsFormFieldsError();
  const activeStep = useSelector(getActiveStep);
  const activityApplyFields = useSelector(getActivityApplyFields);
  const steps = useSelector(getSteps);
  const surveyQuestionSchema: any = useSelector(getSurveyQuestionSchema);
  const schema = useMemo(() => surveyQuestionSchema.toJS(), [
    surveyQuestionSchema,
  ]);

  const handleFieldError = useCallback(() => '這個是必填問題', []);

  const isRequiredValidate = useCallback(
    (value, allValues, formProps, name) => {
      const activityApplyField = activityApplyFields.find(
        (el) => `applyFieldResponseList.${el.get('applyFieldId')}` === name
      );
      if (activityApplyField && Boolean(activityApplyField.get('isRequired'))) {
        let passed = true;

        switch (activityApplyField.get('applyFieldType')) {
          case 'choicemulti':
            passed = value && value.filter((el) => el.get('checked')).size > 0;
            break;
          default:
            passed = !!value;
            break;
        }

        if (!passed) {
          return `「${activityApplyField.get('applyFieldName')}」是必填欄位`;
        }
      }
      return undefined;
    },
    [activityApplyFields]
  );

  const isEmailValidate = useCallback(
    (value, allValues, formProps, name) => {
      const activityApplyField = activityApplyFields.find(
        (el) => `applyFieldResponseList.${el.get('applyFieldId')}` === name
      );
      if (
        activityApplyField &&
        activityApplyField.get('applyFieldType') === 'email' &&
        value &&
        !isEmail(value)
      ) {
        return `「${activityApplyField.get('applyFieldName')}」格式錯誤`;
      }
      return undefined;
    },
    [activityApplyFields]
  );

  return (
    <>
      <AgreeFaceRecognizeAlertDialog
        primary="人臉辨識說明"
        message={
          <ul style={{ margin: 0 }}>
            <li>
              為提供更好的活動體驗及後續服務，本次活動啟用人臉辨識系統進行活動報到。您未來參加本中心的活動時，即可直接刷臉報到完成相關程序。
            </li>
            <li>
              本活動所蒐集之資料，只限於活動參與者之服務使用，例如 :
              報名、報到、領取活動相關資料......等等，所有資料只由經過授權的人員才能接觸使用，並依個人資料保護法之相關規範使用。
            </li>
            <li>
              本中心相關資訊服務均已建置防火牆、防毒系統等相關的各項資訊安全防護措施，以保護提供服務之網站及相關資料的安全。
            </li>
          </ul>
        }
      />
      <form onSubmit={handleSubmit}>
        <Stepper
          activeStep={activeStep}
          className={classes.stepper}
          elevation={1}
        >
          {steps.map((step, index) => {
            // @ts-ignore
            // TODO: Need fixed ts ignore
            const isError = isFieldsError(step.get('fieldNames').toJS());
            return (
              <Step
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classes.step}
                onClick={() => dispatch(setActiveStep(index))}
              >
                <StepLabel error={isError}>
                  {isError ? `${step.get('label')}有誤` : step.get('label')}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <TogglePanel index={0} value={activeStep} implementation="js">
          <FieldAnchor name="position-userName" />
          <Paper
            className={clsx(classes.field, {
              [classes.fieldError]:
                submitFailed && !!indexPath('userName', formSyncErrors),
            })}
          >
            <div className={classes.header}>
              <FormLabel className={classes.label} required color="primary">
                姓名
              </FormLabel>
            </div>
            <Field
              name="userName"
              autoComplete="nope"
              component={TextLoadingField}
              fullWidth
            />
          </Paper>
          <FieldAnchor name="position-userPhone" />
          <Paper
            className={clsx(classes.field, {
              [classes.fieldError]:
                submitFailed && !!indexPath('userName', formSyncErrors),
            })}
          >
            <div className={classes.header}>
              <FormLabel className={classes.label} required color="primary">
                個人手機
              </FormLabel>
            </div>
            <Field
              name="userPhone"
              autoComplete="nope"
              placeholder="09XXXXXXXX"
              component={TextLoadingField}
              fullWidth
              helperText="報到依據，請勿共用"
            />
          </Paper>
          <FieldAnchor name="position-userEmail" />
          <Paper
            className={clsx(classes.field, {
              [classes.fieldError]:
                submitFailed && !!indexPath('userName', formSyncErrors),
            })}
          >
            <div className={classes.header}>
              <FormLabel className={classes.label} required color="primary">
                Email
              </FormLabel>
            </div>
            <Field
              name="userEmail"
              autoComplete="nope"
              component={TextLoadingField}
              fullWidth
              helperText="非公務為佳，建議為常用信箱，報名確認與行前通知使用"
            />
          </Paper>
          {/* TODO: Need refactor to SchemaFields and uniform all data parser. */}
          <FormSection name="applyFieldResponseList">
            {activityApplyFields.map((activityApplyField) => (
              <Fragment key={activityApplyField.get('applyFieldId')}>
                <FieldAnchor
                  name={`position-applyFieldResponseList.${activityApplyField.get(
                    'applyFieldId'
                  )}`}
                />
                <ApplyField
                  data={activityApplyField}
                  validate={[isRequiredValidate, isEmailValidate]}
                  submitFailed={submitFailed}
                  formSyncErrors={formSyncErrors}
                />
              </Fragment>
            ))}
          </FormSection>
          {activityHasFaceRecognize === 1 && (
            <Field
              name="isAgreeFaceRecognize"
              component={StyledCheckboxField}
              margin="normal"
              label={
                <>
                  同意使用人臉辨識入場
                  <IconButton onClick={() => dispatch(openDialog(DIALOG))}>
                    <HelpOutlineIcon />
                  </IconButton>
                </>
              }
            />
          )}
          <Field
            name="isAgreePrivacy"
            component={StyledCheckboxField}
            margin="normal"
            label={
              <>
                我已閱讀並同意 MICEPass{' '}
                <MuiLink component={Link} to="/terms" target="_blank">
                  服務條款
                </MuiLink>{' '}
                以及{' '}
                <MuiLink component={Link} to="/privacy" target="_blank">
                  隱私權政策
                </MuiLink>{' '}
                所有條款。
              </>
            }
          />
        </TogglePanel>
        <TogglePanel index={1} value={activeStep} implementation="js">
          {/* TODO: Need to fixed this ignore. */}
          {/* @ts-ignore */}
          <SchemaFields
            schema={schema}
            isRequiredError={handleFieldError}
            atLeastOneIsRequiredError={handleFieldError}
            renderField={({ label, required, name, ...other }) => (
              <Fragment key={name}>
                <FieldAnchor name={`position-${name}`} />
                <Paper
                  className={clsx(classes.field, {
                    [classes.fieldError]:
                      submitFailed && !!indexPath(name, formSyncErrors),
                  })}
                >
                  <div className={classes.header}>
                    <FormLabel
                      className={classes.label}
                      required={required}
                      color="primary"
                    >
                      {label}
                    </FormLabel>
                  </div>
                  <Field name={name} fullWidth {...other} />
                </Paper>
              </Fragment>
            )}
          />
        </TogglePanel>
      </form>
    </>
  );
};

ActivityApplyStepsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  activityId: PropTypes.string.isRequired,
};

export default reduxForm({
  form: FORM,
  validate,
  asyncValidate,
  asyncChangeFields: ['userPhone'],
})(ActivityApplyStepsForm);
