import React, { useEffect } from 'react';

import { destroyApi } from '@eGroupTeam/redux-modules/immutable/apis';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { getSelectedOrg } from 'redux/modules/common';

import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import CircularProgress from '@eGroupTeam/material/CircularProgress';
import { UrlParams } from 'types';
import { fetchGetSurvey, getSurvey } from './redux';

const SurveyRoot = ({ route, ...other }) => {
  const dispatch = useDispatch();
  const { surveyId } = useParams<UrlParams>();
  const survey = useSelector(getSurvey);
  const selectedOrg = useSelector(getSelectedOrg);

  useEffect(() => {
    dispatch(
      fetchGetSurvey({
        organizationId: selectedOrg.get('organizationId'),
        surveyId,
      })
    );
    return () => {
      dispatch(destroyApi(['mainPages', 'survey']));
    };
  }, [dispatch, selectedOrg, surveyId]);

  if (!survey) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return renderRoutes(route.routes, { survey, ...other });
};

export default SurveyRoot;
