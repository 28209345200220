import { fetcher } from 'redux/apisOrg';
import { OrganizationRole } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
  organizationRoleId?: string;
};
const useOrgRole = makeGetHook<OrganizationRole, PathParams>(
  'organizations/{{organizationId}}/roles/{{organizationRoleId}}',
  fetcher
);

export default useOrgRole;
