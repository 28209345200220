import React, { FC, useCallback, useEffect } from 'react';

import { format } from '@eGroupTeam/utils/dateUtils';
import useInterval from '@eGroupTeam/hooks/useInterval';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import calcPercent from '@eGroupTeam/utils/calcPercent';
import makeChartColors from '@eGroupTeam/utils/makeChartColors';
import { openDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { getSelectedOrg } from 'redux/modules/common';

import DataTableCollapseRow from '@eGroupTeam/material-module/DataTableCollapseRow';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  ListItem,
  MenuItem,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  List as MuiList,
  Table,
  TableBody,
  TableHead,
} from '@eGroupTeam/material';
import { IconButton } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FaceIcon from '@material-ui/icons/Face';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ActionCard from 'components/ActionCard';
import QrCodeIcon from '@eGroupTeam/material-icons/QrCode';
import ListItemBreakText from 'components/ListItemBreakText';
import {
  Activity,
  ActivityClass,
  UrlParams,
  UserApply,
} from 'types';
import {
  UserApplyActivityClassIsCanceled,
} from 'enums'
import CompareDataChart from 'components/CompareDataChart';
import useUserApplies from 'utils/useUserApplies';
import CheckInByUserDataDialog, {
  DIALOG as PHONE_DIALOG,
} from './CheckInByUserDataDialog';
import CheckInByQrCodeDialog, {
  DIALOG as QRCODE_DIALOG,
} from './CheckInByQrCodeDialog';
import CheckInByFaceDialog, {
  DIALOG as FACE_DIALOG,
} from './CheckInByFaceDialog';
import useSelectedApplyField from '../useSelectedApplyField';
import {
  setSelectedActivityClassName,
  setSelectedActivityClassId,
} from '../redux';
import {
  fetchGetUserApplyCountInfo,
  fetchGetActivityApplyFieldResult,
  getUserApplyTotal,
  getUserApplyCounts,
  getActivityApplyFieldResult,
} from './redux';

const COLORS = makeChartColors();

export interface ActivityCheckInProps {
  activity: Activity;
  activityClasses: ActivityClass[];
  selectedActivityClassId: string;
  selectedActivityClassName: string;
}

const ActivityCheckIn: FC<ActivityCheckInProps> = ({
  activity,
  activityClasses,
  selectedActivityClassId,
  selectedActivityClassName,
}) => {
  const dispatch = useDispatch();
  const { activityId } = useParams<UrlParams>();
  const userApplyTotal = useSelector(getUserApplyTotal);
  const userApplyCounts = useSelector(getUserApplyCounts);
  const applyFieldResult = useSelector(getActivityApplyFieldResult);
  const selectedOrg = useSelector(getSelectedOrg);
  const {
    handleChange: handleApplyFieldChange,
    selectedApplyField,
  } = useSelectedApplyField(applyFieldResult);

  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    setPayload,
    page,
    rowsPerPage,
  } = useDataTable('activityCheckIn', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });
  const { data, response, isValidating } = useUserApplies(
    {
      organizationId: selectedOrg.get('organizationId'),
      activityId,
    },
    {
      activityClassId: selectedActivityClassId,
      isCanceled: 0,
      ...payload,
    }
  );

  const fetchData = useCallback(() => {
    dispatch(
      fetchGetUserApplyCountInfo([
        {
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          activityClassId: selectedActivityClassId,
          isCanceled: 0,
        },
        {
          organizationId: selectedOrg.get('organizationId'),
          activityId,
          hasActivityVerifyRecord: 1,
          date: format(new Date(), 'yyyy-MM-dd'),
          activityClassId: selectedActivityClassId,
          isCanceled: 0,
        },
      ])
    );
    dispatch(
      fetchGetActivityApplyFieldResult({
        organizationId: selectedOrg.get('organizationId'),
        activityId,
        activityClassId: selectedActivityClassId,
        isNow: 1,
      })
    );
  }, [activityId, dispatch, selectedActivityClassId, selectedOrg]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useInterval(() => {
    fetchData();
  }, 1000 * 60 * 5);

  const handleSetSelectedActivityClass = (value) => () => {
    setPayload((payload) => ({
      ...payload,
      startIndex: 0,
      size: 10,
    }));
    dispatch(setSelectedActivityClassName(value.name));
    dispatch(setSelectedActivityClassId(value.id));
  };

  const renderColumns = (rowData) => (
    <TableRow>
      {rowData.map((el) => (
        <TableCell key={el}>
          <Typography variant="body2">{el}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );

  const renderDataRow = (rowData: unknown) => {
    const userApply = rowData as UserApply;
    return (
      <DataTableCollapseRow key={userApply.userApplyId} colSpan={3}>
        <>
          <TableCell>{userApply.user.userName}</TableCell>
          <TableCell>{userApply.user.userPhone}</TableCell>
          <TableCell>
            {userApply.userVerifyCreateDateString
              ? format(userApply.userVerifyCreateDateString, 'PP pp')
              : '無資料'}
          </TableCell>
        </>
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>場次名稱</TableCell>
                <TableCell>場次開始時間</TableCell>
                <TableCell>場次結束時間</TableCell>
                <TableCell>報名狀態</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userApply.userApplyActivityClassList.map((el) => (
                <TableRow key={el.activityClass.activityClassId}>
                  <TableCell>{el.activityClass.activityClassName}</TableCell>
                  <TableCell>
                    {format(
                      el.activityClass.activityClassStartDateString,
                      'PPPP p'
                    )}
                  </TableCell>
                  <TableCell>
                    {format(
                      el.activityClass.activityClassEndDateString,
                      'PPPP p'
                    )}
                  </TableCell>
                  <TableCell>
                    {UserApplyActivityClassIsCanceled[el.isCanceled]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </DataTableCollapseRow>
    );
  };

  const renderList = () =>
    activityClasses.map((el) => (
      <ListItem
        key={el.activityClassId}
        selected={selectedActivityClassName === el.activityClassName}
        button
        onClick={handleSetSelectedActivityClass({
          name: el.activityClassName,
          id: el.activityClassId,
        })}
      >
        <ListItemBreakText
          primary={el.activityClassName}
          secondary={el.activityClassCity}
        />
      </ListItem>
    ));

  if (!applyFieldResult) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      {activityId && (
        <>
          <CheckInByUserDataDialog activityId={activityId} />
          <CheckInByQrCodeDialog activityId={activityId} />
          <CheckInByFaceDialog activityId={activityId} />
        </>
      )}
      <Container>
        <Grid container spacing={1}>
          {activity.activityType === 2 && (
            <Grid item xs={12} sm={2}>
              <MuiList disablePadding>
                <ListItem
                  selected={selectedActivityClassName === '全部'}
                  button
                  onClick={handleSetSelectedActivityClass({ name: '全部' })}
                >
                  <ListItemBreakText primary="全部場次" />
                </ListItem>
                {renderList()}
              </MuiList>
            </Grid>
          )}
          <Grid item xs={12} sm={activity.activityType === 2 ? 10 : 12}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar style={{ backgroundColor: COLORS[0] }}>
                        <EventAvailableIcon />
                      </Avatar>
                    }
                    title="今日報到率"
                    subheader={`${calcPercent(
                      userApplyCounts,
                      userApplyTotal
                    )} (${userApplyCounts}/${userApplyTotal})`}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ActionCard
                  onClick={() => {
                    dispatch(openDialog(PHONE_DIALOG));
                  }}
                  title="一般驗證"
                  actionIcon={AssignmentIcon}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ActionCard
                  onClick={() => {
                    dispatch(openDialog(QRCODE_DIALOG));
                  }}
                  title="QRCode驗證"
                  actionIcon={QrCodeIcon}
                />
              </Grid>
              {activity.activityHasFaceRecognize === 1 && (
                <Grid item xs={12} sm={4}>
                  <ActionCard
                    onClick={() => {
                      dispatch(openDialog(FACE_DIALOG));
                    }}
                    title="人臉驗證"
                    actionIcon={FaceIcon}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Paper>
                  <DataTable
                    title="會員驗證紀錄"
                    data={data?.source ?? []}
                    loading={isValidating}
                    isEmpty={response?.status === 204}
                    SearchBarProps={{
                      placeholder: '搜尋會員',
                      onChange: handleSearchChange,
                      defaultValue: payload.query,
                    }}
                    toolsbar={
                      <Tooltip title="匯出簽到簽退 excel">
                        <IconButton
                          component="a"
                          download
                          href={`/api/organizations/${selectedOrg.get(
                            'organizationId'
                          )}/activities/${activityId}/generator-prove-excel?activityClassId=${selectedActivityClassId}`}
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    columns={['', '姓名', '手機', '驗證時間']}
                    serverSide
                    renderColumns={renderColumns}
                    renderDataRow={renderDataRow}
                    MuiTablePaginationProps={{
                      count: data?.total ?? 0,
                      labelRowsPerPage: '每頁幾筆',
                      page,
                      rowsPerPage,
                      onPageChange: handleChangePage,
                      onRowsPerPageChange: handleRowsPerPageChange,
                    }}
                  />
                </Paper>
              </Grid>
              {selectedApplyField && (
                <Grid item xs={12}>
                  <Paper>
                    <Box p={2} display="flex" alignItems="center">
                      <Typography variant="h6" display="inline">
                        現場報名欄位統計
                      </Typography>
                      <TextField
                        select
                        size="small"
                        value={selectedApplyField.applyFieldId}
                        onChange={handleApplyFieldChange}
                        variant="outlined"
                      >
                        {applyFieldResult.map((el) => (
                          <MenuItem
                            key={el.applyFieldId}
                            value={el.applyFieldId}
                          >
                            {el.applyFieldName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Box p={2} pt={0}>
                      <CompareDataChart
                        BarChartProps={{
                          data: selectedApplyField.applyFieldOptionList,
                        }}
                      />
                    </Box>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ActivityCheckIn;
