import { fetcher } from 'redux/apisLP';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';
import { Activity } from 'types';

export type PathParams = {
  activityId?: string;
};
const useActivity = makeGetHook<Activity, PathParams>(
  '/activities/{{activityId}}',
  fetcher
);
export default useActivity;
