import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  SET_DIRECT_NOTIFICATION_ACTIVE_STEP,
  HANDLE_DIRECT_NOTIFICATION_NEXT,
  HANDLE_DIRECT_NOTIFICATION_PREV,
} from './redux';

const reducer = handleActions(
  {
    [SET_DIRECT_NOTIFICATION_ACTIVE_STEP]: (state, action) =>
      state.set('activeDirectNotificationStep', action.payload),
    [HANDLE_DIRECT_NOTIFICATION_NEXT]: (state) =>
      state.update('activeDirectNotificationStep', (el) => {
        const nextStep = el + 1;
        if (nextStep <= state.get('activeDirectNotificationStepMax')) {
          return nextStep;
        }
        return el;
      }),
    [HANDLE_DIRECT_NOTIFICATION_PREV]: (state) =>
      state.update('activeDirectNotificationStep', (el) => {
        const prevStep = el - 1;
        if (prevStep >= 0) {
          return prevStep;
        }
        return el;
      }),
  },
  fromJS({
    activeDirectNotificationStep: 0,
    activeDirectNotificationStepMax: 2,
  })
);

export default reducer;
