import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'js-base64';
import s3Url from 'utils/s3Url';
import { getSelectedOrg } from 'redux/modules/common';

import Main from '@eGroupTeam/material-layout/Main';
import Container from '@eGroupTeam/material/Container';
import Typography from '@eGroupTeam/material/Typography';
import Button from '@eGroupTeam/material/Button';
import CircularProgress from '@eGroupTeam/material/CircularProgress';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import IntroduceCard from 'components/IntroduceCard';
import IntroduceCardHeader from 'components/IntroduceCardHeader';
import IntroduceCardContent from 'components/IntroduceCardContent';
import IntroduceCardActions from 'components/IntroduceCardActions';
import {
  fetchPostActivityUserTemp,
  getActivityUserTempIsPosting,
} from './redux';
import UsersActivityImportResultDialog from './UsersActivityImportResultDialog';

const UsersActivityImport = (props) => {
  const dispatch = useDispatch();
  const isPosting = useSelector(getActivityUserTempIsPosting);
  const selectedOrg = useSelector(getSelectedOrg);

  const handleChange = (e) => {
    const file = e.target.files[0];
    const exts = file.name.split('.').pop();
    const newName = file.name.replace(`.${exts}`, '');
    const newFile = new File([file], `${encode(newName)}.${exts}`, {
      type: file.type,
    });

    const formData = new FormData();
    formData.append('file', newFile);
    dispatch(
      fetchPostActivityUserTemp({
        organizationId: selectedOrg.get('organizationId'),
        formData,
      })
    );
  };

  if (isPosting) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <UsersActivityImportResultDialog />
      <Main>
        <Container maxWidth="sm">
          <IntroduceCard>
            <IntroduceCardHeader title="匯入活動參加名單" />
            <IntroduceCardContent>
              <Typography variant="body1" gutterBottom>
                MICEPass將依照會員參加次數與時數，自動計算會員忠誠度，分析VIP客戶與潛在客戶。
              </Typography>
              <Typography variant="body1">
                1.活動開始時間。
                <br />
                2.活動結束時間。
                <br />
                3.活動舉辦地點。
                <br />
                4.會員參加活動時數。
              </Typography>
            </IntroduceCardContent>
            <IntroduceCardActions>
              <Button
                component="label"
                htmlFor="activityExcelFile"
                variant="contained"
                disableElevation
                color="primary"
                size="small"
              >
                匯入名單
              </Button>
              <input
                onChange={handleChange}
                style={{ display: 'none' }}
                type="file"
                accept=".xlsx, .xls, .csv"
                id="activityExcelFile"
              />
              <Button
                component="a"
                download
                href={s3Url(
                  'resources/file/template/%E6%B4%BB%E5%8B%95%E5%90%8D%E7%A8%B1.xlsx'
                )}
                color="primary"
                size="small"
              >
                下載範本
              </Button>
            </IntroduceCardActions>
          </IntroduceCard>
        </Container>
      </Main>
    </>
  );
};

export default UsersActivityImport;
