import React, { useEffect, useState } from 'react';

import {
  openDialog,
  closeDialog,
  setDialogData,
  withReduxDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@eGroupTeam/material';
import { getSelectedOrg } from 'redux/modules/common';

import ConfirmDialog from '@eGroupTeam/material-module/ConfirmDialog';

import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import { Link } from 'react-router-dom';
import Main from '@eGroupTeam/material-layout/Main';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import RadioGroup from '@eGroupTeam/material/RadioGroup';
import ListItem from '@eGroupTeam/material/ListItem';
import ListItemSecondaryAction from '@eGroupTeam/material/ListItemSecondaryAction';
import Grid from '@eGroupTeam/material/Grid';
import Typography from '@eGroupTeam/material/Typography';
import Paper from '@eGroupTeam/material/Paper';
import Box from '@eGroupTeam/material/Box';
import Button from '@eGroupTeam/material/Button';
import Container from '@eGroupTeam/material/Container';
import Tooltip from '@eGroupTeam/material/Tooltip';
import IconButton from '@eGroupTeam/material/IconButton';
import CircularProgress from '@eGroupTeam/material/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FixedFab from 'components/FixedFab';
import IntroduceCard from 'components/IntroduceCard';
import IntroduceCardHeader from 'components/IntroduceCardHeader';
import IntroduceCardContent from 'components/IntroduceCardContent';
import IntroduceCardActions from 'components/IntroduceCardActions';
import {
  fetchGetUsers,
  fetchDeleteUser,
  getUsers,
  getUsersTotal,
  getIsLoading,
} from './redux';
import UserEditDialog, { DIALOG } from './UserEditDialog';
import UserAddDialog, { DIALOG as ADD_DIALOG } from './UserAddDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    [theme.breakpoints.down(500)]: {
      width: 300,
    },
  },
}));

const DELETE_DIALOG = 'ConfirmDeleteUserDialog';
const ConfirmDeleteDialog = withReduxDialog(DELETE_DIALOG)(ConfirmDialog);

const Users = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const users = useSelector(getUsers);
  const usersTotal = useSelector(getUsersTotal);
  const isLoading = useSelector(getIsLoading);
  const selectedOrg = useSelector(getSelectedOrg);
  const [selectedUserId, setSelectedUserId] = useState();

  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    setPayload,
    page,
    rowsPerPage,
  } = useDataTable('Surveys', {
    startIndex: 0,
    size: 10,
    loyalty: 'DESC',
  }, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });

  useEffect(() => {
    dispatch(
      fetchGetUsers({
        organizationId: selectedOrg.get('organizationId'),
        ...payload,
      })
    );
  },
  [dispatch, payload, selectedOrg])

  const handleRadioGroupChange = (name) => (e) => {
    setPayload((value) => ({
      ...value,
      [name]: e.target.value,
    }));
  };

  const handleAddUser = () => {
    dispatch(openDialog(ADD_DIALOG));
  };

  const handleDeleteUser = (value) => (e) => {
    e.stopPropagation();
    setSelectedUserId(value.userId);
    dispatch(openDialog(DELETE_DIALOG));
  };

  const handleDelUserConfirm = () => {
    dispatch(
      fetchDeleteUser({
        organizationId: selectedOrg.get('organizationId'),
        userId: selectedUserId,
      })
    );
    dispatch(closeDialog(DELETE_DIALOG));
  };

  const renderColumns = (rowData) => (
    <ListItem>
      <Grid container>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">{rowData[0]}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">{rowData[1]}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">{rowData[2]}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">{rowData[3]}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">{rowData[4]}</Typography>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Typography variant="body2">{rowData[5]}</Typography>
        </Grid>
      </Grid>
      <ListItemSecondaryAction />
    </ListItem>
  );

  const renderDataRow = (rowData) => (
    <ListItem
      key={rowData.userId}
      button
      onClick={() => {
        dispatch(openDialog(DIALOG));
        dispatch(
          setDialogData({
            name: DIALOG,
            userId: rowData.userId,
          })
        );
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">{rowData.userName}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">
            {rowData.userOrganizationName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">{rowData.userJobTitle}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">{rowData.userPhone}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography noWrap variant="body2">
            {rowData.userEmail}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Typography variant="body2">{rowData.userLoyalty}</Typography>
        </Grid>
      </Grid>
      <ListItemSecondaryAction>
        <Tooltip title="刪除" onClick={handleDeleteUser(rowData)}>
          <IconButton size="small">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );

  if (!users) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (
    users &&
    users.size === 0 &&
    typeof payload.query === 'undefined' &&
    typeof payload.userMemberStatus === 'undefined'
  ) {
    return (
      <>
        <UserAddDialog />
        <Main>
          <Container maxWidth="sm">
            <IntroduceCard>
              <IntroduceCardHeader title="新增會員資料" />
              <IntroduceCardContent>
                <Typography variant="body1">
                  您可以透過會員管理系統更好的經營您的會員，馬上開始新增會員吧！
                </Typography>
              </IntroduceCardContent>
              <IntroduceCardActions>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  size="small"
                  onClick={handleAddUser}
                >
                  建立會員
                </Button>
                <Button
                  component={Link}
                  disableElevation
                  color="primary"
                  size="small"
                  to="/me/hrm/import-users"
                >
                  匯入會員
                </Button>
              </IntroduceCardActions>
            </IntroduceCard>
          </Container>
        </Main>
      </>
    );
  }

  return (
    <>
      <UserEditDialog />
      <UserAddDialog />
      <ConfirmDeleteDialog
        primary="刪除會員"
        message="確定要刪除此會員嗎？"
        onConfirm={handleDelUserConfirm}
      />
      <Tooltip title="新增會員">
        <FixedFab onClick={handleAddUser} color="primary">
          <AddIcon />
        </FixedFab>
      </Tooltip>
      <Main>
        <Container>
          <Paper>
            <DataTable
              title="會員列表"
              SearchBarProps={{
                placeholder: '會員',
                onChange: handleSearchChange,
                defaultValue: payload.query,
                renderOptions: ({ handleDropDownClose }) => (
                  <Paper className={classes.paper}>
                    <Box p={3}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={4}>
                          <Typography color="textSecondary">
                            忠誠度排序
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <RadioGroup
                            MuiRadioGroupProps={{
                              margin: 'normal',
                              value: payload.loyalty,
                              onChange: handleRadioGroupChange('loyalty'),
                            }}
                            options={[
                              {
                                key: 'ASC',
                                value: 'ASC',
                                label: '低到高',
                              },
                              {
                                key: 'DESC',
                                value: 'DESC',
                                label: '高到低',
                              },
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box p={3} pt={0} textAlign="right">
                      <Button
                        type="submit"
                        onClick={() => {
                          handleDropDownClose();
                        }}
                      >
                        送出
                      </Button>
                    </Box>
                  </Paper>
                ),
              }}
              data={users.toJS()}
              columns={[
                '姓名',
                '單位',
                '職稱',
                '電話',
                'Email',
                // '會員狀態',
                '忠誠度',
              ]}
              serverSide
              isEmpty={users.size === 0}
              loading={isLoading}
              renderColumns={renderColumns}
              renderDataRow={renderDataRow}
              disablePadding
              MuiTablePaginationProps={{
                count: usersTotal || 0,
                labelRowsPerPage: '每頁幾筆',
                page,
                rowsPerPage,
                onPageChange: handleChangePage,
                onRowsPerPageChange: handleRowsPerPageChange,
              }}
            />
          </Paper>
        </Container>
      </Main>
    </>
  );
};

export default Users;
