import React from 'react';
import { combineEpics } from 'redux-observable';
import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import MobileDetect from 'mobile-detect';
import { push } from 'connected-react-router/immutable';
import {
  openDialog,
  setDialogData,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { DIALOG } from 'App';
import {
  fetchPostUserApplyActivityRequest,
  fetchPostUserApplyActivitySuccess,
  fetchPostUserApplyActivityFailure,
  fetchGetUserDataByEmailTokenIdRequest,
  fetchGetUserDataByEmailTokenIdSuccess,
  fetchGetUserDataByEmailTokenIdFailure,
  fetchGetActivitySurveysRequest,
  fetchGetActivitySurveysSuccess,
  fetchGetActivitySurveysFailure,
  fetchGetActivityClassesRequest,
  fetchGetActivityClassesSuccess,
  fetchGetActivityClassesFailure,
  fetchGetActivityApplyFieldsRequest,
  fetchGetActivityApplyFieldsSuccess,
  fetchGetActivityApplyFieldsFailure,
  fetchGetActivitySurveyQuestions,
  fetchGetActivitySurveyQuestionsRequest,
  fetchGetActivitySurveyQuestionsSuccess,
  fetchGetActivitySurveyQuestionsFailure,
  addStep,
  updateStep,
  FETCH_POST_USER_APPLY_ACTIVITY,
  FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID,
  FETCH_GET_ACTIVITY_SURVEYS,
  FETCH_GET_ACTIVITY_CLASSES,
  FETCH_GET_ACTIVITY_APPLY_FIELDS,
  FETCH_GET_ACTIVITY_SURVEY_QUESTIONS,
} from './redux';
import { fetchGetActivity } from '../redux';

export const fetchPostUserApplyActivityEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_USER_APPLY_ACTIVITY,
  apiName: 'landingPage.fetchPostUserApplyActivity',
  fetchRequest: fetchPostUserApplyActivityRequest,
  handleSuccess: (response) => {
    const { data } = response;
    const {
      isAgreeFaceRecognize,
      activity,
      qrCodeToken,
      user,
      userApplyActivityClassList,
    } = data;
    const md = new MobileDetect(window.navigator.userAgent);
    const isApplyFail =
      userApplyActivityClassList.filter(
        (el) => el.activityClass.applyResult === 'success'
      ).length === 0;
    if (isApplyFail) {
      return [
        fetchPostUserApplyActivitySuccess(),
        setDialogData({
          name: DIALOG,
          primary: '報名失敗',
          message: userApplyActivityClassList.map((el) => (
            <>
              {el.activityClass.activityClassName}：
              {el.activityClass.applyResultMessage}
              <br />
            </>
          )),
        }),
        openDialog(DIALOG),
      ];
    }
    if (isAgreeFaceRecognize) {
      if (md.mobile()) {
        return [
          fetchGetActivity({
            activityId: activity.activityId,
          }),
          fetchPostUserApplyActivitySuccess(fromJS(data)),
          push(
            `/train?qrcodeTokenId=${qrCodeToken.qrcodeTokenId}&activityId=${activity.activityId}`
          ),
        ];
      }
      return [
        fetchGetActivity({
          activityId: activity.activityId,
        }),
        fetchPostUserApplyActivitySuccess(fromJS(data)),
        push(
          `/activities/${activity.activityId}/apply-train-qrcode?userId=${user.userId}&qrcodeTokenId=${qrCodeToken.qrcodeTokenId}`
        ),
      ];
    }
    return [
      fetchGetActivity({
        activityId: activity.activityId,
      }),
      fetchPostUserApplyActivitySuccess(fromJS(data)),
      push(`/activities/${activity.activityId}/apply-done`),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostUserApplyActivityFailure(error))
    ),
});

export const fetchGetUserDataByEmailTokenIdEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID,
  apiName: 'landingPage.fetchGetUserDataByEmailTokenId',
  fetchRequest: fetchGetUserDataByEmailTokenIdRequest,
  handleSuccess: (response) => [
    fetchGetUserDataByEmailTokenIdSuccess(fromJS(response.data)),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetUserDataByEmailTokenIdFailure(error))
    ),
});

export const fetchGetActivitySurveysEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_SURVEYS,
  apiName: 'landingPage.fetchGetActivitySurveys',
  fetchRequest: fetchGetActivitySurveysRequest,
  handleSuccess: (response) => {
    const activitySurveys: any = fromJS(response.data.source || []);
    if (activitySurveys.size > 0) {
      return [
        fetchGetActivitySurveyQuestions({
          activityId: activitySurveys.getIn([0, 'activity', 'activityId']),
          surveyId: activitySurveys.getIn([0, 'surveyId']),
        }),
        fetchGetActivitySurveysSuccess(activitySurveys),
      ];
    }
    return [fetchGetActivitySurveysSuccess(activitySurveys)];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivitySurveysFailure(error))
    ),
});

export const fetchGetActivityClassesEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_CLASSES,
  apiName: 'landingPage.fetchGetActivityClasses',
  fetchRequest: fetchGetActivityClassesRequest,
  handleSuccess: (response) => [
    fetchGetActivityClassesSuccess(fromJS(response.data.source)),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityClassesFailure(error))
    ),
});

export const fetchGetActivityApplyFieldsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_APPLY_FIELDS,
  apiName: 'landingPage.fetchGetActivityApplyFields',
  fetchRequest: fetchGetActivityApplyFieldsRequest,
  handleSuccess: (response) => {
    const activityApplyFields: any = fromJS(response.data.source || []);
    if (activityApplyFields.size) {
      return [
        updateStep(
          fromJS({
            index: 0,
            fieldNames: activityApplyFields.map((activityApplyField) =>
              activityApplyField.get('applyFieldId')
            ),
          })
        ),
        fetchGetActivityApplyFieldsSuccess(activityApplyFields),
      ];
    }
    return [fetchGetActivityApplyFieldsSuccess(activityApplyFields)];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivityApplyFieldsFailure(error))
    ),
});

export const fetchGetActivitySurveyQuestionsEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ACTIVITY_SURVEY_QUESTIONS,
  apiName: 'landingPage.fetchGetActivitySurveyQuestions',
  fetchRequest: fetchGetActivitySurveyQuestionsRequest,
  handleSuccess: (response) => {
    const activitySurveyQuestions: any = fromJS(response.data.source || []);
    if (activitySurveyQuestions.size > 0) {
      return [
        addStep(
          fromJS({
            label: '填寫問卷',
            fieldNames: activitySurveyQuestions.map(
              (activitySurveyQuestion) =>
                `responseList.${activitySurveyQuestion.get('questionId')}`
            ),
          })
        ),
        fetchGetActivitySurveyQuestionsSuccess(activitySurveyQuestions),
      ];
    }
    return [fetchGetActivitySurveyQuestionsSuccess(activitySurveyQuestions)];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetActivitySurveyQuestionsFailure(error))
    ),
});

export default combineEpics(
  fetchPostUserApplyActivityEpic,
  fetchGetUserDataByEmailTokenIdEpic,
  fetchGetActivitySurveysEpic,
  fetchGetActivityClassesEpic,
  fetchGetActivityApplyFieldsEpic,
  fetchGetActivitySurveyQuestionsEpic
);
