export default {
  textarea: 'textarea',
  text: 'text',
  email: 'email',
  date: 'date',
  choiceone: 'radioGroup',
  rating: 'rating',
  choicemulti: 'checkboxGroup',
  select: 'select',
  boolean: 'boolean',
  titleBlock: 'titleBlock',
};
