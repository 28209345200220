import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles } from '@eGroupTeam/material';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    height: theme.spacing(6),
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  icon: {
    marginRight: theme.spacing(4),
  },
});

const SideMenuHeader = ({ children, classes, className, icon, ...other }) => {
  const Icon = icon;
  return (
    <div className={clsx(classes.root, className)} {...other}>
      <Icon color="action" className={classes.icon} />
      {children}
    </div>
  );
};

SideMenuHeader.propTypes = {
  /**
   * The content of the side menu header.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The content of the side menu header icon.
   */
  icon: PropTypes.object,
};

export default withStyles(styles)(SideMenuHeader);
