import React from 'react';
import Center from '@eGroupTeam/material-layout/Center';
import { Typography } from '@eGroupTeam/material';

const ActivityNotFound = () => (
  <Center>
    <Typography variant="h5" align="center">
      哎呀，好像找不到這個活動了。
    </Typography>
  </Center>
);

export default ActivityNotFound;
