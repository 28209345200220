import React, { forwardRef } from 'react';

import EGReactSelectField from '@eGroupTeam/material-form/immutable/ReactSelectField';
import Typography from '@eGroupTeam/material/Typography';

const ReactSelectField = forwardRef((props, ref) => (
  <EGReactSelectField
    ref={ref}
    noOptionsMessage={() => (
      <Typography component="span" align="center" style={{ display: 'block' }}>
        查無選項
      </Typography>
    )}
    formatCreateLabel={(value) => `新增 "${value}"`}
    menuPortalTarget={document.body}
    menuPosition="fixed"
    {...props}
  />
));

export default ReactSelectField;
