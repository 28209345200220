import React from 'react';
import { Redirect } from 'react-router-dom';

import Root from 'landingPages/Root';
import Home from 'landingPages/Home';
import FacebookLogin from 'landingPages/FacebookLogin';
import GoogleLogin from 'landingPages/GoogleLogin';
import ActivityRoot from 'landingPages/Activity/ActivityRoot';
import ActivityInfo from 'landingPages/Activity/ActivityInfo';
import ActivitySelectApplyClass from 'landingPages/Activity/ActivitySelectApplyClass';
import ActivityApply from 'landingPages/Activity/ActivityApply';
import ActivityApplyTrainQrcode from 'landingPages/Activity/ActivityApplyTrainQrcode';
import ActivityApplyDone from 'landingPages/Activity/ActivityApplyDone';
import ActivityUserApplyOnlineCheckIn from 'landingPages/Activity/ActivityUserApplyOnlineCheckIn';
import ActivityCancelApply from 'landingPages/Activity/ActivityCancelApply';
import ActivityNotFound from 'landingPages/Activity/ActivityNotFound';
import EmailFillSurvey from 'landingPages/EmailFillSurvey';
import PhoneFillSurvey from 'landingPages/PhoneFillSurvey';
import Pricing from 'landingPages/Pricing';
import Reference from 'landingPages/Reference';
import SurveyRoot from 'landingPages/Survey/SurveyRoot';
import Survey from 'landingPages/Survey';
import SurveyDone from 'landingPages/Survey/SurveyDone';
import CheckUserSurvey from 'landingPages/Survey/CheckUserSurvey';
import Train from 'landingPages/Train';
import Privacy from 'landingPages/Privacy';
import Terms from 'landingPages/Terms';
import NoFound from 'landingPages/NoFound';
import OrganizationInvitation from 'landingPages/OrganizationInvitation';

export default [
  {
    path: '/fb-login',
    component: FacebookLogin,
  },
  {
    path: '/g-login',
    component: GoogleLogin,
  },
  {
    path: '/train',
    component: Train,
  },
  {
    path: '/activities/:activityId',
    component: ActivityRoot,
    routes: [
      {
        path: '/activities/:activityId',
        exact: true,
        component: ActivityInfo,
      },
      {
        path: '/activities/:activityId/user-applies/:userApplyId/online-checkin',
        component: ActivityUserApplyOnlineCheckIn,
      },
      {
        path: '/activities/:activityId/user-applies/:emailTokenId',
        component: ActivityCancelApply,
      },
      {
        path: '/activities/:activityId/select-apply-class',
        component: ActivitySelectApplyClass,
      },
      {
        path: '/activities/:activityId/apply',
        component: ActivityApply,
      },
      {
        path: '/activities/:activityId/apply-train-qrcode',
        component: ActivityApplyTrainQrcode,
      },
      {
        path: '/activities/:activityId/apply-done',
        component: ActivityApplyDone,
      },
    ],
  },
  {
    component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: '/pricing',
        component: Pricing,
      },
      {
        path: '/reference',
        component: Reference,
      },
      {
        path: '/activity-404',
        component: ActivityNotFound,
      },
      {
        path: '/surveys/:surveyId',
        component: SurveyRoot,
        routes: [
          {
            path: '/surveys/:surveyId',
            exact: true,
            component: Survey,
          },
          {
            path: '/surveys/:surveyId/done',
            component: SurveyDone,
          },
          {
            path: '/surveys/:surveyId/check',
            component: CheckUserSurvey,
          },
        ],
      },
      {
        path: '/email-fill-survey',
        component: EmailFillSurvey,
      },
      {
        path: '/phone-fill-survey',
        component: PhoneFillSurvey,
      },
      {
        path: '/privacy',
        component: Privacy,
      },
      {
        path: '/terms',
        component: Terms,
      },
      {
        path: '/not-found',
        component: NoFound,
      },
      {
        path: '/org/invitation',
        component: OrganizationInvitation,
      },
      {
        path: '*',
        render: () => <Redirect to="/" />,
      },
    ],
  },
];
