import React, { FC } from 'react';

import clsx from 'clsx';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import s3Url from 'utils/s3Url';

import RatioImage from '@eGroupTeam/material/RatioImage';
import {
  Typography,
  CircularProgress,
  Button,
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@eGroupTeam/material';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckIcon from '@material-ui/icons/Check';

const styles = (theme: Theme) => createStyles({
  // https://jsfiddle.net/prafuitu/vmL0ys1u/
  container: {
    '--border-color': theme.palette.text.secondary,
    '--border-weight': '2px',
    '--dash-size': '8px',
    '--gap-size': '8px',

    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: 200,
    outline: 'none',
    cursor: 'pointer',

    '&:hover': {
      '--border-color': theme.palette.text.primary,
    },

    '&::after': {
      content: '""',
      transition: 'all .6s ease',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `
        linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top left no-repeat,
        linear-gradient(90deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) top center repeat-x,
        linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top right no-repeat,
        
        linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top left no-repeat,
        linear-gradient(0deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) center left repeat-y,
        linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom left no-repeat,
        
        linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom left no-repeat,
        linear-gradient(90deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) bottom center repeat-x,
        linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom right no-repeat,
        
        linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top right no-repeat,
        linear-gradient(0deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) center right repeat-y,
        linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom right no-repeat;
        background-size: var(--dash-size) var(--border-weight), calc(var(--dash-size) + var(--gap-size)) var(--border-weight), var(--dash-size) var(--border-weight), var(--border-weight) var(--dash-size), var(--border-weight) calc(var(--dash-size) + var(--gap-size)), var(--border-weight) var(--dash-size)`,
    },
  },
  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
  icon: {
    opacity: 0,
    fontSize: 40,
  },
  imgRoot: {
    position: 'relative',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    background: 'rgba(0,0,0,0.7)',
    color: '#fff',
  },
  btn: {
    marginBottom: theme.spacing(1),
  },
  imgContainer: {
    '&::after': {
      content: '""',
      transition: 'opacity .6s ease',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,0.4)',
      opacity: 0,
    },
  },
  selected: {
    color: '#fff',
    '&::after': {
      opacity: 1,
    },
    '& $icon': {
      opacity: 1,
    },
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',

      '&::after': {
        opacity: 1,
      },
    },
  },
});

export interface ImageUploadAreaProps {
  uploadedImagePath?: string
  selectedImagePath?: string
  uploading?: boolean
  hover?: boolean
  onDrop?: DropzoneOptions["onDrop"]
  onCoverClick?: ({ src: string }) => void
  ratio?: string
}

const ImageUploadArea: FC<ImageUploadAreaProps & WithStyles<typeof styles>> = ({
  classes,
  onDrop: onDropProp,
  uploadedImagePath,
  selectedImagePath,
  onCoverClick,
  uploading,
  children,
  hover,
  ratio
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropProp,
    accept: 'image/*',
    disabled: uploading,
  });

  const handleCoverClick = () => {
    if (onCoverClick) {
      onCoverClick({
        src: uploadedImagePath,
      });
    }
  };

  if (uploadedImagePath) {
    return (
      <>
        <Button
          muiButtonClassName={classes.btn}
          variant="contained"
          disableElevation
          startIcon={<RefreshIcon />}
          {...getRootProps() as any}
        >
          重新上傳
        </Button>
        <div
          className={classes.imgRoot}
          onClick={handleCoverClick}
          onKeyDown={handleCoverClick}
          role="button"
          tabIndex={0}
        >
          <input {...getInputProps()} multiple={false} />
          <div
            className={clsx(
              classes.imgContainer,
              hover && classes.hover,
              uploadedImagePath === selectedImagePath && classes.selected
            )}
          >
            <RatioImage
              src={s3Url(uploadedImagePath)}
              fit="cover"
              ratio={ratio}
              alt="default cover image"
            />
            <CheckIcon
              color="inherit"
              className={clsx(classes.center, classes.icon)}
            />
          </div>
          {uploading && (
            <div className={classes.loaderContainer}>
              <CircularProgress color="inherit" />
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className={classes.container} {...getRootProps()}>
      <input {...getInputProps()} multiple={false} />
      {isDragActive ? (
        <Typography className={classes.center}>將圖片拖移至此 ...</Typography>
      ) : (
        <Typography className={classes.center} align="center">
          {children}
        </Typography>
      )}
      {uploading && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ImageUploadArea);
