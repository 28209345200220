import React from 'react';

import { AppBar, Toolbar, makeStyles, Hidden, Drawer } from '@eGroupTeam/material';
import Logo from 'components/Logo';
import UserMenu from 'components/UserMenu';
import Hamburger from 'components/Hamburger';
import NestedSideMenu from '@eGroupTeam/material-module/NestedSideMenu';
import useIsOpen from '@eGroupTeam/hooks/useIsOpen';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  appbar: {
    background: '#fff',
  },
  grow: {
    flexGrow: 1,
  },
}));

const Navbar = ({ routes }) => {
  const classes = useStyles();
  const location = useLocation();
  const { isOpen, handleOpen, handleClose } = useIsOpen();

  return (
    <>
      <Drawer open={isOpen} onClose={handleClose}>
        <NestedSideMenu
          routes={routes}
          pathname={location.pathname}
          NestedListItemProps={{
            MuiListItemProps: {
              onClick: handleClose,
            },
          }}
          NestedListItemItemsProps={{
            MuiListItemProps: {
              onClick: handleClose,
            },
          }}
        />
      </Drawer>
      <AppBar elevation={1} className={classes.appbar}>
        <Toolbar>
          <Hidden mdUp>
            <Hamburger onClick={handleOpen} edge="start" aria-label="Menu" />
          </Hidden>
          <Hidden smDown>
            <Link to="/me/profile">
              <Logo />
            </Link>
          </Hidden>
          <div className={classes.grow} />
          <UserMenu />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
