import { clearApiResponse } from '@eGroupTeam/redux-modules/immutable/apis';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { push } from 'connected-react-router/immutable';
import { fromJS, Map } from 'immutable';
import {
  openDialog,
  setDialogData,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { setSelectedOrg } from 'redux/modules/common';
import { fetchGetOrganizations } from 'components/RouterRoot/actions';
import { DIALOG } from 'App';
import {
  fetchGetMemberInvitationByEmailRequest,
  fetchGetMemberInvitationByEmailSuccess,
  fetchGetMemberInvitationByEmailFailure,
  fetchPostMemberIntoOrganizationRequest,
  fetchPostMemberIntoOrganizationSuccess,
  fetchPostMemberIntoOrganizationFailure,
  FETCH_GET_MEMBER_INVITATION_BY_EMAIL,
  FETCH_POST_MEMBER_INTO_ORGANIZATION,
} from './redux';

export const fetchGetMemberInvitationByEmailEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_MEMBER_INVITATION_BY_EMAIL,
  apiName: 'fetchGetMemberInvitationByEmail',
  fetchRequest: fetchGetMemberInvitationByEmailRequest,
  handleSuccess: (response) => [
    fetchGetMemberInvitationByEmailSuccess(fromJS(response.data || {})),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) => {
    if (error.response && error.response.status === 403) {
      return concat(
        of(push('/')),
        of(
          setDialogData({
            name: DIALOG,
            primary: '無訪問權限',
            message: '無訪問權限，因為您非被邀請人。',
          })
        ),
        of(openDialog(DIALOG)),
        of(fetchGetMemberInvitationByEmailFailure(error))
      );
    }
    return concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetMemberInvitationByEmailFailure(error))
    );
  },
});

export const fetchPostMemberIntoOrganizationEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_MEMBER_INTO_ORGANIZATION,
  apiName: 'fetchPostMemberIntoOrganization',
  fetchRequest: fetchPostMemberIntoOrganizationRequest,
  handleSuccess: (response, { action }) => {
    if (action.payload.organizationInvitationStatus === 3) {
      window.localStorage.removeItem('selectedOrgId');
      return [
        fetchPostMemberIntoOrganizationSuccess(fromJS(response.data)),
        clearApiResponse(fetchGetOrganizations()),
        setSelectedOrg(Map()),
        fetchGetOrganizations(),
        push('/me'),
      ];
    }
    return [
      fetchPostMemberIntoOrganizationSuccess(fromJS(response.data)),
      push('/'),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostMemberIntoOrganizationFailure(error))
    ),
});

export default combineEpics(
  fetchGetMemberInvitationByEmailEpic,
  fetchPostMemberIntoOrganizationEpic
);
