import { useState, useEffect } from 'react';
import { ApplyFieldsResult } from 'types';

/**
 * unify selectedApplyField logic
 * @param applyFieldResult
 */
const useSelectedApplyField = (applyFieldResult: ApplyFieldsResult[]) => {
  const [selectedApplyField, setSelectedApplyField] = useState<
    ApplyFieldsResult
  >();

  const handleChange = (e) => {
    const next = applyFieldResult.find(
      (el) => el.applyFieldId === e.target.value
    );
    setSelectedApplyField(next);
  };

  useEffect(() => {
    if (applyFieldResult) {
      if (!selectedApplyField) {
        setSelectedApplyField(applyFieldResult[0]);
      } else {
        const next = applyFieldResult.find(
          (el) => el.applyFieldId === selectedApplyField.applyFieldId
        );
        setSelectedApplyField(next);
      }
    }
  }, [applyFieldResult, selectedApplyField]);

  return {
    handleChange,
    selectedApplyField,
    setSelectedApplyField,
  };
};

export default useSelectedApplyField;
