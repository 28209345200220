import { combineEpics } from 'redux-observable';
import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  fetchPatchUserApplyStatusRequest,
  fetchPatchUserApplyStatusSuccess,
  fetchPatchUserApplyStatusFailure,
  FETCH_PATCH_USER_APPLY_STATUS,
} from './redux';

export const fetchPatchUserApplyStatusEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_USER_APPLY_STATUS,
  apiName: 'landingPage.fetchPatchUserApplyStatus',
  fetchRequest: fetchPatchUserApplyStatusRequest,
  handleSuccess: (response, { action }) => {
    action.payload.callback();
    return [fetchPatchUserApplyStatusSuccess(fromJS(response.data))];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchUserApplyStatusFailure(error))
    ),
});

export default combineEpics(fetchPatchUserApplyStatusEpic);
