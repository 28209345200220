import React, { useEffect, useState } from 'react';

import { fromJS, Map } from 'immutable';
import { makeStyles } from '@eGroupTeam/material';
import {
  withReduxDialog,
  openDialog,
  closeDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import useOptions from 'utils/useOptions';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedOrg } from 'redux/modules/common';

import Main from '@eGroupTeam/material-layout/Main';
import DataTable, { useDataTable } from '@eGroupTeam/material-module/DataTable';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@eGroupTeam/material-module/ConfirmDialog';
import Box from '@eGroupTeam/material/Box';
import Grid from '@eGroupTeam/material/Grid';
import Button from '@eGroupTeam/material/Button';
import FormControl from '@eGroupTeam/material/FormControl';
import Select from '@eGroupTeam/material/Select';
import MenuItem from '@eGroupTeam/material/MenuItem';
import TableRow from '@eGroupTeam/material/TableRow';
import TableCell from '@eGroupTeam/material/TableCell';
import Typography from '@eGroupTeam/material/Typography';
import Tooltip from '@eGroupTeam/material/Tooltip';
import Paper from '@eGroupTeam/material/Paper';
import FixedFab from 'components/FixedFab';
import Container from '@eGroupTeam/material/Container';
import CircularProgress from '@eGroupTeam/material/CircularProgress';
import IconButton from '@eGroupTeam/material/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PreviewIcon from '@material-ui/icons/Visibility';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import IntroduceCard from 'components/IntroduceCard';
import IntroduceCardHeader from 'components/IntroduceCardHeader';
import IntroduceCardContent from 'components/IntroduceCardContent';
import IntroduceCardActions from 'components/IntroduceCardActions';
import {
  fetchGetOrganizationEdmTemplates,
  fetchDeleteOrganizationEdmTemplate,
  fetchPostOrganizationEdmCopyTemplate,
  getOrganizationEdmTemplates,
  getOrganizationEdmTemplatesTotal,
  getIsLoading,
} from './redux';
import NotificationTemplatesDialog, {
  DIALOG,
} from './NotificationTemplatesDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 360,
    [theme.breakpoints.down(500)]: {
      width: 200,
    },
  },
  formControl: {
    margin: 'auto',
    minWidth: 150,
  },
}));

const DELETE_DIALOG = 'ConfirmDeleteTemplateDialog';
const ConfirmDeleteDialog = withReduxDialog(DELETE_DIALOG)<
  unknown,
  ConfirmDialogProps
>(ConfirmDialog);

const NotificationTemplates = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const organizationEdmTemplates = useSelector(getOrganizationEdmTemplates);
  const organizationEdmTemplatesTotal = useSelector(
    getOrganizationEdmTemplatesTotal
  );
  const isLoading = useSelector(getIsLoading);
  const selectedOrg = useSelector(getSelectedOrg);
  const [selectedTemplate, setSelectedTemplate] = useState(Map());
  const [isEditing, setIsEditing] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [
    organizationEdmTemplateType,
    setOrganizationEdmTemplateType,
  ] = useState('');
  const { data } = useOptions(undefined, {
    isShowOrganizationEdmTemplateType: true,
  });

  const {
    handleSearchChange,
    handleChangePage,
    handleRowsPerPageChange,
    payload,
    setPayload,
    page,
    rowsPerPage,
  } = useDataTable('NotificationTemplates', {}, {
    fromKey: 'startIndex',
    sizeKey: 'size',
  });

  useEffect(() => {
    dispatch(
      fetchGetOrganizationEdmTemplates({
        organizationId: selectedOrg.get('organizationId'),
        ...payload,
      })
    );
  },
  [dispatch, payload, selectedOrg])

  const handleAddTemplate = () => {
    setIsEditing(false);
    setSelectedTemplate(Map());
    dispatch(openDialog(DIALOG));
  };

  const handleEditTemplate = (value) => (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setIsCopy(false);
    setSelectedTemplate(Map(value));
    dispatch(openDialog(DIALOG));
  };

  const handleDeleteTemplate = (value) => (e) => {
    e.stopPropagation();
    setSelectedTemplate(Map(value));
    dispatch(openDialog(DELETE_DIALOG));
  };

  const handleDelTemplateConfirm = () => {
    const organizationEdmTemplateId = selectedTemplate.get(
      'organizationEdmTemplateId'
    );
    dispatch(
      fetchDeleteOrganizationEdmTemplate({
        organizationId: selectedOrg.get('organizationId'),
        organizationEdmTemplateId,
      })
    );
    dispatch(closeDialog(DELETE_DIALOG));
  };

  const handlePreviewTemplate = (value) => (e) => {
    e.stopPropagation();
    window.open(value.organizationEdmTemplatePath, '_blank');
  };

  const handleCopyTemplate = (value) => (e) => {
    e.stopPropagation();
    const payload = (fromJS(value) as any)
      .deleteAll([
        'organizationEdmTemplateCreateDateString',
        'organizationEdmTemplateUpdateDateString',
        'organization',
        'organizationEdmTemplatePath',
        'organizationEdmTemplateTypeString',
      ])
      .set('organizationId', selectedOrg.get('organizationId'))
      .set('isDuplication', 1);
    dispatch(fetchPostOrganizationEdmCopyTemplate(payload.toJS()));
    setIsEditing(true);
    setIsCopy(true);
    setSelectedTemplate(
      (fromJS(value) as any).update(
        'organizationEdmTemplateName',
        (el) => `${el}-複本`
      )
    );
  };

  const handleFilterChange = (e) => {
    setOrganizationEdmTemplateType(e.target.value);
    setPayload((value) => ({
      ...value,
      organizationEdmTemplateType:
        e.target.value !== 'all' ? e.target.value : undefined,
    }));
  };

  const renderColumns = (rowData) => (
    <TableRow>
      <TableCell>
        <Typography variant="body1">{rowData[0]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{rowData[1]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{rowData[2]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{rowData[3]}</Typography>
      </TableCell>
    </TableRow>
  );

  const renderDataRow = (rowData) => (
    <TableRow
      key={rowData.organizationEdmTemplateId}
      hover
      onClick={handleEditTemplate(rowData)}
    >
      <TableCell>
        <Typography variant="body1">
          {rowData.organizationEdmTemplateName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          {
            data?.organizationEdmTemplateType[
              rowData.organizationEdmTemplateType
            ]
          }
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" noWrap>
          {rowData.organizationEdmTemplateTitle}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title="預覽">
          <IconButton size="small" onClick={handlePreviewTemplate(rowData)}>
            <PreviewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="複製" onClick={handleCopyTemplate(rowData)}>
          <IconButton size="small">
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="刪除" onClick={handleDeleteTemplate(rowData)}>
          <IconButton size="small">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  const renderContent = () => {
    if (
      organizationEdmTemplates.size === 0 &&
      typeof payload.query === 'undefined' &&
      typeof payload.organizationEdmTemplateType === 'undefined'
    ) {
      return (
        <Container maxWidth="sm">
          <IntroduceCard>
            <IntroduceCardHeader title="新增通知範本" />
            <IntroduceCardContent>
              <Typography variant="body1">
                透過新增 Email
                通知範本您可以更方便的在活動舉辦時使用範本寄送活動相關的資訊給您的會員。
              </Typography>
            </IntroduceCardContent>
            <IntroduceCardActions>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                onClick={handleAddTemplate}
              >
                新增範本
              </Button>
            </IntroduceCardActions>
          </IntroduceCard>
        </Container>
      );
    }
    return (
      <Container>
        <Paper>
          <DataTable
            size="small"
            minWidth={700}
            title="信件範本列表"
            SearchBarProps={{
              placeholder: '搜尋信件範本',
              onChange: handleSearchChange,
              defaultValue: payload.query,
              renderOptions: ({ handleDropDownClose }) => (
                <Paper className={classes.paper}>
                  <Box p={3}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={5}>
                        <Typography color="textSecondary">
                          篩選信件類型
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <Select
                            value={organizationEdmTemplateType}
                            onChange={handleFilterChange}
                          >
                            <MenuItem value="all">全部</MenuItem>
                            {data?.organizationEdmTemplateType &&
                              Object.keys(data.organizationEdmTemplateType).map(
                                (key) => (
                                  <MenuItem key={key} value={key}>
                                    {data.organizationEdmTemplateType[key]}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box p={3} pt={0} textAlign="right">
                    <Button
                      onClick={() => {
                        handleDropDownClose();
                      }}
                      color="primary"
                    >
                      關閉
                    </Button>
                  </Box>
                </Paper>
              ),
            }}
            data={organizationEdmTemplates.toJS()}
            isEmpty={organizationEdmTemplates.size === 0}
            columns={['範本名稱', '範本類型', '信件標題', '操作']}
            serverSide
            loading={isLoading}
            renderColumns={renderColumns}
            renderDataRow={renderDataRow}
            MuiTablePaginationProps={{
              count: organizationEdmTemplatesTotal,
              labelRowsPerPage: '每頁幾筆',
              page,
              rowsPerPage,
              onPageChange: handleChangePage,
              onRowsPerPageChange: handleRowsPerPageChange,
            }}
          />
        </Paper>
      </Container>
    );
  };

  if (!organizationEdmTemplates) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  return (
    <>
      <NotificationTemplatesDialog
        isCopy={isCopy}
        isEditing={isEditing}
        initialValues={selectedTemplate}
      />
      <ConfirmDeleteDialog
        primary="刪除信件範本"
        message="確定要刪除此信件範本嗎？"
        onConfirm={handleDelTemplateConfirm}
      />
      <Tooltip title="新增信件範本" onClick={handleAddTemplate}>
        <FixedFab color="primary">
          <AddIcon />
        </FixedFab>
      </Tooltip>
      <Main>{renderContent()}</Main>
    </>
  );
};

export default NotificationTemplates;
