import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field, Form } from 'redux-form/immutable';
import { isEmail, isMobilePhone } from 'validator';
import { getSelectedOrg } from 'redux/modules/common';

import TextLoadingField from '@eGroupTeam/material-form/immutable/TextLoadingField';
import apis from 'redux/apis';
import { fetchPostUser } from './redux';

export const FORM = 'userAddForm';

const validate = (values) => {
  const errors = {};
  if (!values.get('userName')) {
    errors.userName = '「姓名」是必填欄位';
  }
  if (values.get('userEmail') && !isEmail(values.get('userEmail'))) {
    errors.userEmail = '「Email」格式錯誤';
  }
  return errors;
};

const asyncValidate = (values, dispatch, props) => {
  if (!isMobilePhone(values.get('userPhone'), 'zh-TW')) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('手機號碼格式錯誤');
    }).catch(() => {
      const error = {
        userPhone: '手機號碼格式錯誤',
      };
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error;
    });
  }

  return apis
    .fetchPostCheckUserDuplicate({
      organizationId: props.organizationId,
      userPhone: values.get('userPhone'),
    })
    .catch(() => {
      const error = {
        userPhone: '手機號碼已存在',
      };
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw error;
    });
};

const UserAddForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(getSelectedOrg);

  const handleCustomSubmit = (values) => {
    dispatch(
      fetchPostUser({
        organizationId: selectedOrg.get('organizationId'),
        ...values.toJS(),
      })
    );
  };

  return (
    <Form onSubmit={handleSubmit(handleCustomSubmit)}>
      <Field
        name="userName"
        label="姓名"
        component={TextLoadingField}
        fullWidth
        variant="outlined"
        required
        margin="normal"
      />
      <Field
        name="userPhone"
        label="電話"
        component={TextLoadingField}
        fullWidth
        variant="outlined"
        required
        margin="normal"
      />
      <Field
        name="userJobTitle"
        label="職稱"
        component={TextLoadingField}
        fullWidth
        variant="outlined"
        required
        margin="normal"
      />
      <Field
        name="userEmail"
        label="Email"
        component={TextLoadingField}
        fullWidth
        variant="outlined"
        required
        margin="normal"
      />
    </Form>
  );
};

UserAddForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORM,
  validate,
  asyncValidate,
  asyncChangeFields: ['userPhone'],
})(UserAddForm);
