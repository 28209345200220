import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import {
  openDialog,
  setDialogData,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import { replace } from 'connected-react-router/immutable';
import { DIALOG } from 'App';
import {
  fetchPostUserInfoRequest,
  fetchPostUserInfoSuccess,
  fetchPostUserInfoFailure,
  FETCH_POST_USER_INFO,
} from './redux';

export const fetchPostUserInfoEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_USER_INFO,
  apiName: 'landingPage.fetchPostUserInfo',
  fetchRequest: fetchPostUserInfoRequest,
  handleSuccess: (response, { action }) => [
    fetchPostUserInfoSuccess(),
    replace(`/surveys/${action.payload.surveyId}`),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) => {
    const url = `/phone-fill-survey?surveyId=${state$.value.getIn([
      'router',
      'location',
      'query',
      'surveyId',
    ])}`;
    if (error.response) {
      if (error.response.status === 403) {
        return concat(
          of(fetchPostUserInfoFailure(error)),
          of(replace(url)).pipe(delay(3000))
        );
      }
      if (error.response.status === 409) {
        return concat(
          of(
            setDialogData({
              name: DIALOG,
              primary: '重複填寫',
              message: '您已填過此問卷',
            })
          ),
          of(openDialog(DIALOG)),
          of(fetchPostUserInfoFailure(error))
        );
      }
    }
    return concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostUserInfoFailure(error)),
      of(replace(url)).pipe(delay(3000))
    );
  },
});

export default combineEpics(fetchPostUserInfoEpic);
