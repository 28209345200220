import React, { FC } from 'react';

// TODO: Need fixed this.
// @ts-ignore
import { submit } from 'redux-form/immutable';
import {
  withReduxDialog,
  WithReduxDialogProps,
} from '@eGroupTeam/redux-modules/immutable/dialogs';

import Button from '@eGroupTeam/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@eGroupTeam/material';
import { useDispatch } from 'react-redux';
import OrgRoleForm, { FORM } from './OrgRoleForm';

export const DIALOG = 'orgAddRoleDialog';
export interface OrgAddRoleDialogProps {
  onSubmit?: (values: any) => void;
  loading?: boolean;
}

const OrgAddRoleDialog: FC<OrgAddRoleDialogProps & WithReduxDialogProps> = ({
  onSubmit,
  isOpen,
  handleClose,
  loading,
}) => {
  const dispatch = useDispatch();
  return (
    <Dialog open={isOpen} fullWidth onClose={handleClose}>
      <DialogTitle>新增角色</DialogTitle>
      <DialogContent>
        <OrgRoleForm onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>取消</Button>
        <Button
          loading={loading}
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(submit(FORM));
          }}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withReduxDialog(DIALOG)<HTMLDivElement, OrgAddRoleDialogProps>(
  OrgAddRoleDialog
);
