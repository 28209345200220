import React, { useCallback, useEffect, useMemo, useState } from 'react';

import MobileDetect from 'mobile-detect';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import {
  withReduxDialog,
  openDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import useInterval from '@eGroupTeam/hooks/useInterval';
import {
  makeStyles,
  Box,
  CircularProgress,
  Paper,
  Typography,
} from '@eGroupTeam/material';
import s3Url from 'utils/s3Url';
import { useSelector, useDispatch } from 'react-redux';
import { clearApisResponse } from '@eGroupTeam/redux-modules/immutable/apis';

import { Redirect } from 'react-router-dom';
import RatioImage from '@eGroupTeam/material/RatioImage';
import AlertDialog from '@eGroupTeam/material-module/AlertDialog';
import Snackbar from '@eGroupTeam/material-lab/Snackbar';
import FixedCenter from '@eGroupTeam/material-layout/FixedCenter';
import Center from '@eGroupTeam/material-layout/Center';

import ActivityAppBar from '../ActivityAppBar';
import {
  fetchGetActivityUserTrainQrCode,
  fetchPostActivityUserModelTrainCheck,
  getActivityUserQrCodeToken,
  getActivityUserModelTrainCheck,
} from './redux';

const useStyles = makeStyles((theme) => ({
  imgBox: {
    width: 350,
    border: '1px solid #323333',
    borderRadius: 16,
    margin: 'auto',
    marginBottom: theme.spacing(4),
    padding: 5,
  },
}));

// const SOCKET_NAME = 'qrcodeTrainSocket';
const DIALOG = 'refreshQrCodeDialog';

const RefreshQrCodeAlertDialog = withReduxDialog(DIALOG)(AlertDialog);

const md = new MobileDetect(window.navigator.userAgent);

const ActivityApplyTrainQrcode = ({ activity }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const qrCodeToken = useSelector(getActivityUserQrCodeToken);
  const activityUserModelTrainCheck = useSelector(
    getActivityUserModelTrainCheck
  );
  const { userId, qrcodeTokenId } = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const handleFetchGetActivityUserTrainQrCode = useCallback(() => {
    dispatch(
      fetchGetActivityUserTrainQrCode({
        activityId: activity.get('activityId'),
        userId,
      })
    );
  }, [activity, dispatch, userId]);

  useEffect(() => {
    handleFetchGetActivityUserTrainQrCode();
  }, [handleFetchGetActivityUserTrainQrCode]);

  useInterval(() => {
    if (!qrCodeToken) return;
    dispatch(
      fetchPostActivityUserModelTrainCheck({
        activityId: activity.get('activityId'),
        qrcodeTokenId: qrCodeToken.get('qrcodeTokenId'),
      })
    );
  }, 5000);

  useEffect(
    () => () => {
      dispatch(
        clearApisResponse([
          fetchGetActivityUserTrainQrCode(),
          fetchPostActivityUserModelTrainCheck(),
        ])
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (activityUserModelTrainCheck) {
      switch (activityUserModelTrainCheck.get('qrCodeStatus')) {
        case 'Active':
          break;
        case 'Success':
          setIsSuccess(true);
          setTimeout(() => {
            setIsRedirect(true);
          }, 3000);
          break;
        case 'Expired':
          dispatch(openDialog(DIALOG));
          break;
        default:
          break;
      }
    }
  }, [activityUserModelTrainCheck, dispatch]);

  if (!userId) {
    return <Redirect to="/not-found" />;
  }

  if (!qrCodeToken) {
    return (
      <FixedCenter>
        <CircularProgress />
      </FixedCenter>
    );
  }

  if (md.mobile() && qrcodeTokenId) {
    return (
      <Redirect
        to={`/train?qrcodeTokenId=${qrcodeTokenId}&activityId=${activity.get(
          'activityId'
        )}`}
      />
    );
  }

  if (isRedirect) {
    return <Redirect to={`/activities/${activity.get('activityId')}`} />;
  }

  return (
    <>
      <RefreshQrCodeAlertDialog
        // @ts-ignore
        // TODO: Need fixed ts-ignore
        primary="QRCode 過期"
        message="QRcode 過期請重新取得一次"
        onConfirm={handleFetchGetActivityUserTrainQrCode}
        onClose={handleFetchGetActivityUserTrainQrCode}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isSuccess}
        variant="success"
        message="報名完成 !"
      />
      <ActivityAppBar />
      <Center offsetTop={64}>
        <div>
          <Box mb={4}>
            <Typography align="center" variant="h6">
              {activity.get('activityName')}
            </Typography>
          </Box>
          <Paper>
            <Box p={4}>
              <div className={classes.imgBox}>
                <RatioImage
                  ratio="1:1"
                  src={s3Url(qrCodeToken.get('qrCodeImagePath'))}
                  alt="qrcode"
                />
              </div>
              <Typography align="center" variant="h5">
                請用手機掃描 QRCode 進行人臉建檔
              </Typography>
            </Box>
          </Paper>
        </div>
      </Center>
    </>
  );
};

export default ActivityApplyTrainQrcode;
