import placeholder from 'static/images/video-placeholder.jpeg';

export type Video = {
  title: string;
  subtitle: string;
  placeholder: string;
  iframeSrc: string;
  iframeTitle: string;
};

export default function getVideoPlayList(): Video[] {
  return [
    {
      title: '活動舉辦',
      subtitle: 'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 活動舉辦',
      placeholder,
      iframeSrc: 'https://www.youtube.com/embed/-IiNFLSUa78',
      iframeTitle: 'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 活動舉辦',
    },
    {
      title: '寄送-活動邀請函&行前通知',
      subtitle: 'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 寄送信件',
      placeholder,
      iframeSrc: 'https://www.youtube.com/embed/GwgG8CVmFlo',
      iframeTitle: 'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 寄送信件',
    },
    {
      title: '活動報名',
      subtitle: 'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 報名活動',
      placeholder,
      iframeSrc: 'https://www.youtube.com/embed/sYcPB-RFfow',
      iframeTitle: 'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 報名活動',
    },
    {
      title: '活動報到',
      subtitle: 'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 活動報到',
      placeholder,
      iframeSrc: 'https://www.youtube.com/embed/hCWy1geznDI',
      iframeTitle: 'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 活動報到',
    },
    {
      title: '活動後-簽到表&參加證明',
      subtitle:
        'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 活動後(簽到表&參加證明)',
      placeholder,
      iframeSrc: 'https://www.youtube.com/embed/EQvurpb2Mhw',
      iframeTitle:
        'MICEPass【Tutorial】│ 專業活動輕鬆辦 - 活動後(簽到表&參加證明)',
    },
    {
      title: '人臉報名及報到',
      subtitle: 'MICEPass【Tutorial】│ 人臉報名及報到',
      placeholder,
      iframeSrc: 'https://www.youtube.com/embed/K_HjF3CcA9I',
      iframeTitle: 'MICEPass【Tutorial】│ 人臉報名及報到',
    },
  ];
}
