import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { Map, OrderedMap, fromJS, List } from 'immutable';
import schemaFieldsTypeMapper from 'utils/schemaFieldsTypeMapper';

/** Actions */
export const {
  fetchPostUserApplyActivity,
  fetchPostUserApplyActivityRequest,
  fetchPostUserApplyActivitySuccess,
  fetchPostUserApplyActivityFailure,

  fetchGetUserDataByEmailTokenId,
  fetchGetUserDataByEmailTokenIdRequest,
  fetchGetUserDataByEmailTokenIdSuccess,
  fetchGetUserDataByEmailTokenIdFailure,

  fetchGetActivitySurveys,
  fetchGetActivitySurveysRequest,
  fetchGetActivitySurveysSuccess,
  fetchGetActivitySurveysFailure,

  fetchGetActivityClasses,
  fetchGetActivityClassesRequest,
  fetchGetActivityClassesSuccess,
  fetchGetActivityClassesFailure,

  fetchGetActivityApplyFields,
  fetchGetActivityApplyFieldsRequest,
  fetchGetActivityApplyFieldsSuccess,
  fetchGetActivityApplyFieldsFailure,

  fetchGetActivitySurveyQuestions,
  fetchGetActivitySurveyQuestionsRequest,
  fetchGetActivitySurveyQuestionsSuccess,
  fetchGetActivitySurveyQuestionsFailure,

  updateStep,
  addStep,
  resetSteps,
  setActiveStep,
  handleNext,
  handlePrev,
} = actionCreators.landingPages.activity.activityApply;

/** Types */

export const FETCH_POST_USER_APPLY_ACTIVITY = fetchPostUserApplyActivity().type;
export const FETCH_POST_USER_APPLY_ACTIVITY_REQUEST = fetchPostUserApplyActivityRequest()
  .type;
export const FETCH_POST_USER_APPLY_ACTIVITY_SUCCESS = fetchPostUserApplyActivitySuccess()
  .type;
export const FETCH_POST_USER_APPLY_ACTIVITY_FAILURE = fetchPostUserApplyActivityFailure()
  .type;

export const FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID = fetchGetUserDataByEmailTokenId()
  .type;
export const FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID_REQUEST = fetchGetUserDataByEmailTokenIdRequest()
  .type;
export const FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID_SUCCESS = fetchGetUserDataByEmailTokenIdSuccess()
  .type;
export const FETCH_GET_USER_DATA_BY_EMAIL_TOKEN_ID_FAILURE = fetchGetUserDataByEmailTokenIdFailure()
  .type;

export const FETCH_GET_ACTIVITY_SURVEYS = fetchGetActivitySurveys().type;
export const FETCH_GET_ACTIVITY_SURVEYS_REQUEST = fetchGetActivitySurveysRequest()
  .type;
export const FETCH_GET_ACTIVITY_SURVEYS_SUCCESS = fetchGetActivitySurveysSuccess()
  .type;
export const FETCH_GET_ACTIVITY_SURVEYS_FAILURE = fetchGetActivitySurveysFailure()
  .type;

export const FETCH_GET_ACTIVITY_CLASSES = fetchGetActivityClasses().type;
export const FETCH_GET_ACTIVITY_CLASSES_REQUEST = fetchGetActivityClassesRequest()
  .type;
export const FETCH_GET_ACTIVITY_CLASSES_SUCCESS = fetchGetActivityClassesSuccess()
  .type;
export const FETCH_GET_ACTIVITY_CLASSES_FAILURE = fetchGetActivityClassesFailure()
  .type;

export const FETCH_GET_ACTIVITY_APPLY_FIELDS = fetchGetActivityApplyFields()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELDS_REQUEST = fetchGetActivityApplyFieldsRequest()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELDS_SUCCESS = fetchGetActivityApplyFieldsSuccess()
  .type;
export const FETCH_GET_ACTIVITY_APPLY_FIELDS_FAILURE = fetchGetActivityApplyFieldsFailure()
  .type;

export const FETCH_GET_ACTIVITY_SURVEY_QUESTIONS = fetchGetActivitySurveyQuestions()
  .type;
export const FETCH_GET_ACTIVITY_SURVEY_QUESTIONS_REQUEST = fetchGetActivitySurveyQuestionsRequest()
  .type;
export const FETCH_GET_ACTIVITY_SURVEY_QUESTIONS_SUCCESS = fetchGetActivitySurveyQuestionsSuccess()
  .type;
export const FETCH_GET_ACTIVITY_SURVEY_QUESTIONS_FAILURE = fetchGetActivitySurveyQuestionsFailure()
  .type;

export const UPDATE_STEP = updateStep().type;
export const ADD_STEP = addStep().type;
export const RESET_STEPS = resetSteps().type;
export const SET_ACTIVE_STEP = setActiveStep().type;
export const HANDLE_NEXT = handleNext().type;
export const HANDLE_PREV = handlePrev().type;

/** Selectors */

/**
 * UiStates
 */
const getUiStates = (state) => state.getIn(['landingPages', 'ActivityApply']);

export const getActiveStep = createSelector(getUiStates, (uiStates) =>
  uiStates.get('activeStep')
);

export const getSteps = createSelector(getUiStates, (uiStates) =>
  uiStates.get('steps')
);

/**
 * ApiStates
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'landingPages', 'activity', 'activityApply'], Map());

const getFetchPostUserApplyActivity = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostUserApplyActivity', Map())
);

const getFetchGetUserDataByEmailTokenId = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetUserDataByEmailTokenId', Map())
);

const getFetchGetActivitySurveys = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetActivitySurveys', Map())
);

const getFetchGetActivityClasses = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetActivityClasses', Map())
);

const getFetchGetActivityApplyFields = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetActivityApplyFields', Map())
);

const getFetchGetActivitySurveyQuestions = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetActivitySurveyQuestions', Map())
);

const getUserApplyActivity = createSelector(
  getFetchPostUserApplyActivity,
  (fetchPost) => fetchPost.get('response')
);

export const getApplyDoneUser = createSelector(
  getUserApplyActivity,
  (userApplyActivity) => {
    if (userApplyActivity) {
      return userApplyActivity.get('user');
    }
    return undefined;
  }
);

export const getIsPosting = createSelector(
  getFetchPostUserApplyActivity,
  (fetchPost) => fetchPost.get('isLoading')
);

export const getUser = createSelector(
  getFetchGetUserDataByEmailTokenId,
  (fetchGet) => fetchGet.get('response')
);

export const getActivitySurveys = createSelector(
  getFetchGetActivitySurveys,
  (fetchGet) => fetchGet.get('response')
);

export const getActivityClasses = createSelector(
  getFetchGetActivityClasses,
  (fetchGet) => fetchGet.get('response')
);

export const getActivityApplyFields = createSelector(
  getFetchGetActivityApplyFields,
  (fetchGet) => fetchGet.get('response')
);

export const getActivitySurveyQuestions = createSelector(
  getFetchGetActivitySurveyQuestions,
  (fetchGet) => fetchGet.get('response')
);

export const getSurveyQuestionSchemaProperties = createSelector(
  getActivitySurveyQuestions,
  (surveyQuestions) => {
    let properties = OrderedMap();
    if (surveyQuestions) {
      surveyQuestions.forEach((surveyQuestion) => {
        const type = schemaFieldsTypeMapper[surveyQuestion.get('questionType')];
        const property = {
          label: surveyQuestion.get('questionName'),
          name: `responseList.${surveyQuestion.get('questionId')}`,
          type,
        };
        switch (type) {
          case 'checkboxGroup':
            property.options = surveyQuestion
              .get('optionList')
              .map((option) => ({
                key: option.get('optionId'),
                label: option.get('optionName'),
                toggleInput: Boolean(option.get('hasInput')),
                name: option.get('optionId'),
              }));
            break;
          case 'radioGroup':
            property.options = surveyQuestion
              .get('optionList')
              .map((option) => ({
                key: option.get('optionId'),
                label: option.get('optionName'),
                toggleInput: Boolean(option.get('hasInput')),
                value: option.get('optionId'),
              }));
            break;
          case 'select':
            property.options = surveyQuestion
              .get('optionList')
              .map((option) => ({
                key: option.get('optionId'),
                label: option.get('optionName'),
                value: option.get('optionId'),
              }));
            property.MuiTextFieldProps = {
              fullWidth: true,
            };
            break;
          default:
            break;
        }
        properties = properties.set(
          surveyQuestion.get('questionId'),
          fromJS(property)
        );
      });
    }
    return properties;
  }
);

const getSurveyQuestionSchemaRequired = createSelector(
  getActivitySurveyQuestions,
  (surveyQuestions) => {
    let required = List();
    if (surveyQuestions) {
      surveyQuestions.forEach((surveyQuestion) => {
        if (surveyQuestion.get('isRequired')) {
          required = required.push(surveyQuestion.get('questionId'));
        }
      });
    }
    return required;
  }
);

export const getSurveyQuestionSchema = createSelector(
  getSurveyQuestionSchemaProperties,
  getSurveyQuestionSchemaRequired,
  (properties, required) =>
    fromJS({
      type: 'object',
      properties,
      required,
    })
);
