import React, { FC } from 'react';
import Button from '@eGroupTeam/material/Button';
import { useDispatch } from 'react-redux';
import {
  Hidden,
  MobileStepper,
  Segment,
  SegmentContent,
  SegmentHeader,
  Typography,
} from '@eGroupTeam/material';
import FroalaEditor from 'components/FroalaEditor';
import { handlePrevStep, handleNextStep } from './actions';
import Toolbar from './Toolbar';

export interface StepEditActivityDetailProps {
  activeStep: number;
  value?: string;
  onModelChange: (model: string) => void;
}

const StepEditActivityDetail: FC<StepEditActivityDetailProps> = ({
  activeStep,
  value,
  onModelChange,
}) => {
  const dispatch = useDispatch();
  const handleNext = () => {
    dispatch(handleNextStep());
  };
  const handlePrev = () => {
    dispatch(handlePrevStep());
  };

  return (
    <>
      <Segment variant="outlined">
        <SegmentHeader>
          <Typography variant="h5">填寫活動內容</Typography>
          <Typography variant="body2" gutterBottom>
            您可以編輯豐富的活動資訊，內容將呈現於活動報名網站。
          </Typography>
        </SegmentHeader>
        <SegmentContent>
          <FroalaEditor
            onModelChange={onModelChange}
            model={value}
            config={{
              heightMin: 300,
            }}
          />
          <Hidden smDown>
            <Toolbar
              activeStep={activeStep}
              onNextClick={handleNext}
              onPrevClick={handlePrev}
              nextDisabled={!value}
            />
          </Hidden>
        </SegmentContent>
      </Segment>
      <Hidden mdUp>
        <MobileStepper
          variant="progress"
          steps={6}
          position="bottom"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              color="primary"
              disabled={!value}
              onClick={handleNext}
            >
              繼續
            </Button>
          }
          backButton={
            <Button size="small" onClick={handlePrev}>
              上一步
            </Button>
          }
        />
      </Hidden>
    </>
  );
};

export default StepEditActivityDetail;
