import { fetcher } from 'redux/apisOrg';
import { OrganizationMember, EntityList } from 'types';
import makeGetHook from '@eGroupTeam/hooks/makeGetHook';

export type PathParams = {
  organizationId?: string;
};
const useOrgMembers = makeGetHook<EntityList<OrganizationMember> | '', PathParams>(
  '/organizations/{{organizationId}}/members',
  fetcher
);
export default useOrgMembers;
