export default function questionToApplyField(question) {
  return {
    applyFieldId: question.questionId,
    applyFieldType: question.questionType,
    applyFieldName: question.questionName,
    isRequired: Number(question.isRequired),
    applyFieldOptionList: question.optionList
      ? question.optionList.map((el) => ({
          applyFieldOptionId: el.optionId,
          applyFieldOptionName: el.optionName,
          applyFieldOptionNo: el.optionNo,
        }))
      : undefined,
  };
}
