import React from 'react';
import { useSelector } from 'react-redux';
import {
  hasSubmitFailed,
  getFormSyncErrors,
  getFormAsyncErrors,
} from 'redux-form/immutable';
import indexPath from './indexPath';

export default function makeIsFormFieldsError(FORM) {
  const submitFailedSelector = (state) => hasSubmitFailed(FORM)(state);
  const formSyncErrorsSelector = (state) => getFormSyncErrors(FORM)(state);
  const formAsyncErrorsSelector = (state) => getFormAsyncErrors(FORM)(state);

  return function useIsFormFieldsError() {
    const submitFailed = useSelector(submitFailedSelector);
    const formSyncErrors = useSelector(formSyncErrorsSelector);
    const formAsyncErrors = useSelector(formAsyncErrorsSelector);

    const isFieldsError = React.useCallback(
      (names) => {
        if (!names) return false;
        const errors = names.map((name) => {
          if (submitFailed) {
            if (
              indexPath(name, formSyncErrors) &&
              indexPath(name, formSyncErrors).length
            ) {
              return true;
            }
            if (formAsyncErrors && indexPath(name, formAsyncErrors)) {
              return true;
            }
          }
          return false;
        });
        return errors.filter(Boolean).length > 0;
      },
      [formAsyncErrors, formSyncErrors, submitFailed]
    );

    return [isFieldsError, submitFailed, formSyncErrors, formAsyncErrors];
  };
}
