import React, { VFC, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core";

import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import { Box, Button } from "@eGroupTeam/material";
import Aos from "@eGroupTeam/material-module/Aos";
import Paper from "@eGroupTeam/material/Paper";
import TextField from "@eGroupTeam/material/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  trailEventSection: {
    padding: "3rem",
    borderRadius: "20px",
    "& h3": {
      fontSize: "1.75rem",
      color: theme.egPalette.primary[1],
      lineHeight: "1.2",
      marginBottom: "0.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
    },
  },
  eventForm: {
    "&>.MuiFormControl-root": {
      paddingTop: "27px",
      width: "100%",
    },
    "& label": {
      marginBottom: theme.spacing(1),
      fontSize: "1rem",
      display: "block",
      color: "#212529",
      top: "-20px",
    },
  },
  footer: {
    borderTop: "1px solid #dee2e6!important",
    paddingTop: theme.spacing(3),
    "& p:nth-child(2)": {
      marginBottom: theme.spacing(2),
    },
    "& button": {
      padding: "12px 20px",
      fontWeight: "600",
      color: theme.egPalette.text[1],
      marginTop: "26px",
      background: "#e5e5e5",
      fontSize: "1rem",

      "&:hover": {
        background: "#e5e5e5",
      },
    },
  },
}));

const BASIC_MP = 19
const SURVEY_MP = 10
const FACE_REC_MP = 15

const TrailEventSection: VFC = () => {
  const classes = useStyles();

  const [survayChecked, setSurvayChecked] = useState(false);
  const [faceChecked, setFaceChecked] = useState(false);
  const [eventDay, setEventDay] = useState(0)
  const [peoplePerDay, setPeoplePerDay] = useState(0)

  const priceForOnePersonPerDay = useMemo(() => {
    let result = 19
    if (survayChecked) {
      result += 10
    }
    if (faceChecked) {
      result += 15
    }
    return result
  }, [faceChecked, survayChecked])

  const total = useMemo(() => eventDay * peoplePerDay * priceForOnePersonPerDay, [eventDay, peoplePerDay, priceForOnePersonPerDay])

  return (
    <Aos variant="slideInRight" config={{ duration: 500 }}>
      <Paper variant="outlined" className={classes.trailEventSection}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" fontWeight={700} align="center">
              活動費用試算
            </Typography>
          </Grid>
          <Box py={2} width="100%">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} className={classes.eventForm}>
                <FormControl>
                  <InputLabel htmlFor="eventDay">活動天數</InputLabel>
                  <TextField fullWidth variant="outlined" id="eventDay" onChange={(e) => setEventDay(Number(e.target.value))}/>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className={classes.eventForm}>
                <FormControl>
                  <InputLabel htmlFor="peoplePerDay">
                    每天人數
                  </InputLabel>
                  <TextField fullWidth variant="outlined" id="peoplePerDay" onChange={(e) => setPeoplePerDay(Number(e.target.value))} />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Typography variant="body1">進階功能</Typography>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={survayChecked}
                    onChange={(_, checked) => setSurvayChecked(checked)}
                    name="remember"
                    color="primary"
                  />
                }
                label="問卷系統(MP 10)"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={faceChecked}
                    onChange={(_, checked) => setFaceChecked(checked)}
                    name="remember"
                    color="primary"
                  />
                }
                label="人臉辨識系統(MP 15)"
              />
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.footer}>
            <Typography variant="body1">基本費用 : MP {BASIC_MP}</Typography>
            <Typography variant="body1">
              問卷系統 : MP {SURVEY_MP}
            </Typography>
            <Typography variant="body1">
              人臉辨識 : MP {FACE_REC_MP}
            </Typography>
            <Typography variant="body1">
              每人/天 : MP {BASIC_MP}{survayChecked && ` + MP ${SURVEY_MP}`}{faceChecked && ` + MP ${FACE_REC_MP}`}{(survayChecked || faceChecked) &&` = MP ${priceForOnePersonPerDay}`}
            </Typography>
            <Button fullWidth>活動服務費 : MP {total}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Aos>
  );
};

export default TrailEventSection;
