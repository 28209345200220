import { createSelector } from 'reselect';
import { Map } from 'immutable';

/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'components', 'routerRoot'], Map());

const getFetchGetOrganizations = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetOrganizations', Map())
);

const getFetchGetQrpassQualify = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetQrpassQualify', Map())
);

const getFetchGetMemberInfo = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetMemberInfo', Map())
);

const getFetchGetMemberModules = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetMemberModules', Map())
);

export const getOrganizations = createSelector(
  getFetchGetOrganizations,
  (fetchGet) => fetchGet.get('response')
);

export const getQrpassQualify = createSelector(
  getFetchGetQrpassQualify,
  (fetchResult) => fetchResult.get('response')
);

export const getMemberInfo = createSelector(
  getFetchGetMemberInfo,
  (fetchResult) => fetchResult.get('response')
);

export const getMemberModules = createSelector(
  getFetchGetMemberModules,
  (fetchResult) => fetchResult.get('response')
);

export const getMemberModuleValues = createSelector(
  getMemberModules,
  (memberModules) =>
    memberModules
      ? memberModules
          .map((el) =>
            el.getIn([
              'organizationRole',
              'serviceModule',
              'serviceModuleValue',
            ])
          )
          .toJS()
      : undefined
);
