import { combineEpics, ofType } from 'redux-observable';
import { fromJS } from 'immutable';
import { of, concat } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { push } from 'connected-react-router/immutable';
import getDeviceInfo from '@eGroupTeam/utils/getDeviceInfo';
import {
  fetchPostActivityUserModelTrain,
  fetchPostActivityUserModelTrainRequest,
  fetchPostActivityUserModelTrainSuccess,
  fetchPostActivityUserModelTrainFailure,
  fetchPostActivityUserModelTrainCheckRequest,
  fetchPostActivityUserModelTrainCheckSuccess,
  fetchPostActivityUserModelTrainCheckFailure,
  fetchPostFaceDetectionRequest,
  fetchPostFaceDetectionSuccess,
  fetchPostFaceDetectionFailure,
  setIsStopSnapshot,
  FETCH_POST_ACTIVITY_USER_MODEL_TRAIN,
  FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK,
  FETCH_POST_FACE_DETECTION,
} from './redux';

export const fetchPostActivityUserModelTrainEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_USER_MODEL_TRAIN,
  apiName: 'landingPage.fetchPostActivityUserModelTrain',
  fetchRequest: fetchPostActivityUserModelTrainRequest,
  handleSuccess: (response, { action }) => [
    fetchPostActivityUserModelTrainSuccess(fromJS(response.data)),
    push(
      `/activities/${response.data.activity.activityId}/apply-done?type=train&qrcodeTokenId=${action.payload.qrcodeTokenId}`
    ),
  ],
  handleFailure: (error, { state$, action, apiErrorsHandler }) => {
    // Filter errors when qr code is expired.
    if (error.response.status === 403) {
      return concat(
        of(setIsStopSnapshot(false)),
        of(fetchPostActivityUserModelTrainFailure(error))
      );
    }
    return concat(
      of(setIsStopSnapshot(false)),
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityUserModelTrainFailure(error))
    );
  },
});

export const fetchPostActivityUserModelTrainCheckEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ACTIVITY_USER_MODEL_TRAIN_CHECK,
  apiName: 'landingPage.fetchPostActivityUserModelTrainCheck',
  fetchRequest: fetchPostActivityUserModelTrainCheckRequest,
  handleSuccess: (response, { apis }) => {
    apis.tools.createLog({
      browserDescription: window.navigator.userAgent,
      jsonData: {
        userId: response.data.userId,
        deviceInfo: getDeviceInfo(),
      },
      level: 'INFO',
    });
    return [fetchPostActivityUserModelTrainCheckSuccess(fromJS(response.data))];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostActivityUserModelTrainCheckFailure(error))
    ),
});

export const fetchPostFaceDetectionEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_FACE_DETECTION,
  apiName: 'landingPage.fetchPostFaceDetection',
  observableMap: mergeMap,
  fetchRequest: fetchPostFaceDetectionRequest,
  handleTakeUntil: (action$) =>
    takeUntil(action$.pipe(ofType(FETCH_POST_ACTIVITY_USER_MODEL_TRAIN))),
  handleSuccess: (response, { action }) => {
    if (
      response.data.faceQualityLowLuminance === 'Pass' &&
      response.data.faceQualityHeadpose === 'Pass'
    ) {
      const { activityId, qrcodeTokenId, formData } = action.payload;
      return [
        fetchPostFaceDetectionSuccess(fromJS(response.data)),
        setIsStopSnapshot(true),
        fetchPostActivityUserModelTrain({
          activityId,
          qrcodeTokenId,
          formData,
        }),
      ];
    }
    return [fetchPostFaceDetectionSuccess(fromJS(response.data))];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostFaceDetectionFailure(error))
    ),
});

export default combineEpics(
  fetchPostActivityUserModelTrainEpic,
  fetchPostActivityUserModelTrainCheckEpic,
  fetchPostFaceDetectionEpic
);
