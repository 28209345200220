import React, { forwardRef, HTMLAttributes } from 'react';

import { withStyles, createStyles, WithStyles } from '@eGroupTeam/material';
import clsx from 'clsx';

const styles = () =>
  createStyles({
    root: {
      display: 'inline-block',
      cursor: 'pointer',
      outline: 'none',
    },
  });

export interface ShareButtonProps
  extends HTMLAttributes<HTMLDivElement>,
    WithStyles<typeof styles> {
  /**
   * Set href to open window.
   */
  href?: string;
}

const ShareButton = forwardRef<HTMLDivElement, ShareButtonProps>(
  (props, ref) => {
    const { className, classes, href, onClick, ...other } = props;

    const handleClick = (e) => {
      if (onClick) {
        onClick(e);
      }
      if (href) {
        window.open(href, '', 'height=600px, width=550px');
      }
    };

    return (
      <div
        className={clsx(className, classes.root)}
        ref={ref}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}
        {...other}
      />
    );
  }
);

export default withStyles(styles)(ShareButton);
