import { fetcher } from 'redux/apisOrg';
import { MicepointTrialFee } from 'types';
import makePostHook from '@eGroupTeam/hooks/makePostHook';

export type PathParams = {
  organizationId?: string;
};
const useActivityTrailFee = makePostHook<MicepointTrialFee, PathParams>(
  '/organizations/{{organizationId}}/activities/trial-fee',
  fetcher
);
export default useActivityTrailFee;
