import React, { FC } from 'react';
import EgDataTable, {
  DataTableProps,
} from '@eGroupTeam/material-module/DataTable';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      padding: theme.spacing(2, 2.5),
    },
  });

const DataTable: FC<DataTableProps & WithStyles<typeof styles>> = ({
  TitleTypographyProps,
  ...other
}) => (
  <EgDataTable
    {...other}
    TitleTypographyProps={{
      variant: 'h5',
      ...TitleTypographyProps,
    }}
  />
);

export default withStyles(styles)(DataTable);
