import React from "react";

import Main from "@eGroupTeam/material-layout/Main";
import s3Url from "utils/s3Url";
import { useDispatch } from "react-redux";
import { openDialog } from "@eGroupTeam/redux-modules/immutable/dialogs";
import { useParams } from "react-router";
import {
  makeStyles,
  AppBar,
  Container,
  Paper,
  Grid,
  Typography,
  Button,
  Hidden,
  Tabs,
  Tab,
  Avatar,
  Box,
  Icomoon,
  Segment,
  SegmentHeader,
  SegmentContent,
  Link,
} from "@eGroupTeam/material";
import useHandleActivityShare from "utils/useHandleActivityShare";
import { Activity, ActivityClass, UrlParams } from "types";

import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import RatioImage from "@eGroupTeam/material/RatioImage";

import ActivityShareDialog from "components/ActivityShareDialog";
import AppToolBar from "components/AppToolBar";
import useTab from "@eGroupTeam/hooks/useTab";
import { format } from "@eGroupTeam/utils/dateUtils";
import clsx from "clsx";
import ResponsiveEmbed from "@eGroupTeam/material-module/ResponsiveEmbed";
import MultilineArea from "@eGroupTeam/material/MultilineArea";
import ActivityApplyPotentialDialog, {
  DIALOG,
} from "../ActivityApplyPotentialDialog";

const API_KEY = "AIzaSyAoB8CIYtqVImRA-lPcmzUNOfpPpt_HrrE";

const useStyles = makeStyles((theme) => ({
  navbar: {
    background: "transparent",
    borderBottom: "solid 1px #dbdde3",
  },
  main: {
    padding: theme.spacing(4),
  },
  subHeader: {
    borderBottom: 0,
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: 0,
  },
  map: {
    width: "100%",
    margin: theme.spacing(2, 0),
  },
  paper: {
    boxShadow: theme.egShadows[1],
    padding: theme.spacing(3),
    color: "#898989",
  },
  topBoxButton: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    "& [class^='EgButton-root']:first-child": {
      marginRight: theme.spacing(1),
    },
    "& [class^='EgButton-root']": {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& [class^='EgButton-root']:first-child": {
        marginRight: 0,
      },
      "& [class^='EgButton-root']": {
        display: "block",
        marginBottom: theme.spacing(1),
      },
      "& button": {
        width: "100%",
      },
    },
  },
  orgAvatar: {
    width: 55,
    height: 55,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  paperOrg: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  iconInfo: {
    fontSize: 50,
  },
  paperInfo: {
    display: "flex",
  },
  logoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
    },
  },
  titleHeadIconBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  titleHeadIconText: {
    paddingLeft: theme.spacing(1),
    "& span": {
      color: "#898989",
      fontSize: 12,
    },
  },
  datelist: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  gridIforBox: {
    display: "flex",
    flexDirection: "column",
  },
  paperFlex1: {
    flex: 1,
  },
  buttonFull: {
    "& button": {
      padding: "10px 20px",
      width: 220,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  attachedList: {
    display: "flex",
    alignItems: "center",
  },
  attachedIconBox: {
    marginRight: theme.spacing(1),
    display: "inline-flex",
    boxShadow: theme.egShadows[1],
    padding: 3,
    borderRadius: 100,
    backgroundColor: "#dadada",
  },
  attachedIcon: {
    fontSize: 30,
  },
  applyActivityMobile: {
    padding: theme.spacing(2, 3),
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

const ActivityInfo = ({ activity: activityProp }) => {
  const activity: Activity = activityProp.toJS();
  const history = useHistory();
  const dispatch = useDispatch();
  const { activityId } = useParams<UrlParams>();
  const classes = useStyles();
  const [handleShareClick, url] = useHandleActivityShare(activity);
  const { activityClassList } = activity;
  const isEnd = Boolean(activity.isEnd);
  const isFull = Boolean(activity.isFull);
  const isRelease = Boolean(activity.isRelease);
  const { value, handleChange } = useTab("activity");

  const renderClassDescription = (activityClass: ActivityClass) => (
    <Grid item xs={12} sm={6} className={classes.gridIforBox}>
      <Box className={classes.titleHeadIconBox}>
        <Icomoon type="computer-info" className={classes.iconInfo} />
        <Box className={classes.titleHeadIconText}>
          <Typography variant="h6" fontWeight={700}>
            場次述敘
          </Typography>
        </Box>
      </Box>
      <Paper className={clsx(classes.paper, classes.paperFlex1)}>
        <MultilineArea
          text={activityClass.activityClassDescription}
          variant="body1"
        />
      </Paper>
    </Grid>
  );

  const renderClassDayList = (activityClass: ActivityClass) => (
    <Grid item xs={12} sm={6} className={classes.gridIforBox}>
      <Box className={classes.titleHeadIconBox}>
        <Icomoon type="calendar-check" className={classes.iconInfo} />
        <Box className={classes.titleHeadIconText}>
          <Typography variant="h6" fontWeight={700}>
            進行時間
          </Typography>
        </Box>
      </Box>
      <Paper className={clsx(classes.paper, classes.paperFlex1)}>
        <ul className={classes.datelist}>
          {activityClass.activityClassDayList.map((activityClassDay) => {
            const date = format(
              activityClassDay.activityClassDayStartTimeString,
              "PPPP"
            );
            const startTime = format(
              activityClassDay.activityClassDayStartTimeString,
              "p"
            );
            const endTime = format(
              activityClassDay.activityClassDayEndTimeString,
              "p"
            );
            return (
              <li key={activityClassDay.activityClassDayId}>
                {date} {startTime} ~ {endTime}
                <br />
              </li>
            );
          })}
        </ul>
      </Paper>
    </Grid>
  );

  const renderClassOnlineMeeting = (activityClass: ActivityClass) => {
    if (!activityClass.activityClassMeetingUrl) return undefined;
    return (
      <Grid item xs={12} sm={6} className={classes.gridIforBox}>
        <Box className={classes.titleHeadIconBox}>
          <Icomoon type="people-setting_2" className={classes.iconInfo} />
          <Box className={classes.titleHeadIconText}>
            <Typography variant="h6" fontWeight={700}>
              線上活動
            </Typography>
          </Box>
        </Box>
        <Paper className={clsx(classes.paper, classes.paperFlex1)}>
          <Typography variant="body1">
            報名參加後將會提供線上活動連結
          </Typography>
        </Paper>
      </Grid>
    );
  };

  const renderClassPlace = (activityClass: ActivityClass) => {
    if (!activityClass.activityClassPlace) return undefined;
    return (
      <Grid item xs={12}>
        <Box className={classes.titleHeadIconBox}>
          <Icomoon type="map" className={classes.iconInfo} />
          <Box className={classes.titleHeadIconText}>
            <Typography variant="h6" fontWeight={700}>
              {activityClass.activity.activityType === 1 ? "活動" : "活動"}地點
            </Typography>
            <span>{activityClass.activityClassPlace}</span>
          </Box>
        </Box>
        <ResponsiveEmbed ratio="1700:550" className={classes.map}>
          <iframe
            className={classes.iframe}
            frameBorder="0"
            src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${activityClass.activityClassCity}, ${activityClass.activityClassArea}, ${activityClass.activityClassAddress}`}
            allowFullScreen
            title="google map address"
          />
        </ResponsiveEmbed>
      </Grid>
    );
  };

  const renderClasses = () => {
    if (activityClassList.length > 1) {
      return (
        <>
          {activityClassList.map(
            (el, i) =>
              value === i && (
                <>
                  {renderClassDescription(el)}
                  {renderClassOnlineMeeting(el)}
                  {renderClassDayList(el)}
                  {renderClassPlace(el)}
                </>
              )
          )}
        </>
      );
    }
    const el = activityClassList[0];
    if (el) {
      return (
        <>
          {renderClassOnlineMeeting(el)}
          {renderClassDayList(el)}
          {renderClassPlace(el)}
        </>
      );
    }
    return undefined;
  };

  const renderBtnText = () => {
    if (!isRelease) {
      return "尚未開放報名";
    }
    if (isEnd) {
      return "已結束";
    }
    if (isFull) {
      return "已額滿";
    }
    return "馬上報名";
  };

  return (
    <>
      <Helmet>
        <title>{activity.activityName}</title>
        <meta property="og:url" content={url} />
        <meta property="og:image" content={s3Url(activity.activityCoverPath)} />
        <meta
          property="og:description"
          content={activity.activityDescription}
        />
      </Helmet>
      <ActivityApplyPotentialDialog activityId={activityId} />
      <ActivityShareDialog activity={activity} />
      <AppBar elevation={0} position="static" className={classes.navbar}>
        <AppToolBar />
      </AppBar>
      <Main>
        <Container>
          <Paper className={classes.main}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <RatioImage
                  src={s3Url(activity.activityCoverPath)}
                  ratio="840:620"
                  alt={activity.activityName}
                  fit="cover"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid
                  container
                  item
                  direction="column"
                  style={{ height: "100%" }}
                >
                  <Grid item>
                    <Box className={classes.topBoxButton}>
                      <Button
                        variant="contained"
                        rounded
                        color="primary"
                        startIcon={<Icomoon type="people-setting_2" />}
                        onClick={handleShareClick}
                      >
                        分享活動
                      </Button>
                      <Button
                        variant="contained"
                        rounded
                        color="warning"
                        startIcon={<Icomoon type="people-setting_2" />}
                        onClick={() => dispatch(openDialog(DIALOG))}
                      >
                        聯絡主辦單位
                      </Button>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={700}>
                        {activity.activityName}
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Paper className={clsx(classes.paper, classes.paperInfo)}>
                        <Icomoon
                          type="info"
                          color="info"
                          className={classes.iconInfo}
                        />
                        <Box pl={1}>{activity.activityDescription}</Box>
                      </Paper>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mb={1}>
                      <Paper className={clsx(classes.paper, classes.paperOrg)}>
                        <Box className={classes.logoBox}>
                          <Avatar
                            className={classes.orgAvatar}
                            src={s3Url(
                              activity.organization.organizationLogoPath
                            )}
                          />
                          <span>{activity.organization.organizationName}</span>
                        </Box>
                        <Button
                          className={classes.buttonFull}
                          size="large"
                          variant="contained"
                          rounded
                          color="info"
                          onClick={() => {
                            history.push(
                              `/activities/${activityId}/select-apply-class`
                            );
                          }}
                          disabled={!isRelease || isEnd || isFull}
                        >
                          {renderBtnText()}
                        </Button>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {activityClassList.length > 1 && (
                <Grid item xs={12}>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={(_, v) => handleChange(v)}
                  >
                    {activityClassList.map((el) => (
                      <Tab label={el.activityClassName} />
                    ))}
                  </Tabs>
                </Grid>
              )}
              {renderClasses()}
              <Grid item xs={12}>
                <Segment>
                  <SegmentHeader>
                    <Typography variant="h5" fontWeight={700}>
                      活動介紹
                    </Typography>
                  </SegmentHeader>
                  <SegmentContent style={{ overflow: "auto" }}>
                    <FroalaEditorView model={activity.activityContentEditor} />
                  </SegmentContent>
                </Segment>
              </Grid>
              {activity.activityFileList && (
                <Grid item xs={12}>
                  <Box className={classes.titleHeadIconBox}>
                    <Icomoon
                      type="computer-info"
                      className={classes.iconInfo}
                    />
                    <Box className={classes.titleHeadIconText}>
                      <Typography variant="h6" fontWeight={700}>
                        檔案下載
                      </Typography>
                      <span />
                    </Box>
                  </Box>
                  <Paper className={classes.paper}>
                    <Grid container>
                      {activity.activityFileList.map((el) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={2}
                          key={el.activityFileId}
                        >
                          <Link
                            href={s3Url(el.activityFilePath)}
                            target="_blank"
                            download
                            rel="noreferrer"
                            underline="none"
                            color="textPrimary"
                          >
                            <div className={classes.attachedList}>
                              <Box className={classes.attachedIconBox}>
                                <Icomoon
                                  type="people-setting_2"
                                  className={classes.attachedIcon}
                                />
                              </Box>
                              {el.activityFileName}
                            </div>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Container>
      </Main>
      <Hidden mdUp>
        <Paper elevation={10} className={classes.applyActivityMobile}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body2">
                主辦單位：
                {activity.organization.organizationName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  history.push(`/activities/${activityId}/select-apply-class`);
                }}
                disabled={!isRelease || isEnd || isFull}
                fullWidth
              >
                {renderBtnText()}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Hidden>
    </>
  );
};

export default ActivityInfo;
