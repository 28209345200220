import React, { VFC } from "react";
import { makeStyles } from "@material-ui/core";

import Container from "@eGroupTeam/material/Container";
import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import Box from "@eGroupTeam/material/Box";
import Aos from "@eGroupTeam/material-module/Aos";

import referenceSvg from "./images/reference.svg";
import Header from "../components/Header";
import Bounce from "../components/Bounce";

import ContentTypeSection from "./ContentTypeSection";

const useStyles = makeStyles((theme) => ({
  textContents: {
    marginTop: "5rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  subTitle: {
    color: theme.egPalette.primary[1],
    marginBottom: "0",
    fontSize: "20px",
  },
  title: {
    color: "#42495b",
    fontSize: "31px",
    marginBottom: "0.5rem",
    lineHeight: "1.2",
  },
  description: {
    color: "#444444",
  },
  middleSection: {
    marginTop: "4rem",
  },
  referenceHeader: {
    "& h2": {
      fontSize: "2rem",
      lineHeight: "1.2",
    },
  },
}));

const Reference: VFC = () => {
  const classes = useStyles();

  return (
    <>
      <Header>
        <Typography
          variant="h2"
          fontWeight={700}
          color="inherit"
          align="center"
        >
          活動花絮
        </Typography>
      </Header>
      <Container className={classes.middleSection}>
        <Box py={2}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <Aos variant="slideInLeft" config={{ duration: 500 }}>
                <Bounce>
                  <Box textAlign="center">
                    <img src={referenceSvg} alt="Reference" width="80%" />
                  </Box>
                </Bounce>
              </Aos>
            </Grid>
            <Grid item sm={12} md={6}>
              <Aos variant="slideInRight" config={{ duration: 500 }}>
                <Box className={classes.textContents}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    className={classes.subTitle}
                  >
                    智能平台，人性互動
                  </Typography>
                  <Typography
                    variant="h2"
                    fontWeight={700}
                    className={classes.title}
                  >
                    讓客戶體驗更棒的活動，紀錄活動精彩時刻
                  </Typography>
                  <Typography variant="body1" className={classes.description}>
                    智能化的平台，讓客戶收到即時資訊、體驗更快速的報到動線 ; 客戶需要經營，好的平台讓你更輕省，客戶更滿意。
                    <br />
                    <br />
                    線上課程、實體活動、會議、展覽、教育訓練、團體旅遊、課程，各種活動應用透過 MICEPass 輕鬆舉辦，好的體驗、讓你的客戶愛上你的活動。
                  </Typography>
                </Box>
              </Aos>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ContentTypeSection />
    </>
  );
};

export default Reference;
