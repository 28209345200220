import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { getSelectedOrg } from 'redux/modules/common';
import { format } from '@eGroupTeam/utils/dateUtils';

import { Link, useHistory } from 'react-router-dom';
import Center from '@eGroupTeam/material-layout/Center';
import ButtonMenu from '@eGroupTeam/material-module/ButtonMenu';
import Main from '@eGroupTeam/material-layout/Main';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {
  Box,
  Button,
  Container,
  MenuItem,
  Tooltip,
  Typography,
} from '@eGroupTeam/material';
import GridOnIcon from '@material-ui/icons/GridOn';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FixedFab from 'components/FixedFab';
import IntroduceCard from 'components/IntroduceCard';
import IntroduceCardHeader from 'components/IntroduceCardHeader';
import IntroduceCardContent from 'components/IntroduceCardContent';
import IntroduceCardActions from 'components/IntroduceCardActions';
import useOrgActivities from 'utils/useOrgActivities';
import { getMemberModuleValues } from 'components/RouterRoot/selectors';
import ActivityCards from './ActivityCards';
import ActivityList from './ActivityList';

const Activities = () => {
  const history = useHistory();
  const selectedOrg = useSelector(getSelectedOrg);
  const [layout, setLayout] = useState('grid');
  const [filterType, setFilterType] = useState('all');
  const memberModuleValues = useSelector(getMemberModuleValues);
  const { data, response } = useOrgActivities(
    {
      organizationId: selectedOrg.get('organizationId'),
    },
    {
      activeDate:
        filterType === 'todayActivities'
          ? format(new Date(), 'yyyy-MM-dd')
          : undefined,
    }
  );

  const handleChange = (event, newLayout) => {
    setLayout(newLayout);
  };

  const handleChnageFilterType = (filterType) => () => {
    setFilterType(filterType);
  };

  const renderContent = () => {
    if (response?.status === 204 && filterType === 'todayActivities') {
      return (
        <Center offsetTop={176}>
          <Typography variant="h5" align="center">
            您尚未擁有進行中的活動。
          </Typography>
        </Center>
      );
    }
    if (layout === 'list') {
      return <ActivityList activities={data?.source} />;
    }
    return <ActivityCards activities={data?.source} />;
  };

  const renderFilterMenuText = () => {
    if (filterType === 'todayActivities') {
      return '本日活動';
    }
    return '全部活動';
  };

  if (response?.status === 204 && filterType === 'all') {
    return (
      <Main>
        <Container maxWidth="sm">
          <IntroduceCard>
            <IntroduceCardHeader title="舉辦活動" />
            <IntroduceCardContent>
              <Typography variant="body1">
                專業活動輕鬆辦，MICEPass 讓活動更精彩！
              </Typography>
            </IntroduceCardContent>
            <IntroduceCardActions>
              {memberModuleValues.includes('activityEdit') && (
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  fullWidth
                  onClick={() => {
                    history.push('/me/new-activity');
                  }}
                >
                  舉辦活動
                </Button>
              )}
            </IntroduceCardActions>
          </IntroduceCard>
        </Container>
      </Main>
    );
  }

  return (
    <>
      {memberModuleValues.includes('activityEdit') && (
        <Tooltip title="新增活動">
          <FixedFab to="/me/new-activity" component={Link} color="primary">
            <AddIcon />
          </FixedFab>
        </Tooltip>
      )}
      <Main>
        <Container maxWidth={false}>
          <Box mb={1} display="flex">
            <ButtonMenu
              id="activity-filter"
              button={
                <Button
                  variant="contained"
                  rounded
                  color="white"
                  endIcon={<ArrowDropDownIcon />}
                >
                  活動篩選：{renderFilterMenuText()}
                </Button>
              }
            >
              <MenuItem
                button
                onClick={handleChnageFilterType('all')}
                selected={filterType === 'all'}
              >
                全部活動
              </MenuItem>
              <MenuItem
                button
                onClick={handleChnageFilterType('todayActivities')}
                selected={filterType === 'todayActivities'}
              >
                本日活動
              </MenuItem>
              {/* <MenuItem
                button
                onClick={handleChnageFilterType('signingUpActivities')}
                selected={filterType === 'signingUpActivities'}
              >
                報名中活動
              </MenuItem> */}
            </ButtonMenu>
            <Box flexGrow={1} />
            <ToggleButtonGroup
              exclusive
              size="small"
              onChange={handleChange}
              value={layout}
            >
              <ToggleButton key={1} value="grid">
                <GridOnIcon />
              </ToggleButton>
              <ToggleButton key={2} value="list">
                <ListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {renderContent()}
        </Container>
      </Main>
    </>
  );
};

export default Activities;
