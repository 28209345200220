import actionCreators from 'redux/actionCreators';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Map } from 'immutable';
import * as schema from 'redux/schema';
import { getEntities } from '@eGroupTeam/redux-modules/immutable/entities';

/** Actions */
export const {
  fetchGetSurvey,
  fetchGetSurveyRequest,
  fetchGetSurveySuccess,
  fetchGetSurveyFailure,
} = actionCreators.mainPages.survey;

/** Types */
export const FETCH_GET_SURVEY = fetchGetSurvey().type;
export const FETCH_GET_SURVEY_REQUEST = fetchGetSurveyRequest().type;
export const FETCH_GET_SURVEY_SUCCESS = fetchGetSurveySuccess().type;
export const FETCH_GET_SURVEY_FAILURE = fetchGetSurveyFailure().type;

/** Selectors */
/**
 * API States
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'survey'], Map());

const getFetchGetSurvey = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetSurvey', Map())
);

export const getSurvey = createSelector(
  getEntities,
  getFetchGetSurvey,
  (entities, fetchGet) =>
    denormalize(fetchGet.get('response'), schema.survey, entities)
);
