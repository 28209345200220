import React, { FC } from 'react';
import {
  createStyles,
  ListItemTextProps,
  WithStyles,
  withStyles,
  ListItemText,
  Theme,
  useTheme,
  useMediaQuery,
} from '@eGroupTeam/material';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      wordWrap: 'break-word',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 300,
      },
    },
  });

export interface ListItemBreakTextProps extends ListItemTextProps {
  /**
   * Set `true` to eclipse text when window size is dowm sm breakpoint.
   */
  noWrap?: boolean;
}

const ListItemBreakText: FC<
  ListItemBreakTextProps & WithStyles<typeof styles>
> = (props) => {
  const { classes, noWrap, ...other } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ListItemText
      primaryTypographyProps={{
        className: classes.root,
        noWrap: matches && noWrap,
      }}
      {...other}
    />
  );
};

export default withStyles(styles)(ListItemBreakText);
