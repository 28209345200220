import actionCreators from 'redux/actionCreators';
import { List, Map } from 'immutable';
import { getEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import { createSelector } from 'reselect';

/** Actions */
export const {
  fetchGetActivityStudyProve,
  fetchGetActivityStudyProveRequest,
  fetchGetActivityStudyProveSuccess,
  fetchGetActivityStudyProveFailure,

  fetchPatchActivityStudyProve,
  fetchPatchActivityStudyProveRequest,
  fetchPatchActivityStudyProveSuccess,
  fetchPatchActivityStudyProveFailure,

  fetchPostActivityStudyProveGenerator,
  fetchPostActivityStudyProveGeneratorRequest,
  fetchPostActivityStudyProveGeneratorSuccess,
  fetchPostActivityStudyProveGeneratorFailure,

  fetchGetActivityEdmUsers,
  fetchGetActivityEdmUsersRequest,
  fetchGetActivityEdmUsersSuccess,
  fetchGetActivityEdmUsersFailure,

  fetchPostNotificationEmail,
  fetchPostNotificationEmailRequest,
  fetchPostNotificationEmailSuccess,
  fetchPostNotificationEmailFailure,

  fetchPostTestNotificationEmail,
  fetchPostTestNotificationEmailRequest,
  fetchPostTestNotificationEmailSuccess,
  fetchPostTestNotificationEmailFailure,

  fetchPostPreviewEdm,
  fetchPostPreviewEdmRequest,
  fetchPostPreviewEdmSuccess,
  fetchPostPreviewEdmFailure,

  setDirectNotificationActiveStep,
  handleDirectNotificationNext,
  handleDirectNotificationPrev,
} = actionCreators.mainPages.activity.activityCertificates;

/** Types */
export const FETCH_GET_ACTIVITY_STUDY_PROVE = fetchGetActivityStudyProve().type;
export const FETCH_GET_ACTIVITY_STUDY_PROVE_REQUEST = fetchGetActivityStudyProveRequest()
  .type;
export const FETCH_GET_ACTIVITY_STUDY_PROVE_SUCCESS = fetchGetActivityStudyProveSuccess()
  .type;
export const FETCH_GET_ACTIVITY_STUDY_PROVE_FAILURE = fetchGetActivityStudyProveFailure()
  .type;

export const FETCH_PATCH_ACTIVITY_STUDY_PROVE = fetchPatchActivityStudyProve()
  .type;
export const FETCH_PATCH_ACTIVITY_STUDY_PROVE_REQUEST = fetchPatchActivityStudyProveRequest()
  .type;
export const FETCH_PATCH_ACTIVITY_STUDY_PROVE_SUCCESS = fetchPatchActivityStudyProveSuccess()
  .type;
export const FETCH_PATCH_ACTIVITY_STUDY_PROVE_FAILURE = fetchPatchActivityStudyProveFailure()
  .type;

export const FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR = fetchPostActivityStudyProveGenerator()
  .type;
export const FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR_REQUEST = fetchPostActivityStudyProveGeneratorRequest()
  .type;
export const FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR_SUCCESS = fetchPostActivityStudyProveGeneratorSuccess()
  .type;
export const FETCH_POST_ACTIVITY_STUDY_PROVE_GENERATOR_FAILURE = fetchPostActivityStudyProveGeneratorFailure()
  .type;

export const FETCH_GET_ACTIVITY_EDM_USERS = fetchGetActivityEdmUsers().type;
export const FETCH_GET_ACTIVITY_EDM_USERS_REQUEST = fetchGetActivityEdmUsersRequest()
  .type;
export const FETCH_GET_ACTIVITY_EDM_USERS_SUCCESS = fetchGetActivityEdmUsersSuccess()
  .type;
export const FETCH_GET_ACTIVITY_EDM_USERS_FAILURE = fetchGetActivityEdmUsersFailure()
  .type;

export const FETCH_POST_NOTIFICATION_EMAIL = fetchPostNotificationEmail().type;
export const FETCH_POST_NOTIFICATION_EMAIL_REQUEST = fetchPostNotificationEmailRequest()
  .type;
export const FETCH_POST_NOTIFICATION_EMAIL_SUCCESS = fetchPostNotificationEmailSuccess()
  .type;
export const FETCH_POST_NOTIFICATION_EMAIL_FAILURE = fetchPostNotificationEmailFailure()
  .type;

export const FETCH_POST_TEST_NOTIFICATION_EMAIL = fetchPostTestNotificationEmail()
  .type;
export const FETCH_POST_TEST_NOTIFICATION_EMAIL_REQUEST = fetchPostTestNotificationEmailRequest()
  .type;
export const FETCH_POST_TEST_NOTIFICATION_EMAIL_SUCCESS = fetchPostTestNotificationEmailSuccess()
  .type;
export const FETCH_POST_TEST_NOTIFICATION_EMAIL_FAILURE = fetchPostTestNotificationEmailFailure()
  .type;

export const FETCH_POST_PREVIEW_EDM = fetchPostPreviewEdm().type;
export const FETCH_POST_PREVIEW_EDM_REQUEST = fetchPostPreviewEdmRequest().type;
export const FETCH_POST_PREVIEW_EDM_SUCCESS = fetchPostPreviewEdmSuccess().type;
export const FETCH_POST_PREVIEW_EDM_FAILURE = fetchPostPreviewEdmFailure().type;

export const SET_DIRECT_NOTIFICATION_ACTIVE_STEP = setDirectNotificationActiveStep()
  .type;
export const HANDLE_DIRECT_NOTIFICATION_NEXT = handleDirectNotificationNext()
  .type;
export const HANDLE_DIRECT_NOTIFICATION_PREV = handleDirectNotificationPrev()
  .type;

/** Selectors */
/**
 * Ui states
 */
const getUiStates = (state) =>
  state.getIn(['mainPages', 'ActivityCertificates']);

export const getDirectNotificationActiveStep = createSelector(
  getUiStates,
  (uiStates) => uiStates.get('activeDirectNotificationStep')
);

export const getDirectNotificationActiveStepMax = createSelector(
  getUiStates,
  (uiStates) => uiStates.get('activeDirectNotificationStepMax')
);

/**
 * Api states
 */
const getApiStates = (state) =>
  state.getIn(['apis', 'mainPages', 'activity', 'activityCertificates'], Map());

const getFetchGetActivityStudyProve = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchGetActivityStudyProve', Map())
);

const getFetchPatchActivityStudyProve = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPatchActivityStudyProve', Map())
);

const getFetchPostActivityStudyProveGenerator = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostActivityStudyProveGenerator', Map())
);

const getFetchGetActivityEdmUsers = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchGetActivityEdmUsers', Map())
);

const getFetchPostNotificationEmail = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostNotificationEmail', Map())
);

const getFetchPostTestNotificationEmail = createSelector(
  getApiStates,
  (apiStates) => apiStates.get('fetchPostTestNotificationEmail', Map())
);

const getFetchPostPreviewEdm = createSelector(getApiStates, (apiStates) =>
  apiStates.get('fetchPostPreviewEdm', Map())
);

export const getActivityStudyProve = createSelector(
  getFetchGetActivityStudyProve,
  (fetchResult) => fetchResult.get('response')
);

export const getStudyProveIsPatching = createSelector(
  getFetchPatchActivityStudyProve,
  (fetchPatch) => fetchPatch.get('isLoading')
);

export const getStudyProveGeneratorIsPosting = createSelector(
  getFetchPostActivityStudyProveGenerator,
  (fetchPost) => fetchPost.get('isLoading')
);

export const getActivityEdmUsers = createSelector(
  getFetchGetActivityEdmUsers,
  getEntities,
  (fetchResult) => {
    if (fetchResult.has('response')) {
      return fetchResult
        .get('response')
        .map((el) =>
          el.set('label', el.get('userName')).set('value', el.get('userId'))
        );
    }
    return List();
  }
);

export const getEmailIsSending = createSelector(
  getFetchPostNotificationEmail,
  (fetchResult) => fetchResult.get('isLoading')
);

export const getTestEmailIsSending = createSelector(
  getFetchPostTestNotificationEmail,
  (fetchResult) => fetchResult.get('isLoading')
);

export const getPreviewEdm = createSelector(
  getFetchPostPreviewEdm,
  (fetchGet) => fetchGet.get('response')
);

export const getIsPreviewPosting = createSelector(
  getFetchPostPreviewEdm,
  (fetchPost) => fetchPost.get('isLoading')
);
