import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@eGroupTeam/material";
import Typography from "@eGroupTeam/material/Typography";
import DropDown, { DropDownProps } from "@eGroupTeam/material/DropDown";
import Grid from "@eGroupTeam/material/Grid";
import Currency from "components/Currency";

const useStyles = makeStyles((theme) => ({
  pointItem: {
    padding: theme.spacing(3, 3, 2),
    borderTop: "1px solid #DBDDE3",
  },
  titleCell: {
    display: "flex",
    alignItems: "center",
  },
  valueCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "end",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px",
    },
  },
  quantityCell: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "end",
    },
  },
  dropdown: {
    boxShadow: "none",
    "& .MuiButtonGroup-root": {
      height: "unset",
    },
    "& .MuiButtonGroup-grouped": {
      border: "0",
      borderBottom: "1px solid #DBDDE3",
      borderRadius: "0",
    },
  },
}));

export interface BuyMicePointItemProps {
  title: string;
  value: number;
  onChange?: DropDownProps["onChange"]
}

const BuyMicePointItem: FC<BuyMicePointItemProps> = (props) => {
  const classes = useStyles();

  const { title, value, onChange } = props;

  return (
    <Box className={classes.pointItem}>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} className={classes.titleCell}>
          <Typography variant="body1">{title}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.valueCell}>
          <Typography variant="body1">
            <Currency value={value} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.quantityCell}
          >
            <Box mr={2}>
              <Typography variant="body1">數量:</Typography>
            </Box>
            <DropDown
              options={Array.from(Array(21).keys()).map((num) => String(num))}
              select
              className={classes.dropdown}
              onChange={onChange}
            >
              0
            </DropDown>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BuyMicePointItem;
