import React, { FC, useCallback } from 'react';

import { makeStyles } from '@eGroupTeam/material';
import useAlertOSNotSupport from 'utils/useAlertOSNotSupport';
import useWindowSize from 'utils/useWindowSize';

import Container from '@eGroupTeam/material/Container';
import Typography from '@eGroupTeam/material/Typography';
import MediaStreamClipper, {
  MediaStreamClipperProps,
} from '@eGroupTeam/material-lab/MediaStreamClipper';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
    background: '#000',
    overflow: 'hidden',
    color: '#fff',
  },
  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,0.2)',
  },
  mediaStreamClipper: {
    objectFit: 'cover',
  },
  main: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  scanner: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative',
    '& > span': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: 4,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
      zIndex: 1,
      animation: '$move 2s linear infinite',
    },
    '& > div': {
      zIndex: 1,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '48%',
    },
    '&::before, &::after, em::after, em::before': {
      borderColor: '#fff',
      content: '""',
      position: 'absolute',
      width: 16,
      height: 16,
      borderStyle: 'solid',
      borderWidth: 0,
    },
    '&::before': {
      left: 0,
      top: 0,
      borderLeftWidth: 3,
      borderTopWidth: 3,
    },
    '&::after': {
      right: 0,
      top: 0,
      borderRightWidth: 3,
      borderTopWidth: 3,
    },
    '& > em::before': {
      left: 0,
      bottom: 0,
      borderLeftWidth: 3,
      borderBottomWidth: 3,
    },
    '& > em::after': {
      right: 0,
      bottom: 0,
      borderRightWidth: 3,
      borderBottomWidth: 3,
    },
  },
  '@keyframes move': {
    '0%': {
      top: '100%',
    },
    '50%': {
      top: '50%',
    },
    '100%': {
      top: 0,
    },
  },
}));

export interface QrCodeRecognizerProps
  extends Omit<MediaStreamClipperProps, 'classes'> {
  errorMsg?: string;
  helperText?: string;
}

/**
 * Wrap MediaStreamClipper with styles and layout.
 */
const QrCodeRecognizer: FC<QrCodeRecognizerProps> = ({
  facingMode,
  errorMsg,
  helperText,
  ...other
}) => {
  const classes = useStyles();
  const [width, height] = useWindowSize();
  const isOSNotSupport = useAlertOSNotSupport();

  const handleUserMediaFulfilled = useCallback((video) => {
    // eslint-disable-next-line no-param-reassign
    video.onloadedmetadata = function (e) {
      video.play();
    };
  }, []);

  if (isOSNotSupport) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <MediaStreamClipper
        intervalTime={333}
        width={width}
        height={height}
        className={classes.mediaStreamClipper}
        mirrored={facingMode !== 'environment'}
        facingMode={facingMode}
        autoPlay
        onGetUserMediaFulfilled={handleUserMediaFulfilled}
        playsInline
        muted
        {...other}
      />
      <div className={classes.mask} />
      <div className={classes.main}>
        <Container>
          {errorMsg && (
            <Typography align="center" variant="h4" gutterBottom color="error">
              {errorMsg}
            </Typography>
          )}
          {helperText && (
            <Typography align="center" variant="h4" gutterBottom>
              {helperText}
            </Typography>
          )}
        </Container>
        <Container maxWidth="xs">
          <div className={classes.scanner}>
            <em />
            <div />
            <span />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default QrCodeRecognizer;
