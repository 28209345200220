import { fromJS } from 'immutable';

/**
 * Parse responseList to api acceptable format.
 * @param {*} responseList
 * @param {*} properties
 */
export default function parseResponseList(responseList, properties) {
  return responseList
    .map((response, key) => {
      const type = properties.getIn([key, 'type']);
      switch (type) {
        case 'checkboxGroup': {
          const optionList = [];
          response.forEach((el, key) => {
            if (el.get('checked')) {
              optionList.push({
                optionId: key,
                responseContent: el.get('text'),
              });
            }
          });
          return fromJS({
            questionId: key,
            optionList,
          });
        }
        case 'radioGroup':
          return fromJS({
            questionId: key,
            optionList: [
              {
                optionId: response.get('value'),
                responseContent: response.get('text'),
              },
            ],
          });
        case 'select':
          return fromJS({
            questionId: key,
            optionList: [
              {
                optionId: response.get('value'),
              },
            ],
          });
        case 'rating':
          return fromJS({
            questionId: key,
            responseContent: response.get('value'),
          });
        case 'titleBlock':
          return undefined;
        default:
          return fromJS({
            questionId: key,
            responseContent: response,
          });
      }
    })
    .valueSeq();
}
