import React, { FC } from 'react';

import s3Url from 'utils/s3Url';
import { format } from '@eGroupTeam/utils/dateUtils';

import {
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from '@eGroupTeam/material';
import { useHistory } from 'react-router-dom';
import theme from 'App/theme';

export interface ActivityCardProps {
  activityId: string;
  activityName: string;
  activityStartDateString: string;
  activityEndDateString: string;
  activityDescription: string;
  activityCoverPath?: string;
  MuiCardActionAreaProps?: CardActionAreaProps;
}

const useStyles = makeStyles({
  root: {
    borderRadius: 27,
  },
  content: {
    padding: theme.spacing(0, 3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
});

const ActivityCard: FC<ActivityCardProps> = ({
  activityId,
  activityName,
  activityStartDateString,
  activityEndDateString,
  activityDescription,
  activityCoverPath,
  MuiCardActionAreaProps,
  ...other
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card elevation={0} className={classes.root} {...other}>
      <CardActionArea
        {...MuiCardActionAreaProps}
        onClick={() => {
          history.push(`/me/activities/${activityId}/dashboard`);
        }}
      >
        {activityCoverPath && (
          <CardMedia
            component="img"
            height="256"
            image={s3Url(activityCoverPath)}
          />
        )}
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" fontWeight="normal">
            {activityName}
          </Typography>
          <Typography gutterBottom variant="body2">
            {format(activityStartDateString, 'PPPP')} ~{' '}
            {format(activityEndDateString, 'PPPP')}
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            {activityDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ActivityCard;
