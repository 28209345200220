import React, { ForwardedRef, forwardRef } from 'react';
import {
  Fab,
  FabProps,
  withStyles,
  createStyles,
  Theme,
} from '@eGroupTeam/material';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      right: theme.spacing(4),
      bottom: theme.spacing(4),
      width: theme.spacing(7),
      height: theme.spacing(7),
      zIndex: theme.zIndex.appBar,
    },
  });

const GenericComponent = forwardRef(
  <C extends React.ElementType>(
    props: FabProps<C, { component?: C }>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => <Fab ref={ref} {...props} />
);

export default withStyles(styles)(GenericComponent);
