import React, { useMemo } from 'react';

import s3Url from 'utils/s3Url';
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Container,
  Chip,
  Button,
  Avatar,
} from '@eGroupTeam/material';
import { useSelector } from 'react-redux';
import useLocationSearch from 'utils/useLocationSearch';

import Center from '@eGroupTeam/material-layout/Center';
import { Redirect, useHistory } from 'react-router-dom';

import {
  getActivityUserModelTrainUserFacePath,
  getActivityUserModelTrainUser,
} from 'landingPages/Train/redux';
import { getApplyDoneUser } from '../ActivityApply/redux';

const useStyles = makeStyles((theme) => ({
  main: {
    background: '#fff',
  },
  avatar: {
    width: 150,
    height: 150,
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  chip: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  btn: {
    margin: theme.spacing(0, 1),
  },
}));

const ActivityApplyDone = ({ activity }) => {
  const history = useHistory();
  const classes = useStyles();
  const userFacePath = useSelector(getActivityUserModelTrainUserFacePath);
  const trainUser = useSelector(getActivityUserModelTrainUser);
  const applyUser = useSelector(getApplyDoneUser);
  const { type, qrcodeTokenId } = useLocationSearch();
  const isTrainType = type === 'train';
  const user = useMemo(() => (isTrainType ? trainUser : applyUser), [
    applyUser,
    isTrainType,
    trainUser,
  ]);

  if (!user) {
    return <Redirect to={`/activities/${activity.get('activityId')}`} />;
  }

  return (
    <Center>
      <Container maxWidth="sm">
        <Box px={4} py={6}>
          {userFacePath && (
            <Avatar
              classes={{ root: classes.avatar }}
              src={s3Url(userFacePath)}
            />
          )}
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography align="center" variant="h6">
                報名完成!
              </Typography>
            </Grid>
            <Grid item className={classes.center} xs={12}>
              <div>
                <Typography gutterBottom align="center">
                  {user.get('userName')}
                </Typography>
                <Typography gutterBottom align="center">
                  {user.get('userPhone')}
                </Typography>
                <Typography align="center">{user.get('userEmail')}</Typography>
              </div>
            </Grid>
            <Grid item className={classes.center} xs={12}>
              <Chip
                label="活動前寄送行前通知與報到資訊"
                variant="outlined"
                className={classes.chip}
              />
            </Grid>
            <Grid item className={classes.center} xs={12}>
              <div>
                <Button
                  className={classes.btn}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push(`/activities/${activity.get('activityId')}`);
                  }}
                >
                  返回活動
                </Button>
                {isTrainType && (
                  <Button
                    className={classes.btn}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      history.push(
                        `/train?qrcodeTokenId=${qrcodeTokenId}&activityId=${activity.get(
                          'activityId'
                        )}`
                      );
                    }}
                  >
                    重新拍照
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Center>
  );
};

export default ActivityApplyDone;
