import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@eGroupTeam/material/Typography";
import Grid from "@eGroupTeam/material/Grid";
import { Box } from "@eGroupTeam/material";
import Paper from "@eGroupTeam/material/Paper";
import { UseFormMethods } from "react-hook-form";
import { PaymentContactFormInput } from "types";
import PaymentContactForm, { PaymentContactFormProps } from "../PaymentForm/PaymentContactForm";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3),
    "& h3": {
      fontSize: "1.75rem",
      lineHeight: "1.2",
    },
  },
  pointItem: {
    padding: theme.spacing(3, 5),
    borderTop: "1px solid #DBDDE3",
  },
  dropdown: {
    boxShadow: "none",
    "& .MuiButtonGroup-root": {
      height: "unset",
    },
    "& .MuiButtonGroup-grouped": {
      border: "0",
      borderBottom: "1px solid #DBDDE3",
      borderRadius: "0",
    },
  },
  paper: {
    border: "1px solid #C0C3CE",
    boxShadow: "0 3px 16px rgba(10, 75, 109, 0.08)",
    borderRadius: "8px 8px 0 0",
  },
  contents: {
    borderTop: "1px solid #DBDDE3",
    "& h5": {
      fontSize: "1.25rem",
      lineHeight: "1.2",
    },
  },
  shadow: {
    boxShadow: "0 3px 16px rgba(10, 75, 109, 0.08)",
  },
}));

export interface PaymentInformationProps {
  onSubmit: PaymentContactFormProps["onSubmit"]
  formMethods: UseFormMethods<PaymentContactFormInput>
}

const PaymentInformation: FC<PaymentInformationProps> = ({
  onSubmit,
  formMethods
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant="h3" fontWeight={400}>
            付款資訊
          </Typography>
        </Box>
        <Box className={classes.contents} p={3}>
          <Paper className={classes.shadow}>
            <Box p={3}>
              <PaymentContactForm
                onSubmit={onSubmit}
                {...formMethods}
              />
            </Box>
          </Paper>
        </Box>
      </Paper>
    </Grid>
  );
};

export default PaymentInformation;
