import React, { FC, ReactNode } from 'react';
import findDeepValue from '@eGroupTeam/utils/findDeepValue';
import * as yup from 'yup';
import { Controller, UseFormMethods } from 'react-hook-form';
import SimpleAddress from '@eGroupTeam/material-module/SimpleAddress';
import { DateTimePicker } from '@material-ui/pickers';
import { WithStyles, createStyles, Grid, Tab, Tabs, TextField, Theme, withStyles } from '@eGroupTeam/material';
import { ActivityFormInput } from 'types';
import useTab from '@eGroupTeam/hooks/useTab';

const styles = (theme: Theme) =>
  createStyles({
    tabs: {
      borderBottom: `solid 1px ${theme.palette.divider}`,
      marginBottom: 16
    },
  });

export interface ActivityEventClassProps {
  name: string
  control: UseFormMethods<ActivityFormInput>["control"]
  register: UseFormMethods<ActivityFormInput>["register"]
  watch: UseFormMethods<ActivityFormInput>["watch"]
  clearErrors: UseFormMethods<ActivityFormInput>["clearErrors"]
  errors: UseFormMethods<ActivityFormInput>["errors"]
  defaultTabValue?: number
}
  
const ActivityEventClass: FC<ActivityEventClassProps & WithStyles<typeof styles>> = ({ classes, name, control, register, watch, clearErrors, errors, defaultTabValue }) => {
  const { value, handleChange } = useTab('activityEventClass', defaultTabValue);
  const startError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassStartDateString`
  );
  const endError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassEndDateString`
  );
  const limitError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassLimitCount`
  );
  const placeError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassPlace`
  );
  const meetingUrlError = findDeepValue<yup.ValidationError>(
    errors,
    `${name}.activityClassMeetingUrl`
  );
  return (
    <>
      <input
        type="hidden"
        name={`${name}.activityClassId`}
        value={watch(`${name}.activityClassId`)}
        ref={register}
      />
      <Grid item xs={12}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={(_, v) => {
            clearErrors()
            handleChange(v)
          }}
          className={classes.tabs}
        >
          <Tab label="現場活動" />
          <Tab label="線上活動" />
        </Tabs>
      </Grid>
      <Grid item xs={8}>
        <Controller
          control={control}
          name={`${name}.activityClassStartDateString`}
          rules={{
            required: '「活動開始時間」是必填欄位'
          }}
          render={({ ref, ...other }) => (
            <DateTimePicker
              label="活動開始時間"
              inputVariant="outlined"
              required
              format="yyyy-MM-dd HH:mm"
              disablePast
              ampm={false}
              size="small"
              fullWidth
              maxDate={watch(`${name}.activityClassEndDateString`) || undefined}
              error={!!startError}
              helperText={startError?.message}
              {...other}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          as={TextField}
          control={control}
          name={`${name}.activityClassLimitCount`}
          label="活動人數"
          variant="outlined"
          size="small"
          required
          fullWidth
          inputProps={{
            type: 'number',
            min: 1,
          }}
          error={!!limitError}
          helperText={limitError?.message || '將依照活動人數收取費用'}
          rules={{
            min: {
              value: 1,
              message: '「活動人數」是必填欄位'
            }
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <Controller
          control={control}
          name={`${name}.activityClassEndDateString`}
          rules={{
            required: '「活動結束時間」是必填欄位'
          }}
          render={({ ref, ...other }) => (
            <DateTimePicker
              label="活動結束時間"
              inputVariant="outlined"
              required
              format="yyyy-MM-dd HH:mm"
              disablePast
              ampm={false}
              size="small"
              fullWidth
              minDate={watch(`${name}.activityClassStartDateString`)}
              error={!!endError}
              helperText={endError?.message}
              {...other}
            />
          )}
        />
      </Grid>
      {
        value === 0 && (
          <Controller
            as={SimpleAddress}
            control={control}
            name={`${name}.activityClassMail`}
            MuiTextFieldProps={{
              size: 'small',
              variant: "outlined",
              fullWidth: true,
              style: {
                minWidth: 120
              },
            }}
            cityProps={{
              label: '活動縣市',
            }}
            areaProps={{
              label: '活動地區',
            }}
            disableZipCode
            renderFields={(field1: ReactNode, field2: ReactNode) => (
              <>
                <Grid item xs={4}>
                  {field1}
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    as={TextField}
                    control={control}
                    name={`${name}.activityClassPlace`}
                    label="活動地點名稱"
                    required
                    variant="outlined"
                    fullWidth
                    size="small"
                    error={!!placeError}
                    helperText={placeError?.message || '活動地點名稱(例如，台北101)'}
                    rules={{
                      required: '「活動地點名稱」是必填欄位',
                      maxLength: {
                        value: 150,
                        message: '長度不能超過150個字元'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  {field2}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={TextField}
                    control={control}
                    name={`${name}.activityClassAddress`}
                    label="活動地址"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
              </>
            )}
          />
        )
      }
      {
        value === 1 && (
          <Grid item xs={8}>
            <Controller
              as={TextField}
              control={control}
              name={`${name}.activityClassMeetingUrl`}
              label="線上會議連結"
              fullWidth
              required
              variant="outlined"
              size="small"
              error={!!meetingUrlError}
              helperText={meetingUrlError?.message || '填寫您所使用的線上會議連結，例如： google meet 會議連結、youtube 直播連結'}
              rules={{
                required: '「線上會議連結」是必填欄位',
                maxLength: {
                  value: 255,
                  message: '長度不能超過255個字元'
                }
              }}
            />
          </Grid>
        )
      }
    </>
  );
};

export default withStyles(styles)(ActivityEventClass)
