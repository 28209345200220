import { fromJS } from 'immutable';
import { combineEpics } from 'redux-observable';
import { of, concat } from 'rxjs';
import { normalize } from 'normalizr';
import makeBasicFetchEpic from '@eGroupTeam/utils/makeBasicFetchEpic';
import { setEntities } from '@eGroupTeam/redux-modules/immutable/entities';
import {
  openDialog,
  closeDialog,
} from '@eGroupTeam/redux-modules/immutable/dialogs';
import {
  setSnackbarData,
  openSnackbar,
} from '@eGroupTeam/redux-modules/immutable/snackbars';
import { SNACKBAR } from 'App';
import {
  fetchPostPreviewEdmRequest,
  fetchPostPreviewEdmSuccess,
  fetchPostPreviewEdmFailure,
  fetchPostOrganizationEdmTemplateRequest,
  fetchPostOrganizationEdmTemplateSuccess,
  fetchPostOrganizationEdmTemplateFailure,
  fetchGetOrganizationEdmTemplatesRequest,
  fetchGetOrganizationEdmTemplatesSuccess,
  fetchGetOrganizationEdmTemplatesFailure,
  fetchDeleteOrganizationEdmTemplateRequest,
  fetchDeleteOrganizationEdmTemplateSuccess,
  fetchDeleteOrganizationEdmTemplateFailure,
  fetchPatchOrganizationEdmTemplateRequest,
  fetchPatchOrganizationEdmTemplateSuccess,
  fetchPatchOrganizationEdmTemplateFailure,
  fetchPostOrganizationEdmCopyTemplateRequest,
  fetchPostOrganizationEdmCopyTemplateSuccess,
  fetchPostOrganizationEdmCopyTemplateFailure,
  FETCH_POST_PREVIEW_EDM,
  FETCH_POST_ORGANIZATION_EDM_TEMPLATE,
  FETCH_GET_ORGANIZATION_EDM_TEMPLATES,
  FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE,
  FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE,
  FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE,
  fetchGetOrganizationEdmTemplates,
} from './redux';
import { DIALOG } from './NotificationTemplatesDialog';

export const fetchPostPreviewEdmEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_PREVIEW_EDM,
  apiName: 'fetchPostPreviewEdm',
  fetchRequest: fetchPostPreviewEdmRequest,
  handleSuccess: (response) => {
    window.open(response.data, '_blank');
    return [fetchPostPreviewEdmSuccess(fromJS(response.data))];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostPreviewEdmFailure(error)),
      of(
        setSnackbarData({
          name: SNACKBAR,
          message: '預覽失敗',
          variant: 'error',
        })
      ),
      of(openSnackbar(SNACKBAR))
    ),
});

export const fetchPostOrganizationEdmTemplateEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ORGANIZATION_EDM_TEMPLATE,
  apiName: 'fetchPostOrganizationEdmTemplate',
  fetchRequest: fetchPostOrganizationEdmTemplateRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.organizationEdmTemplate
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostOrganizationEdmTemplateSuccess(fromJS(result)),
      fetchGetOrganizationEdmTemplates({
        organizationId: action.payload.organizationId,
        startIndex: 0,
        size: 10,
      }),
      closeDialog(DIALOG),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostOrganizationEdmTemplateFailure(error))
    ),
});

export const fetchGetOrganizationEdmTemplatesEpic = makeBasicFetchEpic({
  actionType: FETCH_GET_ORGANIZATION_EDM_TEMPLATES,
  apiName: 'fetchGetOrganizationEdmTemplates',
  fetchRequest: fetchGetOrganizationEdmTemplatesRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data.source || [],
      schema.organizationEdmTemplates
    );
    return [
      setEntities(fromJS(entities)),
      fetchGetOrganizationEdmTemplatesSuccess(
        fromJS({
          result,
          total: response.data.total || 0,
        })
      ),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchGetOrganizationEdmTemplatesFailure(error))
    ),
});

export const fetchDeleteOrganizationEdmTemplateEpic = makeBasicFetchEpic({
  actionType: FETCH_DELETE_ORGANIZATION_EDM_TEMPLATE,
  apiName: 'fetchDeleteOrganizationEdmTemplate',
  fetchRequest: fetchDeleteOrganizationEdmTemplateRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.organizationEdmTemplate
    );
    return [
      setEntities(fromJS(entities)),
      fetchDeleteOrganizationEdmTemplateSuccess(fromJS(result)),
      fetchGetOrganizationEdmTemplates({
        organizationId: action.payload.organizationId,
        startIndex: 0,
        size: 10,
      }),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchDeleteOrganizationEdmTemplateFailure(error))
    ),
});

export const fetchPatchOrganizationEdmTemplateEpic = makeBasicFetchEpic({
  actionType: FETCH_PATCH_ORGANIZATION_EDM_TEMPLATE,
  apiName: 'fetchPatchOrganizationEdmTemplate',
  fetchRequest: fetchPatchOrganizationEdmTemplateRequest,
  handleSuccess: (response, { schema }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.organizationEdmTemplate
    );
    return [
      setEntities(fromJS(entities)),
      fetchPatchOrganizationEdmTemplateSuccess(fromJS(result)),
      closeDialog(DIALOG),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPatchOrganizationEdmTemplateFailure(error))
    ),
});

export const fetchPostOrganizationEdmCopyTemplateEpic = makeBasicFetchEpic({
  actionType: FETCH_POST_ORGANIZATION_EDM_COPY_TEMPLATE,
  apiName: 'fetchPostOrganizationEdmTemplate',
  fetchRequest: fetchPostOrganizationEdmCopyTemplateRequest,
  handleSuccess: (response, { schema, action }) => {
    const { result, entities } = normalize(
      response.data || {},
      schema.organizationEdmTemplate
    );
    return [
      setEntities(fromJS(entities)),
      fetchPostOrganizationEdmCopyTemplateSuccess(fromJS(result)),
      fetchGetOrganizationEdmTemplates({
        organizationId: action.payload.organizationId,
        startIndex: 0,
        size: 10,
      }),
      openDialog(DIALOG),
      setSnackbarData({
        name: SNACKBAR,
        message: '複製成功',
        variant: 'success',
      }),
      openSnackbar(SNACKBAR),
    ];
  },
  handleFailure: (error, { state$, action, apiErrorsHandler }) =>
    concat(
      apiErrorsHandler(error, { state$, action }),
      of(fetchPostOrganizationEdmCopyTemplateFailure(error))
    ),
});

export default combineEpics(
  fetchPostPreviewEdmEpic,
  fetchPostOrganizationEdmTemplateEpic,
  fetchGetOrganizationEdmTemplatesEpic,
  fetchDeleteOrganizationEdmTemplateEpic,
  fetchPatchOrganizationEdmTemplateEpic,
  fetchPostOrganizationEdmCopyTemplateEpic
);
