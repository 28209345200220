import React, { FC } from 'react';

import { FormattedNumber } from 'react-intl';
import MicepointIcon from 'components/MicepointIcon';

export interface CurrencyProps {
  value: number | bigint;
  isMicepoint?: boolean;
}

const Currency: FC<CurrencyProps> = (props) => {
  const { isMicepoint, value } = props;

  if (isMicepoint) {
    return (
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <MicepointIcon style={{ fontSize: 16, marginRight: 4 }} />
        {value}
      </span>
    );
  }

  return (
    <FormattedNumber
      value={value}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency="TWD"
      currencyDisplay="code"
      minimumFractionDigits={0}
    />
  );
};

export default Currency;
