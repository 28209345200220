import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { withReduxDialog } from '@eGroupTeam/redux-modules/immutable/dialogs';
import { clearApiResponse } from '@eGroupTeam/redux-modules/immutable/apis';
import { getSelectedOrg } from 'redux/modules/common';

import Dialog from '@eGroupTeam/material/Dialog';
import DialogContent from '@eGroupTeam/material/DialogContent';
import DialogTitle from '@eGroupTeam/material/DialogTitle';
import { fetchGetUser, fetchPatchUser, getUser } from './redux';
import UserEditForm from './UserEditForm';

export const DIALOG = 'userEditDialog';

const UserEditDialog = ({ isOpen, handleClose, userId }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const selectedOrg = useSelector(getSelectedOrg);

  React.useEffect(() => {
    if (userId) {
      dispatch(
        fetchGetUser({
          organizationId: selectedOrg.get('organizationId'),
          userId,
        })
      );
    }
    return () => {
      dispatch(clearApiResponse(fetchGetUser()));
    };
  }, [dispatch, selectedOrg, userId]);

  const handleSubmit = (values) => {
    dispatch(
      fetchPatchUser({
        organizationId: selectedOrg.get('organizationId'),
        userId: values.get('userId'),
        userName: values.get('userName'),
        userJobTitle: values.get('userJobTitle'),
        userEmail: values.get('userEmail'),
        userPhone: values.get('userPhone'),
        userOrganizationName: values.get('userOrganizationName'),
      })
    );
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>會員資料</DialogTitle>
      <DialogContent>
        <UserEditForm
          onSubmit={handleSubmit}
          initialValues={user}
          organizationId={selectedOrg.get('organizationId')}
        />
      </DialogContent>
    </Dialog>
  );
};

UserEditDialog.propTypes = {
  // redux dialog props
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // parent props
  userId: PropTypes.string,
};

export default withReduxDialog(DIALOG)(UserEditDialog);
