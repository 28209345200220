import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { withStyles } from '@eGroupTeam/material';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

const IntroduceCardContent = React.forwardRef((props, ref) => {
  const { classes, className, ...other } = props;

  return <div className={clsx(classes.root, className)} ref={ref} {...other} />;
});

IntroduceCardContent.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
};

export default withStyles(styles)(IntroduceCardContent);
